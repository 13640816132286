import TableXWrapper from '../../../../components/shared/TableXWrapper'
import {Modal, ModalBody, ModalHeader, Spinner} from 'react-bootstrap'
import {getEnv} from '../../../../../helpers/procurex'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {useMutation, useQuery} from 'react-query'
import axios from 'axios'
import {toast} from 'react-toastify'
import Swal from 'sweetalert2'
import {useAanwijzing} from './core/_aanwijzingProvider'


const FormTambahRekanan = ({aanwijzing, selectedVendor,onSelectVendor,onUnSelectVendor,onSuccess}) => {
  const intl = useIntl();
  const [formIndex,setFormIndex] = useState(0)
  const urlIndex = getEnv('vendor')+"/vendor";
  const [checkedVendors, setCheckedVendors] = useState({});
  const handleOnCheck = (e: any, data: any) => {
    if (e.target.checked) {
      onSelectVendor(data)
    } else {
      onUnSelectVendor(data)
    }
  };

  const checkVendorExists = useQuery({
    queryKey: ['checkVendorExists', aanwijzing?.id],
    queryFn: () => axios.get(getEnv('tender') + `/aanwijzing-vendor?filter[aanwijzing_id]=${aanwijzing?.id}`),
    enabled: !!aanwijzing?.id,
    onSuccess: (data) => {
      data?.data?.data.forEach((v: any) => {
        checkedVendors[v?.vendor?.vendor_no] = true;
      })
    }
  });

  const isChecked = (row) => {
    let status = false;
    selectedVendor?.forEach((v:any)=>{
      if(v?.id === row?.id) status = true;
    })
    return status;
  }

  const tableColumns = [
    {
      Header: intl.formatMessage({ id: "ID" }),
      accessor: "selection",
      width: 100,
      className: "text-center",
      Cell: ({row}: any) => {
        const exists = checkedVendors[row.original.vendor_no];
        return (
          <>
            <input
              className={'form-check-input border-primary'}
              style={{borderColor: `#fff`, borderRadius: '4px'}}
              onChange={(e) => {
                handleOnCheck(e, row?.original)
              }}
              checked={isChecked(row?.original) || exists}
              disabled={exists}
              type={'checkbox'}
            />
          </>
        )
      }
    },
    {
      Header: intl.formatMessage({id: 'Status Vendor'}),
      accessor: 'status_vendor',
      width: 150,
      className: 'text-center text-wrap',
      Cell: ({row}: any) => {
        return (
          (<span className={'badge badge-info'}>{row?.original?.status_vendor}</span>)
        )
      },
    },
    {
      Header: intl.formatMessage({ id: "Vendor No" }),
      accessor: "vendor_no",
      width: 150,
      className: "text-center"
    },
    {
      Header: intl.formatMessage({ id: "Name" }),
      accessor: "name",
      width: 300,
      className: "text-start"
    },
    {
      Header: intl.formatMessage({ id: "PIC Name" }),
      accessor: "pic_name",
      width: 200,
      className: "text-start"
    },
    {
      Header: intl.formatMessage({ id: "PIC Email" }),
      accessor: "pic_email",
      width: 200,
      className: "text-start text-wrap"
    },
  ];

  //region save rekanan
  const {mutate, isLoading} = useMutation({
    mutationKey: ['aanwijzing','vendor','add'],
    mutationFn: (payload) => axios.post(getEnv('tender')+"/aanwijzing-vendor", payload),
    onSuccess: (res:any) => {
      toast.success(res?.data?.meta?.message)
      onSuccess()
    },
    onError: (err:any) => {
      // console.error("error_",)
      toast.error(err?.response?.data?.meta?.message)
    }
  })

  const handOnSubmit = () => {
    const payload = {
      aanwijzing_id: aanwijzing?.uuid,
      vendors: selectedVendor?.map((v)=>{
        return v?.vendor_no
      })
    }
    //@ts-ignore
    mutate({
      ...payload
    })
  }
  //endregion

  return (
    <>
      <TableXWrapper
        key={formIndex}
        readonly={true}
        title={"DUR"}
        urlIndex={urlIndex}
        columns={tableColumns}
        isForceCreateButton={true}
        disablePaddingCard={true}
        defaultFilter={"filter[status_vendor]=active"}
        hideSelection={true}
        hideShowPagination={true}
        hideExportAs={false}
        onAfterLoadData={(data: any) => {
        }}
        disableUrlChanged={true}
        headerButtons={
          <>
              <div className={"badge badge-info"}>
                <i className={"fa fa-list text-white me-2"}></i>
                {selectedVendor?.length} Rekanan Terpilih
              </div>
          </>
        }
        subHeaderButtons={
          <div className={'d-flex flex-row gap-2'}>


            <button
              className={'btn btn-primary btn-sm'}
              type={'button'} disabled={selectedVendor?.length === 0 || isLoading}
              onClick={handOnSubmit}
            >
              {isLoading && <Spinner size={"sm"} className={"me-2"} /> }
              {!isLoading && <i className={"fa fa-save me-2"}></i>}
              Simpan Rekanan
            </button>
          </div>
        }
      />


    </>
  )
}

const StatusBadgeVendor = ({status}: any) => {
  const renderStatus = () => {
    switch (status) {
      case "WAITING":
        return (<span className={"badge badge-info"}>{status}</span>);
      case "REJECT_QUOTATION":
        return (<span className={"badge badge-danger"}>{status}</span>);
      case "REJECT_INVITATION":
        return (<span className={"badge badge-danger"}>{status}</span>);
      default:
        return (<span className={"badge badge-primary"}>{status}</span>);
    }
  };
  return (
    <>
      {renderStatus()}
    </>
  );
};

const StatusBadgeApproveBA = ({status}: any) => {
  const renderStatus = () => {
    switch (status) {
      case "NO RESPON":
        return (<span className={"badge badge-info"}>{status}</span>);
      case "APPROVE":
        return (<span className={"badge badge-primary"}>{status}</span>);
      default:
        return (<span className={"badge badge-danger"}>{status}</span>);
    }
  };
  return (
    <>
      {renderStatus()}
    </>
  );
}

const VendorList = () => {
  const intl = useIntl();
  const [formIndex,setFormIndex] = useState(0)
  const urlIndex = getEnv('tender')+"/aanwijzing-vendor";
  const [isShowModalTambahRekanan,setIsShowModalTambahRekanan] = useState(false)
  const [selectedVendor,setSelectedVendor] = useState([])
  const {aanwijzing} = useAanwijzing()

  //region delete rekanan
  const {mutate:mutateDelete,isLoading:isLoadingDelete} = useMutation({
    mutationKey:['aanwijzing','vendor','delete'],
    mutationFn: (uuid:string) => axios.delete(
      getEnv('tender')+`/aanwijzing-vendor/${uuid}`
    ),
    onSuccess: (res)=> {
      toast.success(res?.data?.meta?.message)
      setFormIndex(formIndex+1)
    },
    onError: (err:any) => {
      console.error(err);
      toast.error(err)
    }
  })
  const handleOnDelete = (row:any) => {
    Swal.fire({
      title: "Apakah anda yakin?",
      text: "Anda akan menghapus rekanan ini",
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Ya, hapus',
      cancelButtonText: 'Tidak',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        console.log('delete_',row)
        mutateDelete(row?.uuid)
      }
    })
  }
  //endregion

  const tableColumns = [
    {
      Header: intl.formatMessage({ id: "Delete" }),
      accessor: "uuid",
      width: 100,
      className: "text-center",
      Cell: ({ row }: any) => {

        return (
          <>
            <button
              type={"button"}
              className={"btn btn-sm btn-danger text-center"}
              disabled={!isCanTambahRekanan}
              onClick={()=>handleOnDelete(row?.original)}
            >
              <i className={"fa fa-trash"} />
            </button>
          </>
        );
      }
    },
    {
      Header: intl.formatMessage({ id: "Status" }),
      accessor: "status",
      width: 150,
      className: "text-center",
      Cell: ({ row }: any) => {
        return (
          <StatusBadgeVendor status={row.original.status} />
        );
      }
    },
    {
      Header: intl.formatMessage({ id: "Status BA" }),
      accessor: "status_approve_ba",
      width: 100,
      className: "text-center",
      Cell: ({ row }: any) => {
        return (
          <StatusBadgeApproveBA status={row.original.status_approve_ba} />
        );
      }
    },
    {
      Header: intl.formatMessage({ id: "Vendor ID" }),
      accessor: "vendor.vendor_no",
      width: 100,
      className: "text-center",
    },
    {
      Header: intl.formatMessage({ id: "Vendor" }),
      accessor: "vendor.name",
      width: 300,
      className: "text-start",
    },
    {
      Header: intl.formatMessage({ id: "PIC Name" }),
      accessor: "vendor.pic_name",
      width: 200,
      className: "text-start",
    },
    {
      Header: intl.formatMessage({ id: "PIC Email" }),
      accessor: "vendor.pic_email",
      width: 200,
      className: "text-start",
    },

  ];

  const handleOnCheckVendor = (row:any) =>  {
    setSelectedVendor([
      ...selectedVendor,
      row
    ])
  }

  const handleOnUncheckVendor = (row:any) =>  {
    const tmp = selectedVendor.filter((r:any)=>r !== row);
    setSelectedVendor(tmp)
  }

  const handleOnSuccess = () => {
    setFormIndex(formIndex+1)
    setIsShowModalTambahRekanan(false)
    setSelectedVendor([])
  }

  const isCanTambahRekanan = useMemo(() => {
    return (aanwijzing?.status === "OPEN" || aanwijzing?.status === "REJECTED") && aanwijzing?.source !== "PROCUREX"
  }, [aanwijzing?.status, aanwijzing?.source])


  return (
    <>
      <div className={"d-flex justify-content-between"}>
        <h3>{intl.formatMessage({id: "Daftar Rekanan"})}</h3>
      </div>
      <hr />
      {aanwijzing?.source === "PROCUREX" && (
        <div className={"alert alert-info"}>
          <strong><i className={"fa fa-exclamation-triangle me-2"}></i> Perhatian: </strong>
          Untuk Aanwijzing yang bersumber dari PROCUREX, tidak dapat mengubah daftar rekanan.
        </div>
      )}
      <TableXWrapper
        key={formIndex}
        readonly={true}
        title={"DUR"}
        urlIndex={urlIndex}
        columns={tableColumns}
        defaultFilter={`filter[aanwijzing_id]=${aanwijzing?.id}`}
        isForceCreateButton={true}
        disablePaddingCard={true}
        hideSelection={true}
        hideShowPagination={true}
        hideExportAs={false}
        onAfterLoadData={(data: any) => {
        }}
        disableUrlChanged={true}
        disableSearchInputAutoFocus={true}
        subHeaderButtons={
        <>
          <button
            type={"button"}
            className={"btn btn-info btn-sm"}
            onClick={()=>setIsShowModalTambahRekanan(true)}
            disabled={!isCanTambahRekanan}
          >
            <i className={"fa fa-plus"}></i>
            Tambah Rekanan
          </button>
        </>
        }
      />

      <Modal
        show={isShowModalTambahRekanan}
        size={"xl"}
        onHide={()=>setIsShowModalTambahRekanan(false)}
      >
      <ModalHeader onHide={()=>setIsShowModalTambahRekanan(false)}>
        <h3>Tambah Rekanan</h3>
      </ModalHeader>
        <ModalBody>
          <FormTambahRekanan
            onSuccess={handleOnSuccess}
            aanwijzing={aanwijzing}
            selectedVendor={selectedVendor}
            onSelectVendor={handleOnCheckVendor}
            onUnSelectVendor={handleOnUncheckVendor}
          />
        </ModalBody>
      </Modal>
    </>
  )
}

export default VendorList;