import { Outlet, Route, Routes } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../../_metronic/layout/core'
import React, { useState, useEffect } from 'react'
import {
    SlocMasterDataStockviewList,
} from './components/SlocMasterDataStockviewList'

const masterData = {
    title: 'Sloc',
    path: '/stockview/masterdata/sloc',
    isSeparator: false,
    isActive: false,
}
const separator = {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
}
const breadcrumbs: Array<PageLink> = [masterData, separator]
const childBreadcrumbs: Array<PageLink> = [
    masterData,
    separator,
    {
        title: 'Master Data Sloc',
        path: '/stockview/masterdata/sloc',
        isSeparator: false,
        isActive: false,
    },
    separator,
]

const SlocMasterDataStockview = () => {
    // @ts-ignore

    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    index
                    element={
                        <>
                            <PageTitle breadcrumbs={breadcrumbs}>Master Data Sloc</PageTitle>
                            <SlocMasterDataStockviewList />
                        </>
                    }
                ></Route>
            </Route>
        </Routes>
    )
}

export default SlocMasterDataStockview
