import { ID, KTCard, KTCardBody } from '../../../../../../_metronic/helpers'
import React, { useState, useRef } from 'react'
import { Link } from 'react-router-dom';
import TableXWrpper from "../../../../../components/shared/TableXWrapper";
import { ApiUrl } from '../../../../../config/Api'
import { getEnv } from "../../../../../../helpers/procurex";
import { Field, Form, Formik } from 'formik'
import SelectX from '../../../../../components/shared/form/SelectX'
import FormikPublicSelect from '../../../../home/vendor-register/components/FormikPublicSelect'
import { toast } from 'react-toastify'
import axios from 'axios'
import { Button, ButtonGroup, Col, Modal, Row } from "react-bootstrap";
import { useAuth } from "../../../../../../app/modules/auth";

const PPICMasterDataStockviewList = () => {
    const { currentUser } = useAuth();
    const tablecolumns = [
        {
            Header: "NAMA",
            accessor: "nama",
            width: 160,
        },
        {
            Header: "JENIS",
            accessor: "jenis",
            width: 160,
        },
        {
            Header: "SATUAN",
            accessor: "satuan",
            width: 160,
        },
        {
            Header: "PPIC ID",
            accessor: "id_ppic",
            width: 160,
        },
        {
            Header: "MATERIAL NUMBER",
            accessor: "material_no",
            width: 250,
        },
        {
            Header: "EXTRA INFO",
            accessor: "extra_info_text",
            width: 250,
        },
    ];

    const [formIndex, setFormIndex] = useState(0);
    const [isShowModal, setIsShowModal] = useState(false)
    const [isShowDetail, setIsShowDetail] = useState(false)
    const [key, setKey] = useState(0)
    const [id, setId] = useState('')
    const [item, setItem] = useState<any>(null)
    const formRef = useRef({} as any)
    const [isConfirmDialog, setIsConfirmDialog] = useState(false)
    const excludedRoles = ['admin_anper'];

    const [initialValues, setInitialValues] = useState<any>({
        active_app: item?.active_app || '',
    })

    const openModalWithItem = (item: any) => {
        // Set the initial value of active_app based on the item
        setInitialValues({
            active_app: item?.active_app || ''
        });
        // Set the item and open the modal
        setItem(item);
        setIsShowModal(true);
    };

    let role = currentUser.has_roles;

    return (
        <>
            <KTCard className='card border mt-5'>
                <KTCardBody >
                    <TableXWrpper
                        title={"PPIC Master Data"}
                        key={formIndex}
                        readonly
                        urlIndex={getEnv("stockview") + `/ppic`}
                        columns={tablecolumns}
                    />
                </KTCardBody>
            </KTCard>
        </>
    )
}

export { PPICMasterDataStockviewList }
