import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import FormikSelect2 from "../../../../../components/shared/form/FormikSelect2";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { formatNumberToDecimalAuction, getEnv } from "../../../../../../helpers/procurex";
import CurrencyInput from "react-currency-input-field";

const ParameterAuctionTab = ({ auction, onSave }: any) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const headerFormSchema = Yup.object().shape({});
  const [isLoadingSave, setIsLoadingSave] = useState(false)

  const isFormEnabled = () => {
    return auction?.status === 'draft'
  }
  const isAuctionFromTender = () => {
    return auction?.no_tender != null
  }
  const onSubmit = async (values: any) => {
    setIsLoadingSave(true)
    try {
      let data = { ...values }
      data.template_id = data.template?.id;
      data.increment = formatNumberToDecimalAuction(data.increment, 2);
      const response: any = await axios.put(getEnv('auction') + `/auction/${auction?.id}`, data)
      if (response?.status === 201 || response?.status === 200) {
        toast.success(response?.status + ': ' + response?.data.meta.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
        onSave && onSave()
        return true
      }
    } catch (error) {
      setIsLoadingSave(false)
      console.error(error)
    } finally {
      setIsLoadingSave(false)
    }
  }

  useEffect(() => {
  }, [])


  return (
    <div className={"p-2"}>
      <Formik
        enableReinitialize
        validationSchema={headerFormSchema}
        initialValues={auction ?? null}
        onSubmit={onSubmit}
      >
        {({ errors, touched, values, setFieldValue }: any) => (
          <Form>
            <div className={'row'}>
              <div className={'col-md-6'}>
                <div className='mb-10'>
                  <label className='required form-label mb-3'>No</label>
                  <Field className='form-control' disabled={true} required name='no_auction' />
                </div>
                <div className='mb-10'>
                  <label className='required form-label mb-3'>Nama</label>
                  <Field
                    className='form-control'
                    disabled={!isFormEnabled()}
                    name='nama'
                    required
                  />
                </div>
                <div className='mb-10'>
                  <FormikSelect2
                    name='purchasing_org'
                    label='Purchasing Org'
                    isDisabled={!isFormEnabled() || isAuctionFromTender()}
                    required
                    optionsUrl={`${API_URL}/api/masterdata/companies`}
                    unique_property={'id'}
                    defaultValue={values?.purchasing_org}
                    getOptionValue={(opt: any) => opt}
                    getOptionLabel={(opt: any) => opt.comp_code_name}
                    onChangeValue={(opt: any) => {
                      setFieldValue('purchasingGroup', 0)
                    }}
                    search_property={"comp_code_name"}
                  />
                </div>
                <div className='mb-10'>
                  <label className='required form-label mb-3'>Buyer</label>
                  <Field
                    className='form-control'
                    disabled={true}
                    name='created_by.name'
                    required
                  />
                </div>
              </div>
              <div className={'col-md-6'}>
                <div className='mb-10'>
                  <label className='required form-label mb-3'>Status</label>
                  <Field className='form-control' name='status' disabled={true} required />
                </div>
                <div className='mb-10'>
                  <FormikSelect2
                    name='tipe_auction'
                    label='Tipe Auction'
                    isDisabled={true}
                    defaultValue={values?.tipe_auction}
                    optionsUrl={
                      API_URL + '/api/masterdata/enumeration?filter[type]=AUCTION_TYPE'
                    }
                    unique_property={'id'}
                    getOptionValue={(opt) => opt}
                    getOptionLabel={(opt) => opt.value}
                    search_property={'value'}
                    required
                  />
                </div>
                <div className='mb-10'>
                  <FormikSelect2
                    label='Purchasing Group'
                    name='purchasing_group'
                    isDisabled={!isFormEnabled() || isAuctionFromTender()}
                    defaultValue={values?.purchasing_group}
                    optionsUrl={
                      API_URL +
                      '/api/masterdata/purchasing-group?filter[comp_code]=' +
                      values?.purchasing_org?.comp_code
                    }
                    getOptionValue={(opt: any) => opt}
                    getOptionLabel={(opt: any) =>
                      `${opt.purch_group_id} - ${opt.purch_group_name}`
                    }
                    unique_property={'id'}
                    required
                    search_property={"purch_group_id_category_name"}
                  />
                </div>
                <div className='mb-10'>
                  <label className='required form-label mb-3'>Freeze Time (Menit)</label>
                  <Field
                    className='form-control'
                    disabled={!isFormEnabled()}
                    name='freeze_time_in_minutes'
                    required
                  />
                </div>
              </div>
              <div className={'col-md-12'}>
                <hr />
              </div>
              <div className={'col-md-6'}>
                <div className='mb-10'>
                  <FormikSelect2
                    name='metode_evaluasi'
                    label='Metode Evaluasi Pemenang'
                    isDisabled={true}
                    defaultValue={values?.metode_evaluasi}
                    optionsUrl={
                      API_URL +
                      '/api/masterdata/enumeration?filter[type]=AUCTION_EVALUATION_METHOD'
                    }
                    unique_property={'id'}
                    getOptionValue={(opt) => opt}
                    getOptionLabel={(opt) => opt.value}
                    search_property={'value'}
                    required
                  />
                </div>
                <div className='mb-10'>
                  <FormikSelect2
                    name='opsi_peringkat'
                    defaultValue={values?.opsi_peringkat}
                    isDisabled={!isFormEnabled()}
                    label='Opsi Penampilan Peringkat'
                    optionsUrl={
                      API_URL +
                      '/api/masterdata/enumeration?filter[type]=AUCTION_RATING_APPEARANCE'
                    }
                    unique_property={'id'}
                    getOptionValue={(opt) => opt}
                    getOptionLabel={(opt) => opt.value}
                    search_property={'value'}
                    required
                  />
                </div>
                <div className='mb-10'>
                  <FormikSelect2
                    name='jenis_penerimaan'
                    defaultValue={values?.jenis_penerimaan}
                    isDisabled={!isFormEnabled()}
                    label='Penerimaan Jumlah Diluar Rentang'
                    optionsUrl={
                      API_URL + '/api/masterdata/enumeration?filter[type]=AUCTION_ACCEPT'
                    }
                    unique_property={'id'}
                    getOptionValue={(opt) => opt}
                    getOptionLabel={(opt) => opt.value}
                    search_property={'value'}
                    required
                  />
                </div>
                <div className='mb-10'>
                  <label className='required form-label mb-3'>Increment / Decrement</label>
                  <CurrencyInput
                    value={(values?.increment)}
                    decimalsLimit={2}
                    decimalSeparator=","
                    groupSeparator="."
                    className="form-control"
                    onValueChange={(value: any) => {
                      setFieldValue("increment", value);
                    }}
                    disabled={!isFormEnabled()}
                    required
                  />
                </div>
              </div>
              <div className={'col-md-6'}>
                <div className={'row'}>
                  <div className={values?.metode_peringkat?.id == 297 ? 'col-md-6' : 'col-md-12'}>
                    <div className='mb-10'>
                      <FormikSelect2
                        name='metode_peringkat'
                        defaultValue={values?.metode_peringkat}
                        isDisabled={true}
                        label='Metode Peringkat'
                        optionsUrl={
                          API_URL +
                          '/api/masterdata/enumeration?filter[type]=AUCTION_RATING_METHOD'
                        }
                        unique_property={'id'}
                        getOptionValue={(opt) => opt}
                        getOptionLabel={(opt) => opt.value}
                        search_property={'value'}
                        required
                      />
                    </div>
                  </div>
                  {values?.metode_peringkat?.id == 297 &&
                    <div className={'col-md-6'}>
                      <div className='mb-10'>
                        <FormikSelect2
                          not_required
                          name='template'
                          defaultValue={values?.template}
                          label='Template MVB'
                          optionsUrl={
                            `${getEnv('auction')}/template`
                          }
                          unique_property={'id'}
                          getOptionValue={(opt) => opt}
                          getOptionLabel={(opt) => opt?.judul}
                          search_property={'judul'}
                          isDisabled={!isFormEnabled()}
                        />
                      </div>
                    </div>
                  }
                </div>
                <div className='mb-10'>
                  <FormikSelect2
                    name='visibilitas_peringkat'
                    defaultValue={values?.visibilitas_peringkat}
                    label='Visibilitas Peringkat Terbaik'
                    isDisabled={!isFormEnabled()}
                    optionsUrl={
                      API_URL + '/api/masterdata/enumeration?filter[type]=AUCTION_VISIBLE'
                    }
                    unique_property={'id'}
                    getOptionValue={(opt) => opt}
                    getOptionLabel={(opt) => opt.value}
                    search_property={'value'}
                    required
                  />
                </div>
                <div className='mb-10'>
                  <FormikSelect2
                    name='visibilitas_harga'
                    defaultValue={values?.visibilitas_harga}
                    isDisabled={!isFormEnabled()}
                    label='Visibilitas Harga Terbaik'
                    optionsUrl={
                      API_URL + '/api/masterdata/enumeration?filter[type]=AUCTION_VISIBLE'
                    }
                    unique_property={'id'}
                    getOptionValue={(opt) => opt}
                    getOptionLabel={(opt) => opt.value}
                    search_property={'value'}
                    required
                  />
                </div>
                <div className='mb-10'>
                  <FormikSelect2
                    name='dominasi'
                    isDisabled={!isFormEnabled()}
                    defaultValue={values?.dominasi}
                    label='Denominasi'
                    optionsUrl={
                      API_URL + '/api/masterdata/enumeration?filter[type]=AUCTION_DOMINATION'
                    }
                    unique_property={'id'}
                    getOptionValue={(opt) => opt}
                    getOptionLabel={(opt) => opt.value}
                    search_property={'value'}
                    required
                  />
                </div>
              </div>
            </div>
            <div className={'row'}>
              <div className={'col-md-12 text-end'}>
                <button className={'btn btn-primary btn-sm'} disabled={isLoadingSave || !isFormEnabled()}>
                  {isLoadingSave && (
                    <Spinner animation={'border'} size={'sm'} className={'me-2'} />
                  )}
                  {!isLoadingSave && <i className={'fa fa-save me-2'}></i>}
                  Simpan
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
};

export { ParameterAuctionTab };
