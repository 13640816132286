import { ID, KTCard, KTCardBody } from '../../../../../../_metronic/helpers'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import TableXWrpper from "../../../../../components/shared/TableXWrapper";
import { ApiUrl } from '../../../../../config/Api'
import { Button, ButtonGroup, Col, Modal, Row } from "react-bootstrap";
import { getEnv } from "../../../../../../helpers/procurex";
import { useIntl } from "react-intl";
import SelectX from '../../../../../components/shared/form/SelectX'
import { MetronicErrorMessage, toastError } from '../../../../../../_metronic/helpers'
import SwalX from '../../../../../components/shared/SwalX'
import { InputNumber } from "rsuite";
import { Field, Form, Formik } from 'formik'
import { toast } from 'react-toastify'
import { useAuth } from "../../../../../../app/modules/auth";
import axios from 'axios'

const TransportationMasterDataStockviewList = () => {
    const { currentUser } = useAuth();
    const intl = useIntl();
    const tablecolumns = [
        {
            Header: "Name",
            accessor: "name",
            width: 250,
        },
        {
            Header: "Capacity",
            accessor: "capacity",
            width: 250,
        },
        {
            Header: "NOMOR IMO",
            accessor: "imo",
            width: 200,
        },
        {
            Header: "Category",
            accessor: "category",
            width: 200,
        },
        {
            Header: "Action",
            accessor: "uuid",
            width: 200,
            className: "text-center fixed-column",
            Cell: (props: any) => {
                const row = props.row.original;
                return (
                    <ButtonGroup size='sm' aria-label='Action'>
                        {!excludedRoles.some(r => role.includes(r)) && (
                            <>
                                <button
                                    type='button'
                                    className='btn btn-sm btn-success'
                                    onClick={(e: any) => {
                                        e.preventDefault();
                                        setId(row.id);
                                        openModalWithItem(row); // Pass the row item to the function
                                    }}
                                >
                                    Edit
                                </button>
                                <button
                                    type='button'
                                    className='btn btn-sm btn-danger'
                                    onClick={() => {
                                        setItemId(row.id)
                                        setIsConfirmDeleteOpen(true)
                                    }}
                                >
                                    <i className={'fa fa-trash'} />
                                </button>
                            </>
                        )}
                    </ButtonGroup>
                );
            },
        },
    ];

    const loadItem = () => {

    }

    useEffect(() => {
        loadItem();
    }, [])

    const [formIndex, setFormIndex] = useState(0);

    const [key, setKey] = useState(0)
    const [isShowDetail, setIsShowDetail] = useState(false)
    const [isConfirmDialog, setIsConfirmDialog] = useState(false)
    const [id, setId] = useState('')
    let [itemId, setItemId] = useState(0)
    const [item, setItem] = useState<any>(null)
    const [isShowModal, setIsShowModal] = useState(false)
    let [isLoadingDelete, setIsLoadingDelete] = useState(false)
    let [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false)
    const excludedRoles = ['admin_anper'];

    const [selectedCategory, setSelectedCategory] = useState(null);

    const handleCategory = (selectedValue: any) => {
        setSelectedCategory(selectedValue);
    };

    const [totalCapacity, setTotalCapacity] = useState(0);

    const handleOnTotalCapacity = (score: number) => {
        setTotalCapacity(score)
    }

    const deleteItem = async () => {
        try {
            setIsLoadingDelete(true)
            const response: any = await axios.delete(getEnv("stockview") + `/transportation/${itemId}`)

            if (response.status === 200) {
                setFormIndex(formIndex + 1)
                setIsConfirmDeleteOpen(false)
                toast.success(response?.data?.meta?.message, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                })
                loadItem()
            }
        } catch (error: any) {
            console.error(error)
            toastError(error.response?.data?.meta?.message || 'Terjadi kesalahan')
        } finally {
            setIsLoadingDelete(false)
        }
    }

    const submit = async (values: any) => {
        try {
            const newData = {
                name: values.name,
                imo: values.imo,
                category: selectedCategory ? selectedCategory : item?.category,
                capacity: totalCapacity ? totalCapacity : item?.capacity,
            };
            console.log(newData);


            const response = await axios.put(getEnv("stockview") + `/transportation/${id}`, newData)

            if (response.status === 200 || response.status === 201) {
                toast.success(response.data.meta.message)
                setId('')
                setIsShowModal(false)
                setFormIndex(formIndex + 1)
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.meta?.message || 'Terjadi kesalahan')
        } finally {
            setIsConfirmDialog(false)
            setIsShowDetail(false)
        }
    }

    const [initialValues, setInitialValues] = useState<any>({
        name: item?.name || '',
        imo: item?.imo || '',
        category: item?.category || '',
        capacity: item?.capacity || '0',
    })
    console.log(initialValues);

    const openModalWithItem = (item: any) => {
        setInitialValues({
            name: item?.name || '',
            imo: item?.imo || '',
            category: item?.category || '',
            capacity: item?.capacity || '0',
        });
        // Set the item and open the modal
        setItem(item);
        setIsShowModal(true);
    };

    let role = currentUser.has_roles;

    return (
        <>
            <SwalX
                title={intl.formatMessage({ id: 'DATATABLE.CONFIRM_DELETE' })}
                message={intl.formatMessage({ id: 'DATATABLE.MESSAGE_DELETE' })}
                show={isConfirmDeleteOpen}
                loading={isLoadingDelete}
                onHide={() => setIsConfirmDeleteOpen(false)}
                onSubmit={() => deleteItem()}
            />

            <Modal size="xl" show={isShowModal} onHide={() => setIsShowModal(false)}>
                <Modal.Header>Edit Kedatangan Stockview</Modal.Header>
                <Modal.Body>
                    <Formik initialValues={initialValues} onSubmit={submit} enableReinitialize>
                        {({ values, setFieldValue }) => (
                            <Form>
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label'>
                                                Name
                                            </label>
                                            <Field className='form-control' name="name" placeholder="input name.." value={values.name} />
                                            {/* <MetronicErrorMessage name='sap_no' /> */}
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label'>
                                                Capacity
                                            </label>
                                            <InputNumber
                                                defaultValue={initialValues.capacity}
                                                min={0}
                                                name="capacity"
                                                className={"w-100 text-end"}
                                                onChange={(value: any) => {
                                                    handleOnTotalCapacity(value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label'>
                                                Category
                                            </label>
                                            <SelectX
                                                defaultValue={initialValues.category}
                                                onChange={handleCategory}
                                                options={[
                                                    { label: 'Shipping', value: 'Shipping' },
                                                    { label: 'Truck', value: 'Truck' },
                                                ]}
                                            />
                                            {/* <MetronicErrorMessage name='sap_no' /> */}
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label'>
                                                Nomor IMO
                                            </label>
                                            <Field className='form-control' name="imo" placeholder="input imo.." value={values.imo} />
                                        </div>
                                    </div>
                                </div>

                                <div className='d-flex gap-4 mt-5'>
                                    <button type='submit' className='btn btn-primary'>
                                        Save
                                    </button>
                                    <button
                                        type='button'
                                        className='btn btn-secondary'
                                        onClick={() => {
                                            setId('')
                                            setIsShowModal(false)
                                        }}
                                    >
                                        Batal
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>

            <KTCard className='card border mt-5'>
                <KTCardBody >
                    <TableXWrpper
                        title={"Sloc Master Data"}
                        key={formIndex}
                        readonly
                        urlIndex={getEnv("stockview") + `/transportation`}
                        columns={tablecolumns}
                        headerButtons={
                            <>
                                <Link
                                    to="/stockview/masterdata/transportation/create"
                                    className="btn btn-primary"
                                    title="Add Data"
                                >
                                    ADD Data
                                </Link>
                            </>
                        }
                    />
                </KTCardBody>
            </KTCard>
        </>
    )
}

export { TransportationMasterDataStockviewList }
