import { KTSVG } from '../../../../../_metronic/helpers'
import { Button, Col, Row } from 'react-bootstrap'

const ListToolbar = ({ setShowModalCreate, buttonText, setDefaultData, isImport = false, importText, setShowModalImport, callBack }: any) => {
  return (
    <Row>
      <Col>
        <div className='d-flex justify-content-end mb-3' data-kt-materialrequest-table-toolbar='base'>
          {isImport && (
            <Button onClick={() => {
              setShowModalImport(true);
            }}
              style={{ marginRight: 10 }}
              className='btn btn-success btn-sm fw-bold'>
              <KTSVG path='/media/icons/duotune/arrows/arr045.svg' className='svg-icon-2' />
              {importText}
            </Button>
          )}
          <Button onClick={() => {
            setDefaultData({})
            setShowModalCreate(true);
            if(callBack) callBack(buttonText);
          }}
            className='btn btn-skyblue btn-sm fw-bold'>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            {buttonText}
          </Button>
          {/* end::Add materialRequest */}
        </div>
      </Col>
    </Row>
  )
}

export default ListToolbar;
