import React, { FC, lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { useAuth } from "../modules/auth";
import { WithChildren } from "../../_metronic/helpers";
import TopBarProgress from "react-topbar-progress-indicator";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";

const BAMPPage = lazy(() => import('../modules/receiving/admin/penerimaan/bamp/BAMPPage'));
const BAPPPPage = lazy(() => import('../modules/receiving/admin/penerimaan/bapp/BAPPPage'));
const PenerimaanMasukBAPPPPage = lazy(() => import('../modules/receiving/admin/penerimaan-masuk/bapp/BAPPPage'));
const TKDNDispositionBAPPPPage = lazy(() => import('../modules/receiving/admin/tkdn-disposition/bapp/BAPPPage'));
const PurchasingOrderPage = lazy(() => import('../modules/receiving/admin/purchasing-order/PurchasingOrderPage'));
const SESGRPage = lazy(() => import('../modules/receiving/admin/ses-gr/SESGRPage'));


//Vendor
const VendorBAMPPage = lazy(() => import('../modules/receiving/vendor/penerimaan/bamp/BAMPPage'));
const VendorBAPPPPage = lazy(() => import('../modules/receiving/vendor/penerimaan/bapp/BAPPPage'));

const PenerimaanRoutes = () => {
	const { currentUser } = useAuth()
	const isVendor = () => currentUser?.vendor;

	const renderSuspensedView = (View: FC) => {
		return (
			<SuspensedView>
				<View />
			</SuspensedView>
		)
	};
	
	return (
		<Routes>
			{/* Menu Buyer */}
			{!isVendor() && (
				<>
					<Route index element={<Navigate to='/penerimaan/purchasing-order' />} />
					<Route path='bamp/*' element={renderSuspensedView(BAMPPage)} />
					<Route path='bapp/*' element={renderSuspensedView(BAPPPPage)} />
					<Route path='penerimaan-masuk/bapp/*' element={renderSuspensedView(PenerimaanMasukBAPPPPage)} />
					<Route path='tkdn-disposition/bapp/*' element={renderSuspensedView(TKDNDispositionBAPPPPage)} />
					<Route path='purchasing-order/*' element={renderSuspensedView(PurchasingOrderPage)} />
					<Route path='ses-gr/*' element={renderSuspensedView(SESGRPage)} />
				</>
			)}
			{/* Menu Vendor */}
			{isVendor() && (
				<>
					<Route index element={<Navigate to='/penerimaan/bamp' />} />
					<Route path='bamp/*' element={renderSuspensedView(VendorBAMPPage)} />
					<Route path='bapp/*' element={renderSuspensedView(VendorBAPPPPage)} />
				</>
			)}
		</Routes>
	)
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
	const baseColor = getCSSVariableValue('--bs-primary')
	TopBarProgress.config({
		barColors: {
			'0': baseColor,
		},
		barThickness: 1,
		shadowBlur: 5,
	})
	return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export default PenerimaanRoutes
