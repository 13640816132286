import { ID, KTCard, KTCardBody } from '../../../../../../_metronic/helpers'
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import TableXWrpper from "../../../../../components/shared/TableXWrapper";
import { ApiUrl } from '../../../../../config/Api'
import { getEnv } from "../../../../../../helpers/procurex";
import { useAuth } from "../../../../../../app/modules/auth";

const GoodMovementSAPStockviewList = () => {
    const { currentUser } = useAuth();
    const tablecolumns = [
        {
            Header: "POSTING DATE",
            accessor: "posting_date",
            width: 100,
        },
        {
            Header: "COMPANY CODE",
            accessor: "company_code",
            width: 100,
        },
        {
            Header: "MATERIAL NUMBER",
            accessor: "material_number",
            width: 200,
        },
        {
            Header: "MOVEMENT TYPE",
            accessor: "movement_type",
            width: 100,
        },
        {
            Header: "PLANT",
            accessor: "plant",
            width: 200,
        },
        {
            Header: "SLOC",
            accessor: "sloc",
            width: 160,
        },
        {
            Header: "RECEIVING PLANT",
            accessor: "receiving_plant",
            width: 100,
        },
        {
            Header: "QUANTITY (TON)",
            accessor: "quantity",
            width: 100,
        },
        {
            Header: "REFERENCE",
            accessor: "reference",
            width: 120,
        },
    ];
    const [formIndex, setFormIndex] = useState(0);
    const [showModal, setShowModal] = useState(false);

    const handleButtonClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    let company_code = currentUser.company_code;

    return (
        <>
            <KTCard className='card border mt-5'>
                <KTCardBody >
                    <TableXWrpper
                        title={"Good Movement SAP"}
                        key={formIndex}
                        readonly
                        urlIndex={getEnv("stockview") + `/transmovement-sap-list?company_code=${company_code}`}
                        columns={tablecolumns}
                    // headerButtons={
                    //     <>
                    //         <Link
                    //             to="/stockview/transaction/goods-movement/create"
                    //             className="btn btn-primary"
                    //             title="Add Data"
                    //         >
                    //             ADD Data
                    //         </Link>
                    //     </>
                    // }
                    />
                </KTCardBody>
            </KTCard>
        </>
    )
}

export { GoodMovementSAPStockviewList }
