import TableXWrapper from '../../../../../components/shared/TableXWrapper'
import { ApiUrl } from '../../../../../config/Api'

interface Props {
  disabled: any
  header: any
  data: any
}

const AccountAssignmentPO = ({ disabled, header, data }: Props) => {

  const columns = [
    {
      Header: 'Seq',
      accessor: (row: any, index: any) => index + 1,
      width: 50,
      className: 'text-center',
      Cell: ({ value }: { value: number }) => <div className="text-center">{value}</div>,
    },
    {Header: 'Quantity', accessor: 'quantity_format', width: 200},
    {Header: 'Percentage', accessor: 'percentage_format', width: 200},
    {
      Header: 'Cost Center',
      accessor: 'cost_center_description',
      width: 200
    },
    {
      Header: 'G/L Account',
      accessor: 'gl_account_description',
      width: 200
    },
    {Header: 'Commitment', accessor: 'commitment_item', width: 200},
    {Header: 'Funds Center', accessor: 'fund_center', width: 200},
    {Header: 'Funds', accessor: 'fund', width: 200},
  ]

  return (
    <>
      <div className='card border mt-5'>
        <div className='card-body'>
          <TableXWrapper
            readonly
            urlIndex={`${ApiUrl}/api/tender/po-item-account-assignment?filter[purchase_order_item_id]=${data?.id}`}
            columns={columns}
          />
        </div>
      </div>
    </>
  )
}

export default AccountAssignmentPO
