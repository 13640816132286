import { ProposalTenderForm } from "../proposal-tender/ProposalTenderForm";
import React, { useEffect } from "react";
import { useAxiosGet } from "../../../../../hooks/useAxios";
import { getEnv } from "../../../../../helpers/procurex";
import { useParams } from "react-router-dom";

export const PraQualificationVendorDetail = () => {
  const [showRegisterPQ, setShowRegisterPQ] = React.useState(false)
  const [indexKey, setIndexKey] = React.useState(0)


  const [{ doSubmit, isLoading, data }] = useAxiosGet(
    getEnv('tender')+'vendor/pq'
  )

  const {uuid} = useParams();

  useEffect(()=>{
    getDetail()
  },[uuid])

  const getDetail = () => {
    // @ts-ignore
    doSubmit(
      {},
      getEnv('tender')+"/vendor/pq/"+uuid
    ).then(r => {});
  }

  return (
    <div key={indexKey}>
      <ProposalTenderForm
        proposalTender={data}
        onRegisterPQ={()=>setShowRegisterPQ(true)}
        source={"PQ"}
        onReload={()=>getDetail()}
      />


    </div>
  )
}