import React, {FC} from 'react'
import {ThemeModeComponent} from "../../../_metronic/assets/ts/layout";

type IProps = {
    show: boolean,
    size?: number,
    children?: any
}
const LoadingX: FC<IProps> = (props) => {
    const { show, size, children } = props;
    return (
        <div className={"text-center"}>
            {(show && ThemeModeComponent.getMode() === 'dark') && (
                <><img src={'/loading_circle.gif'} width={size??18}/>&nbsp;</>
            )}
            {(show && ThemeModeComponent.getMode() !== 'dark') && (
                <><img src={'/loading_circle_blue.gif'} width={size??18}/>&nbsp;</>
            )}
            {!show && (
              children
            )}
        </div>
    )
}

export default LoadingX;