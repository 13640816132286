import { Outlet, Route, Routes, useLocation, useParams } from "react-router-dom";
import { PageLink, PageTitle } from "../../../../../_metronic/layout/core";
import React, { useEffect, useState } from "react";
import { AanwijzingList } from "./AanwijzingList";
import AanwijzingDetail from "./AanwijzingDetail";
import AanwijzingFromMenu from './AanwijzingFromMenu'

const masterData = {
  title: "Tendering",
  path: "tender",
  isSeparator: false,
  isActive: false
};
const separator = {
  title: "",
  path: "",
  isSeparator: true,
  isActive: false
};
const breadcrumbs: Array<PageLink> = [masterData, separator];

const AanwijzingPage = () => {
  const location = useLocation();
  const [index, setIndex] = useState(0); // Default status, bisa disesuaikan dengan default Anda

  useEffect(() => {
    if (location.pathname) {
      setIndex(index + 1);
    }
  }, [location]);


  return (
    <Routes key={index}>
      <Route element={<Outlet />}>
        <Route index
               element={<><PageTitle breadcrumbs={breadcrumbs}>Aanwijzing</PageTitle><AanwijzingList /></>}></Route>
      </Route>
      <Route element={<Outlet />}>
        <Route path={"/:uuid"} element={
          <>
            <PageTitle breadcrumbs={breadcrumbs}>Aanwijzing Detail</PageTitle>
            <AanwijzingFromMenu />
          </>
        }></Route>
      </Route>

    </Routes>
  );
};

export default AanwijzingPage;