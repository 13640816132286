import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { getEnv, parseNumberToFloat } from "../../../../../../../../helpers/procurex";
import { formatCurrency, toastError } from "../../../../../../../../_metronic/helpers";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useAxiosPost } from "../../../../../../../../hooks/useAxios";

type IProps = {
    showModalEvaluasiOE: boolean
    hideModalEvaluasiOE: Function
    proposalTender: any
}

const EvaluasiOEModal = ({ showModalEvaluasiOE, hideModalEvaluasiOE, proposalTender }: IProps) => {
    console.log('proposalTender', proposalTender)
    const [selectedItems, setSelectedItems] = useState([] as any);
    const [selectAll, setSelectAll] = useState(false);
    const [renderKey, setRenderKey] = useState(0);
    const [items, setItems] = useState<any>([])
    const [evaluasiOEReason, setEvaluasiOEReason] = useState("");
    const [{doSubmit, isLoading}] = useAxiosPost(
        getEnv('tender')+`/negotiation/oeEvaluationItem/${proposalTender?.uuid}`
    );

    const handleOnChecked = (event: any, uuid_pr_item: string) => {
      if (selectedItems.includes(uuid_pr_item)) {
        const tmp = selectedItems?.filter((v: any) => v !== uuid_pr_item);
        setSelectedItems(tmp);
      } else {
        const tmp = selectedItems;
        tmp.push(uuid_pr_item);
        setSelectedItems(tmp);
      }
      setRenderKey(renderKey + 1);
    };

    const handleSelectAll = (event: any) => {
      const isChecked = event.target.checked;
      setSelectAll(isChecked);
      if (isChecked) {
          const allItemUUIDs = items?.map((item: any) => item.uuid_pr_item) || [];
          setSelectedItems(allItemUUIDs);
      } else {
          setSelectedItems([]);
      }
      setRenderKey(renderKey + 1);
    };

    useEffect(() => {
        setItems(proposalTender?.items?.filter((item) => item?.retender === "NONE"))
      }, []);

    const onSubmit = async () => {
        // if(!evaluasiOEReason){
        //     toastError("Alasan Evaluasi OE wajib diisi")
        //     return ;
        // }
        Swal.fire({
          title: "Apakah Anda yakin?",
          text: "Anda akan me-Evaluasi OE item ini",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Ya, Evaluasi OE",
          cancelButtonText: "Batal"
        }).then((result) => {
          if (result.isConfirmed) {
            const payload = {
                pr_item_uuid: selectedItems,
                oe_evaluation_reason: evaluasiOEReason
            }

            doSubmit(payload,undefined, toast).then((res:any)=>{
                hideModalEvaluasiOE()
                window.location.reload()
            })
          }
        });
    };

    return (
        <Modal size={"xl"} show={showModalEvaluasiOE}>
            <Modal.Header onHide={() => hideModalEvaluasiOE()}>
                <h4>Pilih Item Untuk Evaluasi OE</h4>
            </Modal.Header>
            <Modal.Body>
                <div className={"table-responsive"} key={"renderKey"}>
                    <table className={"table table-striped table-header-gray"}>
                        <thead>
                        <tr>
                        <th className={"px-2 border text-center"} style={{ width: 50 }}>
                            <input
                                type={"checkbox"}
                                className={"form-check-input"}
                                checked={selectAll}
                                onChange={handleSelectAll}
                            />
                        </th>
                        <th className={"px-2 border text-center"} style={{ width: 50 }}>No</th>
                        <th className={"px-2 border text-center"}>No PR</th>
                        <th className={"px-2 border text-center"}>Line No</th>
                        <th className={"px-2 border text-center"}>Material Group</th>
                        <th className={"px-2 border"}>Short Text</th>
                        <th className={"px-2 border text-end"}>Quantity</th>
                        <th className={"px-2 border text-center"}>UOM</th>
                        <th className={"px-2 border text-center"}>Currency</th>
                        <th className={"px-2 border text-end"}>Harga Satuan</th>
                        <th className={"px-2 border text-end"}>Total Harga</th>
                        </tr>
                        </thead>
                        <tbody>
                            {items?.map((item: any, index: number) => {
                                return (
                                    <tr key={"renteder-item-" + index}>
                                        <td className={"px-2 border text-center"}>
                                            <input
                                            type={"checkbox"}
                                            className={"form-check-input"}
                                            checked={selectedItems?.includes((item?.uuid_pr_item))}
                                            onChange={(e: any) => {
                                                handleOnChecked(e, item?.uuid_pr_item);
                                            }}
                                            />
                                        </td>
                                        <td className={"px-2 border text-center"}>{index + 1}</td>
                                        <td className={"px-2 border text-center"}>{item?.no_pr}</td>
                                        <td className={"px-2 border text-center"}>{item?.line_no}</td>
                                        <td className={"px-2 border text-center"}>{item?.material_group_id}</td>
                                        <td className={"px-2 border"}>{item?.short_text}</td>
                                        <td className={"px-2 border text-end"}>{parseNumberToFloat(item?.quantity)}</td>
                                        <td className={"px-2 border text-center"}>{item?.uom_id}</td>
                                        <td className={"px-2 border text-center"}>{item?.currency_id}</td>
                                        <td className={"px-2 border text-end"}>{formatCurrency(item?.valuation_price)}</td>
                                        <td className={"px-2 border text-end"}>{formatCurrency(item?.total_value)}</td>
                                    </tr>
                                );
                            })}
                            {items?.length === 0 &&
                                <tr key={"renteder-item"}>
                                    <td className={"px-2 border text-center"} colSpan={11}>No Data!</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                    {items?.length > 0 &&
                        <div className={"form-group mt-4"}>
                            <label>Alasan Evaluasi OE *</label>
                            <textarea className={"form-control"} onChange={(e:any)=>setEvaluasiOEReason(e.target.value)}></textarea>
                        </div>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className={"btn btn-danger"} type={"button"} onClick={() => hideModalEvaluasiOE()}>
                    <i className={"fa fa-times"}></i>
                    Batal
                </button>
                <button
                    className={"btn btn-primary"}
                    type={"button"}
                    onClick={onSubmit}
                    disabled={selectedItems.length === 0}
                >
                    <i className={"fa fa-save "}></i>
                    Submit
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default EvaluasiOEModal;