import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { usePostCreateAanwijzing } from "../hooks/proposalTenderHook";
import { toast } from "react-toastify";

interface IProps {
  onAfterSubmit?: Function;
}

const FormCreateAanwijzing = ({onAfterSubmit}: IProps) => {
  const [isShowModal, setIsShowModal] = React.useState(false);
  const formik = React.useRef(null);
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = usePostCreateAanwijzing();
  const [initialValues, setInitialValues] = useState({
    source: "SAP",
    reference: "",
  });
  const [formIndex, setFormIndex] = useState(0);
  const validationSchema = Yup.object().shape({
    source: Yup.string().required("Nama harus diisi"),
    reference: Yup.string().nullable(),
  });
  const handleSubmit = async (values: any) => {
    doSubmit(values, toast, (res:any) => {
      setFormIndex(formIndex + 1)
      window.location.href = `/tender/admin/aanwijzing/${res?.data?.uuid}`
      // onAfterSubmit && onAfterSubmit()
    }).then(() => {})
  }
  // @ts-ignore
  return (
    <>
      <button type={"button"} className={"btn btn-primary btn-sm"} onClick={() => setIsShowModal(true)}>
        <i className="fa fa-plus"></i>
        Buat Free Aanwijzing
      </button>
      <Formik
        innerRef={formik}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        key={formIndex}
      >
        {({ errors, touched, values, setFieldValue, submitForm }) => (
          <>
            <Form>
              <Modal show={isShowModal} onHide={()=>setIsShowModal(false)}>
                <Modal.Header closeButton>
                  <Modal.Title>Buat Free Aanwijzing</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="form-group mb-4">
                    <label className="form-label">Source*</label>
                    <Field
                      name="source"
                      type="text"
                      className="form-control"
                      disabled={true}
                    />
                    {errors.source && touched.source && (
                      <div className="fv-plugins-message-container">
                        {/*@ts-ignore*/}
                        <div className="fv-help-block">{errors?.source}</div>
                      </div>
                    )}
                  </div>
                  <div className="form-group mb-4">
                    <label className="form-label">Nomor Referensi*</label>
                    <Field
                      name="reference"
                      type="text"
                      className="form-control"
                      disabled={isLoading}
                    />
                    {errors.reference && touched.reference && (
                      <div className="fv-plugins-message-container">
                        {/*@ts-ignore*/}
                        <div className="fv-help-block">{errors?.reference}</div>
                      </div>
                    )}
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button type={"button"} disabled={isLoading} className={"btn btn-danger btn-sm"} onClick={() => setIsShowModal(false)}>
                    <i className="fa fa-times"></i>
                    Cancel
                  </button>
                  <button type={"submit"} disabled={isLoading} className={"btn btn-primary btn-sm"} onClick={submitForm}>
                    {isLoading && <i className="fa fa-spinner fa-spin"></i>}
                    {!isLoading && <i className="fa fa-save"></i>}
                    Submit
                  </button>
                </Modal.Footer>
              </Modal>

            </Form>
          </>
        )}
      </Formik>

    </>
  );
};

export default FormCreateAanwijzing;