import { useState, useEffect, useRef } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';

import {
  ClassicEditor,
  AccessibilityHelp,
  Alignment,
  Autoformat,
  AutoImage,
  AutoLink,
  Autosave,
  BalloonToolbar,
  Base64UploadAdapter,
  BlockQuote,
  Bold,
  CloudServices,
  Code,
  Essentials,
  FindAndReplace,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  GeneralHtmlSupport,
  Heading,
  Highlight,
  HorizontalLine,
  ImageBlock,
  ImageCaption,
  ImageInline,
  ImageInsert,
  ImageInsertViaUrl,
  ImageResize,
  ImageStyle,
  ImageTextAlternative,
  ImageToolbar,
  ImageUpload,
  Indent,
  IndentBlock,
  Italic,
  Link,
  LinkImage,
  List,
  ListProperties,
  PageBreak,
  Paragraph,
  PasteFromOffice,
  RemoveFormat,
  SelectAll,
  SourceEditing,
  SpecialCharacters,
  SpecialCharactersArrows,
  SpecialCharactersCurrency,
  SpecialCharactersEssentials,
  SpecialCharactersLatin,
  SpecialCharactersMathematical,
  SpecialCharactersText,
  Strikethrough,
  Style,
  Subscript,
  Superscript,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  TextTransformation,
  TodoList,
  Underline,
  Undo, DecoupledEditor,
} from 'ckeditor5'
import { ExportPdf, ExportWord, ImportWord, MultiLevelList, PasteFromOfficeEnhanced } from 'ckeditor5-premium-features';

import 'ckeditor5/ckeditor5.css';
import 'ckeditor5-premium-features/ckeditor5-premium-features.css';
const LICENSE_KEY = process.env.REACT_APP_CKEDITOR_LICENSE_KEY;

export default function CkeditorPremium({defaultValue, onChange, value, disabled=false }) {
  const editorContainerRef = useRef(null);
  const editorMenuBarRef = useRef(null);
  const editorToolbarRef = useRef(null);
  const editorRef = useRef(null);
  const editorMinimapRef = useRef(null);
  const [isLayoutReady, setIsLayoutReady] = useState(false);
  const [editor, setEditor] = useState(null);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsLayoutReady(true);

    return () => setIsLayoutReady(false);
  }, []);

  const editorConfig = {
    toolbar: {
      items: [
        'undo',
        'redo',
        '|',
        'sourceEditing',
        '|',
        'heading',
        'style',
        '|',
        'fontSize',
        'fontFamily',
        'fontColor',
        'fontBackgroundColor',
        '|',
        'bold',
        'italic',
        'underline',
        '|',
        'link',
        'insertImage',
        'insertTable',
        'highlight',
        'blockQuote',
        '|',
        'alignment',
        '|',
        'bulletedList',
        'numberedList',
        'multiLevelList',
        'todoList',
        'outdent',
        'indent'
      ],
      shouldNotGroupWhenFull: true
    },
    plugins: [
      AccessibilityHelp,
      Alignment,
      Autoformat,
      AutoImage,
      AutoLink,
      Autosave,
      BalloonToolbar,
      Base64UploadAdapter,
      BlockQuote,
      Bold,
      CloudServices,
      Code,
      Essentials,
      ExportPdf,
      ExportWord,
      FindAndReplace,
      FontBackgroundColor,
      FontColor,
      FontFamily,
      FontSize,
      GeneralHtmlSupport,
      Heading,
      Highlight,
      HorizontalLine,
      ImageBlock,
      ImageCaption,
      ImageInline,
      ImageInsert,
      ImageInsertViaUrl,
      ImageResize,
      ImageStyle,
      ImageTextAlternative,
      ImageToolbar,
      ImageUpload,
      ImportWord,
      Indent,
      IndentBlock,
      Italic,
      Link,
      LinkImage,
      List,
      ListProperties,
      MultiLevelList,
      PageBreak,
      Paragraph,
      PasteFromOffice,
      PasteFromOfficeEnhanced,
      RemoveFormat,
      SelectAll,
      SourceEditing,
      SpecialCharacters,
      SpecialCharactersArrows,
      SpecialCharactersCurrency,
      SpecialCharactersEssentials,
      SpecialCharactersLatin,
      SpecialCharactersMathematical,
      SpecialCharactersText,
      Strikethrough,
      Style,
      Subscript,
      Superscript,
      Table,
      TableCaption,
      TableCellProperties,
      TableColumnResize,
      TableProperties,
      TableToolbar,
      TextTransformation,
      TodoList,
      Underline,
      Undo
    ],
    balloonToolbar: ['bold', 'italic', '|', 'link', 'insertImage', '|', 'bulletedList', 'numberedList'],
    exportPdf: {
      stylesheets: [
        /* This path should point to application stylesheets. */
        /* See: https://ckeditor.com/docs/ckeditor5/latest/features/converters/export-pdf.html */
        './App.css',
        /* Export PDF needs access to stylesheets that style the content. */
        'https://cdn.ckeditor.com/ckeditor5/43.0.0/ckeditor5.css',
        'https://cdn.ckeditor.com/ckeditor5-premium-features/43.0.0/ckeditor5-premium-features.css'
      ],
      fileName: 'export-pdf-demo.pdf',
      converterOptions: {
        format: 'A4',
        margin_top: '5mm',
        margin_bottom: '5mm',
        margin_right: '10mm',
        margin_left: '10mm',
        page_orientation: 'portrait'
      }
    },
    exportWord: {
      stylesheets: [
        /* This path should point to application stylesheets. */
        /* See: https://ckeditor.com/docs/ckeditor5/latest/features/converters/export-word.html */
        './App.css',
        /* Export Word needs access to stylesheets that style the content. */
        'https://cdn.ckeditor.com/ckeditor5/43.0.0/ckeditor5.css',
        'https://cdn.ckeditor.com/ckeditor5-premium-features/43.0.0/ckeditor5-premium-features.css'
      ],
      fileName: 'export-word-demo.docx',
      converterOptions: {
        document: {
          orientation: 'portrait',
          size: 'Tabloid',
          margins: {
            top: '20mm',
            bottom: '20mm',
            right: '24mm',
            left: '24mm'
          }
        }
      }
    },
    fontFamily: {
      supportAllValues: true
    },
    fontSize: {
      options: [10, 12, 14, 'default', 18, 20, 22],
      supportAllValues: true
    },
    heading: {
      options: [
        {
          model: 'paragraph',
          title: 'Paragraph',
          class: 'ck-heading_paragraph'
        },
        {
          model: 'heading1',
          view: 'h1',
          title: 'Heading 1',
          class: 'ck-heading_heading1'
        },
        {
          model: 'heading2',
          view: 'h2',
          title: 'Heading 2',
          class: 'ck-heading_heading2'
        },
        {
          model: 'heading3',
          view: 'h3',
          title: 'Heading 3',
          class: 'ck-heading_heading3'
        },
        {
          model: 'heading4',
          view: 'h4',
          title: 'Heading 4',
          class: 'ck-heading_heading4'
        },
        {
          model: 'heading5',
          view: 'h5',
          title: 'Heading 5',
          class: 'ck-heading_heading5'
        },
        {
          model: 'heading6',
          view: 'h6',
          title: 'Heading 6',
          class: 'ck-heading_heading6'
        }
      ]
    },
    htmlSupport: {
      allow: [
        {
          name: /^.*$/,
          styles: true,
          attributes: true,
          classes: true
        }
      ]
    },
    image: {
      toolbar: [
        'toggleImageCaption',
        'imageTextAlternative',
        '|',
        'imageStyle:inline',
        'imageStyle:wrapText',
        'imageStyle:breakText',
        '|',
        'resizeImage'
      ]
    },
    initialData: value,
    licenseKey: LICENSE_KEY,
    link: {
      addTargetToExternalLinks: true,
      defaultProtocol: 'https://',
      decorators: {
        toggleDownloadable: {
          mode: 'manual',
          label: 'Downloadable',
          attributes: {
            download: 'file'
          }
        }
      }
    },
    list: {
      properties: {
        styles: true,
        startIndex: true,
        reversed: true
      }
    },
    menuBar: {
      isVisible: true
    },
    minimap: {
      container: editorMinimapRef.current,
      extraClasses: 'editor-container_include-minimap ck-minimap__iframe-content'
    },
    placeholder: 'Type or paste your content here!',
    table: {
      contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
    }
  };

  useEffect(() => {
    if (editor && value !== undefined && !isMounted) {
      editor.setData(value);
      setIsMounted(true);
    }
  }, [editor, defaultValue]);

  // useEffect(() => {
  //   if(!editor?.plugins) return ;
  //   editor.plugins.get('ExportPdf').execute().then((data:any) => {
  //     console.log('PDF export successful:', data);
  //   })
  // }, [editor?.plugins])



  return (
    <div>
      <div className="main-container">
        <div className="editor-container editor-container_document-editor editor-container_include-minimap" ref={editorContainerRef}>
          <div className="editor-container__menu-bar" ref={editorMenuBarRef}></div>
          <div className="editor-container__toolbar" ref={editorToolbarRef}></div>
          <div className="editor-container__minimap-wrapper">
            <div className="editor-container__editor-wrapper">
              <div className="editor-container__editor">
                <div ref={editorRef} >
                  {isLayoutReady && (
                    <CKEditor
                      onReady={editor => {
                        editorToolbarRef.current.appendChild(editor.ui.view.toolbar.element);
                        editorMenuBarRef.current.appendChild(editor.ui.view.menuBarView.element);
                        setEditor(editor);
                      }}
                      onAfterDestroy={() => {
                        //@ts-ignore
                        Array.from(editorToolbarRef.current.children).forEach(child => child?.remove());
                        //@ts-ignore
                        Array.from(editorMenuBarRef.current.children).forEach(child => child?.remove());
                      }}
                      onChange={(event, editor) => {
                        onChange(editor.getData() as string);
                      }}
                      editor={DecoupledEditor}
                      //@ts-ignore
                      config={editorConfig}
                      disabled={disabled}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="editor-container__sidebar editor-container__sidebar_minimap">
              <div ref={editorMinimapRef}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
