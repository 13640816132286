import { getEnv, parseNumberToCurrency } from "../../../../../../../../helpers/procurex";
import React, { useEffect, useState } from "react";
import TableXWrapper from "../../../../../../../components/shared/TableXWrapper";
import { useAxiosGet, useAxiosPost } from "../../../../../../../../hooks/useAxios";
import { toast } from "react-toastify";
import PreviewAttachment from "../../../../../../../components/PreviewAttachment";
import { useIntl } from "react-intl";
import axios from "axios";
import EvaluasiAdministrasi from "../../EvaluasiAdministrasi";
import EvaluasiTeknis from "../../EvaluasiTeknis";

const StatusBadgePassedFailed = ({ status }: any) => {
  if(status === "PASSED") {
    return (
      <span className={`badge badge-success text-white`}>
      {status}
    </span>
    )
  } else if(status === "FAILED") {
    return (
      <span className={`badge badge-danger text-white`}>
        {status}
      </span>
    )
  }
  return (
    <span className={`badge badge-warning text-dark`}>
        {status}
      </span>
  )
}


const InformasiRekanan = ({ quotation, proposalTender }: any) => {
  const [expiredAt, setExpiredAt] = useState<any>("");
  const [bidBondAttachment,setBidBondAttachment] = useState(null)

  const getExpiredAt = () => {
    const date = new Date(quotation?.date);
    date.setDate(date.getDate() + quotation?.validity);
    const expiredAt = date.toISOString().slice(0,10);
    setExpiredAt(expiredAt)
  }

  useEffect(() => {
    getExpiredAt();
  }, [quotation]);

  return (
    <>
      <div className={"row"}>
        <div className={"col-sm-12 col-md-6"}>
          <div className={"form-group mb-3"}>
            <label htmlFor="">Nama Rekanan</label>
            <input type="text" className={"form-control form-control-sm"} value={quotation?.vendor?.vendor?.name} disabled={true} />
          </div>
          <div className={"form-group mb-3"}>
            <label htmlFor="">Nomor Penawaran</label>
            <input type="text" className={"form-control form-control-sm"} value={quotation?.quotation_number} disabled={true} />
          </div>
          <div className={"form-group mb-3"}>
            <label htmlFor="">Tanggal Penawaran</label>
            <input type="text" className={"form-control form-control-sm"} value={quotation?.date} disabled={true} />
          </div>
          <div className={"form-group mb-3"}>
            <label htmlFor="">Berlaku Hingga Tanggal</label>
            <input type="text" className={"form-control form-control-sm"} value={expiredAt} disabled={true} />
          </div>
          <div className={"form-group mb-3"}>
            <label htmlFor="">LMasa Berlaku Penawaran</label>
            <div className={"input-group"}>
              <input type="text" className={"form-control form-control-sm"} value={quotation?.validity} disabled={true} />
              <div className="input-group-append">
                <span className="input-group-text">Hari</span>
              </div>
            </div>
          </div>
        </div>
        <div className={"col-sm-12 col-md-6"}>
          <div className={"row mb-3"}>
            <div className={"col-md-4"}>
              <div className={"form-group mb-3"}>
                <label htmlFor="">Incoterm</label>
                <input type="text" className={"form-control form-control-sm"} value={quotation?.incoterm?.code} disabled={true} />
              </div>
            </div>
            <div className={"col-md-8"}>
              <div className={"form-group mb-3"}>
                <label htmlFor="">Deskripsi Incoterm</label>
                <input type="text" className={"form-control form-control-sm"} value={quotation?.incoterm?.name} disabled={true} />
              </div>
            </div>
          </div>
          <div className={"form-group mb-3"}>
            <label htmlFor="">Nilai Jaminan Penawaran</label>
            <input type="text" className={"form-control  form-control-sm"}
                   value={parseNumberToCurrency(quotation?.bid_bond_value)} disabled={true} />
            <div className="input-group-append">
              <button
                className={"btn btn-primary btn-sm"}
                type={"button"}
                onClick={() => {
                  setBidBondAttachment(quotation?.bid_bond_media_url);
                }}
              >
                <i className={"fa fa-eye"}></i>
              </button>
            </div>
            <PreviewAttachment previewExt={bidBondAttachment} onHide={() => {
              setBidBondAttachment(null);
            }} />
          </div>
          <div className={"form-group mb-3"}>
            <label htmlFor="">Jaminan Penawaran Berlaku Hingga</label>
            <input type="text" className={"form-control form-control-sm"} value={quotation?.bid_bond_masa_berlaku}
                   disabled={true} />
          </div>
          <div className={"form-group mb-3"}>
            <label htmlFor="">Additional Cost</label>
            <input type="text" className={"form-control form-control-sm"} value={""} disabled={true} />
          </div>
        </div>
      </div>
    </>
  );
};

const DetailPenawaran = ({ proposalTender, quotation, onBack }: any) => {
  const [formIndex, setFormIndex] = useState(0);
  const [{doSubmit: doSubmitPost, isLoading: isLoadingPost}] =  useAxiosPost("");
  const [evaluationData, setEvaluationData] = useState(null as any);
  const [isDisabled, setIsDisabled] = useState(false);
  const intl = useIntl();
  const handleOnChangeEvaluasiKomersil =  () => {
    setFormIndex(formIndex + 1);
  }

  const checkIsDisabled =  () => {
    const allowedStatus = ["Evaluasi Administrasi", "Evaluasi Teknikal", "Evaluasi Komersil"];
    setIsDisabled(!allowedStatus.includes(proposalTender?.status));
  };

  const onChangeEvaluationData = async (field: string, value: any) => {
    setEvaluationData({ ...evaluationData, [field]: value });
  };

  useEffect(() => {
    checkIsDisabled();
  }, [proposalTender]);

  return (
    <>
      <div className={"row"}>
        <div className={"col-md-6 mb-5"}>
          <button className={"btn btn-sm btn-danger"} type={"button"} onClick={() => onBack()}>
            <i className={"fa fa-arrow-left"}></i> Kembali
          </button>
        </div>
        <div className={"col-md-6"}>
        </div>
        <div className={"col-md-12"}>
          <InformasiRekanan proposalTender={proposalTender} quotation={quotation} />
          <hr />
          <EvaluasiAdministrasi
            proposalTender={proposalTender}
            quotation={quotation}
            onChangeEvaluationData={onChangeEvaluationData}
          />
          <EvaluasiTeknis
            proposalTender={proposalTender}
            quotation={quotation}
            onChange={() => handleOnChangeEvaluasiKomersil()}
            onChangeEvaluationData={onChangeEvaluationData}
          />
        </div>
      </div>
    </>
  );
};

const AdministrasiTeknisDuaSampulTab = ({ proposalTender, onQuotationChanged }: any) => {
  const urlIndex = getEnv("tender") + `/evaluation/quotation/${proposalTender.uuid}`;
  const [selectedRows, setSelectedRows] = useState(null);
  const getStatusEvaluation = (row:any) => {
    if(row?.status_evaluation_technical === "PASSED" && row?.status_evaluation_administration === "PASSED"){
      return "PASSED"
    }
    return "FAILED";
  }
  const tableColumns = [
    {
      Header: "#",
      accessor: "line_no",
      width: 100,
      className: "text-center",
      Cell: (props: any) => {
        return (
          <>
            <a href={"#"} title={"Lihat Penawaran"} onClick={(e: any) => {
              e.preventDefault();
              setSelectedRows(props.row.original);
            }} className={"btn btn-primary btn-sm"}>
              <i className={"fa fa-eye"}></i>
            </a>
          </>
        );
      }
    },
    { Header: "Vendor ID", accessor: "vendor_id", width: 100, className: "text-start", Cell: (props: any) => {
        return (
          <>
            {props.row.original.vendor_id}
          </>
        )
      }
    },
    { Header: "Nama Vendor", accessor: "vendor.vendor.name", width: 300, className: "text-start"},
    { Header: "Status", accessor: "vendor.status_evaluation_administration", width: 150, className: "text-center", Cell: (props: any) => {
        return (
          <>
            <><StatusBadgePassedFailed status={getStatusEvaluation(props?.row?.original?.vendor)} /></>
          </>
        )
      }
    },
    { Header: "Quotation Terakhir", accessor: "updated_at", className: "text-center", width: 200 },

  ];

  const getEvaluationTechnicalStat = (row:any) => {
    if(row?.status_evaluation_technical === "PASSED" && row?.status_evaluation_administration === "PASSED"){
      return "PASSED"
    }
    return "FAILED";
  }

  useEffect(() => {
      // onQuotationChanged(selectedRows)
  }, [selectedRows])

  return (
    <>
      {selectedRows && (
        <DetailPenawaran
          proposalTender={proposalTender}
          quotation={selectedRows}
          onBack={() => {
            setSelectedRows(null);
          }}
        />
      )}
      {!selectedRows && (
        <TableXWrapper
          readonly={true}
          title={"Proposal Tender"}
          urlIndex={urlIndex}
          columns={tableColumns}
          isForceCreateButton={true}
          disableUrlChanged={true}
        />
      )}

    </>
  );
};

export {
  AdministrasiTeknisDuaSampulTab,
};