import { NavLink, Outlet, Route, Routes } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import React, { useState } from 'react'
import { useIntl } from 'react-intl';
import moment from 'moment';
import { KTCard } from '../../../../_metronic/helpers';
import TableXWrapper from '../../../components/shared/TableXWrapper';


const OverduePage = () => {
    const urlIndex = process.env.REACT_APP_API_URL + "/api/mr-sr/mr-sr?filter[source]=monitoring&include=current_users,no_pr";
    const intl = useIntl();
    let [key, setKey] = useState(0);
    const [selectedRows, setSelectedRows] = useState([]);
    const tableColumns = [
        {
            Header: "Order Date",
            accessor: "",
            width: 100,
        },
        { Header: "Invoice Date", accessor: "", width: 50, disableCreate: true, disableEdit: true, className: "text-center" },
        { Header: "Due Date", accessor: "", width: 150 },
        { Header: "PO Number", accessor: "", width: 150 },
        { Header: "Project Number", accessor: "", width: 150 },
        { Header: "Project Name", accessor: "", width: 150 },
        { Header: "Project Category", accessor: "", width: 150 },
        { Header: "Total Project", accessor: "", width: 150 },
    ]
    return (
        <>
            <KTCard>
                <TableXWrapper
                    key={key}
                    readonly
                    urlIndex={urlIndex}
                    columns={tableColumns}
                    onSelectedRows={(e: any) => {
                        setSelectedRows(e);
                    }
                    }
                    defaultFilter={"sort=-updated_at"}
                />
            </KTCard>
        </>
    )
}

export default OverduePage
