import React, { FC, useState } from "react";
import { DatePicker } from 'rsuite';

type Props = {
	onChange: any,
	value?: any,
	focus?: boolean,
	readonly?: boolean
	size?: any
	format?: string
}

const DatepickerX2: FC<Props> = (props) => {
	const { onChange } = props;
	const [startDate, setStartDate] = useState(new Date());

	const handleChangeDate = (date: any) => {
		if (!date) {
			onChange(null)
			return;
		}
		setStartDate(date);
		const newDate = new Date(date)
		const _day = newDate.getDate() < 10 ? `0${newDate.getDate()}` : newDate.getDate();
		const _month = (newDate.getMonth() + 1) < 10 ? `0${newDate.getMonth() + 1}` : newDate.getMonth() + 1;
		const _year = newDate.getFullYear();

		let _formatted = `${_year}-${_month}-${_day}`
		if (props.format && (props.format.includes('hh') || props.format.includes('HH'))) {
			const _hour = newDate.getHours() < 10 ? `0${newDate.getHours()}` : newDate.getHours();
			const _minute = newDate.getMinutes() < 10 ? `0${newDate.getMinutes()}` : newDate.getMinutes();
			const _second = newDate.getSeconds() < 10 ? `0${newDate.getSeconds()}` : newDate.getSeconds();
			_formatted = `${_formatted} ${_hour}:${_minute}:${_second}`
		}

		onChange(_formatted)
	}

	const getValue = () => {
		if (!props.value) {
			return null;
		}
		const _date = new Date(props.value);
		return _date;
	}

	return (
		<div>
			<DatePicker
				size={props.size || "md"}
				style={{ width: '100%', }}
				onChange={handleChangeDate}
				format={props.format || "yyyy-MM-dd"}
				value={getValue()}
				readOnly={props.readonly}
				// disabled={props.readonly}
				className={"text-dark"}
			/>
		</div>
	);
};

export default DatepickerX2;