import { Field, Form, Formik } from "formik";
import { Col, Row, Spinner } from "react-bootstrap";
import FormikSelect2 from "./FormikSelect2";
import React, { useRef, useState } from "react";
import axios from "axios";
import { getEnv } from "../../../../helpers/procurex";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useUploadMedia } from "../../../../hooks/MediaUploadHook";
import PreviewAttachment from "../../PreviewAttachment";
import { useIntl } from "react-intl";

const API_URL = process.env.REACT_APP_API_URL
interface IProps{
  media_type_filter: string
  isDisabled?: boolean
  afterSubmit?: Function
  additionalField?: any
  isLoading?: boolean
  onlyFile?: boolean
  isHideLabel?: boolean
  isSmall?: boolean
  isRequired?: boolean
  defaultValue?: any
  previewUrl?: any
  withPreviewButton?: boolean
  overWriteErrMessage?: boolean
}
const FormAttachment = ({media_type_filter, isDisabled, isLoading, afterSubmit, additionalField, onlyFile, isHideLabel, isSmall, isRequired, defaultValue, previewUrl, withPreviewButton, overWriteErrMessage}: IProps) => {
  const [initialValues, setInitialValues] = useState({
    media_url: null,
    media_type: null,
    description: '',
  })
  const validationSchema = Yup.object({
    description: Yup.string().required(),
    media_type: Yup.object().required(),
    media_url: Yup.string().nullable().required('The field is required'),
  })
  const intl = useIntl()
  const [formikIndex,setFormikIndex] = useState(1)
  const [previewAttachment,setPreviewAttachment] = useState(null)
  const [defaultPreviewUrl,setDefaultPreviewUrl] = useState(previewUrl)
  const formik = useRef({} as any)
  const [isLoadingAttachment,setIsLoading] = React.useState(false)
  let [file, setFile] = useState({})
  const {upload} = useUploadMedia()
  const handleSubmit = async (val: any, {resetForm, setFieldError}: any) => {
    let data = {...val}
    setIsLoading(true)
    try {
      //@ts-ignore
      let responseFile = await upload(onlyFile ? 1 : data.media_type.id, file, file?.name)
      if (axios.isAxiosError(responseFile)) {
        if (
          responseFile.response &&
          responseFile.response.status === 422 &&
          responseFile.response.data?.errors?.file[0]
        ) {
          setFieldError('media_url', responseFile.response.data?.errors?.file[0])
        }
        return
      }
      responseFile.description = data.description
      if(!onlyFile) {
        setFormikIndex(formikIndex + 1)
      }
      // setDefaultPreviewUrl(responseFile.data?.data?.url)
      toast.success("Successfully save attachment")
      afterSubmit && afterSubmit(responseFile)

    } catch (error: any) {
      console.error(error)
      window.scrollTo(0, 0)
      toast.error(error.response?.data?.message || 'Terjadi kesalahan', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
      return false
    } finally {
      setIsLoading(false)
    }
  }

  const fileOnChange = async (e: any) => {
    let file = e.target.files[0]
    if (file) {
      if (formik.current) formik.current.setFieldValue('media_url', file.name)
      setFile(file)
    }
    if(onlyFile === true) {
      try {
        setIsLoading(true)
        let responseFile = await upload("1", file,null)
        setDefaultPreviewUrl(responseFile?.url)
        responseFile.description = "-"
        afterSubmit && afterSubmit(responseFile)

      } catch (error: any) {
        console.error(error)
        window.scrollTo(0, 0)
        toast.error(error.response?.data?.message || 'Terjadi kesalahan', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
        return false
      } finally {
        setIsLoading(false)
      }
    }
  }

  return (
    <Formik
      key={formikIndex}
      innerRef={formik}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({errors, touched, values, setFieldValue}) => (
        <>
          <Form>
            <Row>
              <Col sm='12'>
                {additionalField}
              </Col>
              {onlyFile !== true && (
                <Col sm='6'>
                  <div className='mb-10'>
                    <FormikSelect2
                      label={intl.formatMessage({id: 'Jenis Lampiran'})}
                      name='media_type'
                      optionsUrl={
                        API_URL + `/api/media/media-type?filter[type]=${media_type_filter}`
                      }
                      getOptionValue={(opt: any) => opt}
                      getOptionLabel={(opt: any) =>
                        `${opt.properties?.description || opt.name}`
                      }
                      unique_property={'id'}
                      isDisabled={isDisabled}
                    />
                  </div>
                  <div className='mb-10'>
                    <label className='form-label required'>{intl.formatMessage({id:"Deskripsi"})}</label>
                    <Field
                      name='description'
                      type='text'
                      className={isSmall ? 'form-control form-control-sm' : 'form-control'}
                      disabled={isDisabled}
                    />
                    {touched.description && errors.description && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          {/* @ts-ignore*/}
                          <span role='alert'>{errors.description}</span>
                        </div>
                      </div>
                    )}
                  </div>


                </Col>
              )}
              <Col sm={onlyFile === true ? 12 : 6}>
                <div className={overWriteErrMessage ? '' : !isHideLabel ? 'mb-10' : ''}>
                  {!isHideLabel && (
                    <label className={`form-label ${isRequired===false?'':'required'}`}>{intl.formatMessage({id:"File"})}</label>
                  )}
                  <div className={"d-flex flex-row align-items-center position-relative input-group "+(isSmall?'input-group-sm':'')}>
                    {(isLoading || isLoadingAttachment) && (
                      <>
                        <div className="background-overlay"></div>
                        <i className={"fa fa-spinner fa-spin position-absolute center-spinner"}></i>
                      </>
                    )}

                    <input
                      onChange={fileOnChange}
                      name='media_url'
                      type='file'
                      className={isSmall ? 'form-control form-control-sm' : 'form-control'}
                      disabled={isDisabled || isLoadingAttachment}
                    />

                  </div>

                  {defaultValue?.url && (
                    <a href={"#"} className={"text-dark"} onClick={(e:any)=>{
                      e.preventDefault()
                      setPreviewAttachment(defaultValue?.url)
                    }}>
                      <i className={"fa fa-link text-dark"}></i> Preview

                    </a>
                  )}

                  {(withPreviewButton && defaultPreviewUrl) && (
                    <a href={"#"} className={"text-dark text-start"} onClick={(e:any)=>{
                      e.preventDefault()
                      setPreviewAttachment(defaultPreviewUrl)
                    }}>
                      <i className={"fa fa-link text-dark"}></i> Preview

                    </a>
                  )}

                  {!overWriteErrMessage &&
                    <>
                      {touched.media_url && errors.media_url && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            {/* @ts-ignore*/}
                            <span role='alert'>{errors.media_url}</span>
                          </div>
                        </div>
                      )}
                    </>
                  }
                </div>
                {onlyFile !== true && (
                  <div className='d-flex gap-4 justify-content-end mb-5 pt-10'>
                    <button type='submit' className='btn btn-primary btn-sm' disabled={
                      isLoadingAttachment || isLoading || isDisabled
                    }>
                      {(isLoadingAttachment || isLoading) && <span className='spinner-border spinner-border-sm me-1' />}
                      <i className='fa fa-save' />
                      {intl.formatMessage({id:"Submit"})}
                    </button>
                  </div>
                )}
              </Col>
            </Row>
          </Form>
          <PreviewAttachment previewExt={previewAttachment} onHide={()=>setPreviewAttachment(null)} />
        </>
      )}
    </Formik>
  )
}

export default FormAttachment