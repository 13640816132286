import {useIntl} from 'react-intl'
import React, {useEffect, useState} from 'react'
import {ButtonGroup} from 'react-bootstrap'
import axios from 'axios'
import {useMutation} from 'react-query'
import Swal from 'sweetalert2'
import {getEnv} from '../../../../../../helpers/procurex'
import FormAttachment from '../../../../../components/shared/form/FormAttachment'
import TableXWrapper from '../../../../../components/shared/TableXWrapper'
import PreviewAttachment from '../../../../../components/PreviewAttachment'

const fetchUploadAanwijzingAttachment = (payload:any) => {
  return axios.post(`${getEnv('tender')}/aanwijzing-attachment`,payload)
}
const fetchDeleteAttachment = (uuid:string) => {
  return axios.delete(`${getEnv('tender')}/aanwijzing-attachment/${uuid}`)
}
const DokumenKlarifikasiComponent = ({proposalTender,aanwijzing}:any) => {
  const intl = useIntl()
  const [key, setKey] = useState(0)
  const [urlIndex, setUrlIndex] = useState(getEnv('tender') + '/aanwijzing-attachment')
  const [isFormEnabled, setIsFormEnabled] = useState(false)
  const [preview, setPreview] = useState(null)

  const checkProposalTender =() => {
    const allowedStatusTender = [
      'Draft', 'Pra Qualification', 'DUR','Published','Aanwijzing','Registrasi','Penawaran','Penawaran Teknikal','Penawaran Komersil'
    ];
    let isAllowed = true;
    if(proposalTender){
      isAllowed = allowedStatusTender.includes(proposalTender.status)
      if(aanwijzing?.status === "LIVE") isAllowed = true;
      else isAllowed = false;
    } else {
      if(aanwijzing?.status === "LIVE") isAllowed = true;
    }
    setIsFormEnabled(isAllowed)
  }

  const tableColumns = [
    {
      Header: intl.formatMessage({id: 'Aksi'}),
      accessor: 'media_url',
      className: 'text-center',
      width: 150,
      Cell: (props: any) => (
        <>
          <ButtonGroup size={'sm'} aria-label='Action'>
            {/*{(props.cell.row.original.action.delete &&*/}
            {/*  (headerData?.status === 'draft' || headerData?.status === 'rejected' || headerData?.status === 'planner_rejected')*/}
            {/*) && (*/}
            {/*)}*/}

            <button
              type='button'
              className='btn btn-sm btn-secondary'
              onClick={() => {
                setPreview(props.cell.row.values.media_url)
              }}
            >
              <i className={'fa fa-eye'} />
            </button>
          </ButtonGroup>
        </>
      ),
    },
    {
      Header: intl.formatMessage({id: 'Jenis Lampiran'}),
      accessor: 'media_type.name',
      name: 'media_type',
      width: 250,
    },
    {Header: intl.formatMessage({id: 'Deskripsi'}), accessor: 'description', width: 400},
  ]

  //region store data
  const onSuccessStore = (data:any) => {
    setKey(key + 1)
  }
  const onErrorStore = (error:any) => {}
  const {mutate:mutateStore, isLoading:isLoadingStore} = useMutation("store-aanwijzing-attachment",(payload)=>fetchUploadAanwijzingAttachment(payload),{
    onSuccess: onSuccessStore,
    onError: onErrorStore
  })
  const onAfterUpload = (data:any) => {
    const payload = {
      aanwijzing_id: aanwijzing?.uuid,
      type: data?.type?.name,
      description: data.description,
      media_type: data.type,
      media_url: data.url,
    }
    // @ts-ignore
    mutateStore(payload)
  }
  //endregion

  //region delete data
  const onSuccessDelete = (data:any)=>{
    setKey(key+1)
  }
  const onErrorDelete = (error:any)=>{}
  const {isLoading:isLoadingDelete, mutate:mutateDelete} = useMutation("delete-aanwijzing-attachment",(uuid:string)=>fetchDeleteAttachment(uuid),{
    onSuccess: onSuccessStore,
    onError: onErrorStore
  })

  //endregion

  useEffect(() => {
    checkProposalTender()
  }, [proposalTender,aanwijzing])

  return (
    <>

      {/*<FormAttachment*/}
      {/*  media_type_filter={"AANWIJZING_ATTACHMENT"}*/}
      {/*  isDisabled={!isFormEnabled}*/}
      {/*  afterSubmit={onAfterUpload}*/}
      {/*  isLoading={isLoadingStore||isLoadingDelete}*/}
      {/*/>*/}
      {/*<hr />*/}
      <TableXWrapper
        readonly
        key={'table-' + key}
        title={'Test'}
        urlIndex={urlIndex}
        columns={tableColumns}
        defaultFilter={`filter[aanwijzing_id]=${aanwijzing?.id}`}
        disableUrlChanged={true}
        disablePaddingCard={true}
      />
      <PreviewAttachment previewExt={preview} onHide={()=>setPreview(null)} />
    </>
  )
}

export default DokumenKlarifikasiComponent;