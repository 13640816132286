import { Outlet, Route, Routes } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../../_metronic/layout/core'
import React, { useState, useEffect } from 'react'
import {
    VendorMasterDataStockviewList,
} from './components/VendorMasterDataStockviewList'

const masterData = {
    title: 'Vendor',
    path: '/stockview/masterdata/vendor',
    isSeparator: false,
    isActive: false,
}
const separator = {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
}
const breadcrumbs: Array<PageLink> = [masterData, separator]
const childBreadcrumbs: Array<PageLink> = [
    masterData,
    separator,
    {
        title: 'Master Data Vendor',
        path: '/stockview/masterdata/vendor',
        isSeparator: false,
        isActive: false,
    },
    separator,
]

const VendorMasterDataStockview = () => {
    // @ts-ignore

    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    index
                    element={
                        <>
                            <PageTitle breadcrumbs={breadcrumbs}>Master Data Vendor</PageTitle>
                            <VendorMasterDataStockviewList />
                        </>
                    }
                ></Route>
            </Route>
        </Routes>
    )
}

export default VendorMasterDataStockview
