import {toastError} from '../../../../_metronic/helpers'

const downloadFile = async (url: any) => {
  fetch(url)
    .then(async response => {
      const contentDisposition = response.headers.get('Content-Disposition')
      const parts = contentDisposition!.split(';')
      const filename = parts[1].split('=')[1].replaceAll('"', '')
      const blob = await response.blob()

      return ({blob, filename})
    })
    .then(({blob, filename}) => {
      const url = window.URL.createObjectURL(new Blob([blob]))
      const link = document.createElement('a')

      link.href = url
      link.setAttribute('download', filename)

      if (document.body) {
        document.body.appendChild(link)
        link.click()
        link.parentNode?.removeChild(link)
      }
    })
    .catch((error) => {
      console.error('Gagal mengunduh file:', error)
      toastError('Gagal mengunduh file')
    })
}

export {downloadFile}
