import { useEffect, useState } from 'react'
import { Formik, Form, Field } from 'formik'
import { Col, Modal, Row } from 'react-bootstrap'

import AccountAssignmentPO from './AccountAssignmentPO'
import AdditionalCostPO from './AdditionalCostPO'
import DetailItemPO from './DetailItemPO'
import * as Yup from 'yup'
import Tabs from './Tabs'
import { ApiUrl } from '../../../../../config/Api'
import axios from 'axios'
import { useAuth } from '../../../../auth'

interface Props {
  show: boolean
  handleClose: () => void
  data: any
  header: any
}

const DetailFormItemModal = ({
  show,
  handleClose,
  data,
  header,
}: Props) => {
  const {currentUser} = useAuth()
  const validationSchema = Yup.object({})
  const [currentTab, setCurrentTab] = useState<any>(0)
  const [initialValues, setInitialValues] = useState({})

  let tabs: any
  if (!currentUser?.has_roles?.includes('vendor')) {
    tabs = [
      {
        value: 0,
        text: 'Detail Item',
        disabled: false
      },
      {
        value: 1,
        text: 'Additional Cost',
        disabled: false
      },
      {
        value: 2,
        text: 'Account Assignment',
        disabled: false
      },
    ]
  } else {
    tabs = [
      {
        value: 0,
        text: 'Detail Item',
        disabled: false
      },
      {
        value: 1,
        text: 'Additional Cost',
        disabled: false
      },
    ]
  }

  const handleSubmit = async (values: any) => {}

  return (
    <>
      <Modal size='xl' show={show} onHide={handleClose} centered>
        <Modal.Header closeButton={true}>
          <Modal.Title>Detail Item {data?.no_pr}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ errors, touched, values, setFieldValue }) => (
              <>
                <Form>
                  <Tabs currentTab={currentTab} setCurrentTab={setCurrentTab} tabs={tabs} />

                  {!currentUser?.has_roles?.includes('vendor') &&
                    <div className='card border'>
                      <div className='card-body'>
                        <Row>
                          <Col lg={6}>
                            <div className='mb-7'>
                              <label htmlFor='exampleFormControlInput1' className='form-label'>
                                <span>No. PR</span>
                              </label>
                              <Field
                                disabled
                                name='no_pr'
                                value={data?.no_pr}
                                type='text'
                                className='form-control'
                                placeholder=''
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className='mb-7'>
                              <label htmlFor='exampleFormControlInput1' className='form-label'>
                                <span>Item No.</span>
                              </label>
                              <Field
                                disabled
                                name='line_no'
                                value={data?.line_no}
                                type='text'
                                className='form-control'
                                placeholder=''
                              />
                            </div>
                          </Col>
                          <Col>
                            <div className='mb-7'>
                              <label htmlFor='exampleFormControlInput1' className='form-label'>
                                <span>Short Text PR</span>
                              </label>
                              <Field
                                disabled
                                name='description'
                                value={data?.item_text_pr}
                                type='text'
                                className='form-control'
                                placeholder=''
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  }
                </Form>
              </>
            )}
          </Formik>

          {currentTab === 0 &&
            <DetailItemPO
              disabled={false}
              header={header}
              data={data}
              handleClose={handleClose}
            />
          }

          {currentTab === 1 &&
            <AdditionalCostPO
              disabled={false}
              header={header}
              data={data}
            />
          }

          {currentTab === 2 &&
            <AccountAssignmentPO
              disabled={false}
              header={header}
              data={data}
            />
          }
        </Modal.Body>
      </Modal>
    </>
  )
}

export default DetailFormItemModal
