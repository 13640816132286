import React, { useEffect, useRef, useState } from "react";
import { useAxiosPost } from "../../../../../../hooks/useAxios";
import { getEnv } from "../../../../../../helpers/procurex";
import { useIntl } from "react-intl";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import { Modal, Spinner } from "react-bootstrap";
import {useMutation, useQuery} from 'react-query'
import axios from 'axios'

type FormNotesBAType = {
  show: boolean
  onClose: Function
  currentBA: any
  proposalTender: any
  onSaveNotes: Function
  hideNamaPIC?: boolean
}
const FormNotesBA = ({show, onClose, currentBA, proposalTender, onSaveNotes,hideNamaPIC}: FormNotesBAType) => {
  const [isShowForm, setIsShowForm] = useState(show)
  const formRef = useRef<any>(null)
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosPost(
    getEnv("tender")
  );
  const intl = useIntl()
  const initialValues = {
    nama_pic: currentBA?.contact?.name || '',
    jabatan_pic: currentBA?.contact?.jabatan || '',
    notes: ''
  }
  const validationSchema = Yup.object({
    nama_pic:  Yup.string().nullable(),
    jabatan_pic:  Yup.string().nullable(),
    notes: Yup.string().nullable()
  })

  const handleOnSubmitBANotes = async(values:any) => {
    if(currentBA?.url_submit_ba_note) {
      const payload = {
        _method: "PUT",
        url_docx: currentBA?.url,
        note: values?.notes,
        contact: {
          nama_pic: values?.nama_pic,
          jabatan_pic: values?.jabatan_pic
        }
      }

      doSubmit(payload, currentBA?.url_submit_ba_note, toast,(res:any)=>{
        onSaveNotes(res)
      }).then((res: any) => {

      })
    }
  }

  const handleOnSubmitPICVendor = async(values:any) => {
    if(currentBA?.url_submit_pic && values?.nama_pic && values?.jabatan_pic){
      const payload = {
        _method: "PUT",
        proposal_tender_id: proposalTender?.uuid,
        name: values?.nama_pic,
        jabatan: values?.jabatan_pic,
      }
      doSubmit(payload, currentBA?.url_submit_pic, toast).then((res:any)=>{
        onSaveNotes(res)
      })
    }

  }

  const handleOnSubmit = async(values:any) => {
    handleOnSubmitBANotes(values)
    handleOnSubmitPICVendor(values)
    setIsShowForm(false)
  }

  useEffect(() => {
    setIsShowForm(show)

  }, [show]);

  useEffect(() => {
    if(!isShowForm) {
      onClose && onClose(false)
    }
  },[isShowForm])

  //region get current default value
  // @ts-ignore
  const {mutate, isLoading:isLoadingGetCurrentValue} = useMutation({
    // @ts-ignore
    mutationKey: ["awarding","penunjukkan pemenang", "uuid"+proposalTender?.uuid],
    mutationFn: (url:string) => axios.get(url),
    enabled: false,
    onSuccess: (res:any) => {
      if(res?.data?.data?.length > 0){
        const tmp = res?.data?.data[0]
        formRef?.current?.resetForm()
        formRef?.current?.setValues({
          nama_pic:  tmp?.contact?.nama_pic,
          jabatan_pic:  tmp?.contact?.jabatan_pic,
          notes: tmp?.note
        })
      } else {
        formRef?.current?.resetForm()
        formRef?.current?.setValues(initialValues)
      }
    }
  })
  //endregion

  useEffect(() => {
    if(currentBA?.url_get_default_value) {
      mutate(currentBA?.url_get_default_value)
    } else {
      formRef?.current?.resetForm()
      formRef?.current?.setValues(initialValues)
    }

  }, [currentBA]);


  return (
    <>
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
      >
        {({values, setFieldValue, submitForm}) => (
          <Form>
            <Modal show={isShowForm}>
              <Modal.Header closeButton={true} onHide={()=>onClose && onClose(false)}>
                <Modal.Title>{intl.formatMessage({id:'BA Notes'})}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <div className={"form-group mb-4"}>
                    <label>{intl.formatMessage({ id: "Jenis BA" })}</label>
                    <input className={"form-control form-control-sm"} value={currentBA?.title} disabled={true} />
                  </div>
                  {currentBA?.isShowPIC && (
                    <>
                      {!hideNamaPIC && (
                        <div className={'form-group mb-4'}>
                          <label>{intl.formatMessage({id: 'Nama PIC Vendor'})}</label>
                          <input
                            className={'form-control form-control-sm'}
                            value={values?.nama_pic}
                            onChange={(e) => setFieldValue('nama_pic', e.target.value)}
                          />
                        </div>
                      )}

                      <div className={'form-group mb-4'}>
                        <label>{intl.formatMessage({id: 'Jabatan PIC Vendor'})}</label>
                        <input
                          className={'form-control form-control-sm'}
                          value={values?.jabatan_pic}
                          onChange={(e) => setFieldValue('jabatan_pic', e.target.value)}
                        />
                      </div>
                    </>
                  )}

                  <div className={"form-group"}>
                    <label>{intl.formatMessage({ id: "Note" })}</label>
                    <textarea
                      className={"form-control form-control-sm"}
                      value={values?.notes}
                      onChange={(e) => setFieldValue("notes", e.target.value)}
                    ></textarea>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className={"btn btn-sm btn-danger"}
                  type={"button"}
                  onClick={() => onClose && onClose(false)}
                >
                  <i className={"fa fa-times me-1"}></i>
                  {intl.formatMessage({id:"Tutup"})}
                </button>
                <button
                  className={"btn btn-sm btn-primary"}
                  type={"button"}
                  onClick={submitForm}
                  disabled={isLoading}
                >
                  {isLoading && <Spinner size={"sm"} className={"me-1"} /> }
                  {!isLoading  && <i className={"fa fa-save me-1"}></i> }
                  {intl.formatMessage({id:"Simpan"})}
                </button>
              </Modal.Footer>
            </Modal>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default FormNotesBA;