import { useState } from "react";
import axios from "axios";


export const useAxiosGet = (url:string) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);

  const doSubmit = async (values: any, newUrl?: string, _toast?: any, afterSuccess?: Function) => {
    return new Promise(async (resolve, reject) => {
      setIsLoading(true);
      setIsError(false);
      setIsSuccess(false);
      // setData(null);

      try {
        const response = await axios.get(newUrl || url, {
          params: values,
        });
        if (response.status === 200 || response.status === 201) {
          _toast && _toast.success(response.data.meta.message);
          afterSuccess && afterSuccess();
          setIsSuccess(true);
          setData(response.data.data);
          resolve(response.data);
        } else {
          // _toast && _toast.error("Gagal Mengambil Data");
          setIsError(true);
          reject(new Error("Gagal Mengambil Data"));
        }
      } catch (error) {
        // @ts-ignore
        // _toast && _toast.error(error?.response?.data?.meta?.message);
        setIsError(true);
        reject(error);
      } finally {
        setIsLoading(false);
      }
    });
  };

  return [{ doSubmit, isLoading, isError, data, isSuccess }];
};

export const useAxiosPost = (url:string) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);

  const doSubmit = async (values: any, newUrl?: any, _toast?: any, afterSuccess?: Function) => {
    return new Promise(async (resolve, reject) => {
      setIsLoading(true);
      setIsError(false);
      setIsSuccess(false);
      setData({});

      try {
        const response = await axios.post(newUrl || url, values);
        if (response.status === 200 || response.status === 201) {
          _toast && _toast.success(response.data.meta.message);
          afterSuccess && afterSuccess();
          setIsSuccess(true);
          setData(response.data.data);
          resolve(response.data);
        } else {
          _toast && _toast.error("Gagal Mengambil Data");
          setIsError(true);
          reject(new Error("Gagal Mengambil Data"));
        }
      } catch (error) {
        // @ts-ignore
        _toast && _toast.error(error?.response?.data?.meta?.message);
        setIsError(true);
        reject(error);
      } finally {
        setIsLoading(false);
      }
    });
  };

  return [{ doSubmit, isLoading, isError, data, isSuccess }];
};


