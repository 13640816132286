import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { KTCard, KTCardBody } from "../../../../_metronic/helpers";
import { toast } from "react-toastify";
import TableX from "../../../components/shared/TableX";
import {Button, Col, Row} from 'react-bootstrap'
import {formatNumber} from '../../../../helpers/procurex'
import {NumericFormat} from 'react-number-format'
import ListToolbar from './headers/ListToolbar'
import {useAuth} from '../../../../app/modules/auth'

const TabulasiVendorAuction = () => {
  const API_URL = process.env.REACT_APP_API_URL
  const params = useParams()
  const [key, setKey] = useState(0)
  const idParam = params['*']
  const [data, setData] = useState<any>('')
  const [winner_candidate, setWinnerCandidate] = useState<any>('')
  const PusherJS = require('pusher-js')
  const [event, setEvent] = useState<any>('App\\Events\\AuctionEvent')
  const [subscribed, setSubscribed] = useState<any>('auction.' + idParam)
  const {currentUser} = useAuth()
  const [detailData, setDetailData] = useState({
    type: '',
    metode_evaluasi: '',
    variant: '',
    opsi_peringkat: '',
    tipe_auction: '',
  })
  let client = new PusherJS('app-key', {
    wsHost: process.env.REACT_APP_WEBSOCKET_URL,
    wsPort: 443,
    cluster: 'mt1',
    forceTLS: false,
    encrypted: false,
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
    logToConsole: true,
  })

  const tableColumns = [
    {
      Header: 'Nama Item',
      width: 150,
      accessor: 'nama',
      className: 'text-center',
    },
    {Header: 'Qty', width: 50, accessor: 'kuantitas', className: 'text-center'},
    {Header: 'Uom', width: 50, accessor: 'satuan', className: 'text-center'},
    {
      Header: 'Harga Satuan (Sebelumnya)',
      width: 150,
      className: 'text-center',
      Cell: (props: any) => {
        return (
          <>
            <div className='w-200'>
              <span>
                <label className='form-label'>
                  {props?.cell?.row?.original?.harga_satuan
                    ? formatNumber(props?.cell?.row?.original?.harga_satuan)
                    : ''}
                </label>
              </span>
            </div>
          </>
        )
      },
    },
    {
      Header: 'Unit Price',
      width: 200,
      className: 'text-center',
      Cell: (props: any) => {
        return props?.cell?.row?.original?.unit_price ? (
          <>
            <div className='d-flex w-200 justify-content-start'>
              <span>
                <NumericFormat
                  className='form-control'
                  value={props?.cell?.row?.original?.unit_price}
                  thousandSeparator={'.'}
                  type='text'
                  fixedDecimalScale={true}
                  decimalScale={2}
                  decimalSeparator=','
                  onKeyPress={(e: any) => {
                    if (e.key === 'Enter') {
                      let dataParam = {
                        auction_id: idParam,
                        item_auction_id: props?.cell?.row?.original?.item_id,
                      }
                      onChangeUnitPriceEnter(dataParam, e, true)
                    }
                  }}
                />
                <label
                  className='form-label  justify-content-start'
                  style={{fontSize: 10, fontWeight: '100', color: 'red'}}
                >
                  *Press Enter after change
                </label>
              </span>
            </div>
          </>
        ) : (
          ''
        )
      },
    },
    {
      Header: detailData.type.split(' ')[0] == 'Reverse' ? 'Decrement' : 'Increment',
      className: 'text-center',
      Cell: (props: any) => {
        return detailData.metode_evaluasi == 'Paket' ? (
          <>
            <div className='w-200'>
              <span>
                <label className='form-label'>
                  {props?.cell?.row?.original?.decrement
                    ? formatNumber(props?.cell?.row?.original?.decrement)
                    : ''}
                </label>
              </span>
            </div>
          </>
        ) : (
          <>
            <Button
              className='btn btn-info h-10'
              onClick={() => {
                let dataParam = {
                  auction_id: idParam,
                  item_auction_id: props?.cell?.row?.original?.item_id,
                }
                let unitPriceDec =
                  props?.cell?.row?.original?.unit_price - props?.cell?.row?.original?.decrement
                onChangeUnitPriceEnter(dataParam, unitPriceDec, false)
              }}
            >
              {formatNumber(props?.cell?.row?.original?.decrement)}
            </Button>
          </>
        )
      },
    },
    {
      Header: 'Total',
      className: 'text-center',
      Cell: (props: any) => {
        return (
          <>
            <div className='w-200'>
              <span>
                <label className='form-label'>
                  {formatNumber(props?.cell?.row?.original?.total)}
                </label>
              </span>
            </div>
          </>
        )
      },
    },
    {
      Header: 'Ranking',
      width: 50,
      className: 'text-center',
      Cell: (props: any) => {
        return (
          <>
            <div className='w-200'>
              <span>
                {props?.cell?.row?.original?.ranking == 1 && (
                  <i className={'fa fa-crown'} style={{color: '#DA4F37'}} />
                )}{' '}
                <label className='form-label'>
                  {props?.cell?.row?.original?.item_id == '---'
                    ? props?.cell?.row?.original?.ranking
                    : ''}
                </label>
              </span>
            </div>
          </>
        )
      },
    },
  ]

  const tableHistoryColumns = [
    {
      Header: 'Peringkat',
      width: 150,
      className: 'text-center',
      Cell: (props: any) => {
        return (
          <>
            <div className='w-200'>
              <span>
                <label className='form-label' />
                {1}
              </span>
            </div>
          </>
        )
      },
    },
    {
      Header: 'Peserta',
      width: 200,
      className: 'text-center',
      Cell: (props: any) => {
        return (
          props?.cell?.row?.original && (
            <>
              <div className='w-200'>
                <span>
                  <label className='form-label' />
                  {props?.cell?.row?.original?.vendor_name}
                </span>
              </div>
            </>
          )
        )
      },
    },
    {
      Header: 'Total Penawaran',
      width: 200,
      className: 'text-center',
      Cell: (props: any) => {
        return (
          props?.cell?.row?.original && (
            <>
              <div className='w-200'>
                <span>
                  <label className='form-label' />
                  {props?.cell?.row?.original?.total_price}
                </span>
              </div>
            </>
          )
        )
      },
    },
    ,
  ]

  const onChangeUnitPriceEnter = async (paramData: any, val: any, onEnter: boolean) => {
    let valTrim = onEnter
      ? val?.target?.value.toString().split('.').join('')
      : val.toString().split('.').join('')
    let data = {...paramData, unit_price: valTrim.split(',')[0]}
    try {
      const response: any = await axios.post(`${API_URL}/api/auction/as-vendor/bid`, data)
      if (response?.status === 201 || response?.status === 200) {
        setKey(key + 1)
        toast.success('Successful bid', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })

        return true
      }
    } catch (error) {
      console.error(error)
    } finally {
      fetchData()
    }
  }

  const fetchDetailData = async () => {
    const response: any = await axios.get(`${API_URL}/api/auction/as-vendor/auction/${idParam}`)
    let details = {
      type: response?.data?.data?.tipe_auction?.value,
      metode_evaluasi: response.data?.data?.metode_evaluasi?.value,
      variant: response.data?.data?.metode_peringkat?.value,
      opsi_peringkat: response.data?.data?.opsi_peringkat?.value?.toLowerCase(),
      tipe_auction: response.data?.data?.tipe_auction?.value?.toLowerCase()?.split(' ')[0],
    }
    setDetailData(details)
    return details
  }

  const fetchWinnerCandidate = async () => {
    try {
      const response: any = await axios.get(`${API_URL}/api/auction/auction/history-bid/${idParam}`)
      if (response?.status === 201 || response?.status === 200) {
        const _data: any = response?.data
        let _items = _data?.data?.map((c: any) => {
          return {item_name: c.nama, bid: c.bid}
        })

        const new_items = _items.map((item: any) => {
          const init_vendors: any = {}
          item.bid.map((x: any) => {
            init_vendors[x.vendor] = 0
          })
          const total_bid_vendor = item.bid.reduce((a: any, c: any) => {
            if (c.status == 'approved') a[c.vendor] += c['total_price'] ?? 0
            return a
          }, init_vendors)

          const ranks: any = Object.entries(total_bid_vendor).sort((a: any, b: any) => {
            if (detailData?.tipe_auction == 'reverse') {
              return b[1] - a[1]
            } else {
              return a[1] - b[1]
            }
          })
          /** Value opsi rank
           * Semua => semua vendor bisa melihat semua peringkat bid dr vendor lain
           * Sembunyikan => Sama sekali gabisa liat
           * Memimpin => Cuma yg juara 1 yg bisa ngeliat
           */
          const _ranks = ranks.map((rank: any, index: number) => {
            return {
              vendor_name: rank[0],
              total_price: rank[1],
              rank_number: index + 1,
            }
          })

          return _ranks[0]
        })
        _data.data = new_items
        return _data
      }
    } catch (e) {
      console.error(e)
    }
  }

  const fetchData = async () => {
    try {
      const response: any = await axios.get(API_URL + '/api/auction/as-vendor/tabulasi/' + idParam)
      if (response?.status === 201 || response?.status === 200) {
        setKey(key + 1)
        let _data_raw = response?.data
        const _data = _data_raw?.data
        const new_init_data = {
          item_id: '---',
          nama: '',
          kuantitas: null,
          satuan: null,
          harga_satuan: null,
          unit_price: null,
          decrement: null,
          total: 0,
          ranking: _data[0]?.ranking,
        }

        const total_price_paket: any = _data.reduce((a: any, c: any) => {
          a['total'] += c['total'] ?? 0
          return a
        }, new_init_data)

        _data.push(total_price_paket)

        _data_raw.data = _data
        setData(_data_raw)
        return true
      }
    } catch (error) {
      console.error(error)
    } finally {
    }
  }

  const channel = client.subscribe(subscribed)

  channel.bind(event, function (data: any) {
    let datas = JSON.parse(data?.data)
    setData(datas?.data)
    setKey(key + 1)
  })

  useEffect(() => {
    fetchData()
    fetchDetailData()
    fetchWinnerCandidate().then((data) => setWinnerCandidate(data))
  }, [])

  return (
    <>
      {detailData.metode_evaluasi === 'Paket' && (
        <div className={'mt-4'} style={{marginRight: 55}}>
          <ListToolbar
            buttonText={'Bid'}
            onClick={() => {
              // let dataParam = {
              //   auction_id: idParam,
              //   item_auction_id: props?.cell?.row?.original?.item_id,
              // }
              // let unitPriceDec = props?.cell?.row?.original?.unit_price - props?.cell?.row?.original?.decrement
              // onChangeUnitPriceEnter(dataParam, unitPriceDec, false)
            }}
          />
        </div>
      )}
      <KTCard>
        <KTCardBody className='py-4'>
          <TableX
            key={key}
            data={data?.data ?? []}
            meta={{
              perPage: data?.meta?.per_page || data?.data?.length,
              currentPage: data?.meta?.current_page,
              total: data?.data?.length,
            }}
            columns={tableColumns}
            isLoading={false}
          />
        </KTCardBody>
      </KTCard>

      {/* Calon Pemenang */}
      <KTCard>
        <TableX
          key={key}
          data={winner_candidate?.data ?? []}
          meta={{
            perPage: winner_candidate?.meta?.per_page || data?.data?.length,
            currentPage: winner_candidate?.meta?.current_page,
            total: winner_candidate?.data?.length,
          }}
          columns={tableHistoryColumns}
          isLoading={false}
        />
      </KTCard>
    </>
  )
}

export { TabulasiVendorAuction };
