import React, {useEffect, useState} from 'react'
import {ErrorMessage, useField} from 'formik'
import axios from 'axios'
import {GetOptionLabel, GetOptionValue} from 'react-select/dist/declarations/src'
import {AsyncPaginate} from 'react-select-async-paginate'

interface FormikSelectProps {
  label?: any
  name: string
  optionsUrl: string
  getOptionLabel: GetOptionLabel<React.SetStateAction<any>>
  getOptionValue: GetOptionValue<React.SetStateAction<any>>
  onChange?: (value: any) => void
  onChangeValue?: Function
  unique_property?: string
  border?: boolean
  fontSize?: string
  width?: string
  defaultValue?: any
  search_property?: string | Array<string>
  not_required?: boolean
  isDisabled?: boolean
  [key: string]: any
}

const FormikSelect2: React.FC<FormikSelectProps> = ({
  label,
  optionsUrl,
  getOptionLabel,
  getOptionValue,
  onChange,
  unique_property,
  search_property,
  defaultValue,
  not_required,
  isDisabled,
  ...props
}) => {
  const [field, meta, {setValue: setFormikFieldValue}] = useField(props)
  const [key, setKey] = useState(1)
  const [initValue, setInitValue] = useState(null)
  const handleChangeData = (newValue: any) => {
    if (props.isMulti) {
      let values = newValue.map((item: any) => ({
        ...item.value,
      }))

      setFormikFieldValue(values || null)
      if (props.onChangeValue) {
        // @ts-ignore
        props.onChangeValue(values)
      }
    } else {
      if (props.onChangeValue) {
        // @ts-ignore
        props.onChangeValue(newValue?.value)
      }
      setFormikFieldValue(newValue?.value || null)
    }
  }

  const loadData = async (searchQuery: string, loadedOptions: any, {page}: any) => {
    const config: any = {
      params: {
        page: page,
      },
    }

    if (search_property !== undefined) {
      if (typeof search_property === 'string') {
        config.params[`filter[${search_property}]`] = searchQuery
      } else {
        for (const property of search_property) {
          config.params[`filter[${property}]`] = searchQuery
        }
      }
    }

    const response = await axios.get(optionsUrl, config)
    let responseJSON = response.data.data
    responseJSON.map((v: any) => {
      v.disabled = false
      // console.log(field.value.length)
      if (field?.value && field?.value?.length > 0) {
        field?.value?.map((item: any) => {
          if (item?.id === v?.id) {
            v.disabled = true
          }
        })
      }
      return v
    })

    return {
      options: responseJSON.map((option: any) => ({
        value: getOptionValue(option),
        label: getOptionLabel(option),
        isDisabled: option.disabled,
      })),
      hasMore: response.data.meta.current_page < response.data.meta.last_page,
      additional: {
        page: searchQuery ? 2 : page + 1,
      },
    }
  }

  useEffect(() => {
    if (meta.value) {
      let defaultValue = meta.value
      if (!props.isMulti) {
        defaultValue = {
          value: getOptionValue(meta.value),
          label: getOptionLabel(meta.value),
        }
      } else {
        defaultValue = meta.value.map((item: any) => ({
          value: getOptionValue(item),
          label: getOptionLabel(item),
        }))
      }
      // @ts-ignore
      setInitValue(defaultValue)

      setKey(key + 1)
    } else if (meta.value === '') {
      setInitValue(null)
      setKey(key + 1)
    }
  }, [meta.value])

  // @ts-ignore
  return (
    <div className={'w-100'}>
      <div className='form-group w-100' key={key}>
        {label && (
          <label htmlFor={props.name} className={`form-label ${!not_required ? 'required' : ''}`}>
            {label}
          </label>
        )}

        <AsyncPaginate
          isDisabled={isDisabled}
          debounceTimeout={500}
          loadOptions={loadData}
          cacheUniqs={[optionsUrl]}
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (base) => ({...base, zIndex: 2000}),
            singleValue: (baseStyles) => ({
              ...baseStyles,
              color: "black",
              scrollbarColor: "red !important"
            }),
            control: (baseStyles) => ({
              ...baseStyles,
              backgroundColor: isDisabled ? '#eff2f5' : 'transparent',
              padding: '0.2rem',
              border: props.border === false ? 'none' : '1px lightgray solid',
              fontSize: props.fontSize ? props.fontSize : '9.5pt',
              width: props.width ? props.width : '100%',
              maxWidth: props.width ? props.width : '100%',
              borderRadius: props.borderRadius ? props.borderRadius : '4px',
              scrollbarColor: "red !important"
              // zIndex: 1000
            }),
            menuList: (base, state) => {
              return {
                ...base,
                fontSize: props.fontSize ? props.fontSize : '9.5pt',
                zIndex: 9999,
                scrollbarColor: "red !important"
              }
            },
            multiValue: (base) => ({
              ...base,
              maxWidth: '400px',
              scrollbarColor: "red !important"
            }),
          }}
          {...props}
          defaultValue={initValue}
          onChange={handleChangeData}
          onBlur={field.onBlur}
          isClearable={true}
          additional={{
            page: 1,
          }}
        />

        {meta.touched && meta.error ? (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{meta.error}</span>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default FormikSelect2
