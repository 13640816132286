import {Nav, Placeholder} from 'rsuite'
import {useIntl} from 'react-intl'
import React, {createContext, useContext, useEffect, useState} from 'react'
import FormAttachment from '../../../../../components/shared/form/FormAttachment'
import {useFormik} from 'formik'
import {useMutation} from 'react-query'
import axios from 'axios'
import {formatDateTimeToDBFormat, getEnv} from '../../../../../../helpers/procurex'
import {useParams} from 'react-router-dom'
import PreviewAttachment from '../../../../../components/PreviewAttachment'
import Swal from 'sweetalert2'
import {toast} from 'react-toastify'
import {Spinner} from 'react-bootstrap'
import {useProposalTenderVendor} from '../core/_proposalTenderVendorProvider'

//region context
const KlarifikasiPenawaranContext = createContext({
  klarifikasi: {},
  isLoading: false,
  isCanSubmit: true,
  mutateSubmit: (values) => {},
  isLoadingSubmit: false,
  history: [],
  isLoadingGetHistory: false,
  previewAttachment: '',
  setPreviewAttachment: (e: any) => {},
} as any)

const KlarifikasiProvider = ({children}: any) => {
  const intl = useIntl()
  const [isCanSubmit,setIscanSubmit] = useState(true)
  const [history, setHistory] = useState([] as any)
  const {proposalTender} = useProposalTenderVendor();
  const [klarifikasi, setKlarifikasi] = useState({} as any)
  const [previewAttachment, setPreviewAttachment] = useState('')
  const {uuid} = useParams()

  //region get klarifikasi
  const {mutate, isLoading} = useMutation({
    mutationKey: ['getKlarifikasi'],
    mutationFn: () => axios.get(
      getEnv('tender') +
      `/vendor/clarification/${uuid}?include=items.syaratEvaluation.syarat,items.quotationItem.serviceLines,items.quotationItem.quotation,attachment`,
    ),
    onSuccess: (res) => {
      setKlarifikasi(res.data.data)
    },
  })
  //endregion

  //region submit klarifikasi
  const {mutate:mutateSubmit, isLoading:isLoadingSubmit} = useMutation({
    mutationKey: ['submitKlarifikasi'],
    mutationFn: (values) => axios.put(
      getEnv('tender') + `/vendor/clarification/${uuid}`,
      values
    ),
    onSuccess: (res:any) => {
      toast.success(intl.formatMessage({id: 'Klarifikasi berhasil disubmit'}))
    },
  });
  //endregion

  //region get history
  const {mutate: mutateGetHistory, isLoading: isLoadingGetHistory} = useMutation({
    mutationKey: ['get-history', proposalTender?.uuid],
    mutationFn: () => axios.get(
      getEnv('tender')+ `/vendor/clarification/history/${proposalTender?.uuid}/${proposalTender?.tender_vendor_uuid}?include=items,items.syaratEvaluation.syarat,items.quotationItem.serviceLines,items.quotationItem.quotation,proposalTenderVendor,items.attachment`,
    ),
    onSuccess: (res) => {
      setHistory(res?.data?.data)
    },
    onError: (error:any) => {

    },
  })
  //endregion

  useEffect(() => {
    mutate()
    mutateGetHistory()
  }, [uuid])

  return (
    <KlarifikasiPenawaranContext.Provider value={{
      klarifikasi,
      isCanSubmit,
      mutateSubmit,
      isLoadingSubmit,
      history,
      isLoadingGetHistory,
      previewAttachment,
      setPreviewAttachment,
    }}>
      {children}

      <PreviewAttachment
        previewExt={previewAttachment}
        onHide={() => setPreviewAttachment('')}
      />
    </KlarifikasiPenawaranContext.Provider>
  )
}

const useKlarifikasiPenawaran = () => {
  return useContext(KlarifikasiPenawaranContext)
}
//endregion

const KlarifikasiSyaratTable = ({title, type, onChange}) => {
  const intl = useIntl()
  const {setPreviewAttachment} = useKlarifikasiPenawaran()
  const [syarats, setSyarats] = useState([])
  const {klarifikasi, isCanSubmit} = useKlarifikasiPenawaran()

  useEffect(() => {
    if (!klarifikasi?.id) return
    setSyarats(klarifikasi?.items)
  }, [klarifikasi])

  const handleOnReply = (row: any, e: any) => {
    const newSyarats = syarats.map((v: any) => {
      if (v?.id === row?.id) {
        return {
          ...v,
          vendor_note: e.target.value,
        }
      }
      return v
    })
    setSyarats(newSyarats)
    onChange({
      id: row?.id,
      vendor_note: e.target.value,
      attachment_url: row?.attachment_url,
      attachment_description: row?.attachment_description,
    })
  }

  const handleOnAttachment = (row: any, e: any) => {
    const newSyarats = syarats.map((v: any) => {
      if (v?.id === row?.id) {
        return {
          ...v,
          attachment_url: e.url,
        }
      }
      return v
    })
    setSyarats(newSyarats)
    onChange({
      id: row?.id,
      vendor_note: row?.vendor_note,
      attachment_url: e.url,
      attachment_description: row?.attachment_description,
    })
  }

  const handleOnAttachmentDescription = (row: any, e: any) => {
    const newSyarats = syarats.map((v: any) => {
      if (v?.id === row?.id) {
        return {
          ...v,
          attachment_description: e.target.value,
        }
      }
      return v
    })
    setSyarats(newSyarats)
    onChange({
      id: row?.id,
      vendor_note: row?.vendor_note,
      attachment_url: row?.attachment_url,
      attachment_description: e.target.value,
    })
  }

  const types = Array.isArray(type) ? type : [type]

  return (
    <div className={'form-group mb-8 mt-8'}>
      <label className={'mb-2'}>
        <h4>
          <strong>{title}</strong>
        </h4>
      </label>
      <div>
        <table className={'table table-bordered table-striped table-header-gray'}>
          <thead>
          <tr>
            <th
              className={'px-2 border'}
              //@ts-ignore
              width={30}
            >{intl?.formatMessage({id: 'No'})}</th>
            <th
              className={'px-2 border'}
              //@ts-ignore
              width={300}
            >{intl?.formatMessage({id: 'Deskripsi'})}</th>
            <th className={'px-2 border'}>{intl?.formatMessage({id: 'Klarifikasi'})}</th>
          </tr>
          </thead>
          <tbody>
          {syarats?.filter((f:any)=>types.includes(f?.syarat_evaluation?.type))?.map((v: any, i: number) => {
            return (
              <tr key={'syarat-' + v?.id}>
                <td className={'px-2 text-center border'}>{i + 1}</td>
                <td className={'px-2 text-capitalize border'}>
                  <div className={"d-flex flex-column"}>
                    <div><strong>Tipe Penawaran:</strong></div>
                    <div>{v?.syarat_evaluation?.syarat?.type}</div>
                  </div>
                  <hr />
                  <div className={"d-flex flex-column"}>
                    <div><strong>Deskripsi:</strong></div>
                    <div>{v?.syarat_evaluation?.syarat?.description}</div>
                  </div>
                  <hr />
                  <div className={"d-flex flex-row"}>
                    <a
                      href={'javascript:;'}
                      onClick={() => setPreviewAttachment(v?.syarat_evaluation?.media_url)}
                      style={{fontWeight: 'bold'}}
                      className={'text-primary'}
                    >
                      <i className={'fa fa-chevron-right me-2'}></i>
                      Lihat File
                    </a>
                  </div>
                </td>
                <td className={'border px-2'}>
                  <strong><small>Notes dari Buyer</small></strong>
                  <textarea
                    className={'form-control'}
                    disabled={true}
                    value={v?.buyer_note}
                  ></textarea>
                  <hr />
                  <strong><small>Jawaban Klarifikasi</small></strong>
                  <textarea
                    className={'form-control'}
                    disabled={!isCanSubmit}
                    value={v?.vendor_note}
                    onChange={(e) => handleOnReply(v, e)}
                  ></textarea>
                  <div className={"mt-3"}>
                    <strong className={'mt-3'}><small>Attachment Klarifikasi</small></strong>
                    <FormAttachment
                      media_type_filter={'TENDER_ATTACHMENT'}
                      afterSubmit={(e: any) => {
                        handleOnAttachment(v, e)
                      }}
                      onlyFile={true}
                      isRequired={false}
                      isDisabled={false}
                      isHideLabel={true}
                      isSmall={true}
                    />
                  </div>
                  <div className='mt-3'>
                    <strong><small>Deskripsi Attachment</small></strong>
                    <input
                      className={'form-control'}
                      disabled={!isCanSubmit}
                      value={v?.attachment_description}
                      onChange={(e) => handleOnAttachmentDescription(v, e)}
                    />
                  </div>
                </td>
              </tr>
            )
          })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

const KlarifikasiItemTeknisTable =({onChange}) => {
  const intl = useIntl()
  const [previewAttachment, setPreviewAttachment] = useState('')
  const [syarats, setSyarats] = useState([])
  const {klarifikasi, isCanSubmit} = useKlarifikasiPenawaran()

  useEffect(() => {
    if (!klarifikasi?.id) return
    setSyarats(klarifikasi?.items)
  }, [klarifikasi])

  const handleOnReply = (row: any, e: any) => {
    const newSyarats = syarats.map((v: any) => {
      if (v?.id === row?.id) {
        return {
          ...v,
          vendor_note: e.target.value,
        }
      }
      return v
    })
    setSyarats(newSyarats)
    onChange({
      id: row?.id,
      vendor_note: e.target.value,
      attachment_url: row?.attachment_url,
      attachment_description: row?.attachment_description,
    })
  }

  const handleOnAttachment = (row: any, e: any) => {
    const newSyarats = syarats.map((v: any) => {
      if (v?.id === row?.id) {
        return {
          ...v,
          attachment_url: e.url,
        }
      }
      return v
    })
    setSyarats(newSyarats)
    onChange({
      id: row?.id,
      vendor_note: row?.vendor_note,
      attachment_url: e.url,
      attachment_description: row?.attachment_description,
    })
  }

  const handleOnAttachmentDescription = (row: any, e: any) => {
    const newSyarats = syarats.map((v: any) => {
      if (v?.id === row?.id) {
        return {
          ...v,
          attachment_description: e.target.value,
        }
      }
      return v
    })
    setSyarats(newSyarats)
    onChange({
      id: row?.id,
      vendor_note: row?.vendor_note,
      attachment_url: row?.attachment_url,
      attachment_description: e.target.value,
    })
  }

  return (
    <div>
      <div className={'form-group mb-8 mt-8'}>
        <label className={'mb-2'}>
          <h4>
            <strong>{intl.formatMessage({id:'Proses Klarifikasi Spesifikasi Teknis'})}</strong>
          </h4>
        </label>
        <div>
          <table className={'table table-bordered table-striped table-header-gray'}>
            <thead>
            <tr>
              <th
                className={'px-2 border'}
                //@ts-ignore
                width={30}
              >{intl?.formatMessage({id: 'No'})}</th>
              <th
                className={'px-2 border'}
                //@ts-ignore
                width={300}
              >{intl?.formatMessage({id: 'Deskripsi'})}</th>
              <th className={'px-2 border'}>{intl?.formatMessage({id: 'Klarifikasi'})}</th>
            </tr>
            </thead>
            <tbody>
            {syarats?.filter((f: any) => f?.quotation_item !== null)?.map((v: any, i: number) => {
              return (
                <tr key={'syarat-' + v?.id}>
                  <td className={'px-2 text-center border'}>{i + 1}</td>
                  <td className={'px-2 text-capitalize border'}>
                    <div className={'d-flex flex-row gap-2'}>
                      <div><strong>Comply / Deviate:</strong></div>
                      <div>{v?.quotation_item?.quote}</div>
                    </div>
                    <hr />
                    <div className={'d-flex flex-column gap-2'}>
                      <div className={'d-flex flex-row gap-2 justify-content-between'}>
                        <div><strong>Material No:</strong></div>
                        <div>{v?.quotation_item?.material_id}</div>
                      </div>
                      <div className={'d-flex flex-row gap-2 justify-content-between'}>
                        <div><strong>Short Text:</strong></div>
                        <div>{v?.quotation_item?.short_text}</div>
                      </div>
                      <div className={'d-flex flex-row gap-2 justify-content-between'}>
                        <div><strong>Qty:</strong></div>
                        <div>{v?.quotation_item?.quantity}</div>
                      </div>
                      <div className={'d-flex flex-row gap-2 justify-content-between'}>
                        <div><strong>UOM:</strong></div>
                        <div>{v?.quotation_item?.uom?.uom}</div>
                      </div>
                      <div className={'d-flex flex-row gap-2 justify-content-between'}>
                        <div><strong>Delivery Time:</strong></div>
                        <div>{v?.quotation_item?.delivery_time} {intl.formatMessage({id: 'day(s)'})}</div>
                      </div>
                    </div>

                  </td>
                  <td className={'border px-2'}>
                    <strong><small>Notes dari Buyer</small></strong>
                    <textarea
                      className={'form-control'}
                      disabled={true}
                      value={v?.buyer_note}

                    ></textarea>
                    <hr />
                    <strong><small>Jawaban Klarifikasi</small></strong>
                    <textarea
                      className={'form-control'}
                      disabled={!isCanSubmit}
                      value={v?.vendor_note}
                      onChange={(e) => handleOnReply(v, e)}
                    ></textarea>
                    <div className={'mt-3'}>
                      <strong className={'mt-3'}><small>Attachment Klarifikasi</small></strong>
                      <FormAttachment
                        media_type_filter={'TENDER_ATTACHMENT'}
                        afterSubmit={(e: any) => {
                          // formik.setFieldValue('attachment_url',e.url)
                          handleOnAttachment(v, e)
                        }}
                        onlyFile={true}
                        isRequired={false}
                        isDisabled={false}
                        isHideLabel={true}
                        isSmall={true}
                      />
                    </div>
                    <div className={'mt-3'}>
                      <strong><small>Deskripsi Attachment</small></strong>
                      <input
                        className={'form-control'}
                        disabled={!isCanSubmit}
                        value={v?.attachment_description}
                        onChange={(e) => handleOnAttachmentDescription(v, e)}
                      />
                    </div>
                  </td>
                </tr>
              )
            })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

type clarificationItem = {
  id: string,
  vendor_note: string,
  attachment_url: string,
}

const ProsesKlarifikasiTab = ({proposalTender}: any) => {
  const intl = useIntl()
  const {klarifikasi, isCanSubmit, mutateSubmit, isLoadingSubmit, setPreviewAttachment} = useKlarifikasiPenawaran()
  const formik = useFormik({
    initialValues: {
      buyer_note: '',
      vendor_note: '',
      clarification_items: [] as clarificationItem[],
    },
    onSubmit: (values) => {
      Swal.fire({
        title: intl.formatMessage({id: 'Apakah anda yakin?'}),
        text: intl.formatMessage({id: 'Anda akan mensubmit klarifikasi penawaran ini.'}),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: intl.formatMessage({id: 'Ya, Kirim'}),
        cancelButtonText: intl.formatMessage({id: 'Batal'}),
      }).then((result) => {
        if (result.isConfirmed) {
          mutateSubmit(values)
        }
      })

    },
  })


  useEffect(() => {
    if (!klarifikasi?.id) return
    formik.setFieldValue('buyer_note', klarifikasi?.buyer_note)
  }, [klarifikasi])

  return (
    <div className={'pt-10'}>
      <form onSubmit={formik.handleSubmit}>
        <div className={'form-group mb-8'}>
          <label className={'mb-2'}><strong>{intl?.formatMessage({id: 'Note Buyer'})}</strong></label>
          <textarea
            className={'form-control'}
            rows={5}
            onChange={formik.handleChange}
            name={'buyer_note'}
            value={formik.values.buyer_note}
            disabled={true}
          ></textarea>
        </div>
        <div className={'form-group mb-8'}>
          <label className={'mb-2'}><strong>{intl?.formatMessage({id: 'Attachment'})}</strong></label>
          <FormAttachment
            media_type_filter={'TENDER_ATTACHMENT'}
            afterSubmit={(e: any) => {
              formik.setFieldValue('attachment_url', e.url)
            }}
            onlyFile={true}
            isRequired={false}
            isDisabled={true}
            isHideLabel={true}
            isSmall={true}
          />
          {(klarifikasi?.attachment?.media_url) && (
            <div className={'w-100 px-4 py-2 d-flex justify-content-between align-items-center'} style={{
              backgroundColor: '#f1faff',
            }}>
              <div>
                <a href='#'
                  onClick={(e: any) => {
                    e.preventDefault()
                    setPreviewAttachment(klarifikasi?.attachment?.media_url)
                  }}
                  className={'text-info mt-4'}
                  style={{
                    color: 'blue',
                    textDecoration: 'underline',
                  }}>
                  <strong>Lampiran Buyer</strong>
                </a>
              </div>
            </div>
          )}
        </div>
        <hr />

        <KlarifikasiSyaratTable
          title={intl.formatMessage({id: 'Klarifikasi Penawaran Administrasi & Teknis'})}
          type={['administration', 'technical']}
          onChange={(e: any) => {
            const currentValue = formik.values.clarification_items
            const isExist = currentValue.find((f: any) => f?.id === e?.id)
            if (isExist) {
              const newValues = currentValue.map((v: any) => {
                if (v?.id === e?.id) {
                  return e
                }
                return v
              })
              formik.setFieldValue('clarification_items', newValues)
            } else {
              formik.setFieldValue('clarification_items', [...currentValue, e])
            }
          }}
        />

        <KlarifikasiSyaratTable
          title={intl.formatMessage({id: 'Klarifikasi Penawaran Komersil'})}
          type={'commercial'}
          onChange={(e: any) => {
            const currentValue = formik.values.clarification_items
            const isExist = currentValue.find((f: any) => f?.id === e?.id)
            if (isExist) {
              const newValues = currentValue.map((v: any) => {
                if (v?.id === e?.id) {
                  return e
                }
                return v
              })
              formik.setFieldValue('clarification_items', newValues)
            } else {
              formik.setFieldValue('clarification_items', [...currentValue, e])
            }
          }}
        />

        <KlarifikasiItemTeknisTable
          onChange={(e: any) => {
            const currentValue = formik.values.clarification_items
            const isExist = currentValue.find((f: any) => f?.id === e?.id)
            if (isExist) {
              const newValues = currentValue.map((v: any) => {
                if (v?.id === e?.id) {
                  return e
                }
                return v
              })
              formik.setFieldValue('clarification_items', newValues)
            } else {
              formik.setFieldValue('clarification_items', [...currentValue, e])
            }
          }}
        />

        <div className={'form-group mb-8'}>
          <label className={'mb-2'}><strong>{intl?.formatMessage({id: 'Note Vendor'})}</strong></label>
          <textarea
            className={'form-control'}
            rows={5}
            onChange={formik.handleChange}
            name={'vendor_note'}
            value={formik.values.vendor_note}
            disabled={!isCanSubmit}
          ></textarea>
        </div>


        <div className={'form-group text-end'}>
          <button
            className={'btn btn-primary'}
            type={'submit'}
            disabled={isLoadingSubmit || !isCanSubmit}
          >
            {!isLoadingSubmit && <i className={'fa fa-paper-plane me-2'}></i>}
            {isLoadingSubmit && <Spinner animation="border" size="sm" />}
            {intl.formatMessage({id: 'Submit Klarifikasi'})}
          </button>
        </div>
      </form>
    </div>
  )
}

const RiwayatKlarifikasiTab = () => {
  const intl = useIntl();
  const {
    isLoadingGetHistory,
    history
  } = useKlarifikasiPenawaran()
  const [currentPreview,setCurrentPreview] = useState(null as string)

  const getBackground = (rowHistory:any) => {
    if(rowHistory.session % 2 === 0){
      return 'bg-light-info'
    }
    return '';
  }

  if(isLoadingGetHistory) {
    return <Placeholder.Grid
      rows={10}
      columns={4}
      active={true}
    />
  }

  return (
    <div className={"table-responsive mt-10"}>
      <table className={"table"}>
        <thead>
        <tr>
          <th className={'border p-2'}>
            <strong>{intl?.formatMessage({id: '#'})}</strong>
          </th>

          <th className={'border p-2'}
            //@ts-ignore
              width={200}
          >
            <strong>{intl?.formatMessage({id: 'Item Klarifikasi'})}</strong>
          </th>
          <th className={'border p-2 text-nowrap'}
            //@ts-ignore
              width={200}
          >
            <strong>{intl?.formatMessage({id: 'Notes dari Buyer'})}</strong>
          </th>
          <th className={'border p-2'}
            //@ts-ignore
              width={200}
          >
            <strong>{intl?.formatMessage({id: 'Jawaban Klarifikasi'})}</strong>
          </th>
        </tr>
        </thead>
        <tbody>
        {history.length === 0 && (
          <tr>
            <td colSpan={4} className={'border p-2 text-center'}>
              <i>
                {intl?.formatMessage({id: 'Tidak ada riwayat klarifikasi'})}
              </i>
            </td>
          </tr>
        )}
        {history?.map((rowHistory:any,i:number)=>{
          return (
            <>
              <tr className={`border ${getBackground(rowHistory)}`} style={{
                border:"5px solid"
              }}>
                <td className={'border p-2'} rowSpan={rowHistory?.items?.length}>
                  <div className={'d-flex flex-column'}>
                    <div className={'border-bottom py-1'}>
                      <div><small><strong>Sesi</strong></small></div>
                      <div>{rowHistory?.session}</div>
                    </div>
                    <div className={'border-bottom py-1'}>
                      <div><small><strong>Vendor</strong></small></div>
                      <div>{rowHistory?.vendor?.vendor?.name}</div>
                    </div>
                    <div className={'border-bottom py-1'}>
                      <div className={"d-flex justify-content-between"}>
                        <div><small><strong>Buyer</strong></small></div>
                        <div className={"text-muted"}><small>{formatDateTimeToDBFormat(rowHistory?.created_at)} WIB</small></div>
                      </div>
                      <div>{rowHistory?.buyer_note}</div>

                    </div>
                    <div className={' py-1'}>
                      <div className={'d-flex justify-content-between'}>
                        <div><small><strong>vendor</strong></small></div>
                        <div className={'text-muted'}><small>{formatDateTimeToDBFormat(rowHistory?.updated_at)} WIB</small></div>
                      </div>
                      <div>{rowHistory?.vendor_note}</div>

                    </div>
                  </div>
                </td>

                <td className={'border p-2'}>
                  <small className={'text-muted'}>
                    {rowHistory?.items[0]?.syarat_evaluation ? rowHistory?.items[0]?.syarat_evaluation?.type : 'Item'}
                  </small> <br />
                  {
                    rowHistory?.items[0]?.syarat_evaluation?.syarat?.description ??
                    rowHistory?.items[0]?.quotation_item?.short_text
                  }
                </td>
                <td className={'border p-2'}>
                  <div>
                    {rowHistory?.items[0]?.buyer_note}
                  </div>

                  {rowHistory?.items[0]?.syarat_evaluation?.media_url && (
                    <div className={'border-top mt-2 pt-2'}>
                      <div>{rowHistory?.items[0]?.syarat_evaluation?.attachment_description}</div>
                      <a href={rowHistory?.items[0]?.syarat_evaluation?.media_url} onClick={(e) => {
                        e.preventDefault()
                        setCurrentPreview(rowHistory?.items[0]?.syarat_evaluation?.media_url)
                      }}>
                        <small>
                          <strong>
                            <i className={'fa fa-chevron-right me-2'}></i>
                            Lihat File
                          </strong>
                        </small>
                      </a>
                    </div>
                  )}

                </td>
                <td className={'border p-2'}>
                  <div>{rowHistory?.items[0]?.vendor_note}</div>

                  {rowHistory?.items[0]?.attachment?.media_url && (
                    <div className={"border-top mt-2 pt-2"}>
                      <a href={rowHistory?.items[0]?.attachment?.media_url} onClick={(e)=>{
                        e.preventDefault();
                        setCurrentPreview(rowHistory?.items[0]?.attachment?.media_url)
                      }}>
                        <small>
                          <strong>
                            <i className={"fa fa-chevron-right me-2"}></i>
                            Lihat File
                          </strong>
                        </small>
                      </a>
                    </div>
                  )}
                </td>
              </tr>
              {rowHistory?.items?.map((rowHistoryItem:any,j:number) => {
                if(j===0) return;
                return (
                  <tr key={'rowHistoryItem' + j} className={`${getBackground(rowHistory)}`}>
                    <td className={'border p-2'}>
                      <small className={"text-muted"}>
                        {rowHistoryItem?.syarat_evaluation ? rowHistoryItem?.syarat_evaluation?.type : 'Item' }
                      </small> <br />
                      {rowHistoryItem?.syarat_evaluation?.syarat?.description ?? rowHistoryItem?.quotation_item?.short_text}
                    </td>
                    <td className={'border p-2'}>
                      <div>{rowHistoryItem?.buyer_note}</div>

                      {rowHistory?.items[0]?.syarat_evaluation?.media_url && (
                        <div className={"border-top mt-2 pt-2"}>
                          <a href={rowHistory?.items[0]?.syarat_evaluation?.media_url} onClick={(e)=>{
                            e.preventDefault();
                            setCurrentPreview(rowHistory?.items[0]?.syarat_evaluation?.media_url)
                          }}>
                            <small>
                              <strong>
                                <i className={"fa fa-chevron-right me-2"}></i>
                                Lihat File
                              </strong>
                            </small>
                          </a>
                        </div>
                      )}
                    </td>
                    <td className={'border p-2'}>
                      {rowHistoryItem?.vendor_note}
                    </td>
                  </tr>
                )
              })}
            </>
          )
        })}
        </tbody>
      </table>
      <PreviewAttachment previewExt={currentPreview} onHide={()=>setCurrentPreview(null)} />
    </div>
  )
}


const KlarifikasiPenawaranVendorPage = ({proposalTender}: any) => {
  const intl = useIntl()
  const [activeKey, setActiveKey] = useState('1')

  useEffect(() => {
    if(!proposalTender?.is_on_clarification) {
      setActiveKey('2')
    }
  }, [proposalTender])

  return (
    <div className={'card'}>
      <div className={'card-body'}>


        <KlarifikasiProvider>
          <div>
            <Nav justified appearance={'subtle'} activeKey={activeKey} onSelect={setActiveKey}>
              <Nav.Item
                eventKey={'1'}
                className={`d-flex justify-content-center ${proposalTender?.is_on_clarification ? 'text-danger':""}`}
                disabled={!proposalTender?.is_on_clarification}
              >
                {intl.formatMessage({id: 'Klarifikasi Penawaran'})}
                {proposalTender?.is_on_clarification && (
                  <>
                    <i className={'fa fa-info-circle ms-2 blink_me text-danger'}></i>
                  </>
                )}
              </Nav.Item>
              <Nav.Item eventKey={'2'} className={'d-flex justify-content-center'}>
                {intl.formatMessage({id: 'Riwayat Klarifikasi'})}
              </Nav.Item>
            </Nav>

            {activeKey === '1' && <ProsesKlarifikasiTab proposalTender={proposalTender} />}
            {activeKey === '2' && <RiwayatKlarifikasiTab />}
          </div>

        </KlarifikasiProvider>
      </div>
    </div>
  )
}

export default KlarifikasiPenawaranVendorPage