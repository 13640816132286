import {FC, useEffect, useState } from 'react'
import {useLocation} from 'react-router'
import clsx from 'clsx'
import {useLayout} from '../core'
import {DrawerComponent} from '../../assets/ts/components'
import {WithChildren} from '../../helpers'
import ModalContact from './ModalContact'

const Content: FC<WithChildren> = ({ children }) => {
  const [show, setShow] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const {classes} = useLayout()
  const location = useLocation()
  useEffect(() => {
    DrawerComponent.hideAll()
  }, [location])

  return (
    <>
      <div id='kt_content_container' className={clsx(classes.contentContainer.join(' '))}>
        {children}
      </div>
      {/* <ModalContact show={show} handleClose={() => setShow(false)} disabled={false} /> */}
      {/* {isHovered && (
        <div
          style={{
            position: 'fixed',
            bottom: '33px',
            right: '57px',
            backgroundColor: '#50CD89',
            color: 'white',
            padding: '5px',
            borderRadius: '5px',
            fontSize: '14px',
            zIndex: '999',
            transition: 'opacity 0.3s',
            pointerEvents: 'none',
            // paddingRight: '11px'
            marginRight: '11px'
          }}
        >
          Kontak Informasi
        </div>
      )}
      <span
        onClick={() => setShow(true)}
        style={{
          position: 'fixed',
          bottom: '20px',
          right: '5px',
          backgroundColor: '#50CD89',
          borderRadius: '50%',
          padding: '15px',
          cursor: 'pointer',
          zIndex: '999',
          transition: 'background-color 0.3s',
          transform: isHovered ? 'scale(1.1)' : 'scale(1)',
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <i className='fa-solid fa-phone' style={{ color: 'white', fontSize: '25px' }}></i>
      </span> */}
    </>
  )
}

export {Content}
