import { useEffect, useState } from "react";


const PaktaIntegritasAuctionTab = ({ auction, tabulasi }: any) => {
  useEffect(() => {
  }, []);
  return (
    <>
      <h3>Pakta Integritas</h3>
      <p className='py-3' dangerouslySetInnerHTML={{ __html: auction?.petunjuk }}></p>
      <h3>Status Pakta Integritas</h3>
          <div className={"table-responsive"}>
            <table className={"table table-header-gray"}>
              <thead>
                <tr>
                  <th className={"border text-center px-2"} style={{ width: "50px" }}>No</th>
                  <th className={"border text-start px-2 "}>Vendor</th>
                  <th className={"border text-center px-2"} style={{ width: 200 }}>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {tabulasi?.peserta?.length === 0 && (
                    <td className={"border text-center px-2"} colSpan={4}>
                      Tidak ada data
                    </td>
                  )}
                </tr>
                {tabulasi?.peserta?.map((item: any, index: number) => {
                  return (
                    <tr key={index}>
                      <td className={"border text-center px-2"}>{index + 1}</td>
                      <td className={"border text-start px-2 blink"}>{item.vendor.no} - {item.vendor.name}</td>
                      <td
                        className={`border text-center`}
                      >
                        {item?.status == 'inactive' ? '-' : (item?.status == 'rejected' ? 'Rejected' : 'Approve')}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
    </>
  );
};

export { PaktaIntegritasAuctionTab };
