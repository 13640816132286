import React, { useEffect, useState } from "react";
import { KTCard, KTCardBody } from "../../../../../../_metronic/helpers";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import FormikSelect2 from "../../../../../components/shared/form/FormikSelect2";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import ReactQuill from "react-quill";

const PetunjukPeraturanAuction = () => {
    const BASE_URL = process.env.REACT_APP_API_URL;
    const headerFormSchema = Yup.object().shape({});
    const params = useParams();
    const id = params?.['*'];
    const [detailData, setDetailData]: any = useState({});
    const [isLoadingSave, setIsLoadingSave] = useState(false)

    const fetchDetailData = async () => {
        const response: any = await axios.get(`${BASE_URL}/api/auction/auction/${id}`)
        let details = {
            petunjuk: response.data?.data?.petunjuk,
            status: response.data?.data?.status
        }
        setDetailData(details);
    }

    const onSubmit = async (values: any) => {
        setIsLoadingSave(true)
        try {
            let data = { ...values }
            const response: any = await axios.put(`${BASE_URL}/api/auction/auction/petunjuk/` + id, data)
            if (response?.status === 201 || response?.status === 200) {
                toast.success(response?.status + ': ' + response?.data.meta.message, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                })
                return true
            }
        } catch (error) {
            setIsLoadingSave(false)
            console.error(error)
        } finally {
            setIsLoadingSave(false)
        }
    }

    useEffect(() => {
        fetchDetailData();
    }, [])

    return (
        <>
            <KTCard>
                <KTCardBody className="py-4">
                    <Formik
                        enableReinitialize
                        validationSchema={headerFormSchema}
                        initialValues={detailData}
                        onSubmit={onSubmit}
                    >
                        {({ errors, touched, values, setFieldValue }: any) => (
                            <Form>
                                <div className={"row"}>
                                    <div className={"col-md-12"}>
                                        <div className="mb-10">
                                            <label className="form-label mb-3">Peraturan</label>
                                            <ReactQuill
                                                value={values?.petunjuk}
                                                theme='snow'
                                                onChange={(value) => {
                                                    setFieldValue('petunjuk', value)
                                                }}
                                                modules={{
                                                    toolbar: [
                                                        [{ header: [1, 2, false] }],
                                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                                                        ['link', 'image'],
                                                        ['clean'],
                                                    ],
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {detailData?.status == 'published' && (
                                    <div className={"row"}>
                                        <div className={"col-md-12 text-end"}>
                                            <button className={"btn btn-primary"} disabled={isLoadingSave}>
                                                {isLoadingSave && <Spinner animation={"border"} size={"sm"} className={"me-2"} />}
                                                <i className={"fa fa-save"}></i>
                                                Simpan
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </Form>
                        )
                        }
                    </Formik>
                </KTCardBody>
            </KTCard>
        </>
    );
};

export { PetunjukPeraturanAuction };
