import { Navigate, Route, Routes } from 'react-router-dom'
import { FC, lazy, Suspense, useState, createContext } from 'react'
import { WithChildren } from '../../_metronic/helpers'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import TopBarProgress from 'react-topbar-progress-indicator'
import { AuctionDraft } from "../modules/auction/list/components/details/AuctionDraft";
import { useAuth } from '../modules/auth'
import { TabVendorAuction } from '../modules/auction/vendor/TabVendorAuction'
import { TabulasiVendorAuction } from '../modules/auction/vendor/TabulasiVendorAuction'
import AuctionVendorPage from '../modules/auction/AuctionVendorPage'
import moment from 'moment';

interface AuctionContextNode {
  itemPesertaState: boolean;
  setItemPesertaState: any;
  auctionDetail: any;
  setAuctionDetail: any;
}

export const AuctionContext = createContext({} as AuctionContextNode)

const AuctionProvider: FC<any> = ({children}) => {
  const [itemPesertaState, setItemPesertaState] = useState<boolean>(false);
  const [auctionDetail, setAuctionDetail]:any = useState({
    nama: '',
    status: '',
    nomor: '',
    type: '',
    metode_evaluasi: '',
    metode_peringkat: '',
    visibilitas_peringkat: '',
    visibilitas_harga: '',
    jenis_penerimaan: '',
    opsi_peringkat: '',
    perusahaan: '',
    batas_awal: '',
    batas_akhir: '',
    catatan: '',
    petunjuk: '',
    isReadyPublish: false,
    startDate: moment(),
    endDate: moment(),
  });
  return (
    <AuctionContext.Provider value={{
      itemPesertaState, setItemPesertaState, auctionDetail, setAuctionDetail
    }}>
      {children}
    </AuctionContext.Provider>
  )
}

const AuctionRoutes = () => {
  const MVBTemplatePage = lazy(() => import('../modules/auction/MVBTemplatePage'))
  const AuctionPage = lazy(() => import('../modules/auction/AuctionPage'))
  const { currentUser, logout } = useAuth()

  return (
    <AuctionProvider>
        <Routes>
          <Route index element={<Navigate to='/auction/list' />} />
          {currentUser?.has_roles?.includes("user_internal") && (
            <>
              <Route path='list/*' element={<SuspensedView><AuctionPage /></SuspensedView>} />
              <Route path='draft/*' element={<SuspensedView><AuctionDraft /></SuspensedView>} />
            </>
          )}
          {currentUser?.has_roles?.includes("vendor") && (
            <>
              <Route path='list/*' element={<SuspensedView><AuctionVendorPage /></SuspensedView>} />
              <Route path='draft/*' element={<SuspensedView><TabVendorAuction /></SuspensedView>} />
            </>
          )}
        </Routes>
    </AuctionProvider>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export default AuctionRoutes
