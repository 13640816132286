import { ID, KTCard, KTCardBody } from '../../../../../../_metronic/helpers'
import React, { useState, useEffect } from 'react'
import { ApiUrl } from '../../../../../config/Api'
import { getEnv } from "../../../../../../helpers/procurex";
import { initialQueryState, KTSVG, useDebounce } from '../../../../../../_metronic/helpers'
import { toast } from 'react-toastify'
import axios from 'axios'
import { Button, ButtonGroup, Col, Modal, Nav, Row, Tab } from "react-bootstrap";
import { useAuth } from "../../../../auth";
import * as pdfjsLib from 'pdfjs-dist';
import { Link, useNavigate } from "react-router-dom"

const getFirstPageThumbnail = async (pdfUrl) => {
    const pdf = await pdfjsLib.getDocument(pdfUrl).promise;
    const page = await pdf.getPage(1);
    const viewport = page.getViewport({ scale: 1 });
    const canvas = document.createElement('canvas');
    canvas.width = viewport.width;
    canvas.height = viewport.height;
    const context = canvas.getContext('2d');
    await page.render({ canvasContext: context, viewport }).promise;
    return canvas.toDataURL();
};

const BuletinRepositoryStockview = ({ filteredItems, groupedItems }) => {
    const [thumbnails, setThumbnails] = useState({});

    useEffect(() => {
        if (groupedItems && Object.keys(groupedItems).length > 0) {
            Object.keys(groupedItems).forEach(jenis => {
                const items = groupedItems[jenis];
                items.forEach((item, idx) => {
                    getFirstPageThumbnail(item.file_path).then(thumbnail => {
                        setThumbnails(prevThumbnails => ({
                            ...prevThumbnails,
                            [jenis]: {
                                ...prevThumbnails[jenis],
                                [idx]: thumbnail,
                            },
                        }));
                    });
                });
            });
        } else {
            setThumbnails({});
        }
    }, [groupedItems]);

    const navigate = useNavigate();

    const handleDetailClick = (item) => {
        navigate("/stockview/buletin-price/dashboard/components/DetailBuletinStockview", {
            state: {
                id: item.id,
                file_path: item.file_path,
                judul: item.judul,
                jenis_publikasi: item.jenis_publikasi,
                tanggal_keluar_publikasi: item.tanggal_keluar_publikasi,
                date_created: item.date_created
            },
        });
    };

    return (
        <Tab.Container id='left-tabs-example' defaultActiveKey={Object.keys(groupedItems)[0]}>
            <Row>
                <Col sm={4}>
                    <Nav variant='pills' className='flex-column'>
                        {Object.keys(groupedItems).map((jenis, index) => (
                            <Nav.Item key={index}>
                                <Nav.Link className='py-5 fs-4' eventKey={jenis}>
                                    {jenis}
                                </Nav.Link>
                            </Nav.Item>
                        ))}
                    </Nav>
                </Col>
                <Col sm={8}>
                    <Tab.Content>
                        {Object.keys(groupedItems).map((jenis, index) => (
                            <Tab.Pane eventKey={jenis} key={index}>
                                {groupedItems[jenis].map((item, idx) => (
                                    <div key={idx}>
                                        <div className='card text-bg-light m-2' style={{ borderRadius: '2rem', boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.5)' }}>
                                            <img
                                                src={thumbnails[jenis]?.[idx] || 'https://img.icons8.com/?size=100&id=13654&format=png&color=000000'}
                                                style={{ filter: 'blur(25px)', backgroundColor: '#fff', opacity: '1', height: '280px' }}
                                                alt=""
                                            />
                                            <div className='card-img-overlay'>
                                                <img
                                                    className='mt-1 rounded rounded-5 border border-white border-5'
                                                    src={thumbnails[jenis]?.[idx] || 'https://img.icons8.com/?size=100&id=13654&format=png&color=000000'}
                                                    style={{
                                                        width: '500px', height: '250px', display: 'block', margin: 'auto', objectFit: 'cover',
                                                    }}
                                                    alt=""
                                                />
                                            </div>
                                            <div className='card-body bg-white' style={{ zIndex: '2' }}>
                                                <span className='text-gray-500' style={{ height: '100px', fontSize: '14px' }}>
                                                    {item.jenis_publikasi}
                                                </span>
                                                <h5 className='card-title' style={{ height: '100px', fontSize: '20px', fontWeight: 'Bold' }}>
                                                    {item.judul}
                                                </h5>
                                                <button onClick={() => handleDetailClick(item)} className='btn btn-lg btn-primary col-12'>
                                                    Detail Buletin
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Tab.Pane>
                        ))}
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
    );
};

export { BuletinRepositoryStockview };