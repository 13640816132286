import {IntlShape, useIntl} from 'react-intl'
import {IColumns} from '../../../../../components/shared/TableX'
import {CellProps, Column, Renderer} from 'react-table'
import {ClarificationTenderItemModel} from '../../../core/_models'
import {Link, useParams} from 'react-router-dom'
import React, {useMemo} from 'react'
import {KTCard, KTCardBody} from '../../../../../../_metronic/helpers'
import TableXWrapper from '../../../../../components/shared/TableXWrapper'
import {formatDateTimeToDBFormat, getEnv} from '../../../../../../helpers/procurex'
import {useClarificationTenderItemQuery} from '../../evaluation/core/_queries'
import {EvaluationIncomingForm} from '../../evaluation/components/EvaluationIncomingDetail'
import {darkModeClass} from '../../../../../../helpers/utils'
import {Placeholder} from 'rsuite'

const TENDER_API_URL = getEnv('tender')

export const MonitoringEvaluationDetail = () => {
  const intl = useIntl()
  const {uuid} = useParams()

  const {data: clarificationTenderItem, isLoading} = useClarificationTenderItemQuery(
    uuid,
    {
      include: [
        'poText',
        'vendors.clarification_attachments',
        'evaluator_results.notes',
        'evaluator_results.params',
      ],
    },
    {staleTime: Infinity}
  )

  return (
    <>
      <div className='mb-5 d-flex'>
        <div>
          <h1>
            {clarificationTenderItem !== undefined && !isLoading ? (
              `Evaluasi PR ${clarificationTenderItem.no_pr} Item ${clarificationTenderItem.line_no} (${clarificationTenderItem.short_text})`
            ) : (
              <Placeholder.Graph height={20} width={500} />
            )}
          </h1>
        </div>
      </div>

      <div className={'mb-4 d-flex'}>
        <div>
          <Link to={'..'} className={'btn btn-danger btn-sm'}>
            <i className={'fa fa-arrow-left'}></i>
            {intl.formatMessage({id: 'Kembali ke list'})}
          </Link>
        </div>
      </div>

      <EvaluationIncomingForm
        clarificationTenderItem={clarificationTenderItem}
        isReadOnly={true}
        hideBottomToolbar={true}
        isLoading={isLoading}
      />
    </>
  )
}

export type CellRenderer = Renderer<CellProps<ClarificationTenderItemModel>>

const getTableColumns = (
  intl: IntlShape,
  actionCell?: CellRenderer | undefined
): IColumns[] | Column<ClarificationTenderItemModel>[] => [
  {
    Header: intl.formatMessage({id: 'Aksi'}),
    accessor: 'name',
    width: 100,
    className: 'text-start fixed-column',
    Cell:
      actionCell ??
      (({row}) => (
        <Link relative={'route'} to={row?.original?.uuid} className={`me-2 ${darkModeClass()}`}>
          <i className={`fa fs-3 fa-eye ${darkModeClass()}`} />
        </Link>
      )),
  },
  {
    Header: 'No Tender',
    accessor: 'no_tender',
    orderable: false,
    disableSortBy: true,
    width: 175,
  },
  {
    Header: intl.formatMessage({id: 'Nomor PR'}),
    accessor: 'no_pr',
    orderable: false,
    disableSortBy: true,
    width: 150,
  },
  {
    Header: intl.formatMessage({id: 'Item PR'}),
    accessor: 'line_no',
    orderable: false,
    disableSortBy: true,
    width: 150,
  },
  {
    Header: intl.formatMessage({id: 'Status'}),
    accessor: 'status_description',
    width: 250,
  },
  {
    Header: intl.formatMessage({id: 'Posisi Dokumen'}),
    accessor: 'current_user_data',
    searchable: false,
    orderable: false,
    disableSortBy: true,
    width: 250,
    Cell: ({value}) => (value ? `${value?.username} - ${value?.name}` : ''),
  },
  {
    Header: intl.formatMessage({id: 'Kategori Evaluasi'}),
    accessor: 'categories',
    orderable: false,
    disableSortBy: true,
    width: 150,
    Cell: ({value}) => (
      <>
        {Array.isArray(value) && value.length > 0 && (
          <ul>
            {value.map((item: string, index: number) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        )}
      </>
    ),
  },
  {
    Header: intl.formatMessage({id: 'Parameter'}),
    accessor: 'parameters',
    orderable: false,
    disableSortBy: true,
    width: 200,
    Cell: ({value}) => (
      <>
        {Array.isArray(value) && value.length > 0 && (
          <ul>
            {value.map((item: string, index: number) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        )}
      </>
    ),
  },
  {
    Header: intl.formatMessage({id: 'Short Text'}),
    accessor: 'short_text',
    orderable: false,
    disableSortBy: true,
    width: 150,
  },
  {
    Header: intl.formatMessage({id: 'Buyer'}),
    accessor: 'created_by',
    orderable: false,
    disableSortBy: true,
    width: 250,
    Cell: ({value}) =>
      typeof value !== 'string' && value?.username ? `${value.username} - ${value.name}` : value,
  },
  {
    Header: intl.formatMessage({id: 'Tanggal Kirim'}),
    accessor: 'created_at',
    searchable: false,
    className: 'text-center',
    Cell: ({value}) => (value ? formatDateTimeToDBFormat(value) : ''),
  },
]

const MonitoringEvaluationList = ({actionCell}: {actionCell?: CellRenderer | undefined}) => {
  const intl = useIntl()
  const columns = useMemo(() => getTableColumns(intl, actionCell) as IColumns[], [actionCell, intl])

  return (
    <KTCard>
      <KTCardBody className='py-4'>
        <TableXWrapper
          readonly={true}
          urlIndex={`${TENDER_API_URL}/clarification/tender-item?sort=-updated_at&source=monitoring&include=currentUserData`}
          columns={columns}
          disablePaddingCard={true}
        />
      </KTCardBody>
    </KTCard>
  )
}

export default MonitoringEvaluationList
