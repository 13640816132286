import { Outlet, Route, Routes } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../../_metronic/layout/core'
import React, { useState, useEffect } from 'react'
import Select2FormX from '../../../../components/shared/form/Select2FormX'
import DatepickerX2 from "../../../../components/shared/form/DatepickerX2";
import ReactApexChart from 'react-apexcharts'
import { useTable } from 'react-table'
import { ApiUrl } from '../../../../config/Api'
import { getEnv } from "../../../../../helpers/procurex";
import TableXWrapper from '../../../../components/shared/TableXWrapper'
import axios, { AxiosResponse } from 'axios'
import {
    ProductionStockviewList,
} from './components/ProductionStockviewList'

const masterData = {
    title: 'Production',
    path: '/stockview/transaction/production',
    isSeparator: false,
    isActive: false,
}
const separator = {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
}
const breadcrumbs: Array<PageLink> = [masterData, separator]
const childBreadcrumbs: Array<PageLink> = [
    masterData,
    separator,
    {
        title: 'Stockview Production',
        path: '/stockview/transaction/production',
        isSeparator: false,
        isActive: false,
    },
    separator,
]

const ProductionStockview = () => {
    // @ts-ignore
    const chartSeries = [
        {
            name: 'North',
            data: [],
        },
    ]

    const chartOptions = {}

    const [activeTab, setActiveTab] = useState(0) // State to track the active tab
    const [items, setItems] = useState<any>([])
    const [itemsMaping, setItemsMaping] = useState<any>([])
    const [itemsCompanyStock, setItemsCompanyStock] = useState<any>([])
    const [itemsMainCardStock, setItemsMainCardStock] = useState<any>([]);
    const [activeCompany, setActiveCompany] = useState<any>([]);
    const [itemsIDGrouping, setIDGrouping] = useState<any>({});
    const [itemLoading, setItemLoading] = useState(false)

    const handleTabClick = (index: any) => {
        setActiveTab(index)
    }

    let dateTime = new Date()
    const options: any = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        // timeZoneName: 'short',
        timeZone: 'Asia/Jakarta', // Set the time zone to WIB
    }

    let formattedDateTime = dateTime.toLocaleDateString('en-GB', options)

    // Convert the formatted date and time to uppercase
    formattedDateTime = formattedDateTime.toUpperCase()

    const [textareaValue, setTextareaValue] = useState('')

    const handleTextareaChange = (e: any) => {
        setTextareaValue(e.target.value)
    }

    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    index
                    element={
                        <>
                            <PageTitle breadcrumbs={breadcrumbs}>Production Stockview</PageTitle>
                            <ProductionStockviewList />
                        </>
                    }
                ></Route>
            </Route>
        </Routes>
    )
}

export default ProductionStockview
