import React from "react";
import Swal from "sweetalert2";
import {isImpersonate, logoutImpersonate, useAuth} from '../../../../app/modules/auth'
import { toast } from "react-toastify";
import { useIntl } from "react-intl";

const HeaderImpersonate: React.FC = () => {
  const { currentUser, logout } = useAuth()
  const intl = useIntl()
  const handleLogoutImpersonate = async () => {
    Swal.fire({
      title: `${intl.formatMessage({id:"Apakah Anda yakin"})}?`,
      html: `${intl.formatMessage({id:"Anda akan logout dari Mode Impersonate"})}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Ya, ${intl.formatMessage({id:"Logout"})}!`,
      cancelButtonText: intl.formatMessage({id:"Cancel"}),
    }).then((result) => {
      if (result.isConfirmed) {
        logoutImpersonate()
        toast.success('Berhasil Logout Impersonate')
        setTimeout(()=>{
          if(currentUser?.has_roles?.includes("vendor")) {
            window.location.href = '/procure-x/user-management/user'
          } else {
            window.location.reload();
          }
        },2000)
      }
    })
  }
  return (
    <>
      {isImpersonate() && (
        <div className={"w-100 p-2 px-4 mb-2"} style={{
          backgroundColor: "red"
        }}>
          <div className={"text-white text-center"} style={{}}>
            <i className={"fa fa-user-astronaut fs-2 me-3"}></i>{" "}
            Anda sedang berada dalam mode Impersonate
            <button className={"btn btn-light btn-sm ms-4"} type={"button"}
                    onClick={handleLogoutImpersonate}>
              <i className={"fa fa-power-off"}></i>
              Keluar
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default HeaderImpersonate