import { ID, KTCard, KTCardBody } from '../../../../../../_metronic/helpers'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import TableXWrpper from "../../../../../components/shared/TableXWrapper";
import { ApiUrl } from '../../../../../config/Api'
import { getEnv } from "../../../../../../helpers/procurex";
import { useIntl } from 'react-intl'
import SelectX from '../../../../../components/shared/form/SelectX'
import { MetronicErrorMessage, toastError } from '../../../../../../_metronic/helpers'
import SwalX from '../../../../../components/shared/SwalX'
import { InputNumber } from "rsuite";
import { Field, Form, Formik } from 'formik'
import { toast } from 'react-toastify'
import axios from 'axios'
import { Button, ButtonGroup, Col, Modal, Row } from "react-bootstrap";
import { useAuth } from "../../../../../../app/modules/auth";
import DatepickerX2 from "../../../../../components/shared/form/DatepickerX2";

const ArrivalStockviewList = () => {
    const { currentUser } = useAuth();
    const intl = useIntl()
    const tablecolumns = [
        {
            Header: "MATERIAL NUMBER",
            accessor: "material_number",
            width: 250,
        },
        {
            Header: "PLANT",
            accessor: "plant",
            width: 200,
        },
        {
            Header: "SLOC",
            accessor: "sloc",
            width: 100,
        },
        {
            Header: "QUANTITY",
            accessor: "qty",
            width: 100,
        },
        {
            Header: "ETA",
            accessor: "eta",
            width: 100,
            Cell: ({ value }) => {
                if (!value) return ''; // Handle null or undefined values
                const date = new Date(value);
                const day = date.getDate().toString().padStart(2, '0'); // Get day and pad with leading zero if necessary
                const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get month (+1 because month index starts from 0) and pad with leading zero if necessary
                const year = date.getFullYear(); // Get full year
                return `${day}-${month}-${year}`;
            }
        },
        {
            Header: "REFERENCE",
            accessor: "reference",
            width: 150,
        },
        {
            Header: "MODA TRANSPORTASI",
            accessor: "moda_transportasi",
            width: 150,
        },
        {
            Header: "NAMA TRANSPORTASI",
            accessor: "nama_transportasi",
            width: 150,
        },
        {
            Header: "STATUS KEDATANGAN",
            accessor: "status_kedatangan",
            width: 150,
        },
        {
            Header: "Action",
            accessor: "uuid",
            width: 180,
            className: "text-center fixed-column",
            Cell: (props: any) => {
                const row = props.row.original;
                return (
                    <ButtonGroup size='sm' aria-label='Action'>
                        {!excludedRoles.some(r => role.includes(r)) && (
                            <>
                                <button
                                    type='button'
                                    className='btn btn-sm btn-success'
                                    onClick={(e: any) => {
                                        e.preventDefault();
                                        setId(row.id);
                                        openModalWithItem(row); // Pass the row item to the function
                                    }}
                                >
                                    Edit
                                </button>
                                <button
                                    type='button'
                                    className='btn btn-sm btn-danger'
                                    onClick={() => {
                                        setItemId(props.row.original.id)
                                        setIsConfirmDeleteOpen(true)
                                    }}
                                >
                                    <i className={'fa fa-trash'} />
                                </button>
                            </>
                        )}
                    </ButtonGroup>
                );
            },
        },
    ];
    const [formindex, setFormIndex] = useState(0);
    const [isShowModal, setIsShowModal] = useState(false)
    const [isShowDetail, setIsShowDetail] = useState(false)
    const [key, setKey] = useState(0)
    const [id, setId] = useState('')
    const [item, setItem] = useState<any>(null)
    const [isConfirmDialog, setIsConfirmDialog] = useState(false)

    const [totalPR, setTotalPR] = useState(0);
    const [totalQTY, setTotalQTY] = useState(0);
    const [totalContract, setTotalContract] = useState(0);

    const handleOnTotalPRChange = (score: number) => {
        setTotalPR(score)
    }
    const handleOnTotalContractChange = (score: number) => {
        setTotalContract(score)
    }
    const handleOnTotalQTYChange = (score: number) => {
        setTotalQTY(score)
    }

    const [selectedModaTransportasi, setSelectedModaTransportasi] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [selectedNamaTransportasi, setSelectedNamaTransportasi] = useState(null);
    const [selectedMaterial, setSelectedMaterial] = useState(null);
    const [selectedPlant, setSelectedPlant] = useState(null);
    const [selectedSloc, setSelectedSloc] = useState(null);

    const handleModaTransportasiSelect = (selectedValue: any) => {
        setSelectedModaTransportasi(selectedValue);
    };

    const handleStatusSelect = (selectedValue: any) => {
        setSelectedStatus(selectedValue);
    };

    const handleNamaTransportasiSelect = (selectedValue: any) => {
        setSelectedNamaTransportasi(selectedValue);
    };

    const handleMaterialSelect = (selectedValue: any) => {
        setSelectedMaterial(selectedValue);
    };

    const handlePlantSelect = (selectedValue: any) => {
        setSelectedPlant(selectedValue);
    };

    const handleSlocSelect = (selectedValue: any) => {
        setSelectedSloc(selectedValue);
    };

    const [itemsMasterMaterial, setItemsMasterMaterial] = useState<any>([])
    const [itemsMasterCompany, setItemsCompanyStock] = useState<any>([])
    const [ItemsTransportasi, setItemsTransportasi] = useState<any>([])
    const [ItemsPlant, setItemsPlant] = useState<any>([])
    const [ItemsSloc, setItemsSloc] = useState<any>([])
    const [itemLoading, setItemLoading] = useState(false)
    const excludedRoles = ['Gudang_Bahan_Baku_Anper', 'Managerial_PI', 'RendalProd_Anper'];

    const loadItem = () => {
        axios
            .get(getEnv("stockview") + `/master-material`)
            .then((res) => {
                setItemsMasterMaterial(res.data.data)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setItemLoading(false)
            })
        axios
            .get(getEnv("stockview") + `/company-stock?perPage=50&page=1`)
            .then((res) => {
                setItemsCompanyStock(res.data.data)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setItemLoading(false)
            })
        axios
            .get(getEnv("stockview") + `/transportation?perPage=1000&page=1`)
            .then((res) => {
                setItemsTransportasi(res.data.data)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setItemLoading(false)
            })
        axios
            .get(getEnv("stockview") + `/material-plant-all?filter[active_app]=X&company_code=${company_code_user_first}`)
            .then((res) => {
                setItemsPlant(res.data.data)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setItemLoading(false)
            })
        axios
            .get(getEnv("stockview") + `/sloc?company_code=${company_code_user_first}`)
            .then((res) => {
                const uniqueItems = res.data.data.filter((item, index, self) =>
                    index === self.findIndex((t) => t.code === item.code)
                );
                console.log(uniqueItems);

                setItemsSloc(uniqueItems);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setItemLoading(false);
            });
    }

    useEffect(() => {
        loadItem();
    }, [])

    const submit = async (values: any) => {
        const { materialnumberLabel, plantLabel, slocLabel, namaTransportasiLabel } = await processItem(item);

        try {
            const newData = {
                reference: values.reference,
                moda_transportasi: selectedModaTransportasi ? selectedModaTransportasi : item?.moda_transportasi,
                nama_transportasi: selectedNamaTransportasi ? selectedNamaTransportasi : namaTransportasiLabel,
                material_number: selectedMaterial ? selectedMaterial : materialnumberLabel,
                plant: selectedPlant ? selectedPlant : plantLabel,
                sloc: selectedSloc ? selectedSloc : slocLabel,
                pr: totalPR ? totalPR : item?.pr,
                qty: totalQTY ? totalQTY : item?.qty,
                contract: totalContract ? totalContract : item?.contract,
                keterangan: values.keterangan,
                po: values.po,
                eta: values.eta,
                actual_gr_date: values.actual_gr_date
            };

            const response = await axios.put(getEnv("stockview") + `/kedatangan-transaction/${id}`, newData)

            if (response.status === 200 || response.status === 201) {
                toast.success(response.data.meta.message)
                setId('')
                setIsShowModal(false)
                // setFormIndex(formindex + 1)
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.meta?.message || 'Terjadi kesalahan')
        } finally {
            setIsConfirmDialog(false)
            setIsShowDetail(false)
        }
    }

    let [itemId, setItemId] = useState(0)
    let [isLoadingDelete, setIsLoadingDelete] = useState(false)
    let [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false)

    const deleteItem = async () => {
        try {
            setIsLoadingDelete(true)
            const response: any = await axios.delete(getEnv("stockview") + `/kedatangan-transaction/${itemId}`)

            if (response.status === 200) {
                // setFormIndex(formindex + 1)
                setIsConfirmDeleteOpen(false)
                toast.success(response?.data?.meta?.message, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                })
                loadItem()
            }
        } catch (error: any) {
            console.error(error)
            toastError(error.response?.data?.meta?.message || 'Terjadi kesalahan')
        } finally {
            setIsLoadingDelete(false)
        }
    }

    const defaultInitialValues = {
        reference: '',
        moda_transportasi: '',
        nama_transportasi: '',
        material_number: '',
        plant: '',
        pr: '',
        eta: '',
        actual_gr_date: '',
        qty: '',
        keterangan: '',
        po: '',
        sloc: '',
        contract: '',
    };

    const [initialValues, setInitialValues] = useState<any>(defaultInitialValues);
    const processItem = async (item: any) => {
        const materialnumber = item?.material_number || '';
        const materialnumberParts = materialnumber.split(' - ');
        const materialnumberLabel = materialnumberParts[0];

        const plant = item?.plant || '';
        const plantParts = plant.split(' - ');
        const plantLabel = plantParts[0];

        const sloc = item?.sloc || '';
        const slocParts = sloc.split(' - ');
        const slocLabel = slocParts[0];

        const namaTransportasi = item?.nama_transportasi || '';
        const namaTransportasiParts = namaTransportasi.split(' - ');
        const namaTransportasiLabel = namaTransportasiParts[0];

        return { materialnumberLabel, plantLabel, slocLabel, namaTransportasiLabel };
    };

    const openModalWithItem = async (item: any) => {
        const { materialnumberLabel, plantLabel, slocLabel, namaTransportasiLabel } = await processItem(item);

        setInitialValues({
            reference: item?.reference || '',
            moda_transportasi: item?.moda_transportasi || '',
            nama_transportasi: namaTransportasiLabel,
            material_number: materialnumberLabel,
            plant: plantLabel,
            pr: item?.pr || '0',
            eta: item?.eta || '',
            actual_gr_date: item?.actual_gr_date || '',
            qty: item?.qty || '',
            keterangan: item?.keterangan || '',
            po: item?.po || '',
            sloc: slocLabel,
            contract: item?.contract || '0',
        });
        setItem(item);
        setIsShowModal(true);
    };

    let company_code_user = currentUser.company_code;
    let company_code_user_first = currentUser.company_code[0];
    let role = currentUser.has_roles;

    return (
        <>
            <SwalX
                title={intl.formatMessage({ id: 'DATATABLE.CONFIRM_DELETE' })}
                message={intl.formatMessage({ id: 'DATATABLE.MESSAGE_DELETE' })}
                show={isConfirmDeleteOpen}
                loading={isLoadingDelete}
                onHide={() => setIsConfirmDeleteOpen(false)}
                onSubmit={() => deleteItem()}
            />

            <Modal size="xl" show={isShowModal} onHide={() => setIsShowModal(false)}>
                <Modal.Header>Edit Kedatangan Stockview</Modal.Header>
                <Modal.Body>
                    <Formik initialValues={initialValues} onSubmit={submit} enableReinitialize>
                        {({ values, setFieldValue }) => (
                            <Form>
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label'>
                                                Reference
                                            </label>
                                            <Field className='form-control' name="reference" placeholder="input reference.." value={values.reference} />
                                            {/* <MetronicErrorMessage name='sap_no' /> */}
                                        </div>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label'>
                                                Moda Transportasi
                                            </label>
                                            <SelectX
                                                defaultValue={initialValues.moda_transportasi}
                                                onChange={handleModaTransportasiSelect}
                                                options={[
                                                    { label: 'Shipping', value: 'Shipping' },
                                                    { label: 'Truck', value: 'Truck' },
                                                ]}
                                            />
                                            {/* <MetronicErrorMessage name='sap_no' /> */}
                                        </div>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label'>
                                                Nama Transportasi
                                            </label>
                                            <SelectX
                                                defaultValue={initialValues.nama_transportasi}
                                                onChange={handleNamaTransportasiSelect}
                                                options={ItemsTransportasi.map((item: any) => ({
                                                    label: `${item.name} - ${item.capacity}`,
                                                    value: `${item.name}`
                                                }))}
                                            />
                                            {/* <MetronicErrorMessage name='sap_no' /> */}
                                        </div>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label'>
                                                Material Number *
                                            </label>
                                            <SelectX
                                                defaultValue={initialValues.material_number.trim()}
                                                onChange={handleMaterialSelect}
                                                options={itemsMasterMaterial.map((item: any) => ({
                                                    label: `${item.material_number} - ${item.material_desc}`,
                                                    value: item.material_number.trim()
                                                }))}
                                            />
                                            {/* <MetronicErrorMessage name='sap_no' /> */}
                                        </div>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label'>
                                                Plant *
                                            </label>
                                            <SelectX
                                                defaultValue={initialValues.plant}
                                                onChange={handlePlantSelect}
                                                options={ItemsPlant.map((item: any) => ({
                                                    label: `${item.kode_plant} - ${item.nama_plant}`,
                                                    value: item.kode_plant
                                                }))}
                                            />
                                            {/* <MetronicErrorMessage name='npwp_no' /> */}
                                        </div>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label'>
                                                Sloc
                                            </label>
                                            <SelectX
                                                defaultValue={initialValues.sloc}
                                                onChange={handleSlocSelect}
                                                options={ItemsSloc.map((item: any) => ({
                                                    label: `${item.code}`,
                                                    value: item.code
                                                }))}
                                            />
                                            {/* <MetronicErrorMessage name='npwp_no' /> */}
                                        </div>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label'>
                                                PR
                                            </label>
                                            <InputNumber
                                                defaultValue={initialValues.pr}
                                                min={0}
                                                name="pr"
                                                className={"w-100 text-end"}
                                                onChange={(value: any) => {
                                                    handleOnTotalPRChange(value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label'>
                                                Quantity *
                                            </label>
                                            <InputNumber
                                                defaultValue={initialValues.qty}
                                                min={0}
                                                name="qty"
                                                className={"w-100 text-end"}
                                                onChange={(value: any) => {
                                                    handleOnTotalQTYChange(value)
                                                }}
                                            />
                                        </div>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label'>
                                                Eta *
                                            </label>
                                            <DatepickerX2
                                                onChange={(date: any) => setFieldValue("eta", date)}
                                                size={"lg"}
                                                value={values.eta}
                                                format={"dd-MM-yyyy"}
                                            />
                                        </div>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label'>
                                                Actual GR Date
                                            </label>
                                            <DatepickerX2
                                                onChange={(date: any) => setFieldValue("actual_gr_date", date)}
                                                size={"lg"}
                                                value={values.actual_gr_date}
                                                format={"dd-MM-yyyy"}
                                            />
                                        </div>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label'>
                                                Keterangan
                                            </label>
                                            <Field className='form-control' name="keterangan" placeholder="input keterangan.." value={values.keterangan} />
                                            {/* <MetronicErrorMessage name='sap_no' /> */}
                                        </div>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label'>
                                                PO
                                            </label>
                                            <Field className='form-control' name="po" placeholder="input po.." value={values.po} />
                                            {/* <MetronicErrorMessage name='sap_no' /> */}
                                        </div>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label'>
                                                Contract
                                            </label>
                                            <InputNumber
                                                defaultValue={initialValues.contract}
                                                min={0}
                                                name="contract"
                                                className={"w-100 text-end"}
                                                onChange={(value: any) => {
                                                    handleOnTotalContractChange(value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='d-flex gap-4 mt-5'>
                                    <button type='submit' className='btn btn-primary'>
                                        Save
                                    </button>
                                    <button
                                        type='button'
                                        className='btn btn-secondary'
                                        onClick={() => {
                                            setId('')
                                            setIsShowModal(false)
                                        }}
                                    >
                                        Batal
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>

            <KTCard className='card border mt-5'>
                <KTCardBody >
                    <TableXWrpper
                        title={"Arrival"}
                        key={formindex}
                        readonly
                        urlIndex={getEnv("stockview") + `/kedatangan-view?company_code=${company_code_user}`}
                        columns={tablecolumns}
                        headerButtons={
                            !excludedRoles.some(r => role.includes(r)) && (
                                <Link
                                    to="/stockview/transaction/arrival/create"
                                    className="btn btn-primary"
                                    title="Add Data"
                                >
                                    Add Data
                                </Link>
                            )
                        }
                    />
                </KTCardBody>
            </KTCard>
        </>
    )
}

export { ArrivalStockviewList }
