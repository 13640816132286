import { AuthModel } from './_models'
import { toast } from 'react-toastify'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'

const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    // console.log(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const setAuthImpersonate = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY+"_original", localStorage.getItem(AUTH_LOCAL_STORAGE_KEY) as string)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const logoutImpersonate = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY,  localStorage.getItem(AUTH_LOCAL_STORAGE_KEY+"_original") as string)
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY+"_original")
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const isImpersonate = () => {
  if (!localStorage) {
    return false;
  }

  try {
    return localStorage.getItem(AUTH_LOCAL_STORAGE_KEY+"_original") ? true : false
  } catch (error) {
    return false
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setupAxios(axios: any, onError: Function) {
  axios.defaults.baseUrl = 'http://localhost:8000/api'
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: {headers: {Authorization: string}, url: string}) => {
      const auth = getAuth()
      if (auth && auth.access_token) {
        config.headers.Authorization = `Bearer ${auth.access_token}`;
        // @ts-ignore
        config.headers['Procurex-Is-Impersonate'] = isImpersonate() ? "true" : "false"
        // @ts-ignore
        config.headers['Procurex-User-Impersonate'] = isImpersonate() ? JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY+"_original"))?.access_token : ""
      }

      // config.url = config.url.replace(process.env.REACT_APP_API_URL + '/api/mr-sr', 'http://127.0.0.1:8000/api')
      // config.url = config.url.replace(process.env.REACT_APP_API_URL + '/api/dashboard-main', 'http://127.0.0.1:8000/api')
      // config.url = config.url.replace(process.env.REACT_APP_API_URL + '/api/dashboardStock-main', 'http://127.0.0.1:8000/api')
      // config.url = config.url.replace(process.env.REACT_APP_API_URL + '/api/cardDashboard-main', 'http://127.0.0.1:8000/api')
      // config.url = config.url.replace(process.env.REACT_APP_API_URL + '/api/chartDashboard-main', 'http://127.0.0.1:8000/api')
      // config.url = config.url.replace(process.env.REACT_APP_API_URL + '/api/kedatangan-list', 'http://127.0.0.1:8000/api')
      // config.url = config.url.replace(process.env.REACT_APP_API_URL + '/api/transmovement-list', 'http://127.0.0.1:8000/api')
      // config.url = config.url.replace(process.env.REACT_APP_API_URL + '/api/transmovement-trans', 'http://127.0.0.1:8000/api')
      // config.url = config.url.replace(process.env.REACT_APP_API_URL + '/api/approval', 'http://127.0.0.1:8081/api')
      // config.url = config.url.replace(process.env.REACT_APP_API_URL + '/api/vendor', 'http://127.0.0.1:8002/api')
      // config.url = config.url.replace(process.env.REACT_APP_API_URL + '/api/masterdata', 'http://127.0.0.1:8001/api')
      // config.url = config.url.replace(process.env.REACT_APP_API_URL + '/api/tender', 'http://127.0.0.1:8004/api')
    // config.url = config.url.replace(process.env.REACT_APP_API_URL + '/api/media', 'http://127.0.0.1:8005/api')
      return config
    },
    (err: any) => {
      alert("ERROR")
      Promise.reject(err)
    }
  )
  axios.interceptors.response.use((response: any) => {
    return response;
  }, function (error: any) {
    onError(error)
    return Promise.reject(error);
  })
}



export { getAuth, setAuth, removeAuth, setAuthImpersonate, AUTH_LOCAL_STORAGE_KEY, logoutImpersonate, isImpersonate }
