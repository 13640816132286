import { BeritaAcaraPenunjukkanPemenangTab } from "./BeritaAcaraPenunjukkanPemenangTab";


const PenunjukkanPemenangPage = ({ proposalTender, onRefresh }: any) => {
  return (
    <div className={"card"}>
      <div className={"card-body"}>
        <div className={"d-flex justify-content-between"}>
          <div><h3>Penunjukkan Pemenang</h3></div>
        </div>
        <hr />
        <BeritaAcaraPenunjukkanPemenangTab
          proposalTender={proposalTender}
          onRefresh={onRefresh}
        />
      </div>
    </div>
  );
};

export default PenunjukkanPemenangPage;