import { ID, KTCard, KTCardBody } from '../../../../../../_metronic/helpers'
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import TableXWrpper from "../../../../../components/shared/TableXWrapper";
import { ApiUrl } from '../../../../../config/Api'
import { getEnv } from "../../../../../../helpers/procurex";
import { useAuth } from "../../../../../../app/modules/auth";

const PlantMasterDataStockviewList = () => {
    const { currentUser } = useAuth();
    const tablecolumns = [
        {
            Header: "KODE PLANT",
            accessor: "kode_plant",
            width: 150,
        },
        {
            Header: "MATERIAL NUMBER",
            accessor: "material_number",
            width: 200,
        },
        {
            Header: "MATERIAL DESC",
            accessor: "material_desc",
            width: 250,
        },
        {
            Header: "NAMA PLANT",
            accessor: "nama_plant",
            width: 200,
        },
        {
            Header: "UOM",
            accessor: "uom",
            width: 150,
        },
        {
            Header: "COMPANY",
            accessor: "company",
            width: 200,
        },
    ];
    const [formIndex, setFormIndex] = useState(0);

    let company_code_user = currentUser.company_code[0];

    return (
        <>
            <KTCard className='card border mt-5'>
                <KTCardBody >
                    <TableXWrpper
                        title={"Plant Master Data"}
                        key={formIndex}
                        readonly
                        urlIndex={getEnv("stockview") + `/material-plant?company_code=${company_code_user}`}
                        columns={tablecolumns}
                    />
                </KTCardBody>
            </KTCard>
        </>
    )
}

export { PlantMasterDataStockviewList }
