import { ID, KTCard, KTCardBody } from '../../../../../../_metronic/helpers'
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import TableXWrpper from "../../../../../components/shared/TableXWrapper";
import { ApiUrl } from '../../../../../config/Api'
import { Button, ButtonGroup, Col, Modal, Row } from "react-bootstrap";
import { getEnv } from "../../../../../../helpers/procurex";
import { useIntl } from "react-intl";

const CompanyMasterDataStockviewList = () => {
    const intl = useIntl();
    const tablecolumns = [
        {
            Header: "Company Code",
            accessor: "company_code",
            width: 500,
        },
        {
            Header: "Company Name",
            accessor: "company_name",
            width: 500,
        },
    ];
    const [formIndex, setFormIndex] = useState(0);

    return (
        <>
            <KTCard className='card border mt-5'>
                <KTCardBody >
                    <TableXWrpper
                        title={"Company Master Data"}
                        key={formIndex}
                        readonly
                        urlIndex={getEnv("stockview") + `/company-stock`}
                        columns={tablecolumns}
                    />
                </KTCardBody>
            </KTCard>
        </>
    )
}

export { CompanyMasterDataStockviewList }
