import React, { useState, useEffect, Fragment } from "react";
import { Modal } from "react-bootstrap";
import { darkModeClass } from "../../helpers/utils";

interface Props {
  previewExt: any,
  onHide: Function
}


const PreviewAttachment = ({ previewExt, onHide }: Props) => {
  // @ts-ignore
  return (
    <>
      {previewExt && (


        <div className={""}>
          <Modal
            show={previewExt ? true : false}
            onHide={() => {
              onHide && onHide();
            }}
            size={"xl"}
            backdrop="static"
            // centered
          >
            <Modal.Header closeButton={true} className="">
              <h4 className={darkModeClass()}>Preview</h4>
            </Modal.Header>
            <Modal.Body className="border overflow-visible">
              {previewExt?.value && (
                <object width="100%" data={previewExt?.value} type="application/pdf">
                  {" "}
                </object>
              )}

              {(previewExt?.ext === "jpg" || previewExt?.ext === "png" || previewExt?.ext === "jpeg") && (
                <>
                  <img className="w-100" src={previewExt?.value} alt="image" />
                </>
              )}

              {(typeof previewExt === "string") && (
                <>
                  <object width="100%" style={{
                    height: "700px"
                  }} data={previewExt} type="application/pdf">
                    {" "}
                  </object>
                </>
              )}
            </Modal.Body>
          </Modal>
        </div>
      )}
    </>
  );
};

export default PreviewAttachment;
