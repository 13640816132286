import React, { FC, useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import ItemPRPage from "./ItemPRPage";
import DokumenPengadaanPage from "./DokumenPengadaanPage";
import JadwalTenderPage from "./JadwalTenderPage";
import { useNavigate } from "react-router-dom";
import RegistrasiTenderPage from "./RegistrasiTenderPage";
import InputPenawaranPage from "./InputPenawaranPage";
import NegotiationVendorPage from "./NegotiationVendorPage";
import PraQualificationPage from "./PraQualificationPage";
import InputPenawaranKomersilPage from "./InputPenawaranKomersilPage";
import { useIntl } from "react-intl";
import { useAxiosGet } from "../../../../../../hooks/useAxios";
import { getEnv } from "../../../../../../helpers/procurex";
import PengumumanPemenangPage from "./PengumumanPemenangPage";
import PenunjukkanPemenangPage from "./PenunjukkanPemenangPage";
import BidOpeningPage from "./BidOpeningPage";
import KlarifikasiPenawaranVendorPage from "./KlarifikasiPenawaranVendorPage";
import AanwijzingVendorDetail from '../../aanwijzing/AanwijzingVendorDetail'

const RowMenu = ({ ...props }: any) => {
  return (
    <div className={"py-3"} style={{
      fontWeight: "normal",
      borderLeft: props.active ? "5px solid #5d78ff" : "",
      paddingLeft: "10px"
    }}>
      <div className={"d-flex justify-content-between w-100"}>
        <div>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href={"#"} onClick={(e) => {
            e.preventDefault();
            if (props.disabled) {
              return;
            }
            props.onClick();
          }} className={`text-decoration-none ${props.disabled
            ? "text-secondary"
            : props?.warning
              ? "text-danger"
              : "text-dark"}`}>
            {props.title}
          </a>
        </div>
        <div>
          {props?.warning && (<i className={"fa fa-warning text-danger me-2 blink_me"}></i> )}
        </div>
      </div>

    </div>
  );
};

enum source {
  "PQ" = "PQ",
  "Proposal Tender" = "Proposal Tender",
  "Quotation" = "Quotation",
}

const HEADER_MENU_INDEX_PROPOSAL_TENDER = 1;
const HEADER_MENU_INDEX_PROSES_TENDER = 2;

interface Props {
  proposalTender?: any,
  onIndexChange?: Function
  onMenuHeaderChange?: Function
  onRefresh?: Function
  source: source
  isHide?: boolean
  onHideButtonClicked?: Function
}

export const getQuotationStatus = (proposalTender: any, intl: any, quotationStep=1) => {
  if(proposalTender?.status_quotation?.length === 0) {
    return {
      message: intl.formatMessage({id:"Belum Ada Penawaran",}),
      status: false
    };
  }
  if(proposalTender?.metode_penyampaian === "1 Sampul") {
    return {
      message: proposalTender?.status_quotation?.status_all?.message,
      status: proposalTender?.status_quotation?.status_all?.status
    }
  }
  if(proposalTender?.metode_penyampaian === "2 Sampul" || proposalTender?.metode_penyampaian === "2 Tahap") {
    if(quotationStep === 1){
      return {
        message: proposalTender?.status_quotation?.status_technical?.message,
        status: proposalTender?.status_quotation?.status_technical?.status
      }
    } else {
      return {
        message: proposalTender?.status_quotation?.status_commercial?.message,
        status: proposalTender?.status_quotation?.status_commercial?.status
      }
    }
  }

  return {
    message: "Ada Penawaran",
    status: true
  };
}

export const checkIsComplete1Sampul = (proposalTender:any) => {
  if(proposalTender?.vendor_status === "REJECT-QUOTATION") return true;
  if(proposalTender?.status !== "Penawaran") {
    return true;
  }
  if(proposalTender?.status_quotation?.length === 0) {
    return false;
  }
  if(proposalTender?.status_quotation?.status_all?.status === false) {
    return false;
  }
  return true;
}

export const checkIsComplete2Sampul = (proposalTender:any,quotationStep) => {
  const allowedStatus = [
    "Penawaran",
    "Penawaran Teknikal"
  ]
  if(proposalTender?.vendor_status === "REJECT-QUOTATION") return true;
  if(!allowedStatus.includes(proposalTender?.status)){
    return true;
  }
  if(proposalTender?.status_quotation.length === 0) {
    return false;
  }
  if(quotationStep === 1){
    if(proposalTender?.status_quotation?.status_technical?.status === false) {
      return false;
    }
  } else {
    if(proposalTender?.status_quotation?.status_commercial?.status === false) {
      return false;
    }
  }
  return true;
}

const SideLeftLayout: FC<Props> = (props) => {
  const intl = useIntl();
  const [proposalMenu, setProposalMenu] = useState<any>([]);
  const [prosesTenderMenu, setProsesTenderMenu] = useState<any>([]);
  const [isNeedResetLayout, setIsNeedResetLayout] = useState(true);
  const [isBidInputValid, setIsBidInputValid] = useState(true);
  const [renderKey, setRenderKey] = useState(0);

  const [isCompletePQ, setIsCompletePQ] = useState(false);
  const [syaratAdministrasi, setSyaratAdministrasi] = useState<any>(props?.proposalTender?.syarats?.filter((syarat: any) => syarat.source === "pra_qualification" && syarat.type === "administration"));
  const [syaratAdditional, setSyaratAdditional] = useState<any>(props?.proposalTender?.syarats?.filter((syarat: any) => syarat.source === "pra_qualification" && syarat.type === "additional"));

  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosGet(
    getEnv("tender") + "/vendor/pq/" + props?.proposalTender?.uuid
  );

  const getSyarats = () => {
    doSubmit({}, undefined, undefined).then((res: any) => {
      setSyaratAdministrasi(res?.data?.syarats?.filter((syarat: any) => syarat.source === "pra_qualification" && syarat.type === "administration"));
      setSyaratAdditional(res?.data?.syarats?.filter((syarat: any) => syarat.source === "pra_qualification" && syarat.type === "additional"));
      setRenderKey(renderKey + 1);
    });
  }

  useEffect(() => {
    getSyarats();
  }, [props?.proposalTender, props?.onRefresh]);

  useEffect(() => {
    isUploadValid();
  }, [syaratAdditional]);

  const [selectedMenu, setSelectedMenu] = useState(
    {
      index: 0,
      headerIndex: HEADER_MENU_INDEX_PROPOSAL_TENDER,
      component: null
    }
  );

  const navigate = useNavigate();

  const handleOnRefresh = async (needReset: boolean) => {
    if(needReset === true || needReset === undefined) {
      setIsNeedResetLayout(true);
    } else {
      setIsNeedResetLayout(false);
    }
    props.onRefresh && props.onRefresh();
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(props?.proposalTender?.no).then(function() {
      toast.success("Berhasil Copy Nomor Tender");
    }, function() {
      toast.error("Gagal Copy Nomor Tender");
    });
  };

  // Pengaturan disable / enable menu Registrasi Tender
  const checkIsMenuProsesTenderRegistrasiEnabled = useCallback(() => {
    const vendorStatusAllowed = [
      "INVITED",
      "REGISTRATION",
      "REGISTRATION-QUOTATION",
      "NEGOTIATION_PROCESS",
      "NEGOTIATION",
      "REJECT-QUOTATION",
      "REJECT-INVITATION",
    ];
    return vendorStatusAllowed.includes(props.proposalTender?.vendor_status);
  }, [props.proposalTender?.vendor_status]);

  // Pengaturan disable / enable menu Input Penawaran
  const checkIsMenuProsesTenderInputPenawaranKomersilEnabled = useCallback(() => {
    const vendorStatusAllowed = [
      "REGISTRATION",
      "REGISTRATION-QUOTATION",
      "NEGOTIATION_PROCESS",
      "REJECT-QUOTATION"
    ];
    const proposalTenderStatus = [
      "Penawaran",
      "Evaluasi Administrasi",
      "Bid Opening",
      "Penawaran Komersil",
      "Evaluasi Komersil",
      "Negotiation"
    ];
    const passedEvaluasiTeknis =
      props?.proposalTender?.status_evaluation_administration == "PASSED" &&
      props?.proposalTender?.status_evaluation_technical == "PASSED";

    return (
      // props?.proposalTender?.published_action?.quotation &&
      proposalTenderStatus.includes(props?.proposalTender?.status) &&
      vendorStatusAllowed.includes(props.proposalTender?.vendor_status) &&
      (props?.proposalTender?.metode_penyampaian_step === 1 || props?.proposalTender?.metode_penyampaian_step === 2) &&
      props?.proposalTender?.status !== "Pra Qualification" &&
      passedEvaluasiTeknis
    );

  }, [props.proposalTender?.metode_penyampaian_step,
    props.proposalTender?.status,
    props.proposalTender?.vendor_status]);

  const checkIsMenuProsesTenderInputPenawaranEnabled = useCallback(() => {
    const vendorStatusAllowed = [
      "REGISTRATION",
      "REGISTRATION-QUOTATION",
      "NEGOTIATION_PROCESS",
      "NEGOTIATION",
      "REJECT-QUOTATION"
    ];
    const proposalTenderStatus = [
      "Negotiation",
      "Penawaran",
      "Evaluasi Administrasi",
      "Evaluasi Komersil",
      "Evaluasi Teknis",
      "Awarding",
      "Bid Opening",
      "Penawaran Komersil",
      "Penawaran Teknikal",
      "Registrasi"
    ];
    return (
      // props?.proposalTender?.published_action?.quotation &&
      proposalTenderStatus.includes(props?.proposalTender?.status) &&
      vendorStatusAllowed.includes(props.proposalTender?.vendor_status) &&
      props?.proposalTender?.status !== "Pra Qualification"
    );
  }, [props.proposalTender?.status, props.proposalTender?.vendor_status]);

  const checkIsBidInputValid = (quotationStep=1) => {
    if(props.proposalTender?.vendor_status === "INVITED") return true;
    if(props.proposalTender?.vendor_status === "REGISTRATION" && quotationStep === 1) return false;
    if(props.proposalTender?.vendor_status === "REJECT-INVITATION") return true;
    if(props.proposalTender?.vendor_status === "REJECT-QUOTATION") return true;

    if(props?.proposalTender?.metode_penyampaian === "2 Tahap") {
      //region 2 tahap
      if(props.proposalTender?.status !== "Penawaran Teknikal") return true;

      const technical = checkIsComplete2Sampul(props.proposalTender,1);
      const commercial = checkIsComplete2Sampul(props.proposalTender,2);
      if(quotationStep === 1) return technical;

      if(props?.proposalTender?.status !== "Penawaran Komersil") return true;

      return commercial;

      //endregion
    } else {

      //region 1 sampul & 2 sampul
      if(props.proposalTender?.status !== "Penawaran") return true;

      if(props.proposalTender?.status_quotation?.length === 0) {
        return false;
      }

      if(props?.proposalTender?.metode_penyampaian === "1 Sampul") {
        return checkIsComplete1Sampul(props.proposalTender);
      } else if(props?.proposalTender?.metode_penyampaian === "2 Sampul") {
        const technical = checkIsComplete2Sampul(props.proposalTender,1);
        const commercial = checkIsComplete2Sampul(props.proposalTender,2);
        if(technical === false || commercial === false) {
          return false;
        }
      }
      return true;
      //endregion
    }
  }

  // Pengaturan disable / enable menu Negoisasi
  const checkIsMenuProsesTenderNegoisasiEnabled = useCallback(() => {
    const allowedStatus = [
      "Negotiation"
    ];
    const allowedVendorStatus = [
      "NEGOTIATION_PROCESS",
      "NEGOTIATION"
    ];
    return allowedVendorStatus?.includes(props.proposalTender?.vendor_status);
  }, [props.proposalTender?.vendor_status]);

  // Pengaturan disable / enable menu BidOpening
  const checkIsMenuBidOpeningEnabled = useCallback(() => {
    const allowedStatus = [
      "Evaluasi Administrasi",
      "Evaluasi Teknikal",
      "Evaluasi Komersil",
      "Laporan Pengadaan",
      "Pengumuman Pemenang",
      "Penunjukan Pemenang",
      "Purchase Order"
    ];
    return allowedStatus?.includes(props.proposalTender?.status);
  }, [props.proposalTender?.status]);

  // Pengaturan disable / enable menu Pemilihan Pemenang
  const checkIsMenuProsesTenderPemilihanPemenangEnabled = () => {
    return false;
  };

  // Pengaturan disable / enable menu Pengumuman Pemenang
  const checkIsMenuProsesTenderPengumumanPemenangEnabled = useCallback(() => {
    const allowedStatus = [
      "Penunjukan Pemenang",
      "Purchase Order",
    ];

    return allowedStatus?.includes(props.proposalTender?.status);
  }, [props.proposalTender?.status, props.proposalTender?.vendor_status]);

  // Pengaturan disable / enable menu Penunjukan Pemenang
  const checkIsMenuProsesTenderPenunjukanPemenangEnabled = useCallback(() => {
    const allowedStatus = [
      "Purchase Order",
    ];

    const allowedVendorStatus = [
      "WINNER",
    ];

    return allowedStatus?.includes(props.proposalTender?.status) && allowedVendorStatus?.includes(props.proposalTender?.vendor_status);
  }, [props.proposalTender?.status, props.proposalTender?.vendor_status]);

  const isUploadValid = async () => {
    if (props?.proposalTender?.vendor_status) {
      let isExists = 0
      let requiredAttachment = syaratAdministrasi.filter((syarat:any)=>syarat.required_attachment);
      requiredAttachment = requiredAttachment.map((syarat:any)=>{
        if(!syarat?.media_url && syarat?.required_attachment) isExists++
      })

      let requiredAttachmentTambahan = syaratAdditional.filter((syarat:any)=>syarat.required_attachment);
      requiredAttachmentTambahan = requiredAttachmentTambahan.map((syarat:any)=>{
        if(!syarat?.media_url && syarat?.required_attachment) isExists++
      })

      if(isExists === 0) {
        setIsCompletePQ(false)
      } else {
        setIsCompletePQ(true)
      }
    } else {
      setIsCompletePQ(true)
    }
  }

  const initProposalMenus = useCallback(() => {
    // @ts-ignore
    let _proposalMenu = [
      {
        title: intl.formatMessage({id:"Parameter Pengadaan"}),
        disabled: false,
        component: null,
        warning: null
      },
      {
        title: intl.formatMessage({id:"Item PR"}),
        disabled: false,
        component: <ItemPRPage proposalTender={props.proposalTender} />,
        warning: null
      },
      {
        title: intl.formatMessage({id:"Dokumen Pengadaan"}),
        disabled: false,
        component: <DokumenPengadaanPage proposalTender={props.proposalTender} />,
        warning: null
      },
      {
        title: intl.formatMessage({id:"Jadwal Tender"}),
        disabled: false,
        component: <JadwalTenderPage proposalTender={props.proposalTender} />,
        warning: null
      },
      {
        title: intl.formatMessage({id:"Aanwijzing"}),
        disabled: !(props?.proposalTender?.metode_aanwijzing === "E-Aanwijzing") || (props?.proposalTender?.status === "Pra Qualification"),
        component: <AanwijzingVendorDetail aanwijzingUuid={props.proposalTender?.aanwijzing_uuid} isShowBackButton={false} />,
        warning: null
      }
    ];

    if (props.proposalTender.praqualification) {
      _proposalMenu.push({
        title: intl.formatMessage({id:"Pra Qualification"}),
        disabled: false,
        component: <PraQualificationPage proposalTender={props.proposalTender} onRefresh={() => {
          props.onRefresh && props.onRefresh();
        }}
        setIsCompletePQ={setIsCompletePQ}
        />,
        warning: isCompletePQ
      });
    }
    setProposalMenu(_proposalMenu);
  }, [props, isCompletePQ]);

  useEffect(() => {
    initProposalMenus();
  }, [initProposalMenus]);

  const checkIsRegistrasiValid = () => {
    const allowedStatus = [
        "Penawaran",
        "Penawaran Teknikal"
    ];
    if(allowedStatus.includes(props?.proposalTender?.status) && props?.proposalTender?.vendor_status === "INVITED") {
      return false;
    }
    return true;
  }

  const initProsesTenderMenus = useCallback(() => {
    let menus = [{
      title: intl.formatMessage({id:'Registrasi'}),
      disabled: !checkIsMenuProsesTenderRegistrasiEnabled(),
      component: <RegistrasiTenderPage
        proposalTender={props.proposalTender}
        onRefresh={handleOnRefresh}
      />,
      warning: !checkIsRegistrasiValid()
    }];

    const bidOpeningPage = {
      title:intl.formatMessage({id:'Bid Opening'}),
      disabled: !checkIsMenuBidOpeningEnabled(),
      component: <BidOpeningPage
        proposalTender={props.proposalTender}
        onRefresh={() => props.onRefresh && props.onRefresh()}
      />,
      warning: null
    }

    //kondisional sidebar untuk tender 1 sampul dan 2 sampul
    if (props.proposalTender.metode_penyampaian === "1 Sampul" || props.proposalTender.metode_penyampaian === "2 Sampul") {
      menus.push({
        title: intl.formatMessage({id:'Input Penawaran'}),
        disabled: !checkIsMenuProsesTenderInputPenawaranEnabled(),
        component: <InputPenawaranPage
          proposalTender={props.proposalTender}
          onRefresh={() => handleOnRefresh(true)}
          key={renderKey}
        />,
        warning: !checkIsBidInputValid()
      });

      //kondisional if use visibilitas general
      if (props?.proposalTender?.bid_opening_validation === 'General') menus.push(bidOpeningPage)

    }

    //kondisional sidebar untuk 2 tahap
    if (props.proposalTender.metode_penyampaian === "2 Tahap") {
      menus.push({
        title:  intl.formatMessage({id:'Penawaran Teknis'}),
        disabled: !checkIsMenuProsesTenderInputPenawaranEnabled(),
        component: <InputPenawaranPage
          proposalTender={props.proposalTender}
          onRefresh={() => props.onRefresh && props.onRefresh()}
        />,
        warning: !checkIsBidInputValid(1)
      });
      menus.push({
        title:  intl.formatMessage({id:'Penawaran Komersil'}),
        disabled: !checkIsMenuProsesTenderInputPenawaranKomersilEnabled(),
        component: <InputPenawaranKomersilPage
          proposalTender={props.proposalTender}
          onRefresh={() => props.onRefresh && props.onRefresh()}
        />,
        warning: !checkIsBidInputValid(2)
      });

      //kondisional if use visibilitas general
      if (props?.proposalTender?.bid_opening_validation === 'General') menus.push(bidOpeningPage)



      // menus.push({
      //   title: intl.formatMessage({id:'Pemilihan Pemenang'}),
      //   disabled: !checkIsMenuProsesTenderNegoisasiEnabled(),
      //   component: <InputPenawaranPage
      //     proposalTender={props.proposalTender}
      //     onRefresh={() => props.onRefresh && props.onRefresh()}
      //   />
      // });
    }

    const checkIsKlarifikasiPenawaranEnabled = () => {
      const allowedStatus = [
        "Negotiation",
        "Evaluasi Administrasi",
        "Evaluasi Teknikal",
        "Evaluasi Komersil",
        "Laporan Pengadaan",
        "Pengumuman Pemenang",
        "Penunjukan Pemenang",
        "Purchase Order"
      ];
      return allowedStatus?.includes(props.proposalTender?.status);
      // return props?.proposalTender?.is_on_clarification;
      // const allowedStatusDetail = [
      //   "Klarifikasi Vendor",
      //   "Klarifikasi Vendor dan Evaluasi Evaluator"
      // ];
      // return allowedStatusDetail.includes(props?.proposalTender?.status_detail);
    }

    menus.push({
      title: intl.formatMessage({id:'Klarifikasi Penawaran'}),
      disabled: !checkIsKlarifikasiPenawaranEnabled(),
      component: <KlarifikasiPenawaranVendorPage
        proposalTender={props.proposalTender}
        onRefresh={() => props.onRefresh && props.onRefresh()}
      />,
      warning: props.proposalTender?.is_on_clarification
    });

    menus.push({
      title: intl.formatMessage({id:'Negosiasi'}),
      disabled: !checkIsMenuProsesTenderNegoisasiEnabled(),
      component: <NegotiationVendorPage
        proposalTender={props.proposalTender}
        onRefresh={() => props.onRefresh && props.onRefresh()}
      />,
      warning: props.proposalTender?.vendor_status === "NEGOTIATION_PROCESS"
    });

    //kondisional if use pengumuman pemenang
    if (props.proposalTender.pengumuman_pemenang) {
      menus.push({
        title:intl.formatMessage({id:'Pengumuman Pemenang'}),
        disabled: !checkIsMenuProsesTenderPengumumanPemenangEnabled(),
        component: <PengumumanPemenangPage
          proposalTender={props.proposalTender}
          onRefresh={() => props.onRefresh && props.onRefresh()}
        />,
        warning: null
      });
    }

    //kondisional if use penunjukan pemenang
    if (props.proposalTender.penunjukan_pemenang) {
      menus.push({
        title:intl.formatMessage({id:'Penunjukan Pemenang'}),
        disabled: !checkIsMenuProsesTenderPenunjukanPemenangEnabled(),
        component: <PenunjukkanPemenangPage
          proposalTender={props.proposalTender}
          onRefresh={() => props.onRefresh && props.onRefresh()}
        />,
        warning: null
      });
    }

    setProsesTenderMenu(menus);
  }, [
    checkIsMenuProsesTenderInputPenawaranEnabled,
    checkIsMenuProsesTenderInputPenawaranKomersilEnabled,
    checkIsMenuProsesTenderNegoisasiEnabled,
    checkIsMenuProsesTenderRegistrasiEnabled,
    props
  ]);

  const handleNavigateMenu = async(header: number, menu: any) => {
    navigate({
      hash: `#menu=${header}-${
        getSidenavMenuIndex(header, menu)
      }`
    }, { replace: true });
    handleChangeSideMenu(header, menu);
  };

  const overrideMenu = useCallback(() => {
    const hashValue = window.location.hash;
    const match = hashValue.match(/#menu=(\d+)-(\d+)/);

    if (match) {
      const start = parseInt(match[1], 10);
      const end = parseInt(match[2], 10);

      const arrayValue = [start, end];
      if (arrayValue.length === 2) {
        let menu = null;
        if (arrayValue[0] === HEADER_MENU_INDEX_PROPOSAL_TENDER) {
          menu = proposalMenu[arrayValue[1]];
        } else if (arrayValue[0] === HEADER_MENU_INDEX_PROSES_TENDER) {
          menu = prosesTenderMenu[arrayValue[1]];
        }
        // handleChangeSideMenu(arrayValue[0], menu);
      }
    }
  }, [proposalMenu, prosesTenderMenu]);

  const getSidenavMenuIndex = (header: number, menu: any) => {
    if (header === HEADER_MENU_INDEX_PROPOSAL_TENDER) {
      return proposalMenu.indexOf(menu);
    } else if (header === HEADER_MENU_INDEX_PROSES_TENDER) {
      return prosesTenderMenu.indexOf(menu);
    }
  };

  const hideSidebar = () => {
    // hide sidebar
    const elementToClick = document.getElementById("kt_aside_toggle");

    if (elementToClick && !elementToClick.classList.contains("active")) {
      // Memicu klik pada elemen
      elementToClick.click();
    }
  };

  const handleChangeSideMenu = (header: number, menu: any) => {
    const payload = {
      headerIndex: header,
      index: getSidenavMenuIndex(header, menu),
      component: menu?.component
    };
    // @ts-ignore
    setSelectedMenu(payload);
    props.onIndexChange && props.onIndexChange(payload);
  };

  const overrideMenuByStatus = () => {
    if(props.proposalTender?.status === "Penawaran") {
      const menu = prosesTenderMenu[1];
      handleChangeSideMenu(HEADER_MENU_INDEX_PROSES_TENDER, menu);
    } else if(props.proposalTender?.status === "Negotiation") {
      const menu = prosesTenderMenu[2];
      handleChangeSideMenu(HEADER_MENU_INDEX_PROSES_TENDER, menu);
    }
  }

  useEffect(() => {
    hideSidebar();
  }, []);

  useEffect(() => {
    if (props.proposalTender) {
      initProposalMenus();
      initProsesTenderMenus();
      // overrideMenuByStatus()
      if(props?.proposalTender?.quotation_id) {
        checkIsBidInputValid();
      }
      if(isNeedResetLayout) {
        setRenderKey(renderKey + 1);
      }
    }
  }, [initProposalMenus, initProsesTenderMenus, props, props.proposalTender, props.proposalTender?.vendor_status]);

  useEffect(() => {
    overrideMenu();
  }, [overrideMenu, proposalMenu]);



  return (
    <div>
      <div className={"d-flex align-items-center gap-2"} key={renderKey}>
        {!props.isHide && (
          <div className={"text-center d-flex align-items-center justify-content-center w-100"}>
            <h1 className={"text-center"}>{props?.proposalTender?.status}</h1>
          </div>
        )}

      </div>
      {!props.isHide && (
        <>
          <div className={"mb-1 mt-2"}>
            <div className={"form-group mb-3"}>
              <label htmlFor="" className={"font-weight"}><strong>{intl.formatMessage({id:'No. Proposal Tender'})}</strong></label>
              <div className={"input-group"}>
                <input type="text" className={"form-control form-control-sm"} disabled={true}
                       value={props?.proposalTender?.no} />
                <div className={"input-group-append"}>
                  <button className={"btn btn-sm btn-info"} onClick={() => copyToClipboard()}>
                    <i className={"fa fa-copy"}></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className={"text-end gap-2 d-flex align-items-end justify-content-end"}>
            <div className={"badge badge-primary text-white"}
                 style={{ fontStyle: "italic" }}>#{props?.proposalTender?.order_placement}</div>
            <div className={"badge badge-primary text-white"}
                 style={{ fontStyle: "italic" }}>#{props?.proposalTender?.metode_evaluasi}</div>
            <div className={"badge badge-primary text-white"}
                 style={{ fontStyle: "italic" }}>#{props?.proposalTender?.metode_penyampaian}</div>
          </div>
          <hr className={"mt-1"} />
          <div className={"mb-1 d-flex flex-column"}>
            {/* Region Header Menu, Proposal, Process, Awarding*/}
            {/* Region Proposal Menu*/}
            <>
              {proposalMenu.map((menu: any, index: number) => {
                return (
                  <RowMenu
                    title={menu.title} key={"menu" + index}
                    active={selectedMenu.headerIndex === HEADER_MENU_INDEX_PROPOSAL_TENDER && selectedMenu.index === index}
                    onClick={() => handleNavigateMenu(
                      HEADER_MENU_INDEX_PROPOSAL_TENDER,
                      menu
                    )}
                    disabled={menu.disabled}
                    warning={menu.warning}
                  />
                );
              })}
            </>
            {/* End Region Proposal Menu*/}
            <hr />
            <h4><strong>{intl.formatMessage({id:'Proses Tender'})}</strong></h4>
            <>
              {prosesTenderMenu.map((menu: any, index: number) => {
                return (
                  <RowMenu
                    title={menu.title}
                    key={"proses" + index}
                    active={selectedMenu.headerIndex === HEADER_MENU_INDEX_PROSES_TENDER && selectedMenu.index === index}
                    onClick={() => handleNavigateMenu(
                      HEADER_MENU_INDEX_PROSES_TENDER,
                      menu
                    )}
                    disabled={menu.disabled}
                    warning={menu.warning}
                  />
                );
              })}
            </>
          </div>
        </>
      )}

      {props.isHide && (
        <>
          {proposalMenu.map((menu: any, index: number) => {
            return (
              <RowMenu
                title={index + 1} key={"menu" + index}
                active={selectedMenu.headerIndex === HEADER_MENU_INDEX_PROPOSAL_TENDER && selectedMenu.index === index}
                onClick={() => handleNavigateMenu(
                  HEADER_MENU_INDEX_PROPOSAL_TENDER,
                  menu
                )}
                disabled={menu.disabled}
              />
            );
          })}
          {/* End Region Proposal Menu*/}
          <hr />
          <h4><strong></strong></h4>
          <>
            {prosesTenderMenu.map((menu: any, index: number) => {
              return (
                <RowMenu
                  title={index + 1}
                  key={"proses" + index}
                  active={selectedMenu.headerIndex === HEADER_MENU_INDEX_PROSES_TENDER && selectedMenu.index === index}
                  onClick={() => handleNavigateMenu(
                    HEADER_MENU_INDEX_PROSES_TENDER,
                    menu
                  )}
                  disabled={menu.disabled}
                />
              );
            })}
          </>
        </>
      )}
    </div>
  );
};

export default SideLeftLayout;