import {Navigate, Route, Routes} from 'react-router-dom'
import {FC, lazy, Suspense} from 'react'
import {WithChildren} from '../../_metronic/helpers'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import TopBarProgress from 'react-topbar-progress-indicator'
import MediaPage from "../modules/procure-x/media/MediaPage";
import MasterDataIncotermPage from "../modules/procure-x/masterdata-incoterm/MasterDataIncotermPage";
// import AnnouncementCategoryPage from '../modules/master-data/announcement-category/AnnouncementCategoryPage'

const ProcurexRoutes = () => {
  const ModuleManagementPage = lazy(() => import('../modules/procure-x/module-management/ModuleManagementPage'))
    const MenuManagementPage = lazy(() => import('../modules/procure-x/menu-management/MenuManagementPage'))
    const MasterDataMaterials = lazy(()=>import('../modules/procure-x/masterdata-materials/MasterDataMaterials'));
    const MasterDataUnitKerja = lazy(()=>import('../modules/procure-x/masterdata-unitkerja/MasterDataUnitKerjaPage'))
    const MasterDataEnumeration = lazy(()=>import('../modules/procure-x/masterdata-enumeration/EnumerationPage'))
    const MasterDataDocument = lazy(()=>import('../modules/procure-x/masterdata-document/DocumentPage'))
    const MasterDataVendor = lazy(()=>import('../modules/procure-x/masterdata-vendor/MasterDataVendorPage'))
    const MasterDataBatasMinimum = lazy(()=>import('../modules/procure-x/masterdata-batasminimum/MasterDataBatasMinimumPage'))
    const MasterDataTipeDokumen = lazy(()=>import('../modules/procure-x/masterdata-tipedokumen/MasterDataTipeDokumenPage'))
    const SapConnection = lazy(()=>import('../modules/procure-x/sap-connection/SapConnectionPage'))
    const SapSetupSync = lazy(()=>import('../modules/procure-x/sap-setup-sync/SapSetupSyncPage'))
    const SapTask = lazy(()=>import('../modules/procure-x/sap-task/SapTaskPage'))
    const SubMenuManagement = lazy(()=>import('../modules/procure-x/submenu-management/SubMenuManagementPage'))
    const UserManagement = lazy(()=>import('../modules/procure-x/user-management/UserManagementPage'))
    const RoleManagement = lazy(()=>import('../modules/procure-x/role-management/RoleManagementPage'))
    const RoleAccessManagement = lazy(()=>import('../modules/procure-x/role-access-management/RoleAccessManagementPage'))

    const MasterDataPlant = lazy(()=>import('../modules/procure-x/masterdata-plant/MasterDataPlantPage'))
    const MasterDataSloc = lazy(()=>import('../modules/procure-x/masterdata-sloc/MasterDataSlocPage'))
    const MasterDataTransportation = lazy(()=>import('../modules/procure-x/masterdata-transportation/MasterDataTransportationPage'))
    const MasterDataMaterialGroup = lazy(()=>import('../modules/procure-x/masterdata-materialgroup/MasterDataMaterialGroupPage'))
    const MasterDataPurchasingGroup = lazy(()=>import('../modules/procure-x/masterdata-purchasinggroup/MasterDataPurchasingGroupPage'))
    const MasterDataCostCenter = lazy(()=>import('../modules/procure-x/masterdata-costcenter/MasterDataCostCenterPage'))
    const MasterDataGlAccount = lazy(()=>import('../modules/procure-x/masterdata-glaccount/MasterDataGlAccountPage'))
    const ServiceManagement = lazy(()=>import('../modules/procure-x/service-management/ServiceManagementPage'))
    const NewsGroupPage = lazy(()=>import('../modules/procure-x/news-group/NewsGroupPage'))
    const NewsTypePage = lazy(()=>import('../modules/procure-x/news-type/NewsTypePage'))
    const AnnouncementCategoryPage = lazy(()=>import('../modules/procure-x/announcement-category/AnnouncementCategoryPage'))
    const NewsPage = lazy(()=>import('../modules/procure-x/news/NewsPage'))
    const MediaType = lazy(()=>import('../modules/procure-x/media-type/MediaTypePage'))
    const AnnouncementPage = lazy(()=>import('../modules/procure-x/announcement/AnnouncementPage'))
    const MasterDataSatuan = lazy(()=>import('../modules/procure-x/masterdata-satuan/MasterDataSatuanPage'))
    const IpromsGetMaterial = lazy(()=>import('../modules/procure-x/iproms-getmaterial/IpromsGetMaterialPage'))
    const IpromsGetMatrixMaterial = lazy(()=>import('../modules/procure-x/iproms-getmatrixmaterialgroup/IpromsGetMatrixMaterialPage'))
    const MasterDataDivision = lazy(()=>import('../modules/procure-x/division/DivisionPage'))
    const MasterDataPlanner = lazy(()=>import('../modules/procure-x/masterdata-planner/PlannerPage'))
    const MasterDataBuyer = lazy(()=>import('../modules/procure-x/masterdata-buyer/BuyerPage'))
    const MasterDataMRPController = lazy(()=>import('../modules/procure-x/mrp-controller/MRPControllerPage'))
    const WBSProject = lazy(()=>import('../modules/procure-x/wbs-project/WBSProjectPage'))
    const DocumentType = lazy(()=>import('../modules/procure-x/document-types/DocumentTypePage'))
    const Asset = lazy(()=>import('../modules/procure-x/assets/AssetPage'))
    const JobList = lazy(()=>import('../modules/procure-x/jobs-list/JobListPage'))
    const VersioningList = lazy(()=>import('../modules/procure-x/versioning/VersioningPage'));
  return (
    <Routes>
        <Route index element={<Navigate to='/procure-x/module-management' />} />
        <Route path='menu-management/module/*' element={<SuspensedView><ModuleManagementPage /></SuspensedView>}/>
        <Route path='menu-management/menu' element={<SuspensedView><MenuManagementPage /></SuspensedView>}/>
        <Route path='menu-management/submenu/*' element={<SuspensedView><SubMenuManagement /></SuspensedView>}/>
        <Route path='menu-management/service/*' element={<SuspensedView><ServiceManagement /></SuspensedView>}/>

        <Route path='masterdata/materials/*' element={<SuspensedView><MasterDataMaterials /></SuspensedView>}/>
        <Route path='masterdata/unitkerja/*' element={<SuspensedView><MasterDataUnitKerja /></SuspensedView>}/>
        <Route path='masterdata/enumeration/*' element={<SuspensedView><MasterDataEnumeration /></SuspensedView>}/>
        <Route path='masterdata/document/*' element={<SuspensedView><MasterDataDocument /></SuspensedView>}/>
        <Route path='masterdata/vendor/*' element={<SuspensedView><MasterDataVendor /></SuspensedView>}/>
        <Route path='masterdata/batas-minimum/*' element={<SuspensedView><MasterDataBatasMinimum /></SuspensedView>}/>
        <Route path='masterdata/tipe-dokumen/*' element={<SuspensedView><MasterDataTipeDokumen /></SuspensedView>}/>
        <Route path='sap/connection/*' element={<SuspensedView><SapConnection /></SuspensedView>}/>
        <Route path='sap/setup-sync/*' element={<SuspensedView><SapSetupSync /></SuspensedView>}/>
        <Route path='sap/task/*' element={<SuspensedView><SapTask /></SuspensedView>}/>

        <Route path='user-management/user/*' element={<SuspensedView><UserManagement /></SuspensedView>}/>
        <Route path='user-management/role/*' element={<SuspensedView><RoleManagement /></SuspensedView>}/>
        <Route path='user-management/permission/*' element={<SuspensedView><RoleAccessManagement /></SuspensedView>}/>

        <Route path='masterdata/plant/*' element={<SuspensedView><MasterDataPlant /></SuspensedView>}/>
        <Route path='masterdata/sloc/*' element={<SuspensedView><MasterDataSloc /></SuspensedView>}/>
        <Route path='masterdata/transportation/*' element={<SuspensedView><MasterDataTransportation /></SuspensedView>}/>
        <Route path='masterdata/material-group/*' element={<SuspensedView><MasterDataMaterialGroup /></SuspensedView>}/>
        <Route path='masterdata/purchasing-group/*' element={<SuspensedView><MasterDataPurchasingGroup /></SuspensedView>}/>
        <Route path='masterdata/cost-center/*' element={<SuspensedView><MasterDataCostCenter /></SuspensedView>}/>
        <Route path='masterdata/gl-account/*' element={<SuspensedView><MasterDataGlAccount /></SuspensedView>}/>
        <Route path='masterdata/satuan/*' element={<SuspensedView><MasterDataSatuan /></SuspensedView>}/>
        <Route path='masterdata/incoterm/*' element={<SuspensedView><MasterDataIncotermPage /></SuspensedView>}/>
        <Route path='masterdata/versioning/*' element={<SuspensedView><VersioningList /></SuspensedView>}/>

        <Route path='news/group/*' element={<SuspensedView><NewsGroupPage /></SuspensedView>}/>
        <Route path='news/type/*' element={<SuspensedView><NewsTypePage /></SuspensedView>}/>
        <Route path='news/list/*' element={<SuspensedView><NewsPage /></SuspensedView>}/>

        <Route path='announcement/category/*' element={<SuspensedView><AnnouncementCategoryPage /></SuspensedView>}/>
        <Route path='announcement/list/*' element={<SuspensedView><AnnouncementPage /></SuspensedView>}/>

        <Route path='iproms/get-material/*' element={<SuspensedView><IpromsGetMaterial /></SuspensedView>}/>
        <Route path='iproms/get-matrix-material-group/*' element={<SuspensedView><IpromsGetMatrixMaterial /></SuspensedView>}/>
        <Route path='media-service/media-type/*' element={<SuspensedView><MediaType /></SuspensedView>}/>
        <Route path='media-service/media/*' element={<SuspensedView><MediaPage /></SuspensedView>}/>

        <Route path='masterdata/division/*' element={<SuspensedView><MasterDataDivision /></SuspensedView>}/>
        <Route path='masterdata/planner/*' element={<SuspensedView><MasterDataPlanner /></SuspensedView>}/>
        <Route path='masterdata/buyer/*' element={<SuspensedView><MasterDataBuyer /></SuspensedView>}/>
        <Route path='masterdata/mrp-controller/*' element={<SuspensedView><MasterDataMRPController /></SuspensedView>}/>
        <Route path='masterdata/wbs-project/*' element={<SuspensedView><WBSProject /></SuspensedView>}/>
        <Route path='masterdata/document-type/*' element={<SuspensedView><DocumentType /></SuspensedView>}/>
        <Route path='masterdata/assets/*' element={<SuspensedView><Asset /></SuspensedView>}/>
        <Route path='jobs/list/*' element={<SuspensedView><JobList /></SuspensedView>}/>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export default ProcurexRoutes
