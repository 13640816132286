import { ID, KTCard, KTCardBody } from '../../../../../../_metronic/helpers'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import TableXWrpper from "../../../../../components/shared/TableXWrapper";
import SelectX from '../../../../../components/shared/form/SelectX'
import { ApiUrl } from '../../../../../config/Api'
import { getEnv } from "../../../../../../helpers/procurex";
import SwalX from '../../../../../components/shared/SwalX'
import { InputNumber } from "rsuite";
import { Field, Form, Formik } from 'formik'
import { useIntl } from 'react-intl'
import { MetronicErrorMessage, toastError } from '../../../../../../_metronic/helpers'
import { toast } from 'react-toastify'
import axios from 'axios'
import { Button, ButtonGroup, Col, Modal, Row } from "react-bootstrap";
import { useAuth } from "../../../../auth";
import DatepickerX2 from "../../../../../components/shared/form/DatepickerX2";
import moment from 'moment/moment'
import FormikUploadFile from '../../../../../components/shared/form/FormikUploadFile'
import { useUploadMedia } from '../../../../../../hooks/MediaUploadHook'

const BuletinPriceUpdateStockviewList = () => {
    const intl = useIntl()
    const { currentUser } = useAuth();
    const tablecolumns = [
        {
            Header: "TANGGAL PUBLIKASI",
            accessor: "tanggal_keluar_publikasi",
            width: 160,
            Cell: ({ value }) => {
                if (!value) return '';
                const date = new Date(value);
                const day = date.getDate().toString().padStart(2, '0');
                const month = (date.getMonth() + 1).toString().padStart(2, '0');
                const year = date.getFullYear();
                return `${day}-${month}-${year}`;
            }
        },
        {
            Header: "JUDUL",
            accessor: "judul",
            width: 300,
        },
        {
            Header: "JENIS PUBLIKASI",
            accessor: "jenis_publikasi",
            width: 160,
        },
        {
            Header: "TANGGAL INPUT",
            accessor: "date_created",
            width: 220,
            Cell: ({ value }) => {
                if (!value) return '';
                const date = new Date(value);
                const day = date.getDate().toString().padStart(2, '0');
                const month = (date.getMonth() + 1).toString().padStart(2, '0');
                const year = date.getFullYear();
                return `${day}-${month}-${year}`;
            }
        },
        {
            Header: "FILE",
            accessor: "file_path",
            width: 150,
            Cell: (props: any) => {
                return (
                    <button
                        className='btn btn-sm btn-primary'
                        onClick={() => {
                            preview(props.row.original.file_path)
                        }}
                    >
                        <i className='fa fa-eye' title='preview pdf'></i>
                    </button>
                )
            },
        },
        {
            Header: "Action",
            accessor: "uuid",
            width: 180,
            className: "text-center fixed-column",
            Cell: (props: any) => {
                const row = props.row.original;
                return (
                    <ButtonGroup size='sm' aria-label='Action'>
                        {!excludedRoles.some(r => role.includes(r)) && (
                            <>
                                <button
                                    type='button'
                                    className='btn btn-sm btn-success'
                                    onClick={(e: any) => {
                                        e.preventDefault();
                                        setId(row.id);
                                        openModalWithItem(row);
                                    }}
                                >
                                    Edit
                                </button>
                                <button
                                    type='button'
                                    className='btn btn-sm btn-danger'
                                    onClick={() => {
                                        setItemId(row.id);
                                        setIsConfirmDeleteOpen(true);
                                    }}
                                >
                                    <i className='fa fa-trash' />
                                </button>
                            </>
                        )}
                    </ButtonGroup>
                );
            },
        },
    ];

    const excludedRoles = ['admin_anper', 'DaanExp_Anper', 'Gudang_Bahan_Baku_Anper', 'Perencanaan_Pengadaan_Anper', 'Managerial_PI'];
    const [formindex, setFormIndex] = useState(0);

    const [isShowModal, setIsShowModal] = useState(false)
    const [isShowDetail, setIsShowDetail] = useState(false)
    let [itemId, setItemId] = useState(0)
    let [isLoadingDelete, setIsLoadingDelete] = useState(false)
    let [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false)
    const [key, setKey] = useState(0)
    const [id, setId] = useState('')
    const [item, setItem] = useState<any>(null)
    const [isConfirmDialog, setIsConfirmDialog] = useState(false)
    const [itemsMasterJenisPublikasi, setItemsMasterJenisPublikasi] = useState<any>([])
    const [itemLoading, setItemLoading] = useState(false)
    const [itemsBuletinPrice, setItemsBuletinPrice] = useState([]);

    const options =
        itemsBuletinPrice.map((item: any) => ({
            label: `${item.file_path}`
        }));

    const loadItem = () => {
        axios
            .get(getEnv("stockview") + `/jenis-publikasi`)
            .then((res) => {
                setItemsMasterJenisPublikasi(res.data.data)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setItemLoading(false)
            })
        axios.get(getEnv("stockview") + `/buletin-price`)
            .then((res) => {
                setItemsBuletinPrice(res.data.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    useEffect(() => {
        loadItem();
    }, [])

    const [totalConsumption, setTotalConsumption] = useState(0);

    const handleOnTotalConsumptionChange = (score: number) => {
        setTotalConsumption(score)
    }

    const [selectedMaterial, setSelectedMaterial] = useState(null);
    const [selectedCompanyCode, setSelectedCompanyCode] = useState(null);

    const handleCompanySelect = (selectedValue: any) => {
        setSelectedCompanyCode(selectedValue);
    };

    const handleMaterialSelect = (selectedValue: any) => {
        setSelectedMaterial(selectedValue);
    };

    const preview = (value: string) => {
        window.open(value, '_blank')
    };

    const { upload } = useUploadMedia()

    const submit = async (values: any) => {
        try {
            const newData = {
                judul: values.judul,
                jenis_publikasi: values.jenis_publikasi,
                tanggal_keluar_publikasi: values?.tanggal_keluar_publikasi,
                file: values.file || item.file_path,
                date_created: item?.date_created,
            };

            if (typeof newData.file !== 'string') {
                let responseFile = await upload('49', newData.file);
                newData.file = responseFile.url;
            }

            const response = await axios.put(getEnv("stockview") + `/buletin-price/${id}`, newData)

            if (response.status === 200 || response.status === 201) {
                toast.success(response.data.meta.message)
                setId('')
                setIsShowModal(false)
                setFormIndex(formindex + 1)
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.meta?.message || 'Terjadi kesalahan')
        } finally {
            setIsConfirmDialog(false)
            setIsShowDetail(false)
        }
    }

    const deleteItem = async () => {
        try {
            setIsLoadingDelete(true)
            const response: any = await axios.delete(getEnv("stockview") + `/buletin-price/${itemId}`)

            if (response.status === 200) {
                setFormIndex(formindex + 1)
                setIsConfirmDeleteOpen(false)
                toast.success(response?.data?.meta?.message, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                })
                loadItem()
            }
        } catch (error: any) {
            console.error(error)
            toastError(error.response?.data?.meta?.message || 'Terjadi kesalahan')
        } finally {
            setIsLoadingDelete(false)
        }
    }

    const defaultInitialValues = {
        judul: '',
        jenis_publikasi: '',
        tanggal_keluar_publikasi: '',
        file: '',
        date_created: '',
    };

    const [initialValues, setInitialValues] = useState<any>(defaultInitialValues);

    const openModalWithItem = async (item: any) => {
        setInitialValues({
            judul: item.judul,
            jenis_publikasi: item.jenis_publikasi,
            tanggal_keluar_publikasi: item?.tanggal_keluar_publikasi,
            file: item?.file_path,
        });
        setItem(item);
        setIsShowModal(true);
    };

    let company_code_user = currentUser.company_code;
    let jenis_api = "table";
    let role = currentUser.has_roles;

    return (
        <>
            <SwalX
                title={intl.formatMessage({ id: 'DATATABLE.CONFIRM_DELETE' })}
                message={intl.formatMessage({ id: 'DATATABLE.MESSAGE_DELETE' })}
                show={isConfirmDeleteOpen}
                loading={isLoadingDelete}
                onHide={() => setIsConfirmDeleteOpen(false)}
                onSubmit={() => deleteItem()}
            />

            <Modal size="xl" show={isShowModal} onHide={() => setIsShowModal(false)}>
                <Modal.Header>Edit Buletin Stockview</Modal.Header>
                <Modal.Body>
                    <Formik initialValues={initialValues} onSubmit={submit} enableReinitialize>
                        {({ values, setFieldValue }) => (
                            <Form>
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label required'>
                                                Judul
                                            </label>
                                            <Field className='form-control' name="judul" />
                                            <small className="form-text">
                                                Contoh Penulisan: PUBLIKASI JUDUL - 2023/07/20
                                            </small>
                                        </div>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label required'>
                                                Jenis Publikasi
                                            </label>
                                            <SelectX
                                                defaultValue={initialValues.jenis_publikasi}
                                                onChange={(e: any) => setFieldValue('jenis_publikasi', e)}
                                                options={itemsMasterJenisPublikasi.map((item: any) => ({
                                                    label: `${item.nama_jenis}`,
                                                    value: item.nama_jenis
                                                }))}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label required'>
                                                Tanggal Keluar Publikasi
                                            </label>
                                            <DatepickerX2
                                                onChange={(date: any) => setFieldValue("tanggal_keluar_publikasi", date)}
                                                size={"lg"}
                                                value={values.tanggal_keluar_publikasi}
                                                format={"dd-MM-yyyy"}
                                            />
                                        </div>
                                        <div className='mb-6'>
                                            <FormikUploadFile
                                                mediaTypeId='49'
                                                label='Lampiran Publikasi'
                                                name='file'
                                            />
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>File Type : pdf, excel</span>
                                                    <br />
                                                    <span role='alert'>
                                                        MAX FILE 10MB
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='d-flex gap-4 mt-5'>
                                    <button type='submit' className='btn btn-primary'>
                                        Save
                                    </button>
                                    <button
                                        type='button'
                                        className='btn btn-secondary'
                                        onClick={() => {
                                            setId('')
                                            setIsShowModal(false)
                                        }}
                                    >
                                        Batal
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>

            <KTCard className='card border mt-5'>
                <KTCardBody >
                    <TableXWrpper
                        title={"Buletin Price"}
                        key={formindex}
                        readonly
                        urlIndex={getEnv("stockview") + `/buletin-price`}
                        columns={tablecolumns}
                        headerButtons={
                            !excludedRoles.some(r => role.includes(r)) && (
                                <>
                                    <Link
                                        to="/stockview/price-bulletin/table-price-bulletin/create"
                                        className="btn btn-primary"
                                        title="Add Data"
                                    >
                                        Add Data
                                    </Link>
                                </>
                            )
                        }
                    />
                </KTCardBody>
            </KTCard>
        </>
    )
}

export { BuletinPriceUpdateStockviewList }
