import React, { useEffect, useState } from "react";
import TableXWrapper from "../../../../../../components/shared/TableXWrapper";
import { capitalizeFirstChar, getEnv } from "../../../../../../../helpers/procurex";
import { useAxiosGet, useAxiosPost } from "../../../../../../../hooks/useAxios";
import { Placeholder, Radio, RadioGroup } from "rsuite";
import PreviewAttachment from "../../../../../../components/PreviewAttachment";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { Field } from "formik";
import { Spinner } from "react-bootstrap";
import BottomToolbarWrapper from "../../../../../../components/shared/BottomToolbarWrapper";
import DatepickerX2 from "../../../../../../components/shared/form/DatepickerX2";
import { useIntl } from "react-intl";


const StatusTypeBadge = ({ status }: any) => {

  const renderStatus = () => {
    let badgeColor = "badge-info";
    switch (status) {
      case 'administration':
        badgeColor = "badge-success";
    }
    return (
      <span className={`badge ${badgeColor}`}>{capitalizeFirstChar(status)}</span>
    )
  }

  return (
    <>
      {renderStatus()}
    </>
  )
}


const FormPraQualificationEvaluate = ({ proposalTender, selectedRow, onBack }: any) => {
  const [evaluateVendorStatus, setEvaluateVendorStatus] = React.useState<any>(null);
  const [alasanDitolak, setAlasanDitolak] = React.useState<any>(selectedRow?.note_pq);
  const intl = useIntl();
  const [syarats, setSyarats] = React.useState<any>([]);
  const [renderKey, setRenderKey] = React.useState(0);
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosGet(
    getEnv("tender") + `/evaluation/pq/${proposalTender?.uuid}/${selectedRow?.uuid}`
  )
  const  [{ doSubmit: doSubmitSave, isLoading: isLoadingSave }] = useAxiosPost(
    getEnv("tender") + `/evaluation/pq/syarat`
  )

  const [isCheckAll, setIsCheckAll] = React.useState(false);
  const [isUncheckAll, setIsUncheckAll] = React.useState(false);
  const [previewAttachment, setPreviewAttachment] = React.useState<any>(null);
  const handleCheckAll = () => {
    setIsCheckAll(!isCheckAll);
    setIsUncheckAll(false);
    syarats?.map((item:any, index:number)=>{
      saveSyarat(index, 1, true)
    })
  }
  const handleUncheckAll = () => {
    setIsUncheckAll(!isUncheckAll);
    setIsCheckAll(false);
    syarats?.map((item:any, index:number)=>{
      saveSyarat(index, 0, true)
    })
  }
  const isFormEnabled = () => {
    // if(selectedRow?.status_pra_qualification === "PASSED" || selectedRow?.status_pra_qualification === "FAILED") return false;
    const allowedStatus = ['Pra Qualification'];
    const allowedStatusDetail = ['Quotation','Evaluation','Published'];
    return allowedStatus.includes(proposalTender?.status) && allowedStatusDetail.includes(proposalTender?.status_detail);
  };

  const updateSyarat = (index:number, state:number) => {
    saveSyarat(index, state)
  }

  const getDetail = () => {
    doSubmit({}, undefined, undefined).then((res: any) => {
      setSyarats(res?.data);
    });
    if(selectedRow?.status_pra_qualification === "PASSED"){
      setEvaluateVendorStatus("1")
    } else if(selectedRow?.status_pra_qualification === "FAILED"){
      setEvaluateVendorStatus("0")
    }
  };


  const handleEvaluateVendor = () => {
    Swal.fire({
      title: `${intl.formatMessage({id:'Apakah Anda yakin'})}?`,
      text: evaluateVendorStatus === '1' ? intl.formatMessage({id:'Anda akan menyetujui vendor ini'}) : intl.formatMessage({id:'Anda akan menolak vendor ini'}),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({id:"Ya, Submit"}),
      cancelButtonText: intl.formatMessage({id:"Tidak"})
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const url = getEnv('tender') + `/evaluation/pq/vendor/${proposalTender?.uuid}`
        doSubmitSave({
          proposal_tender_vendor_id: selectedRow?.uuid,
          evaluation: evaluateVendorStatus === "1",
          reason: alasanDitolak
        },url,toast).then((res:any)=>{
        })
      }
    })
  }

  const checkIsTerimaEnabled = () => {
    if(!isFormEnabled()) return false;

    let currentState = true;
    syarats?.map((item:any, index:number)=>{
      if(item?.required_attachment && item?.status !== 'PASSED'){
        currentState = false;
      }
    })
    console.log(syarats)
    return currentState;
  }
  const saveSyarat = (index:any,state:number, isAll?:boolean) => {
    // @ts-ignore
    const syarat = syarats[index];
    const payload = {
      proposal_tender_vendor_id: selectedRow?.uuid,
      proposal_tender_syarat_id: syarat?.uuid,
      evaluation: state,
      description: '-'
    }
    doSubmitSave(payload, getEnv("tender") + `/evaluation/pq/syarat/${proposalTender?.uuid}`, undefined, () => {}).then((res: any) => {
      const newSyarats = [...syarats];
      newSyarats[index].status = state === 1 ? 'PASSED' : 'FAILED';
      setSyarats(newSyarats);
      setRenderKey(renderKey + 1);
      if((isAll && index === syarats.length - 1) || !isAll){
        toast.success(intl.formatMessage({id:"Data berhasil disimpan"}));
      }
    });
  }

  useEffect(() => {
    getDetail();
  }, []);

  useEffect(() => {
  }, [syarats]);

  return (
    <>
      <div className={"row"}>
        <div className={"col-md-6 mb-4"}>
          <button className={"btn btn-danger btn-sm"} type={"button"} onClick={onBack}>
            <i className={"fa fa-arrow-left"}></i> {intl.formatMessage({id:"Kembali"})}
          </button>
        </div>
        <div className={"col-md-6"}></div>
        {isLoading && (
          <Placeholder.Grid rows={10} columns={5} active={true} />
        )}
        <table className={"table table-bordered table-striped table-header-gray"}>
          <thead>
          <tr>
            <th className={"text-center border px-2"} rowSpan={2}><strong>{intl.formatMessage({id:"No"})}</strong></th>
            <th className={"text-left border"} rowSpan={2}><strong>{intl.formatMessage({id:"Deskripsi"})}</strong></th>
            <th className={"text-center border"} rowSpan={2}><strong>{intl.formatMessage({id:"Mandatory"})}</strong></th>
            <th className={"text-center border"} rowSpan={2}><strong>{intl.formatMessage({id:"Jenis"})}</strong></th>
            <th className={"text-left border"} rowSpan={2}><strong>{intl.formatMessage({id:"Dokumen"})}</strong></th>
            <th className={"text-center border text-nowrap"} colSpan={2}><strong>{intl.formatMessage({id:"Hasil Evaluasi"})}</strong></th>
          </tr>
          <tr>
            <th className={"text-center text-nowrap"}>
              <div className={"d-flex align-items-center gap-2 justify-content-center"}>
                <input type={"checkbox"} checked={isCheckAll} disabled={!isFormEnabled()}
                       onChange={handleCheckAll} /> {intl.formatMessage({id:"Setujui Semua"})}
              </div>
            </th>
            <th className={"text-center text-nowrap"}>
              <div className={"d-flex align-items-center gap-2 justify-content-center"}>
                <input type={"checkbox"} checked={isUncheckAll} disabled={!isFormEnabled()}
                       onChange={handleUncheckAll} /> {intl.formatMessage({id:"Tolak Semua"})}
              </div>
            </th>
          </tr>
          </thead>
          <tbody>
          {syarats?.map((item: any, index: number) => {
            return (
              <tr key={"items-" + index}>
                <td className={"text-center"}>{index + 1}</td>
                <td className={"text-start"}>{item?.description}</td>
                <td className={"text-center"}>
                  {item?.required_attachment ? intl.formatMessage({id:"Ya"}) : intl.formatMessage({id:"Tidak"})}
                </td>
                <td className={"text-center"}>
                  <StatusTypeBadge status={item?.type} />
                </td>
                <td className={"text-start"}>
                  {item?.media_url && (
                    <div className={"input-group input-group-sm"}>
                      <input type={"text"} className={"form-control form-control-sm"} value={item?.description} disabled={true} />
                      <button type={"button"} className={"btn btn-primary btn-sm"} disabled={isLoading} onClick={()=>setPreviewAttachment(
                        item?.media_url
                      )}>
                        <i className={"fa fa-eye"}></i>
                      </button>
                    </div>
                  )}
                  {!item?.media_url &&
                    <div className={"text-gray"} style={{
                      fontStyle: 'italic',
                      color: '#a1a5b7'
                    }}><i className={"fa fa-file"}></i> {intl.formatMessage({id:"Tidak ada data"})}</div>
                  }
                </td>
                <td className={"border text-center"}>
                  <div className={"d-flex align-items-center justify-content-center gap-2"}>
                    {isLoadingSave && (
                      <i className={"fa fa-spinner fa-spin"}></i>
                    )}
                    <input type={"checkbox"} checked={item?.status === 'PASSED'} disabled={!isFormEnabled()} onChange={(e:any)=>{
                      updateSyarat(index, 1)
                    }} /> {intl.formatMessage({id:"Diterima"})}
                  </div>

                </td>
                <td className={"border text-center"}>
                  <div className={"d-flex align-items-center justify-content-center gap-2"}>
                    {isLoadingSave && (
                      <i className={"fa fa-spinner fa-spin"}></i>
                    )}
                    <input type={"checkbox"} checked={item?.status === 'FAILED'} disabled={!isFormEnabled()} onChange={(e:any)=>{
                      updateSyarat(index, 0)
                    }}  /> {intl.formatMessage({id:"Ditolak"})}
                  </div>
                </td>
              </tr>
            );
          })}
          </tbody>
        </table>
        <div className={"mt-5"}>
          <div className={"form-group mb-4"}>
            <label className="required form-label">
              {intl.formatMessage({id:"Evaluasi Vendor"})}
            </label>
            <br />
            <RadioGroup name="radioList" className={"gap-0"} value={evaluateVendorStatus} readOnly={!isFormEnabled()}>
              <Radio value="1" className={""} style={{
                marginBottom: '-10px'
              }} disabled={isFormEnabled() ? !checkIsTerimaEnabled() : false} onChange={(e:any)=>setEvaluateVendorStatus(e)}>{intl.formatMessage({id:"Diterima"})}</Radio>
              <Radio value="0" className={""} onChange={(e:any)=>setEvaluateVendorStatus(e)}>{intl.formatMessage({id:"Ditolak"})}</Radio>
            </RadioGroup>
          </div>
          {evaluateVendorStatus === "0" && (
            <div className={"form-group mb-4"}>
              <label className="required form-label">
                {intl.formatMessage({id:"Alasan Ditolak"})}
              </label>
                <textarea className={"form-control"} value={alasanDitolak} disabled={!isFormEnabled()} onChange={(e:any)=>setAlasanDitolak(e.target.value)}></textarea>
            </div>
          )}
          <div className={"form-group"}>
            <button
              className={"btn btn-primary btn-sm me-2"}
              type={"button"}
              disabled={!isFormEnabled()}
              onClick={()=>handleEvaluateVendor()}
            >
              <i className={"fa fa-save"}></i> {intl.formatMessage({id:"Submit"})}
            </button>
          </div>
        </div>
        <PreviewAttachment previewExt={previewAttachment} onHide={setPreviewAttachment} />
      </div>
    </>
  );
};


const PraQualificationEvaluatePage = ({ proposalTender, onRefresh }: any) => {
  const urlIndex = getEnv("tender") + `/dur-vendor?filter[proposal_tender_id]=${proposalTender?.id}`;
  const [selectedRows, setSelectedRows] = React.useState<any>(null);
  const intl = useIntl();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    if(proposalTender){
      const _date = proposalTender?.jadwals.find((jadwal:any) => jadwal.description === "Pra Qualification")
      console.log(_date)
      setStartDate(_date ? _date.start_at : "")
      setEndDate(_date ? _date.end_at : "")
    }
  }, [proposalTender]);

  const tableColumns = [
    {
      Header: intl.formatMessage({id:"Aksi"}),
      accessor: "line_no",
      width: 100,
      className: "text-center",
      Cell: (props: any) => {
        return (
          <>
            <a href={"#"} title={"Lihat Detail"} onClick={(e: any) => {
              e.preventDefault();
              setSelectedRows(props.row.original);
            }} className={"btn btn-primary btn-sm"}>
              <i className={"fa fa-eye"}></i>
            </a>
          </>
        );
      }
    },
    {
      Header: intl.formatMessage({id:"ID Vendor"}), accessor: "vendor_id", width: 150, className: "text-start", Cell: (props: any) => {
        return (
          <>
            {props.row.original.vendor_id}
          </>
        );
      }
    },
    { Header: intl.formatMessage({id:"Nama Vendor"}), accessor: "vendor.name", width: 300, className: "text-start" },
    { Header: intl.formatMessage({id:"Status PQ Vendor"}), accessor: "status_pra_qualification_vendor", width: 200, className: "text-center text-nowrap", Cell: (props: any) => {
      return (
        <>
          {props.row.original.status_pra_qualification_vendor === "QUOTE" ? (
            <span className={"badgex bgx-approved"}>
              {props.row.original.status_pra_qualification_vendor}
            </span>
          ) : (
            <span className={"badgex bgx-pending"}>
              {props.row.original.status_pra_qualification_vendor}
            </span>
          )}
        </>
      )
    }},
    { Header: intl.formatMessage({id:"Status Evaluasi"}), accessor: "status_pra_qualification", width: 250, className: "text-center text-nowrap", Cell: (props: any) => {
      return (
        <>
          {props.row.original.status_pra_qualification === "PASSED" && (
            <span className={"badge bg-success"}>
              {props.row.original.status_pra_qualification}
            </span>
          )}
          {props.row.original.status_pra_qualification === "FAILED" && (
            <span className={"badge bg-danger"}>
              {props.row.original.status_pra_qualification}
            </span>
          )}
          {props.row.original.status_pra_qualification === "NOT-EVALUATE" && (
            <span className={"badge bg-info"}>
              {props.row.original.status_pra_qualification}
            </span>
          )}
        </>
      )
    }}

  ];
  const [{ doSubmit: doSubmitPost, isLoading: isLoadingPost, data: dataPost }] = useAxiosPost(`${getEnv("tender")}/dur-vendor/${proposalTender?.uuid}}`);
  const isShowClosePQ = () => {
    const allowedStatusDetail = ["Quotation","Published","Evaluation"];
    return proposalTender?.status === "Pra Qualification"
      && allowedStatusDetail.includes(proposalTender?.status_detail);
  };

  const handleTutupPQ = async() => {
    Swal.fire({
      title: `${intl.formatMessage({id:"Apakah Anda yakin"})}?`,
      text: intl.formatMessage({id:'Anda akan menutup sesi Pra Qualification ini'}),
      icon: "question",
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({id:"Ya, Submit"}),
      cancelButtonText: intl.formatMessage({id:"Tidak"})
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        doSubmitPost(
          {},
          getEnv("tender") + `/pq/submit/${proposalTender.uuid}`,
          toast
        ).then((res: any) => {
          onRefresh && onRefresh();
        });
        onRefresh && onRefresh();
      }
    });
  }

  return (
    <>
      <h3>
        {!selectedRows && (intl.formatMessage({id:"Daftar Vendor PQ"}))}
        {selectedRows &&(intl.formatMessage({id:"Detail Vendor PQ"}))}
      </h3>
      <hr />
      {!selectedRows &&(
        <div className={"row"}>
          <div className={"col-md-6"}>
            <div className={"form-group mb-5"}>
              <label htmlFor=""><strong>{intl.formatMessage({id:"Jadwal Mulai Pra Qualification"})}</strong></label>
              <DatepickerX2 value={startDate} onChange={(e: any) => {}} readonly={true} format={"yyyy-MM-dd HH:ii:ss"} />
            </div>
            <div className={"form-group mb-3"}>
              <label htmlFor=""><strong>{intl.formatMessage({id:"Jadwal Selesai Pra Qualification"})}</strong></label>
              <DatepickerX2 value={endDate} onChange={(e: any) => {}} readonly={true} format={"yyyy-MM-dd HH:ii:ss"} />
            </div>
          </div>
        </div>
      )}
      <br />
      {!selectedRows && (
        <TableXWrapper
          readonly={true}
          title={"Proposal Tender"}
          urlIndex={urlIndex}
          columns={tableColumns}
          isForceCreateButton={true}
          disableUrlChanged={true}
        />
      )}
      {selectedRows && (
        <FormPraQualificationEvaluate proposalTender={proposalTender} selectedRow={selectedRows}
                                      onBack={() => setSelectedRows(null)} />
      )}
      <BottomToolbarWrapper children={
        <>
          {isShowClosePQ() && (
            <button className={"btn btn-danger"} type={"button"} onClick={handleTutupPQ}>
              {isLoadingPost ? <Spinner animation="border" size={"sm"} /> : <i className={"fa fa-flag"}></i>}
              {intl.formatMessage({id:"Tutup PQ"})}
            </button>
          )}

        </>
      } />
    </>
  );
};

export default PraQualificationEvaluatePage;