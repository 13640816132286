import { FC, useState } from 'react'
import { Col, Container, Modal, Row } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { NavLink } from 'react-router-dom'
import NavigationBar from './components/NavigationBar'
import TenderingList from './components/TenderingList'

const HomePage: FC = () => {
  const intl = useIntl()
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{intl.formatMessage({ id: 'LOGIN.AS' })}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='text-center'>
            <button onClick={handleClose} className='btn btn-info btn-lg'>
              Vendor
            </button>
            <NavLink to='/dashboard' className='btn btn-primary ms-5 btn-lg'>
              Internal
            </NavLink>
          </div>
        </Modal.Body>
      </Modal>
      
      <NavigationBar />
      <div
        style={{
          height: '500px',
          backgroundColor: '#777',
          backgroundImage:
            'linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0)), url(/background_login.jpg)',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <Container>
          <Row>
            <Col>
                <p style={{ color: 'white', fontSize: '28px', fontWeight: '550', textAlign: 'left', paddingTop: '160px' }}>
                  Procurement System <br /> Pupuk Indonesia (Persero)
                </p>
            </Col>
          </Row>
        </Container>
      </div>
      <main>
        <Container>
          <Row>
            <Col>
              <div className='card' style={{ marginTop: '-80px' }}>
                <div className='card-body'>
                  <TenderingList />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </main>
      <footer className='mt-10 text-center py-5'>
        © 2023 PT Pupuk Indonesia (Persero) | All Rights Reserved
      </footer>
    </>
  )
}

export { HomePage }
