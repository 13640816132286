import { ID, KTCard, KTCardBody } from '../../../../../../_metronic/helpers'
import React, { useState, useEffect } from 'react'
import { ApiUrl } from '../../../../../config/Api'
import { getEnv } from "../../../../../../helpers/procurex";
import { initialQueryState, KTSVG, useDebounce } from '../../../../../../_metronic/helpers'
import { toast } from 'react-toastify'
import axios from 'axios'
import { Button, ButtonGroup, Col, Modal, Nav, Row, Tab } from "react-bootstrap";
import { useAuth } from "../../../../auth";
import * as pdfjsLib from 'pdfjs-dist';
import { BuletinCardStockview } from './BuletinCardStockview';
import { BuletinRepositoryStockview } from './BuletinRepositoryStockview';
import DatepickerX2 from '../../../../../components/shared/form/DatepickerX2'

const BuletinDashboardStockview = () => {
    const { currentUser } = useAuth();
    const [itemsBuletinPrice, setItemsBuletinPrice] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [activeMenu, setActiveMenu] = useState('search-publikasi');
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [itemLoading, setItemLoading] = useState(false)

    useEffect(() => {
        loadItem();
    }, []);

    const loadItem = () => {
        setItemLoading(true);
        axios.get(getEnv("stockview") + `/buletin-price`)
            .then((res) => {
                setItemsBuletinPrice(res.data.data);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setItemLoading(false);
            });
    };

    const filteredItems = itemsBuletinPrice.filter(item => {
        if (item && item.judul) {
            return item.judul.toLowerCase().includes(searchTerm.toLowerCase());
        }
        return false;
    });

    const groupedItems = itemsBuletinPrice.reduce((groups, item) => {
        const jenis = item.jenis_publikasi;
        if (!groups[jenis]) {
            groups[jenis] = [];
        }
        groups[jenis].push(item);
        return groups;
    }, {});

    const handleDateChange = (newDate: Date | null) => {
        setSelectedDate(newDate);

        if (newDate) {
            setItemLoading(true);
            axios.get(getEnv("stockview") + `/buletin-price-filter?tanggal=${newDate}`)
                .then((res) => {
                    setItemsBuletinPrice(res.data.data);
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    setItemLoading(false);
                });
        } else {
            loadItem();
        }
    };

    return (
        <>
            <div className='d-flex justify-content-center mb-3'>
                <button
                    className={`btn ${activeMenu === 'search-publikasi' ? 'btn-primary' : 'btn-secondary'}`}
                    onClick={() => setActiveMenu('search-publikasi')}
                >
                    Search Publikasi
                </button>
                <button
                    className={`btn ${activeMenu === 'repository' ? 'btn-primary' : 'btn-secondary'} ms-3`}
                    onClick={() => setActiveMenu('repository')}
                >
                    Repository
                </button>
            </div>

            <KTCard className='card border mt-5'>
                <KTCardBody>
                    {activeMenu === 'search-publikasi' && (
                        <div id='search-publikasi'>
                            <div className='row'>
                                <div className='col-9 mb-4 text-end	'>
                                    Filter Tanggal dan Tahun
                                </div>
                                <div className='col-3 mb-4 text-start'>
                                    <DatepickerX2
                                        onChange={handleDateChange}
                                        value={selectedDate}
                                        size={"sm"}
                                        format={"dd MMMM yyyy"}
                                    />
                                </div>
                            </div>
                            <div className='d-flex align-items-center position-relative my-1'>
                                <KTSVG
                                    path='/media/icons/duotune/general/gen021.svg'
                                    className='svg-icon-1 position-absolute ms-6'
                                />
                                <input
                                    type='text'
                                    data-kt-user-table-filter='search'
                                    className='form-control form-control-solid ps-14'
                                    placeholder='Search user'
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                            {itemLoading ? (
                                <div className="text-center">
                                    <h1>Loading... <span className='spinner-border h-15px w-15px align-middle text-gray-400' /></h1>
                                </div>
                            ) : (
                                <div id='content-catalog' className='row row-catalog'>
                                    {filteredItems.length > 0 ? (
                                        filteredItems
                                            .sort((a, b) => new Date(b.tanggal_keluar_publikasi).getTime() - new Date(a.tanggal_keluar_publikasi).getTime())
                                            .map((item, index) => (
                                                <BuletinCardStockview key={index} item={item} />
                                            ))
                                    ) : (
                                        <div className="col-12 text-center">
                                            <h1>Data Publikasi Tidak Ditemukan</h1>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    )}

                    {activeMenu === 'repository' && (
                        <div id='repository'>
                            <BuletinRepositoryStockview filteredItems={filteredItems} groupedItems={groupedItems} />
                        </div>
                    )}
                </KTCardBody>
            </KTCard>
        </>
    );
};

export { BuletinDashboardStockview };

