import React, { useEffect, useState } from "react";
import { useAxiosGet } from "../../../../../../../../hooks/useAxios";
import { getEnv } from "../../../../../../../../helpers/procurex";
import { Placeholder } from "rsuite";
import { useIntl } from "react-intl";

const TR= ({item, proposalTender}:any) => {

  const allowanceShowCompliance = [
      'REGISTRATION-QUOTATION',
      'NEGOTIATION',
      'NEGOTIATION_PROCESS',
      'WINNER'
  ];

  const checkIsShowCompliance = () => {
    return allowanceShowCompliance.includes(item?.vendor?.status_tender)
  }
  return (
    <tr key={item?.uuid}>
      <td className={"border px-2"}>
        {item?.vendor?.vendor_id} <br />
        {item?.vendor?.vendor?.name}
      </td>
      <td className={"border px-2"}>
        {checkIsShowCompliance() && (
          item?.items?.map((item2:any, index2:number)=>{
            return (
              <>
                <div className={"d-flex mb-2 align-items-start gap-2"}>
                  <div className={"badge badge-secondary"}>{item2?.quote}</div> :
                  <div><b>{item2?.short_text}</b> - </div>
                  <div><b className="text-highlight">{item2?.no_pr} ({item2?.line_no})</b></div>
                </div>
              </>
            )
          })
        )}

      </td>
      <td className={"border px-2 text-center"}>
        <span className={"badge badge-primary"}>
          {item?.vendor?.status_tender}
        </span>
      </td>
      <td className={"border px-2"}>
        {item?.updated_at}
      </td>
    </tr>
  )
}

const GambaranUmumSatuSampulTab = ({proposalTender, jadwalDescription}:any) => {
  const intl = useIntl();
  const [startDate, setStartDate] = useState("");
  const [dataItems,setDataItems] = useState([] as any)
  const [renderKey,setRenderKey] = useState(0);
  const [endDate, setEndDate] = useState("");
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosGet(
    getEnv("tender") + `/evaluation/quotation/${proposalTender?.uuid}`
  )

  const getItems = () => {
    doSubmit({},undefined,undefined).then((res:any)=>{
      setDataItems(res.data)
    })
  }

  useEffect(() => {
    getItems()
  }, []);

  useEffect(() => {
    if(proposalTender){
      const _date = proposalTender?.jadwals.find((jadwal:any) => jadwal.description === "Penawaran")
      setStartDate(_date ? _date.start_at : "")
      setEndDate(_date ? _date.end_at : "")
      setRenderKey(renderKey+1);
    }
  }, [proposalTender]);
  return (
    <>
      <div className={"row"} key={renderKey}>
        <div className={"col-md-6"}>
          <div className={"form-group mb-5"}>
            <label htmlFor=""><strong>{intl.formatMessage({id:'Jadwal Mulai Pemasukan Penawaran'})}</strong></label>
            {/*<DatepickerX2 value={startDate} onChange={(e: any) => {}} readonly={true} format={"yyyy-MM-dd HH:ii:ss"} />*/}
            <input type={"text"} className={"form-control"} value={startDate} disabled={true}/>
          </div>
          <div className={"form-group mb-3"}>
            <label htmlFor=""><strong>{intl.formatMessage({id:'Jadwal Selesai Pemasukan Penawaran'})}</strong></label>
            {/*<DatepickerX2 value={endDate} onChange={(e: any) => {}} readonly={true} format={"yyyy-MM-dd HH:ii:ss"} />*/}
            <input type={"text"} className={"form-control"} value={endDate} disabled={true}/>
          </div>
        </div>

        <div className={"col-md-12 mt-5"}>
          {isLoading && <Placeholder.Grid rows={5} columns={4} active /> }
          {!isLoading && (
            <div className={"table-responsive"}>
              <table className={"table table-header-gray"}>
                <thead>
                <tr>
                  <th className={"border px-2"}>{intl.formatMessage({id:"Nama Rekanan"})}</th>
                  <th className={"border px-2"}>{intl.formatMessage({id:"Compliance"})}</th>
                  <th className={"border px-2 text-center"}>{intl.formatMessage({id:"Status"})}</th>
                  <th className={"border px-2 text-nowrap"}>{intl.formatMessage({id:"Tanggal Quotation"})}</th>
                </tr>
                </thead>
                <tbody>
                {dataItems.length === 0 && (
                  <tr>
                    <td colSpan={4} className={"border text-center"}>{intl.formatMessage({ id: "Tidak ada data" })}</td>
                  </tr>
                )}
                {dataItems.map((item: any, index: number) =>  {
                  return (
                      <TR key={"peserta-"+index} proposalTender={proposalTender} item={item} />
                  )
                })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default GambaranUmumSatuSampulTab;