import { FC, useEffect, useState } from "react";
import clsx from 'clsx'
import { Link, useNavigate } from "react-router-dom";
import {useLocation} from 'react-router'
import {checkIsActive, KTSVG, WithChildren} from '../../../helpers'
import {useGetSideMenu} from "../core/hooks";
import { Badge } from "rsuite";
import { useAxiosGet } from "../../../../hooks/useAxios";
import { Spinner } from "react-bootstrap";

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  countingUrl?: string
}

const AsideMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  countingUrl
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const [totalCounting, setTotalCounting] = useState("")
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosGet(countingUrl || '')
  const navigation = useNavigate();

  useEffect(() => {
    if(countingUrl) {
      doSubmit({},undefined,undefined).then((res: any) => {
        if(res?.meta?.total > 100){
          setTotalCounting('99+')
          return
        }
        setTotalCounting(res?.meta?.total || "0")
      })
    }
  }, [countingUrl])

  const handleOnClick = async(e:any)=>{
    e.preventDefault()
    const isHttp = to.includes('http') || to.includes('https')
    if (isHttp) {
      window.open(to, "_blank")
      return
    }
    navigation(to)
  }

  // @ts-ignore
  return (
    <div className='menu-item'>
      <a href={"#"} className={clsx('menu-link without-sub', {active: isActive})} onClick={handleOnClick}>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {(icon && !icon.includes("fa-")) && (
          <span className='menu-icon'>
            <KTSVG path={icon} className='svg-icon-2' />
          </span>
        )}
        {(icon && icon.includes("fa-")) && (
            <span className='menu-icon'>
            <i className={icon} />
            </span>
        )}
        {fontIcon && <i className={clsx('bi fs-3', fontIcon)}></i>}
        <span className='menu-title'>{title}</span>
        {countingUrl && (
          <>
            <Badge content={<>
              {isLoading && (
                <Spinner animation={'border'} size={"sm"} style={{color: 'white'}} />
              )}
              {!isLoading && totalCounting !== "0" && totalCounting}
            </>} />
          </>
        )}
      </a>
      {children}
    </div>
  )
}

export {AsideMenuItem}
