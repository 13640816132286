import { ID, KTCard, KTCardBody } from '../../../../../../_metronic/helpers'
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import TableXWrpper from "../../../../../components/shared/TableXWrapper";
import { ApiUrl } from '../../../../../config/Api'
import { getEnv } from "../../../../../../helpers/procurex";
import { useAuth } from "../../../../../../app/modules/auth";

const GoodMovementStockviewList = () => {
    const { currentUser } = useAuth();

    const tablecolumns = [
        {
            Header: "POSTING DATE",
            accessor: "post_date",
            width: 100,
            Cell: ({ value }) => {
                if (!value) return '';
                const date = new Date(value);
                const day = date.getDate().toString().padStart(2, '0');
                const month = (date.getMonth() + 1).toString().padStart(2, '0');
                const year = date.getFullYear();
                return `${day}-${month}-${year}`;
            }
        },
        {
            Header: "MATERIAL NUMBER",
            accessor: "material_number_desc",
            width: 230,
        },
        {
            Header: "MOVEMENT TYPE",
            accessor: "movement_type",
            width: 100,
        },
        {
            Header: "PLANT",
            accessor: "plant",
            width: 200,
        },
        {
            Header: "SLOC",
            accessor: "sloc",
            width: 200,
        },
        {
            Header: "QUANTITY (TON)",
            accessor: "qty",
            width: 100,
        },
        {
            Header: "COMPANY",
            accessor: "company_code",
            width: 100,
        },
        {
            Header: "REFERENCE DOCUMENT",
            accessor: "reference",
            width: 150,
        },
    ];
    const [formIndex, setFormIndex] = useState(0);
    const [showModal, setShowModal] = useState(false);

    const handleButtonClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    let company_code = currentUser.company_code;

    return (
        <>
            <KTCard className='card border mt-5'>
                <KTCardBody >
                    <TableXWrpper
                        title={"MR/SR Unit Kerja"}
                        key={formIndex}
                        readonly
                        urlIndex={getEnv("stockview") + `/transmovement-list?company_code=${company_code}`}
                        columns={tablecolumns}
                    // headerButtons={
                    //     <>
                    //         <Link
                    //             to="/stockview/transaction/goods-movement/create"
                    //             className="btn btn-primary"
                    //             title="Add Data"
                    //         >
                    //             ADD Data
                    //         </Link>
                    //     </>
                    // }
                    />
                </KTCardBody>
            </KTCard>
        </>
    )
}

export { GoodMovementStockviewList }
