import { Modal } from "react-bootstrap";
import React, { Component, useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../modules/auth";
import FormikSelect2 from "./form/FormikSelect2";
import Swal from "sweetalert2";

interface IProps {
  title: string;
  show: boolean;
  closeModal: Function;
  tableList: any;
  onSubmit?: Function;
  isLoading?: boolean;
  isTerimaDokumen?: boolean;
}

const ModalDisposisiRejectX = ({
                                 title,
                                 show,
                                 closeModal,
                                 tableList,
                                 onSubmit,
                                 isLoading,
                                 isTerimaDokumen
                               }: IProps) => {
  const initialValues = {
    notes: ""
  };
  const validationSchema = Yup.object({
    notes: Yup.string().required("The field is required")
  });
  const formikRef = React.useRef<any>();

  useEffect(() => {
    if (show) {
      formikRef.current?.resetForm();
    }
  }, [show]);

  const handleSubmit = (values: any) => {
    if (onSubmit) {
      Swal.fire({
        title: "Apakah anda yakin?",
        text: isTerimaDokumen ? "Anda akan mengembalikan dokumen ini." : "Anda akan melakukan pengembalian dokumen ini.",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Ya, lanjutkan",
        cancelButtonText: "Tidak"
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          onSubmit(values);
        }
      });
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        innerRef={formikRef}
      >
        {({ errors, touched, values, submitForm }) => (
          <Form>
            <Modal show={show} size={"lg"}>
              <Modal.Header closeButton onHide={() => closeModal(false)}>
                <Modal.Title>{title}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <div className={"table-responsive"} style={{
                    maxHeight: "300px"
                  }}>
                    {tableList}
                  </div>
                  <div className="my-10">
                    <label className="form-label mb-3" htmlFor="note">
                      Catatan {isTerimaDokumen ? "Pengembalian Dokumen" : "Reject"}
                    </label>
                    <Field
                      as={"textarea"}
                      name={"notes"}
                      className={"form-control"}
                    />
                    {errors.notes && touched.notes && (
                      <>
                        {/* @ts-ignore*/}
                        <div className="text-danger">{errors.notes}</div>
                      </>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  onClick={() => {
                    closeModal(false);
                  }}
                  disabled={isLoading}
                  className="btn btn-danger">
                  <i className="fa fa-times"></i>
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={isLoading}
                  onClick={submitForm}
                  className="btn btn-primary">
                  {isLoading && <i className="fa fa-spinner fa-spin"></i>}
                  {!isLoading && <i className="fa fa-save"></i>}
                  Submit
                </button>
              </Modal.Footer>
            </Modal>

          </Form>
        )}
      </Formik>
    </>
  );
};

// @ts-ignore
export default ModalDisposisiRejectX;