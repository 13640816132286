import axios from 'axios'
import {useState} from 'react'
import {ApiUrl} from '../app/config/Api'


function useUploadMedia() {
  const [isLoading, setIsLoading] = useState(false)

  const upload = async (mediaTypeId: string, file: any, notes=null) => {
    let formData = new FormData()
    formData.append('file', file)
    formData.append('media_type_id', mediaTypeId)
    formData.append("notes",notes || file?.name)

    try {
      const response = await axios.post(`${ApiUrl}/api/media/media`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      if (response.status === 200 || response.status === 201) {
        return response.data.data
      }
    } catch (error) {
      console.error(error)
      return error
    } finally {
      setIsLoading(false)
    }
  }

  return {upload, isLoading}
}

export {useUploadMedia}
