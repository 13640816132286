import { Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { formatCurrency } from "../../../../../../../_metronic/helpers";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import {getEnv, parseNumberToFloat} from '../../../../../../../helpers/procurex'
import { useAxiosGet, useAxiosPost } from "../../../../../../../hooks/useAxios";
import { ApprovalX } from "../../../../../../components/shared/ApprovalX";

type IProps = {
  proposalTender: any
  show: boolean
  onHide: Function
}

const RetenderForm = ({ proposalTender, show, onHide }: IProps) => {
    const [items, setItems] = useState(proposalTender?.items?.filter((item: any) => item?.retender === "NONE"));
    const [selectedItems, setSelectedItems] = useState([] as any);
    const [selectAll, setSelectAll] = useState(false);
    const [renderKey, setRenderKey] = useState(0);
    const [approvalSchema, setApprovalSchema] = React.useState([]);
    const [isShowApproval,setIsShowApproval] = useState(false)
    const [checkBoxDisabled,setCheckBoxDisabled] = useState(false)
    const [cancelReason, setCancelReason] = useState("");
    const [{doSubmit, isLoading}] = useAxiosPost("");
    const [{doSubmit: doSubmitGet, isLoading: isLoadingGet}] = useAxiosGet("");

    const handleOnChecked = (event: any, uuid: string) => {
      if (selectedItems.includes(uuid)) {
        const tmp = selectedItems?.filter((v: any) => v !== uuid);
        setSelectedItems(tmp);
      } else {
        const tmp = selectedItems;
        tmp.push(uuid);
        setSelectedItems(tmp);
      }
      setRenderKey(renderKey + 1);
    };

    const handleSelectAll = (event: any) => {
      const isChecked = event.target.checked;
      setSelectAll(isChecked);
      if (isChecked) {
          const allItemUUIDs = items?.map((item: any) => item.uuid) || [];
          setSelectedItems(allItemUUIDs);
      } else {
          setSelectedItems([]);
      }
      setRenderKey(renderKey + 1);
    };

    const onSubmit = async () => {
      if(!cancelReason){
        toast.error("Alasan Retender wajib diisi")
        return ;
      }
      Swal.fire({
        title: "Apakah Anda yakin?",
        text: "Anda akan me-Retender item ini",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya, Retender",
        cancelButtonText: "Batal"
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          const newUrl = getEnv('tender')+`/tender/re-tender/routing-approval-code/${proposalTender?.uuid}`;
          doSubmitGet({}, newUrl, undefined).then((res: any) => {
            setApprovalSchema(res.data?.details);
            setIsShowApproval(true);
            onHide()
          }).finally(() => {
          });
        }
      });
    };

    const handleOnSubmitRetender = (data:any) => {
      const payload = {
        cancel_reason: cancelReason,
        approvers: data.approval.map((item: any) => {
          return {
            step: item.step,
            jabatan: item.jabatan,
            type: "APPROVAL",
            user_id: item.extra_info.user.user_id,
            extra_info: item.extra_info
          };
        }),
        ...(proposalTender?.order_placement === "Itemize" && { proposal_tender_items: selectedItems })
      }

      let newUrl: string
      if (proposalTender?.order_placement === "Itemize") {
        newUrl = getEnv('tender')+`/tender/re-tender/${proposalTender?.uuid}`
      } else {
        newUrl = getEnv('tender')+`/tender/cancel/${proposalTender?.uuid}`
      }
      
      doSubmit(payload,newUrl,toast).then((res:any)=>{
        if (res?.meta?.code === 200) {
          setIsShowApproval(false);
          window.location.reload()
        }
      })
    }

    useEffect(() => {
      if (proposalTender?.order_placement === "Paket") {
        const allItemUUIDs = items?.map((item: any) => item.uuid) || [];
        setSelectedItems(allItemUUIDs);
        setSelectAll(true);
        setCheckBoxDisabled(true)
      }
    }, [items]);

    return (
      <>
        <Modal size={"xl"} show={show}>
          <Modal.Header onHide={() => onHide(false)}>
            <h4>Pilih Item Untuk Retender</h4>
          </Modal.Header>
          <Modal.Body>
            <div className={"table-responsive"} key={"renderKey" + renderKey}>
              <table className={"table table-striped table-header-gray"}>
                <thead>
                <tr>
                  <th className={"px-2 border text-center"} style={{ width: 50 }}>
                      <input
                          type={"checkbox"}
                          className={"form-check-input"}
                          checked={selectAll}
                          disabled={checkBoxDisabled}
                          onChange={handleSelectAll}
                      />
                  </th>
                  <th className={"px-2 border text-center"} style={{ width: 50 }}>No</th>
                  <th className={"px-2 border text-center"}>No PR</th>
                  <th className={"px-2 border text-center"}>Line No</th>
                  <th className={"px-2 border text-center"}>Material Group</th>
                  <th className={"px-2 border"}>Short Text</th>
                  <th className={"px-2 border text-end"}>Quantity</th>
                  <th className={"px-2 border text-center"}>UOM</th>
                  <th className={"px-2 border text-center"}>Currency</th>
                  <th className={"px-2 border text-end"}>Harga Satuan</th>
                  <th className={"px-2 border text-end"}>Total Harga</th>
                </tr>
                </thead>
                <tbody>
                {items?.map((v: any, index: number) => {
                  return (
                    <tr key={"renteder-item-" + index}>
                      <td className={"px-2 border text-center"}>
                        <input
                          type={"checkbox"}
                          className={"form-check-input"}
                          checked={selectedItems?.includes((v?.uuid))}
                          disabled={checkBoxDisabled}
                          onChange={(e: any) => {
                            handleOnChecked(e, v?.uuid);
                          }}
                        />
                      </td>
                      <td className={"px-2 border text-center"}>{index + 1}</td>
                      <td className={"px-2 border text-center"}>{v?.no_pr}</td>
                      <td className={"px-2 border text-center"}>{v?.line_no}</td>
                      <td className={"px-2 border text-center"}>{v?.material_group_id}</td>
                      <td className={"px-2 border"}>{v?.short_text}</td>
                      <td className={"px-2 border text-end"}>{parseNumberToFloat(v?.quantity)}</td>
                      <td className={"px-2 border text-center"}>{v?.uom_id}</td>
                      <td className={"px-2 border text-center"}>{v?.currency_id}</td>
                      <td className={"px-2 border text-end"}>{formatCurrency(v?.valuation_price)}</td>
                      <td className={"px-2 border text-end"}>{formatCurrency(v?.total_value)}</td>
                    </tr>
                  );
                })}
                {items?.length === 0 &&
                  <tr>
                    <td className={"px-2 border text-center"} colSpan={11}>No Data!</td>
                  </tr>
                }
                </tbody>
              </table>
              <div className={"form-group mt-4"}>
                <label>Alasan Retender*</label>
                <textarea className={"form-control"} onChange={(e:any)=>setCancelReason(e.target.value)}></textarea>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className={"btn btn-danger"} type={"button"} onClick={() => onHide()}>
              <i className={"fa fa-times"}></i>
              Batal
            </button>
            <button
              className={"btn btn-primary"}
              type={"button"}
              onClick={onSubmit}
              disabled={selectedItems.length === 0}
            >
              <i className={"fa fa-refresh"}></i>
              Submit Retender
            </button>
          </Modal.Footer>
        </Modal>
        <ApprovalX
          show={isShowApproval}
          schema={approvalSchema}
          onHide={() => {
            setIsShowApproval(false);
          }}
          onSubmit={(data: any) => {
            // handleOnSubmitApprovalSubmitAwarding(data);
            handleOnSubmitRetender(data)
          }}
          isLoading={isLoading}
          source={"TENDER"}
          object_id={proposalTender?.id}
          company_id={proposalTender?.company?.id}
          code={"tender_retender"}
          readOnly={proposalTender?.is_on_approval}
        />
      </>
    );
  }
;

export default RetenderForm;