import React, {useId} from 'react'
import {useIntl} from "react-intl";
import * as Yup from 'yup'
import {Field, Form, Formik} from "formik";
import {useMutation, useQuery} from "react-query";
import axios from "axios";
import {useAuth} from "../../../auth";
import {getEnv} from "../../../../../helpers/procurex";
import {Spinner} from "react-bootstrap";
import {toastError, toastSuccess} from "../../../../../_metronic/helpers";

interface PreferencesData {
  notify_wa: boolean
  notify_email: boolean
}

//region Hooks
const useGetPreferences = (userId: number | string) => {
  const {isLoading: isPreferencesLoading, data: preferences} = useQuery({
    queryKey: ['sso', 'preferences', userId],
    refetchOnWindowFocus: false,
    queryFn: ({ signal }) => axios
      .get(getEnv("sso") + '/preferences', { signal })
      .then((res) => res.data.data.preferences),
  })

  return {isPreferencesLoading, preferences};
}

const useUpdatePreferences = () => {
  const {isLoading: isUpdatePreferencesLoading, mutate: updatePreferences} = useMutation({
    mutationFn: (values: PreferencesData) => axios.post(getEnv("sso") + '/preferences', {
      preferences: values
    }),
    onSuccess: (response) => {
      toastSuccess(response.data?.meta?.message)
    },
    onError: (error) => {
      console.error(error)
      axios.isAxiosError(error) && toastError(error?.response?.data?.meta?.message || 'Terjadi kesalahan')
    },
  })

  return {isUpdatePreferencesLoading, updatePreferences};
}
//endregion

const Preferences: React.FC = () => {
  const intl = useIntl();
  const {currentUser} = useAuth();
  const {isPreferencesLoading, preferences} = useGetPreferences(currentUser?.id || '')
  const {isUpdatePreferencesLoading, updatePreferences} = useUpdatePreferences()
  const ids = {
    notify_wa: useId(),
    notify_email: useId(),
  }

  const isFormDisabled = isPreferencesLoading || isUpdatePreferencesLoading;

  return (
      <div className={'row'}>
        <div className={'col-sm-12 col-md-8 col-lg-8'}>
          <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>{intl.formatMessage({id:'Notifikasi'})}</h3>
              </div>
            </div>
            <div className='collapse show px-10'>
              <Formik
                initialValues={preferences || {
                  notify_wa: true,
                  notify_email: true
                }}
                validationSchema={Yup.object({
                  notify_wa: Yup.boolean().required('The Field is required'),
                  notify_email: Yup.boolean().required('The Field is required'),
                })}
                onSubmit={(values) => updatePreferences(values)}
                enableReinitialize
              >
                <Form>
                  <div className={"d-flex justify-content-between align-items-center"}>
                    <div className="form-check form-check-custom mt-5">
                      <Field
                        className="form-check-input"
                        type="checkbox"
                        id={ids.notify_wa}
                        name="notify_wa"
                        disabled={isFormDisabled}
                      />
                      <label className="form-check-label" htmlFor={ids.notify_wa}>
                        <span>{intl.formatMessage({ id: "Notifikasi Whatsapp" })}</span>
                      </label>
                    </div>
                    <div className={"text-muted"}>
                      <i>{currentUser?.employee?.hp || 'Unknown'}</i>
                    </div>
                  </div>

                  <div className={"d-flex justify-content-between align-items-center"}>
                    <div className="form-check form-check-custom mt-5">
                      <Field
                        className="form-check-input"
                        type="checkbox"
                        id={ids.notify_email}
                        name="notify_email"
                        disabled={isFormDisabled}
                      />
                      <label className="form-check-label" htmlFor={ids.notify_email}>
                        <span>{intl.formatMessage({ id: "Notifikasi Email" })}</span>
                      </label>
                    </div>
                    <div>
                      <i>{currentUser?.employee?.email || 'Unknown'}</i>
                    </div>
                  </div>

                  <div className={"d-flex justify-content-end mb-3"}>
                    <button
                      type="submit"
                      disabled={isFormDisabled}
                      className={"btn btn-primary btn-sm"}
                    >
                      {(isFormDisabled) && (<Spinner animation="border" role="status" size={"sm"} />)}
                      {intl.formatMessage({ id: "BUTTON.SIMPAN" })}
                    </button>
                  </div>
                </Form>
              </Formik>
            </div>

          </div>
        </div>
      </div>
  )
}

export {Preferences}
