import {Outlet, Route, Routes} from 'react-router-dom'
import {PageTitle} from '../../../../../_metronic/layout/core'
import MonitoringEvaluationList, {
  CellRenderer,
  MonitoringEvaluationDetail,
} from './components/MonitoringEvaluationList'
import React from 'react'

const tender = {
  title: 'Tendering',
  path: 'tender',
  isSeparator: false,
  isActive: false,
}
const separator = {
  title: '',
  path: '',
  isSeparator: true,
  isActive: false,
}
const MonitoringEvaluationPage = ({
  mainBreadcrumb,
  actionCell,
}: {
  mainBreadcrumb?: typeof tender
  actionCell?: CellRenderer
}) => {
  const breadcrumbs = [mainBreadcrumb ?? tender, separator]

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          index
          element={
            <>
              <PageTitle breadcrumbs={breadcrumbs}>Monitoring Evaluasi Penawaran</PageTitle>
              <MonitoringEvaluationList actionCell={actionCell} />
            </>
          }
        />
        <Route
          path={'/:uuid'}
          element={
            <>
              <PageTitle breadcrumbs={breadcrumbs}>Monitoring Evaluasi Penawaran</PageTitle>
              <MonitoringEvaluationDetail />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default MonitoringEvaluationPage
