import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useAxiosGet } from "../../../../hooks/useAxios";
import { getEnv } from "../../../../helpers/procurex";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";

const VersioningModal = () => {
  const [show, setShow] = useState(false);
  const intl = useIntl();
  const navigate = useNavigate();
  const [lastVersion, setLastVersion] = useState({
    version_no: '',
    description: '',
  });
  const [{ doSubmit, isLoading }] = useAxiosGet(getEnv('masterdata') + '/changelog');
  const getVersionList = () => {
    doSubmit({}, getEnv('masterdata') + '/changelog?sort=-id').then((res: any) => {
      setLastVersion(res.data[0]);
    });
  }

  useEffect(() => {
    getVersionList();
  }, []);

  useEffect(() => {
    if (lastVersion.version_no === "") return;
    const currentVersion = localStorage.getItem('version');
    // @ts-ignore
    if (currentVersion !== lastVersion?.version_no) {
      setShow(true);
      localStorage.setItem('version', lastVersion?.version_no);
    }
  }, [lastVersion]);

  return (
    <>
      <Modal show={show} size="xl" centered onHide={() => setShow(false)}>
        <Modal.Header closeButton={true}>
          <Modal.Title>New Version {lastVersion?.version_no}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={"row"}>

            <div className={"col-9"} style={{
              maxHeight: '400px',
              overflow: 'auto'
            }}>
              {parse(lastVersion?.description)}
            </div>
            <div className={"col-3 text-center"}>
              <img src="/media/illustrations/dozzy-1/4.png" alt="update" style={{
                width: '100%'
              }} />
            </div>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className={"btn btn-info btn-sm"} onClick={() => {
            navigate('/release-notes');
            setShow(false);
          }}>
            <i className={"fa fa-history"}></i>
            {intl.formatMessage({ id: "Lihat Semua Versi" })}
          </button>
          <button className={"btn btn-primary btn-sm"} onClick={() => setShow(false)}>
            <i className={"fa fa-thumbs-up"}></i>
            {intl.formatMessage({ id: "OK" })}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default VersioningModal;