import { Field, Form, Formik } from 'formik'
import { useState } from 'react'
import {Col, Modal, Row} from 'react-bootstrap'
import * as Yup from 'yup'
import { MetronicErrorMessage } from '../../../../../../../_metronic/helpers'
import { ApiUrl } from '../../../../../../config/Api'
import axios from 'axios'
import { toast } from 'react-toastify'
import {useAuth} from "../../../../../../modules/auth";


const ListToolbar = () => {
  const validationSchema = Yup.object({})

  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const {currentUser} = useAuth();
  const defaultValue = {
    purchase_order_number: ''
  }
  const [initialValues, setInitialValues] = useState(defaultValue)

  const handleSubmit = async (values: any) => {
    setLoading(true)
    try {
      const response = await axios.post(`${ApiUrl}/api/tender/po`, values)

      if (response?.status === 200) {
        toast.success(response?.status + ': ' + response?.data?.meta?.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
        setInitialValues(defaultValue)
        handleClose()
      }
    } catch (error: any) {
      console.error(error)
      toast.error(error?.data?.meta?.message || 'Terjadi kesalahan', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
    } finally {
      setLoading(false)
    }
  }

  const isBuyer = () => {
    return (currentUser?.has_roles?.includes('staff_buyer_pi')) || (currentUser?.has_roles?.includes('staff_buyer_anper'))
  }

  const handleClose = () => setShow(false)

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({errors, touched, values, setFieldValue}) => (
          <>
            <Modal show={show} onHide={handleClose}>
              <Form>
                <Modal.Header closeButton>
                  <Modal.Title>Input Manual PO SAP</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className='mb-10'>
                    <label className='form-label mb-3 required' htmlFor='note'>
                      Purchase Order Number
                    </label>
                    <Field
                      name='purchase_order_number'
                      className={'form-control'}
                      placeholder={'Purchase Order Number'}
                      type='purchase_order_number'
                    />
                    <MetronicErrorMessage name='purchase_order_number' />
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    onClick={handleClose}
                    className='btn btn-sm btn-danger'
                    type='button'
                    disabled={loading}
                  >
                    <i className="fas fa-times"/>
                    Cancel
                  </button>
                  <button
                    className='btn btn-sm btn-primary'
                    type='submit'
                    disabled={loading}
                  >
                    {loading ? <span className='spinner-border spinner-border-sm me-1'/> : <i className="fas fa-save"/>}
                    Simpan
                  </button>
                </Modal.Footer>
              </Form>
            </Modal>
          </>
        )}
      </Formik>

      <Row>
        <Col>
          <div
            className='d-flex justify-content-end mb-3'
            data-kt-materialrequest-table-toolbar='base'
          >
            {isBuyer() && (
              <button
                className='btn btn-sm btn-primary shadow-sm fw-bold'
                onClick = {() => setShow(true)}
              >
                <i className="fas fa-plus"/>
                Input Manual PO SAP
              </button>
            )}
          </div>
        </Col>
      </Row>
    </>
  )
}

export {ListToolbar}
