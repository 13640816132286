import React, { useEffect, useState } from "react";
import ModalDisposisiApproveX from "./ModalDisposisiApproveX";
import ModalDisposisiRejectX from "./ModalDisposisiRejectX";

interface DisposisiXProps {
  label: string;
  isLoading?: boolean;
  disabled?: boolean;
  showButtonDisposisi?: boolean;
  showButtonTerima?: boolean;
  tableList?: any;
  onSubmitDisposisi?: Function;
  onRejectDisposisi?: Function;
  urlTujuanDisposisi?: string;

  //untuk menampilkan history
  historyUrls?: any;
}

const DisposisiX = ({
                      label,
                      disabled,
                      isLoading,
                      showButtonDisposisi,
                      tableList,
                      showButtonTerima,
                      onSubmitDisposisi,
                      onRejectDisposisi,
                      urlTujuanDisposisi,
                      historyUrls,
}: DisposisiXProps) => {
  const [showModalDisposisi, setShowModal] = useState(false);
  const [showModalTerimaDisposisi, setShowModalTerimaDisposisi] = useState(false);
  const [modalName, setModalName] = useState("");
  const [showModalDisposisiReject, setShowModalDisposisiReject] = useState(false);

  useEffect(() => {
    if(!isLoading){
      setShowModal(false)
      setShowModalDisposisiReject(false)
    }
  },[isLoading])

  return (<>
    <div className={"btn-group"}>
      {showButtonDisposisi === true && (
        <button
          className="btn btn-sm btn-primary shadow-sm fw-bold"
          disabled={disabled || isLoading}
          onClick={() => {
            setModalName("Disposisi Dokumen "+ label)
            setShowModal(true)
          }}
        >
          <i className="fas fa-hand-holding-hand fa-fw me-2"></i>
          Disposisi {label}
        </button>
      )}

      {showButtonTerima === true && (
        <button
          className="btn btn-sm btn-warning shadow-sm fw-bold"
          disabled={disabled || isLoading}
          onClick={() => {
            setModalName("Terima Dokumen "+ label)
            setShowModalTerimaDisposisi(true)
          }}
        >
          <i className="fas fa-hand-holding-hand fa-fw me-2"></i>
          Terima {label}
        </button>
      )}
      {(showButtonDisposisi === true || showButtonTerima === true) && (
        <button
          className="btn btn-sm btn-danger shadow-sm fw-bold"
          disabled={disabled || isLoading}
          onClick={() => setShowModalDisposisiReject(true)}
        >
          <i className="fas fa-redo fa-fw me-2"></i>
          Kembalikan {label}
        </button>
      )}
    </div>

    {/* Disposisi Ke User*/}
    <ModalDisposisiApproveX
      title={modalName}
      show={showModalDisposisi}
      closeModal={setShowModal}
      urlTujuanDisposisi={urlTujuanDisposisi}
      onSubmit={onSubmitDisposisi}
      tableList={tableList}
      isLoading={isLoading}
      isTerimaDokumen={false}
      historyUrls={historyUrls}
    />

    {/* Disposisi Terima PR*/}
    <ModalDisposisiApproveX
      title={'Terima Dokumen '+ label}
      show={showModalTerimaDisposisi}
      closeModal={setShowModalTerimaDisposisi}
      urlTujuanDisposisi={urlTujuanDisposisi}
      onSubmit={onSubmitDisposisi}
      tableList={tableList}
      isLoading={isLoading}
      isTerimaDokumen={true}
      historyUrls={historyUrls}
    />
    <ModalDisposisiRejectX
      title={
        showButtonTerima ? 'Kembalikan Dokumen '+ label : 'Disposisi Reject '+ label
      }
      show={showModalDisposisiReject}
      closeModal={setShowModalDisposisiReject}
      onSubmit={onRejectDisposisi}
      tableList={tableList}
      isLoading={isLoading}
      isTerimaDokumen={showButtonTerima}
    />
  </>);
};

export {
  DisposisiX
};