import React, { useEffect } from "react";
import { ApprovalX, ButtonProsesApproval } from "../../../../../components/shared/ApprovalX";
import { useNavigate } from "react-router-dom";
import { getEnv } from "../../../../../../helpers/procurex";
import { useAxiosGet } from "../../../../../../hooks/useAxios";
import {useMutation, useQuery} from 'react-query'
import axios from 'axios'
import {toast} from 'react-toastify'

interface IProps {
  proposalTender?: any;
  isLoading?: any;
  onCancel?: any;
  onDraft?: any;
  onLanjutPQ?: any;
  onSubmit?: any;
  onPublishPQ?: any;
  onApprove?: any;
  onCancelPQ?: any;
  onBidOpening?: any;
  onSubmitEvaluasi?: any;
  quotation?: any;
  onSaveKonfigurasiNego?: any;
  onCloseNegosiasi?: any;
  onSubmitToAwarding?: any;
  onSubmitReportAwarding?: any;
  onSubmitPengumumanPemenang?: any;
  onSubmitPenunjukkanPemenang?: any;
  onShowDetailApprovalPengumumanPemenang?: any;
}

const ButtonToolbar = ({
                         proposalTender,
                         isLoading,
                         onCancel,
                         onDraft,
                         onLanjutPQ,
                         onSubmit,
                         onPublishPQ,
                         onApprove,
                         onCancelPQ,
                         onBidOpening,
                         onSubmitEvaluasi,
                         onSaveKonfigurasiNego,
                         onCloseNegosiasi,
                         onSubmitToAwarding,
                         onSubmitReportAwarding,
                         onSubmitPengumumanPemenang,
                         onSubmitPenunjukkanPemenang,
                         quotation
                       }: IProps) => {

  const navigation = useNavigate();

  const [isShowApprovalRetender, setIsShowApprovalRetender] = React.useState(false);

  const [retenderList, setRetenderList] = React.useState([] as any);
  const [renderKey, setRenderKey] = React.useState(0);
  const [{ doSubmit: doSubmitGet, isLoading: isLoadingGet }] = useAxiosGet(
    getEnv("tender") + `/tender/re-tender/list?filter[proposal_tender_id]=${proposalTender?.id}`
  )

  const isDraft = () => {

    return proposalTender?.status === "Draft";
  };

  const isEvaluasiSatuTahap = () => {
    return (proposalTender?.status === "Evaluasi Administrasi" && proposalTender?.status_detail === "On Going")
      && proposalTender?.metode_penyampaian === "1 Tahap";
  };

  const isShowPembukaanPenawaran = () => {
    return (proposalTender?.status === "Bid Opening" && proposalTender?.status_detail === "Bid Opening");
  };

  const isOnDURApproval = () => {
    return (proposalTender.status === "DUR" || proposalTender.status === "Praqualification") &&
      proposalTender.is_on_approval;
  };

  const isShowClosePQ = () => {
    return proposalTender?.status === "Pra Qualification"
      && proposalTender?.status_detail === "Quotation";
  };
  const isShowpublishPQ = () => {
    return proposalTender?.status === "Pra Qualification" && proposalTender?.status_detail === "Draft";
  };

  const isShowSubmitPQ = () => {
    return proposalTender?.status === "Pra Qualification" && proposalTender?.status_detail === "Evaluation";
  };

  const isDUR = () => {
    return proposalTender?.status === "DUR";
  };

  const isCancel = () => {
    return proposalTender?.status === "Cancel";
  };

  const isPublished = () => {
    return proposalTender?.status === "Published";
  };

  const isShowSubmitEvaluasi = () => {
    const allowedStatus = [
      "Evaluasi Administrasi",
      "Evaluasi Teknikal",
      "Evaluasi Komersil"
    ];
    return allowedStatus.includes(proposalTender?.status)
      && proposalTender?.status_detail === "On Going";
    // && quotation
  };

  const isOnLaporanPengadaanApproval = () => {
    return proposalTender?.status === "Laporan Pengadaan" && proposalTender?.status_detail === "Submit for Approval";
  }

  const isOnNegotiationApproval = () => {
    return proposalTender?.status === "Negotiation" && proposalTender?.status_detail === "Submit for Approval";
  }

  const isOnPengumumanPemenangApproval = () => {
    return proposalTender?.status === "Pengumuman Pemenang"
      && (proposalTender?.status_detail === "Submit for Approval" || proposalTender?.status_detail === "Masa Sanggah");
  }
  const isOnPenunjukkanPemenangApproval = () => {
    return proposalTender?.status === "Penunjukan Pemenang"
      && (proposalTender?.status_detail === "Submit for Approval");
  }

  const isOnBatalTenderApproval = () => {
    return proposalTender?.status_detail === "Submit for Approval Cancel";
  }

  const isOnReTenderApproval = () => {
    return proposalTender?.status_detail === "Submit for Approval Retender";
  }

  const isOnExtendTender = () => {
    return proposalTender?.status_detail === "Submit for Approval Change Schedule";
  }

  const isShowSaveNego = () => {
    const allowedStatus = [
      "Negotiation"
    ];
    const allowedStatusDetail = [
      "Draft"
    ];
    return allowedStatus.includes(proposalTender?.status) &&
      allowedStatusDetail.includes(proposalTender?.status_detail);
  };

  const isShowCloseNegotiation = () => {
    const allowedStatus = [
      "Negotiation"
    ];
    const allowedStatusDetail = [
      "Proses Negosiasi"
    ];
    return allowedStatus.includes(proposalTender?.status) &&
      allowedStatusDetail.includes(proposalTender?.status_detail) &&
      proposalTender?.negotiation?.type === "SATUAN";
  };

  const isShowSubmitAwarding = () => {
    const allowedStatus = [
      "Laporan Pengadaan"
    ];
    // const allowedStatusDetail = [
    //   'Proses Negosiasi',
    // ];
    return allowedStatus.includes(proposalTender?.status);
    // allowedStatusDetail.includes(proposalTender?.status_detail)
  };

  const isShowSubmitPengumumanPemenang = () => {
    const allowedStatus = [
      "Pengumuman Pemenang"
    ];
    const allowedStatusDetail = [
      'Draft',
      'Rejected'
    ];
    return allowedStatus.includes(proposalTender?.status) &&
    allowedStatusDetail.includes(proposalTender?.status_detail)
  };

  const isShowSubmitEvaluasiTechnical = () => {
    return proposalTender?.status === "Evaluasi Teknikal"
      && proposalTender?.status_detail === "On Going"
      && quotation;
  };

  const isShowPublishEvaluasi = () => {
    return proposalTender?.status === "Evaluasi Teknikal" &&
      proposalTender?.status_detail === "On Going";
  };

  const isShowStatusOnly = () => {
    const allowanceShowStatusOnly = [
      "Penawaran",
      "Negotiation",
      "Laporan Pengadaan",
      "Pra Qualification",
      "Pengumuman Pemenang",
      "Penawaran Teknikal",
    ];

    const alloanceShowStatusDetailOnly = [
      "Awarding",
      "Proses Negosiasi",
      "Quotation",
      "Draft",
      "Published"
    ];

    return allowanceShowStatusOnly.includes(proposalTender?.status) &&
      alloanceShowStatusDetailOnly.includes(proposalTender?.status_detail);
  };

  const isShowAwarding = () => {
    const allowedStatus = [
      "Negotiation"
    ];
    const allowedStatusDetail = [
      "Draft"
    ];
    return allowedStatus.includes(proposalTender?.status) &&
      allowedStatusDetail.includes(proposalTender?.status_detail);
  };

  const isShowSubmitReportAwarding = () => {
    const allowedStatus = [
      "Laporan Pengadaan"
    ];
    const allowedStatusDetail = [
      "Awarding",
      "Rejected"
    ];
    return allowedStatus.includes(proposalTender?.status) &&
      allowedStatusDetail.includes(proposalTender?.status_detail);
  };

  const handleOnShowApprovalRetender = () => {
    setIsShowApprovalRetender(true);
    setRenderKey(renderKey + 1);
  }

  const getRetenderList = () => {
    doSubmitGet({},undefined, undefined).then((res: any) => {
      setRetenderList(res.data);

    })
  }

  // region submit nego -> awarding via approval
  const [isShowApprovalAwarding, setIsShowApprovalAwarding] = React.useState(false);
  const [awardingApprovalCode, setAwardingApprovalCode] = React.useState(false);
  const [awardingApprovalSchema, setAwardingApprovalSchema] = React.useState([]);
  const {refetch: refetchGetApprovalCode, isLoading: isLoadingGetApprovalCodeAwarding} = useQuery({
    queryKey: ["negotiation","approval-code",proposalTender?.uuid],
    refetchOnWindowFocus: false,
    enabled: false,
    queryFn: () => axios.get(getEnv('tender')+`/negotiation/routing-approval-code/${proposalTender?.negotiation?.uuid}`),
    onSuccess: (res)=> {
      setAwardingApprovalCode(res?.data?.data?.code)
      setIsShowApprovalAwarding(true)
      setAwardingApprovalSchema(res?.data?.data?.details)
      setRenderKey(renderKey+1)
    },
    onError: (err) => {

    }
  })
  const {mutate: mutateSubmitAwarding, isLoading: isLoadingSubmitAwarding} = useMutation({
    mutationKey: ["negotiation","approval",proposalTender?.negotiation?.uuid],
    mutationFn: (payload) => axios.post(getEnv("tender")+`/negotiation/${proposalTender?.negotiation?.uuid}`,payload),
    onSuccess: (res)=> {
      toast.success(res?.data?.meta?.message)
      onApprove && onApprove()
    }
  })
  const handleOnAwarding = () => {
    refetchGetApprovalCode().then(r => {})
  }

  const handleOnAwardingSubmit = (data:any) => {
    let payload = {
      _method: 'PUT',
      proposal_tender_id: proposalTender?.uuid,
      process: "Awarding",
      note: "",
      approvers: data.approval.map((item: any) => {
        return {
          step: item.step,
          jabatan: item.jabatan,
          type: item?.type,
          user_id: item.extra_info.user.user_id,
          extra_info: item.extra_info
          }
        })
      }
      // @ts-ignore
    mutateSubmitAwarding(payload)
  }

  useEffect(() => {
    getRetenderList();
  }, []);

  return (
    <>
      <button className={"btn btn-sm btn-primary"} onClick={() => onSubmit(true)} style={{
        visibility: 'hidden'
      }}>
        <i className={"fa fa-check"}></i>
        Visibility hidden agar tetap ada jarak dibagian atas form kanan
      </button>
      {isDraft() && (
        <>
            {/* <button type={"submit"} className={"btn btn-sm btn-warning me-3"} onClick={() => onDraft(true)}
                    disabled={isLoading}>
              {isLoading ? (
                <i className="fa fa-spinner fa-spin"></i>
              ) : (
                <i className={"fa fa-save"}></i>
              )}
              Save Draft
            </button> */}
            {/* {proposalTender.praqualification && (
              <>
                <button type={"submit"} className={"btn btn-sm btn-primary me-3"} onClick={() => onLanjutPQ(
                  "Apakah Anda yakin?",
                  "Anda akan melanjutkan ke tahap Pra Qualification",
                  () => {
                    navigation(`/tender/admin/proposal-tender/detail/${proposalTender.uuid}`);
                  }
                )}
                        disabled={isLoading}>
                  {isLoading ? (
                    <i className="fa fa-spinner fa-spin"></i>
                  ) : (
                    <i className={"fa fa-arrow-right"}></i>
                  )}
                  Lanjut PQ
                </button>
              </>
            )} */}



          {/* {!proposalTender?.praqualification && (
            <button className={"btn btn-sm btn-primary"} onClick={() => onSubmit(true)}>
              <i className={"fa fa-check"}></i>
              Submit
            </button>
          )} */}
        </>
      )}
      {/* {isShowClosePQ() && (
        <>
          {!proposalTender.is_on_approval && (
            <>

              <button type={"button"} disabled={isLoading} className={"btn btn-sm btn-primary me-3"}
                      onClick={() => onPublishPQ(
                        "Apakah Anda Yakin?",
                        "Anda akan menutup PQ ini",
                        () => {
                          navigation(`/tender/admin/proposal-tender/detail/${proposalTender.uuid}`);
                        }
                      )}>
                {isLoading ? (
                  <i className="fa fa-spinner fa-spin"></i>
                ) : (
                  <i className={"fa fa-flag"}></i>
                )}
                Tutup PQ
              </button>

              {proposalTender?.status_detail === "Published" && (
                <button type={"button"} disabled={true} className={"btn btn-sm btn-success me-3"}>
                  <i className={"fa fa-globe"}></i>
                  Published
                </button>
              )}
            </>
          )}

        </>
      )} */}

      {isShowSubmitPQ() && (
        <>
          {!proposalTender.is_on_approval && (
            <>

              <button type={"button"} disabled={isLoading} className={"btn btn-sm btn-primary me-3"}
                      onClick={() => onPublishPQ(
                        "Apakah Anda Yakin?",
                        "Anda akan menutup PQ ini",
                        () => {
                          navigation(`/tender/admin/proposal-tender/detail/${proposalTender.uuid}`);
                        }
                      )}>
                {isLoading ? (
                  <i className="fa fa-spinner fa-spin"></i>
                ) : (
                  <i className={"fa fa-flag"}></i>
                )}
                Submit PQ
              </button>

              {proposalTender?.status_detail === "Published" && (
                <button type={"button"} disabled={true} className={"btn btn-sm btn-success me-3"}>
                  <i className={"fa fa-globe"}></i>
                  Published
                </button>
              )}
            </>
          )}

        </>
      )}

      {isShowpublishPQ() && (
        <>
          {/*<button type={"button"} disabled={isLoading} className={"btn btn-sm btn-danger me-3"}*/}
          {/*        onClick={() => onCancelPQ(*/}
          {/*          "Apakah Anda Yakin?",*/}
          {/*          "Anda akan membatalkan PQ ini",*/}
          {/*          () => {*/}
          {/*            navigation(`/tender/admin/proposal-tender/detail/${proposalTender.uuid}`);*/}
          {/*          }*/}
          {/*        )}>*/}
          {/*  {isLoading ? (*/}
          {/*    <i className="fa fa-spinner fa-spin"></i>*/}
          {/*  ) : (*/}
          {/*    <i className={"fa fa-times"}></i>*/}
          {/*  )}*/}
          {/*  Batalkan PQ*/}
          {/*</button>*/}
          {/*<button*/}
          {/*  className={"btn btn-sm btn-success me-3"}*/}
          {/*  onClick={() => onPublishPQ("Apakah Anda yakin?", "Anda akan mempublish PQ ini", () => {*/}

          {/*  })}*/}
          {/*  disabled={isLoading}*/}
          {/*>*/}
          {/*  <i className={"fa fa-globe"}></i>*/}
          {/*  Publish PQ*/}
          {/*</button>*/}
        </>

      )}
      {isDUR() && (
        <>
          {!proposalTender.is_on_approval && (
            <>
              {/* <button type={"button"} disabled={isLoading} className={"btn btn-sm btn-danger me-3"}
                      onClick={() => onCancel("Apakah Anda Yakin?", "Anda akan membatalkan DUR ini")}>
                {isLoading ? (
                  <i className="fa fa-spinner fa-spin"></i>
                ) : (
                  <i className={"fa fa-times"}></i>
                )}
                Batalkan DUR
              </button> */}

              {/* {proposalTender?.status_detail === "Draft" && (
                <button type={"submit"} className={"btn btn-sm btn-warning me-3"} onClick={() => onDraft(true)}
                        disabled={isLoading}>
                  {isLoading ? (
                    <i className="fa fa-spinner fa-spin"></i>
                  ) : (
                    <i className={"fa fa-save"}></i>
                  )}
                  Save Draft
                </button>
              )} */}

              {/* <button className={"btn btn-sm btn-primary me-3"} onClick={() => onSubmit()} disabled={isLoading}>
                {isLoading ? (
                  <i className="fa fa-spinner fa-spin"></i>
                ) : (
                  <i className={"fa fa-check"}></i>
                )}
                Submit
              </button> */}
            </>
          )}


        </>
      )}
      {isCancel() && (
        <>
          <button type={"button"} disabled={true} className={"btn btn-sm btn-warning me-3"}>
            <i className={"fa fa-times"}></i>
            Proposal Tender Ini Telah Dibatalkan
          </button>
        </>
      )}
      {isPublished() && (
        <>
          <button type={"button"} disabled={true} className={"btn btn-sm btn-warning me-3"}>
            <i className={"fa fa-globe"}></i>
            Proposal Tender Ini Telah Di Publish
          </button>
        </>
      )}

      {isOnDURApproval() && (
        <>
          <ButtonProsesApproval
            isLoading={isLoading}
            onShowDetailApproval={() => {
              onSubmit();
            }}
            source={"TENDER"}
            object_id={proposalTender?.id}
            company_id={proposalTender?.company?.id}
            code={proposalTender?.routing_approval_config_code}
            readOnly={proposalTender?.is_on_approval}
            onSubmit={() => {
              onApprove && onApprove();
            }}
          />
        </>
      )}

      {isOnLaporanPengadaanApproval() && (
        <>
          <ButtonProsesApproval
            isLoading={isLoading}
            onShowDetailApproval={() => {
              onSubmit();
            }}
            source={"TENDER"}
            object_id={proposalTender?.id}
            company_id={proposalTender?.company?.id}
            code={proposalTender?.routing_approval_config_code}
            readOnly={isOnLaporanPengadaanApproval()}
            onSubmit={() => {
              onApprove && onApprove();
            }}
          />
        </>
      )}

      {isOnNegotiationApproval() && (
        <>
          <ButtonProsesApproval
            isLoading={isLoading}
            onShowDetailApproval={() => {
              onSubmit();
            }}
            source={"TENDER"}
            object_id={proposalTender?.id}
            company_id={proposalTender?.company?.id}
            code={proposalTender?.routing_approval_config_code}
            readOnly={isOnNegotiationApproval()}
            onSubmit={() => {
              onApprove && onApprove();
            }}
          />
        </>
      )}

      {isOnPengumumanPemenangApproval() && (
        <>
          <ButtonProsesApproval
            isLoading={isLoading}
            onShowDetailApproval={() => {
              onSubmitPengumumanPemenang();
            }}
            source={"TENDER"}
            object_id={proposalTender?.id}
            company_id={proposalTender?.company?.id}
            code={proposalTender?.routing_approval_config_code}
            readOnly={isOnPengumumanPemenangApproval()}
            onSubmit={() => {
              onApprove && onApprove();
            }}
          />
        </>
      )}

      {isOnPenunjukkanPemenangApproval() && (
        <>
          <ButtonProsesApproval
            isLoading={isLoading}
            onShowDetailApproval={() => {
              onSubmitPenunjukkanPemenang();
            }}
            source={"TENDER"}
            object_id={proposalTender?.id}
            company_id={proposalTender?.company?.id}
            code={proposalTender?.routing_approval_config_code}
            readOnly={isOnPenunjukkanPemenangApproval()}
            onSubmit={() => {
              onApprove && onApprove();
            }}
          />
        </>
      )}

      {isOnBatalTenderApproval() && (
        <>
          <ButtonProsesApproval
            isLoading={isLoading}
            onShowDetailApproval={() => {
              onSubmit();
            }}
            source={"TENDER"}
            object_id={proposalTender?.id}
            company_id={proposalTender?.company?.id}
            code={"tender_cancel"}
            readOnly={isOnBatalTenderApproval()}
            onSubmit={() => {
              onApprove && onApprove();
            }}
          />
        </>
      )}

      {isOnReTenderApproval() && (
        <>

          {retenderList.length > 0 && (
            <>
              <ButtonProsesApproval
                isLoading={isLoading}
                onShowDetailApproval={() => {
                  handleOnShowApprovalRetender()
                }}
                source={"TENDER"}
                object_id={retenderList[0]?.proposal_tender_id}
                company_id={proposalTender?.company?.id}
                code={"tender_retender"}
                readOnly={isOnReTenderApproval()}
                onSubmit={() => {
                  onApprove && onApprove();
                }}
              />
              <ApprovalX
                key={renderKey}
                show={isShowApprovalRetender}
                schema={[]}
                onHide={() => {
                  setIsShowApprovalRetender(false);
                }}
                onSubmit={(data: any) => {
                  // handleOnSubmitApprovalSubmitAwarding(data);
                }}
                isLoading={isLoading}
                source={"TENDER"}
                object_id={retenderList[0]?.proposal_tender_id}
                company_id={proposalTender?.company?.id}
                code={"tender_retender"}
                readOnly={true}
                // readOnly={props.proposalTender?.is_on_approval}
              />
            </>

          )}

        </>
      )}


      {isOnExtendTender() && (
        <>
          <ButtonProsesApproval
            isLoading={isLoading}
            onShowDetailApproval={() => {
              onSubmit();
            }}
            source={"TENDER"}
            object_id={proposalTender?.id}
            company_id={proposalTender?.company?.id}
            code={"tender_change_schedule"}
            readOnly={isOnExtendTender()}
            onSubmit={() => {
              onApprove && onApprove();
            }}
          />
        </>
      )}

      {/* {isShowSubmitReportAwarding() && (
        <>
          <button
            type={"button"}
            disabled={isLoading}
            className={"btn btn-sm btn-primary me-3"}
            onClick={() => onSubmitReportAwarding(
              "Apakah Anda yakin?",
              "Anda akan submit Laporan Pengadaan ini"
            )}
          >
            {isLoading ? (
              <i className="fa fa-spinner fa-spin"></i>
            ) : (
              <i className={"fa fa-paper-plane"}></i>
            )}
            Submit Laporan Pengadaan
          </button>
        </>
      )} */}

      {/* {isShowSubmitPengumumanPemenang() && (
        <>
          <button
            type={"button"}
            disabled={isLoading}
            className={"btn btn-sm btn-primary me-3"}
            onClick={() => onSubmitPengumumanPemenang(
              "Apakah Anda yakin?",
              "Anda akan submit Pengumuman Pemenang ini"
            )}
          >
            {isLoading ? (
              <i className="fa fa-spinner fa-spin"></i>
            ) : (
              <i className={"fa fa-paper-plane"}></i>
            )}
            Submit Pengumuman Pemenang
          </button>
        </>
      )} */}

      {isEvaluasiSatuTahap() && (
        <>
          <button
            type={"button"}
            disabled={isLoading}
            className={"btn btn-sm btn-primary me-3"}
            onClick={() => onBidOpening(
              "Apakah Anda yakin?",
              "Anda akan mempublish Evaluasi Administrasi ini"
            )}
          >
            {isLoading ? (
              <i className="fa fa-spinner fa-spin"></i>
            ) : (
              <i className={"fa fa-paper-plane"}></i>
            )}
            Submit & Publish Evaluasi
          </button>
        </>
      )}



      {/*{isShowSubmitEvaluasi() && (*/}
      {/*  <>*/}
      {/*    <button*/}
      {/*      type={"button"}*/}
      {/*      disabled={isLoading}*/}
      {/*      className={"btn btn-sm btn-primary me-3"}*/}
      {/*      onClick={() => onSubmitEvaluasi(*/}
      {/*        "Apakah Anda yakin?",*/}
      {/*        `Anda akan menutup ${proposalTender?.status} ini`,*/}
      {/*      )}*/}
      {/*    >*/}
      {/*      {isLoading ? (*/}
      {/*        <i className="fa fa-spinner fa-spin"></i>*/}
      {/*      ) : (*/}
      {/*        <i className={"fa fa-paper-plane"}></i>*/}
      {/*      )}*/}
      {/*      Tutup {proposalTender?.status}*/}
      {/*    </button>*/}
      {/*  </>*/}
      {/*)}*/}

      {isShowSaveNego() && (
        <>
          {/*<button*/}
          {/*  type={"button"}*/}
          {/*  disabled={isLoading}*/}
          {/*  className={"btn btn-sm btn-primary me-3"}*/}
          {/*  onClick={()=>onSaveKonfigurasiNego(*/}
          {/*    "Apakah Anda yakin?",*/}
          {/*    "Anda akan menyimpan konfigurasi nego ini",*/}
          {/*    "Draft"*/}
          {/*  )}*/}
          {/*>*/}
          {/*  {isLoading ? (*/}
          {/*    <i className="fa fa-spinner fa-spin"></i>*/}
          {/*  ) : (*/}
          {/*    <i className={"fa fa-save"}></i>*/}
          {/*  )}*/}
          {/*  Simpan Konfigurasi Nego*/}
          {/*</button>*/}
          {/*<button*/}
          {/*  type={"button"}*/}
          {/*  disabled={isLoading}*/}
          {/*  className={"btn btn-sm btn-primary me-3"}*/}
          {/*  onClick={()=>onSaveKonfigurasiNego(*/}
          {/*    "Apakah Anda yakin?",*/}
          {/*    "Anda akan menyimpan konfigurasi nego ini",*/}
          {/*    "Proses NegosiasiPage"*/}
          {/*  )}*/}
          {/*>*/}
          {/*  {isLoading ? (*/}
          {/*    <i className="fa fa-spinner fa-spin"></i>*/}
          {/*  ) : (*/}
          {/*    <i className={"fa fa-paper-plane"}></i>*/}
          {/*  )}*/}
          {/*  Publish Negosiasi*/}
          {/*</button>*/}
        </>
      )}

      {/*{isShowCloseNegotiation() && (*/}
      {/*  <>*/}
      {/*    <button*/}
      {/*      type={"button"}*/}
      {/*      disabled={isLoading}*/}
      {/*      className={"btn btn-sm btn-danger me-3"}*/}
      {/*      onClick={() => onCloseNegosiasi(*/}
      {/*        "Apakah Anda yakin?",*/}
      {/*        "Anda akan menutup negosiasi?",*/}
      {/*        "Draft"*/}
      {/*      )}*/}
      {/*    >*/}
      {/*      {isLoading ? (*/}
      {/*        <i className="fa fa-spinner fa-spin"></i>*/}
      {/*      ) : (*/}
      {/*        <i className={"fa fa-close"}></i>*/}
      {/*      )}*/}
      {/*      Tutup Negosiasi*/}
      {/*    </button>*/}


      {/*  </>*/}
      {/*)}*/}

      {isShowAwarding() && (
        <>
        <button
          type={"button"}
          disabled={isLoading || isLoadingGetApprovalCodeAwarding}
          className={"btn btn-sm btn-primary me-3"}
          onClick={() => handleOnAwarding()}
        >
          {(isLoading || isLoadingGetApprovalCodeAwarding) ? (
            <i className="fa fa-spinner fa-spin"></i>
          ) : (
            <i className={"fa fa-trophy"}></i>
          )}
          Submit Approval Nego
        </button>
        <ApprovalX
        key={renderKey}
        show={isShowApprovalAwarding}
        schema={awardingApprovalSchema}
        onHide={() => {
          setIsShowApprovalAwarding(false);
        }}
        onSubmit={(data: any) => {
          handleOnAwardingSubmit(data)
        }}
        isLoading={isLoading}
        source={"TENDER"}
        object_id={retenderList[0]?.proposal_tender_id}
        company_id={proposalTender?.company?.id}
        code={awardingApprovalCode}
        // readOnly={}
        readOnly={proposalTender?.is_on_approval}
      />
          </>
      )}

      {isShowSubmitAwarding() && (
        <>
          {/*<button*/}
          {/*  type={"button"}*/}
          {/*  disabled={isLoading}*/}
          {/*  className={"btn btn-sm btn-primary me-3"}*/}
          {/*  onClick={()=>onCloseNegosiasi(*/}
          {/*    "Apakah Anda yakin?",*/}
          {/*    "Anda akan melakukan awarding",*/}
          {/*    "Draft"*/}
          {/*  )}*/}
          {/*>*/}
          {/*  {isLoading ? (*/}
          {/*    <i className="fa fa-spinner fa-spin"></i>*/}
          {/*  ) : (*/}
          {/*    <i className={"fa fa-save"}></i>*/}
          {/*  )}*/}
          {/*  Submit Awarding*/}
          {/*</button>*/}
        </>
      )}

      {isShowStatusOnly() && (
        <>
          <button
            type={"button"}
            disabled={true}
            className={"btn btn-sm btn-primary me-3"}
          >
            <i className={"fa fa-info-circle"}></i>
            {proposalTender?.status_detail}
          </button>
        </>
      )}

      {isCancel() && (
        <>
          <button type={"button"} disabled={true} className={"btn btn-sm btn-info me-3"}>
            <i className={"fa fa-info-circle"}></i>
            {proposalTender?.status_detail}
          </button>
        </>
      )}
    </>
  );
};

export default ButtonToolbar;