import { Link, useNavigate } from "react-router-dom";
import { KTSVG } from '../../../../_metronic/helpers'
import { useGetModuleUser } from "../../../../_metronic/partials/layout/header-menus/core/hooks";
import { Button, InputGroup, Modal, Spinner } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { darkModeClass } from "../../../../helpers/utils";

const WidgetListApp = () => {
  const [isLoadingModule, responseDataModule, responseErrorModule, getDataModule] = useGetModuleUser();
  const [isModalAppOpen, setIsModalAppOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [listModule, setListModule] = useState<any[]>(responseDataModule.data || []);
  const [listModuleOriginal, setListModuleOriginal] = useState<any[]>(responseDataModule.data || []);
  const inputRef = useRef(null);
  const intl = useIntl();
  const navigation = useNavigate();

  const shortcut = () => {
    const handleKeyPress = (event:any) => {
      // Mengecek apakah tombol Ctrl, Shift, dan F ditekan
      if ((event.ctrlKey && event.shiftKey && event.key === 'F') || (event.metaKey && event.shiftKey && event.key === 'F')) {
        // Mencegah perilaku default dari kombinasi tombol tersebut
        event.preventDefault();

        // Menetapkan fokus pada elemen input
        if (inputRef.current) {
          //@ts-ignore
          inputRef?.current?.focus();
        }
      }
    };

    // Menambahkan event listener untuk mendengarkan kombinasi tombol
    window.addEventListener('keydown', handleKeyPress);

    // Membersihkan event listener saat komponen tidak lagi digunakan
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }
  const filterModule = (value: string) => {
    if (value === "") {
      setListModule(listModuleOriginal)
    } else {
      const _list = listModuleOriginal.filter((v: any) => v.name.toLowerCase().includes(value.toLowerCase()))
      setListModule(_list)
    }
  }

  useEffect(() => {
    filterModule(search)
    shortcut()
  }, [search]);

  useEffect(() => {
    setListModule(responseDataModule.data || [])
    setListModuleOriginal(responseDataModule.data || [])
  }, [responseDataModule]);


  return (
    <>
      <div className='col-md-4'>
        <div className='card p-0'>
          <div className='card-header border-bottom px-3 d-flex align-items-center'>
            <div className={"form-group w-100"}>
             <InputGroup size={"sm"}>
                <InputGroup.Text className={"border-bottom"} style={{
                  border: "none",
                  backgroundColor: "transparent"
                }}>
                  <i className='bi bi-search'></i>
                </InputGroup.Text>
                <input
                  ref={inputRef}
                  type={"text"}
                  className={`form-control border-bottom ${darkModeClass()}`}
                  style={{
                    border: "none",
                  }}
                  placeholder={intl.formatMessage({id:'Cari Aplikasi'})+" (ctrl+shift+f)"}
                  autoFocus={true}
                  onChange={(e) => setSearch(e.target.value)}
                />
             </InputGroup>
            </div>
          </div>
          {/* end::Header */}

          {/* begin::Body */}
          <div className='card-body pt-5' style={{
            maxHeight: "440px",
            overflowY: "auto"
          }}>
            {/* begin::Item */}
            {isLoadingModule && (
              <div className={"text-center"}><Spinner animation={"border"} /></div>
            )}

            {(listModule && !isLoadingModule) && listModule?.filter((lm:any)=>lm.status !== "INACTIVE").map((v: any) => {
              return (
                <a href={"#"} onClick={(e:any)=>{
                  e.preventDefault();
                  navigation(v?.path);
                }} className='d-flex align-items-center mb-7 border-bottom' key={"module-" + v.id}>
                  <div className='symbol symbol-50px me-5'>
                    <span className='symbol-label bg-light-success'>
                      <KTSVG
                        path='/media/icons/duotune/abstract/abs027.svg'
                        className='svg-icon-2x svg-icon-success'
                      />
                    </span>
                  </div>
                  <div className='d-flex flex-column'>
                    <Link
                      to={v.path}
                      className={`fs-6 fw-bold ${darkModeClass()}`}
                      style={{
                        color: (v.status === 'INACTIVE') ? '#c0c4ce' : '#333'
                      }}
                      onClick={(e:any) => {
                        if (v.status === 'INACTIVE') {
                          e.preventDefault()
                        }
                      }}
                    >
                      {v.name}
                    </Link>
                    <span className={`fw-semibold text-small ${darkModeClass()}`} style={{
                      color: (v.status === 'INACTIVE') ? '#c0c4ce' : '#333',
                      fontSize: "10px"
                    }}>Aplikasi {v.name} Online</span>
                  </div>

                </a>

              )
            })}

            {/* end::Item */}
          </div>
        </div>
      </div>
    </>
  )
}

export default WidgetListApp
