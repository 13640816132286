import {ErrorMessage as BaseErrorMessage, Field, Form, Formik} from "formik";
import {Modal, Spinner} from "react-bootstrap";
import * as Yup from "yup";
import { useAxiosPost } from "../../../../../../../../hooks/useAxios";
import CurrencyInput from "react-currency-input-field";
import { useEffect, useState } from "react";
import { formatNumberToDecimalAuction, getEnv, multiplyNumber, parseNumberToCurrency } from "../../../../../../../../helpers/procurex";
import { toastError } from "../../../../../../../../_metronic/helpers";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { PageAlertX } from "../../../../../../../components/shared/PageAlertX";

type IProps = {
    showModalFreeAuction: boolean
    hideModalFreeAuction: Function
    valuesSesiNego: any
    setValuesSesiNego: Function
    proposalTender: any
}

const RowServiceLineAuction = ({vendorDetail, indexVendorDetail, itemDetail, indexItemDetail, serviceLinesDetail, indexServiceLineDetail, setValuesSesiNegoForPayload, valuesSesiNegoForPayload, isJasa }: any) => {
    const [totalPrice, setTotalPrice] = useState(0)
    const [unitPrice, setUnitPrice] = useState(0)
  
    const calculateTotalPrice = () => {
        const qty = isJasa ? serviceLinesDetail?.quantity : itemDetail?.quantity
        setTotalPrice(multiplyNumber(qty, formatNumberToDecimalAuction(unitPrice, 3)))
    }

    const updatePayload = () => {
        let updatedPayload = {
            ...valuesSesiNegoForPayload,
            ...Object.keys(valuesSesiNegoForPayload).reduce((acc, key) => {
                const isItem = valuesSesiNegoForPayload[key]?.proposal_tender_vendor_id === vendorDetail?.proposal_tender_vendor_id
                if (isJasa && isItem) {
                    acc[key] = {
                        ...valuesSesiNegoForPayload[key],
                        fullItems: valuesSesiNegoForPayload[key].fullItems.map((item, index) => 
                            item?.uuid === itemDetail?.uuid
                                ? { 
                                    ...item, 
                                    serviceLines: item.serviceLines.map((serviceLine, serviceIndex) => 
                                        serviceLine?.uuid === serviceLinesDetail?.uuid 
                                            ? { 
                                                ...serviceLine, 
                                                current_negotiation: { 
                                                    ...serviceLine.current_negotiation, 
                                                    net_price: unitPrice 
                                                } 
                                            }
                                            : serviceLine
                                    )
                                  }
                                : item
                        )
                    }
                } else if (!isJasa && isItem){
                    acc[key] = {
                        ...valuesSesiNegoForPayload[key],
                        fullItems: valuesSesiNegoForPayload[key].fullItems.map(item => 
                            item?.uuid === itemDetail?.uuid
                                ? { ...item, total_current_negotiation: unitPrice }
                                : item
                        )
                    }
                } else {
                    acc[key] = valuesSesiNegoForPayload[key]
                }

                return acc
            }, {})
        }
    
        setValuesSesiNegoForPayload(updatedPayload);
    }

    useEffect(() => {
        calculateTotalPrice()
        updatePayload()
    }, [unitPrice])

    return (
      <>
        {isJasa ? (
            <>
                {(indexServiceLineDetail == 0) &&
                    <tr className='border border-gray-600'>
                        <td rowSpan={itemDetail?.serviceLines?.length + 1} className={'border border-gray-600 text-start px-2'}>{ indexVendorDetail + 1 }</td>
                        <td rowSpan={itemDetail?.serviceLines?.length + 1} className={'border border-gray-600 text-start px-2'}>{ vendorDetail?.vendor?.vendor_no } - { vendorDetail?.vendor?.name }</td>
                    </tr>
                }
                <tr className='border border-gray-600'>
                    <td className={'border border-gray-600 text-start px-2'}>{ itemDetail?.no_pr }</td>
                    <td className={'border border-gray-600 text-start px-2'}>{ serviceLinesDetail?.line_no }</td>
                    <td className={'border border-gray-600 text-start px-2'}>{ serviceLinesDetail?.short_text }</td>
                    <td className={'border border-gray-600 text-start px-2'}>{ serviceLinesDetail?.quantity }</td>
                    <td className={'border border-gray-600 text-start px-2'}>{
                        <CurrencyInput
                            className={'form-control form-control-sm text-end '}
                            groupSeparator='.'
                            decimalSeparator=','
                            decimalsLimit={3}
                            value={unitPrice}
                            onValueChange={(value: any) => {
                                setUnitPrice(value)
                            }}
                        />
                    }</td>
                    <td className={'border border-gray-600 text-start px-2'}>{parseNumberToCurrency(totalPrice, serviceLinesDetail?.currency_id)}</td>
                </tr>
            </>
        ) : (
            <>
                {(indexItemDetail == 0) &&
                    <tr className='border border-gray-600'>
                        <td rowSpan={vendorDetail?.items?.length + 1} className={'border border-gray-600 text-start px-2'}>{ indexVendorDetail + 1 }</td>
                        <td rowSpan={vendorDetail?.items?.length + 1} className={'border border-gray-600 text-start px-2'}>{ vendorDetail?.vendor?.vendor_no } - { vendorDetail?.vendor?.name }</td>
                    </tr>
                }
                <tr className='border border-gray-600'>
                    <td className={'border border-gray-600 text-start px-2'}>{ itemDetail?.no_pr }</td>
                    <td className={'border border-gray-600 text-start px-2'}>{ itemDetail?.line_no }</td>
                    <td className={'border border-gray-600 text-start px-2'}>{ itemDetail?.short_text }</td>
                    <td className={'border border-gray-600 text-start px-2'}>{ itemDetail?.quantity }</td>
                    <td className={'border border-gray-600 text-start px-2'}>{
                        <CurrencyInput
                            className={'form-control form-control-sm text-end '}
                            groupSeparator='.'
                            decimalSeparator=','
                            decimalsLimit={3}
                            value={unitPrice}
                            onValueChange={(value: any) => {
                                setUnitPrice(value)
                            }}
                        />
                    }</td>
                    <td className={'border border-gray-600 text-start px-2'}>{parseNumberToCurrency(totalPrice, itemDetail?.currency_id)}</td>
                </tr>
            </>
        )}
      </>
    )
  }

const FreeAuctionModal = ({ showModalFreeAuction, hideModalFreeAuction, valuesSesiNego, setValuesSesiNego, proposalTender }: IProps) => {
  const [{doSubmit, isLoading}] = useAxiosPost(getEnv('tender')+`/negotiation/sumbit-free-auction-fromTender/${proposalTender?.negotiation?.uuid}`);
  const [valuesSesiNegoForPayload, setValuesSesiNegoForPayload] = useState<any>([]);
  const [alertMessage, setAlertMessage] = useState<any>(null);
  useEffect(() => {
    setAlertMessage(`
      Menginputkan nilai dari hasil Free Auction hanya bisa dilakukan 1x.
      Pastikan untuk checklist Vendor dan Item dengan benar serta mengisi detail nilai hasil Auction dengan benar.
    `)
  })

  const initialValues = {
    no_auction: ""
  }

  const validationSchema = Yup.object({no_auction: Yup.string().required('No Auction is required')})

  const isValidateFreeAuction = (data) => {
        let validate = false
        let priceItemChangeCount = 0
        let priceServiceChangeCount = 0
        let totalItemsCount = 0
        let totalServiceLinesCount = 0

        Object.values(data).forEach((vendor: any) => {
            vendor?.fullItems.forEach((item) => {
                if (item?.total_current_negotiation > 0) {
                    priceItemChangeCount += 1
                }
                item?.serviceLines.forEach((serviceLine) => {
                    if (serviceLine?.current_negotiation?.net_price > 0) {
                        priceServiceChangeCount += 1
                    }
                })
                totalServiceLinesCount += item?.serviceLines?.length
            })
            totalItemsCount += vendor?.items?.length
        })
        
        if (priceItemChangeCount === totalItemsCount
            || priceServiceChangeCount === totalServiceLinesCount
        ) validate = true

        return validate
    }

  const onSubmit = (values:any) => {
    Swal.fire({
        title: "Apakah anda yakin?",
        text: "Anda akan submit free auction.",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Ya, lanjutkan",
        cancelButtonText: "Tidak"
    }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            const validateFreeAuction =  isValidateFreeAuction(valuesSesiNegoForPayload)
    
            if (!validateFreeAuction) {
                return toastError('Harga detail auction is required')
            }

            const payload = {
                ...values,
                valuesSesiNegoForPayloads: valuesSesiNegoForPayload
            }
            
            doSubmit(payload,undefined,toast).then((res:any)=>{
              hideModalFreeAuction()
            })
        }
    })
  }

    const ErrorMessage = (props: any) => (
        <BaseErrorMessage
            {...props}
            render={(msg) => (
            <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                <span role='alert'>{msg}</span>
                </div>
            </div>
            )}
        />
    )

    useEffect(() => {
        setValuesSesiNegoForPayload(valuesSesiNego)
    }, [valuesSesiNego])

  return (
    <>
      <Modal show={showModalFreeAuction} size={"xl"}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {() => (
            <Form>
              <>
                <Modal.Header>
                  <Modal.Title>Free Auction</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                <PageAlertX
                    title={"Petunjuk Mengisi Nilai Hasil Free Auction"}
                    variant={"warning"}
                    message={alertMessage}
                />
                <div className={'row'}>
                    <div className={'col-md-6'}>
                        <div className='mb-5'>
                            <label className="form-label mb-3 required" htmlFor="note">
                                No Auction
                            </label>
                            <Field
                                name='no_auction'
                                type='text'
                                className='form-control'
                                placeholder='No Auction'
                            />
                            <ErrorMessage name='no_auction' />
                        </div> 
                    </div> 
                </div>
                <hr className='mb-10' />
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <label className="form-label mb-3 required" htmlFor="note">
                            Detail Auction
                        </label>
                        <div className='table-responsive'>
                            <table className={'table'}>
                                <thead className='bg-gray-200 fw-bold'>
                                    <tr>
                                    <th className={'border border-gray-600 text-center px-2'} style={{width: '50px'}}>
                                        No
                                    </th>
                                    <th className={'border border-gray-600 text-start px-2'}>Vendor</th>
                                    <th className={'border border-gray-600 text-start px-2'}>No PR</th>
                                    <th className={'border border-gray-600 text-start px-2'}>Item PR</th>
                                    <th className={'border border-gray-600 text-start px-2'}>Deskripsi</th>
                                    <th className={'border border-gray-600 text-start px-2'}>Qty</th>
                                    <th className={'border border-gray-600 text-start px-2'}>Harga</th>
                                    <th className={'border border-gray-600 text-start px-2'}>Sub Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {valuesSesiNego?.map((vendorDetail: any, indexVendorDetail: number) => {
                                        return vendorDetail?.fullItems?.map((itemDetail: any, indexItemDetail: number) => {
                                            if (itemDetail?.serviceLines?.length > 0) {
                                                return itemDetail?.serviceLines?.map((serviceLinesDetail: any, indexServiceLineDetail: number) => {
                                                    return (
                                                        <RowServiceLineAuction
                                                            key={`service-line-${indexVendorDetail}-${indexItemDetail}-${indexServiceLineDetail}`}
                                                            vendorDetail={vendorDetail}
                                                            indexVendorDetail={indexVendorDetail}
                                                            itemDetail={itemDetail}
                                                            indexItemDetail={indexItemDetail}
                                                            serviceLinesDetail={serviceLinesDetail}
                                                            indexServiceLineDetail={indexServiceLineDetail}
                                                            setValuesSesiNegoForPayload={setValuesSesiNegoForPayload}
                                                            valuesSesiNegoForPayload={valuesSesiNegoForPayload}
                                                            isJasa={itemDetail?.serviceLines?.length > 0}
                                                        />
                                                    )
                                                })
                                            } else {
                                                return (
                                                    <RowServiceLineAuction
                                                        key={`line-item-${indexVendorDetail}-${indexItemDetail}`}
                                                        vendorDetail={vendorDetail}
                                                        indexVendorDetail={indexVendorDetail}
                                                        itemDetail={itemDetail}
                                                        indexItemDetail={indexItemDetail}
                                                        serviceLinesDetail={null}
                                                        indexServiceLineDetail={null}
                                                        setValuesSesiNegoForPayload={setValuesSesiNegoForPayload}
                                                        valuesSesiNegoForPayload={valuesSesiNegoForPayload}
                                                        isJasa={itemDetail?.serviceLines?.length > 0}
                                                    />
                                                )
                                            }
                                        })
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                </Modal.Body>

                <Modal.Footer>
                    <button className={"btn btn-danger"} type={"button"} onClick={()=> {
                        setValuesSesiNego([])
                        hideModalFreeAuction()
                    }}>
                        <i className={"fa fa-times"}></i>
                        Tutup
                    </button>
                    <button className={"btn btn-primary"} type={"submit"} disabled={isLoading}>
                        {isLoading ? <Spinner animation={'border'} size={"sm"} className={'me-2'} style={{color: 'white'}} /> : <i className={"fa fa-save"}/>}
                        Submit
                    </button>
                </Modal.Footer>
              </>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default FreeAuctionModal;