import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { FC, lazy, Suspense } from 'react'
import { WithChildren } from '../../_metronic/helpers'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import TopBarProgress from 'react-topbar-progress-indicator'
import PurchasingRequisitionPage from "../modules/tendering/admin/purchasing-requisition/PurchasingRequisitionPage";
import OverduePage from '../modules/padi/overdue/OverduePage'
import { PageLink, PageTitle } from '../../_metronic/layout/core'


const masterData = {
  title: 'Dashboard',
  path: 'padi',
  isSeparator: false,
  isActive: false,
}
const separator = {
  title: '',
  path: '',
  isSeparator: true,
  isActive: false,
}
const breadcrumbs: Array<PageLink> = [masterData, separator]
const PadiRoutes = () => {
  const DashboardPage = lazy(() => import('../modules/padi/dashboard/PadiDashboard'));
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route index element={<Navigate to='/padi/dashboard/umkm' />} />
      </Route>
      <Route element={<Outlet />}>
        <Route path={"/dashboard/umkm"} element={
          <>
            <PageTitle breadcrumbs={breadcrumbs}>Padi UMKM</PageTitle>
            <DashboardPage />
          </>
        }></Route>
        <Route path={"/dashboard/overdue"} element={
          <>
            <PageTitle breadcrumbs={breadcrumbs}>Overdue</PageTitle>
            <OverduePage />
          </>
        }></Route>
      </Route>

    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export default PadiRoutes
