import { useContext, useEffect, useState } from "react";
// import TableXWrpper from "../../../../../components/shared/TableXWrapper";
import TableX, {IColumns} from '../../../../../components/shared/TableX'
import { Button, Modal, Spinner } from "react-bootstrap";
import { KTCard, KTCardBody } from "../../../../../../_metronic/helpers";
import { useParams } from "react-router-dom";
import {useGetTableList} from '../../../../../components/shared/core/hook';
import SwalX from "../../../../../components/shared/SwalX";
import { toast } from "react-toastify";
import axios from "axios";
import moment from 'moment';
import { AuctionContext } from "../../../../../routing/AuctionRoutes";

const TabulasiAuction = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const params = useParams();
  const [key, setKey] = useState(0)
  const idParam = params['*'];
  const [isConfirmBanOpen, setIsConfirmBanOpen] = useState(false)
  const [isConfirmUnfreezeOpen, setIsConfirmUnfreezeOpen] = useState(false)
  const [isLoadingBan, setIsLoadingBan] = useState(false)
  const [isLoadingUnfreeze, setIsLoadingUnfreeze] = useState(false)

  const [paIsLoading, paResponseData, paResponseError, paGetData] = useGetTableList(
    `${API_URL}/api/auction/auction/${idParam}`, undefined
  );

  const [paId, setPaId] = useState();

  const { auctionDetail } = useContext(AuctionContext)
  
  const onBanPeserta = async () => {
    setIsLoadingBan(true)
    try {
      const response: any = await axios.put(`${API_URL}/api/auction/peserta/${idParam}/banned/1`)
      if (response.status === 200) {
        setKey(key + 1)
        setIsConfirmBanOpen(false)
        toast.success(response?.data?.meta?.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      }
    } catch (error) {
    } finally {
      setIsLoadingBan(false)
    }
  }

  const onUnfreezePeserta = async () => {
    setIsLoadingUnfreeze(true)
    try {
      const response: any = await axios.put(`${API_URL}/api/auction/peserta/${idParam}/freeze/0`)

      if (response.status === 200) {
        setKey(key + 1)
        setIsConfirmBanOpen(false)
        toast.success(response?.data?.meta?.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      }
    } catch (error) {
      setIsLoadingUnfreeze(false)
    } finally {
      setIsLoadingUnfreeze(false)
    }
  }

  const [paketData, setPaketData]:any[] = useState([]);
  const [groupPaketData, setGroupPaketData]:any[] = useState([]);
  
  const fetchTaData = async () => {
    const response: any = await axios.get(`${API_URL}/api/auction/auction/tabulasi/${idParam}`);
    let respData = response.data.data;
    let data:any[] = [];
    for(let i=0;i<respData.length;i++){
      data.push(respData[i]);
    }
    setTaTrfData(data);
    
    let newData:any[] = [];
    let groupPaketData:any[] = [];
    for(let i=0;i<respData.length;i++) {
      let row = {
        date: '',
        vendor: '',
        nama: '',
        currency: '',
        kuantitas: '',
        unit_price_hps: 0,
        unit_price: 0,
        total_price: 0,
        total_paket: 0,
        ranking: 0,
        group_ref: -1
      };
      for(let j=0;j<respData[i].bid.length;j++)
      {
        row.date = respData[i].bid[j].date;
        row.vendor = respData[i].bid[j].vendor;
        row.nama = respData[i].nama;
        row.currency = respData[i].currency;
        row.kuantitas = respData[i].bid[j].kuantitas;
        row.unit_price_hps = respData[i].unit_price;
        row.unit_price = respData[i].bid[j].unit_price;
        row.total_price = respData[i].bid[j].total_price;
        row.total_paket = 0;
        row.ranking = respData[i].bid[j].ranking;
        // console.log(row);
        newData.push(row);
      }
    }
    // let isNameChange = false;
    let _totalPaket = 0;
    let _name = "";
    let _currency = "";
    let _date = "";
    for(let i=0;i<newData.length;i++) {
      _name = newData[i].nama;
      _currency = newData[i].currency;
      _date = moment(newData[i].date).format('D MMMM YYYY, hh:mm:ss');
      _totalPaket+=newData[i].total_price;
      if((i+1)!=newData.length)
      {
        if(newData[i+1].nama!=_name) {
          groupPaketData.push({nama: _name, currency: _currency, date: _date, total_paket: _totalPaket});
          _totalPaket=0;
        }
      } else {
        groupPaketData.push({nama: _name, currency: _currency, date: _date, total_paket: _totalPaket});
      }
    }
    for(let i=0;i<newData.length;i++) for(let j=0;j<groupPaketData.length;j++) if(newData[i].nama==groupPaketData[j].nama) {
      newData[i].total_paket = groupPaketData[j].total_paket;
      newData[i].group_ref = j;
    }
    // console.log(newData);
    console.log(groupPaketData);
    let rowGroups:any[] = [];
    let rowGroup:any[] = [];
    let _groupIdx = -1;
    for(let i=0;i<newData.length;i++)
    {
      _groupIdx = newData[i].group_ref;
      rowGroup.push(newData[i]);
      if((i+1)!=newData.length)
      {
        if(newData[i+1].group_ref!=_groupIdx)
        {
          rowGroups.push(rowGroup);
          rowGroup = [];
        }
      } else {
        rowGroups.push(rowGroup);
      }
    }
    console.log(rowGroups);
    setPaketData(rowGroups);
    
    setGroupPaketData(groupPaketData);
  }
  
  useEffect(() => {
    paGetData();
    fetchTaData();
    //console.log(taTrfData);
  }, []);

  const [taTrfData, setTaTrfData]:any[] = useState([]);
  
  //const [dataArray, setDataArray] = useState([]);

  // setTaTrfData(taResponseData.data);

  // if(taTrfData.length==0) {
  //   let taData = taResponseData.data;
  //   let taDataTmp:any = [];
  //   if(taData)
  //   {
  //     for(let i=0;i<taData.length;i++)
  //     {
  //       // console.log(i);
  //       //console.log(taData[i]);
  //       //const tmp = taData[i];
  //       //delete tmp.bid;
  //       taDataTmp.push(taData[i]);
  //       if(taData[i].bid)
  //       {
  //         // console.log('test');
  //         if(taData[i].bid.length > 0)
  //         {
  //           taDataTmp.push({ref_idx: i});
  //         }
  //       }
  //       //delete taData[i].bid;
  //     }
  //     setTaTrfData(taDataTmp);
  //     // console.log(taDataTmp);
  //   }
  //   //console.log(taDataTmp);
  // } else {
  //   console.log(taTrfData);
  // }

  const paColumns = [
    { Header: "Nomor Peserta", accessor: "vendor.vendor_no", className: "text-center" },
    { Header: "Nama Peserta", accessor: "vendor.name", className: "text-center" },
    { Header: "Status", accessor: "status", className: "text-center" },
    {
      Header: "Action", accessor: "", className: "text-center",
      Cell: (props: any) => (
        <>
          <div className='d-flex w-100 justify-content-center'>
            <Button onClick={() => {
              setPaId(props?.cell?.row?.original?.id)
              setIsConfirmBanOpen(true)
            }} className='btn btn-danger btn-sm fw-bold'
              style={{ marginRight: 5 }}>
              BAN
            </Button>
            {props?.cell?.row?.original?.freeze && (
              <Button
                onClick={() => {
                  setPaId(props?.cell?.row?.original?.id)
                  setIsConfirmUnfreezeOpen(true)
                }}
                style={{ marginRight: 5 }}
                className='btn btn-sm fw-bold'
              >
                UNFREEZE
              </Button>
            )}
            <Button onClick={() => {
              setPaId(props?.cell?.row?.original?.id);
              // setIsConfirmBanOpen(true)
            }} className='btn btn-light btn-sm fw-bold'
              style={{ marginRight: 5 }}>
              History
            </Button>
          </div>
        </>
      ),
    },
  ];

  const columnHeaderStyle:any = {
    "boxSizing": "border-box",
    "width": "180px",
    "position": "relative",
    "flex": "180 0 auto",
    "minWidth": "80px",
    "cursor": "pointer"
  };

  const cellHeader = (title:string, colspan:number=1) => {
    return (<th colSpan={colspan} className={'text-center text-muted fw-bolder fs-7 text-uppercase gs-0'} role="columnheader" title="Toggle SortBy" style={columnHeaderStyle}>{title}
      <div draggable="false" role="separator" className="resizer" style={{cursor: "col-resize"}}></div>
      <span></span>
    </th>)
  };

  const cell = (content:string, colspan:number=1, rowspan:number=1) => {
    return (<td rowSpan={rowspan} colSpan={colspan} className={'text-center'}><span>{content}</span></td>)
  };

  const headerRow = (cells:any[], style:any={}) => {
    return (
      <>
      <tr style={style}>
        {cells.map((elm:any, index:any) => {
          return cellHeader(elm.content, elm.colspan)
        })}
      </tr>
      </>
    )
};

  const row = (cells:any[], style:any={}) => {
      return (
        <>
        <tr style={style}>
          {cells.map((elm:any, index:any) => {
            return (elm) ? cell(elm.content, elm.colspan, elm.rowspan) : (<></>)
          })}
        </tr>
        </>
      )
  };

  return (
    <>
      <SwalX
        title={'Confirmation UNFREEZE'}
        message={'Are you sure?'}
        show={isConfirmUnfreezeOpen}
        loading={isLoadingUnfreeze}
        onHide={() => setIsConfirmUnfreezeOpen(false)}
        onSubmit={() => onUnfreezePeserta()}
      />
      <SwalX
        title={'Confirmation BAN'}
        message={'Are you sure?'}
        show={isConfirmBanOpen}
        loading={isLoadingBan}
        onHide={() => setIsConfirmBanOpen(false)}
        onSubmit={() => onBanPeserta()}
      />
      <div className={'mt-4'} style={{ marginRight: 55 }}>
      </div>
      <KTCard>
        <KTCardBody className="py-4">
          <label className="form-label mb-3">Peserta Auction</label>
          <TableX
            data={paResponseData.data ? paResponseData.data.peserta ?? paResponseData.data : []}
            meta={{
              perPage: paResponseData?.meta?.per_page || 25,
              currentPage: paResponseData?.meta?.current_page,
              total: paResponseData?.meta?.total,
            }}
            columns={paColumns}
            isLoading={paIsLoading}
            onRefresh={(state: any, perPage: number, currentPage: number, serialize: string) => {
              paGetData(perPage, currentPage, serialize)
            }}
            hideSearch={true}
          />
        </KTCardBody>
        <KTCardBody className="py-4">
          <label className="form-label mb-3">Tabulasi Auction</label>
          <div className={'table-responsive w-100 mt-4'} style={{paddingLeft: "25px", paddingRight: "25px"}}>
          {
            (auctionDetail.metode_evaluasi==="Itemize") ? (
                  <table className={"table table-hover table-rounded border gs-7"}>
                    <tbody>
                      {
                        taTrfData.map((elm:any, index:any) => {
                        return (
                          <>
                          {headerRow(
                            [
                              {content: "No"},
                              {content: "Description"},
                              {content: "Currency"},
                              {content: "Quantity"},
                              {content: "Unit Price"},
                              {content: "Total Price"},
                              {content: ""},
                              {content: "Action"},
                            ], {boxShadow: '0px 4px 3px rgba(99, 93, 93, 0.25)'}
                          )}
                          {row([
                              {content: (index+1)},
                              {content: elm.nama},
                              {content: elm.currency},
                              {content: new Intl.NumberFormat("id-ID", {currency: "IDR"}).format(elm.kuantitas)},
                              {content: new Intl.NumberFormat("id-ID", {currency: "IDR"}).format(elm.unit_price)+",00"},
                              {content: new Intl.NumberFormat("id-ID", {currency: "IDR"}).format(elm.total_price)+",00"},
                              {content: ""},
                              {content: ""}
                            ])
                          }
                          {
                            (elm.bid.length > 0) ? (
                              (
                              <>
                                {headerRow(
                                  [
                                    {content: "Submit Data", colspan: 2},
                                    {content: "Vendor"},
                                    {content: "Quanitity"},
                                    {content: "Unit Price"},
                                    {content: "Total Price"},
                                    {content: "Ranking"},
                                  ], {boxShadow: '0px 4px 3px rgba(99, 93, 93, 0.25)'}
                                )}
                              </>
                              )
                            ) : (<></>)
                          }
                          {
                            (elm.bid.length > 0) ? (
                              elm.bid.map((elm:any, index:any) => {
                              return (
                              <>
                                {row([
                                    {content: moment(elm.date).format('D MMMM YYYY, h:mm:ss'), colspan: 2},
                                    {content: elm.vendor},
                                    {content: new Intl.NumberFormat("id-ID", {currency: "IDR"}).format(elm.kuantitas)},
                                    {content: new Intl.NumberFormat("id-ID", {currency: "IDR"}).format(elm.unit_price)+",00"},
                                    {content: new Intl.NumberFormat("id-ID", {currency: "IDR"}).format(elm.total_price)+",00"},
                                    {content: elm.ranking},
                                    {content: ""}
                                  ])
                                }
                              </>
                              )})
                            ) : (<></>)
                          }
                          </>
                        )
                        })
                      }
                    </tbody>
                  </table>
            ) : (
              <table className={"table table-hover table-rounded border gs-7"}>
                <tbody>
                  {
                    groupPaketData.map((elm:any, index:any) => {
                    return (
                      <>
                      {headerRow(
                        [
                          {content: "No"},
                          {content: "Submit Date"},
                          {content: "Rekanan"},
                          {content: "Description"},
                          {content: "Currency"},
                          {content: "Quantity"},
                          {content: "Unit Price HPS"},
                          {content: "Unit Price"},
                          {content: "Total Price"},
                          {content: "Total Paket"},
                          {content: "Ranking"},
                          {content: "Action"},
                        ], {boxShadow: '0px 4px 3px rgba(99, 93, 93, 0.25)'}
                      )}
                      {
                        paketData[index].map((elm:any, idx:any) => {
                        return (
                          <>
                          {row([
                              (paketData[index].length > 1 && ((idx+1) % paketData[index].length)==1) ? 
                                {content: (index+1), rowspan: paketData[index].length} : 
                                  (paketData[index].length == 1) ? {content: (index+1)} : null,
                              (paketData[index].length > 1 && ((idx+1) % paketData[index].length)==1) ? 
                              {content: elm.date, rowspan: paketData[index].length} : 
                                (paketData[index].length == 1) ? {content: elm.date} : null,
                              (paketData[index].length > 1 && ((idx+1) % paketData[index].length)==1) ? 
                              {content: elm.vendor, rowspan: paketData[index].length} : 
                                (paketData[index].length == 1) ? {content: elm.vendor} : null,
                              {content: elm.nama},
                              (paketData[index].length > 1 && ((idx+1) % paketData[index].length)==1) ? 
                              {content: elm.currency, rowspan: paketData[index].length} : 
                                (paketData[index].length == 1) ? {content: elm.currency} : null,
                              {content: new Intl.NumberFormat("id-ID", {currency: "IDR"}).format(elm.kuantitas)},
                              {content: new Intl.NumberFormat("id-ID", {currency: "IDR"}).format(elm.unit_price_hps)+",00"},
                              {content: new Intl.NumberFormat("id-ID", {currency: "IDR"}).format(elm.unit_price)+",00"},
                              {content: new Intl.NumberFormat("id-ID", {currency: "IDR"}).format(elm.total_price)+",00"},
                              (paketData[index].length > 1 && ((idx+1) % paketData[index].length)==1) ? 
                              {content: new Intl.NumberFormat("id-ID", {currency: "IDR"}).format(elm.total_paket)+",00", rowspan: paketData[index].length} : 
                                (paketData[index].length == 1) ? {content: new Intl.NumberFormat("id-ID", {currency: "IDR"}).format(elm.total_paket)+",00"} : null,
                                (paketData[index].length > 1 && ((idx+1) % paketData[index].length)==1) ? 
                                {content: elm.ranking, rowspan: paketData[index].length} : 
                                  (paketData[index].length == 1) ? {content: elm.ranking} : null,
                              {content: ""}
                            ])
                          }
                          </>
                        )})
                      }
                      </>
                    )})
                  }
                </tbody>
              </table>
            )
          }
          </div>
        </KTCardBody>
        <KTCardBody className="py-4">
          <label className="form-label mb-3">Ranking</label>
          <div className={'table-responsive w-100 mt-4'} style={{paddingLeft: "25px", paddingRight: "25px"}}>
            <table className={"table table-hover table-rounded border gs-7"}>
              <tbody>
                {
                  taTrfData.map((elm:any, index:any) => {
                  return (
                    <>
                    {headerRow(
                      [
                        {content: "No"},
                        {content: "Description"},
                        {content: "Currency"},
                        {content: "Quantity"},
                        {content: "Unit Price"},
                        {content: "Total Price"},
                      ], {boxShadow: '0px 4px 3px rgba(99, 93, 93, 0.25)'}
                    )}
                    {row([
                        {content: (index+1)},
                        {content: elm.nama},
                        {content: elm.currency},
                        {content: new Intl.NumberFormat("id-ID", {currency: "IDR"}).format(elm.kuantitas)},
                        {content: new Intl.NumberFormat("id-ID", {currency: "IDR"}).format(elm.unit_price)+",00"},
                        {content: new Intl.NumberFormat("id-ID", {currency: "IDR"}).format(elm.total_price)+",00"},
                      ])
                    }
                    {
                      (elm.bid.length > 0) ? (
                        <>
                          {headerRow(
                            [
                              {content: "Ranking", colspan: 2},
                              {content: "Kode Vendor"},
                              {content: "Quantity"},
                              {content: "Harga Satuan"},
                              {content: "Total Price"},
                            ], {boxShadow: '0px 4px 3px rgba(99, 93, 93, 0.25)'}
                          )}
                        </>
                      ) : (<></>)
                    }
                    {
                      (elm.bid.length > 0) ? (
                        elm.bid.map((elm:any, index:any) => {
                        return (
                        <>
                          {row([
                              {content: elm.ranking, colspan: 2},
                              {content: elm.vendor},
                              {content: new Intl.NumberFormat("id-ID", {currency: "IDR"}).format(elm.kuantitas)},
                              {content: new Intl.NumberFormat("id-ID", {currency: "IDR"}).format(elm.unit_price)+",00"},
                              {content: new Intl.NumberFormat("id-ID", {currency: "IDR"}).format(elm.total_price)+",00"}
                            ])
                          }
                        </>
                        )})
                      ) : (<></>)
                    }
                    </>
                  )
                  })
                }
              </tbody>
            </table>
          </div>
        </KTCardBody>
      </KTCard>
    </>
  );
};

export { TabulasiAuction };
