import React, { useState, useEffect } from 'react';
import ApexCharts from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import axios, { AxiosResponse } from 'axios';
import { ApiUrl } from '../../../../config/Api';
import { getEnv } from "../../../../../helpers/procurex";
import { useAuth } from "../../../../../app/modules/auth";

type IProps = {
    DataActiveCompany: any,
    DataDateSelected: any,
    TypeSelectedChart: any
}

const options2: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'long',
    year: 'numeric'
};
const formattedDate = new Date().toLocaleDateString('en-GB', options2)
    .replace(/(\d+) ([a-zA-Z]+) (\d+)/, "$1-$2-$3");

const ApexChart: React.FC<IProps> = (props) => {
    const [itemsMainChart, setItemsMainChart] = useState<any>([]);
    const [itemLoading, setItemLoading] = useState(false)

    const { currentUser } = useAuth();
    let company_chart = currentUser.company_code;
    let company_chart_plant = currentUser.company_code.charAt(0);

    const colors = {
        primary: "#6571ff",
        secondary: "#7987a1",
        success: "#05a34a",
        info: "#66d1d1",
        warning: "#fbbc06",
        danger: "#ff3366",
        light: "#e9ecef",
        dark: "#060c17",
        muted: "#7987a1",
        gridBorder: "rgba(77, 138, 240, .15)",
        bodyColor: "#000",
        cardBg: "#fff",
        // stock: "#fce205",
        stock: "black",
        stock2: "#277da1",
        stock3: "#9fc5e8",
        stock4: "#483087",
        stock5: "#795DBF",
        konsumsi: "#7ca868",
        mutasi: "#f3722c",
        produksi: "#0000FF",
        rencana_pembelian: "#A4A4A8",
        proses_pengadaan: "#00FF00",
        kedatangan: "#B624FF",
        safety_stock: "#F42C10",
    };

    let dataDateSeries: any = [];

    const [state, setState] = useState<any>({
        series: [
        ],
        options: {
            chart: {
                type: "line",
                height: "400",
                parentHeightOffset: 0,
                foreColor: colors.bodyColor,
                background: colors.cardBg,
                toolbar: {
                    show: true,
                },
                stacked: false,
            },
            theme: {
                mode: "light",
            },
            tooltip: {
                enabled: true,
                style: {
                    opacity: 0.8
                },
                theme: "light",
                x: {
                    show: true,
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: 3,
                curve: 'smooth',
            },
            title: {
                align: 'left'
            },
            subtitle: {
                align: 'left'
            },
            colors: [
                colors.kedatangan,
                colors.proses_pengadaan,
                colors.rencana_pembelian,
                colors.mutasi,
                colors.konsumsi,
                colors.produksi,
                colors.safety_stock,
                colors.stock,
                colors.stock2,
                colors.stock3,
                colors.stock4,
                colors.stock5,
            ],
            fill: {
                type: 'solid' //make color chart fill not gradient
            },
            grid: {
                padding: {
                    bottom: -4,
                },
                borderColor: colors.gridBorder,
                xaxis: {
                    lines: {
                        show: true,
                    },
                },
            },
            xaxis: {
                type: 'datetime',
                labels: {
                    format: "dd MMM yyyy",
                },
                categories: dataDateSeries,
                tooltip: {
                    enabled: false,
                },
                lines: {
                    show: true,
                },
                axisBorder: {
                    color: colors.gridBorder,
                },
                axisTicks: {
                    show: true,
                    borderType: 'solid',
                    color: colors.bodyColor,
                },
                crosshairs: {
                    stroke: {
                        color: colors.bodyColor,
                    },
                },
            },
            yaxis: {
                labels: {
                    formatter: function (val: any) {
                        if (typeof val === 'number') {
                            return val.toLocaleString("id-ID");
                        } else {
                            return '';
                        }
                    }
                },
                opposite: false,
                title: {
                    text: "Stock (ton)",
                    style: {
                        size: 9,
                        color: colors.muted,
                    },
                },
                tickAmount: 10,
                type: 'numeric',
                min: undefined,
                max: undefined,
                // tooltip: {
                //     enabled: true,
                // },
                crosshairs: {
                    stroke: {
                        color: colors.secondary,
                    },
                },
            },
            markers: {
                size: 0,
            },
            legend: {
                horizontalAlign: 'center',
                showForSingleSeries: true,
                showForNullSeries: true,
                showForZeroSeries: true,
            },
            annotations: {
                xaxis: [
                    {
                        x: props.DataDateSelected ? new Date(props.DataDateSelected).getTime() : new Date().getTime(),
                        width: '250%',
                        borderColor: "#2A08B6",
                        label: {
                            borderColor: "#2A08B6",
                            style: {
                                color: "#fff",
                                background: "#2A08B6"
                            },
                            orientation: "horizontal",
                            text: "Penunjuk Hari Ini"
                        }
                    }
                ]
            }
        }
    });

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        setState(prevState => ({
            ...prevState,
            options: {
                ...prevState.options,
                annotations: {
                    xaxis: [
                        {
                            x: props.DataDateSelected ? new Date(props.DataDateSelected).getTime() : new Date().getTime(),
                            width: '250%',
                            borderColor: "#2A08B6",
                            label: {
                                borderColor: "#2A08B6",
                                style: {
                                    color: "#fff",
                                    background: "#2A08B6"
                                },
                                orientation: "horizontal",
                                text: "Penunjuk Hari Ini"
                            }
                        }
                    ]
                }
            }
        }));
    }, [props.DataDateSelected]);

    const isNumeric = (value: any) => {
        return /^-?\d*\.?\d+$/.test(value);
    };

    const fetchData = async () => {
        setState((prevState: any) => ({ ...prevState, itemLoading: true }));

        const formattedDate = new Date().toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'long',
            year: 'numeric'
        }).replace(/(\d+) ([a-zA-Z]+) (\d+)/, "$1-$2-$3");

        const defaultGroupingId = '10';
        const defaultCompany = company_chart == 'A000' ? 'B000' : company_chart;

        try {
            const response = await axios.post(getEnv("stockview") + `/chartDashboard-main/main-chart-dashboard`, {
                grouping_id: props.DataActiveCompany?.id_grouping || defaultGroupingId,
                company: props.DataActiveCompany?.company_code || defaultCompany,
                date: formattedDate,
                type: props.TypeSelectedChart || "all"
            });

            const success = response.data?.data;
            if (success) {
                let newSeriesData: any = [];
                let isDap = false;
                let seriesTemp: any[] = [];

                for (let key in success) {
                    if (success.hasOwnProperty(key)) {
                        let name;

                        switch (key) {
                            case 'proses_pengadaan':
                                name = "Proses Pengadaan";
                                break;
                            case 'rencana_pembelian':
                                name = "Rencana Pembelian";
                                break;
                            case 'produksi':
                                name = "Produksi";
                                break;
                            case 'kedatangan':
                                name = "Kedatangan";
                                break;
                            case 'mutasi':
                                name = "Mutasi";
                                break;
                            case 'konsumsi':
                                name = "Konsumsi";
                                break;
                            case 'safety_stock':
                                name = "Safety Stock";
                                break;
                            case 'stock':
                                const materials = Object.keys(success[key]);
                                isDap = materials.some(material => ['DAP', 'KCL', 'RP', 'ZA', 'PA', 'SA', 'SULFUR', 'KCL FLAKE'].includes(material));
                                seriesTemp = materials.filter(material => !['DAP', 'KCL', 'RP', 'ZA', 'PA', 'SA', 'SULFUR', 'KCL FLAKE'].includes(material));
                                materials.forEach(material => {
                                    if (isDap && ['DAP', 'KCL', 'RP', 'ZA', 'PA', 'SA', 'SULFUR', 'KCL FLAKE'].includes(material)) {
                                        const transformedData = Object.values(success[key][material]).map(item => {
                                            const { x, y } = item as { x: string, y: string }; // Type assertion here
                                            return { x, y };
                                        });
                                        newSeriesData.push({
                                            name: `Stock Material ${material}`,
                                            type: "area",
                                            data: transformedData,
                                            fill: {
                                                colors: ['#fce205'] // Set fill color to #fce205
                                            },
                                        });
                                    }
                                });
                                break;
                            default:
                                name = key;
                        }

                        if (isDap) {
                            for (let i = 0; i < seriesTemp.length; i++) {
                                if (success[key][seriesTemp[i]]) {
                                    const transformedData = Object.values(success[key][seriesTemp[i]]).map(item => {
                                        const { x, y } = item as { x: string, y: string }; // Type assertion here
                                        return { x, y };
                                    });
                                    newSeriesData.push({
                                        name: `Stock Material ${seriesTemp[i]}`,
                                        type: "line",
                                        data: transformedData
                                    });
                                }
                            }
                        }

                        if (name !== undefined && key !== 'stock' && success[key]) {
                            newSeriesData.push({
                                name: name,
                                type: isNumeric(key) ? "area" : "line",
                                data: success[key]
                            });
                        }
                    }
                }

                const order = ['Kedatangan', 'Proses Pengadaan', 'Rencana Pembelian', 'Mutasi', 'Konsumsi', 'Produksi', 'Safety Stock'];

                // Sort all items including Stock Materials
                newSeriesData.sort((a: any, b: any) => {
                    // Get the order index of each item
                    const orderIndexA = order.indexOf(a.name.replace('Stock Material ', ''));
                    const orderIndexB = order.indexOf(b.name.replace('Stock Material ', ''));

                    // If the item is not in the order array, set its order index to a high number to push it to the end
                    const adjustedOrderIndexA = orderIndexA === -1 ? order.length : orderIndexA;
                    const adjustedOrderIndexB = orderIndexB === -1 ? order.length : orderIndexB;

                    return adjustedOrderIndexA - adjustedOrderIndexB;
                });

                // Find the minimum value from all data series
                const minValue = newSeriesData.reduce((min, series) => {
                    const seriesMin = Math.min(...series.data.map((point: any) => point.y));
                    return seriesMin < min ? seriesMin : min;
                }, Infinity);

                // Find the maximum value from all data series
                const maxValue = newSeriesData.reduce((max, series) => {
                    const seriesMax = Math.max(...series.data.map((point: any) => point.y));
                    return seriesMax > max ? seriesMax : max;
                }, -Infinity);

                setState((prevState: any) => ({
                    ...prevState,
                    series: newSeriesData,
                    options: {
                        ...prevState.options,
                        yaxis: {
                            ...prevState.options.yaxis,
                            min: minValue,
                            max: maxValue
                        },
                        fill: {
                            ...prevState.options.yaxis,
                            colors: ['#fce205']
                        }
                    }
                }));
            }
        } catch (error) {
            console.error(error);
        } finally {
            setState((prevState: any) => ({ ...prevState, itemLoading: false }));
        }
    };

    useEffect(() => {
        if (props.DataActiveCompany || props.DataDateSelected || props.TypeSelectedChart) {
            fetchData();
        }
    }, [props.DataActiveCompany, props.TypeSelectedChart, props.DataDateSelected]);

    return (
        <div>
            <div id="chart">
                <ApexCharts options={state.options} series={state.series} type="area" height={350} />
            </div>
            <div id="html-dist"></div>
        </div>
    );
}

export default ApexChart;
