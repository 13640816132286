/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, NavLink} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login} from '../core/_requests'
import {useAuth} from '../core/Auth'
import {useIntl} from 'react-intl'
import {setAuth} from '../core/AuthHelpers'
import { toast } from "react-toastify";
import axios from "axios";
import { getEnv } from "../../../../helpers/procurex";

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email or username is required'),
})

const initialValues = {
  email: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function ForgotPassword() {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const [isShowPassword, setIsShowPassword] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const showSidebar = async () => {
    // hide sidebar
    const elementToClick = document.getElementById('kt_aside_toggle')

    if (elementToClick && elementToClick.classList.contains('active')) {
      // Memicu klik pada elemen
      elementToClick.click()
    }
  }
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        await axios.post(getEnv('sso')+'/request-reset-password', {
          username: values.email
        }).then((response) => {
          setIsSuccess(true)
        })
      } catch (error:any) {
        toast.error(error)
      } finally {
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      <div className='text-center mb-10'>
        <img src='https://www.pupuk-indonesia.com/assets/img/logo.png' alt='' />
      </div>
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Forgot Password</h1>

      </div>
      {/* begin::Heading */}
      {!isSuccess && (
        <>
          {formik.status ? (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          ) : null}

          {/* begin::form group */}
          <div className='fv-row mb-8'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({ id: 'HOMEPAGE.LOGIN_EMAIL_OR_USERNAME' })}
            </label>
            <input
              placeholder={intl.formatMessage({ id: 'HOMEPAGE.LOGIN_EMAIL_OR_USERNAME' })}
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': formik.touched.email && formik.errors.email },
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
              type='email'
              name='email'
              autoComplete='off'
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::form group */}

          {/* begin::Wrapper */}
          <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
            <div />

            {/* begin::Link */}
            <Link to='/auth' className='link-primary'>
              Return to login
            </Link>
            {/* end::Link */}
          </div>
          {/* end::Wrapper */}

          {/* begin::Action */}
          <div className='d-grid mb-10'>
            <button
              type='submit'
              id='kt_sign_in_submit'
              className='btn btn-primary'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Submit</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
              Tunggu sebentar...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
              )}
            </button>
          </div>
          {/* end::Action */}
        </>
      )}
      {isSuccess && (
        <div className="text-center mb-10">
          <img src="/media/auth/please-verify-your-email.png" alt="" className={"w-50"} />
          <h4>Please check your email.</h4>

          <Link to='/auth' className='link-primary'>
            Return to login
          </Link>
        </div>
      )}


      {/* <div className="d-grid mb-10">
        <NavLink to="/auth/registration" className="btn btn-outline btn-outline-primary">
          {intl.formatMessage({ id: "HOMEPAGE.LOGIN_REGISTER" })}
        </NavLink>
      </div> */}

      {/* <div className='text-gray-500 text-center fw-semibold fs-6'>
        Daftar jadi vendor?{' '}
        <Link to='/auth/registration' className='link-primary'>
          Daftar
        </Link>
      </div> */}
    </form>
  )
}
