import {useEffect, useState} from "react";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {setAuth} from "../../auth";

interface IMeta {
	message: string,
	code: number
}
interface IResponse {
	data: any,
	meta: IMeta
}
export const useGetProfile = ()=> {
	const url = process.env.REACT_APP_API_URL+"/api/auth/profile"
	const [isLoadingProfile,setIsLoading] = useState<boolean>(false);
	const [responseDataProfile, setResponseData] = useState<any>({})
	const [responseErrorProfile,setResponseError] = useState<any>({})

	useEffect(()=>{
		getDataProfile();
	},[])
	const getDataProfile = async():Promise<void> => {
		setIsLoading(true)
		setResponseData({})
		try {
			const response =  await axios.get<IResponse>(url)
			setResponseData(response.data);
		} catch(e) {
			setResponseError(e)
		} finally {
			setIsLoading(false)
		}
	}

	return [ isLoadingProfile, responseDataProfile, responseErrorProfile, getDataProfile] as const;
}
export const useUpdateProfile = ()=> {
	const url = process.env.REACT_APP_API_URL+"/api/auth/profile"
	const [isLoadingUpdateProfile,setIsLoading] = useState<boolean>(false);
	const [responseDataUpdateProfile, setResponseData] = useState<any>({})
	const [responseErrorUpdateProfile,setResponseError] = useState<any>({})

	const submitUpdateProfile = async(payload:any):Promise<void> => {
		setIsLoading(true)
		setResponseData({})
		setResponseError({})
		try {
			const response =  await axios.post<IResponse>(url,payload)
			setResponseData(response.data);
			toast.success(response.data.meta.message)
		} catch(e) {
			setResponseError(e)
			//@ts-ignore
			toast.error(e)
		} finally {
			setIsLoading(false)
		}
	}

	return [ isLoadingUpdateProfile, responseDataUpdateProfile, responseErrorUpdateProfile, submitUpdateProfile] as const;
}
export const useUpdatePassword = ()=> {
	const url = process.env.REACT_APP_API_URL+"/api/auth/profile/password"
	const [isLoadingUpdatePassword,setIsLoading] = useState<boolean>(false);
	const [responseDataUpdatePassword, setResponseData] = useState<any>({})
	const [responseErrorUpdatePassword,setResponseError] = useState<any>({})

	const submitUpdatePassword = async(payload:any):Promise<void> => {
		setIsLoading(true)
		setResponseData({})
		setResponseError({})
		try {
			const response =  await axios.post<IResponse>(url,payload)
			setResponseData(response.data);
			toast.success(response.data.meta.message)
		} catch(e) {
			setResponseError(e)
			//@ts-ignore
			toast.error(e)
		} finally {
			setIsLoading(false)
		}
	}

	return [ isLoadingUpdatePassword, responseDataUpdatePassword, responseErrorUpdatePassword, submitUpdatePassword] as const;
}
export const useRefreshJwt = ()=> {
	const url = process.env.REACT_APP_API_URL+"/api/auth/profile/refresh_token"
	const [isLoadingRefreshToken,setIsLoading] = useState<boolean>(false);
	const [responseDataRefreshToken, setResponseData] = useState<any>({})
	const [responseErrorRefreshToken,setResponseError] = useState<any>({})

	const submitUpdateRefreshToken = async():Promise<void> => {
		setIsLoading(true)
		setResponseData({})
		setResponseError({})
		try {
			const response =  await axios.post<IResponse>(url)
			setResponseData(response.data);
			//@ts-ignore
			setAuth(response.data)
			toast.success(response.data.meta.message)
		} catch(e) {
			setResponseError(e)
			//@ts-ignore
			toast.error(e)
		} finally {
			setIsLoading(false)
		}
	}

	return [ isLoadingRefreshToken, responseDataRefreshToken, responseErrorRefreshToken, submitUpdateRefreshToken] as const;
}