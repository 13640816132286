import React, { useEffect, useState, useContext } from "react";
import { KTCard, KTCardBody } from "../../../../../../_metronic/helpers";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import { DatePicker } from "rsuite";
import { NIL } from "uuid";
import { AuctionContext } from "../../../../../routing/AuctionRoutes";

const JadwalAuction = () => {
    const BASE_URL = process.env.REACT_APP_API_URL;
    const headerFormSchema = Yup.object().shape({});
    const params = useParams();
    const id = params?.['*'];
    const [detailData, setDetailData]: any = useState({});
    const [isLoadingSave, setIsLoadingSave] = useState(false)
    const [batasAwalEmpty, setIsBatasAwalEmpty] = useState(false)
    const [batasAkhirEmpty, setIsBatasAkhirEmpty] = useState(false)
    const { itemPesertaState, setItemPesertaState } = useContext(AuctionContext);

    const fetchDetailData = async () => {
        const response: any = await axios.get(`${BASE_URL}/api/auction/auction/${id}`)
        let details = {
            batas_awal: response.data?.data?.batas_awal ? Date.parse(moment(response.data?.data?.batas_awal).format('yyyy-MM-DD HH:mm:ss')) : null,
            batas_akhir: response.data?.data?.batas_akhir ? Date.parse(moment(response?.data?.data?.batas_akhir).format('yyyy-MM-DD HH:mm:ss')) : null,
            catatan: response.data?.data?.catatan,
            status: response.data?.data?.status
        }
        setDetailData(details);
    }

    const onSubmit = async (values: any) => {
        if (!values?.batas_awal) {
            setIsBatasAwalEmpty(true);
        } else if (!values?.batas_akhir) {
            setIsBatasAkhirEmpty(true);
        } else {
            setIsLoadingSave(true)
            let data = {
                batas_awal: moment(values?.batas_awal).format('yyyy-MM-DD HH:mm:ss'),
                batas_akhir: moment(values?.batas_akhir).format('yyyy-MM-DD HH:mm:ss'),
                catatan: values?.catatan
            }
            try {
                const response: any = await axios.put(`${BASE_URL}/api/auction/auction/jadwal/` + id, data)
                if (response?.status === 201 || response?.status === 200) {
                    toast.success(response?.status + ': ' + response?.data.meta.message, {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'light',
                    })
                    setItemPesertaState(true)
                    return true
                }
            } catch (error) {
                setIsLoadingSave(false)
                console.error(error)
            } finally {
                setIsLoadingSave(false)
            }
        }

    }

    useEffect(() => {
        fetchDetailData();
        setItemPesertaState(false)
    }, [itemPesertaState])

    return (
      <>
        <KTCard>
          <KTCardBody className='py-4'>
            <Formik
              enableReinitialize
              validationSchema={headerFormSchema}
              initialValues={detailData}
              onSubmit={onSubmit}
            >
              {({errors, touched, values, setFieldValue}: any) => (
                <Form>
                  <div className={'row'}>
                    <div className={'col-md-12'}>
                      <div className='mb-10'>
                        <label className='form-label mb-3'>Batas Awal Auction Submission</label>
                        <DatePicker
                          className='form-control'
                          style={{borderColor: batasAwalEmpty ? 'red' : '#e4e6ef'}}
                          value={values?.batas_awal ?? null}
                          disabled={detailData?.status == 'published'}
                          onChange={(val: any) =>
                            setFieldValue(
                              'batas_awal',
                              Date.parse(moment(val).format('yyyy-M-DD HH:mm:ss'))
                            )
                          }
                          format='dd-MM-yyyy HH:mm:ss'
                        />
                        <label
                          hidden={!batasAwalEmpty}
                          className='form-label mb-3'
                          style={{fontSize: 10, color: 'red'}}
                        >
                          *Batas Awal is Required!
                        </label>
                      </div>
                      <div className='mb-10'>
                        <label className='form-label mb-3'>Batas Akhir Auction Submission</label>
                        <DatePicker
                          style={{borderColor: batasAkhirEmpty ? 'red' : '#e4e6ef'}}
                          className='form-control'
                          disabled={detailData?.status == 'published'}
                          value={values?.batas_akhir ?? null}
                          onChange={(val: any) =>
                            setFieldValue(
                              'batas_akhir',
                              Date.parse(moment(val).format('yyyy-M-DD HH:mm:ss'))
                            )
                          }
                          format='dd-MM-yyyy HH:mm:ss'
                        />
                        <label
                          hidden={!batasAkhirEmpty}
                          className='form-label mb-3'
                          style={{fontSize: 10, color: 'red'}}
                        >
                          *Batas Awal is Required!
                        </label>
                      </div>
                      <div className='mb-10'>
                        <label className='form-label mb-3'>Catatan</label>
                        <Field
                          disabled={detailData?.status == 'published'}
                          required
                          as={'textarea'}
                          rows={5}
                          className='form-control'
                          name='catatan'
                        />
                      </div>
                    </div>
                  </div>
                  {detailData?.status == 'draft' && (
                    <div className={'row'}>
                      <div className={'col-md-12 text-end'}>
                        <button className={'btn btn-primary'} disabled={isLoadingSave}>
                          {isLoadingSave && (
                            <Spinner animation={'border'} size={'sm'} className={'me-2'} />
                          )}
                          <i className={'fa fa-save'}></i>
                          Simpan
                        </button>
                      </div>
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          </KTCardBody>
        </KTCard>
      </>
    )
};

export { JadwalAuction };
