import { Outlet, Route, Routes } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../../_metronic/layout/core'
import React, { useState, useEffect } from 'react'
import { PPICTransactionStockviewList } from './components/PPICTransactionStockviewList'
import { Field, Form, Formik } from 'formik'
import { useAuth } from "../../../../../app/modules/auth";
import { toast } from 'react-toastify'
import { getEnv } from "../../../../../helpers/procurex";
import axios from 'axios'
import { useIntl } from "react-intl";
import SelectX from '../../../../components/shared/form/SelectX'
import DatepickerX2 from "../../../../components/shared/form/DatepickerX2";

const masterData = {
    title: 'PPIC Transaction',
    path: '/stockview/transaction/ppic-transaction',
    isSeparator: false,
    isActive: false,
}
const separator = {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
}
const breadcrumbs: Array<PageLink> = [masterData, separator]
const childBreadcrumbs: Array<PageLink> = [
    masterData,
    separator,
    {
        title: 'Stockview PPIC Transaction',
        path: '/stockview/transaction/ppic-transaction',
        isSeparator: false,
        isActive: false,
    },
    separator,
]

const PPICTransactionStockview = () => {
    const { currentUser } = useAuth();

    const intl = useIntl();
    const initialValues = {
        komoditas: '',
        tanggal: '',
    }

    const [formindex, setFormIndex] = useState(0)
    const refFormik = React.useRef<any>();
    const [loading, setLoading] = useState(true)
    const [isLoading, setIsLoading] = useState(false);
    const [itemsMasterPPIC, setItemsMasterPPIC] = useState<any>([])
    const [itemLoading, setItemLoading] = useState(false)

    const loadItem = () => {
        axios
            .get(getEnv("stockview") + `/ppic?perPage=100&page=1`)
            .then((res) => {
                setItemsMasterPPIC(res.data.data)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setItemLoading(false)
            })
    }

    useEffect(() => {
        loadItem();
    }, [])

    const handleSubmit = async (values: any) => {
        setLoading(true);
        try {
            const requests = [];

            requests.push(axios.post(`${getEnv("stockview")}/ppic-transaction`, values));

            const responses = await Promise.all(requests);

            responses.forEach(response => {
                if (response.status === 200 || response.status === 201) {
                    toast.success(response?.status + ': ' + response?.data.meta.message, {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'light',
                    });
                }
            });

        } catch (error) {
            console.error('Error processing records:', error);
            if (error.response) {
                // Server responded with a status other than 200 range
                toast.error(error.response.data?.meta?.message || 'Terjadi kesalahan', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                });
            } else if (error.request) {
                // Request was made but no response received
                console.error('Request error:', error.request);
                toast.error('Failed to get response from the server', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                });
            } else {
                // Something else caused an error
                console.error('General error:', error.message);
                toast.error('Terjadi kesalahan', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                });
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Routes>
                <Route element={<Outlet />}>
                    <Route
                        index
                        element={
                            <>
                                <PageTitle breadcrumbs={breadcrumbs}>PPIC Transaction Stockview</PageTitle>
                                <div className='card'>
                                    <div className='card-body'>
                                        <p className="text-muted mb-3">Halaman ini bertujuan sebagai tools penarikan data dari PPIC PKG.</p>
                                        <Formik
                                            key={formindex}
                                            innerRef={refFormik}
                                            initialValues={initialValues}
                                            onSubmit={handleSubmit}
                                            enableReinitialize
                                            validateOnChange={true}
                                        >
                                            {({ touched, values, setFieldValue }: any) => (
                                                <Form>
                                                    <div className='card my-2'>
                                                        <div className='card-header d-flex align-items-center'>
                                                            <b>Pull Data PPIC PKG</b>
                                                        </div>
                                                        <div className='card-body'>
                                                            <div className='row'>
                                                                <div className='col-lg-6'>
                                                                    <div className='mb-6'>
                                                                        <label htmlFor='' className='form-label required'>
                                                                            Komoditas
                                                                        </label>
                                                                        <SelectX
                                                                            onChange={(e: any) => setFieldValue('komoditas', e)}
                                                                            options={itemsMasterPPIC.map((item: any) => ({
                                                                                label: `${item.nama} - ${item.id_ppic}`,
                                                                                value: item.id_ppic
                                                                            }))}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-6'>
                                                                    <div className='mb-6'>
                                                                        <label htmlFor='' className='form-label required'>
                                                                            Tanggal
                                                                        </label>
                                                                        <DatepickerX2
                                                                            onChange={(date: any) => setFieldValue("tanggal", date)}
                                                                            size={"lg"}
                                                                            value={values.tanggal}
                                                                            format={"dd-MM-yyyy"}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className='d-flex gap-4'>
                                                                    <button type="submit" className={"btn btn-primary btn-sm fw-bold"}>
                                                                        {isLoading && <i className={"fa fa-spinner fa-spin"}></i>}
                                                                        {!isLoading && <i className={"fa fa-arrow-down"}></i>}
                                                                        {intl.formatMessage({ id: "PULL" })}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                    </div>
                                </div>
                                <PPICTransactionStockviewList />
                            </>
                        }
                    ></Route>
                </Route>
            </Routes>
        </>
    )
}

export default PPICTransactionStockview
