import { Modal } from "react-bootstrap";
import React, { useEffect, useState, useRef } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import FormikSelect2 from "./form/FormikSelect2";
import Swal from "sweetalert2";
import { formatDateTimeToDBFormat, getEnv } from "../../../helpers/procurex";
import { useAxiosGet } from "../../../hooks/useAxios";

interface IProps {
  title: string;
  show: boolean;
  closeModal: Function;
  tableList: any
  onSubmit?: Function;
  urlTujuanDisposisi?: string;
  isLoading?: boolean;
  isTerimaDokumen?: boolean;

  //untuk menampilkan history
  historyUrls?: any
}

const GetDetailUser = ({userId}: any) => {
  const [{ doSubmit, isLoading: isLoadingGetDetail, isError, data, isSuccess }] = useAxiosGet(
    getEnv("sso")+`/user/${userId}`
  )
  const [user,setUser] = useState({
    name: ''
  })
  const initialized = useRef(false)
  useEffect(() => {
    doSubmit({},undefined,undefined).then((res:any)=>{
      setUser(res?.data)
    })
  }, []);
  return (
    <>
      {isLoadingGetDetail && (<i className={"fa fa-spin fa-spinner"}></i>)}
      {user?.name}
    </>
  )
}

const ModalDisposisiApproveX = ({
                                  title,
                                  show,
                                  closeModal,
                                  tableList,
                                  urlTujuanDisposisi,
                                  isLoading,
                                  onSubmit,
                                  isTerimaDokumen,
                                  historyUrls

}: IProps) => {
  const initialValues = {
    notes: '',
    user: ''
  }
  const [validationSchema, setValidationSchema] = useState(Yup.object({
    // notes: Yup.string().required('The field is required'),
  }))
  const formikRef = React.useRef<any>();

  const [{ doSubmit, isLoading: isLoadingGetDetail, isError, data, isSuccess }] =useAxiosGet(
    getEnv("approval")+"/routing-approval/detail?"
  )
  const [historyList,setHistoryList] = useState([]);

  const handleSubmit = (values:any) => {
    if(onSubmit){
      Swal.fire({
        title: "Apakah anda yakin?",
        text: isTerimaDokumen ? "Anda akan menerima dokumen ini." : "Anda akan melakukan disposisi.",
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Ya, lanjutkan',
        cancelButtonText: 'Tidak',
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          const payload = {
            ...values,
            notes: values?.notes || '-',
          }
          onSubmit(payload)
        }
      })

    }
  };

  const getRiwayat = async() => {
    let tmp: Array<any> = [];
    historyUrls?.map(async (v:any)=>{
      await doSubmit({},v,undefined).then((res:any)=>{
        tmp = [...tmp, ...res?.data[0]?.logs];
      })
      // @ts-ignore
      setHistoryList(tmp)
      console.log(tmp);
    })

  }

  useEffect(() => {
    if(show){
      formikRef.current?.resetForm();
    }
  }, [show])

  useEffect(() => {
    if(historyUrls && historyUrls?.length > 0 && show){
      getRiwayat()
    }
  }, [historyUrls, show]);

  useEffect(() => {
    if(!isTerimaDokumen){
      setValidationSchema(Yup.object({
        notes: Yup.string().required('The field is required'),
        user: Yup.object().required('The field is required'),
      }));
    }
  }, []);



  return (
    <>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ errors, touched, values, submitForm }) => (
          <Form>
            <>
              <Modal show={show} size={"lg"}>
                <Modal.Header closeButton onHide={() => closeModal(false)}>
                  <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{
                  zIndex: 9999
                }}>
                  <div>
                    
                    <div className={"table-responsive"} style={{
                      maxHeight: "500px"
                    }}>
                      {tableList}
                    </div>

                    {historyList?.length > 0 && (
                      <div className={"table-responsive"} style={{
                        maxHeight: "500px"
                      }}>
                        <label className="form-label mb-3" htmlFor="note">
                          <strong>Riwayat Disposisi</strong>
                        </label>
                        <table className={"table table-bordered table-header-gray"}>
                          <thead>
                          <tr>
                            <th className={"text-center"} style={{width: '50px'}}>No</th>
                            <th className={"text-center"}>Jabatan</th>
                            <th className={"text-start"}>User</th>
                            <th className={"text-start"}>Aksi</th>
                            <th className={"text-start"}>Catatan</th>
                            <th className={"text-center"}>Tanggal</th>
                          </tr>
                          </thead>
                          <tbody>
                          {historyList?.map((v:any, index:number)=>{
                            return (
                              <tr key={"history-table-"+index}>
                                <td className={"text-center px-2 border"}>{index+1}</td>
                                <td className={"text-center px-2 border"}>
                                  <span className={"badge badge-success text-dark"}>{v?.jabatan}</span>
                                </td>
                                <td className={"text-start px-2 border"}>
                                  <GetDetailUser userId={v?.user_id} />
                                </td>
                                <td className={"text-start px-2 border"}>{v?.action}</td>
                                <td className={"text-start px-2 border"}>{v?.comment}</td>
                                <td className={"text-center px-2 border"}>{formatDateTimeToDBFormat(v?.created_at)}</td>
                              </tr>
                            )
                          })}
                          </tbody>
                        </table>
                      </div>
                    )}

                    <div className="my-10">
                      <label className="form-label mb-3" htmlFor="note">
                        Catatan {isTerimaDokumen ? 'Penerimaan Dokumen' : 'Disposisi'}
                      </label>
                      <Field
                        as={"textarea"}
                        name={"notes"}
                        className={"form-control"}
                      />
                      {(errors.notes && touched.notes) && (
                        <div className="fv-plugins-message-container">
                          {/* @ts-ignore*/}
                          <div className="fv-help-block">{errors.notes}</div>
                        </div>
                      )}
                    </div>
                    {!isTerimaDokumen && (
                      <div>
                        <label className="form-label mb-3" htmlFor="destination">
                          Tujuan Disposisi
                        </label>
                        <FormikSelect2
                          name={`user`}
                          optionsUrl={`${urlTujuanDisposisi}`}
                          getOptionLabel={(opt: any) => {
                            return opt?.username + " - " + opt?.name
                          }}
                          getOptionValue={(opt: any) => opt}
                          required={true}
                          unique_property="emp_no"
                          search_property={["name"]}
                        />

                      </div>
                    )}

                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="button"
                    onClick={() => {
                      closeModal(false);
                    }}
                    disabled={isLoading}
                    className="btn btn-danger">
                    <i className="fa fa-times"></i>
                    Cancel
                  </button>
                  <button
                    type="button"
                    onClick={submitForm}
                    disabled={isLoading}
                    className="btn btn-primary">
                    {isLoading && <i className="fa fa-spinner fa-spin"></i>}
                    {!isLoading && <i className="fa fa-save"></i>}
                    Submit
                  </button>
                </Modal.Footer>
              </Modal>
            </>
          </Form>
        )}
      </Formik>
    </>
  );
};

// @ts-ignore
export default ModalDisposisiApproveX;