import { KTCard, KTCardBody } from "../../../../../_metronic/helpers";
import React, { useEffect, useState } from "react";
import TableXWrpper from "../../../../components/shared/TableXWrapper";
import { useNavigate } from "react-router-dom";
import { getEnv } from "../../../../../helpers/procurex";
import FormCreateAanwijzing from "./FormCreateAanwijzing";
import {Button, Popover, Whisper} from 'rsuite'

interface IPropsStatusBadge {
  status: string;
  isHideStatusDescription?: boolean
}
export const StatusBadgeAanwijzing = ({status,isHideStatusDescription}:IPropsStatusBadge) => {
  const [badge,setBadge] = useState("badge-primary");
  useEffect(() => {
    setBadge(getBadge(status))
  }, [status]);

  const getBadge = (status:string) => {
    if (status === "OPEN") return "badge-info";
    if (status === "APPROVED") return "badge-primary";
    if (status === "CANCEL" || status === "REJECTED") return "badge-danger";
    if (status === "SUBMITTED") return "badge-warning";
    if (status === "LIVE") return"badge-success";
    if (status === "DONE") return "badge-secondary";
    if (status === "CLOSE") return "badge-dark";
    return "badge-info";
  }

  const availableStatus = [
    { label: "OPEN", desc: "Status ketika pertama kali dibuat." },
    { label: "SUBMITTED", desc: "Status ketika pengajuan approval oleh creator." },
    { label: "APPROVED", desc: "Status ketika sudah di approved oleh seluruh approval, creator dapat mulai mengisi tab lain." },
    { label: "REJECTED", desc: "Status approval reject." },
    { label: "LIVE", desc: "Status ketika sudah di approve dan memasuki jadwal, creator dan vendor dapat join kedalam forum." },
    { label: "DONE", desc: "Status ketika jadwal berakhir / ditutup oleh creator, creator masih dapat mengupdate summary dan vendor masih dapat approve BA." },
    { label: "CLOSE", desc: "Status ketika aanwijzing sudah selesai, vendor masih dapat melakukan approval BA." },
    { label: "CANCEL", desc: "Status ketika creator membatalkan aanwizing." },
  ]

  return (
    <div className={"text-lg"}>
      {!isHideStatusDescription && (
        <Whisper
          trigger="hover"
          placement={'autoHorizontal'}
          speaker={
            <Popover title="Available Status Aanwijzing">
              <ul>
                {availableStatus?.map((v: any, i: number) => {
                  return (
                    <li key={'status-' + i} className={"mb-2"}>
                      <span className={`badge ${getBadge(v?.label)}`}>{v?.label}</span><span className={"w-50 ms-2"}>{v?.desc}</span>
                    </li>
                  )
                })}

              </ul>
            </Popover>
          }>
          <i className={'fa fa-info-circle me-3'}></i>
        </Whisper>
      )}

      <div className={`badge ${badge} badge-lg`}>{status}</div>
    </div>
  )
}
const AanwijzingList = () => {
  const urlIndex = getEnv('tender') + `/aanwijzing`
  const [tableIndex, setTableIndex] = React.useState(0)
  const navigate = useNavigate()
  const tableColumns = [
    {
      Header: 'Aksi',
      accessor: 'aksi',
      width: 50,
      className: 'text-center',
      Cell: (props: any) => {
        const row = props.row.original;
        return (
          <>
            <a href={"#"} title={"Edit"} onClick={() => {
              navigate("/tender/admin/aanwijzing/"+row.uuid);
            }}>
              <i className="fa fs-3 fa-eye"></i>
            </a>
          </>
        )
      }
    },
    {
      Header: "status",
      accessor: "status",
      className: "text-start",
      width: 100,
      Cell: (props: any) => {
        return (
          <>
            <StatusBadgeAanwijzing
              status={props.row.original.status}
              isHideStatusDescription={true}
            />
          </>
        )
      }
    },
    {Header: 'Reference', accessor: 'reference', width: 180, disableCreate: true, disableEdit: true, className: 'text-start'},
    {Header: 'Company', accessor: 'company_code', width: 100, disableCreate: true, disableEdit: true, className: 'text-center'},
    {Header: 'Source', accessor: 'source', width: 100, disableCreate: true, disableEdit: true, className: 'text-center'},
    {Header: 'Title', accessor: 'title', width: 300},
    {Header: 'Start At', accessor: 'start_at', width: 200, className:'text-center'},
    {Header: 'End At', accessor: 'end_at', width: 200, className:'text-center'},
    {Header: 'Created At', accessor: 'created_at', width: 250, className:'text-center'},
    {Header: 'Current User', accessor: 'current_user', width: 150, className: 'text-center', Cell: (props: any) => {
        const data = props.cell.row.original
        return data.current_approval_user ?(
          <span className={`badge badge-primary`}>
          {data.current_approval_user}
          </span>
        ) : data?.current_user;
      }},
  ]
  useEffect(() => {
    setTableIndex(tableIndex + 1);
  }, []);
  return (
    <>
      <KTCard>
        <KTCardBody className='py-4'>
          <TableXWrpper
            key={tableIndex}
            readonly={true}
            title={"Aanwijzing"}
            urlIndex={urlIndex}
            columns={tableColumns}
            isForceCreateButton={true}
            headerButtons={
              <>
                <FormCreateAanwijzing onAfterSubmit={()=>setTableIndex(tableIndex+1)} />
              </>
            }
            disableUrlChanged={true}
            hasAnySearch={true}
          />
        </KTCardBody>
      </KTCard>
    </>
  )
}

export {AanwijzingList}
