import React, { useEffect, useState } from "react";
import { getEnv } from "../../../../../../helpers/procurex";
import { ButtonGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useDeletePRAttachment, usePostPRAttachment } from "../../hooks/proposalTenderHook";
import FormAttachment from "../../../../../components/shared/form/FormAttachment";
import Select from "react-select";
import TableXWrapper from "../../../../../components/shared/TableXWrapper";
import PreviewAttachment from "../../../../../components/PreviewAttachment";


export default function FormAttachmentPRItemTender({selectedRows, disableAddAttachment}: any) {
  const [{ doSubmit: doSubmitAttachment, isLoading: isLoadingAttachment, data: dataAttachment, isSuccess: isSuccessAttachment }] = usePostPRAttachment();
  const [{ doSubmit: doSubmitDeleteAttachment, isLoading: isLoadingDeleteAttachment }] = useDeletePRAttachment()
  const [selectedPrId, setSelectedPrId] = React.useState(null);
  const [selectedPrIds, setSelectedPrIds] = React.useState("");
  const [selectedPrIdForFiltering, setSelectedPrIdForFiltering] = React.useState("");
  const [prOptions, setPrOptions] = React.useState([]);
  const urlIndex = getEnv("tender") + `/purchase-requisition-attachment`;
  const [tableIndex,setTableIndex] = useState(1)
  const [previewAttachment,setPreviewAttachment] = useState<string>("")

  const tableColumns = [
    {
      Header: "Action",
      accessor: "media_url",
      className: "text-center",
      width: 150,
      Cell: (props: any) => (
        <>
          <ButtonGroup size={"sm"} aria-label="Action">
            {/*{(props.cell.row.original.action.delete &&*/}
            {/*  (headerData?.status === 'draft' || headerData?.status === 'rejected' || headerData?.status === 'planner_rejected')*/}
            {/*) && (*/}
            {disableAddAttachment !== true && (
            <a
              href={"#"}
              type="button"
              className="me-4"
              onClick={() => {
                handleOnDeleteAttachment(props.cell.row.original);
              }}
            >
              <i className={"fa fa-trash fs-2"} />
            </a>
            )}
            {/*)}*/}

            <a href={"#"}
               type="button"
               onClick={() => {
                 setPreviewAttachment(props.cell.row.original.media_url);
               }}
            >
              <i className={"fa fa-eye fs-2"} />
            </a>
          </ButtonGroup>
        </>
      )
    },
    { Header: "Nomor PR", accessor: "no_pr", width: 150, className: "text-center" },
    { Header: "Type", accessor: "media_type.properties.description", width: 200, className: "text-center" },
    { Header: "Description", accessor: "description", width: 200, className: "text-center" }
  ];


  useEffect(() => {
    // @ts-ignore
    let tmp = [];
    // @ts-ignore
    let ids = "";
    let ids2 = "";
    selectedRows?.map((row: any,index:number) => {
      tmp.push({
        value: row?.original?.purchaseRequisitionItem?.purchaseRequisition?.uuid,
        label: `${row?.original?.no} (${row?.original?.line_no}) - ${row?.original?.short_text}`
      });
      ids += row?.original?.purchaseRequisitionItem?.purchaseRequisition?.id+"::"+index;
      ids2 += row?.original?.purchaseRequisitionItem?.purchaseRequisition?.id;
      if(index !== (selectedRows.length) -1){
        ids += ","
        ids2 += ","
      }

    });
    setSelectedPrIds(ids)
    setSelectedPrIdForFiltering(ids2)

    // selectedPrIds(ids);
    // @ts-ignore
    setPrOptions(tmp);
  }, [selectedRows]);

  const onAfterUpload = async (res: any) => {
    const payload = {
      pr_id: selectedPrId,
      media_type: res.type,
      description: res.description,
      media_url: res.url,
      skp: true
    };
    await doSubmitAttachment(payload, toast, () => {
      setTableIndex(tableIndex+1)
    });
  };


  const handleOnDeleteAttachment = (item:any) => {
    Swal.fire({
      title: "Konfirmasi",
      text: "Anda akan menghapus attachment ini",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ya, hapus',
      cancelButtonText: 'Tidak',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        doSubmitDeleteAttachment(item.uuid,toast,()=>{
          setTableIndex(tableIndex+1)
        }).then((res)=>{})
      }
    })
  }

  return (
    <>
      {disableAddAttachment !== true && (
        <FormAttachment
          additionalField={
            <div>
              <div className="form-group mb-5">
                <label><strong>Nomor PR</strong></label>
                <Select
                  options={prOptions}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 })
                  }}
                  onChange={(e: any) => setSelectedPrId(e.value)}
                />
              </div>
            </div>
          }
          media_type_filter={"TENDER_ATTACHMENT"}
          isDisabled={false}
          isLoading={isLoadingAttachment || isLoadingDeleteAttachment}
          afterSubmit={onAfterUpload}
        />
      )}

      <div className={"table-responsive"} key={"attachment-"+selectedPrIdForFiltering}>
        <TableXWrapper
          key={tableIndex}
          readonly={true}
          title={"Proposal Tender"}
          urlIndex={urlIndex}
          defaultFilter={`filter[purchase_requisition_id]=${selectedPrIdForFiltering}`}
          columns={tableColumns}
          isForceCreateButton={true}
        />
      </div>
      <PreviewAttachment previewExt={previewAttachment} onHide={()=>setPreviewAttachment("")} />
    </>
  )
}