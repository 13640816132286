import React, { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { Field, Form, Formik } from "formik";
import FormikSelect2 from "../../../../../components/shared/form/FormikSelect2";
import { Col, Row } from "react-bootstrap";
import FormikSelect3 from "../../../../../components/shared/form/FormikSelect3";
import * as Yup from "yup";
import { useAuth } from "../../../../auth";
import Swal from "sweetalert2";
import {
  useDeleteDraftTender,
  useGetApprovalSchema,
  usePutDraftTender,
  useSubmitDraft,
  useSubmitTender
} from "../../../admin/hooks/proposalTenderHook";
import { toast } from "react-toastify";
import { ApprovalX } from "../../../../../components/shared/ApprovalX";
import { parseStringToBool } from "../../../../../../helpers/procurex";
import { useIntl } from "react-intl";

const API_URL = process.env.REACT_APP_API_URL;
type IProps = {
  proposalTender: any
  afterSaveDraft?: Function
  show: boolean
  parent: any
}
export type Ref = HTMLButtonElement;
const ParameterPengadaanPage= forwardRef<Ref, IProps>((props, ref)  => {
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = usePutDraftTender(props.proposalTender?.uuid);
  const intl = useIntl();
  const [{ doGetDetail: doGetDetailApprovalSchema, isLoading: isLoadingSchema, data: dataSchema }] = useGetApprovalSchema();
  const [{ doSubmit: doSubmitDelete, isLoading: isLoadingDelete, isError: isErrorDelete, data: dataDelete, isSuccess: isSuccessDelete }] = useDeleteDraftTender(props.proposalTender?.uuid);
  const [{ doSubmit: doSubmitDraft, isLoading: isLoadingDraft}] = useSubmitDraft(props.proposalTender?.uuid);
  const [{ doSubmit: doSubmitTender, isLoading:isLoadingSubmitTender, isError:isErrorSubmitTender, data:dataSubmitTender, isSuccess: isSuccessSubmitTender }] = useSubmitTender(props.proposalTender?.uuid)
  const [isShowApprovalSchema, setIsShowApprovalSchema] = React.useState(false);
  const [approvalSchema, setApprovalSchema] = React.useState([]);
  const { currentUser } = useAuth();
  const [isDisabledForm, setIsDisabledForm] = React.useState(true);
  const initialValues = {
    ...props.proposalTender,
    visibilitas: "",
    masa_berlaku: [30,45,60,90].includes(props.proposalTender?.masa_berlaku) ? props.proposalTender?.masa_berlaku : "0",
    masa_berlaku_others: [30,45,60,90].includes(props.proposalTender?.masa_berlaku) ? "" : props.proposalTender?.masa_berlaku,
    delivery_time: [30,45,60,90].includes(props.proposalTender?.delivery_time) ? props.proposalTender?.delivery_time : "0",
    delivery_time_others: [30,45,60,90].includes(props.proposalTender?.delivery_time) ? "" : props.proposalTender?.delivery_time,
    buyer: props.proposalTender?.buyer
  };
  const formSchemaValidation = Yup.object().shape({
    no: Yup.string().required(intl.formatMessage({id:"Wajib diisi"})),
    buyer: Yup.string().required(intl.formatMessage({id:"Wajib diisi"})),
    title: Yup.string().required(intl.formatMessage({id:"Wajib diisi"})),
    metode_pengadaan: Yup.object().required(intl.formatMessage({id:"Wajib diisi"})),
    praqualification: Yup.boolean().required(intl.formatMessage({id:"Wajib diisi"})),
    metode_aanwijzing: Yup.string().required(intl.formatMessage({id:"Wajib diisi"})),
    metode_negosiasi: Yup.string().required(intl.formatMessage({id:"Wajib diisi"})),
    metode_penyampaian: Yup.string().required(intl.formatMessage({id:"Wajib diisi"})),
    metode_evaluasi: Yup.string().required(intl.formatMessage({id:"Wajib diisi"})),
    order_placement: Yup.string().required(intl.formatMessage({id:"Wajib diisi"})),
    multiwinner: Yup.boolean().required(intl.formatMessage({id:"Wajib diisi"})),
    incoterm: Yup.object().required(intl.formatMessage({id:"Wajib diisi"})),
    bid_opening_validation: Yup.string().required(intl.formatMessage({id:"Wajib diisi"})),
    purchasing_group: Yup.object().required(intl.formatMessage({id:"Wajib diisi"})),
    masa_berlaku: Yup.number().required(intl.formatMessage({id:"Wajib diisi"})),
    bid_bond: Yup.boolean().required(intl.formatMessage({id:"Wajib diisi"})),
    tkdn: Yup.boolean().required(intl.formatMessage({id:"Wajib diisi"})),
    lingkup_pekerjaan: Yup.string().nullable(),
    internal_note: Yup.string().nullable(),
    external_note: Yup.string().nullable(),
    lokasi_pengiriman: Yup.string().required(intl.formatMessage({id:"Wajib diisi"})),
  });
  const formik = useRef({} as any);
  const [formIndex, setFormIndex] = React.useState(0);
  useEffect(() => {
    if (props.proposalTender) {
      // doGetDetailApprovalSchema(props.proposalTender?.uuid);
      // const statusNotDisabled = ["Draft"]
      // setIsDisabledForm(!(statusNotDisabled.includes(props.proposalTender?.status)));
    }
    setIsDisabledForm(true)
  }, [props.proposalTender]);


  // @ts-ignore
  useImperativeHandle(ref, () => ({

  }));

  const handleSaveDraft = async (values: any, errors: any) => {
    console.log(values);
    const payload = {
      "title": values.title,
      "reference": values.reference,
      "purchasing_group_id": values.purchasing_group?.purch_group_id,
      "praqualification": parseStringToBool(values.praqualification),
      "metode_pengadaan_id": values.metode_pengadaan?.id,
      "metode_aanwijzing": values.metode_aanwijzing,
      "metode_negosiasi": values.metode_negosiasi,
      "metode_penyampaian": values.metode_penyampaian,
      "metode_evaluasi": values.metode_evaluasi,
      "order_placement": values.order_placement,
      "bid_bond": parseStringToBool(values.bid_bond),
      "bid_bond_value": values.bid_bond_value ? parseFloat(values.bid_bond_value).toFixed(3) : "0.000",
      "bid_opening_validation": values.bid_opening_validation,
      "incoterm_id": values.incoterm?.id,
      "multiwinner": parseStringToBool(values.multiwinner),
      "tkdn": parseStringToBool(values.tkdn),
      "tkdn_description": parseStringToBool(values.tkdn) ? values.tkdn_description : " ",
      "lokasi_pengiriman": values.lokasi_pengiriman,
      "masa_berlaku": values.masa_berlaku,
      "delivery_time": values.delivery_time,
      "lingkup_pekerjaan": values.lingkup_pekerjaan,
      "internal_note": values.internal_note,
      "external_note": values.external_note
    };
    await doSubmit(payload, toast, () => {
      props.afterSaveDraft && props.afterSaveDraft();
    });
  };

  const handleOnSubmitApproval = async (values: any) => {
    const payload = {
      approvers: values.map((item: any) => {
        return {
          step: item.step,
          jabatan: item.jabatan,
          type: 'APPROVAL',
          user_id: item.extra_info.user.user_id,
          extra_info: item.extra_info,
        }
      })
    }
    doSubmitTender(payload, toast, () => {}).then((data:any)=>{
      setIsShowApprovalSchema(false);
      props.afterSaveDraft && props.afterSaveDraft();
    }).catch(()=>{
    })
  }

  const handleOnChangeInputJaminanPenawaran = (e: any, setFieldValue:any) => {
    const inputValue = e.target.value;

    // Hapus karakter selain angka dan desimal "." dari input
    const sanitizedValue = inputValue.replace(/[^0-9.]/g, '');

    // Membatasi jumlah digit setelah desimal menjadi 3
    const parts = sanitizedValue.split('.');
    if (parts[1] && parts[1].length > 3) {
      parts[1] = parts[1].slice(0, 3);
    }

    // Gabungkan kembali nilai yang telah disaring
    const finalValue = parts.join('.');
    setFieldValue(e.target.name,finalValue);
  }

  return (
    <div style={{
      display: props.show ? "block" : "none"
    }}>
      <Formik
        innerRef={formik}
        key={`formik-tender-parameter-pengadaan-`+formIndex}
        initialValues={initialValues}
        onSubmit={handleSaveDraft}
        validationSchema={formSchemaValidation}
        enableReinitialize
        validateOnChange={true}

      >
        {({ errors, touched, values, setFieldValue, resetForm, submitForm }: any) => (
          <Form id="form-hps">
            <div className={"card"}>
              <div className={"card-body"}>
                <div className={"d-flex justify-content-between"}>
                  <div><h3>{intl.formatMessage({id:"Parameter Pengadaan"})}</h3></div>
                </div>
                <hr />

                <div className={"row"}>
                  <div className={"col-md-6 col-sm-12 col-lg-6"}>
                    <div className={"form-group mb-3"}>
                      <label className="required form-label">
                        {intl.formatMessage({id:"No. Proposal Tender"})}
                      </label>
                      <Field
                        name="no"
                        type="text"
                        className="form-control"
                        disabled={isDisabledForm}
                      />
                      {(errors.no && touched.no) && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.no}</div>
                        </div>
                      )}
                    </div>

                    <div className={"form-group mb-3"}>
                      <label className="required form-label">
                        {intl.formatMessage({id:"Judul Proposal Tender"})}
                      </label>
                      <Field
                        name="title"
                        type="text"
                        className="form-control"
                        disabled={isDisabledForm}
                      />
                      {(errors.title && touched.title) && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.title}</div>
                        </div>
                      )}
                    </div>
                    <div className={"form-group mb-3"}>
                      <label className="required form-label">
                        {intl.formatMessage({id:"Metode Pengadaan"})}
                      </label>
                      <FormikSelect2
                        name="metode_pengadaan"
                        optionsUrl={`${API_URL}/api/masterdata/metode-pengadaan`}
                        unique_property={"id"}
                        search_property={"name"}
                        getOptionValue={(opt: any) => opt}
                        getOptionLabel={(opt: any) =>
                          `${opt.name}`
                        }
                        isDisabled={isDisabledForm}
                        onChangeValue={(opt: any) => {
                          setFieldValue("praqualification", opt.pra_qualification)
                        }}
                      />
                      {(errors.metode_pengadaan_id && touched.metode_pengadaan_id) && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.metode_pengadaan_id}</div>
                        </div>
                      )}
                    </div>
                    <div className={"form-group mb-3"}>
                      <label>{intl.formatMessage({id:"Pra Qualification"})}</label>
                      <Row className={"mt-2"}>
                        <Col>
                          <label>
                            <Field
                                type="radio"
                                disabled={isDisabledForm || values?.metode_pengadaan?.pra_qualification}
                                name="praqualification"
                                className={"me-2"}
                                value={true}
                                checked={values?.metode_pengadaan?.pra_qualification ? true : parseStringToBool(values.praqualification)}
                            />
                            {intl.formatMessage({id:"Ya"})}
                          </label>
                        </Col>
                        <Col>
                          <label>
                            <Field type="radio" name="praqualification" className={"me-2"} value={false}
                                   disabled={isDisabledForm || values?.metode_pengadaan?.pra_qualification}
                                   checked={!parseStringToBool(values.praqualification)} />
                            {intl.formatMessage({id:"Tidak"})}
                          </label>
                        </Col>
                      </Row>
                      {(errors.praqualification && touched.praqualification) && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.praqualification}</div>
                        </div>
                      )}
                    </div>

                    <div className={"form-group mb-3"}>
                      <FormikSelect3
                        label={intl.formatMessage({id:"Metode Aanwijzing"})}
                        name="metode_aanwijzing"
                        optionsUrl={`${API_URL}/api/masterdata/enumeration?filter[type]=TENDER_METODE_AANWIJZING`}
                        getOptionValue={(opt: any) => opt.value}
                        getOptionLabel={(opt: any) =>
                          `${opt.value}`
                        }
                        isDisabled={true}
                      />
                      {(errors.metode_aanwijzing && touched.metode_aanwijzing) && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.metode_aanwijzing}</div>
                        </div>
                      )}
                    </div>

                    <div className={"form-group mb-3"}>
                      <FormikSelect3
                        label={intl.formatMessage({id:"Metode Negosiasi"})}
                        name="metode_negosiasi"
                        optionsUrl={`${API_URL}/api/masterdata/enumeration?filter[type]=TENDER_METODE_NEGOSIASI`}
                        getOptionValue={(opt: any) => opt.value}
                        getOptionLabel={(opt: any) =>
                          `${opt.value}`
                        }
                        isDisabled={isDisabledForm}
                      />
                      {(errors.metode_negosiasi && touched.metode_negosiasi) && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.metode_negosiasi}</div>
                        </div>
                      )}
                    </div>

                    <div className={"form-group mb-3"}>
                      <FormikSelect3
                        label={intl.formatMessage({id:"Metode Penyampaian"})}
                        name="metode_penyampaian"
                        optionsUrl={`${API_URL}/api/masterdata/enumeration?filter[type]=TENDER_METODE_PENYAMPAIAN`}
                        getOptionValue={(opt: any) => opt.value}
                        getOptionLabel={(opt: any) =>
                          `${opt.value}`
                        }
                        isDisabled={isDisabledForm}
                      />
                      {(errors.metode_penyampaian && touched.metode_penyampaian) && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.metode_penyampaian}</div>
                        </div>
                      )}
                    </div>

                    <div className={"form-group mb-3"}>
                      <FormikSelect3
                        label={intl.formatMessage({id:"Metode Evaluasi"})}
                        name="metode_evaluasi"
                        optionsUrl={`${API_URL}/api/masterdata/enumeration?filter[type]=TENDER_METODE_EVALUASI`}
                        getOptionValue={(opt: any) => opt.value}
                        getOptionLabel={(opt: any) =>
                          `${opt.value}`
                        }
                        isDisabled={isDisabledForm}
                      />
                      {(errors.metode_evaluasi && touched.metode_evaluasi) && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.metode_evaluasi}</div>
                        </div>
                      )}
                    </div>

                    <div className={"form-group mb-3"}>
                      <FormikSelect3
                        label={intl.formatMessage({id:"Order Placement"})}
                        name="order_placement"
                        optionsUrl={`${API_URL}/api/masterdata/enumeration?filter[type]=TENDER_ORDER_PLACEMENT`}
                        getOptionValue={(opt: any) => opt.value}
                        getOptionLabel={(opt: any) =>
                          `${opt.value}`
                        }
                        isDisabled={isDisabledForm}
                      />
                      {(errors.order_placement && touched.order_placement) && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.order_placement}</div>
                        </div>
                      )}
                    </div>

                    <div className={"form-group mb-3"}>
                      <label>{intl.formatMessage({id:"Multiwinner"})}</label>
                      <Row className={"mt-2"}>
                        <Col>
                          <Field type={"radio"} disabled={isDisabledForm} name={"multiwinner"} value={true}
                                 checked={parseStringToBool(values.multiwinner)} /> {intl.formatMessage({id:"Ya"})}
                        </Col>
                        <Col>
                          <Field type={"radio"} disabled={isDisabledForm} name={"multiwinner"} value={false}
                                 checked={!parseStringToBool(values.multiwinner)} /> {intl.formatMessage({id:"Tidak"})}
                        </Col>
                      </Row>
                      {(errors.multiwinner && touched.multiwinner) && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.multiwinner}</div>
                        </div>
                      )}
                    </div>

                    <div className={"form-group mb-3"}>
                      <div className={"row"}>
                        <div className={"col-4"}>
                          <label className="required form-label">{intl.formatMessage({id:"Incoterm"})}</label>
                          <FormikSelect2
                            name="incoterm"
                            optionsUrl={`${API_URL}/api/masterdata/incoterm`}
                            unique_property={"id"}
                            search_property={"code_name"}
                            getOptionValue={(opt: any) => opt}
                            getOptionLabel={(opt: any) =>
                              `${opt.code}`
                            }
                            isDisabled={isDisabledForm}
                          />
                          {
                            (errors.incoterm_id && touched.incoterm_id) && (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{errors.incoterm_id}</div>
                              </div>
                            )
                          }
                        </div>
                        <Col>
                          <label className={"mb-2"} style={{
                            fontWeight: "450"
                          }}>{intl.formatMessage({id:"Lokasi Pengiriman / Pekerjaan"})}</label>
                          <Field
                            name="lokasi_pengiriman"
                            type="text"
                            className="form-control"
                            disabled={isDisabledForm}
                            label={"LOKASI_PENGIRIMAN_PEKERJAAN"} />
                          {
                            (errors.lokasi_pengiriman && touched.lokasi_pengiriman) && (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{errors.lokasi_pengiriman}</div>
                              </div>
                            )
                          }
                        </Col>
                      </div>
                    </div>

                    <div className={"form-group mb-3"}>
                      <FormikSelect3
                        label={intl.formatMessage({id:"Visibilitas Bid Opening"})}
                        name="bid_opening_validation"
                        optionsUrl={`${API_URL}/api/masterdata/enumeration?filter[type]=TENDER_VISIBILITAS`}
                        getOptionValue={(opt: any) => opt.value}
                        getOptionLabel={(opt: any) =>
                          `${opt.value}`
                        }
                        isDisabled={isDisabledForm}
                      />
                    </div>

                  </div>
                  <div className={"col-md-6 col-sm-12 col-lg-6"}>
                    {/*<div className={"form-group mb-3"}>*/}
                    {/*  <label className="form-label">*/}
                    {/*    Referensi Tender*/}
                    {/*  </label>*/}
                    {/*  <Field*/}
                    {/*    name="reference"*/}
                    {/*    type="text"*/}
                    {/*    className="form-control"*/}
                    {/*    label={"Incoterm"}*/}
                    {/*    disabled={isDisabledForm}*/}
                    {/*  />*/}
                    {/*  {*/}
                    {/*    (errors.reference && touched.reference) && (*/}
                    {/*      <div className="fv-plugins-message-container">*/}
                    {/*        <div className="fv-help-block">{errors.reference}</div>*/}
                    {/*      </div>*/}
                    {/*    )*/}
                    {/*  }*/}
                    {/*</div>*/}
                    <div className={"form-group mb-3"}>
                      <label className={"mb-2"} style={{
                        fontWeight: "450"
                      }}>{intl.formatMessage({id:"Buyer"})}</label>
                      <Field
                        name="buyer"
                        type="text"
                        className="form-control"
                        disabled={isDisabledForm}
                        label={"Buyer"} />
                      {
                        (errors.buyer && touched.buyer) && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{errors.buyer}</div>
                          </div>
                        )
                      }
                    </div>
                    <div className={"form-group mb-3"}>
                      <FormikSelect2
                        label={intl.formatMessage({id:"Purchasing Group"})}
                        name="purchasing_group"
                        optionsUrl={`${API_URL}/api/masterdata/purchasing-group?filter[purch_group_category]=barang&filter[comp_code]=${currentUser?.company_code}`}
                        getOptionValue={(opt: any) => opt}
                        getOptionLabel={(opt: any) =>
                          `${opt.purch_group_id} - ${opt.purch_group_name}`
                        }
                        unique_property={"id"}
                        search_property="purch_group_id_category_name"
                        isDisabled={isDisabledForm}
                      />
                      {(errors.purchasing_group_id && touched.purchasing_group_id) && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.purchasing_group_id}</div>
                        </div>
                      )}
                    </div>
                    <div className={"form-group mb-9"}>
                      <label className={"form-label"}>{intl.formatMessage({id:"Delivery Time"})}</label>
                      <Row className={"mt-2"}>
                        <Col><Field type={"radio"} disabled={isDisabledForm} name={"delivery_time"} value={30}
                                    checked={parseInt(values.delivery_time) === 30} /> 30 {intl.formatMessage({id:"Hari"})}</Col>
                        <Col><Field type={"radio"} disabled={isDisabledForm} name={"delivery_time"} value={45}
                                    checked={parseInt(values.delivery_time) === 45} /> 45 {intl.formatMessage({id:"Hari"})}</Col>
                        <Col><Field type={"radio"} disabled={isDisabledForm} name={"delivery_time"} value={60}
                                    checked={parseInt(values.delivery_time) === 60} /> 60 {intl.formatMessage({id:"Hari"})}</Col>
                        <Col><Field type={"radio"} disabled={isDisabledForm} name={"delivery_time"} value={90}
                                    checked={parseInt(values.delivery_time) === 90} /> 90 {intl.formatMessage({id:"Hari"})}</Col>
                        <Col>
                          <Field type={"radio"} disabled={isDisabledForm} name={"delivery_time"} value={0}
                                 checked={parseInt(values.delivery_time) === 0} />
                          <Field
                            type={"text"}
                            name={"delivery_time_others"}
                            className={"ms-2"}
                            style={{ width: "50px" }}
                            // disabled={true}
                            // disabled={values.masa_berlaku == '90' }
                            disabled={[30, 45, 60, 90].includes(parseInt(values.masa_berlaku)) }
                            // const invalidValue = [30, 45, 60, 90].includes(parseInt(values.masa_berlaku));
                            // return !invalidValue;
                          />
                        </Col>
                      </Row>
                      {(errors.delivery_time && touched.delivery_time) && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.delivery_time}</div>
                        </div>
                      )}
                    </div>

                    <div className={"form-group mb-9"}>
                      <label className={"form-label"}>{intl.formatMessage({id:"Masa Berlaku Penawaran"})}</label>
                      <Row className={"mt-2"}>
                        <Col><Field type={"radio"} disabled={isDisabledForm} name={"masa_berlaku"} value={30}
                                    checked={parseInt(values.masa_berlaku) === 30} /> 30 {intl.formatMessage({id:"Hari"})}</Col>
                        <Col><Field type={"radio"} disabled={isDisabledForm} name={"masa_berlaku"} value={45}
                                    checked={parseInt(values.masa_berlaku) === 45} /> 45 {intl.formatMessage({id:"Hari"})}</Col>
                        <Col><Field type={"radio"} disabled={isDisabledForm} name={"masa_berlaku"} value={60}
                                    checked={parseInt(values.masa_berlaku) === 60} /> 60 {intl.formatMessage({id:"Hari"})}</Col>
                        <Col><Field type={"radio"} disabled={isDisabledForm} name={"masa_berlaku"} value={90}
                                    checked={parseInt(values.masa_berlaku) === 90} /> 90 {intl.formatMessage({id:"Hari"})}</Col>
                        <Col>
                          <Field type={"radio"} disabled={isDisabledForm} name={"masa_berlaku"} value={0}
                                 checked={parseInt(values.masa_berlaku) === 0} />
                          <Field
                            type={"text"}
                            name={"masa_berlaku_others"}
                            className={"ms-2"}
                            style={{ width: "50px" }}
                            // disabled={true}
                            // disabled={values.masa_berlaku == '90' }
                            disabled={[30, 45, 60, 90].includes(parseInt(values.masa_berlaku)) }
                            // const invalidValue = [30, 45, 60, 90].includes(parseInt(values.masa_berlaku));
                            // return !invalidValue;
                          />
                        </Col>
                      </Row>
                      {(errors.masa_berlaku && touched.masa_berlaku) && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.masa_berlaku}</div>
                        </div>
                      )}
                    </div>

                    <div className={"form-group mb-5"}>
                      <label className={"form-label"}>{intl.formatMessage({id:"Jaminan Penawaran (Bid Bond)"})}</label>
                      <Row className={"mt-2"}>
                        <Col>
                          <div className={"d-flex flex-row gap-2 align-items-center"}>
                            <Field type={"radio"} disabled={isDisabledForm} name={"bid_bond"} value={true}
                                   checked={parseStringToBool(values.bid_bond)} /> {intl.formatMessage({id:"Ya"})}
                            <Field type={"text"} name={"bid_bond_value"} style={{
                              maxWidth: "50px"
                            }}
                                   onChange={(e:any)=>handleOnChangeInputJaminanPenawaran(e, setFieldValue)}
                                   disabled={!parseStringToBool(values.bid_bond) || isDisabledForm} />
                          </div>
                        </Col>
                        <Col><Field type={"radio"} disabled={isDisabledForm} name={"bid_bond"} value={false}
                                    checked={!parseStringToBool(values.bid_bond)} /> {intl.formatMessage({id:"Tidak"})}</Col>
                      </Row>
                      {(errors.bid_bond && touched.bid_bond) && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.bid_bond}</div>
                        </div>
                      )}
                    </div>
                    <div className={"form-group mb-9"}>
                      <label className={"form-label"}>{intl.formatMessage({id:"TKDN"})}</label>
                      <Row className={"mt-2"}>
                        <Col><Field type={"radio"} disabled={isDisabledForm} name={"tkdn"} value={true}
                                    checked={parseStringToBool(values.tkdn)} /> {intl.formatMessage({id:"Ya"})}</Col>
                        <Col><Field type={"radio"} disabled={isDisabledForm} name={"tkdn"} value={false}
                                    checked={!parseStringToBool(values.tkdn)} /> {intl.formatMessage({id:"Tidak"})}</Col>
                      </Row>
                      {(errors.tkdn && touched.tkdn) && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.tkdn}</div>
                        </div>
                      )}
                    </div>
                    <div className={"form-group mb-3"}>
                      <label className={"form-label"}>{intl.formatMessage({id:"Lingkup Pekerjaan"})}</label>
                      <Field as={"textarea"} disabled={isDisabledForm} className={"form-control"} name={"lingkup_pekerjaan"}></Field>
                    </div>
                    {/*<div className={"form-group mb-3"}>*/}
                    {/*  <label className={"form-label"}>Note Internal</label>*/}
                    {/*  <Field as={"textarea"} disabled={isDisabledForm} className={"form-control"} name={"internal_note"}></Field>*/}
                    {/*</div>*/}
                    <div className={"form-group"}>
                      <label className={"form-label"}>{intl.formatMessage({id:"External Note"})}</label>
                      <Field as={"textarea"} disabled={isDisabledForm} className={"form-control"} name={"external_note"}></Field>
                    </div>

                  </div>
                  {/*  End Col-6 */}
                </div>

              </div>
            </div>
            {/*{JSON.stringify(errors)}*/}
            {/*  End Row */}
          </Form>
        )}
      </Formik>
      <ApprovalX
        show={isShowApprovalSchema}
        schema={approvalSchema}
        onHide={() => {
          setIsShowApprovalSchema(false)
        }}
        onSubmit={(data: any) => {
          handleOnSubmitApproval(data)
        }}
        isLoading={isLoadingSubmitTender}
        source={"TENDER"}
        object_id={props.proposalTender?.id}
        company_id={props.proposalTender?.company?.id}
        code={props.proposalTender?.routing_approval_config_code}
        readOnly={props.proposalTender?.is_on_approval}
      />

    </div>

  );
});
export default ParameterPengadaanPage;