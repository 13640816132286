import React, { useEffect, useState } from "react";
import GambaranUmumSatuSampulTab from "./GambaranUmumSatuSampulTab";
import {AdministrasiTeknisSatuSampulTab} from "./AdministrasiTeknisSatuSampulTab";
import { ResumeEvaluasiTab } from "../ResumeEvaluasiTab";
import { Nav, Tabs } from "rsuite";
import BottomToolbarWrapper from "../../../../../../../components/shared/BottomToolbarWrapper";
import { useAxiosPost } from "../../../../../../../../hooks/useAxios";
import { getEnv } from "../../../../../../../../helpers/procurex";
import { Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import {BeritaAcaraTab} from "../BeritaAcaraTab";
import { useIntl } from "react-intl";
import axios from "axios";
import {useParams} from 'react-router-dom'
import {useGetDetailProposalTender} from '../../../../hooks/proposalTenderHook'
import KlarifikasiVendorTab from './KlarifikasiVendorTab'
import {EvaluasiUserTab} from './EvaluasiUserTab'
import {ToolbarInterface, useProposalTender} from '../../../core/_proposalTenderProvider'


const EvaluasiTeknisSatuSampulTab = ({proposalTender,onQuotationChanged}:any) => {
  const [selectedTab, setSelectedTab] = useState("");
  const [menuTab, setMenuTab] = useState([] as any);
  const intl = useIntl();
  const  [{ doSubmit, isLoading }] = useAxiosPost(
    getEnv("tender") + `/evaluation/administration/syarat`
  )
  const [isLoadingCheck, setIsLoadingCheck] = useState(false);
  const { uuid } = useParams();
  const [{ doGetDetail, data: currentPropoposalTender }] = useGetDetailProposalTender();

  const checkIsAdministrasiTeknisEnabled = (_proposalTender) => {
    const allowedStatus = [
      "Evaluasi Teknis",
      "Evaluasi Administrasi",
      "Negotiation",
      "Awarding",
      "Laporan Pengadaan",
      "Pengumuman Pemenang",
      "Penunjukan Pemenang",
      "Purchase Order",
    ];
    return allowedStatus.includes(_proposalTender?.status);
  }

  const isGambaranUmumDisabled = (_proposalTender) => {
    const disabledStatus = ["Draft","DUR"];
    return disabledStatus.includes(_proposalTender?.status);
  }

  const initiateTab = (_proposalTender) => {
    const tmp = [
      { eventKey: "gambaran-umum", label: intl.formatMessage({id:"Gambaran Umum"}), disabled: isGambaranUmumDisabled(_proposalTender) },
      { eventKey: "administrasi-teknis", label: intl.formatMessage({id:"Administrasi, Teknis & Komersil"}), disabled: !checkIsAdministrasiTeknisEnabled(_proposalTender) },
      { eventKey: "evaluasi", label: intl.formatMessage({id:"Resume Evaluasi"}), disabled: !checkIsAdministrasiTeknisEnabled(_proposalTender) },
      { eventKey: "berita-acara", label: intl.formatMessage({id:"Berita Acara"}) },
    ]
    let tmpSelectedTab = "";
    tmp.forEach((item:any) => {
      if(!item?.disabled && tmpSelectedTab === ""){
        tmpSelectedTab = item.eventKey;
        setSelectedTab(item.eventKey);
        return false;
      }
    })
    setMenuTab(tmp);
  }

  const showTab = () => {
    switch (selectedTab) {
      case "gambaran-umum":
        return <GambaranUmumSatuSampulTab proposalTender={proposalTender} />;
      case "administrasi-teknis":
        return <AdministrasiTeknisSatuSampulTab proposalTender={proposalTender} />;
      case "evaluasi":
        return <ResumeEvaluasiTab proposalTender={proposalTender} />;
      case "berita-acara":
        return <BeritaAcaraTab proposalTender={proposalTender} />;
      default:
        return <></>;
    }
  }

  const isShowBidOpening = () => {
    return proposalTender?.status === "Bid Opening" &&
      (proposalTender?.status_detail === "Bid Opening" ||
       proposalTender?.status_detail === "Rejected");
  }

  const isShowTutupEvaluasiAdministrasi = () => {
    return proposalTender?.status === "Evaluasi Administrasi" &&
      proposalTender?.status_detail === "On Going";
  }

  const onBidOpening = () => {
    Swal.fire({
      title: intl.formatMessage({id:"Apakah Anda yakin"})+"?",
      text: intl.formatMessage({id:"Anda akan membuka penawaran"}),
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Ya, submit",
      cancelButtonText: "Tidak"
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        doSubmit(
          {},
          getEnv("tender") + `/bid/submit/${proposalTender.uuid}`,
          toast
        ).then((res: any) => {
          onQuotationChanged && onQuotationChanged();
        }).finally(() => {
        });
      } else {
      }
    });
  }

  const checkIsAllEvaluate = async() => {
    setIsLoadingCheck(true);
    let state = true;
    const {data} = await axios.get(getEnv("tender") + `/evaluation/quotation/${proposalTender.uuid}?perPage=1000`)
    data?.data?.forEach((item:any) => {
      if(item?.vendor?.status_evaluation === "NOT-EVALUATE"){
        state = false;
      }
    });
    setIsLoadingCheck(false);
    return state;
  }

  const onTutupEvaluasiAdministrasi = async() => {
    const isValid = await checkIsAllEvaluate();
    if(!isValid){
      toast.error(intl.formatMessage({id:"Evaluasi vendor belum lengkap."}))
      return false;
    }
    Swal.fire({
      title: intl.formatMessage({id:"Apakah Anda yakin"})+"?",
      text: intl.formatMessage({id:"Anda akan menutup evaluasi"}),
      icon: "question",
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({id:"Ya, Submit"}),
      cancelButtonText: intl.formatMessage({id:"Tidak"})
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        // Submit evaluasi vendor
        doSubmit(
          {},
          getEnv("tender") + `/evaluation/submit/${proposalTender.uuid}`,
          toast
        ).then((res: any) => {
          // submit evaluasi administrasi
        }).finally(() => {
          onQuotationChanged && onQuotationChanged();
        });
      } else {
        onQuotationChanged && onQuotationChanged();
      }
    });
  }



  useEffect(() => {
    doGetDetail(uuid,undefined).then((resProposalTender:any)=>{

    })
  }, []);

  useEffect(() => {
    initiateTab(currentPropoposalTender)
  }, [currentPropoposalTender])

  return (
    <>
      <div className={""}>

          <div className={"d-flex justify-content-between"}>
            <div><h3>{intl.formatMessage({id:"Evaluasi Tender"})}</h3></div>
          </div>
          <hr />

          <Nav appearance={"subtle"} justified activeKey={selectedTab} onSelect={(e:any)=>{setSelectedTab(e)}} style={{ marginBottom: 20 }}>
            {menuTab.map((item:any, index:number) => {
              return (
                <Nav.Item eventKey={item.eventKey} key={index} disabled={item?.disabled} className={"d-flex flex-row justify-content-center"}>
                  {item.label}
                </Nav.Item>
              );
            })}
          </Nav>

          {showTab()}


          <BottomToolbarWrapper children={
            <>
            {isShowBidOpening() && (
              <button
                type={"button"}
                disabled={isLoading}
                className={"btn btn-primary me-3"}
                onClick={() => onBidOpening()}
              >
                {isLoading ? (
                  <Spinner animation="border" role="status" size={"sm"} />
                ) : (
                  <i className={"fa fa-envelope-open"}></i>
                )}
                { proposalTender?.metode_penyampaian === '1 Sampul' ?
                  intl.formatMessage({id:"Pembukaan Penawaran"})
                  : proposalTender?.metode_penyampaian_step === 0
                    ? intl.formatMessage({id:"Pembukaan Penawaran Teknis"})
                    : intl.formatMessage({id:"Pembukaan Penawaran Komersil"})
                }              
                </button>
              )}

              {isShowTutupEvaluasiAdministrasi() && (
                <>

                  <button
                    type={"button"}
                    disabled={isLoading}
                    className={"btn btn-primary me-3"}
                    onClick={onTutupEvaluasiAdministrasi}
                  >
                    {(isLoading && isLoadingCheck) ? (
                      <Spinner animation="border" role="status" size={"sm"} />
                    ) : (
                      <i className={"fa fa-envelope"}></i>
                    )}
                    {intl.formatMessage({ id: "Tutup Evaluasi Penawaran" })}
                  </button>
                </>
              )}
            </>
          } />


      </div>
    </>
  );
};

const EvaluasiTeknisSatuSampulPage = ({proposalTender,onQuotationChanged}:any) => {
  const intl = useIntl();
  const [selectedTab, setSelectedTab] = useState("1")
  const {toolbarEvaluasi} = useProposalTender()

  return (
    <>
      {toolbarEvaluasi?.currentTab === 0 && (
        <EvaluasiTeknisSatuSampulTab
          proposalTender={proposalTender}
          onQuotationChanged={onQuotationChanged}
        />
      )}

      {toolbarEvaluasi?.currentTab === 1 && (
        <EvaluasiUserTab
          proposalTender={proposalTender}
        />
      )}

      {toolbarEvaluasi?.currentTab === 2 && (
        <KlarifikasiVendorTab
          proposalTender={proposalTender}
        />
      )}
    </>
  )
}

export default EvaluasiTeknisSatuSampulPage
