import React, { useEffect, useRef, useState } from "react";
import TableXWrpper from "../../../../../components/shared/TableXWrapper";
import { Modal, Spinner } from "react-bootstrap";
import {  getEnv } from "../../../../../../helpers/procurex";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const ModalFormPanitiaPengadaan = ({ auction, detailData, isModalOpen, setIsModalOpen, isEdit, onAfterSave }: any) => {
  const validationSchema = Yup.object({
    full_name: Yup.string().required("Nama harus diisi"),
    jabatan: Yup.string().required("Jabatan harus diisi"),
    departemen: Yup.string().required("Departemen harus diisi"),
  });
  const [indexForm, setFormIndex] = useState(0)
  const formik = useRef({} as any)

  const initialValue = {
    full_name: "",
    jabatan: "",
    departemen: "",
  }
  const [initialValues, setInitialValues] = useState(initialValue)

  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const onSubmit = async (values: any, { resetForm }: any) => {
    setIsLoadingSave(true);
    try {
      let data = { auction_id: auction?.id, ...values };
      const response: any = (isEdit) ? await axios.put(`${getEnv('auction')}/panitia-pengadaan/${detailData?.id}`, data) : await axios.post(`${getEnv('auction')}/panitia-pengadaan`, data);
      if (response?.status === 201 || response?.status === 200) {
        toast.success(response?.status + ": " + response?.data.meta.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light"
        });
        onAfterSave && onAfterSave();
        return true;
      }
    } catch (error) {
      setIsLoadingSave(false);
      console.error(error);
    } finally {
      // setKey(key + 1);

      setIsLoadingSave(false);

    }
  };


  useEffect(() => {
    if (detailData) {
      setInitialValues({
        full_name: detailData?.full_name,
        jabatan: detailData?.jabatan,
        departemen: detailData?.departemen,
      })
    } else {
      setInitialValues(initialValue)
    }
  }, [detailData])

  return (
    <>

      <Modal
        key={'modal-' + indexForm}
        show={isModalOpen}
        size="lg"
        onHide={() => {
          setFormIndex(indexForm + 1)
          setInitialValues(initialValue)
          setIsModalOpen(false);
        }}>
        <Formik
          key={indexForm}
          innerRef={formik}
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}>
          {({ errors, touched, values, setFieldValue, handleChange, submitForm }: any) => (
            <>
              <Form>
                <Modal.Header closeButton>
                  <Modal.Title>{isEdit ? 'Edit' : 'Tambah'} Panitia Pengadaan</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className={"form-group mb-4"}>
                    <label className="required form-label mb-3">Nama Panitia</label>
                    <Field
                      type={"text"}
                      className="form-control"
                      name="full_name"
                      required
                    />
                    {(errors.full_name && touched.full_name) && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{errors.full_name}</div>
                      </div>
                    )}
                  </div>

                  <div className={"form-group mb-4"}>
                    <label className="required form-label mb-3">Jabatan</label>
                    <Field
                      type={"text"}
                      className="form-control"
                      name="jabatan"
                      required
                    />
                    {(errors.jabatan && touched.jabatan) && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{errors.jabatan}</div>
                      </div>
                    )}
                  </div>
                  <div className={"form-group mb-4"}>
                    <label className="required form-label mb-3">Departemen</label>
                    <Field
                      type={"text"}
                      className="form-control"
                      name="departemen"
                      required
                    />
                    {(errors.departemen && touched.departemen) && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{errors.departemen}</div>
                      </div>
                    )}
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    className="btn btn-secondary btn-sm"
                    onClick={() => {
                      setIsModalOpen(false);
                    }}
                    type={"button"}
                  >
                    <i className="fa fa-times"></i> Tutup
                  </button>
                  <button
                    className="btn btn-primary btn-sm"
                    type={"submit"}
                    disabled={isLoadingSave}
                    onClick={() => {
                      submitForm();
                    }}
                  >
                    {isLoadingSave ? (
                      <Spinner animation="border" variant="light" size="sm" />
                    ) : (
                      <i className="fa fa-save"></i>
                    )}{" "}
                    Simpan
                  </button>
                </Modal.Footer>
              </Form>
            </>
          )}
        </Formik>
      </Modal>

    </>
  );
};

const PanitiaPengadaanTab = ({ auction, tabulasi, onSave }: any) => {
  const [tableKey, setTableKey] = useState(0);
  const [urlIndex, setUrlIndex] = useState(getEnv("auction") + "/panitia-pengadaan?auction_id=" + auction?.id);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedData, setSelectedData] = useState<any>(null);
  const tableColumns = [
    {
      Header: "Action",
      width: 100,
      className: "text-center",
      Cell: (props: any) => {
        const data = props?.cell?.row?.original;
        // @ts-ignore
        return (
          <>
            {isCreateEnabled() && (
              <>
                <a href={"#"} className={"me-2"} onClick={(e: any) => {
                  e.preventDefault();
                  setSelectedData(JSON.parse(JSON.stringify(data)));
                  setIsModalOpen(true);
                  setIsEdit(true);
                }}>
                  <i className="fa fa-edit fs-3"></i>
                </a>
                <a href={"#"} onClick={(e: any) => {
                  e.preventDefault();
                  handleDelete(data?.id);
                }}>
                  <i className="fa fa-trash fs-3"></i>
                </a>
              </>
            )}

          </>
        )
      }
    },
    { Header: "Nama", accessor: "full_name", width: 250, className: "text-start" },
    { Header: "Jabatan", accessor: "jabatan", width: 250, className: "text-start" },
    { Header: "Departemen", accessor: "departemen", width: 250, className: "text-start" },
  ];

  const handleDelete = async (id: any) => {
    Swal.fire({
      title: "Apakah Anda Yakin?",
      text: "Anda akan menghapus data ini!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, Hapus",
      cancelButtonText: "Tidak"
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        try {
          const response: any = await axios.delete(`${getEnv('auction')}/panitia-pengadaan/${id}`)
          if (response.status === 200) {
            toast.success(response?.data?.meta?.message, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            })
            setTableKey(tableKey + 1);
            onSave && onSave();
          }
        } catch (error) {
        } finally {
        }
      } else {
      }
    });
  }

  const isCreateEnabled = () => {
    return tabulasi?.status === "done"
  }

  return (
    <div className={"p-2"}>
      <TableXWrpper
        key={tableKey}
        title={"Item Auction"}
        urlIndex={urlIndex}
        columns={tableColumns}
        readonly
        disableUrlChanged={true}
        disablePaddingCard={true}
        headerButtons={
          <>
            {/* {auction?.no_tender == null && */}
              <button
                className="btn btn-primary btn-sm"
                type={"button"}
                disabled={!isCreateEnabled()}
                onClick={() => {
                  // onSave && onSave();
                  setSelectedData(null)
                  setIsEdit(false)
                  setIsModalOpen(true)
                }}
              >
                <i className="fa fa-plus"></i> Tambah Panitia Pengadaan
              </button>
            {/* } */}
          </>
        }
      />

      <ModalFormPanitiaPengadaan
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        id={0}
        setTableKey={setTableKey}
        auction={auction}
        isEdit={isEdit}
        onAfterSave={() => {
          onSave && onSave();
          setIsModalOpen(false);
          setTableKey(tableKey + 1);
        }}
        detailData={selectedData}
      />
    </div>
  );
};

export { PanitiaPengadaanTab };
