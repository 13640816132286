import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import { Nav, Placeholder } from "rsuite";
import { useAxiosGet } from "../../../../../../hooks/useAxios";
import { getEnv, getSizeInBytes } from "../../../../../../helpers/procurex";
import PreviewAttachment from "../../../../../components/PreviewAttachment";

interface EvaluasiAdministrasiTabProps {
  proposalTender: any;
  evaluation: any;
  note?: string;
  attachment?: any;
  attachmentUrl?: any;
}

const StatusBadge = ({status}:any) => {
  const intl = useIntl();
  return (
    <>
      {status === "PASSED" && (
        <>
          <i className={"fa fa-check-circle text-success"}></i> {intl.formatMessage({ id: status })}
        </>
      )}
      {status !== "PASSED" && (
        <>
          <>
            <i className={"fa fa-times-circle text-danger"}></i> {intl.formatMessage({ id: status })}
          </>
        </>
      )}
    </>
  )
}

const Administrasi = ({proposalTender, evaluation, note, attachment, attachmentUrl}: EvaluasiAdministrasiTabProps) => {
  const intl = useIntl();
  const [previewFile, setPreviewFile] = useState(null as any);

  const isPaket = () => {
    return proposalTender?.order_placement === "Paket";
  };

  const isItemize = () => {
    return proposalTender?.order_placement === "Itemize"
  }

  return (
    <>
      <div className={"table-responsive"}>
        <table className={"table table-header-gray"}>
          <thead>
          <tr>
            <th style={{ width: "40px" }} className={"text-center"}>{intl.formatMessage({ id: "No" })}</th>
            <th>{intl.formatMessage({ id: "Deskripsi" })}</th>
            <th className={"text-center"} style={{ width: 200 }}>{intl.formatMessage({ id: "Hasil Evaluasi" })}</th>
          </tr>
          </thead>
          <tbody>
            {evaluation?.map((item: any, index: number) => {
              if (isItemize()) {
                return (
                  <tr key={index}>
                    <td className={"text-center"}>{index + 1}</td>
                    <td>{item?.description}</td>
                    <td className={"text-center"}>
                      <StatusBadge status={item?.status || "FAILED"} />
                    </td>
                  </tr>
                );
              } else if (isPaket()) {
                return (
                  <tr key={index}>
                    <td className={"text-center"}>{index + 1}</td>
                    <td>{item?.description}</td>
                    <td className={"text-center"}>
                      <StatusBadge status={item?.status || "FAILED"} />
                    </td>
                  </tr>
                );
              }
              return null;
            })}
          </tbody>
        </table>
        <div className={"form-group"}>
          <label>{intl.formatMessage({ id: "Catatan" })}</label>
          <textarea
            className={"form-control"}
            disabled={true}
          >{note}</textarea>
        </div>
        {attachmentUrl && (
          <div className={"form-group mt-10"}>
            <label>{intl.formatMessage({ id: "Lampiran" })}</label>
            <div
              className={"mt-2 mb-1 alert alert-info bg-light-info px-2 py-2 w-100 d-flex align-items-center justify-content-between"}
              style={{
                borderRadius: 0,
                border: "none"
              }}>
              <div>
                <a href={"#"} className={""} onClick={(e: any) => {
                  e.preventDefault();
                  setPreviewFile(attachmentUrl);
                }}>
                  <strong className={"text-info"}>&nbsp;</strong>
                  <strong>{attachment || 'Unknown'}</strong>
                </a>
              </div>
            </div>
          </div>
        )}

      </div>
      <PreviewAttachment previewExt={previewFile} onHide={() => setPreviewFile(null)} />
    </>
  )
}

const Resume = ({ proposalTender, evaluation, note }: EvaluasiAdministrasiTabProps) => {
  const intl = useIntl();
  const getBackground = (status: any) => {
    return "";
    // if(status === "PASSED"){
    //   return "bg-success";
    // } else {
    //   return "bg-danger";
    //
    // }
  };
  return (
    <>
      <div className={"table-responsive"}>
        <table className={"table table-header-gray"}>
          <thead>
          <tr>
            <th className={"text-center"}>{intl.formatMessage({ id: "Administrasi" })}</th>
            <th className={"text-center"}>{intl.formatMessage({ id: "Teknis" })}</th>
            <th className={"text-center"}>{intl.formatMessage({ id: "Komersil" })}</th>
            <th className={"text-center"}>{intl.formatMessage({ id: "Hasil Akhir" })}</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td className={`text-center ${getBackground(evaluation?.["administration"])}`}>
              <StatusBadge
                status={evaluation?.["administration"]}
              />
            </td>
            <td className={`text-center ${getBackground(evaluation?.["technical"])}`}>
              <StatusBadge status={evaluation?.["technical"]} />
            </td>
            <td className={`text-center ${getBackground(evaluation?.["commercial"])}`}>
              <StatusBadge status={evaluation?.["commercial"]} />
            </td>
            <td className={`text-center ${getBackground(evaluation?.["final"])}`}>
              <StatusBadge status={evaluation?.["final"]} />
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

interface ModalEvaluasiProps {
  proposalTender: any;
  show: boolean;
  onClose: Function;
}

const ModalEvaluasi = (props: ModalEvaluasiProps) => {
  const intl = useIntl();
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosGet(
    getEnv("tender") + `/vendor/published/evaluation/${props.proposalTender?.uuid}`
  );
  const [isShowModal, setIsShowModal] = useState(false);
  const [menuTab, setMenuTab] = useState([] as any);
  const [evaluation, setEvaluation] = useState({} as any)
  const [selectedTab, setSelectedTab] = useState("evaluasi-administrasi");

  useEffect(() => {
    setIsShowModal(props.show);
    if(props.show){
      doSubmit({},undefined,undefined).then((res:any)=>{
        setEvaluation(res.data);
      }).catch((err:any)=>{

      });
    }
  }, [props.show]);

  const handleClose = () => {
    setIsShowModal(false);
    props.onClose();
  }

  const generateTab = () => {
    const tab = [
      { eventKey: "evaluasi-administrasi", label: intl.formatMessage({id:"Evaluasi Administrasi"}) },
      { eventKey: "evaluasi-teknis", label: intl.formatMessage({id:"Evaluasi Teknis"}) },
      { eventKey: "evaluasi-komersil", label: intl.formatMessage({id:"Evaluasi Komersil"}) },
      { eventKey: "resume", label: intl.formatMessage({id:"Resume"}) },
    ]
    setMenuTab(tab);
  }

  useEffect(() => {
    generateTab();
  }, []);

  return (
    <>
      <Modal show={isShowModal} onHide={() => handleClose()} size={"xl"}>
        <Modal.Header closeButton>
          <Modal.Title>{intl.formatMessage({id:"Hasil Evaluasi"})}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading && (
            <Placeholder.Grid rows={5} columns={4} active={isLoading}>

            </Placeholder.Grid>
          )}
          {!isLoading && (
            <>
              <Nav
                appearance={"subtle"}
                activeKey={selectedTab}
                onSelect={(e:any)=>{setSelectedTab(e)}}
                style={{ marginBottom: 20 }}
                className={"d-flex align-items-center justify-content-between"}
              >
                {menuTab.map((item:any, index:number) => {
                  return (
                    <Nav.Item eventKey={item.eventKey} key={index}>
                      {item.label}
                    </Nav.Item>
                  );
                })}
              </Nav>
              {selectedTab === "evaluasi-administrasi" && (
                <Administrasi
                  proposalTender={props.proposalTender}
                  evaluation={evaluation?.['administration']}
                  note={evaluation?.['notes']?.find((item:any)=>item?.type === "Evaluasi Administrasi")?.note||""}
                  attachment={evaluation?.['attachments']?.find((item:any)=>item?.type === "Evaluasi Administrasi")?.description||""}
                  attachmentUrl={evaluation?.['attachments']?.find((item:any)=>item?.type === "Evaluasi Administrasi")?.url||""}
                />
              )}
              {selectedTab === "evaluasi-teknis" && (
                <Administrasi
                  proposalTender={props.proposalTender}
                  evaluation={evaluation?.['technical']}
                  note={evaluation?.['notes']?.find((item:any)=>item?.type === "Evaluasi Teknikal")?.note||""}
                  attachment={evaluation?.['attachments']?.find((item:any)=>item?.type === "Evaluasi Teknikal")?.description||""}
                  attachmentUrl={evaluation?.['attachments']?.find((item:any)=>item?.type === "Evaluasi Teknikal")?.url||""}
                />
              )}
              {selectedTab === "evaluasi-komersil" && (
                <Administrasi
                  proposalTender={props.proposalTender}
                  evaluation={evaluation?.['commercial']}
                  note={evaluation?.['notes']?.find((item:any)=>item?.type === "Evaluasi Komersil")?.note||""}
                  attachment={evaluation?.['attachments']?.find((item:any)=>item?.type === "Evaluasi Komersil")?.description||""}
                  attachmentUrl={evaluation?.['attachments']?.find((item:any)=>item?.type === "Evaluasi Komersil")?.url||""}
                />
              )}
              {selectedTab === "resume" && (
                <>
                  <Resume
                    proposalTender={props.proposalTender}
                    evaluation={evaluation?.['resume']}
                  />
                </>
              )}
            </>
          )}

        </Modal.Body>
        <Modal.Footer>
          <button className={"btn btn-sm btn-danger"} onClick={() => handleClose()} disabled={isLoading}>
            <i className={"fa fa-times"}></i>
            {intl.formatMessage({id:"Tutup"})}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ModalEvaluasi;