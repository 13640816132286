import { Button, Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { usePostingPRToAvailable } from "../../hooks/proposalTenderHook";
import FormDisplayAttachmentPRItemTender from "./FormDisplayAttachmentPRItemTender";
import { Nav, Popover, Whisper } from "rsuite";
import { TrTableDisposisi } from "./ButtonToolbarDisplayPRIncoming";
import { useAxiosGet } from "../../../../../../hooks/useAxios";
import { getEnv } from "../../../../../../helpers/procurex";

const GetItemPRSpeakerText = ({row, lineNo}:any)=>{
  const [selectedDetail, setSelectedDetail] = useState<any>({});
  const [{ doSubmit, isLoading: isLoadingGetDetail }] = useAxiosGet(
    getEnv("tender")+`/purchase-requisition/${row?.purchaseRequisitionItem?.purchaseRequisition?.uuid}`
  )

  useEffect(() => {
    doSubmit({},undefined,undefined).then((res:any)=>{
      const items = res?.data?.items?.filter((i:any)=>i?.line_no === lineNo)
      const poText = items[0]?.poTexts?.filter((i:any)=>i?.line_no === lineNo)
      setSelectedDetail(poText[0])
    })
  }, [doSubmit, lineNo]);

  return (
    <>
      {isLoadingGetDetail && (<i className={"fa fa-spin fa-spinner"}></i>)}
      <p style={{whiteSpace: "pre-wrap"}}>
        {selectedDetail?.text || '-'}
      </p>
    </>
  )
}

const FormDisplayPRDetail = ({ show, closeModal, selectedRows, onSuccessCreate }: any) => {
  const [selectedTab, setSelectedTab] = React.useState("tab1");
  const [{ isLoading }] = usePostingPRToAvailable();

  const speakerPopover = (row:any) => {
    return (
      // <Popover title={"No: "+row?.no}>
      <Popover title="Header Text">
        <p>
          <span className={"text-muted"} style={{whiteSpace: "pre-wrap"}}>
            {row?.text_pr_header}
          </span>
        </p>
      </Popover>
    )
  }

  const speakerPopoverItemText = (row:any,lineNo:any) => {

    return (
      <Popover title="Material PO Text">
        <p>
          <span className={"text-muted"} style={{whiteSpace: "pre-wrap"}}>
            <GetItemPRSpeakerText row={row} lineNo={lineNo}/>
          </span>
        </p>
      </Popover>
    )
  }

  return (
    <>
      <Modal show={show} size={"xl"}>
        <Modal.Header closeButton onHide={() => closeModal(false)}>
          <Modal.Title>Tampilkan PR</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Nav appearance={"subtle"} justified={true} className={"text-center"} activeKey={selectedTab} onSelect={(e: any) => {
            setSelectedTab(e);
          }} style={{ marginBottom: 20 }}>
            <Nav.Item eventKey={"tab1"} className={"d-flex align-items-center justify-content-center"}>
              PR Item
            </Nav.Item>
            <Nav.Item eventKey={"tab2"} className={"d-flex align-items-center justify-content-center"}>
              Document PR
            </Nav.Item>
            <Nav.Item eventKey={"tab3"} className={"d-flex align-items-center justify-content-center"}>
              Riwayat Disposisi
            </Nav.Item>
          </Nav>
          {selectedTab === "tab1" && (
            <div className={"table-responsive"} style={{
              maxHeight: "500px"
            }}>
              <table className="table table-header-gray">
                <thead>
                <tr>
                  <th className={"text-center px-2"}>No</th>
                  <th className={"text-center px-2"}>Header PR SAP</th>
                  <th className={"text-center px-2"}>Material PO Text</th>
                  <th className={"text-center"}>Purch. Group</th>
                </tr>
                </thead>
                <tbody>
                  <tr key={"pr-modal-" + selectedRows?.id}>
                    <td className={"text-center px-2"}>{1}</td>
                    <td className={"text-left"}>
                      <div>
                        <b>Nomor PR: </b><i className="text-highlight">{selectedRows?.no}</i>
                      </div>
                      <div
                      dangerouslySetInnerHTML={{
                        __html: selectedRows?.purchaseRequisitionItem?.purchaseRequisition?.text?.replace(/\n/g, "<br />"),
                      }}></div>
                    </td>
                    <td className={"text-left"}>
                      <div>
                        <b>Item:</b> <i className="text-highlight">{selectedRows?.line_no}</i>
                      </div>
                      <div>
                        <b>Short Text:</b> <i className="text-highlight">{selectedRows?.short_text}</i>
                      </div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: selectedRows?.purchaseRequisitionItem?.poTexts[0]?.text?.replace(/\n/g, "<br />"),
                        }}
                      ></div>
                    </td>
                    {/* <td>{row?.original?.line_no}</td> */}
                    <td className={"text-center"}>{selectedRows?.purchaseRequisitionItem?.purchasing_group_id}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
          {selectedTab === "tab2" && (
            <FormDisplayAttachmentPRItemTender selectedRows={selectedRows} />
          )}
          {selectedTab === "tab3" && (
            <TrTableDisposisi
              key={"tr-dispoisisi-notes-" + selectedRows?.id}
              row={selectedRows}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            onClick={() => {
              closeModal(false);
            }}
            disabled={isLoading}
            className="btn btn-danger btn-sm">
            <i className="fa fa-times"></i>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>


    </>
  );
};

// @ts-ignore
export default FormDisplayPRDetail;