import React, { useEffect, useRef, useState } from "react";
import TableXWrpper from "../../../../../components/shared/TableXWrapper";
import { Modal, Spinner } from "react-bootstrap";
import { formatNumber, formatNumberToDecimalAuction, getEnv, multiplyNumber, parseNumberToCurrency } from "../../../../../../helpers/procurex";
import { Field, Form, Formik } from "formik";
import FormikSelect2 from "../../../../../components/shared/form/FormikSelect2";
import CurrencyInput from "react-currency-input-field";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const ModalFormItemAuction = ({ auction, detailData, isModalOpen, setIsModalOpen, isEdit, onAfterSave }: any) => {
  const validationSchema = Yup.object({
    nama: Yup.string().required("Nama harus diisi"),
    kuantitas: Yup.string().required("Kuantitas harus diisi"),
    mata_uang: Yup.object().required("Mata Uang harus diisi").nullable(),
    satuan: Yup.object().required("Satuan harus diisi").nullable(),
    unit_price_tertinggi: Yup.string().required("Unit Price Tertinggi harus diisi"),
    unit_price_terendah: Yup.string().required("Unit Price Terendah harus diisi"),
    unit_price: Yup.string().required("Unit Price harus diisi"),
    note: Yup.string().required("Note harus diisi")
  });
  let [key, setKey] = useState(0)
  const [indexForm, setFormIndex] = useState(0)
  const formik = useRef({} as any)

  let initialValue = {
    nama: "",
    kuantitas: "",
    mata_uang: null,
    satuan: null,
    unit_price_tertinggi: "",
    unit_price_terendah: "",
    unit_price: "",
    subtotal: "",
    note: ""
  }
  const [initialValues, setInitialValues] = useState(initialValue)

  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const isAuctionFromTender = () => {
    return auction?.no_tender != null
  }
  const onSubmit = async (values: any, { resetForm }: any) => {
    setIsLoadingSave(true);
    try {
      let data = { auction_id: auction?.id, ...values };
      data.unit_price_tertinggi = formatNumberToDecimalAuction(data.unit_price_tertinggi, 2);
      data.unit_price_terendah = formatNumberToDecimalAuction(data.unit_price_terendah, 2);
      data.unit_price = formatNumberToDecimalAuction(data.unit_price, 2);
      data.subtotal = formatNumberToDecimalAuction(data.subtotal, 2);
      const response: any = (isEdit) ? await axios.put(`${getEnv('auction')}/item-auction/${detailData?.id}`, data) : await axios.post(`${getEnv('auction')}/item-auction`, data);
      if (response?.status === 201 || response?.status === 200) {
        toast.success(response?.status + ": " + response?.data.meta.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light"
        });
        onAfterSave && onAfterSave();
        return true;
      }
    } catch (error) {
      setIsLoadingSave(false);
      console.error(error);
    } finally {
      // setKey(key + 1);

      setIsLoadingSave(false);

    }
  };

  const calculateSubtotal = (qty: any, unitprice: any, setFieldValue: any) => {
    if (qty && unitprice) {
      const subtotal = multiplyNumber(
        qty,
        unitprice
      );
      setFieldValue("subtotal", subtotal.toFixed(2));
    }
  }


  useEffect(() => {
    if (detailData) {
      setInitialValues({
        nama: detailData?.nama,
        kuantitas: detailData?.kuantitas,
        mata_uang: detailData?.mata_uang,
        satuan: detailData?.satuan,
        unit_price_tertinggi: formatNumberToDecimalAuction(detailData?.unit_price_tertinggi, 2),
        unit_price_terendah: formatNumberToDecimalAuction(detailData?.unit_price_terendah, 2),
        unit_price: formatNumberToDecimalAuction(detailData?.unit_price, 2),
        subtotal: formatNumberToDecimalAuction(detailData?.subtotal, 2),
        note: detailData?.note
      })
      setFormIndex(indexForm + 1)
    } else {
      if (auction?.metode_peringkat_id == 297) {
        //multivariant
        initialValue.unit_price_terendah = "0";
        initialValue.unit_price_tertinggi = "0";
      }
      setInitialValues(initialValue)
      setFormIndex(indexForm + 1)
    }
  }, [detailData])

  return (
    <>

      <Modal
        key={'modal-' + indexForm}
        show={isModalOpen}
        size="lg"
        onHide={() => {
          setFormIndex(indexForm + 1)
          setInitialValues(initialValue)
          setIsModalOpen(false);
        }}>
        <Formik
          key={indexForm}
          innerRef={formik}
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}>
          {({ errors, touched, values, setFieldValue, handleChange, submitForm }: any) => (
            <>
              <Form>
                <Modal.Header closeButton>
                  <Modal.Title>{isEdit ? 'Edit' : 'Tambah'} Item Auction</Modal.Title>
                </Modal.Header>
                <Modal.Body>


                  <div className={"form-group mb-4"}>
                    <label className="required form-label mb-3">Nama Item</label>
                    <Field
                      type={"text"}
                      className="form-control"
                      disabled={isAuctionFromTender()}
                      name="nama"
                      required
                    />
                    {(errors.nama && touched.nama) && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{errors.nama}</div>
                      </div>
                    )}
                  </div>
                  <div className={"form-group mb-4"}>
                    <FormikSelect2
                      isDisabled={isAuctionFromTender()}
                      name="mata_uang"
                      label="Mata Uang"
                      optionsUrl={`${getEnv('masterdata')}/currency`}
                      unique_property={"id"}
                      getOptionValue={(opt: any) => opt}
                      getOptionLabel={(opt: any) => opt.label}
                      search_property={"label"}
                      defaultValue={initialValues?.mata_uang}
                      required
                    />
                  </div>
                  <div className={"row mb-4"}>
                    <div className={"col-md-4"}>
                      <label className="required form-label mb-3">Kuantitas</label>
                      <Field>
                        {({ }) => (
                          <CurrencyInput
                            value={(values.kuantitas)}
                            allowDecimals={false}
                            allowNegativeValue={false}
                            intlConfig={{ locale: 'id-ID' }}
                            disableGroupSeparators={false}
                            groupSeparator="."
                            decimalSeparator=","
                            decimalsLimit={0}
                            className="form-control"
                            onValueChange={(value: any) => {
                              setFieldValue("kuantitas", value);
                              calculateSubtotal(value?.replace(/,/g, '.'), values?.unit_price?.replace(/,/g, '.'), setFieldValue);
                            }}
                            disabled={isAuctionFromTender()}
                            required
                          />
                        )}

                      </Field>
                      {(errors.kuantitas && touched.kuantitas) && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.kuantitas}</div>
                        </div>
                      )}
                    </div>
                    <div className={"col-md-8"}>
                      <FormikSelect2
                        name="satuan"
                        label="Satuan"
                        defaultValue={initialValues?.satuan}
                        optionsUrl={`${getEnv('masterdata')}/satuan`}
                        unique_property={"id"}
                        getOptionValue={(opt: any) => opt}
                        getOptionLabel={(opt: any) => opt.uom_uom_name}
                        search_property={"uom_uom_name"}
                        isDisabled={isAuctionFromTender()}
                        required
                      />
                    </div>
                  </div>
                  <div className={"form-group mb-4"}>
                    <label className="required form-label mb-3">Unit Price</label>
                    <Field>
                      {({ }) => (
                        <CurrencyInput
                          value={(values.unit_price)}
                          decimalsLimit={2}
                          decimalSeparator=","
                          groupSeparator="."
                          className="form-control"
                          onValueChange={(value: any) => {
                            setFieldValue("unit_price", value);
                            calculateSubtotal(values?.kuantitas?.replace(/,/g, '.'), value?.replace(/,/g, '.'), setFieldValue);
                          }}
                          disabled={isAuctionFromTender()}
                          required
                        />
                      )}
                    </Field>
                    {(errors.unit_price && touched.unit_price) && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{errors.unit_price}</div>
                      </div>
                    )}
                  </div>
                  <div className={"form-group mb-4"}>
                    <label className="required form-label mb-3">Sub Total (Estimasi)</label>
                    <Field>
                      {({ }) => (
                        <CurrencyInput
                          name="subtotal"
                          value={(values.subtotal)}
                          decimalsLimit={2}
                          decimalSeparator=","
                          groupSeparator="."
                          className="form-control"
                          required
                          disabled
                        />
                      )}
                    </Field>
                  </div>
                  {auction?.metode_peringkat_id != 297 &&
                    <div className={"row mb-4"}>
                      <div className={"col-md-6"}>
                        <label className="required form-label mb-3">Unit Price Terendah</label>
                        <Field>
                          {({ }) => (
                            <CurrencyInput
                              value={(values.unit_price_terendah)}
                              decimalsLimit={2}
                              decimalSeparator=","
                              groupSeparator="."
                              className="form-control"
                              onValueChange={(value: any) => {
                                setFieldValue("unit_price_terendah", value);
                              }}
                              required
                            />
                          )}
                        </Field>
                        {(errors.unit_price_terendah && touched.unit_price_terendah) && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{errors.unit_price_terendah}</div>
                          </div>
                        )}
                      </div>
                      <div className={"col-md-6"}>
                        <label className="required form-label mb-3">Unit Price Tertinggi</label>
                        <Field>
                          {({ }) => (
                            <CurrencyInput
                              value={(values.unit_price_tertinggi)}
                              decimalsLimit={2}
                              decimalSeparator=","
                              groupSeparator="."
                              className="form-control"
                              onValueChange={(value: any) => {
                                setFieldValue("unit_price_tertinggi", value);
                              }}
                              required
                            />
                          )}
                        </Field>
                        {(errors.unit_price_tertinggi && touched.unit_price_tertinggi) && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{errors.unit_price_tertinggi}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  }
                  <div className={"form-group mb-4"}>
                    <label className="required form-label mb-3">Note</label>
                    <Field
                      as="textarea"
                      rows={5}
                      className="form-control"
                      name="note"
                      required
                    />
                    {(errors.note && touched.note) && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{errors.note}</div>
                      </div>
                    )}
                  </div>

                </Modal.Body>
                <Modal.Footer>
                  <button
                    className="btn btn-secondary btn-sm"
                    onClick={() => {
                      setIsModalOpen(false);
                    }}
                    type={"button"}
                  >
                    <i className="fa fa-times"></i> Tutup
                  </button>
                  <button
                    className="btn btn-primary btn-sm"
                    type={"submit"}
                    disabled={isLoadingSave}
                    onClick={() => {
                      submitForm();
                    }}
                  >
                    {isLoadingSave ? (
                      <Spinner animation="border" variant="light" size="sm" />
                    ) : (
                      <i className="fa fa-save"></i>
                    )}{" "}
                    Simpan
                  </button>
                </Modal.Footer>
              </Form>
            </>
          )}
        </Formik>
      </Modal>

    </>
  );
};

const ItemAuctionTab = ({ auction, onSave }: any) => {
  const [tableKey, setTableKey] = useState(0);
  const [urlIndex, setUrlIndex] = useState(getEnv("auction") + "/item-auction?auction_id=" + auction?.id);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedData, setSelectedData] = useState<any>(null);
  let tableColumns = [
    {
      Header: "Action",
      width: 100,
      className: "text-center",
      Cell: (props: any) => {
        const data = props?.cell?.row?.original;
        // @ts-ignore
        return (
          <>
            {isCreateEnabled() && (
              <>
                <a href={"#"} className={"me-2"} onClick={(e: any) => {
                  e.preventDefault();
                  setSelectedData(JSON.parse(JSON.stringify(data)));
                  setIsModalOpen(true);
                  setIsEdit(true);
                }}>
                  <i className="fa fa-edit fs-3"></i>
                </a>
                {auction?.no_tender == null &&
                  <a href={"#"} onClick={(e: any) => {
                    e.preventDefault();
                    handleDelete(data?.id);
                  }}>
                    <i className="fa fa-trash fs-3"></i>
                  </a>
                }
              </>
            )}

          </>
        )
      }
    },
    { Header: "Deskripsi", accessor: "nama", width: 250, className: "text-start" },
    { Header: "Note", accessor: "note", width: 200, className: "text-start" },
    { Header: "Currency", accessor: "mata_uang.code", width: 100, className: "text-center" },
    {
      Header: "Kuantitas", accessor: "kuantitas", width: 200, className: "text-end",
      Cell: (props: any) => {
        return (
          <>
            <div className="w-200">
              <span>
                <label className="form-label">{formatNumber(props?.cell?.row?.original?.kuantitas)}</label>
              </span>
            </div>
          </>
        );
      }
    },
    {
      Header: "Unit Price Tertinggi", accessor: "unit_price_tertinggi", width: 200, className: "text-end",
      Cell: (props: any) => {
        return (
          <>
            <div className="w-200">
              <span>
                <label
                  className="form-label">{parseNumberToCurrency(props?.cell?.row?.original?.unit_price_tertinggi, props?.cell?.row?.original?.mata_uang?.code)}</label>
              </span>
            </div>
          </>
        );
      }
    },
    {
      Header: "Unit Price Terendah", accessor: "unit_price_terendah", width: 200, className: "text-end",
      Cell: (props: any) => {
        return (
          <>
            <div className="w-200">
              <span>
                <label
                  className="form-label">{parseNumberToCurrency(props?.cell?.row?.original?.unit_price_terendah, props?.cell?.row?.original?.mata_uang?.code)}</label>
              </span>
            </div>
          </>
        );
      }
    },
    {
      Header: "Unit Price", accessor: "unit_price", width: 200, className: "text-end",
      Cell: (props: any) => {
        return (
          <>
            <div className="w-200">
              <span>
                <label className="form-label">{parseNumberToCurrency(props?.cell?.row?.original?.unit_price, props?.cell?.row?.original?.mata_uang?.code)}</label>
              </span>
            </div>
          </>
        );
      }
    },
    {
      Header: "Subtotal", accessor: "subtotal", width: 200, className: "text-end",
      Cell: (props: any) => {
        return (
          <>
            <div className="w-200">
              <span>
                <label className="form-label">{parseNumberToCurrency(props?.cell?.row?.original?.subtotal, props?.cell?.row?.original?.mata_uang?.code)}</label>
              </span>
            </div>
          </>
        );
      }
    }
  ];

  if(auction?.metode_peringkat_id == 297){
    tableColumns.splice(5,1);
    tableColumns.splice(5,1);
  }

  const handleDelete = async (id: any) => {
    Swal.fire({
      title: "Apakah Anda Yakin?",
      text: "Anda akan menghapus data ini!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, Hapus",
      cancelButtonText: "Tidak"
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        try {
          const response: any = await axios.delete(`${getEnv('auction')}/item-auction/${id}`)
          if (response.status === 200) {
            toast.success(response?.data?.meta?.message, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            })
            setTableKey(tableKey + 1);
            onSave && onSave();
          }
        } catch (error) {
        } finally {
        }
      } else {
      }
    });
  }

  const isCreateEnabled = () => {
    return auction?.status === "draft"
  }

  return (
    <div className={"p-2"}>
      <TableXWrpper
        key={tableKey}
        title={"Item Auction"}
        urlIndex={urlIndex}
        columns={tableColumns}
        readonly
        disableUrlChanged={true}
        disablePaddingCard={true}
        headerButtons={
          <>
            {auction?.no_tender == null &&
              <button
                className="btn btn-primary btn-sm"
                type={"button"}
                disabled={!isCreateEnabled()}
                onClick={() => {
                  // onSave && onSave();
                  setSelectedData(null)
                  setIsEdit(false)
                  setIsModalOpen(true)
                }}
              >
                <i className="fa fa-plus"></i> Tambah Item
              </button>
            }
          </>
        }
      />

      <ModalFormItemAuction
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        id={0}
        setTableKey={setTableKey}
        auction={auction}
        isEdit={isEdit}
        onAfterSave={() => {
          onSave && onSave();
          setIsModalOpen(false);
          setTableKey(tableKey + 1);
        }}
        detailData={selectedData}
      />
    </div>
  );
};

export { ItemAuctionTab };
