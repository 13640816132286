import React, { FC, useEffect, useState } from "react";
import { useAuth } from "../../../../auth";
import { usePostDisposisi } from "../../hooks/proposalTenderHook";
import { toast } from "react-toastify";
import { formatDateTimeToDBFormat, getEnv } from "../../../../../../helpers/procurex";
import { DisposisiX } from "../../../../../components/shared/DisposisiX";
import { useAxiosGet } from "../../../../../../hooks/useAxios";
import { Button, Spinner } from "react-bootstrap";
import { Nav, Popover, Whisper } from "rsuite";
import FormAttachmentPRItemTender from "./FormAttachmentPRItemTender";

type IProps = {
  selectedRows: any
  afterSubmit: Function,
  afterSync?: Function,
}

const GetUserName = (userId: any) => {
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosGet(
    getEnv("sso")+`/user/${userId?.userId}`
  )
  const [userName, setUserName] = useState('')
  useEffect(() => {
    doSubmit({},undefined,undefined).then((res:any)=>{
      setUserName(res?.data?.name)
    })
  }, []);

  return (
    <>
      <Spinner animation={"border"} size={"sm"} className={"me-2"} hidden={!isLoading} />
      {userName}
    </>
  )
}

export const TrTableDisposisi = ({row}: any) => {
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosGet(
    getEnv('approval')+`/routing-approval/detail?company_id=${1}&object_id=${row?.id}&source=TENDER&code=tender_pr_item_disposition`
  )
  const [logs, setLogs] = useState([])
  const [isShowComment, setIsShowComment] = useState(true)
  const getLogs = () => {
    doSubmit({},undefined,undefined)
      .then((res:any) => {
        console.log('res', res)
        setLogs(res?.data[0]?.logs || [])
      })
      .catch((error:any) => {
        console.error("Error fetching logs: ", error);
        setLogs([])
      });
  }


  useEffect(() => {
    getLogs()
  }, [row?.id]);

  return (
    <>

      {/*<tr key={"approve-dispo-" + row?.original?.id}>*/}
      {/*  <td>*/}
      {/*    <a href={historyUrl} target={"_blank"}></a>*/}
      {/*  </td>*/}
      {/*  <td>{row?.original?.no}</td>*/}
      {/*  <td>{row?.original?.no}</td>*/}
      {/*  <td>{row?.original?.short_text}</td>*/}
      {/*  <td className={"text-center"}>{row?.original?.purchasing_group_id}</td>*/}
      {/*</tr>*/}

      {/*<tr>*/}
      {/*  <td colSpan={5} className={"p-0 m-0"}>*/}
          <div className={"card border mb-3"}>
            <div className={"card-header p-3 bg-info text-white d-flex justify-content-between"} style={{
              minHeight: "auto"
            }}>
              <div>
                Nomor SAP: <strong> {row?.no_pr} - {row?.short_text}</strong>
              </div>
              <div>
                Purchasing Group: <strong>{row?.purchasing_group_id}</strong>
              </div>
            </div>
            <div className={"card-body p-3"}>
              <div className={"row p-0"}>
                <div className={"col-sm-12"}>
                  {logs.length === 0 && (
                    <div className={"text-start"}>
                      Belum ada riwayat
                    </div>
                  )}
                  {logs?.map((v:any, index:number)=>{
                    return (
                      <div className={"border p-2 rounded mb-2"}>
                        <div className={"d-flex justify-content-between"}>
                          <div>
                            <small className={"text-muted"}><strong>{v?.jabatan}</strong>: <GetUserName userId={v?.user_id} /></small>
                          </div>
                          <div>
                            <small className={`me-3 badge ${v?.action==='REJECT'?'badge-danger':'badge-success'}`}>{v?.action}</small>
                            <small className={"text-muted"}>{formatDateTimeToDBFormat(v?.created_at)}</small>
                          </div>
                        </div>
                        {v?.comment}
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        {/*</td>*/}
      {/*</tr>*/}
    </>

  )
}

const GetItemPRSpeakerText = ({row, lineNo}:any)=>{
  const [selectedDetail, setSelectedDetail] = useState<any>({});
  const [{ doSubmit, isLoading: isLoadingGetDetail, isError, data, isSuccess }] = useAxiosGet(
    getEnv("tender")+`/purchase-requisition/${row?.purchaseRequisitionItem?.purchaseRequisition?.uuid}`
  )

  useEffect(() => {
    doSubmit({},undefined,undefined).then((res:any)=>{
      const items = res?.data?.items?.filter((i:any)=>i?.line_no === lineNo)
      const poText = items[0]?.poTexts?.filter((i:any)=>i?.line_no === lineNo)
      setSelectedDetail(poText[0])
    })
  }, []);

  return (
    <>
      {isLoadingGetDetail && (<i className={"fa fa-spin fa-spinner"}></i>)}
      <p style={{whiteSpace: "pre-wrap"}}>
        {selectedDetail?.text || '-'}
      </p>
    </>
  )
}

const ButtonToolbarPRIncoming: FC<IProps> = (props) => {
  const { currentUser, logout } = useAuth();
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = usePostDisposisi();
  const [showModalDisposisi, setShowModalDisposisi] = useState(false);
  const [showModalDisposisiReject, setShowModalDisposisiReject] = useState(false);
  const [levelTujuanDisposisi, setLevelTujuanDisposisi] = useState('');
  const [purchasingGroupTujuanDisposisi, setPurchasingGroupTujuanDisposisi] = useState('');
  const urlTujuanDisposisi = getEnv('sso')+'/user';
  const [historyUrls,setHistoryUrls] = useState([])
  const [selectedTab, setSelectedTab] = useState("Daftar PR");

  useEffect(() => {
    if (currentUser) {
      if (
        currentUser.has_roles.includes('spv_buyer_pi') ||
        currentUser.has_roles.includes('spv_buyer_anper')
      ) {
        setLevelTujuanDisposisi('VP Buyer')
      } else if (
        currentUser.has_roles.includes('vp_buyer_pi') ||
        currentUser.has_roles.includes('avp_buyer_anper')
      ) {
        setLevelTujuanDisposisi('Staff Buyer')
      } else if (currentUser.has_roles.includes('vp_buyer_anper')) {
        setLevelTujuanDisposisi('AVP Buyer')
      }
    }
  }, [currentUser]);

  useEffect(() => {
    if(props.selectedRows.length > 0){
      let tmp: Array<string> = [] ;
      let _historyUrls : Array<string> = [];
      props.selectedRows.map((row: any) => {
        if(!tmp.includes(row?.original?.purchasing_group_id)){
          tmp.push(row?.original?.purchasing_group_id)
        }
        const companyId = 1;
        const objectId = row?.original?.id;
        const source = "TENDER";
        const code = "tender_pr_item_disposition";
        _historyUrls.push(
          getEnv('approval')+`/routing-approval/detail?company_id=${companyId}&object_id=${objectId}&source=${source}&code=${code}`
        )
      })
      setPurchasingGroupTujuanDisposisi(tmp.join(','))
      // @ts-ignore
      setHistoryUrls(_historyUrls)
      // console.log(props.selectedRows)
    }
  }, [props.selectedRows]);

  const handleOnDisposisiReject = (values:any) => {
    const payload = {
      pr_items: props.selectedRows?.map((row: any) => {return row?.original?.uuid}),
      action: 'REJECT',
      next_user_id: null,
      comment: values.notes
    }
    doSubmit(payload, toast, props.afterSubmit).then(() => {
      setShowModalDisposisiReject(false)
    })
  }

  const handleOnDisposisiApprove = (values:any) => {
    const payload = {
      pr_items: props.selectedRows?.map((row: any) => {return row?.original?.uuid}),
      action: 'APPROVE',
      next_user_id: values?.user?.employee?.emp_no,
      comment: values.notes
    }
    doSubmit(payload, toast, props.afterSubmit).then(() => {
      setShowModalDisposisi(false)
    })
  }

  const speakerPopover = (row:any) => {
    return (
      <Popover title="Header Text">
        <p>
          <span className={"text-muted"} style={{whiteSpace: "pre-wrap"}}>
            {row?.purchaseRequisitionItem?.purchaseRequisition?.text}
          </span>
        </p>
      </Popover>
    )
  }

  const speakerPopoverItemText = (row:any,lineNo:any) => {
    return (
      <Popover title="Material PO Text">
        <p>
          <span className={"text-muted"} style={{whiteSpace: "pre-wrap"}}>
            <GetItemPRSpeakerText row={row} lineNo={lineNo}/>
          </span>
        </p>
      </Popover>
    )
  }

  return (
    <>
        <>
          <DisposisiX
            isLoading={isLoading}
            label={"PR"}
            disabled={props.selectedRows.length === 0}
            urlTujuanDisposisi={urlTujuanDisposisi+`?filter[buyers_level]=${levelTujuanDisposisi}&filter[buyers_level_purch_group]=${purchasingGroupTujuanDisposisi}`}
            showButtonDisposisi={
              (currentUser?.has_roles?.includes("spv_buyer_anper") ||
                currentUser?.has_roles?.includes("spv_buyer_pi") ||
                currentUser?.has_roles?.includes("vp_buyer_pi") ||
                currentUser?.has_roles?.includes("avp_buyer_anper") ||
                currentUser?.has_roles?.includes("vp_buyer_anper"))
            }
            showButtonTerima={
              (currentUser?.has_roles?.includes("staff_buyer_pi") ||
                currentUser?.has_roles?.includes("staff_buyer_anper"))
            }
            tableList={
              <>
                <Nav appearance={"subtle"} justified={true} activeKey={selectedTab} onSelect={(e: any) => {
                  setSelectedTab(e);
                }} style={{ marginBottom: 20 }}>
                  <Nav.Item eventKey={"Daftar PR"} className={"d-flex align-items-center justify-content-center text-center w-100"}>
                    Daftar PR
                  </Nav.Item>
                  <Nav.Item eventKey={"Attachment"} className={"d-flex align-items-center justify-content-center text-center w-100"}>
                    Attachment
                  </Nav.Item>
                  <Nav.Item eventKey={"Catatan Disposisi"} className={"d-flex align-items-center justify-content-center text-center w-100"}>
                    Catatan Disposisi
                  </Nav.Item>
                </Nav>
                {/*<table className="table table-bordered table-hover table-striped">*/}
                  {/*<thead>*/}
                  {/*<tr>*/}
                  {/*  <td>No</td>*/}
                  {/*  <td>Nomor PR</td>*/}
                  {/*  <td>Item PR</td>*/}
                  {/*  <td>Short Text</td>*/}
                  {/*  <td className={"text-center"}>Purchasing Group</td>*/}
                  {/*</tr>*/}
                  {/*</thead>*/}
                  {/*<tbody>*/}

                  {/*</tbody>*/}
                {/*</table>*/}
                {selectedTab === "Daftar PR" && (
                  <>
                    <table className="table table-header-gray">
                    <thead>
                    <tr>
                      <th className={"border px-2 text-center"}>No</th>
                      <th className={"border text-center"}>Nomor PR</th>
                      <th className={"border text-center"}>Item PR</th>
                      <th className={"border text-center"}>Short Text</th>
                      <th className={"text-center"}>Purchasing Group</th>
                    </tr>
                    </thead>
                    <tbody>
                    {props.selectedRows?.map((row: any, index: number) => {
                      return (
                        <tr key={"row-table-daftar-pr"+index}>
                          <td className={"border px-2 text-center"}>{index+1}</td>
                          <td className={"border px-2 text-center"}>
                            <Whisper
                              placement="top"
                              trigger="hover"
                              controlId="control-id-hover-enterable"
                              speaker={speakerPopover(row?.original)}
                              enterable
                            >
                              <Button size={"sm"}>{row?.original?.no}</Button>
                            </Whisper>
                          </td>
                          <td className={"border px-2 text-center"}>
                            <Whisper
                              placement="top"
                              trigger="hover"
                              controlId="control-id-hover-enterable"
                              speaker={speakerPopoverItemText(row?.original,row?.original?.line_no)}
                              enterable
                            >
                              <Button size={"sm"} variant = {"secondary"}>{row?.original?.line_no}</Button>
                            </Whisper>
                          </td>
                          <td className={"border px-2 text-center"}>{row?.original?.short_text}</td>
                          <td className={"border px-2 text-center"}>{row?.original?.purchasing_group_id}</td>
                        </tr>
                      );
                    })}
                    </tbody>
                    </table>
                  </>
                )}
                {selectedTab === "Catatan Disposisi" && (
                  <>
                    {props.selectedRows?.map((row: any, index: number) => {
                      return (
                        <TrTableDisposisi
                          key={"reject-dispo-" + row?.original?.id}
                          row={row}
                        />
                      );
                    })}
                  </>
                )}
                {selectedTab === "Attachment" && (
                  <>
                    <FormAttachmentPRItemTender
                      selectedRows={props.selectedRows}
                      disableAddAttachment={true}
                    />
                  </>
                )}
              </>
            }
            onSubmitDisposisi={handleOnDisposisiApprove}
            onRejectDisposisi={handleOnDisposisiReject}
          />

        </>




    </>
  );
};

// @ts-ignore
export default ButtonToolbarPRIncoming;