import {getEnv} from '../../../../../../helpers/procurex'
import React, {createContext, useContext, useEffect, useState} from 'react'
import {useMutation, useQuery} from 'react-query'
import axios from 'axios'
import {toast} from 'react-toastify'
import {useParams} from 'react-router-dom'

const API_URL = getEnv('tender')
export interface ToolbarInterface {
  show: boolean;
  currentTab: number;
}

//region context
const ProposalTenderContext = createContext({
  proposalTender: null as any,
  proposalTenderUuid: null as string,
  isLoadingProposalTender: false,
  toolbarEvaluasi: {
    show: false,
    currentTab: 0
  } as ToolbarInterface,
  refetchProposalTender: () => {},
  setToolbarEvaluasi: () => {},
} as any)

const ProposalTenderVendorProvider = ({children}: any) => {
  const [proposalTender, setProposalTender] = useState(null)
  const [isLoadingProposalTender,setIsLoadingProposalTender] = useState(false)
  const [proposalTenderUuid,setProposalTenderUuid] = useState("")
  const { uuid } = useParams();

  const {refetch: refetchProposalTender,isLoading} = useQuery({
    enabled: false,
    queryKey: "proposalTenderDetail",
    queryFn: () => axios.get(API_URL+`/vendor/published/${uuid}`),
    onSuccess:(res) => {
      setProposalTender(res?.data?.data)
    }
  })

  useEffect(() => {
    refetchProposalTender().then(r => {})
    setProposalTenderUuid(uuid)
  }, [uuid])

  return (
    <ProposalTenderContext.Provider value={{
      proposalTender,
      proposalTenderUuid,
      refetchProposalTender,
      isLoadingProposalTender,
    }}>
      {children}
    </ProposalTenderContext.Provider>
  )
}

const useProposalTenderVendor = () => {
  return useContext(ProposalTenderContext)
}
//endregion

export {
  ProposalTenderVendorProvider,
  useProposalTenderVendor,
}

