import { Nav, Radio, RadioGroup, Toggle } from "rsuite";
import React, { useEffect, useRef, useState } from "react";
import { useAxiosGet, useAxiosPost } from "../../../../../../hooks/useAxios";
import { getEnv, parseStringToBool } from "../../../../../../helpers/procurex";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Field, Form, Formik } from "formik";
import FormAttachment from "../../../../../components/shared/form/FormAttachment";
import PreviewAttachment from "../../../../../components/PreviewAttachment";
import BottomToolbarWrapper from "../../../../../components/shared/BottomToolbarWrapper";
import { Spinner } from "react-bootstrap";
import { useIntl } from "react-intl";

const FormRegisterPQ = ({ proposalTender, onRefresh }: any) => {
  const [initialValues, setInitialValues] = useState({
    proposal_tender_id: proposalTender?.uuid,
    proposal_tender_no: proposalTender?.no,
    description: proposalTender?.title,
    date: "",
    note: "",
    response: "",
    attachment: null
  });
  const intl = useIntl();
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosPost(
    getEnv("tender") + `/vendor/pq/registration/${proposalTender.uuid}`
  );
  const [formKey, setFormKey] = useState(0);
  const formSchemaValidation = Yup.object().shape({
    proposal_tender_id: Yup.string().required("Required")
  });
  const formik = useRef({} as any);

  const checkIsFormEnabled = () => {
    const allowedStatusDetail = ["Published","Quotation","Evaluation"];
    return proposalTender?.vendor_status === null &&
      proposalTender?.status === "Pra Qualification" &&
      allowedStatusDetail.includes(proposalTender?.status_detail);
  };

  const getJadwalRegistrasiTender = () => {
    let date = "";
    proposalTender?.jadwals.map((jadwal: any) => {
      if (jadwal.description === "Pra Qualification") {
        date = jadwal.start_at + " - " + jadwal.end_at;
      }
    });
    return date;
  };

  const handleSubmit = (values: any, { setStatus, setSubmitting }: any) => {
    Swal.fire({
      title: `${intl.formatMessage({id:'Apakah Anda yakin'})}?`,
      html: parseStringToBool(values.response) ?
        intl.formatMessage({id:'Anda akan melakukan Registrasi PQ'}) :
        intl.formatMessage({id:'Anda akan melakukan Registrasi PQ'}),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({id:'Ya, Submit'}),
      cancelButtonText: intl.formatMessage({id:'Tidak'}),
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const payload = values;
        doSubmit(payload, null, toast).then((res: any) => {
          onRefresh();
        });
      }
    });
  };

  const getDefaultResponseValue = () : string => {
    if(!proposalTender?.praqualification) return "0";
    const registrationStatus = ["PRA-QUALIFICATION"];
    if(registrationStatus.includes(proposalTender?.vendor_status)) {
      return "1";
    }
    return "";
  }

  useEffect(() => {
    setInitialValues({
      ...initialValues,
      date: getJadwalRegistrasiTender(),
      response: getDefaultResponseValue()
    });
    setFormKey(formKey + 1);
  }, [proposalTender]);

  return (
    <>
      <Formik
        innerRef={formik}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={formSchemaValidation}
        enableReinitialize
        validateOnChange={true}
        key={formKey}
      >
        {({ errors, touched, values, setFieldValue, resetForm, submitForm }: any) => (
          <Form id="form-register-tender">
            <div className={"row"}>
              <div className={"col-md-8 col-sm-12 form-group mb-3"}>
                <div className={"form-group mb-4"}>
                  <label className="required form-label">
                    {intl.formatMessage({ id: "No. Proposal Tender" })}
                  </label>
                  <Field
                    name="proposal_tender_no"
                    type="text"
                    className="form-control"
                    disabled={true}
                  />

                </div>
                <div className={"form-group mb-4"}>
                  <label className="required form-label">
                    {intl.formatMessage({ id: "Deskripsi" })}
                  </label>
                  <Field
                    name="description"
                    type="text"
                    as={"textarea"}
                    className="form-control"
                    disabled={true}
                  />

                </div>
                <div className={"form-group mb-4"}>
                  <label className="required form-label">
                    {intl.formatMessage({ id: "Jadwal" })}
                  </label>
                  <Field
                    name="date"
                    type="text"
                    className="form-control"
                    disabled={true}
                  />
                </div>
                <div className={"form-group mb-4"}>
                  <label className="required form-label">
                    {intl.formatMessage({ id: "Registrasi" })}
                  </label>
                  <br />
                  <RadioGroup name="radioList" className={"gap-0"} defaultValue={values?.response} readOnly={!checkIsFormEnabled()}>
                    <Radio value="1" className={""} onChange={(e:any)=>setFieldValue("response",e)}>
                      {intl.formatMessage({ id: "Registrasi" })}
                    </Radio>
                    {/*<Radio value="0" className={""} onChange={(e:any)=>setFieldValue("response",e)}>Tidak Registrasi</Radio>*/}
                  </RadioGroup>
                </div>
                {values?.response === "0" && (
                  <div className={"form-group mb-4"}>
                    <label className="required form-label">
                      {intl.formatMessage({ id: "Alasan tidak registrasi" })}
                    </label>
                    <Field
                      name="note"
                      type="text"
                      as={"textarea"}
                      className="form-control"
                      disabled={!checkIsFormEnabled()}
                    />
                  </div>
                )}



                <div className={"form-group mb-4 text-end"}>
                  <button
                    type="submit"
                    className="btn btn-primary btn btn-sm"
                    disabled={isLoading || !checkIsFormEnabled()}
                  >
                    {isLoading && (
                      <i className={"fa fa-spinner fa-spin"}></i>
                    )}
                    {!isLoading && (
                      <i className={"fa fa-save"}></i>
                    )}
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

const RowPersyaratanPQ = ({ proposalTender, syarat, index, onAfterUpload }: any) => {

  const [currentMediaUrl, setCurrentMediaUrl] = React.useState<any>(syarat?.media_url);
  const [previewUrl, setPreviewUrl] = React.useState<any>(null);
  const intl = useIntl();
  const checkIsAttachmentEnabled = () => {
    const allowedStatusDetail = ["Published","Quotation","Evaluation"];
    return proposalTender?.vendor_status === 'PRA-QUALIFICATION' &&
    proposalTender?.status === "Pra Qualification" &&
    allowedStatusDetail.includes(proposalTender?.status_detail);
  };

  const handleSubmit = async (e: any) => {
    const payload = {
      proposal_tender_syarat_id: syarat?.uuid,
      media_type: {
        ...e.type
      },
      media_url: e.url,
      required: syarat?.required_attachment
    };
    setCurrentMediaUrl(payload.media_url);
    // await doSubmit(payload, null, toast, () => {
    //
    // });
    onAfterUpload && onAfterUpload(index, payload);

  };
  return (
    <tr key={`row-syarat-${index}`}>
      <td className={"border px-2 text-center"}>{index + 1}</td>
      <td className={"border px-2 text-start"}>{syarat?.description}</td>
      <td className={"border px-2 text-center"}>{syarat?.required_attachment ? intl.formatMessage({id:"Ya"}) : intl.formatMessage({id:"Tidak"})}</td>
      <td className={"border px-2 text-start"}>
          <FormAttachment
            media_type_filter={"TENDER_ATTACHMENT"}
            isHideLabel={true}
            isDisabled={!checkIsAttachmentEnabled()}
            onlyFile={true}
            isSmall={true}
            afterSubmit={handleSubmit}
            previewUrl={syarat?.media_url}
            withPreviewButton={true}
          />
      </td>
    </tr>
  );
};
const FormPersyaratanPQ = ({ proposalTender, onRefresh }: any) => {
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosGet(
    getEnv("tender") + "/vendor/pq/" + proposalTender?.uuid
  );
  const [{ doSubmit: doSubmitPost, isLoading: isLoadingPost }] = useAxiosPost(
    getEnv("tender") + "/vendor/pq/requirement/" + proposalTender?.uuid
  );
  const [renderKey, setRenderKey] = useState(0);
  const [syaratAdministrasi, setSyaratAdministrasi] = useState<any>(proposalTender?.syarats?.filter((syarat: any) => syarat.source === "pra_qualification" && syarat.type === "administration"));
  const [syaratAdditional, setSyaratAdditional] = useState<any>(proposalTender?.syarats?.filter((syarat: any) => syarat.source === "pra_qualification" && syarat.type === "additional"));
  const [uploadedFiles, setUploadedFiles] = useState<any>([]);
  const intl = useIntl();
  const getSyarats = () => {
    doSubmit({}, undefined, undefined).then((res: any) => {
      console.log(res?.data?.syarats);
      setSyaratAdministrasi(res?.data?.syarats?.filter((syarat: any) => syarat.source === "pra_qualification" && syarat.type === "administration"));
      setSyaratAdditional(res?.data?.syarats?.filter((syarat: any) => syarat.source === "pra_qualification" && syarat.type === "additional"));
      setRenderKey(renderKey + 1);
    });
  }

  useEffect(() => {
    getSyarats();
  }, [proposalTender]);

  const handleSubmit = async (e: any) => {
    if(uploadedFiles.length === 0) {
      toast.error(intl.formatMessage({id:"Mohon lengkapi persyaratan administrasi"}));
      return ;
    }

    let requiredAttachment = syaratAdministrasi.filter((syarat:any)=>syarat.required_attachment);
    requiredAttachment = requiredAttachment.map((syarat:any)=>{
      const isExists = uploadedFiles.filter((file:any)=>file.proposal_tender_syarat_id === syarat.uuid);
      return {
        ...syarat,
        isExists: isExists.length > 0
      }
    })

    let requiredAttachmentTambahan = syaratAdditional.filter((syarat:any)=>syarat.required_attachment);
    requiredAttachmentTambahan = requiredAttachmentTambahan.map((syarat:any)=>{
      const isExists = uploadedFiles.filter((file:any)=>file.proposal_tender_syarat_id === syarat.uuid);
      return {
        ...syarat,
        isExists: isExists.length > 0
      }
    })


    let isAllRequiredAttachmentUploaded = true;
    requiredAttachment.map((syarat:any)=>{
      if(!syarat.isExists) {
        isAllRequiredAttachmentUploaded = false;
      }
    })

    if (isAllRequiredAttachmentUploaded) {
      requiredAttachmentTambahan.map((syarat:any)=>{
        if(!syarat.isExists) {
          isAllRequiredAttachmentUploaded = false;
          return;
        }
      })
    }

    if(!isAllRequiredAttachmentUploaded) {
      toast.error(intl.formatMessage({id:"Mohon lengkapi persyaratan administrasi"}));
      return ;
    }

    Swal.fire({
      title: intl.formatMessage({id:"Apakah Anda yakin"})+"?",
      text: intl.formatMessage({id:"Anda akan melakukan Submit PQ"}),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({id:"Ya, Submit"}),
      cancelButtonText: intl.formatMessage({id:"Tidak"}),
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        uploadedFiles.map(async (file: any, idx: number) => {
          await doSubmitPost(file, null, idx === uploadedFiles.length - 1 ? toast : undefined)
        })
        onRefresh()
      }
    })
  }

  const handleAfterUpload = async(index: number, payload: any) => {
    const isExists = uploadedFiles.filter((file:any)=>file.proposal_tender_syarat_id === payload.proposal_tender_syarat_id);
    if(isExists.length > 0) {
      const newUploadedFiles = uploadedFiles.map((file:any)=>{
        if(file.proposal_tender_syarat_id === payload.proposal_tender_syarat_id) {
          return payload;
        }
        return file;
      });
      setUploadedFiles(newUploadedFiles);
    } else {
      setUploadedFiles([...uploadedFiles, payload]);
    }
  }

  const isButtonSubmitEnabled = () => {
    const allowedStatusDetail = ["Published","Quotation","Evaluation"];
    return proposalTender?.vendor_status === 'PRA-QUALIFICATION' &&
      proposalTender?.status === "Pra Qualification" &&
      allowedStatusDetail.includes(proposalTender?.status_detail);
  }

  return (
    <>
      <div className={"table-responsive"} key={'render-key'+renderKey}>
        <table className={"table table-header-gray"}>
          <thead>
          <tr>
            <th className={"border px-2 text-center"} style={{ width: 50 }}>{intl.formatMessage({id:"No"})}</th>
            <th className={"border px-2 text-center"} style={{ width: 300 }}>{intl.formatMessage({id:"Deskripsi"})}</th>
            <th className={"border px-2 text-center"} style={{ width: 50 }}>{intl.formatMessage({id:"Mandatory"})}</th>
            <th className={"border px-2 text-center"} style={{ width: 300 }}>{intl.formatMessage({id:"File"})}</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td className={"border px-2 text-start bg-secondary"} colSpan={4}>
              <strong>{intl.formatMessage({id:"Persyaratan Administrasi"})}</strong>
            </td>
          </tr>
          {syaratAdministrasi.map((syarat: any, index: number) => {
            return <RowPersyaratanPQ
              proposalTender={proposalTender}
              syarat={syarat}
              index={index}
              key={`row-syarat-pq-${index}`}
              onAfterUpload={handleAfterUpload}
            />;
          })}
          {syaratAdministrasi.length === 0 && (
              <tr>
                <td className={"border px-2 text-center"} colSpan={4}>
                  {intl.formatMessage({id:'Tidak ada data'})}
                </td>
              </tr>
          )}
          <tr>
            <td className={"border px-2 text-start bg-secondary"} colSpan={4}>
              <strong>{intl.formatMessage({id:'Persyaratan Tambahan'})}</strong>
            </td>
          </tr>
          {syaratAdditional.map((syarat: any, index: number) => {
            return <RowPersyaratanPQ
              proposalTender={proposalTender}
              syarat={syarat}
              index={index}
              key={`row-syarat-pq-additional-${index}`}
              onAfterUpload={handleAfterUpload}
            />;
          })}
          {syaratAdditional.length === 0 && (
              <tr>
                <td className={"border px-2 text-center"} colSpan={4}>
                  {intl.formatMessage({id:'Tidak ada data'})}
                </td>
              </tr>
          )}
          </tbody>
        </table>

        <BottomToolbarWrapper children={
          <>
            <button
              type="button"
              className="btn btn-primary btn"
              disabled={isLoadingPost || !isButtonSubmitEnabled()}
              onClick={handleSubmit}
              >
              {isLoadingPost && (
                <Spinner animation="border" size="sm" className={"me-2"} />
              )}
              {!isLoadingPost && (
                <i className={"fa fa-save"}></i>
              )}
              {intl.formatMessage({id:'Submit'})}
            </button>
          </>
        } />
      </div>
    </>
  );
};

const PraQualificationPage = (props: any) => {
  const [selectedTab, setSelectedTab] = React.useState("registration");
  const intl = useIntl();
  const [isPersyaratanEnabled, setIsPersyaratanEnabled] = React.useState(false);
  const [renderKey, setRenderKey] = React.useState(0);
  const [currentProposalTender,setCurrentProposalTender] = useState(props.proposalTender);
  const [isCompleteRegistration, setIsCompleteRegistration] = useState(false);
  const [isCompletePersyaratan, setIsCompletePersyaratan] = useState(false);
  const [syaratAdministrasi, setSyaratAdministrasi] = useState<any>(props?.proposalTender?.syarats?.filter((syarat: any) => syarat.source === "pra_qualification" && syarat.type === "administration"));
  const [syaratAdditional, setSyaratAdditional] = useState<any>(props?.proposalTender?.syarats?.filter((syarat: any) => syarat.source === "pra_qualification" && syarat.type === "additional"));
  const isTabPersyaratanEnabled = () => {
    const allowedStatus = ["PRA-QUALIFICATION","OPEN"];
    const allowedStatusTender = ["Pra Qualification"];
    setIsPersyaratanEnabled(allowedStatus.includes(props.proposalTender?.vendor_status) &&
      allowedStatusTender.includes(props.proposalTender?.status));
  }
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosGet(
    getEnv("tender") + "/vendor/pq/" + props?.proposalTender?.uuid
  );


  const handleOnRefresh = async () => {
    setIsPersyaratanEnabled(true)
    setRenderKey(renderKey + 1);
    setCurrentProposalTender({
      ...currentProposalTender,
      vendor_status: "PRA-QUALIFICATION"
    });
    props.onRefresh();
    getSyarats()
  }

  const getSyarats = () => {
    doSubmit({}, undefined, undefined).then((res: any) => {
      setSyaratAdministrasi(res?.data?.syarats?.filter((syarat: any) => syarat.source === "pra_qualification" && syarat.type === "administration"));
      setSyaratAdditional(res?.data?.syarats?.filter((syarat: any) => syarat.source === "pra_qualification" && syarat.type === "additional"));
      setRenderKey(renderKey + 1);
    });
  }

  useEffect(() => {
    isUploadValid();
  }, [syaratAdditional]);

  const isUploadValid = async () => {
    if (currentProposalTender?.vendor_status) {
      let isExists = 0
      let requiredAttachment = syaratAdministrasi.filter((syarat:any)=>syarat.required_attachment);
      requiredAttachment = requiredAttachment.map((syarat:any)=>{
        if(!syarat?.media_url && syarat?.required_attachment) isExists++
      })

      let requiredAttachmentTambahan = syaratAdditional.filter((syarat:any)=>syarat.required_attachment);
      requiredAttachmentTambahan = requiredAttachmentTambahan.map((syarat:any)=>{
        if(!syarat?.media_url && syarat?.required_attachment) isExists++
      })

      if(isExists === 0) {
        props.setIsCompletePQ(false)
        setIsCompleteRegistration(false)
        setIsCompletePersyaratan(false)
      } else {
        props.setIsCompletePQ(true)
        setIsCompleteRegistration(false)
        setIsCompletePersyaratan(true)
      }
    } else {
      props.setIsCompletePQ(true)
      setIsCompleteRegistration(true)
      setIsCompletePersyaratan(false)
    }
  }

  const renderTab = () => {
    switch (selectedTab) {
      case "persyaratan":
        return <FormPersyaratanPQ
          proposalTender={currentProposalTender}
          onRefresh={handleOnRefresh}
        />;
      default:
        return <FormRegisterPQ proposalTender={currentProposalTender} onRefresh={handleOnRefresh}/>;
    }
  };

  useEffect(() => {
    isTabPersyaratanEnabled();
  }, []);

  return (
    <>
      <div className={"card"}>
        <div className={"card-body"} key={renderKey}>
          <div className={"d-flex justify-content-between"}>
            <div><h3>Pra Qualification</h3></div>

          </div>
          <hr />
          <Nav appearance={"subtle"} activeKey={selectedTab} onSelect={(e: any) => {
            setSelectedTab(e);
          }} style={{ marginBottom: 20 }}>
            <Nav.Item eventKey={"registration"} className={isCompleteRegistration ? "red-border" : ""}>
              {intl.formatMessage({ id: "Registrasi" })}
              {isCompleteRegistration &&
                <small className="error-counter">
                  <i className={"fa fa-exclamation-circle text-danger text-sm blink_me"}></i>
                </small>
              }
            </Nav.Item>
            <Nav.Item eventKey={"persyaratan"} disabled={!isPersyaratanEnabled} className={isCompletePersyaratan ? "red-border" : ""}>
              {intl.formatMessage({ id: "Persyaratan" })}
              {isCompletePersyaratan &&
                <small className="error-counter">
                  <i className={"fa fa-exclamation-circle text-danger text-sm blink_me"}></i>
                </small>
              }
            </Nav.Item>
          </Nav>

          {renderTab()}
        </div>
      </div>

    </>
  );
};
export default PraQualificationPage;