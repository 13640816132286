import {Field, Form, Formik} from 'formik'
import React, {useCallback, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {FormikProps} from 'formik/dist/types'
import {MetronicErrorMessage, toastError, toastSuccess} from '../../../../../../_metronic/helpers'
import {useMutation} from 'react-query'
import axios, {AxiosError} from 'axios'
import {getEnv} from '../../../../../../helpers/procurex'
import {FetchResponse, Response} from '../../../../../../_models'
import Swal from 'sweetalert2'
import {UseMutationOptions} from 'react-query/types/react/types'

const approvalUrl = getEnv('approval')

export interface BatchApprovalItems {
  source: string
  object_id: string | number
  company_id: string | number
  code: string
}

interface ApprovalBatchPayload {
  items: BatchApprovalItems[]
  detail_action: string
  comment: string
}

const useUpdateApprovalBatchMutation = (
  options?: Omit<UseMutationOptions<Response<any>, unknown, ApprovalBatchPayload>, 'mutationFn'>
) =>
  useMutation({
    mutationFn: (data: ApprovalBatchPayload): FetchResponse<any> =>
      axios
        .patch(`${approvalUrl}/routing-approval-detail/status-disposition`, data)
        .then((response) => response.data),
    onSuccess: (response: Response<any>) => {
      toastSuccess(response.meta.message)
    },
    onError: (error: AxiosError) => {
      toastError(
        error.response.data?.meta?.message || error.response.data?.message || 'Terjadi kesalahan'
      )
    },
    ...options,
  })

interface FormBatchApprovalProps {
  show: boolean
  type: 'APPROVE' | 'REJECT'
  items: BatchApprovalItems[]
  onHide?: () => void
  onSuccess?: () => void
}

const FormBatchApproval = ({show, type, items, onHide, onSuccess}: FormBatchApprovalProps) => {
  const intl = useIntl()
  const formikRef = React.useRef<FormikProps<any>>()
  const {mutate, isLoading} = useUpdateApprovalBatchMutation()

  useEffect(() => {
    formikRef.current?.resetForm()
  }, [type, items])

  const labels = {
    comment: intl.formatMessage({id: 'Komentar'}),
  }

  const handleOnHide = useCallback(() => {
    !isLoading && onHide && onHide()
  }, [isLoading, onHide])

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{comment: ''}}
      validationSchema={Yup.object().shape({comment: Yup.string()})}
      onSubmit={async (values) => {
        if (!values.comment && type === 'REJECT') {
          toastError('Please fill comment first')
          return
        }

        const result = await Swal.fire({
          title: `${intl.formatMessage({id: 'Apakah Anda yakin'})}?`,
          html: `${intl.formatMessage({id: 'Anda akan melakukan aksi'})} <strong>${type}</strong>`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: `Ya, ${type}`,
          cancelButtonText: intl.formatMessage({id: 'Cancel'}),
          confirmButtonColor: type === 'APPROVE' ? '#3085d6' : '#d33',
          willOpen() {
            const element = document.querySelector('.modal.show[tabindex]')

            element?.removeAttribute('tabindex')
            element?.classList.add('has-tabindex')
          },
          didOpen() {
            Swal.getConfirmButton().focus()
          },
          willClose() {
            const element = document.querySelector('.has-tabindex')

            element?.setAttribute('tabindex', '-1')
            element?.classList.remove('has-tabindex')
          },
        })

        if (result.isConfirmed) {
          mutate(
            {
              items,
              detail_action: type,
              comment: values.comment,
            },
            {
              onSuccess: () => {
                handleOnHide()
                onSuccess && onSuccess()
              },
            }
          )
        }
      }}
    >
      {() => (
        <Modal size='lg' keyboard={!isLoading} show={show} onHide={handleOnHide}>
          <Form>
            <Modal.Header closeButton>
              <Modal.Title>{type === 'APPROVE' ? 'Approve' : 'Reject'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='mb-10'>
                <label htmlFor='comment mb-3 form-label'>{labels.comment}</label>
                <Field as='textarea' name='comment' id='comment' className='form-control' autoFocus />
                <MetronicErrorMessage name='comment' />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                onClick={handleOnHide}
                className='btn btn-secondary btn-sm'
                type='button'
                disabled={isLoading}
              >
                <i className={'fa fa-times'}></i>
                Cancel
              </button>
              <button
                className={`btn ${type === 'APPROVE' ? 'btn-success' : 'btn-danger'} btn-sm`}
                type='submit'
                disabled={isLoading}
              >
                {type === 'APPROVE' && <i className='fa fa-check me-2'></i>}
                {type === 'REJECT' && <i className='fa fa-times me-2'></i>}
                {isLoading && <i className='fa fa-spinner fa-spin me-2'></i>}
                {type === 'APPROVE' ? 'Approve' : 'Reject'}
              </button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </Formik>
  )
}

export interface BatchApprovalProps {
  enabled: boolean
  items: BatchApprovalItems[]
  onSuccess?: () => void
  buttonSize?: 'sm' | 'lg'
}

const BatchApproval = ({enabled = false, items, onSuccess, buttonSize}: BatchApprovalProps) => {
  const [type, setType] = useState<'APPROVE' | 'REJECT'>('APPROVE')
  const [isShowModal, setIsShowModal] = useState(false)

  const showButtons = enabled && items.length > 0
  const buttonSizeClass = buttonSize ? ` btn-${buttonSize}` : ''

  return (
    <>
      {showButtons && (
        <button
          type={'button'}
          className={`btn btn-danger me-3 ${buttonSizeClass}`}
          onClick={() => {
            setType('REJECT')
            setIsShowModal(true)
          }}
        >
          Reject
        </button>
      )}

      {showButtons && (
        <button
          type={'button'}
          className={`btn btn-success me-3 ${buttonSizeClass}`}
          onClick={() => {
            setType('APPROVE')
            setIsShowModal(true)
          }}
        >
          Approve
        </button>
      )}

      <FormBatchApproval
        show={isShowModal}
        items={items}
        type={type}
        onHide={() => {
          setIsShowModal(false)
        }}
        onSuccess={onSuccess}
      />
    </>
  )
}

export default BatchApproval
