import TableXWrapper from '../../../../components/shared/TableXWrapper'
import {Modal, ModalBody, ModalHeader, Spinner} from 'react-bootstrap'
import {getEnv} from '../../../../../helpers/procurex'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {useMutation, useQuery} from 'react-query'
import axios from 'axios'
import {toast} from 'react-toastify'
import Swal from 'sweetalert2'
import {useAanwijzing} from './core/_aanwijzingProvider'
import {Form, Formik} from 'formik'
import * as Yup from 'yup'
import FormikSelect2 from '../../../../components/shared/form/FormikSelect2'


const FormTambahPanitia = ({aanwijzing, onSuccess, onCancel}) => {
  const intl = useIntl()
  const [formIndex, setFormIndex] = useState(0)
  const formik = React.useRef(null)
  const validationSchema = Yup.object().shape({
    user: Yup.object().required('User Harus diisi'),
    note: Yup.string().notRequired(),
  })
  const [initialValues, setInitialValues] = useState({
    user: null,
    note: null,
  })

  //region save panitia
  const {mutate, isLoading} = useMutation({
    mutationKey: ['aanwijzing', 'panitia', 'add'],
    mutationFn: (payload) => axios.post(getEnv('tender') + '/aanwijzing-panitia', payload),
    onSuccess: (res: any) => {
      toast.success(res?.data?.meta?.message)
      onSuccess()
    },
    onError: (err: any) => {
      // console.error("error_",)
      toast.error(err?.response?.data?.meta?.message)
    },
  })

  const handleSubmit = (values: any) => {
    const payload = {
      aanwijzing_id: aanwijzing.uuid,
      username: values?.user?.username,
      user: values?.user,
      note: values?.note,
    }

    // @ts-ignore
    mutate(payload)
  }
  //endregion

  return (
    <>
      <Formik
        innerRef={formik}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        key={formIndex}
      >
        {({errors, touched, values, setFieldValue}) => (
          <>
            <Form>
              <div className={'form-group mb-3'}>
                <label className="required form-label">
                  {intl.formatMessage({id: 'User'})}
                </label>
                <FormikSelect2
                  name="user"
                  optionsUrl={`${getEnv('sso')}/user`}
                  unique_property={'id'}
                  search_property={'username'}
                  getOptionValue={(opt: any) => opt}
                  getOptionLabel={(opt: any) =>
                    `${opt.username} - ${opt.name}`
                  }
                  onChangeValue={(opt: any) => {
                    setFieldValue('user', opt.user)
                  }}
                />
              </div>
              <div className={"form-group mb-3"}>
                <label className="form-label">
                  {intl.formatMessage({id: 'Catatan'})}
                </label>
                <textarea
                  className={"form-control form-control-sm"}
                  rows={3}
                  value={values.note}
                  onChange={(e) => setFieldValue('note', e.target.value)}
                  ></textarea>
              </div>
              <div className={'border-top py-2 d-flex flex-end gap-2'}>
                <button
                  className={'btn btn-danger btn-sm'}
                  type="button"
                  onClick={onCancel}
                  disabled={isLoading}
                >
                  <i className="fa fa-times mr-1"></i>
                  {intl.formatMessage({id: 'Cancel'})}
                </button>
                <button
                  className={'btn btn-primary btn-sm'}
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading && <Spinner animation="border" size="sm" />}
                  {!isLoading && <i className="fas fa-save mr-1"></i>}
                  {intl.formatMessage({id: 'Submit'})}
                </button>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </>
  )
}

const StatusBadgeVendor = ({status}: any) => {
  const renderStatus = () => {
    switch (status) {
      case 'WAITING':
        return (<span className={'badge badge-info'}>{status}</span>)
      default:
        return (<span className={'badge badge-primary'}>{status}</span>)
    }
  }
  return (
    <>
      {renderStatus()}
    </>
  )
}

const StatusBadgeApproveBA = ({status}: any) => {
  const renderStatus = () => {
    switch (status) {
      case 'NO RESPON':
        return (<span className={'badge badge-info'}>{status}</span>)
      case 'APPROVE':
        return (<span className={'badge badge-primary'}>{status}</span>)
      default:
        return (<span className={'badge badge-danger'}>{status}</span>)
    }
  }
  return (
    <>
      {renderStatus()}
    </>
  )
}

const PanitiaList = () => {
  const intl = useIntl()
  const [formIndex, setFormIndex] = useState(0)
  const urlIndex = getEnv('tender') + '/aanwijzing-panitia'
  const [isShowModalTambahPanitia, setIsShowModalTambahPanitia] = useState(false)
  const {aanwijzing} = useAanwijzing()

  //region delete panita
  const {mutate: mutateDelete, isLoading: isLoadingDelete} = useMutation({
    mutationKey: ['aanwijzing', 'panitia', 'delete'],
    mutationFn: (uuid: string) => axios.delete(
      getEnv('tender') + `/aanwijzing-panitia/${uuid}`,
    ),
    onSuccess: (res) => {
      toast.success(res?.data?.meta?.message)
      setFormIndex(formIndex + 1)
    },
    onError: (err: any) => {
      toast.error(err)
    },
  })

  const handleOnDelete = (row: any) => {
    Swal.fire({
      title: 'Apakah anda yakin?',
      text: 'Anda akan menghapus panitia ini',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Ya, hapus',
      cancelButtonText: 'Tidak',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        console.log('delete_', row)
        mutateDelete(row?.id)
      }
    })
  }
  //endregion

  const tableColumns = [
    {
      Header: intl.formatMessage({id: 'Delete'}),
      accessor: 'uuid',
      width: 100,
      className: 'text-center',
      searchable: false,
      Cell: ({row}: any) => {

        return (
          <>
            <button
              type={'button'}
              className={'btn btn-sm btn-danger text-center'}
              disabled={!isCanAddPanitia}
              onClick={() => handleOnDelete(row?.original)}
            >
              <i className={'fa fa-trash'} />
            </button>
          </>
        )
      },
    },
    {
      Header: intl.formatMessage({id: 'Employee'}),
      accessor: 'username',
      width: 600,
      className: 'text-start',
      Cell: ({row}: any) => {

        return (
          <>
            {row?.original?.username} - {row?.original?.user?.name} <br />
            <i className={"text-muted"}>
              {row?.original?.user?.employee?.pos_title}
            </i>

          </>
        )
      },
    },
    {
      Header: intl.formatMessage({id: 'Note'}),
      accessor: 'note',
      width: 300,
      className: 'text-start',
    },
  ]

  const handleOnSuccess = () => {
    setFormIndex(formIndex + 1)
    setIsShowModalTambahPanitia(false)
  }

  const isCanAddPanitia = useMemo(() => {
    return aanwijzing?.status === 'OPEN' || aanwijzing?.status === 'REJECTED' && aanwijzing?.source !== 'PROCUREX'
  }, [aanwijzing?.status, aanwijzing?.source])


  return (
    <>
      <div className={'d-flex justify-content-between'}>
        <h3>{intl.formatMessage({id: 'Daftar Panitia'})}</h3>
      </div>
      <hr />

      <TableXWrapper
        key={formIndex}
        readonly={true}
        title={'DUR'}
        urlIndex={urlIndex}
        columns={tableColumns}
        defaultFilter={`filter[aanwijzing_id]=${aanwijzing?.id}`}
        isForceCreateButton={true}
        disablePaddingCard={true}
        hideSelection={true}
        hideShowPagination={true}
        hideExportAs={false}
        onAfterLoadData={(data: any) => {
        }}
        disableUrlChanged={true}
        disableSearchInputAutoFocus={true}
        subHeaderButtons={
          <>
            <button
              type={'button'}
              className={'btn btn-info btn-sm'}
              onClick={() => setIsShowModalTambahPanitia(true)}
              disabled={!isCanAddPanitia}
            >
              <i className={'fa fa-plus'}></i>
              Tambah Panitia
            </button>
          </>
        }
      />

      <Modal
        show={isShowModalTambahPanitia}
        onHide={() => setIsShowModalTambahPanitia(false)}
      >
        <ModalHeader onHide={() => setIsShowModalTambahPanitia(false)}>
          <h3>Tambah Panitia</h3>
        </ModalHeader>
        <ModalBody>
          <FormTambahPanitia
            onSuccess={handleOnSuccess}
            aanwijzing={aanwijzing}
            onCancel={() => setIsShowModalTambahPanitia(false)}
          />
        </ModalBody>
      </Modal>
    </>
  )
}

export default PanitiaList