import React, { FC, useEffect, useState } from "react";
import { Dropdown, DropdownButton, ListGroup, Modal, Spinner } from "react-bootstrap";
import { useIntl } from "react-intl";
import { KTSVG } from "../../../helpers";
import { useGetModuleUser } from "../../../partials/layout/header-menus/core/hooks";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { useGetNotificationList } from "../core/hooks";
import { darkModeClass } from "../../../../helpers/utils";
import { toast } from "react-toastify";
import { Nav, Placeholder } from "rsuite";
import { useAxiosPost } from "../../../../hooks/useAxios";
import { formatDateTimeToDBFormat, getEnv, parseDBDateToJSFormat } from "../../../../helpers/procurex";

interface IPropsRowNotifikasi {
  uuid: string
  title: string,
  description: string,
  code: string,
	url?: string
  isUnread: boolean
  created_at: string
  onSuccess?: any
}

const RowNotifikasi = ({ uuid, title, code, description, url, created_at, isUnread, onSuccess }: IPropsRowNotifikasi) => {
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosPost(
    getEnv('task')+`/notifications/mark-as-read/${uuid}`
  );
  const intl = useIntl()
  const [showPreview, setShowPreview] =useState(false)

	const handleClick = () => {
    // handleMarkAsRead()
		// @ts-ignore
		window.location.href = url;
	}

  const handleMarkAsRead = async () => {
    // toast.success(intl.formatMessage({id:'Berhasil menandai'}))
    doSubmit({},undefined,undefined).then((res) => {
      toast.success(intl.formatMessage({id:'Berhasil menandai'}))
      onSuccess && onSuccess()
    })
      .catch((e) => {

      })
  }

  const getCode = () => {
    if(title.includes('Proposal Tender')) return "TNDR";
    if(title.includes('MR SR')) return "MRSR";
    if(title.includes('PR Item')) return "PR";
  }

  const getBackgroundColor = () => {
    return "bg-primary";
  }

  const handlePreview = () => {
    doSubmit({},undefined,undefined).then((res) => {
    })
      .catch((e) => {

      })
    setShowPreview(true);
  }

  return (
    <>

      <div className={"d-flex w-120 gap-2 mb-2"}>
        <a href={"#"} onClick={() => handleClick()}>
          <div className={`border rounded-circle d-flex justify-content-center align-items-center p-2 h-50px w-50px btn-light-primary ${darkModeClass()}`}>
            {isUnread && <small>{getCode()}</small>}
            {!isUnread && <i className={"fa fa-check"}></i>}
          </div>
        </a>
          <div style={{ width: "83%" }}>
            <div className={`single-line-text ${darkModeClass()}`}>
              {isUnread && <strong>{title}</strong>}
              {!isUnread && <span>{title}</span>}

            </div>
            <div className={`text-wrap mb-2 ${darkModeClass()}`} style={{
              maxHeight: 40,
              overflow: 'hidden',
            }}>
              <small>{description}</small>
              <small>{description}</small>
            </div>
            <div className={"d-flex justify-content-between"}>
              <small className={"text-muted"}><i className={"fa fa-clock"} /> {formatDateTimeToDBFormat(created_at)}
              </small>
              {isUnread && (
                <>
                  <button className={'btn btn-xs btn-info'} onClick={()=>{
                    handlePreview()
                  }} disabled={isLoading}>
                    {isLoading && <Spinner animation="border" role="status" size={'sm'} className={'me-2'} />}
                    {!isLoading && <i className={'fa fa-eye me-2 text-white'}></i>}
                    {intl.formatMessage({
                      id: 'Preview',
                    })}
                  </button>
                  <button className={'btn btn-xs btn-primary'} onClick={handleMarkAsRead} disabled={isLoading}>
                    {isLoading && <Spinner animation="border" role="status" size={'sm'} className={'me-2'} />}
                    {!isLoading && <i className={'fa fa-check-circle me-2 text-white'}></i>}
                    {intl.formatMessage({
                      id: 'Dibaca',
                    })}
                  </button>
                </>
              )}
            </div>
            <Modal show={showPreview}>
              <Modal.Header>
                <h4>Notification Preview</h4>
              </Modal.Header>
              <Modal.Body>
                <>
                  {description}
                  <br /> <br />
                  <small className={"text-muted"}><i className={"fa fa-clock"} /> {formatDateTimeToDBFormat(created_at)}</small>
                </>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className={'btn btn-primary'}
                  type={'button'}
                  onClick={() => {
                    setShowPreview(false)
                  }}
                >
                  <i className={'fa fa-thumbs-up'}></i>
                  Ok
                </button>
              </Modal.Footer>
            </Modal>
          </div>
      </div>
      <hr className={'my-2'} />
    </>
  );
};

interface INotificationList {
  isUnread: boolean;
  onSuccess?: any
  onGetTotalNotification?: any
}

const NotificationList = ({isUnread, onSuccess, onGetTotalNotification}: INotificationList) => {
  const [isLoadingNotification, totalNotification, responseDataNotification, responseErrorNotification, getDataNotification] = useGetNotificationList(isUnread);
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosPost(
    getEnv('task')+`/notifications/mark-all-as-read`
  );
  const intl = useIntl();
  const handleMarkAllRead = async() => {
    doSubmit(getEnv('task')+`/notifications/mark-all-as-read/${responseDataNotification[0]?.uuid}`,undefined,undefined).then((res) => {
      toast.success(intl.formatMessage({id:'Berhasil menandai'}))
      getDataNotification()
      onSuccess && onSuccess()
    })
      .catch((e) => {

      })
  }

  useEffect(() => {
      onGetTotalNotification && onGetTotalNotification(totalNotification)
  }, [totalNotification]);

  return (
    <>
    <div className={"overflow-scroll"} style={{
      maxHeight: 400
    }}>
      {isLoadingNotification && <div className={"p-4"}> <Placeholder.Paragraph rows={5} /></div>}
      {!isLoadingNotification && <>
        <ListGroup className={""}>
          <ListGroup.Item>
            {responseDataNotification.length === 0 && (
              <div className={"text-center p-2"}>
                {intl.formatMessage({ id: "Tidak ada notifikasi" })}
              </div>
            )}


            {responseDataNotification?.map((v: any) => {
              return (
                <RowNotifikasi
                  key={"notifikasi" + v.id}
                  uuid={v?.uuid}
                  title={v.title}
                  description={v.description}
                  code={v.source?.split("-")[0]}
                  url={v.url}
                  isUnread={isUnread}
                  created_at={v?.created_at}
                  onSuccess={getDataNotification}
                />
              );
            })}
          </ListGroup.Item>
        </ListGroup>
      </>
      }
    </div>
      {(isUnread) && (
        <div className={"p-2"}>
          <button className={"btn btn-block btn-primary"} type={"button"} onClick={handleMarkAllRead} disabled={isLoading ||  totalNotification === 0}>
            {isLoading && <Spinner animation="border" role="status" size={"sm"} className={"me-2"} />}
            {!isLoading && <i className={"fa fa-check-circle"}></i>}
            {intl.formatMessage({ id: "Tandai Semua Dibaca" })}
          </button>
        </div>
      )}
    </>
  )
}

const HeaderNotification: FC = () => {
  const intl = useIntl();
  const [selectedTab,setSelectedTab] = useState("unread")
  const [isLoadingNotification, totalNotification, responseDataNotification, responseErrorNotification, getDataNotification] = useGetNotificationList(true);
  const [totalUnreadNotification, setTotalUnreadNotification] = useState(0)
  const handleRefresh = () => {
    getDataNotification()
  }

  useEffect(() => {
    setTotalUnreadNotification(totalNotification)
  }, [totalNotification]);

  return (
    <>
      <a
        href="#"
        className={clsx("", "")}
        data-kt-menu-trigger={"hover"}
        data-kt-menu-attach="parent"
        data-kt-menu-placement={"bottom"}
      >
        <i className={`fa fa-bell fs-2 ${darkModeClass()}`}></i>
        {isLoadingNotification && (
          <Spinner animation="border" role="status" size={"sm"} className={"ms-2"} />
        )}
        {(totalUnreadNotification > 0) && (
          <small className="notification-counter text-xs" style={{
            fontSize: "0.7rem",
            fontWeight: "bold"
          }}>{totalUnreadNotification > 100 ? '99+' : totalUnreadNotification}</small>
        )}
      </a>

      <div
        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-0 fs-base w-400px"
        data-kt-menu="true"
      >

        {/* begin::Menu item */}
        <div className="menu-item p-0 my-0  max-h-200px">
          <Nav activeKey={selectedTab} onSelect={setSelectedTab} appearance={"subtle"}>
            <Nav.Item eventKey="unread">{intl.formatMessage({id:"Belum Dibaca"})}</Nav.Item>
            <Nav.Item eventKey="all">{intl.formatMessage({id:"Semua"})}</Nav.Item>
          </Nav>

          {selectedTab === "unread" && (
            <NotificationList isUnread={true} onSuccess={()=>handleRefresh()} onGetTotalNotification={(value:any)=>setTotalUnreadNotification(value)} />
          )}
          {selectedTab === "all" && (
            <NotificationList isUnread={false} onSuccess={()=>handleRefresh()}/>
          )}
        </div>

      </div>
    </>

  );
};
export { HeaderNotification };