import React from "react";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { ThemeModeComponent } from "../../../_metronic/assets/ts/layout";

const BottomToolbarWrapper = ({leftChildren, onGoBack, children}: any) => {
  const navigate = useNavigate();
  const intl = useIntl();
  const showSidebar = () => {
    // hide sidebar
    const elementToClick = document.getElementById('kt_aside_toggle');

    if (elementToClick && elementToClick.classList.contains('active')) {
      // Memicu klik pada elemen
      elementToClick.click();
    }
  }
  return (
    <>
      <div className={"fixed-bottom row px-7"} style={{
        zIndex: 1,
        backgroundColor: ThemeModeComponent.getMode() === 'dark' ? '#1e1e2d' : '#fff',
      }}>
        <div className={"row"}>
          <div className={"col-sm-12 col-md-6 p-4"}>
            {leftChildren}
          </div>
          <div className={"col-sm-12 col-md-6 text-end p-4 flex-1"}>
            {/* {onGoBack && (
              <button
                className={"btn btn-secondary me-3"}
                type={"button"}
                onClick={() => onGoBack && onGoBack()}
              >
                <i className="fa fa-arrow-left"></i>
                {intl.formatMessage({ id: 'Kembali' })}
              </button>
            )} */}
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default BottomToolbarWrapper;