import { ID, KTCard, KTCardBody } from '../../../../../../_metronic/helpers'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import TableXWrpper from "../../../../../components/shared/TableXWrapper";
import SelectX from '../../../../../components/shared/form/SelectX'
import { ApiUrl } from '../../../../../config/Api'
import { getEnv } from "../../../../../../helpers/procurex";
import SwalX from '../../../../../components/shared/SwalX'
import { InputNumber } from "rsuite";
import { Field, Form, Formik } from 'formik'
import { useIntl } from 'react-intl'
import { MetronicErrorMessage, toastError } from '../../../../../../_metronic/helpers'
import { toast } from 'react-toastify'
import axios from 'axios'
import { Button, ButtonGroup, Col, Modal, Row } from "react-bootstrap";
import { useAuth } from "../../../../../../app/modules/auth";
import DatepickerX2 from "../../../../../components/shared/form/DatepickerX2";
import moment from 'moment/moment'
import { ImportExcelModal } from '../../../../../components/shared/import-excel/ImportExcelModal';

const MonitoringAkurasiDataListStockview = () => {
    const intl = useIntl()
    const { currentUser } = useAuth();
    const tablecolumns = [
        {
            Header: "TANGGAL",
            accessor: "tanggal",
            width: 150,
            Cell: ({ value }) => {
                if (!value) return '';
                const date = new Date(value);
                const day = date.getDate().toString().padStart(2, '0');
                const month = (date.getMonth() + 1).toString().padStart(2, '0');
                const year = date.getFullYear();
                return `${day}-${month}-${year}`;
            }
        },
        {
            Header: "MATERIAL GROUP",
            accessor: "material_group",
            width: 200,
        },
        {
            Header: "COMPANY CODE",
            accessor: "company_code",
            width: 200,
        },
        {
            Header: "QUANTITY PROYEKSI",
            accessor: "qty_proyeksi",
            width: 150,
        },
        {
            Header: "QUANTITY REALISASI",
            accessor: "qty_realisasi",
            width: 150,
        },
        {
            Header: "AKURASI",
            accessor: "akurasi",
            width: 150,
        },
    ];

    const excludedRoles = ['admin_anper', 'DaanExp_Anper', 'Gudang_Bahan_Baku_Anper', 'Perencanaan_Pengadaan_Anper', 'Managerial_PI'];
    const [formindex, setFormIndex] = useState(0);

    const [isShowModal, setIsShowModal] = useState(false)
    const [isShowDetail, setIsShowDetail] = useState(false)
    let [itemId, setItemId] = useState(0)
    let [isLoadingDelete, setIsLoadingDelete] = useState(false)
    let [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false)
    const [id, setId] = useState('')
    const [item, setItem] = useState<any>(null)
    const [isConfirmDialog, setIsConfirmDialog] = useState(false)
    const [itemsMasterMaterial, setItemsMasterMaterial] = useState<any>([])
    const [itemsMasterCompany, setItemsCompanyStock] = useState<any>([])
    const [itemLoading, setItemLoading] = useState(false)
    const [isShowImportModal, setIsShowImportModal] = useState(false)

    const loadItem = () => {
        axios
            .get(getEnv("stockview") + `/master-material`)
            .then((res) => {
                setItemsMasterMaterial(res.data.data)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setItemLoading(false)
            })
        axios
            .get(getEnv("stockview") + `/company-stock`)
            .then((res) => {
                setItemsCompanyStock(res.data.data)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setItemLoading(false)
            })
    }

    useEffect(() => {
        loadItem();
    }, [])

    const [totalConsumption, setTotalConsumption] = useState(0);

    const handleOnTotalConsumptionChange = (score: number) => {
        setTotalConsumption(score)
    }

    const [selectedMaterial, setSelectedMaterial] = useState(null);
    const [selectedCompanyCode, setSelectedCompanyCode] = useState(null);

    const handleCompanySelect = (selectedValue: any) => {
        setSelectedCompanyCode(selectedValue);
    };

    const handleMaterialSelect = (selectedValue: any) => {
        setSelectedMaterial(selectedValue);
    };

    const submit = async (values: any) => {
        try {
            const newData = {
                total_consumption: totalConsumption ? totalConsumption : item?.total_consumption || '0',
                company_code: selectedCompanyCode ? selectedCompanyCode : item?.company_code,
                uom: item?.uom ? item?.uom : selectedMaterial ? 'TON' : '',
                start_validity_date: values.start_validity_date || item?.start_validity_date,
                end_validity_date: values.end_validity_date || item?.end_validity_date,
            };

            const response = await axios.put(getEnv("stockview") + `/consumption-list/${id}`, newData)

            if (response.status === 200 || response.status === 201) {
                toast.success(response.data.meta.message)
                setId('')
                setIsShowModal(false)
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.meta?.message || 'Terjadi kesalahan')
        } finally {
            setIsConfirmDialog(false)
            setIsShowDetail(false)
        }
    }

    const deleteItem = async () => {
        try {
            setIsLoadingDelete(true)
            const response: any = await axios.delete(getEnv("stockview") + `/consumption-list/${itemId}`)

            if (response.status === 200) {
                // setFormIndex(formindex + 1)
                setIsConfirmDeleteOpen(false)
                toast.success(response?.data?.meta?.message, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                })
                loadItem()
            }
        } catch (error: any) {
            console.error(error)
            toastError(error.response?.data?.meta?.message || 'Terjadi kesalahan')
        } finally {
            setIsLoadingDelete(false)
        }
    }

    const defaultInitialValues = {
        material_number: '',
        company_code: '',
        total_consumption: '',
        uom: '',
        start_validity_date: '',
        end_validity_date: '',
    };

    const [initialValues, setInitialValues] = useState<any>(defaultInitialValues);
    let company_code_user = currentUser.company_code;
    let jenis_api = "table";
    let role = currentUser.has_roles;

    return (
        <>
            <SwalX
                title={intl.formatMessage({ id: 'DATATABLE.CONFIRM_DELETE' })}
                message={intl.formatMessage({ id: 'DATATABLE.MESSAGE_DELETE' })}
                show={isConfirmDeleteOpen}
                loading={isLoadingDelete}
                onHide={() => setIsConfirmDeleteOpen(false)}
                onSubmit={() => deleteItem()}
            />

            <KTCard className='card border mt-5'>
                <KTCardBody >
                    <TableXWrpper
                        title={"Monitoring Akurasi Data Stockview"}
                        key={formindex}
                        readonly
                        urlIndex={getEnv("stockview") + `/monitoring-akurasi-data`}
                        columns={tablecolumns}
                    />
                </KTCardBody>
            </KTCard>
        </>
    )
}

export { MonitoringAkurasiDataListStockview }
