import { Outlet, Route, Routes } from "react-router-dom";
import { PageLink, PageTitle } from "../../../../../_metronic/layout/core";
import React from "react";
import { MonitoringTenderList } from "./components/MonitoringTenderList";
import { ProposalTenderForm } from "../proposal-tender/ProposalTenderForm";

const masterData = {
  title: 'Tendering',
  path: 'tender',
  isSeparator: false,
  isActive: false,
}
const separator = {
  title: '',
  path: '',
  isSeparator: true,
  isActive: false,
}
const breadcrumbs: Array<PageLink> = [masterData, separator]
const MonitoringTenderPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route index element={<><PageTitle breadcrumbs={breadcrumbs}>Monitoring Proposal Tender</PageTitle><MonitoringTenderList /></>}></Route>
      </Route>
      <Route element={<Outlet />}>
        <Route path={"/:uuid"} element={
          <>
            <PageTitle breadcrumbs={breadcrumbs}>Monitoring Proposal Tender</PageTitle>
            <ProposalTenderForm />
          </>
        }></Route>
      </Route>
    </Routes>
  )
}

export default MonitoringTenderPage
