import {Placeholder} from 'rsuite'
import React, {useEffect, useState} from 'react'
import useRemoteFileAsObjectUrl from '../../../../../hooks/useRemoteFileAsObjectUrl'
import {getEnv} from '../../../../../helpers/procurex'

export const BeritaAcaraAanwijzing = ({aanwijzing}) => {
  const [items,setItems] = useState([] as any);
  const [key, setKey] = useState(0);
  const [selectedItemIndex, setSelectedItemIndex] = useState<number|null>(null);
  const selectedItem = selectedItemIndex !== null ? items[selectedItemIndex] : null
  const {
    objectUrl,
    isLoading: isLoadingObjectUrl,
    setSourceUrl,
    resetObjectUrl,
  } = useRemoteFileAsObjectUrl(selectedItem?.url);

  const setItemsData = () => {
    let tmp = [
      {
        "title": "Berita Acara",
        "subTitle":"",
        "url": `${getEnv('tender')}/aanwijzing/generate-document/${aanwijzing?.uuid}`,
      },
    ]
    setItems(tmp)
    setSelectedItemIndex(0)
  }

  useEffect(() => {
    setItemsData()
  }, [])

  return (
    <div className={"row"}>
      <div className={"col-md-3"}>
        {items && items?.map((item: any, index: number) => (
          <div className={"mb-2 btn-group-vertical w-100"}>
            <div className="btn-group" role="group" aria-label="Basic example">
              <button
                key={index}
                className={"btn btn-sm w-100"}
                style={{
                  backgroundColor: `${selectedItemIndex === index ? "var(--bs-primary)" : "var(--bs-secondary)"}`,
                  color: `${selectedItemIndex === index ? "white" : "black"}`,
                  padding: "11px",
                  border: "#333"
                }}
                disabled={item.url === null || isLoadingObjectUrl || item?.disabled}
                onClick={() => setSelectedItemIndex(index)}
              >
                <div>{item?.title}</div>
                <div>{item?.subTitle}</div>
              </button>
              {/*<div className={"btn-group w-100"}>*/}
              {/*  /!*<button className={"btn btn-sm btn-danger"} type={"button"} onClick={()=>handleOnDelete()}>*!/*/}
              {/*  /!*  <i className={"fa fa-trash"}></i>*!/*/}
              {/*  /!*</button>*!/*/}
              {/*  <button className={"btn btn-sm btn-info"} onClick={()=>handleOnEdit(index)} disabled={isLoadingObjectUrl || isLoadingGetDetail || item?.disabled}>*/}
              {/*    {(isLoadingGetDetail || isLoadingObjectUrl) ? <span className='spinner-border spinner-border-sm me-1' /> : <i className={"fa fa-edit"}></i> }*/}
              {/*  </button>*/}
              {/*  <button className={"btn btn-sm btn-success"} onClick={()=>handleOnDownload(index)} disabled={isLoadingObjectUrl || isLoadingGetDetail|| item?.disabled}>*/}
              {/*    {(isLoadingGetDetail || isLoadingObjectUrl) ? <span className='spinner-border spinner-border-sm me-1' /> : <i className={"fa fa-download"}></i> }*/}
              {/*  </button>*/}
              {/*</div>*/}
              {/*<hr />*/}
            </div>
          </div>
        ))}
      </div>
      <div className={"col-md-9"} key={key}>
        {(isLoadingObjectUrl)
          ? <Placeholder.Graph height={700} active={isLoadingObjectUrl} />
          : selectedItem && objectUrl
            ? <>
              <object width="100%" style={{
                height: "700px"
              }} data={objectUrl} type="application/pdf">
                {" "}
              </object>
            </>
            : <div className={"bg-secondary w-100"} style={{"height": 700, "opacity": "0.6"}}></div>
        }
      </div>
    </div>
  )
}