import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, ButtonGroup, Col, Modal, Row } from "react-bootstrap";
import { ID, KTCard, KTCardBody } from '../../../../../../_metronic/helpers'
import TableXWrpper from "../../../../../components/shared/TableXWrapper";
import axios from 'axios'
import { getEnv } from "../../../../../../helpers/procurex";
import { useAuth } from "../../../../../../app/modules/auth";
import * as XLSX from 'xlsx';

const DetailBuletinStockview = () => {
    const location = useLocation();
    const { currentUser } = useAuth();
    const { id, file_path, judul, jenis_publikasi, tanggal_keluar_publikasi, date_created } = location.state || {};
    const navigate = useNavigate();

    const [activityLog, setActivityLog] = useState([]);
    const [MediaLog, setMediaLog] = useState<{ [key: string]: any }>({});

    const extractedValue = file_path.split('/').slice(-2, -1)[0];

    useEffect(() => {
        loadItem();
        fetchAndReadExcel();
    }, []);

    const loadItem = () => {
        axios.get(getEnv("stockview") + `/log`)
            .then((res) => {
                setActivityLog(res.data.data);
            })
            .catch((error) => {
                console.error("Error fetching logs:", error);
            });
        axios.get(getEnv('media') + `/media/${extractedValue}`)
            .then((res) => {
                setMediaLog(res.data.data);
            })
            .catch((error) => {
                console.error("Error fetching logs:", error);
            });
    };


    const filteredActivityLog = activityLog.filter(log => log.properties?.id === id);
    const lastDownload = filteredActivityLog.length > 0 ? filteredActivityLog[filteredActivityLog.length - 1] : null;

    const handleBackClick = () => {
        navigate('/stockview/price-bulletin/dashboard-price-bulletin');
    };

    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    // untuk ubah tanggal_keluar_publikasi coy
    const date_tanggal_keluar_publikasi = new Date(tanggal_keluar_publikasi);
    const day_tanggal_keluar_publikasi = date_tanggal_keluar_publikasi.getDate().toString().padStart(2, '0');
    const month_tanggal_keluar_publikasi = monthNames[date_tanggal_keluar_publikasi.getMonth()];
    const year_tanggal_keluar_publikasi = date_tanggal_keluar_publikasi.getFullYear();
    const dateFix_tanggal_keluar_publikasi = `${day_tanggal_keluar_publikasi} ${month_tanggal_keluar_publikasi} ${year_tanggal_keluar_publikasi}`;


    // untuk ubah tanggal_keluar_publikasi coy
    const date_date_created = new Date(date_created);
    const day_date_created = date_date_created.getDate().toString().padStart(2, '0');
    const month_date_created = monthNames[date_date_created.getMonth()];
    const year_created = date_date_created.getFullYear();
    const dateFix_date_created = `${day_date_created} ${month_date_created} ${year_created}`;

    const [isLoading, setIsLoading] = useState(false);

    const handleDownload = async () => {
        if (file_path) {
            setIsLoading(true);
            try {
                const response = await fetch(file_path);
                const blob = await response.blob();

                const url = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.download = judul || 'download.pdf';
                link.style.display = 'none';
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);

                const logData = {
                    id,
                    judul,
                    jenis_publikasi,
                    tanggal_keluar_publikasi,
                    date_created,
                    user: {
                        name: currentUser.name,
                        company_code: currentUser.company_code,
                        emp_no: currentUser.employee.emp_no
                    }
                };

                await axios.post(getEnv("stockview") + `/log-download-file/`, logData);
                window.location.reload();
            } catch (error) {
                console.error('Download failed:', error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const [formindex, setFormIndex] = useState(0);
    const [isShowModal, setIsShowModal] = useState(false)
    const openModalWithItem = (item: any) => {
        setIsShowModal(true);
    };

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 3;

    const totalPages = Math.ceil(filteredActivityLog.length / itemsPerPage);

    const currentItems = filteredActivityLog.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const [previewData, setPreviewData] = useState([])
    const fetchAndReadExcel = async () => {
        try {
            // Fetch the file from the URL
            const response = await fetch(file_path);
            const blob = await response.blob();

            // Read the file content
            const reader = new FileReader();
            reader.onload = (event: any) => {
                const data = new Uint8Array(event.target.result);
                const workbook = XLSX.read(data, { type: 'array' });

                // Use the provided sheet name or default to the first sheet
                const sheet = workbook.Sheets[MediaLog?.notes] || workbook.Sheets[workbook.SheetNames[0]];
                if (sheet) {
                    const jsonData = XLSX.utils.sheet_to_json(sheet, {
                        header: 1,
                        blankrows: true,
                        defval: ""
                    });
                    setPreviewData(jsonData);
                } else {
                    console.error('Sheet not found:', MediaLog?.notes);
                }
            };
            reader.readAsArrayBuffer(blob);
        } catch (error) {
            console.error('Error fetching or reading the Excel file:', error);
        }
    };

    return (
        <>
            <Modal size="xl" show={isShowModal} onHide={() => setIsShowModal(false)}>
                <Modal.Header>Informasi Activity Download Publikasi <h1>{judul}</h1></Modal.Header>
                <Modal.Body>
                    <KTCardBody>
                        {currentItems.length > 0 ? (
                            currentItems.map((log, index) => (
                                <div key={index} className="mb-3">
                                    <h5>Download Activity {(currentPage - 1) * itemsPerPage + index + 1}</h5>
                                    <p><strong>Log Name:</strong> {log.log_name}</p>
                                    <p><strong>Description:</strong> {log.description}</p>
                                    <p><strong>User:</strong> {log.properties?.name || 'N/A'} - {log.properties?.emp_no || 'N/A'} - {log.properties?.company_code || 'N/A'}</p>
                                    <p><strong>Event:</strong> {log.event}</p>
                                    <p><strong>Tanggal Download Publikasi:</strong> {log.created_at ?
                                        new Date(log.created_at).toLocaleString() : 'N/A'}
                                    </p>
                                    <p><strong>Jenis Publikasi:</strong> {log.properties?.jenis_publikasi || 'N/A'}</p>
                                    <hr />
                                </div>
                            ))
                        ) : (
                            <p>No download activity found.</p>
                        )}
                    </KTCardBody>
                    {totalPages > 1 && (
                        <div className="pagination">
                            {Array.from({ length: totalPages }, (_, index) => (
                                <button
                                    key={index}
                                    onClick={() => handlePageChange(index + 1)}
                                    className={`btn ${currentPage === index + 1 ? 'btn-primary' : 'btn-secondary'}`}
                                >
                                    {index + 1}
                                </button>
                            ))}
                        </div>
                    )}
                </Modal.Body>
            </Modal>

            <div>
                <div className='d-flex justify-content-between mb-6'>
                    <button className='btn btn-secondary btn-sm fw-bold' onClick={handleBackClick}>
                        Back To Dashboard
                    </button>
                </div>

                <div className='card my-5'>
                    <div className='card-header d-flex align-items-center text-2xl'>
                        <b>DETAIL PUBLIKASI ({judul})</b>
                    </div>
                    <div className='card-body'>
                        <div className='row'>
                            <div className='col-12 col-sm-6'>
                                <div className='mb-6' style={{ height: 'calc(80vh - 50px)' }}>
                                    {MediaLog?.mime == 'application/pdf' ? (
                                        file_path ? (
                                            <iframe
                                                src={`${file_path}#toolbar=0&navpanes=0&scrollbar=0`}
                                                title={judul || 'PDF Viewer'}
                                                style={{ width: '100%', height: '100%', border: 'none' }}
                                            />
                                        ) : (
                                            <p>Loading PDF...</p>
                                        )
                                    ) : (
                                        <>
                                            <h2>Excel Data Preview</h2>
                                            <div className='overflow-auto' style={{ maxWidth: '100%', maxHeight: '600px' }}>
                                                <table className='table table-striped' style={{ tableLayout: 'fixed', width: '100%' }}>
                                                    <tbody>
                                                        {previewData.map((row, rowIndex) => (
                                                            <tr key={rowIndex}>
                                                                {row.map((cell: any, cellIndex: number) => (
                                                                    <td key={cellIndex} style={{ whiteSpace: 'nowrap', maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                                        {cell}
                                                                    </td>
                                                                ))}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 col-sm-6">
                                <div className='mb-6'>
                                    <label htmlFor='' className='form-label'>
                                        Judul Publikasi
                                    </label>
                                    <h1 className="mb-3">{judul}</h1>
                                </div>
                                <div className='mb-6'>
                                    <label htmlFor='' className='form-label'>
                                        Jenis Publikasi
                                    </label>
                                    <h1 className="mb-3">{jenis_publikasi}</h1>
                                </div>
                                <div className='mb-6'>
                                    <label htmlFor='' className='form-label'>
                                        Tanggal Keluar Publikasi
                                    </label>
                                    <h1 className="mb-3">{dateFix_tanggal_keluar_publikasi}</h1>
                                </div>
                                <div className='mb-6'>
                                    <label htmlFor='' className='form-label'>
                                        Tanggal Input Publikasi
                                    </label>
                                    <h1 className="mb-3">{dateFix_date_created}</h1>
                                </div>
                                <div>
                                    {isLoading ? (
                                        <p>Downloading...</p>
                                    ) : (
                                        <button className='btn btn-lg btn-primary col-4' onClick={handleDownload}>
                                            Download Publikasi
                                        </button>
                                    )}
                                    <button
                                        className='btn btn-lg btn-info col-4'
                                        style={{ marginLeft: 15 }}
                                        onClick={(e: any) => {
                                            e.preventDefault();
                                            openModalWithItem(filteredActivityLog);
                                        }}
                                    >
                                        History Download
                                    </button>
                                </div>
                                <div className='mt-4'>
                                    {lastDownload ? (
                                        <div>
                                            <span>
                                                Last downloaded by: {lastDownload.properties.name} - ({lastDownload.properties.emp_no} - {lastDownload.properties.company_code}) di tanggal {new Date(lastDownload.created_at).toLocaleString()}
                                            </span>
                                        </div>
                                    ) : (
                                        <span>No recent downloads found.</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DetailBuletinStockview;
