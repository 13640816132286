import { ID, KTCard, KTCardBody } from '../../../../../../_metronic/helpers'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import TableXWrpper from "../../../../../components/shared/TableXWrapper";
import { ApiUrl } from '../../../../../config/Api'
import { getEnv } from "../../../../../../helpers/procurex";
import { useIntl } from 'react-intl'
import SelectX from '../../../../../components/shared/form/SelectX'
import { MetronicErrorMessage, toastError } from '../../../../../../_metronic/helpers'
import SwalX from '../../../../../components/shared/SwalX'
import { InputNumber } from "rsuite";
import { Field, Form, Formik } from 'formik'
import { toast } from 'react-toastify'
import axios from 'axios'
import { Button, ButtonGroup, Col, Modal, Row } from "react-bootstrap";
import DatepickerX2 from "../../../../../components/shared/form/DatepickerX2";
import { useAuth } from "../../../../../../app/modules/auth";

const MaxStockStockviewList = () => {
    const { currentUser } = useAuth();
    const intl = useIntl()
    const tablecolumns = [
        {
            Header: "MATERIAL NUMBER",
            accessor: "material_number_name",
            width: 250,
        },
        {
            Header: "COMPANY CODE",
            accessor: "company_code_short",
            width: 200,
        },
        {
            Header: "MAX STOCK",
            accessor: "max_stock",
            width: 150,
        },
        {
            Header: "UOM",
            accessor: "uom",
            width: 100,
        },
        {
            Header: "START VADILITY",
            accessor: "start_validity_date",
            width: 150,
            Cell: ({ value }) => {
                if (!value) return ''; // Handle null or undefined values
                const date = new Date(value);
                const day = date.getDate().toString().padStart(2, '0'); // Get day and pad with leading zero if necessary
                const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get month (+1 because month index starts from 0) and pad with leading zero if necessary
                const year = date.getFullYear(); // Get full year
                return `${day}-${month}-${year}`;
            }
        },
        {
            Header: "END VADILITY",
            accessor: "end_validity_date",
            width: 150,
            Cell: ({ value }) => {
                if (!value) return ''; // Handle null or undefined values
                const date = new Date(value);
                const day = date.getDate().toString().padStart(2, '0'); // Get day and pad with leading zero if necessary
                const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get month (+1 because month index starts from 0) and pad with leading zero if necessary
                const year = date.getFullYear(); // Get full year
                return `${day}-${month}-${year}`;
            }
        },
        {
            Header: "Action",
            accessor: "uuid",
            width: 180,
            className: "text-center fixed-column",
            Cell: (props: any) => {
                const row = props.row.original;
                console.log(row);
                return (
                    <ButtonGroup size='sm' aria-label='Action'>
                        {!excludedRoles.some(r => role.includes(r)) && (
                            <>
                                <button
                                    type='button'
                                    className='btn btn-sm btn-success'
                                    onClick={(e: any) => {
                                        e.preventDefault();
                                        setId(row.id);
                                        openModalWithItem(row); // Pass the row item to the function
                                    }}
                                >
                                    Edit
                                </button>
                            </>
                        )}
                    </ButtonGroup>
                );
            },
        },
    ];
    const [formindex, setFormIndex] = useState(0);
    const [isShowModal, setIsShowModal] = useState(false)
    const [isShowDetail, setIsShowDetail] = useState(false)
    const [key, setKey] = useState(0)
    const [id, setId] = useState('')
    const [item, setItem] = useState<any>(null)
    const [isConfirmDialog, setIsConfirmDialog] = useState(false)

    const [totalMaxStock, setTotaMaxStock] = useState(0);
    const [totalQTY, setTotalQTY] = useState(0);
    const [totalContract, setTotalContract] = useState(0);

    const handleOnTotalMaxStockChange = (score: number) => {
        setTotaMaxStock(score)
    }

    const [selectedMaterial, setSelectedMaterial] = useState(null);
    const [selectedCompany, setSelectedCompany] = useState(null);

    const handleMaterialSelect = (selectedValue: any) => {
        setSelectedMaterial(selectedValue);
    };

    const handleCompanySelect = (selectedValue: any) => {
        setSelectedCompany(selectedValue);
    };

    const [itemsMasterMaterial, setItemsMasterMaterial] = useState<any>([])
    const [itemsMasterCompany, setItemsCompanyStock] = useState<any>([])
    const [itemLoading, setItemLoading] = useState(false)
    const excludedRoles = ['DaanExp_Anper', 'Gudang_Bahan_Baku_Anper', 'Managerial_PI', 'RendalProd_Anper'];

    const loadItem = () => {
        axios
            .get(getEnv("stockview") + `/master-material`)
            .then((res) => {
                setItemsMasterMaterial(res.data.data)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setItemLoading(false)
            })
        axios
            .get(getEnv("stockview") + `/company-stock`)
            .then((res) => {
                setItemsCompanyStock(res.data.data)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setItemLoading(false)
            })
    }

    useEffect(() => {
        loadItem();
    }, [])

    const submit = async (values: any) => {
        const { materialnumberLabel } = await processItem(item);

        try {
            const newData = {
                material_number: selectedMaterial ? selectedMaterial : materialnumberLabel,
                company_code: selectedCompany ? selectedCompany : item?.company_code,
                max_stock: totalMaxStock ? totalMaxStock : item?.max_stock,
                uom: item?.uom || '',
                start_validity_date: values.start_validity_date || item?.start_validity_date,
                end_validity_date: values.end_validity_date || item?.end_validity_date,
            };
            console.log(newData);


            const response = await axios.put(getEnv("stockview") + `/max-stock/${id}`, newData)

            if (response.status === 200 || response.status === 201) {
                toast.success(response.data.meta.message)
                setId('')
                setIsShowModal(false)
                // setFormIndex(formindex + 1)
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.meta?.message || 'Terjadi kesalahan')
        } finally {
            setIsConfirmDialog(false)
            setIsShowDetail(false)
        }
    }

    const defaultInitialValues = {
        material_number: '',
        company_code: '',
        plant: '',
        max_stock: '',
        uom: '',
        start_validity_date: '',
        end_validity_date: '',
    };

    const [initialValues, setInitialValues] = useState<any>(defaultInitialValues);
    const processItem = async (item: any) => {
        const materialnumber = item?.material_number || '';
        const materialnumberParts = materialnumber.split(' - ');
        const materialnumberLabel = materialnumberParts[0];

        return { materialnumberLabel };
    };

    const openModalWithItem = async (item: any) => {
        const { materialnumberLabel } = await processItem(item);

        setInitialValues({
            material_number: materialnumberLabel,
            company_code: item?.company_code || '',
            max_stock: item?.max_stock || '0',
            uom: item?.uom || '',
            start_validity_date: item?.start_validity_date || '',
            end_validity_date: item?.end_validity_date || '',
        });
        setItem(item);
        setIsShowModal(true);
    };

    let company_code_user = currentUser.company_code;
    let role = currentUser.has_roles;

    return (
        <>
            <Modal size="xl" show={isShowModal} onHide={() => setIsShowModal(false)}>
                <Modal.Header>Edit Max Stock Stockview</Modal.Header>
                <Modal.Body>
                    <Formik initialValues={initialValues} onSubmit={submit} enableReinitialize>
                        {({ values, setFieldValue }) => (
                            <Form>
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label'>
                                                Material Number
                                            </label>
                                            <SelectX
                                                defaultValue={initialValues.material_number.trim()}
                                                onChange={handleMaterialSelect}
                                                options={itemsMasterMaterial.map((item: any) => ({
                                                    label: `${item.material_number} - ${item.material_desc}`,
                                                    value: item.material_number.trim()
                                                }))}
                                            />
                                            {/* <MetronicErrorMessage name='sap_no' /> */}
                                        </div>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label'>
                                                Company Code
                                            </label>
                                            <SelectX
                                                defaultValue={initialValues.company_code}
                                                onChange={handleCompanySelect}
                                                options={itemsMasterCompany
                                                    .filter((item: any) => {
                                                        if (company_code_user === "A000") {
                                                            return item.active_app === "X" && item.company_code !== "A000";
                                                        } else {
                                                            return item.active_app === "X" && item.company_code !== "A000" && item.company_code === company_code_user;
                                                        }
                                                    })
                                                    .map((item: any) => ({
                                                        label: item.company_code,
                                                        value: item.company_code
                                                    }))
                                                }
                                            />
                                            {/* <MetronicErrorMessage name='npwp_no' /> */}
                                        </div>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label'>
                                                Total Max Stock
                                            </label>
                                            <InputNumber
                                                defaultValue={initialValues.max_stock}
                                                min={0}
                                                name="max_stock"
                                                className={"w-100 text-end"}
                                                onChange={(value: any) => {
                                                    handleOnTotalMaxStockChange(value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label'>
                                                Uom
                                            </label>
                                            <Field className='form-control' name="uom" value={initialValues.uom || ''} disabled />
                                            {/* <MetronicErrorMessage name='sap_no' /> */}
                                        </div>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label'>
                                                Start Validity Date
                                            </label>
                                            <DatepickerX2
                                                onChange={(date: any) => setFieldValue("start_validity_date", date)}
                                                size={"lg"}
                                                value={values.start_validity_date}
                                                format={"dd-MM-yyyy"}
                                            />
                                        </div>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label'>
                                                End Validity Date
                                            </label>
                                            <DatepickerX2
                                                onChange={(date: any) => setFieldValue("end_validity_date", date)}
                                                size={"lg"}
                                                value={values.end_validity_date}
                                                format={"dd-MM-yyyy"}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='d-flex gap-4 mt-5'>
                                    <button type='submit' className='btn btn-primary'>
                                        Save
                                    </button>
                                    <button
                                        type='button'
                                        className='btn btn-secondary'
                                        onClick={() => {
                                            setId('')
                                            setIsShowModal(false)
                                        }}
                                    >
                                        Batal
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>

            <KTCard className='card border mt-5'>
                <KTCardBody >
                    <TableXWrpper
                        title={"MR/SR Unit Kerja"}
                        key={formindex}
                        readonly
                        urlIndex={getEnv("stockview") + `/max-stock?company_code=${company_code_user}`}
                        columns={tablecolumns}
                        headerButtons={
                            !excludedRoles.some(r => role.includes(r)) && (
                                <Link
                                    to="/stockview/transaction/max-stock/create"
                                    className="btn btn-primary"
                                    title="Add Data"
                                >
                                    Add Data
                                </Link>
                            )
                        }
                    />
                </KTCardBody>
            </KTCard>
        </>
    )
}

export { MaxStockStockviewList }
