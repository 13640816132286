import React, {useState} from 'react'
import axios from 'axios'
import { useLocation } from 'react-router-dom'
import { getEnv } from "../../../../helpers/procurex";

interface IMeta {
  message: string
  code: number
}

interface IResponse {
  data: any
  meta: IMeta
}

export const useGetTableList = (url: string, defaultFilter: string | undefined) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [responseData, setResponseData] = useState<any>({})
  const [responseError, setResponseError] = useState<any>({})
  const location = useLocation()

  let text = location.search;
  const myArray = text.split("perPage=25&currentPage=1");
  const filter = myArray[1]

  const getData = async (
      perPage?: number,
      currentPage?: number,
      serializeSearch?: string,
      sortColumn?: string,
      isDesc?: boolean,
  ): Promise<void> => {
    try {
      setIsLoading(true)
      let _url = url + (url.includes('?') ?'&':'?') + `perPage=${perPage ?? 25}&page=${currentPage ?? 1}`
      if (defaultFilter) _url += '&' + defaultFilter
      if (serializeSearch) _url += '&' + serializeSearch
      else if (location.search.includes('filter') && filter !== undefined) _url += filter
      // console.log(location.search.includes('filter'))
      if(sortColumn){
        if(isDesc) {
          _url += `&sort=${sortColumn}`
        } else {
          _url += `&sort=-${sortColumn}`
        }
      }

      const response = await axios.get<IResponse>(_url)
      setResponseData(response.data)
    } catch (err: any) {
      setResponseError(err)
    } finally {
      setIsLoading(false)
    }
  }
  return [isLoading, responseData, responseError, getData] as const
}

export const useGetSelectList = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [responseData, setResponseData] = useState<any>({})
  const [responseError, setResponseError] = useState<any>({})
  const getData = async (url: string,page=1,filterKey="",filterValue=""): Promise<void> => {
    try {
      let _url = url + (url.includes("?")?"&":"?") + 'perPage=1000&' +
        'page=' + page +(filterKey ? `&filter[${filterKey}]=` + filterValue : '')
      setResponseData([])
      setIsLoading(true)
      const response = await axios.get<IResponse>(_url)
      setIsLoading(false)
      setResponseData(response.data)
    } catch (err: any) {
      setResponseError(err)
    } finally {
      setIsLoading(false)
    }
  }
  return [isLoading, responseData, responseError, getData] as const
}

export const useGetSelectDetail = () => {
  const [isLoadingDetail, setIsLoading] = useState<boolean>(false)
  const [responseDataDetail, setResponseData] = useState<any>({})
  const [responseErrorDetail, setResponseError] = useState<any>({})
  const getDataDetail = async (url: string): Promise<void> => {
    try {
      let _url = url + (url.includes("?")?"&":"?") + 'perPage=1000'
      setResponseData([])
      setIsLoading(true)
      const response = await axios.get<IResponse>(_url)
      setIsLoading(false)
      setResponseData(response.data)
    } catch (err: any) {
      setResponseError(err)
    } finally {
      setIsLoading(false)
    }
  }
  return [isLoadingDetail, responseDataDetail, responseErrorDetail, getDataDetail] as const
}

export const useCreate = (url: string) => {
  const [isLoadingCreate, setIsLoading] = useState<boolean>(false)
  const [responseDataCreate, setResponseData] = useState<any>(null)
  const [responseErrorCreate, setResponseError] = useState<any>(null)

  const submitCreate = async (payload: any): Promise<void> => {
    try {
      setResponseError(null)
      setResponseData(null)
      setIsLoading(true)
      const response = await axios.post<IResponse>(url, payload)
      setResponseData(response.data)
    } catch (err: any) {
      setResponseError(err)
    } finally {
      setIsLoading(false)
    }
  }
  return [isLoadingCreate, responseDataCreate, responseErrorCreate, submitCreate] as const
}

export const useUpdate = (url: string) => {
  const [isLoadingUpdate, setIsLoading] = useState<boolean>(false)
  const [responseDataUpdate, setResponseData] = useState<any>(null)
  const [responseErrorUpdate, setResponseError] = useState<any>(null)
  const submitUpdate = async (id: any, payload: any): Promise<void> => {
    try {
      setResponseError(null)
      setResponseData(null)
      setIsLoading(true)
      const response = await axios.put<IResponse>(`${url}/${id}`, payload)
      setResponseData(response.data)
    } catch (err: any) {
      setResponseError(err)
    } finally {
      setIsLoading(false)
    }
  }
  return [isLoadingUpdate, responseDataUpdate, responseErrorUpdate, submitUpdate] as const
}

export const useDelete = (url: string) => {
  const [isLoadingDelete, setIsLoading] = useState<boolean>(false)
  const [responseDataDelete, setResponseData] = useState<any>({})
  const [responseErrorDelete, setResponseError] = useState<any>({})

  const submitDelete = async (id: any): Promise<void> => {
    try {
      setResponseError({})
      setIsLoading(true)
      const response = await axios.delete<IResponse>(`${url}/${id}`)
      setResponseData(response.data)
    } catch (err: any) {
      setResponseError(err)
    } finally {
      setIsLoading(false)
    }
  }
  return [isLoadingDelete, responseDataDelete, responseErrorDelete, submitDelete] as const
}

export const useDetail = () => {
  const [isLoadingDetail, setIsLoading] = useState<boolean>(false)
  const [responseDataDetail, setResponseData] = useState<any>({})
  const [responseErrorDetail, setResponseError] = useState<any>({})

  const getDetail = async (url:string): Promise<void> => {
    try {
      setResponseError({})
      setIsLoading(true)
      const response = await axios.get<IResponse>(url)
      setResponseData(response.data)
    } catch (err: any) {
      setResponseError(err)
    } finally {
      setIsLoading(false)
    }
  }
  return [isLoadingDetail, responseDataDetail, responseErrorDetail, getDetail] as const
}

export const useGetSuperiorEmployee = () => {
  const [isLoadingSuperiorEmployee, setIsLoading] = useState<boolean>(false)
  const [responseSuperiorEmployee, setResponseData] = useState<any>(null)
  const [responseErrorSuperiorEmployee, setResponseError] = useState<any>(null)
  const getSuperiorEmployee = async (grade: string): Promise<void> => {
    try {
      let _url = process.env.REACT_APP_API_URL+"/api/auth/employee/superior?level="
      setResponseData([])
      setIsLoading(true)
      const response = await axios.get<IResponse>(_url+grade)
      setIsLoading(false)
      setResponseData(response.data)
    } catch (err: any) {
      setResponseError(err)
    } finally {
      setIsLoading(false)
    }
  }
  return [isLoadingSuperiorEmployee, responseSuperiorEmployee, responseErrorSuperiorEmployee, getSuperiorEmployee] as const
}

export const useGetDetailRoutingApproval = () => {
  const [isLoadingSuperiorEmployee, setIsLoading] = useState<boolean>(false)
  const [responseSuperiorEmployee, setResponseData] = useState<any>(null)
  const [responseErrorSuperiorEmployee, setResponseError] = useState<any>(null)
  const getSuperiorEmployee = async (grade: string): Promise<void> => {
    try {
      let _url = getEnv('approval')+'/routing-approval/detail'
      setResponseData([])
      setIsLoading(true)
      const response = await axios.get<IResponse>(_url+grade)
      setIsLoading(false)
      setResponseData(response.data)
    } catch (err: any) {
      setResponseError(err)
    } finally {
      setIsLoading(false)
    }
  }
  return [isLoadingSuperiorEmployee, responseSuperiorEmployee, responseErrorSuperiorEmployee, getSuperiorEmployee] as const
}
