import axios, {AxiosResponse} from 'axios'
import React, {useState} from 'react'
import {useQuery} from 'react-query'
import {Placeholder} from 'rsuite'
import {Response} from '../../../../../../_models'
import {getEnv} from '../../../../../../helpers/procurex'
import useRemoteFileAsObjectUrl from '../../../../../../hooks/useRemoteFileAsObjectUrl'

const BeritaAcaraTabContent = ({proposalTenderUuid}: {proposalTenderUuid: string}) => {
  const {data: daftarBeritaAcara} = useQuery(
    ['proposal-tender', proposalTenderUuid, 'ba-hasil-evaluasi'],
    () =>
      axios
        .get(getEnv('tender') + `/clarification/evaluator/berita-acara/${proposalTenderUuid}`)
        .then((res: AxiosResponse<Response<Array<{evaluator_id: number}>>>) => res.data.data)
  )
  const [selectedItemIndex, setSelectedItemIndex] = useState<number>(0)
  const evaluatorId = daftarBeritaAcara?.[selectedItemIndex]?.evaluator_id
  const {objectUrl, isLoading: isLoadingObjectUrl} = useRemoteFileAsObjectUrl(
    evaluatorId
      ? getEnv('tender') +
          `/clarification/evaluator/berita-acara/${proposalTenderUuid}/${evaluatorId}/generate/`
      : undefined
  )

  const title = `Berita Acara Evaluasi ${selectedItemIndex + 1}`

  if (proposalTenderUuid === undefined) {
    return <></>
  }

  return (
    <div className={'row'}>
      <div className={'col-md-3'}>
        {daftarBeritaAcara === undefined && (
          <>
            <Placeholder.Graph height={40} active />
            <Placeholder.Graph height={40} active />
            <Placeholder.Graph height={40} active />
          </>
        )}

        {daftarBeritaAcara?.map((_item, index) => (
          <div className={'mb-2 btn-group-vertical w-100'}>
            <button
              key={index}
              className={'btn btn-sm w-100'}
              style={{
                backgroundColor: `${
                  selectedItemIndex === index ? 'var(--bs-primary)' : 'var(--bs-secondary)'
                }`,
                color: `${selectedItemIndex === index ? 'white' : 'black'}`,
                padding: '11px',
                border: '#333',
              }}
              onClick={() => setSelectedItemIndex(index)}
            >
              <div>Hasil Evaluasi {index + 1}</div>
            </button>
            {/*<hr />*/}
          </div>
        ))}
      </div>
      <div className={'col-md-9'}>
        {isLoadingObjectUrl && <Placeholder.Graph height={700} active />}

        {objectUrl && (
          <object
            width='100%'
            height='700'
            data={objectUrl}
            type='application/pdf'
            aria-label={title}
          ></object>
        )}
      </div>
    </div>
  )
}

export default BeritaAcaraTabContent
