import { Outlet, Route, Routes } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { MapContainer, TileLayer, Marker, Popup, Circle, CircleMarker, FeatureGroup, Polygon, Polyline, Rectangle, useMapEvent, useMapEvents } from 'react-leaflet';
import { useState, useEffect } from 'react'
import L, { LatLngExpression } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css';
import "leaflet-defaulticon-compatibility";

const masterData = {
    title: 'Vessel Management Dashboard',
    path: 'stockview/vessel-management/Dashboard-vessel',
    isSeparator: false,
    isActive: false,
}
const separator = {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
}
const breadcrumbs: Array<PageLink> = [masterData, separator]
const childBreadcrumbs: Array<PageLink> = [
    masterData,
    separator,
    {
        title: 'Stockview Vessel Management Dashboard',
        path: 'stockview/vessel-management/Dashboard-vessel',
        isSeparator: false,
        isActive: false,
    },
    separator,
]

const VesselManagementStockviewPage: React.FC = () => {
    // const center: LatLngExpression = [49.1422, -123.4300]; // Example center
    const marker1: LatLngExpression = [49.1422, -123.4300]; // Example position
    const marker2: LatLngExpression = [3.590218, 91.72813];
    const fillBlueOptions = { fillColor: 'blue' }
    const blackOptions = { color: '#26bd3c' }
    const customOptions = { color: '#26bd3c' }
    const customOptions2 = { color: 'yellow' }
    const limeOptions = { color: 'lime' }
    const purpleOptions = { color: 'purple' }
    const redOptions = { color: 'red' }
    const [center, setCenter] = useState([51.505, -0.09])
    const data = "23.6031 37.9345, 23.6022 37.8302, 23.7127 37.6496, 23.7056 37.6035, 23.6234 37.3639, 23.4216 36.9451, 23.229 36.4224, 23.1209 36.4007, 22.9908 36.4161, 22.8799 36.4056, 22.5453 36.3436, 22.0771 36.3505, 19.8853 36.3859, 17.0453 36.4213, 16.0126 36.4389, 14.5459 36.461, 12.2835 37.0352, 11.0962 37.4966, 10.2338 37.6534, 9.5746 37.6577, 8.8824 37.6447, 7.8552 37.5924, 6.2292 37.4225, 4.3781 37.2391, 3.5815 37.1472, 2.2467 37.0245, 0.1648 36.7609, -1.9391 36.4655, -2.9718 36.3328, -4.021 36.2132, -5.3448 36.0269, -5.5563 35.9713, -5.8612 35.9625, -6.1963 35.9558, -9.0088 36.6948, -9.1681 36.7609, -9.3109 36.884, -9.3713 37.1341, -9.657 38.1043, -9.8602 38.7455, -9.8492 39.0491, -9.8492 39.6818, -9.8383 40.1873, -9.7998 42.3829, -9.7943 42.9444, -9.7778 43.2091, -6.861 47.1486, -5.9876 48.3051, -5.6195 48.7562, -5.1691 48.9442, -4.5428 49.1781, -3.3893 49.6214, -2.9169 49.8026, -2.439 49.8911, -1.5491 50.0501, -0.3571 50.2612, 0.9668 50.4889, 1.3528 50.7125, 1.4177 50.8727, 1.4687 50.906, 1.7071 51.0776, 2.0596 51.2246, 2.2412 51.4745, 3.1406 52.1546, 3.5788 52.4091, 4.1803 52.4844, 4.5456 52.4644, 4.6609 52.4627, 4.6788 52.4526, 4.7173 52.4351, 4.7392 52.4301, 4.7763 52.4251, 4.7955 52.4234, 4.8225 52.4197, 4.8581 52.4147, 4.8793 52.4083, 4.8985 52.389";

    const [vessels, setVessels] = useState({ METADATA: {}, DATA: [] });
    const [vesselsPast, setVessels2] = useState([]);
    const [vesselsPast2, setVessels22] = useState([]);
    const [vesselsFuture, setVesselsFuture] = useState({ METADATA: {}, DATA: [] });

    let apiUrl = process.env.REACT_APP_API_VESSEL;
    let apiPASTUrl = process.env.REACT_APP_API_VESSEL_PAST_ROUTES;
    let apiPASTUrl2 = process.env.REACT_APP_API_VESSEL_PAST_ROUTES_2;
    let apiFUTUREUrl = process.env.REACT_APP_API_VESSEL_FUTURE_ROUTES;
    let apiKey = process.env.REACT_APP_API_KEY_VESSEL;

    const [polylines, setPolylines] = useState([]);
    const [polylinesPast, setPolylinesPast] = useState([]);
    const [polylinesPast2, setPolylinesPast2] = useState([]);
    const [polylinesFuture, setPolylinesFuture] = useState([]);
    const [shipnamee, setShipname] = useState([]);
    const [imo, setImo] = useState([]);
    const [eta, setEta] = useState([]);
    const [destination, setDestination] = useState([]);
    const [flag, setFlag] = useState([]);
    const [draught, setDraught] = useState([]);
    const [typeShip, setTypeShip] = useState([]);
    const [selectedShipId, setSelectedShipId] = useState(null);
    const [multiPolyline, setMultiPolyline] = useState([
        [
            [51.5, -0.1],
            [51.5, -0.12],
            [51.52, -0.12],
        ],
        [
            [51.5, -0.05],
            [51.5, -0.06],
            [51.52, -0.06],
        ],
    ] as LatLngExpression[][])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(apiUrl);
                const response2 = await fetch(apiPASTUrl);
                const response22 = await fetch(apiPASTUrl2);
                const response3 = await fetch(apiFUTUREUrl);

                if (!response.ok) throw new Error('Network response 1 was not ok');
                if (!response2.ok) throw new Error('Network response 2 was not ok');
                if (!response22.ok) throw new Error('Network response 22 was not ok');
                if (!response3.ok) throw new Error('Network response 3 was not ok');

                const data = await response.json();
                const data2 = await response2.json();
                const data22 = await response22.json();
                const data3 = await response3.json();

                setVessels(data);
                setVessels2(data2);
                setVessels22(data22);
                setVesselsFuture(data3);

                const coordsArray = data.DATA.map(item => ({
                    lat: parseFloat(item.LAT),
                    lng: parseFloat(item.LON)
                }));

                const coordsArrayPast = data2.map(item => ({
                    id: item.SHIP_ID,
                    lat: parseFloat(item.LAT),
                    lng: parseFloat(item.LON)
                }));

                const coordsArrayPast2 = data22.map(item => ({
                    id: item.SHIP_ID,
                    lat: parseFloat(item.LAT),
                    lng: parseFloat(item.LON)
                }));

                // const coordsArrayFuture3 = data3.map(item => ({
                //     lat: item.FINAL_PATH
                // }));
                const coordsArrayFuture3 = data3.map(item => {
                    const linestring = item.FINAL_PATH;
                    const coords = linestring.match(/\(([^)]+)\)/)[1]; // Extract the coordinates inside the parentheses
                    const coordsArray = coords.split(', ').map(coord => {
                        const [lng, lat] = coord.split(' ').map(parseFloat);
                        return { lat, lng }; // Return an object with lat and lng properties
                    });
                    return coordsArray;
                });

                // Flatten the array of arrays if necessary (only if you want a single array of coordinates)
                const flatCoordsArrayFuture3 = coordsArrayFuture3.flat();

                setPolylines(coordsArray);
                setPolylinesPast(coordsArrayPast);
                setPolylinesPast2(coordsArrayPast2);
                setPolylinesFuture(flatCoordsArrayFuture3);

                const shipname = data.DATA.map(item => item.SHIPNAME);
                setShipname(shipname);

                const imo = data.DATA.map(item => item.IMO);
                setImo(imo);

                const eta = data.DATA.map(item => item.ETA);
                setEta(eta);

                const destination = data.DATA.map(item => item.DESTINATION);
                setDestination(destination);

                const flag = data.DATA.map(item => item.FLAG);
                setFlag(flag);

                const draught = data.DATA.map(item => item.DRAUGHT);
                setDraught(draught);

                const typeName = data.DATA.map(item => item.
                    TYPE_NAME);
                setTypeShip(typeName);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [apiUrl, apiPASTUrl, apiPASTUrl2]);

    const handleMarkerClick = (shipId) => {
        setSelectedShipId(shipId);
    };

    const MapClickHandler = () => {
        useMapEvents({
            click: () => {
                setSelectedShipId(null); // Reset selected ship ID on map click
            },
        });
        return null;
    };

    const customIcon = new L.Icon({
        iconUrl: 'https://img.icons8.com/?size=100&id=M6ngXcukVH0k&format=png&color=000000',
        iconSize: [35, 35], // Adjust the size as needed
        iconAnchor: [12, 12], // Adjust the anchor point as needed
        popupAnchor: [0, -12] // Adjust the popup anchor point as needed
    });

    // useEffect(() => {
    //     const coordsArray = data.split(", ").map(item => {
    //         const coords = item.split(" ");
    //         return [parseFloat(coords[1]), parseFloat(coords[0])];
    //     });
    //     // @ts-ignore
    //     setPolylines(coordsArray);
    // }, [])

    console.log(vessels);
    console.log(vesselsPast);
    // console.log(vesselsFuture);
    console.log(vessels.DATA);
    console.log(polylines);
    console.log(polylinesPast);
    console.log(shipnamee);
    console.log(selectedShipId);
    console.log(polylinesFuture);

    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    index
                    element={
                        <>
                            <PageTitle breadcrumbs={breadcrumbs}>Dashboard Vessel Stockview</PageTitle>
                            <div className='card border '>
                                {/* <MapContainer center={[51.505, -0.09]} zoom={5} scrollWheelZoom={false} */}
                                <MapContainer center={[5.932664069026318, 107.9545671722174]} zoom={5} scrollWheelZoom={false}
                                    style={{ height: '78vh', width: '100%' }}
                                >
                                    <TileLayer
                                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                    {/*<Circle center={[51.505, -0.09]} pathOptions={fillBlueOptions} radius={200} />*/}
                                    {/*<CircleMarker center={[51.51, -0.12]} pathOptions={redOptions} radius={20}>*/}
                                    {/*  <Popup>Popup in CircleMarker</Popup>*/}
                                    {/*</CircleMarker>*/}
                                    {/* <Polyline pathOptions={customOptions} positions={polylinesPast} /> */}
                                    {/* <Marker position={marker2}>
                                        <Popup>
                                            Route Forecast {shipnamee[0]}
                                        </Popup>
                                    </Marker> */}
                                    {polylines.map((item, index) => {
                                        const position: LatLngExpression = [item.lat, item.lng];

                                        console.log(index);

                                        // Dynamically determine which dataset to use
                                        const vessel = index === 1 ? vesselsPast[index] : (index === 2 ? vesselsPast2[index] : '');

                                        console.log(vessel);

                                        return (
                                            <Marker
                                                key={index}
                                                icon={customIcon}
                                                position={position}
                                                eventHandlers={{ click: () => handleMarkerClick(vessel.SHIP_ID) }}>
                                                <Popup>
                                                    Ship Position <strong>{shipnamee[index]}</strong> <br /> <br />
                                                    IMO : <strong>{imo[index]}</strong> <br />
                                                    ETA : <strong>{eta[index]}</strong> <br />
                                                    Destination : <strong>{destination[index]}</strong><br />
                                                    Flag : <strong>{flag[index]}</strong><br />
                                                    Draught : <strong>{draught[index]}</strong><br />
                                                    Type Name : <strong>{typeShip[index]}</strong><br />
                                                </Popup>
                                            </Marker>
                                        );
                                    })}
                                    {selectedShipId && (
                                        <Polyline
                                            pathOptions={customOptions}
                                            positions={(polylinesPast)
                                                .filter(item => item.id === selectedShipId)
                                                .map(item => [item.lat, item.lng])}
                                        />
                                    )}
                                    {selectedShipId && (
                                        <Polyline
                                            pathOptions={customOptions}
                                            positions={(polylinesPast2)
                                                .filter(item => item.id === selectedShipId)
                                                .map(item => [item.lat, item.lng])}
                                        />
                                    )}
                                    {selectedShipId && (
                                        <Polyline
                                            pathOptions={customOptions2}
                                            positions={(polylinesFuture)
                                                .filter(item => item.SHIP_ID !== 1)
                                                .map(item => [item.lat, item.lng])}
                                        />
                                    )}

                                    <MapClickHandler />
                                    {/* {polylines && polylines.length > 0 && (
                                        <Marker position={[parseFloat(polylines[polylines.length - 1][1]), parseFloat(polylines[polylines.length - 1][0])]}>
                                            <Popup>End Point</Popup>
                                        </Marker>
                                    )} */}

                                    {/* <Polyline pathOptions={blackOptions} positions={multiPolyline} /> */}
                                    {/*<Polygon pathOptions={purpleOptions} positions={polygon} />*/}
                                    {/*<Polygon pathOptions={purpleOptions} positions={multiPolygon} />*/}
                                    {/*<Rectangle bounds={rectangle} pathOptions={blackOptions} />*/}
                                </MapContainer>
                            </div>
                        </>
                    }
                ></Route>
            </Route>
        </Routes>
    );
};

export default VesselManagementStockviewPage;
