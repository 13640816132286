import React, { useEffect, useRef, useState } from "react";
import {useParams} from "react-router-dom";
import {Placeholder} from "rsuite";
import useRemoteFileAsObjectUrl from "../../../../../../hooks/useRemoteFileAsObjectUrl";
import useGet from "../../../../../../hooks/useGet";
import { getEnv } from "../../../../../../helpers/procurex";
const TENDER_BASE_URL = getEnv("tender");

export const BeritaAcaraPengumumanPemenangTab = ({proposalTender}: any) => {
  const { uuid: proposalTenderUuid } = useParams()
  const [key, setKey] = useState(0);

  const isBADURDisabled = () => {
    return false;
  }

  const [items,setItems] = useState([] as any);

  const [selectedItemIndex, setSelectedItemIndex] = useState<number|null>(0);
  const selectedItem = selectedItemIndex !== null ? items[selectedItemIndex] : null
  const { objectUrl, isLoading: isLoadingObjectUrl} = useRemoteFileAsObjectUrl(selectedItem?.url);
  const [{doGetDetail, data: objectData, isLoading: isLoadingGetDetail}] = useGet();

  const setItemsData = () => {
    let tmp = [
      {
        "title": "Pengumuman Pemenang",
        "subTitle":"",
        "url": `${TENDER_BASE_URL}/awarding-announcement/generate-document/${proposalTenderUuid}`,
        url_submit_ba_note: `${TENDER_BASE_URL}/awarding-announcement/generate-document/${proposalTenderUuid}`,
        disabled: isBADURDisabled(),
        isShowPIC: false
      },
    ]
    setItems(tmp)
  }

  useEffect(() => {
    if(proposalTenderUuid){
      setItemsData()
    }
  },[proposalTenderUuid])


  return (
    <div className={"row"}>
      <div className={"col-md-3"}>
        {items && items?.map((item:any, index:number) => (
          <div className={"mb-2 btn-group-vertical w-100"}>
            <div className="btn-group" role="group" aria-label="Basic example">
              <button
                key={index}
                className={"btn btn-sm w-100"}
                style={{
                  backgroundColor: `${selectedItemIndex === index ? "var(--bs-primary)" : "var(--bs-secondary)"}`,
                  color: `${selectedItemIndex === index ? "white" : "black"}`,
                  padding: "11px",
                  border: "#333"
                }}
                disabled={item.url === null || isLoadingObjectUrl || isLoadingGetDetail || item?.disabled}
                onClick={() => setSelectedItemIndex(index)}
              >
                <div>{item?.title}</div>
                <div>{item?.subTitle}</div>
              </button>
            </div>
          </div>
        ))}
      </div>
      <div className={"col-md-9"} key={key}>
        {(isLoadingObjectUrl || isLoadingGetDetail)
          ? <Placeholder.Graph height={700} active={isLoadingObjectUrl || isLoadingGetDetail} />
          : selectedItem && objectUrl
            ? <>
              <object width="100%" style={{
                height: "700px"
              }} data={objectUrl} type="application/pdf">
                {" "}
              </object>
            </>
            : <div className={"bg-secondary w-100"} style={{ "height": 700, "opacity": "0.6" }}></div>
        }
      </div>
    </div>
  );
};