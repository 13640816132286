import * as Yup from "yup";
import React, { useEffect, useRef, useState } from "react";
import { Field, Form, Formik } from "formik";
import { getEnv, parseStringToBool } from "../../../../../../helpers/procurex";
import { useAxiosPost } from "../../../../../../hooks/useAxios";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import FormAttachment from "../../../../../components/shared/form/FormAttachment";
import { Radio, RadioGroup } from "rsuite";
import { useIntl } from "react-intl";
import { MetronicErrorMessage } from "../../../../../../_metronic/helpers";


const RegistrasiTenderPage = ({ proposalTender, onRefresh }: any) => {
  const [initialValues, setInitialValues] = useState({
    proposal_tender_id: proposalTender?.uuid,
    proposal_tender_no: proposalTender?.no,
    description: proposalTender?.title,
    attachment: proposalTender?.vendor_rejection?.media_url ?? "",
    note: proposalTender?.vendor_rejection?.note ?? "",
    date: "",
    response: ""
  });
  const intl = useIntl();
  const navigate = useNavigate();
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosPost(
    getEnv("tender") + `/vendor/published/registration/${proposalTender.uuid}`
  );
  const formSchemaValidation = Yup.object().shape({
    proposal_tender_id: Yup.string().required("Required"),
    response: Yup.string().required(`${intl.formatMessage({id:'Registrasi Tender'})} is required`),
    note: Yup.string().when('response', {
      is: (response: any) => response && response === "0",
      then: Yup.string().required(`${intl.formatMessage({id:'Alasan Tidak Registrasi Tender'})} is required`),
      otherwise: Yup.string().nullable(),
    }),
    attachment: Yup.mixed().when('response', {
      is: (response: any) => response && response === "0",
      then: Yup.mixed()
        .test('is-required', 'File is required', (value) => {
          return value !== "";
        })
        .required('File is required'),
      otherwise: Yup.mixed().nullable(),
    }),
  });
  const [vendorStatus, setVendorStatus] = useState(proposalTender?.vendor_status);
  const [formIndex, setFormIndex] = useState(0);
  const formik = useRef({} as any);

  const checkIsFormEnabled = () => {
    return vendorStatus === "INVITED" &&
      proposalTender?.published_action?.registration;
  };
  const getJadwalRegistrasiTender = () => {
    let date = "";
    proposalTender?.jadwals.map((jadwal: any) => {
      if (jadwal.description === "Registrasi") {
        date = jadwal.start_at + " s/d " + jadwal.end_at;
      }
    });
    return date;
  };

  const handleSubmit = (values: any, { setStatus, setSubmitting }: any) => {
    if (!proposalTender?.published_action?.registration && parseStringToBool(values.response)) {
      Swal.fire({
        title: intl.formatMessage({id:'Tidak dalam masa Registrasi'}),
        icon: "error",
      }).then((result) => {
      });
    } else {
      Swal.fire({
        title: intl.formatMessage({id:'Apakah Anda yakin'})+"?",
        html: parseStringToBool(values.response) ?
          intl.formatMessage({id:'Anda akan registrasi tender ini'}) :
          intl.formatMessage({id:'Anda tidak akan registrasi tender ini'}),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: intl.formatMessage({id:'Ya, Submit'}),
        cancelButtonText: intl.formatMessage({id:'Tidak'}),
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          const payload = values;
          if (values.attachment) {
            // @ts-ignore
            payload["media_type"] = {
              ...values.attachment,
              type: values.attachment.type.name
            };
            // @ts-ignore
            payload["media_url"] = values.attachment.url;
          }
          // onRefresh && onRefresh(false);
          doSubmit({
            ...values
          }, null, toast).then((res: any) => {
            if(values.response === "1") {
              setVendorStatus("REGISTRATION");
            } else {
              setVendorStatus("REJECTED");
            }
            onRefresh && onRefresh(false);

          });
        }
      });
    }

  };

  const initForm = () => {
    // default value berdasarkan status
    const trueStatus = ["REGISTRATION","REGISTRATION-QUOTATION","REJECT-QUOTATION","NEGOTIATION","NEGOTIATION_PROCESS","WINNER"];
    const falseStatus = ["REJECT-INVITATION"]
    if(trueStatus.includes(proposalTender?.vendor_status)){
      setInitialValues({
        ...initialValues,
        date: getJadwalRegistrasiTender(),
        response: "1"
      });
    } else if(falseStatus.includes(proposalTender?.vendor_status)){
      setInitialValues({
        ...initialValues,
        date: getJadwalRegistrasiTender(),
        response: "0"
      });
    } else {
      setInitialValues({
        ...initialValues,
        date: getJadwalRegistrasiTender(),
        response: ""
      });
    }
    setFormIndex(formIndex + 1);
  }

  useEffect(() => {
    initForm();
  }, [proposalTender, proposalTender?.vendor_status]);

  return (
    <>
      <div className={"card"}>
        <div className={"card-body"}>
          <div className={"d-flex justify-content-between"}>
            <div><h3>{intl.formatMessage({id:'Registrasi Tender'})}</h3></div>

          </div>
          <hr />
          <Formik
            innerRef={formik}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={formSchemaValidation}
            enableReinitialize
            validateOnChange={true}
          >
            {({ errors, touched, values, setFieldValue, resetForm, submitForm }: any) => (
              <Form id="form-register-tender">
                <div className={"row"}>
                  <div className={"col-md-8 col-sm-12 form-group mb-3"}>
                    <div className={"form-group mb-4"}>
                      <label className="required form-label">
                        {intl.formatMessage({id:'No. Proposal Tender'})}
                      </label>
                      <Field
                        name="proposal_tender_no"
                        type="text"
                        className="form-control"
                        disabled={true}
                      />

                    </div>
                    <div className={"form-group mb-4"}>
                      <label className="required form-label">
                        {intl.formatMessage({id:'Deskripsi'})}
                      </label>
                      <Field
                        name="description"
                        type="text"
                        as={"textarea"}
                        className="form-control"
                        disabled={true}
                      />

                    </div>
                    <div className={"form-group mb-4"}>
                      <label className="required form-label">
                        {intl.formatMessage({id:'Jadwal'})}
                      </label>
                      <Field
                        name="date"
                        type="text"
                        className="form-control"
                        disabled={true}
                      />
                    </div>
                    <div className={"form-group mb-4"}>
                      <label className="required form-label">
                        {intl.formatMessage({id:'Registrasi Tender'})}
                      </label>
                      <RadioGroup name="radioList" className={"gap-0"} value={values?.response}>
                        <Radio value="1" className={""} style={{ marginBottom: "-10px" }}
                               onChange={(e: any) => {
                                setFieldValue("note", "")
                                setFieldValue("attachment", "")
                                setFieldValue("response", e)
                               }}
                               readOnly={!checkIsFormEnabled()}
                        >
                          {intl.formatMessage({id:'Register'})}
                        </Radio>
                        <Radio
                          value="0"
                          className={""}
                          onChange={(e: any) => setFieldValue("response", e)}
                          readOnly={!checkIsFormEnabled()}
                        >
                          {intl.formatMessage({id:'Tidak Registrasi'})}
                        </Radio>
                      </RadioGroup>
                      <MetronicErrorMessage name={`response`} />
                    </div>
                    {(values.response === "0") && (
                      <>
                        <div className={"form-group mb-4"}>
                          <label className="required form-label">
                            {intl.formatMessage({id:'Alasan Tidak Registrasi Tender'})}
                          </label>
                          <Field
                            name="note"
                            type="text"
                            as={"textarea"}
                            className="form-control"
                            disabled={parseStringToBool(values.response) || !checkIsFormEnabled()}
                            value={values?.note}
                          />
                          <MetronicErrorMessage name={`note`} />
                        </div>

                        <FormAttachment
                          media_type_filter={"TENDER_ATTACHMENT"}
                          isDisabled = {parseStringToBool(values.response) || !checkIsFormEnabled()}
                          onlyFile={true}
                          afterSubmit={(e: any) => {
                            setFieldValue("attachment", e);
                          }}
                          previewUrl={values?.attachment}
                          withPreviewButton={true}
                          overWriteErrMessage={true}
                        />
                        <MetronicErrorMessage name={`attachment`} />
                      </>
                    )}
                    <div className={"form-group mb-4 text-end"}>
                      <button
                        type="submit"
                        className="btn btn-primary btn btn-sm"
                        disabled={isLoading || !checkIsFormEnabled()}
                      >
                        {isLoading && (
                          <i className={"fa fa-spinner fa-spin"}></i>
                        )}
                        {!isLoading && (
                          <i className={"fa fa-save"}></i>
                        )}
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default RegistrasiTenderPage;