import React, { useEffect, useState } from "react";
import { useField } from "formik";
import axios from "axios";
import { GetOptionLabel, GetOptionValue } from "react-select/dist/declarations/src";
import { AsyncPaginate } from "react-select-async-paginate";
import Select from "react-select/dist/declarations/src/Select";
import { ThemeModeComponent } from "../../../../_metronic/assets/ts/layout";
import { darkModeClass } from "../../../../helpers/utils";

interface FormikSelectProps {
  label?: any;
  name: string;
  optionsUrl: string;
  getOptionLabel: GetOptionLabel<React.SetStateAction<any>>;
  getOptionValue: GetOptionValue<React.SetStateAction<any>>;
  onChange?: (value: any) => void;
  onChangeValue?: Function;
  unique_property?: string;
  border?: boolean;
  fontSize?: string;
  width?: string;
  defaultValue?: any;
  search_property?: string | Array<string>;
  not_required?: boolean;

  [key: string]: any;
}

const FormikSelect3: React.FC<FormikSelectProps> = ({
                                                      label,
                                                      optionsUrl,
                                                      getOptionLabel,
                                                      getOptionValue,
                                                      onChange,
                                                      unique_property,
                                                      search_property,
                                                      defaultValue,
                                                      not_required,
                                                      ...props
                                                    }) => {
  const [field, meta, { setValue: setFormikFieldValue }] = useField(props);
  const [key, setKey] = useState(1);
  const [initValue, setInitValue] = useState(null);
  const handleChangeData = (newValue: any) => {
    if (props.isMulti) {
      let values = newValue.map((item: any) => ({
        ...item.value
      }));

      setFormikFieldValue(getOptionValue(newValue) || null);
      if (props.onChangeValue) {
        // @ts-ignore
        props.onChangeValue(values);
      }
    } else {
      if (props.onChangeValue) {
        // @ts-ignore
        props.onChangeValue(newValue);
      }
      setFormikFieldValue(newValue?.value || null);
    }
  };

  const loadData = async (searchQuery: string, loadedOptions: any, { page }: any) => {
    const config: any = {
      params: {
        page: page
      }
    };

    if (search_property !== undefined) {
      if (typeof search_property === "string") {
        config.params[`filter[${search_property}]`] = searchQuery;
      } else {
        for (const property of search_property) {
          config.params[`filter[${property}]`] = searchQuery;
        }
      }
    }

    const response = await axios.get(optionsUrl, config);
    let responseJSON = response.data.data;
    responseJSON.map((v: any) => {
      v.disabled = false;
      // console.log(field.value.length)
      if (field?.value && field?.value?.length > 0) {
      //   field?.value?.map((item: any) => {
      //     if (item?.id === v?.id) {
      //       v.disabled = true;
      //     }
      //   });
      }
      return v;
    });

    return {
      options: responseJSON.map((option: any) => ({
        value: getOptionValue(option),
        label: getOptionLabel(option),
        isDisabled: option.disabled
      })),
      hasMore: response.data.meta.current_page < response.data.meta.last_page,
      additional: {
        page: searchQuery ? 2 : page + 1
      }
    };
  };

  useEffect(() => {
    if (meta.value) {
      let defaultValue = meta.value;
      if (!props.isMulti) {
        defaultValue = {
          value: getOptionValue(meta),
          label: getOptionLabel(meta)
        };
      } else {
        defaultValue = meta.value.map((item: any) => ({
          value: getOptionValue(item),
          label: getOptionLabel(item)
        }));
      }
      // @ts-ignore
      setInitValue(defaultValue);

      setKey(key + 1);
    } else {
      setInitValue(null);
      setKey(key + 1);
    }
  }, [meta.value]);

  // @ts-ignore
  return (
    <div className={"w-100"}>
      <div className="form-group w-100" key={key}>
        {label && (
          <label htmlFor={props.name} className={`form-label ${!not_required ? "required" : ""}`}>
            {label}
          </label>
        )}

        <AsyncPaginate
          debounceTimeout={500}
          loadOptions={loadData}
          className={darkModeClass()}
          styles={{
            menuPortal: (base) => ({...base, zIndex: 2000}),
            option: (base, state) => ({
              ...base,
              backgroundColor: state.isSelected ? "#f0f0f0" : "white",
              color: "black"
            }),
            singleValue: (baseStyles) => ({
              ...baseStyles,
              color: "black"
            }),
            control: (baseStyles) => ({
              ...baseStyles,
              color: 'black !important',
              backgroundColor: props?.isDisabled ? '#eff2f5' : 'transparent',
              padding: '0.2rem',
              border: props.border === false ? 'none' : '1px lightgray solid',
              fontSize: props.fontSize ? props.fontSize : '9.5pt',
              width: props.width ? props.width : '100%',
              maxWidth: props.width ? props.width : '100%',
              borderRadius: props.borderRadius ? props.borderRadius : '4px',
              // zIndex: 1000
            }),
            menuList: (base, state) => {
              return {
                ...base,
                fontSize: props.fontSize ? props.fontSize : '9.5pt',
                zIndex: 9999,
              }
            },
            multiValue: (base) => ({
              ...base,
              maxWidth: '400px',
            }),
          }}
          {...props}
          defaultValue={initValue}
          onChange={handleChangeData}
          onBlur={field.onBlur}
          isClearable={true}
          additional={{
            page: 1
          }}
        />

        {meta.touched && meta.error ? (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{meta.error}</span>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default FormikSelect3;
