import React, {useEffect, useState} from 'react'
import Select from 'react-select'
import {useField} from 'formik'
import CreatableSelect from 'react-select/creatable'
import axios from 'axios'
import {GetOptionLabel, GetOptionValue} from 'react-select/dist/declarations/src'

interface FormikSelectProps {
  label?: string
  name: string
  optionsUrl: string
  getOptionLabel: GetOptionLabel<React.SetStateAction<any>>
  getOptionValue: GetOptionValue<React.SetStateAction<any>>
  onChange?: (value: any) => void
  createable?: boolean
  unique_property?: string
  [key: string]: any
}

const FormikSelect: React.FC<FormikSelectProps> = ({
  label,
  optionsUrl,
  getOptionLabel,
  getOptionValue,
  onChange,
  createable,
  unique_property,
  ...props
}) => {
  const [field, meta] = useField(props)
  const [options, setOptions] = useState<any[]>([])
  const [value, setValue] = useState<any>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    axios
      .get(optionsUrl, {
        params: {
          // perPage: 20,
        },
      })
      .then((response) => {
        const options = response.data.data.map((option: any) => ({
          value: getOptionValue(option),
          label: getOptionLabel(option),
        }))
        setOptions(options)
        // setValue(
        //   options.find((option: any) => {
        //     if (typeof option?.value === 'object') {
        //       return option?.value.id === field?.value?.id
        //     }
        //     return option?.value === field?.value
        //   })
        // )
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [optionsUrl])

  const handleChange = (option: any) => {
    const value = option?.value ?? ''
    const event = {
      target: {
        name: field.name,
        value,
      },
    }
    field.onChange(event)
    if (onChange) onChange(option?.value || '')

    setValue(
      options.find((option: any) => {
        if (field?.value) {
          if (typeof value === 'object') {
            if (unique_property) return value[unique_property] === field?.value[unique_property]
            return value?.id === field?.value?.id
          }
          return value === field?.value
        }
      })
    )
  }

  return (
    <div className='form-group'>
      {/* @ts-ignore */}
      {label && (
        <label htmlFor={props.name} className='required form-label'>
          {label}
        </label>
      )}
      {createable && (
        <>
          <CreatableSelect
            placeholder={loading ? 'Loading...' : 'Select...'}
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                backgroundColor: 'transparent',
                padding: '0.2rem',
              }),
            }}
            {...field}
            {...props}
            options={options}
            value={value}
            onChange={handleChange}
            onBlur={field.onBlur}
            isLoading={loading}
          />
        </>
      )}

      {!createable && (
        <>
          {/*{JSON.stringify(value)}*/}
          <Select
            placeholder={loading ? 'Loading...' : 'Select...'}
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                backgroundColor: 'transparent',
                padding: '0.2rem',
              }),
            }}
            {...field}
            {...props}
            options={options}
            value={value}
            onChange={handleChange}
            onBlur={field.onBlur}
            isLoading={loading}
          />
        </>
      )}
      {meta.touched && meta.error ? (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{meta.error}</span>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default FormikSelect
