import moment from 'moment'

export function formatNumber(number) {
  if(number) {
    return (new Intl.NumberFormat().format(number)).toString().replace(/,/g, '.')
  }
  return 0;
}

export function formatNumberToDecimal(number, decimal=3) {
  if(typeof number === 'number')
    return parseFloat(number).toFixed(decimal).toString().replace(/,/g, '.')
  if(number){
    return parseFloat(number.toString()).toFixed(decimal).toString().replace(/,/g, '.')
  }
  return parseFloat(0).toFixed(decimal).toString().replace(/,/g, '.')
}

export function formatNumberToDecimalAuction(number, decimal=3) {
  if(typeof number === 'number')
    return parseFloat(number).toFixed(decimal).toString().replace(/,/g, '.')
  if(number){
    return parseFloat(number.replace(/,/g, '.').toString()).toFixed(decimal).toString().replace(/,/g, '.')
  }
  return parseFloat(0).toFixed(decimal).toString().replace(/,/g, '.')
}

export function getEnv(type){
  let url = "";
  if(type.toLowerCase() === "mrsr") url = process.env.REACT_APP_API_MRSR_URL;
  else if(type.toLowerCase() === "masterdata") url = process.env.REACT_APP_API_MASTERDATA_URL;
  else if (type.toLowerCase() === "sap") url = process.env.REACT_APP_API_SAP_URL;
  else if(type.toLowerCase() === "media") url = process.env.REACT_APP_API_MEDIA_URL;
  else if(type.toLowerCase() === "task") url = process.env.REACT_APP_API_TASK_URL;
  else if(type.toLowerCase() === "approval") url = process.env.REACT_APP_API_APPROVAL_URL;
  else if(type.toLowerCase() === "tender") url = process.env.REACT_APP_API_TENDER_URL;
  else if(type.toLowerCase() === "auction") url = process.env.REACT_APP_API_AUCTION_URL;
  else if(type.toLowerCase() === "penerimaan") url = process.env.REACT_APP_API_PENERIMAAN_URL;
  else if(type.toLowerCase() === "padi") url = process.env.REACT_APP_API_PADI_URL;
  else if(type.toLowerCase() === "vendor") url = process.env.REACT_APP_API_VENDOR_URL;
  else if(type.toLowerCase() === "stockview") url = process.env.REACT_APP_API_STOCKVIEW_URL;
  else if(type.toLowerCase() === "ppic") url = process.env.REACT_APP_API_PPIC_URL;
  else if(type.toLowerCase() === "sso") url = process.env.REACT_APP_API_SSO_URL;
  else if(type.toLowerCase() === "excel_importer") url = process.env.REACT_APP_API_EXCEL_IMPORTER_URL;
  if(!url) url = process.env.REACT_APP_API_URL;
  return url;
}

export function parseStringToBool(str) {
  if(!str) return false;
  // Convert the string to lowercase and trim any leading/trailing whitespace
  str = str.toString().trim().toLowerCase();

  // Check if the string is one of the "truthy" values
  if (str === 'true' || str === 'yes' || str === '1') {
    return true;
  }

  // Check if the string is one of the "falsy" values
  if (str === 'false' || str === 'no' || str === '0') {
    return false;
  }

  // If the string doesn't match any known values, return null or handle it as needed
  return null;
}

export function parseDateTimeToDBFormat(str) {
  const inputDate = new Date(str);

  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Tambahkan nol di depan jika kurang dari 10
  const day = String(inputDate.getDate()).padStart(2, '0');
  const hours = String(inputDate.getHours()).padStart(2, '0');
  const minutes = String(inputDate.getMinutes()).padStart(2, '0');
  const seconds = String(inputDate.getSeconds()).padStart(2, '0');

  const formattedDateString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  return formattedDateString;
}

export function parseDateToDBFormat(str) {
  const inputDate = new Date(str);

  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Tambahkan nol di depan jika kurang dari 10
  const day = String(inputDate.getDate()).padStart(2, '0');

  const formattedDateString = `${year}-${month}-${day}`;
  return formattedDateString;
}

//convert 2023-11-24T15:57:11.000000Z to yyyy-mm-dd hh:ii:ss
export function formatDateTimeToDBFormat(datetimeString,withSecond=true) {
  const date = new Date(datetimeString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  if(!withSecond) return `${year}-${month}-${day} ${hours}:${minutes}`;

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export function parseDBDateToJSFormat(databaseDate) {
  const [datePart, timePart] = databaseDate.split(' ');
  const [year, month, day] = datePart.split('-');
  const [hour, minutes, seconds] = timePart.split(':');

  const jsDate = new Date(year, month - 1, day, hour, minutes, seconds);

  return jsDate;
}

export function parseNumberToFloat(number, minimumFractionDigits = 2) {
  try {
    let _number = number;
    if(!number) {
      _number = 0;
    }
    const formatter = new Intl.NumberFormat('id-ID', {
      minimumFractionDigits: Number.isInteger(parseFloat(number)) ? 0 : minimumFractionDigits,
    });

    return formatter.format(_number);
  } catch {
    return number;
  }
}

export function parseNumberToCurrency(number, currency) {
  try {
    let _number = number;
    if(!number) {
      _number = 0;
    }
    const handleCurrency = ((currency === null || currency === undefined) ? 'IDR' : currency)
    const formatter = new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: handleCurrency,
      minimumFractionDigits: 0
    });

    return formatter.format(_number);
  } catch {
    return number;
  }
}

export function parseNumberToCurrencyAuction(number, currency) {
  try {
    let _number = number;
    if(!number) {
      _number = 0;
    }
    const handleCurrency = ((currency === null || currency === undefined) ? 'IDR' : currency)
    const formatter = new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: handleCurrency,
      minimumFractionDigits: 3
    });

    return formatter.format(_number);
  } catch {
    return number;
  }
}

//format from DB format to DD M YYYY H:i:s
export const  formatDateTime = (input, subfix="WIB") => {
  if(!input) return "NOT SET"
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const date = new Date(input);
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  return day + ' ' + monthNames[monthIndex] + ' ' + year + ' ' + hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0') + ` ${subfix}`;
}

export function parseNumberToCurrencyMvb(number, currency='IDR') {
  let _number = number;
  if(!number) {
    _number = 0;
  }
  const formatter = new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 3
  });

  return formatter.format(_number);
}

export function parseNumberToDigitAuction(number) {
  let _number = number;
  if(!number) {
    _number = 0;
  }
  const formatter = new Intl.NumberFormat('id-ID', {
    minimumFractionDigits: 3
  });

  return formatter.format(_number);
}

export function multiplyNumber(number, multiplier) {
  if(!number) return 0;
  return parseFloat(number) * parseFloat(multiplier);
}

export function subtractNumber(number, subtractor) {
  if(!number) return 0;
  return parseFloat(number) - parseFloat(subtractor);
}

export function additionNumber(number, number2) {
  if(!number) return 0;
  return parseFloat(number) + parseFloat(number2);
}

export function isGreaterNumber(number, comparator) {
  if(!number) return false;
  return parseFloat(number) > parseFloat(comparator);
}

export function isGreaterEqualNumber(number, comparator) {
  if(!number) return false;
  return parseFloat(number) >= parseFloat(comparator);
}

export function capitalizeFirstChar(str) {
  if(!str || str === undefined) return str;
  // Check if the input string is not empty
  if (str && str.length === 0) {
    return str;
  }

  // Capitalize the first character and concatenate the rest of the string
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function formatCurrencyToNumber(currency) {
  if(!currency) return 0;
  return currency.toString().replace(/\./g, "");
}

export function stringPadZero(str) {
  if(!str) return '';
  return String(parseInt(str));
}

export function getSizeInBytes(value) {
  const bytes = parseFloat(value) / 1024;
  if(bytes < 1024) return bytes.toFixed(1)+" KB";
  if(bytes >= 1024 && bytes < 1024*1024) return (bytes/1024).toFixed(1)+" MB";
}

export function getMomentFromTimestamp(timestamp) {
  return moment(timestamp).utcOffset(7); // Set to GMT+7
}