import { useEffect, useState } from "react";
import { useAxiosGet, useAxiosPost } from "../../../../../../hooks/useAxios";
import {
  formatNumberToDecimalAuction,
  getEnv,
  multiplyNumber,
  parseNumberToCurrency
} from "../../../../../../helpers/procurex";
import CurrencyInput from "react-currency-input-field";
import { toast } from "react-toastify";
import { Placeholder } from "rsuite";
import { Spinner } from "react-bootstrap";

const MvbBidLineVariable = ({ auction, item, mvbVariable, getParamSetting }: any) => {
  const [value, setValue] = useState(mvbVariable?.unit_price || 0);
  const [debounceTimer, setDebounceTimer] = useState<any>(null);
  const [isValidValue, setIsValidValue] = useState(true)
  const [{ doSubmit, isLoading }] = useAxiosPost(
    getEnv("auction") + `/param-setting/${auction.id}/unit-price`
  );


  const debounce = (func: any, delay: number) => {
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }
    const timer = setTimeout(func, delay);
    setDebounceTimer(timer);
  };
  const isFormEnabled = () => {
    return auction?.status === "draft" || isLoading;
  }

  const handleOnSaveValue = async () => {

    const payload = {
      _method: 'PUT',
      item_auction_id: item?.item_id,
      peserta_id: item?.id,
      param_setting_variable_id: mvbVariable?.id,
      unit_price: formatNumberToDecimalAuction(value, 2)
    }
    await doSubmit(payload, undefined, undefined).then((res: any) => {
      getParamSetting && getParamSetting();
      setIsValidValue(true);
    }).catch((e: any) => {
      console.error(e)
      setIsValidValue(false)
    })
  }

  return (
    <td className={"border text-end px-2 text-nowrap"}>
      {mvbVariable?.editable &&
        <div className={"input-group input-group-sm"}>
          <div className="input-group-text">
            {(isValidValue && !isLoading) && <i className={"fa fa-check text-success"}></i>}
            {(!isValidValue && !isLoading) && <i className={"fa fa-times text-danger"}></i>}
            {isLoading && <Spinner animation={"border"} size={"sm"}></Spinner>}
          </div>
          <CurrencyInput
            className="form-control form-control-sm text-end"
            decimalSeparator=","
            groupSeparator="."
            decimalsLimit={2}
            value={value}
            onValueChange={(value: any) => {
              setValue(value);
            }}
            onBlur={(e) => {
              debounce(handleOnSaveValue, 0);
            }}
            disabled={!isFormEnabled() || isLoading}
          />
        </div>
      }
      {!mvbVariable?.editable && mvbVariable?.unit_price}
    </td>
  )
}

const RowPeserta = ({ auction, item, index, onSave, getParamSetting }: any) => {
  const [renderIndex, setRenderIndex] = useState(0)
  const [unitPrice, setUnitPrice] = useState(item?.unit_price || 0);
  const [totalPrice, setTotalPrice] = useState(0)
  const [debounceTimer, setDebounceTimer] = useState<any>(null);
  const [isValidPrice, setIsValidPrice] = useState(true)
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosPost(
    getEnv("auction") + `/param-setting/${auction.id}/unit-price`
  );

  const debounce = (func: any, delay: number) => {
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }
    const timer = setTimeout(func, delay);
    setDebounceTimer(timer);
  };
  const isFormEnabled = () => {
    return (auction?.status === "draft" && auction?.no_tender == null) || auction?.no_tender == null || isLoading;
  }

  const calculateTotalPrice = () => {
    setTotalPrice(
      multiplyNumber(item?.qty, formatNumberToDecimalAuction(unitPrice, 2))
    )
  }


  const handleOnSaveRow = async () => {
    const payload = {
      _method: 'PUT',
      item_auction_id: item?.item_id,
      peserta_id: item?.id,
      unit_price: formatNumberToDecimalAuction(unitPrice, 2)
    }
    await doSubmit(payload, undefined, undefined).then((res: any) => {
      onSave && onSave()
      setIsValidPrice(true)
    }).catch((e: any) => {
      console.error(e)
      setIsValidPrice(false)
    })
  }

  useEffect(() => {
    setRenderIndex(renderIndex + 1);
    calculateTotalPrice()
  }, [unitPrice]);
  return (
    <tr key={'peserta' + index + '-' + index}>
      <td className={"border text-center px-2"}>{index + 1}</td>
      <td className={"border text-start text-nowrap px-2"}>{item?.rekanan}</td>
      <td className={"border text-center px-2"}>{item?.currency}</td>
      <td className={"border text-end px-2"}>{item?.qty}</td>
      {item?.bid_line_mvb_variables?.map((mvbVariable: any) => {
        return <MvbBidLineVariable getParamSetting={() => getParamSetting()} auction={auction} item={item} mvbVariable={mvbVariable} />
      })}
      {item?.bid_line_mvb_variables?.length == 0 &&
        <>
          <td className={"border text-end px-2 d-flex align-items-center gap-2"}>
            <div className={"input-group input-group-sm"}>
              <div className="input-group-text">
                {(isValidPrice && !isLoading) && <i className={"fa fa-check text-success"}></i>}
                {(!isValidPrice && !isLoading) && <i className={"fa fa-times text-danger"}></i>}
                {isLoading && <Spinner animation={"border"} size={"sm"}></Spinner>}
              </div>
              <CurrencyInput
                className="form-control form-control-sm text-end"
                decimalSeparator=","
                groupSeparator="."
                decimalsLimit={2}
                value={unitPrice}
                onValueChange={(value: any) => {
                  setUnitPrice(value);
                }}
                onBlur={(e) => {
                  debounce(handleOnSaveRow, 0);
                }}
                disabled={!isFormEnabled() || isLoading}
              />
            </div>
          </td>
          <td className={"border text-end px-2"}>{parseNumberToCurrency(totalPrice, item?.currency)}</td>
        </>
      }
    </tr>
  )
}
const RowInitBid = ({ auction, item, index, onSave, getParamSetting }: any) => {
  return (
    <div className={"mb-5"}>
      <div><h4>{item.nama}</h4></div>
      <div className={"d-flex gap-3"}>
        <h6>Quantity: {item.kuantitas} {item.satuan} | </h6>
        {auction?.metode_peringkat_id != 297 &&
          <h6>Unit Price: {parseNumberToCurrency(item.unit_price_terendah, item.currency)} - {parseNumberToCurrency(item.unit_price_tertinggi, item.currency)} | </h6>
        }
        <h6>{auction?.tipe_auction_id == 366 ? 'Decrement' : 'Increment'}: {auction?.increment}</h6>
      </div>
      <div className={"table-responsive"}>
        <table className={"table table-header-gray"}>
          <thead>
            <tr>
              <th className={"border text-center px-2"} style={{ minWidth: "50px", width: "50px" }}>No</th>
              <th className={"border text-start px-2"}>Rekanan</th>
              <th className={"border text-center px-2"}>Currency</th>
              <th className={"border text-end px-2"}>Qty</th>
              {item?.mvb_variables?.map((variable: any) => {
                return (
                  <th className={"border text-center px-2 text-nowrap"} style={{ minWidth: "170px", width: "170px" }}>{variable?.detail_template?.variable_name}</th>
                )
              })}
              {item?.mvb_variables?.length == 0 &&
                <>
                  <th className={"border text-end px-2"} style={{ minWidth: "170px", width: "170px" }}>Unit Price</th>
                  <th className={"border text-end px-2"} style={{ minWidth: "170px", width: "170px" }}>Total Nilai</th>
                </>
              }
            </tr>
          </thead>
          <tbody>
            {item.peserta.length === 0 && (
              <tr>
                <td className={"border text-center px-2"} colSpan={6}>
                  Tidak ada data
                </td>
              </tr>
            )}
            {item?.peserta?.map((peserta: any, indexPeserta: number) => {
              return (
                <RowPeserta
                  key={"peserta-" + index + '-' + indexPeserta}
                  auction={auction}
                  item={peserta}
                  index={indexPeserta}
                  onSave={() => onSave()}
                  getParamSetting={() => getParamSetting()}
                />
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

const RowBidderVariable = ({ auction, variable, index }: any) => {
  const [valueMin, setValueMin] = useState(variable?.value_min || 0);
  const [valueMax, setValueMax] = useState(variable?.value_max || 0);
  const [debounceTimer, setDebounceTimer] = useState<any>(null);
  const [isValidValueMin, setIsValidValueMin] = useState(true)
  const [isValidValueMax, setIsValidValueMax] = useState(true)
  const [{ doSubmit: doSubmitValueMin, isLoading: isLoadingValueMin }] = useAxiosPost(
    getEnv("auction") + `/param-setting/${auction.id}/bidder-variable/value-min`
  );

  const [{ doSubmit: doSubmitValueMax, isLoading: isLoadingValueMax }] = useAxiosPost(
    getEnv("auction") + `/param-setting/${auction.id}/bidder-variable/value-max`
  );


  const debounce = (func: any, delay: number) => {
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }
    const timer = setTimeout(func, delay);
    setDebounceTimer(timer);
  };
  const isFormEnabled = () => {
    return auction?.status === "draft" || isLoadingValueMin || isLoadingValueMax;
  }

  const handleOnSaveValueMin = async () => {
    if (valueMax != null && valueMax != 0) {
      if (parseFloat(valueMin) > parseFloat(valueMax)) {
        toast.error("Value Min should lower than Value Max");
        setIsValidValueMin(false)
        return false;
      }
    }
    const payload = {
      _method: 'PUT',
      param_setting_variable_id: variable?.id,
      value_min: formatNumberToDecimalAuction(valueMin, 2)
    }
    await doSubmitValueMin(payload, undefined, undefined).then((res: any) => {
      setIsValidValueMin(true)
    }).catch((e: any) => {
      console.error(e)
      setIsValidValueMin(false)
    })
  }

  const handleOnSaveValueMax = async () => {
    if (parseFloat(valueMin) > parseFloat(valueMax)) {
      toast.error("Value Max must be higher than the Value Min");
      setIsValidValueMax(false)
      return false;
    }
    const payload = {
      _method: 'PUT',
      param_setting_variable_id: variable?.id,
      value_max: formatNumberToDecimalAuction(valueMax, 2)
    }
    await doSubmitValueMax(payload, undefined, undefined).then((res: any) => {
      setIsValidValueMax(true)
    }).catch((e: any) => {
      console.error(e)
      setIsValidValueMax(false)
    })
  }
  return (
    <tr key={'bidderVariables' + index}>
      <td className={"border text-start px-2"}>{variable?.detail_template?.variable_name}</td>
      <td className={"border text-end px-2"}>
        <div className={"input-group input-group-sm"}>
          <div className="input-group-text">
            {(isValidValueMin && !isLoadingValueMin) && <i className={"fa fa-check text-success"}></i>}
            {(!isValidValueMin && !isLoadingValueMin) && <i className={"fa fa-times text-danger"}></i>}
            {isLoadingValueMin && <Spinner animation={"border"} size={"sm"}></Spinner>}
          </div>
          <CurrencyInput
            className="form-control form-control-sm text-end"
            decimalSeparator=","
            groupSeparator="."
            decimalsLimit={2}
            value={valueMin}
            onValueChange={(value: any) => {
              setValueMin(value);
            }}
            onBlur={(e) => {
              debounce(handleOnSaveValueMin, 0);
            }}
            disabled={!isFormEnabled()}

          />
        </div>
      </td>
      <td className={"border text-end px-2"}>
        <div className={"input-group input-group-sm"}>
          <div className="input-group-text">
            {(isValidValueMax && !isLoadingValueMax) && <i className={"fa fa-check text-success"}></i>}
            {(!isValidValueMax && !isLoadingValueMax) && <i className={"fa fa-times text-danger"}></i>}
            {isLoadingValueMax && <Spinner animation={"border"} size={"sm"}></Spinner>}
          </div>
          <CurrencyInput
            className="form-control form-control-sm text-end"
            decimalSeparator=","
            groupSeparator="."
            decimalsLimit={2}
            value={valueMax}
            onValueChange={(value: any) => {
              setValueMax(value);
            }}
            onBlur={(e) => {
              debounce(handleOnSaveValueMax, 0);
            }}
            disabled={!isFormEnabled()}
          />
        </div>
      </td>
      <td className={"border text-end px-2"}>{variable?.detail_template?.rank}</td>
      <td className={"border text-start px-2"}>{variable?.detail_template?.symbol}</td>
    </tr>
  )
}

const BidderVariables = ({ auction, variables }: any) => {
  return (
    <div className={"mb-5"}>
      <div><h4>Bidder Variable</h4></div>

      <div className={"table-responsive"}>
        <table className={"table table-header-gray"}>
          <thead>
            <tr>
              <th className={"border text-center px-2"}>Name</th>
              <th className={"border text-center px-2"} style={{ minWidth: "170px", width: "170px" }}>Value (Min)</th>
              <th className={"border text-center px-2"} style={{ minWidth: "170px", width: "170px" }}>Value (Max)</th>
              <th className={"border text-center px-2"}>Rank</th>
              <th className={"border text-center px-2"}>Symbol</th>
            </tr>
          </thead>
          <tbody>
            {variables?.map((variable: any, index: number) => {
              return (
                <>
                  <RowBidderVariable auction={auction} variable={variable} index={index} />
                </>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}


const NonBidderVariables = ({ auction, variables }: any) => {
  return (
    <div className={"mb-5"}>
      <div><h4>Non Bidder Variable</h4></div>

      <div className={"table-responsive"}>
        <table className={"table table-header-gray"}>
          <thead>
            <tr>
              <th className={"border text-nowrap text-center px-2"}>Name</th>
              <th className={"border text-nowrap text-center px-2"}>Type</th>
              <th className={"border text-nowrap text-center px-2"}>Decimal</th>
              <th className={"border text-nowrap text-center px-2"}>Var.Rank</th>
              <th className={"border text-nowrap text-center px-2"}>Symbol</th>
              <th className={"border text-nowrap text-center px-2"}>One Time</th>
              <th className={"border text-nowrap text-center px-2"}>Show</th>
              <th className={"border text-nowrap text-center px-2"}>Set For Bidder</th>
              <th className={"border text-nowrap text-center px-2"}>Formula</th>
            </tr>
          </thead>
          <tbody>
            {variables?.map((variable: any, index: number) => {
              return (
                <>
                  <tr key={'nonbidderVariables' + index}>
                    <td className={"border text-nowrap text-center px-2"}>{variable?.detail_template?.variable_name}</td>
                    <td className={"border text-nowrap text-center px-2"}>{variable?.detail_template?.type?.value}</td>
                    <td className={"border text-nowrap text-center px-2"}>{variable?.digit_decimal}</td>
                    <td className={"border text-nowrap text-center px-2"}>{variable?.detail_template?.rank}</td>
                    <td className={"border text-nowrap text-center px-2"}>{variable?.detail_template?.symbol}</td>
                    <td className={"border text-nowrap text-center px-2"}><input type='checkbox' checked={variable?.detail_template?.is_one_time} disabled /> </td>
                    <td className={"border text-nowrap text-center px-2"}><input type='checkbox' checked={variable?.detail_template?.is_show} disabled /></td>
                    <td className={"border text-nowrap text-center px-2"}><input type='checkbox' checked={variable?.detail_template?.set_for_bidder} disabled /></td>
                    <td className={"border text-nowrap text-center px-2"}>{variable?.detail_template?.formula}</td>
                  </tr>
                </>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

const MvbValueInitialBid = ({ auction, variable }: any) => {
  const [value, setValue] = useState(variable?.value || 0);
  const [debounceTimer, setDebounceTimer] = useState<any>(null);
  const [isValidValue, setIsValidValue] = useState(true)
  const [{ doSubmit, isLoading }] = useAxiosPost(
    getEnv("auction") + `/param-setting/${auction.id}/mvb-variable-initial-value`
  );


  const debounce = (func: any, delay: number) => {
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }
    const timer = setTimeout(func, delay);
    setDebounceTimer(timer);
  };
  const isFormEnabled = () => {
    return auction?.status === "draft" || isLoading;
  }

  const handleOnSaveValue = async () => {
    const payload = {
      _method: 'PUT',
      item_auction_mvb_initial_value_id: variable?.id,
      value: formatNumberToDecimalAuction(value, 2)
    }
    await doSubmit(payload, undefined, undefined).then((res: any) => {
      setIsValidValue(true)
    }).catch((e: any) => {
      console.error(e)
      setIsValidValue(false)
    })
  }
  return (
    <div className={"input-group input-group-sm"}>
      <div className="input-group-text">
        {(isValidValue && !isLoading) && <i className={"fa fa-check text-success"}></i>}
        {(!isValidValue && !isLoading) && <i className={"fa fa-times text-danger"}></i>}
        {isLoading && <Spinner animation={"border"} size={"sm"}></Spinner>}
      </div>
      <CurrencyInput
        className="form-control form-control-sm text-end"
        decimalSeparator=","
        groupSeparator="."
        decimalsLimit={2}
        value={value}
        onValueChange={(value: any) => {
          setValue(value);
        }}
        onBlur={(e) => {
          debounce(handleOnSaveValue, 0);
        }}
        disabled={!isFormEnabled() || isLoading}
      />
    </div>
  )
}


const ItemMvbInitialValue = ({ auction, variables }: any) => {
  return (
    <div className={"mb-5"}>
      <div><h4>Item Initial Value</h4></div>

      <div className={"table-responsive"}>
        <table className={"table table-header-gray"}>
          <thead>
            <tr>
              <th className={"border text-center px-2"}>No</th>
              <th className={"border text-center px-2"}>Item Auction</th>
              <th className={"border text-center px-2"}>Quantity</th>
              <th className={"border text-center px-2"}>UOM</th>
              <th className={"border text-center px-2"}>Category</th>
              <th className={"border text-center px-2"} style={{ minWidth: "170px", width: "170px" }}>Value</th>
            </tr>
          </thead>
          <tbody>
            {variables?.map((group: any, index: number) => {
              return group.map((variable: any, index2: number) => {
                return (
                  <>
                    <tr key={'itemVariableInitialValue' + index + index2}>
                      {index2 == 0 &&
                        <>
                          <td className={"border text-center align-middle px-2"} rowSpan={group.length}>{index + 1}</td>
                          <td className={"border text-center align-middle px-2"} rowSpan={group.length}>{variable?.item_name}</td>
                          <td className={"border text-center align-middle px-2"} rowSpan={group.length}>{variable?.kuantitas}</td>
                          <td className={"border text-center align-middle px-2"} rowSpan={group.length}>{variable?.satuan}</td>
                        </>
                      }
                      <td className={"border text-center px-2"}>{variable?.variable_name}</td>
                      <td className={"border text-center px-2"}><MvbValueInitialBid auction={auction} variable={variable} /></td>
                    </tr>
                  </>
                )
              });
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

const InitBid = ({ auction, onSave }: any) => {
  const [rows, setRows] = useState<any>([]);
  const [paramSetting, setParamSetting] = useState<any>([]);
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosGet(
    getEnv("auction") + `/param-setting/${auction?.id}`
  );
  const [{ doSubmit: getRefreshParamSetting, isLoading: isLoadingRefreshParamSetting }] = useAxiosGet(
    getEnv("auction") + `/param-setting/${auction?.id}`
  );

  const getRows = () => {
    doSubmit({}, undefined, undefined).then((res: any) => {
      setParamSetting(res.data);
      setRows(res.data.item);
    });
  };

  const onSaveMvbVariable = () => {
    getRefreshParamSetting({}, undefined, undefined).then((res: any) => {
      setParamSetting(res.data);
      setRows(res.data.item);
    });
  }

  useEffect(() => {
    getRows();
  }, []);

  return (
    <>
      <div>
        {isLoading && (
          <>
            <Placeholder.Paragraph active rows={2} />
            <Placeholder.Grid active columns={6} rows={5} />

            <Placeholder.Paragraph active rows={2} />
            <Placeholder.Grid active columns={6} rows={5} />

            <Placeholder.Paragraph active rows={2} />
            <Placeholder.Grid active columns={6} rows={5} />
          </>
        )}
        {!isLoading && paramSetting?.bidder_variables?.length > 0 &&
          <BidderVariables auction={auction} variables={paramSetting?.bidder_variables} />
        }

        {!isLoading && paramSetting?.item_mvb_initial_values?.length > 0 &&
          <ItemMvbInitialValue auction={auction} variables={paramSetting?.item_mvb_initial_values} />
        }

        {!isLoading && paramSetting?.non_bidder_variables?.length > 0 &&
          <NonBidderVariables auction={auction} variables={paramSetting?.non_bidder_variables} />
        }

        {!isLoading && (
          rows?.map((item: any, index: number) => {
            return (
              <div key={index}>
                <RowInitBid auction={auction} item={item} index={index} onSave={() => onSave()} getParamSetting={() => onSaveMvbVariable()} />
              </div>
            );
          })
        )}
      </div>
    </>
  );
};

const ParamSettingAuctionTab = ({ auction, onSave }: any) => {
  return (
    <>
      <InitBid auction={auction} onSave={() => onSave()} />
    </>
  );
};

export { ParamSettingAuctionTab };
