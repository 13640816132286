import React, { useEffect, useRef } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { ButtonToolbar, Dropdown } from "rsuite";
import PreviewAttachment from "../PreviewAttachment";

const ChatRow = ({id,type,from,message,time,isFromMe=false,current,previousChat=null, onReply,replyToMessage=null,replyToType=null,replyToFrom=null,replyToId=null,attachmentType=null,attachmentUrl=null,attachmentDescription=null}) => {
  const [previewAttachment, setPreviewAttachment] = React.useState(null);
  const handleReply = () => {
    const element = document.getElementById(`chat-row-id-${replyToId}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      element.classList.add('blink_me');

      // Hapus kelas "blink" setelah animasi selesai
      setTimeout(() => {
        element.classList.remove('blink_me');
      }, 1500);
    }
  };

  const getBadgeUser = (state:string) => {
    if(state.toLowerCase() === "buyer"){
      return "badge-primary";
    }

    return "badge-info"
  }
  return (
    <>
      {previousChat && previousChat.date !== current.date && (
        <div className={"text-center"}>
          <small className={"text-muted"}>{current.date}</small>
        </div>
      )}

      {!previousChat && (
        <div className={"text-center"}>
          <small className={"text-muted"}>{current.date}</small>
        </div>
      )}
      <div id={"chat-row-id-"+id} className={`d-flex ${isFromMe ? "justify-content-end" : ""}`}>
        <div className={`border p-2 mb-3 rounded-xl ${isFromMe ? "rounded-start" : "rounded-end"}`}
          style={{
            width: "fit-content",
            backgroundColor: isFromMe ? "#d9eedb" : "#C2E0FC",
            maxWidth:'80%'
          }}
        >
          <div className={"d-flex justify-content-between"}>
            <div>
              <small><span
                className={`badge ${getBadgeUser(type)}`}>{type}</span></small>
            </div>
            <div className={"ms-4"}>
              <ButtonToolbar>
                <Dropdown
                  title= {isFromMe ? "Me" : from}
                  appearance={"subtle"}
                  size={"xs"}
                  placement={"bottomEnd"}
                >
                  <Dropdown.Item onClick={()=>onReply({
                    id: id,
                    type: type,
                    from: from,
                    message: message,
                    time: time,
                    isFromMe: isFromMe
                  })}>Reply</Dropdown.Item>
                </Dropdown>
              </ButtonToolbar>
            </div>
          </div>
          {replyToMessage && (
            <div className={"p-2 mb-2"} style={{
              backgroundColor: isFromMe ? "#b1d0ab" : "#8ebce6",
              borderRadius: '5px',
              borderLeft: '20px #f00',
              maxHeight: '7em',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              /* Menambahkan 'webkit' prefix untuk mendukung browser Safari */
              WebkitLineClamp: 5,
              WebkitBoxOrient: 'vertical'

            }} onClick={()=>handleReply()}>
              <div><small><strong>{replyToFrom}</strong></small></div>
              <div><small>{replyToMessage}</small></div>
            </div>
          )}
          {/*{JSON.stringify(attachmentType)}*/}
          {attachmentType && attachmentUrl && (
            <div className={"p-3 border-start border-end border-bottom mb-2"}
              style={{
                backgroundColor: isFromMe ? "#b1d0ab" : "#8ebce6",
                borderRadius: '5px',
                borderLeft: '20px #f00',
                maxHeight: '7em',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                /* Menambahkan 'webkit' prefix untuk mendukung browser Safari */
                WebkitLineClamp: 5, /* Ganti '2' dengan jumlah baris maksimum yang Anda inginkan */
                WebkitBoxOrient: 'vertical'
              }}
            >
              <div className={"d-flex flex-row gap-3"}>
                <div>
                  <a href={"javascript:;"} target={"_blank"} onClick={(e: any) => {
                    e.preventDefault();
                    setPreviewAttachment(attachmentUrl);
                  }}>
                    <i className={"fa fa-file fa-2x text-white"}></i>
                  </a>
                </div>
                <div>
                  <div>
                    <small className={"badge badge-info"}>
                      <i className={"fa fa-flag text-white me-2"}></i> {attachmentType}
                    </small>
                  </div>
                  <div>{attachmentDescription}</div>
                </div>
              </div>
              <PreviewAttachment
                previewExt={previewAttachment}
                onHide={() => setPreviewAttachment(null)}
              />
            </div>
          )}
          <div className={"d-flex justify-content-between"} style={{}}>

            <div className={"me-4"}>{message}</div>
            <div>
              <small className={"text-muted"}>{time}</small>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const ChatInput = ({ onSend, onAttach, isLoading, replyTo, onRemoveReplyTo, attachment, disabled }) => {
  const [message, setMessage] = React.useState("");

  // Handler for key press events in the input field
  const handleKeyPress = (event) => {
    // Check if Enter was pressed without shift key
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault(); // Prevent the default action to avoid line break in input
      if (message.trim()) { // Send message if it's not just white space
        handleSubmit();
      }
    }
  };

  const handleSubmit = () => {
    onSend && onSend({
      message: message.trim(),
      reply_to: replyTo ? replyTo.id : null
    });
    setMessage(""); // Clear input field after sending
    onRemoveReplyTo && onRemoveReplyTo();
  };
  return (
    <div>
      {replyTo && (
        <div
          className={"border-start border-end border-bottom  p-3 text-muted"}
        >
          <div className={"d-flex justify-content-between"}>
            <div><small>[{replyTo?.type}] {replyTo?.from}</small></div>
            <div>
              <a href={"#"} onClick={(e) => {
                e.preventDefault();
                onRemoveReplyTo && onRemoveReplyTo();
              }}>
                <i className={"fa fs-2 fa-times-circle text-danger"}></i>
              </a>
            </div>
          </div>
          <div>{replyTo?.message}</div>
        </div>
      )}
      {attachment}
      <div
        className={"d-flex border-start border-end border-bottom justify-content-between align-items-center"}
      >
        <div className={"form-group"} style={{
          width: "100%"
        }}>
          <input
            className={"form-control border-0"}
            placeholder={"Type a message"}
            autoFocus={true}
            value={message}
            disabled={disabled}
            style={{
              width: "100%"
            }}
            onKeyPress={handleKeyPress}  // Add key press event handler
            onChange={(e) => {
              setMessage(e.target.value);
            }}
          />
        </div>
        <div className={"d-flex"}>
          <button
            type={"button"}
            className={"btn btn-sm btn-info me-1"}
            disabled={isLoading||disabled}
            onClick={() => {
              onAttach && onAttach();
            }}>
            <i className={"fa fa-file"}></i>

          </button>

          <button
            type={"button"}
            className={"btn btn-sm btn-primary"}
            onClick={() => {
              handleSubmit();
            }}
            disabled={isLoading||disabled}
          >
            {isLoading && (
              <Spinner animation="border" role="status" size={"sm"} />
            )}
            {!isLoading && (
              <i className={"fa fa-paper-plane"}></i>
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

type IChatList = {
  id?: number,
  type?: string,
  from: string,
  message: string,
  time: string,
  isFromMe: boolean,
  reply_to_id?: number
  reply_to_message?: string,
  reply_to_type?: string,
  reply_to_from?: string,
  attachment_type?: string,
  attachment_url?: string,
  attachment_description?: string,
}

interface ForumComponentProps {
  chatList: IChatList[];
  isLoading?: boolean;
  onSend?: Function;
  onAttach?: Function;
  date?: string;
  attachment?: any;
  disabled?: boolean;
}

//params chatlist array of chat
const ForumComponent: React.FC<ForumComponentProps> = ({ chatList, isLoading, onSend, onAttach, attachment, disabled }) => {
  const bottomRef = useRef(null);
  const [replyTo, setReplyTo] = React.useState(null);
  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "auto" });
  }, [chatList]);

  useEffect(() => {
  }, []);
  return (
    <>
      <div
        className={"w-100 border"}
        style={{
          minHeight: "300px",
          maxHeight: "300px",
          padding: "10px",
          overflow: "auto",
          position: "relative"
        }}
      >
        <div>
          {chatList.map((chat, index) => {
            return (
              <div key={"chat-list" + index}>
                <ChatRow
                  id={chat.id}
                  type={chat.type}
                  from={chat.from}
                  message={chat.message}
                  time={chat.time}
                  isFromMe={chat.isFromMe}
                  current={chat}
                  replyToMessage={chat.reply_to_message}
                  replyToType={chat.reply_to_type}
                  replyToFrom={chat.reply_to_from}
                  replyToId={chat.reply_to_id}
                  attachmentType={chat.attachment_type}
                  attachmentUrl={chat.attachment_url}
                  attachmentDescription={chat.attachment_description}
                  previousChat={index > 0 ? chatList[index - 1] : null}
                  onReply={(data:any) => setReplyTo(data)}
                />
              </div>
            );
          })}
          <div ref={bottomRef}></div>

          {chatList.length === 0 && (
            <div
              className={"d-flex justify-content-center align-items-center"}
              style={{
                height: "100%"
              }}
            >
              <div className={"text-center"}>
                <img
                  src={"/icons8-no-chat.gif"}
                  alt={"chat"}
                  style={{
                    width: "50px"
                  }}
                />
                <h6 className={"text-muted"}>No chat available</h6>
              </div>
            </div>
          )}
        </div>
      </div>
      <div>
      <ChatInput
          onSend={onSend}
          onAttach={onAttach}
          isLoading={isLoading}
          replyTo={replyTo}
          attachment={attachment}
          onRemoveReplyTo={() => setReplyTo(null)}
          disabled={disabled}
        />
      </div>

    </>
  )
}
export default ForumComponent;