import React, {useEffect, useState} from 'react'
import {useIntl} from "react-intl";
import FormX from "../../../../../components/shared/form/FormX";
import {useRefreshJwt, useUpdatePassword, useUpdateProfile} from "../../../core/hooks";

type IProps = {
  profile: any
}
const ProfileDetails: React.FC<IProps> = (props) => {
  const intl = useIntl();
  const [ isLoadingUpdateProfile, responseDataUpdateProfile, responseErrorUpdateProfile, submitUpdateProfile] = useUpdateProfile();
  const [ isLoadingUpdatePassword, responseDataUpdatePassword, responseErrorUpdatePassword, submitUpdatePassword] = useUpdatePassword();
  const [ isLoadingRefreshToken, responseDataRefreshToken, responseErrorRefreshToken, submitUpdateRefreshToken] = useRefreshJwt();
  const [formProfile,setFormProfile] = useState([])
  const [formPassword,setFormPassword] = useState([])

  useEffect(()=>{
    if(!responseErrorUpdateProfile.data) return ;
    submitUpdateRefreshToken();
  },[responseErrorUpdateProfile])
  useEffect(()=>{
    if(props.profile) generateFormProfile()
    generateFormPassword()
  },[props])
  const generateFormProfile = () => {
    let form = [
      {
        Header: intl.formatMessage({id:'Username'}),
        accessor: 'username',
        focus: true,
        readonly: true,
        value: props?.profile?.username
      },
      {
        Header: intl.formatMessage({id:'Nama Lengkap'}),
        accessor: 'name',
        focus: false,
        readonly: props?.profile?.employee ? true : false,
        value: props?.profile?.name
      },
      {
        Header: intl.formatMessage({id:'Email'}),
        accessor: 'email',
        focus: false,
        readonly: true,
        value: props?.profile?.email
      },
      {
        Header: intl.formatMessage({id:'Company'}),
        accessor: 'company',
        focus: false,
        readonly: true,
        value: props?.profile?.company?.code + " - " + props?.profile?.company?.name
      },
    ]
    // @ts-ignore
    setFormProfile(form)
  }
  const generateFormPassword = () => {
    let form = [
      {
        Header: intl.formatMessage({id:'Password baru'}),
        accessor: 'new_password',
        focus: false,
      },
      {
        Header: intl.formatMessage({id:'Ulangi password baru'}),
        accessor: 'new_password_confirmation',
        focus: false,
        readonly: false,
      },
    ]
    // @ts-ignore
    setFormPassword(form)
  }
  return (
      <div className={'row'}>
        <div className={'col-sm-12 col-md-8 col-lg-8'}>
          <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
            >
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>{useIntl().formatMessage({id:'Profile Detail'})}</h3>
              </div>
            </div>
            {formProfile.length > 0 && (
                <div id='kt_account_profile_details' className='collapse show px-10'>
                  <FormX
                      title={"Profile"}
                      fields={formProfile}
                      onSubmit={(e:any)=>{submitUpdateProfile(e)}}
                      formError={responseErrorUpdateProfile}
                      onChange={()=>{}}
                      colMd={12}
                      colXl={12}
                      showSubmitButton={true}
                      disableSubmitButton={props?.profile?.employee?true:false}
                      isLoadingSubmitButton={isLoadingUpdateProfile}
                  />
                </div>
            )}

          </div>
        </div>
        <div className={'col-sm-12 col-md-4 col-lg-4'}>
          <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                aria-controls='kt_account_profile_details'
            >
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>Password ProcureX</h3>
              </div>
            </div>

            <div id='kt_account_profile_details' className='collapse show px-10'>
              <div className={"alert alert-info"}>
                <i className={"fa fa-info-circle me-2"}></i>
                Hanya untuk login ke ProcureX.
              </div>
              <FormX
                  title={"Profile"}
                  fields={formPassword}
                  onSubmit={(e:any)=>{submitUpdatePassword(e)}}
                  onChange={()=>{}}
                  formError={responseErrorUpdatePassword}
                  colMd={12}
                  colXl={12}
                  showSubmitButton={true}
                  disableSubmitButton={false}
                  isLoadingSubmitButton={isLoadingUpdatePassword}
              />
            </div>
          </div>
        </div>

      </div>
  )
}

export {ProfileDetails}
