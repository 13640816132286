import { ID, KTCard, KTCardBody } from '../../../../../../_metronic/helpers'
import React, { useState, useEffect } from 'react'
import { ApiUrl } from '../../../../../config/Api'
import { getEnv } from "../../../../../../helpers/procurex";
import { initialQueryState, KTSVG, useDebounce } from '../../../../../../_metronic/helpers'
import { toast } from 'react-toastify'
import axios from 'axios'
import { Button, ButtonGroup, Col, Modal, Row, Nav, Tab } from "react-bootstrap";
import { useAuth } from "../../../../auth";
import * as pdfjsLib from 'pdfjs-dist';
import { Link, useNavigate } from "react-router-dom"

const getFirstPageThumbnail = async (pdfUrl) => {
    const pdf = await pdfjsLib.getDocument(pdfUrl).promise;
    const page = await pdf.getPage(1);
    const viewport = page.getViewport({ scale: 1 });
    const canvas = document.createElement('canvas');
    canvas.width = viewport.width;
    canvas.height = viewport.height;
    const context = canvas.getContext('2d');
    await page.render({ canvasContext: context, viewport }).promise;
    return canvas.toDataURL();
};

const BuletinCardStockview = ({ item }) => {
    const [thumbnail, setThumbnail] = useState('');

    useEffect(() => {
        getFirstPageThumbnail(item.file_path)
            .then(setThumbnail)
            .catch(() => {
                setThumbnail('https://img.icons8.com/?size=100&id=13654&format=png&color=000000');
            });
    }, [item.file_path]);

    const navigate = useNavigate();

    const handleDetailClick = () => {
        navigate("/stockview/buletin-price/dashboard/components/DetailBuletinStockview", {
            state: { id: item.id, file_path: item.file_path, judul: item.judul, jenis_publikasi: item.jenis_publikasi, tanggal_keluar_publikasi: item.tanggal_keluar_publikasi, date_created: item.date_created },
        });
    };

    return (
        <div className='col-sm-6 col-md-4 col-xl-3 mb-2'>
            <div className='card text-bg-light m-2' style={{ borderRadius: '2rem', boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.5)' }}>
                <img src={thumbnail} style={{ filter: 'blur(25px)', backgroundColor: '#fff', opacity: '1', height: '280px' }} alt="" />
                <div className='card-img-overlay'>
                    <img className='mt-1 rounded rounded-5 border border-white border-5' src={thumbnail} style={{ width: '180px', height: '250px', display: 'block', margin: 'auto' }} alt="" />
                </div>
                <div className='card-body bg-white' style={{ zIndex: '2' }}>
                    <span className='text-gray-500'>
                        {item.jenis_publikasi}
                    </span>
                    <h5 className='card-title' style={{ fontSize: '16px', fontWeight: 'Bold' }}>
                        {item.judul}
                    </h5>
                    <span>Publication Out Date
                        <h5 className='card-title' style={{ height: '50px', fontSize: '16px', fontWeight: 'Bold' }}>
                            {item.tanggal_keluar_publikasi}
                        </h5>
                    </span>
                    {/* <a href={item.file_path} target={'_blank'} className='btn btn-lg btn-primary col-12'>Detail</a> */}
                    <button onClick={handleDetailClick} className='btn btn-lg btn-primary col-12'>
                        Detail Buletin
                    </button>
                </div>
            </div>
        </div>
    );
};

export { BuletinCardStockview };

