import React, { useContext, useEffect, useRef, useState } from "react";
import TableXWrpper from "../../../../../components/shared/TableXWrapper";
import { KTCard, KTCardBody } from "../../../../../../_metronic/helpers";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import FormikSelect2 from "../../../../../components/shared/form/FormikSelect2";
import axios from "axios";
import { toast } from "react-toastify";
import ListToolbar from "../headers/ListToolbar";
import * as XLSX from 'xlsx';
import SwalX from "../../../../../components/shared/SwalX";
import { useIntl } from "react-intl";
import moment from "moment";
import { AuctionContext } from "../../../../../routing/AuctionRoutes";

const PesertaAuction = () => {
  const {itemPesertaState, setItemPesertaState} = useContext(AuctionContext);
  const validationSchema = Yup.object({});
  const params = useParams();
  const id = params['*'];
  const intl = useIntl()
  const [idDetail, setIdDetail] = useState('')
  const [isShowModalCreate, setIsShowModalCreate] = useState(false)
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false)
  const [isConfirmBanOpen, setIsConfirmBanOpen] = useState(false)
  const [isConfirmUnfreezeOpen, setIsConfirmUnfreezeOpen] = useState(false)
  const [isLoadingSave, setIsLoadingSave] = useState(false)
  const [detailData, setDetailData] : any = useState({})
  const [isShowModalImport, setShowModalImport] = useState(false)
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)
  const [isLoadingBan, setIsLoadingBan] = useState(false)
  const [isLoadingUnfreeze, setIsLoadingUnfreeze] = useState(false)
  const [key, setKey] = useState(0)

  let [errorMessage, setErrorMessage] = useState('')
  let [file, setFile] = useState('')
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [previewData, setPreviewData] = useState([])
  const API_URL = process.env.REACT_APP_API_URL

  const [initialValuesFile, setInitialValuesFile] = useState({
    media: null,
  })

  const handleFileUpload = async () => {
    try {
      var bodyFormData = new FormData();
      bodyFormData.append('auction_id', `${id}`);
      bodyFormData.append('media', file);

      const response = await axios({
        method: "post",
        url: `${API_URL}/api/auction/peserta/import`,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })

      if (response.status === 200) {
        toast.success(response.status + ': Berhasil Import Data', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })

        setErrorMessage('')
        setShowModalImport(false)
      }
    } catch (error: any) {
      if (error.response) {
        window.scrollTo(0, 0)
        setErrorMessage(error.response.data.message)
        toast.error(error.response.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      } else if (error.request) {
        console.log("Request made but no response received:", error.request);
      } else {
        console.log("Error:", error.message);
      }
    }
  };

  const formikFile = useRef({} as any)

  const fileOnChange = (e: any) => {
    let file = e.target.files[0];
    if (file) {
      if (formikFile.current) formikFile.current.setFieldValue('media', file.name);
      setFile(file);

      const reader = new FileReader();
      reader.onload = (event: any) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData: any = XLSX.utils.sheet_to_json(sheet, { header: 1, blankrows: true, defval: "" });
        setPreviewData(jsonData.slice(1));
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleReset = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
      setPreviewData([])
    }
  };

  const tableColumns = [
    {
      Header: 'Action',
      width: 300,
      Cell: (props: any) => (
        <>
          <div className='d-flex w-100 justify-content-center'>
            <Button onClick={() => {
              setIdDetail(props?.cell?.row?.original?.id)
              setIsConfirmBanOpen(true)
            }} className='btn btn-danger btn-sm fw-bold'
              style={{ marginRight: 5 }}>
              BAN
            </Button>
            {props?.cell?.row?.original?.freeze && (
              <Button
                onClick={() => {
                  setIdDetail(props?.cell?.row?.original?.id)
                  setIsConfirmUnfreezeOpen(true)
                }}
                style={{ marginRight: 5 }}
                className='btn btn-sm fw-bold'
              >
                UNFREEZE
              </Button>
            )}
            <Button
              onClick={() => {
                setIdDetail(props?.cell?.row?.original?.id)
                setIsConfirmDeleteOpen(true)
              }}
              className='btn btn-info btn-sm fw-bold'
            >
              DELETE
            </Button>
          </div>
        </>
      ),
    },
    {
      Header: "Rekanan", className: "text-center",
      width: 570,
      Cell: (props: any) => {
        return (
          <>
            <div className='d-flex w-100 justify-content-center'>
              <span>
                {props?.cell?.row?.original.vendor.vendor_no} ({props?.cell?.row?.original.vendor.name})
              </span>
            </div>
          </>
        )
      }
    },
    {
      Header: "Status", className: "text-center",
      Cell: (props: any) => {
        return (
          <>
            <div className='d-flex w-100 justify-content-center'>
              <span>
                {props?.cell?.row?.original.banned ?? props?.cell?.row?.original?.status}
              </span>
            </div>
          </>
        )
      },
    },
    {
      Header: "Status Freeze", className: "text-center",
      Cell: (props: any) => {
        return (
          <>
            <div className='d-flex w-100 justify-content-center'>
              <span>
                {props?.cell?.row?.original.freeze ?? 'Normal'}
              </span>
            </div>
          </>
        )
      }
    },
  ];

  const onBanPeserta = async () => {
    setIsLoadingBan(true)
    try {
      const response: any = await axios.put(`${API_URL}/api/auction/peserta/${idDetail}/banned/1`)

      if (response.status === 200) {
        setKey(key + 1)
        setIsConfirmBanOpen(false)
        toast.success(response?.data?.meta?.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      }
    } catch (error) {
    } finally {
      setIsLoadingBan(false)
    }
  }

  const onUnfreezePeserta = async () => {
    setIsLoadingUnfreeze(true)
    try {
      const response: any = await axios.put(`${API_URL}/api/auction/peserta/${idDetail}/freeze/0`)

      if (response.status === 200) {
        setKey(key + 1)
        setIsConfirmBanOpen(false)
        toast.success(response?.data?.meta?.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      }
    } catch (error) {
      setIsLoadingUnfreeze(false)
    } finally {
      setIsLoadingUnfreeze(false)
    }
  }

  const deleteItem = async () => {
    setIsLoadingDelete(true)
    try {
      const response: any = await axios.delete(`${API_URL}/api/auction/peserta/${idDetail}`)

      if (response.status === 200) {
        setKey(key + 1)
        setIsConfirmDeleteOpen(false)
        toast.success(response?.data?.meta?.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
        setItemPesertaState(true);
      }
    } catch (error) {
    } finally {
      setIsLoadingDelete(false)
    }
  }

  const onSubmit = async (values: any) => {
    setIsLoadingSave(true)
    try {
      let data = { auction_id: id, ...values }
      const response: any = await axios.post(`${API_URL}/api/auction/peserta`, data)
      if (response?.status === 201 || response?.status === 200) {
        setKey(key + 1)
        toast.success(response?.status + ': ' + response?.data.meta.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })

        setIsShowModalCreate(false)
        setItemPesertaState(true);
        return true
      }
    } catch (error) {
      setIsLoadingSave(false)
      console.error(error)
    } finally {
      setIsLoadingSave(false)
      //const responseDetailAuction: any = await axios.get(`${API_URL}/api/auction/auction/${id}`)
      // if (responseDetailAuction?.data?.data?.is_param_setting) {
      //   window.location.reload();
      // }
    }
  }


  const fetchDetailData = async () => {
    const response: any = await axios.get(`${API_URL}/api/auction/auction/${id}`)
    let details = {
      nama: response?.data?.data?.nama,
      status: response?.data?.data?.status,
      nomor: response?.data?.data?.no_auction,
      type: response?.data?.data?.tipe_auction?.value,
      perusahaan: response?.data?.data?.perusahaaan,
      batas_awal: response.data?.data?.batas_awal ? moment(response.data?.data?.batas_awal).format('DD MMMM yyyy mm:HH') : '',
      batas_akhir: response.data?.data?.batas_akhir ? moment(response.data?.data?.batas_akhir).format('DD MMMM yyyy mm:HH') : '',
      catatan: response.data?.data?.catatan,
      petunjuk: response.data?.data?.petunjuk,
      isReadyPublish: response.data?.data?.ready_publish ?? false
    }
    setDetailData(details);
  }

  useEffect(() => {
    fetchDetailData();
    setItemPesertaState(false);
  }, [itemPesertaState])

  return (
    <>
      <SwalX
        title={'Confirmation UNFREEZE'}
        message={'Are you sure?'}
        show={isConfirmUnfreezeOpen}
        loading={isLoadingUnfreeze}
        onHide={() => setIsConfirmUnfreezeOpen(false)}
        onSubmit={() => onUnfreezePeserta()}
      />
      <SwalX
        title={'Confirmation BAN'}
        message={'Are you sure?'}
        show={isConfirmBanOpen}
        loading={isLoadingBan}
        onHide={() => setIsConfirmBanOpen(false)}
        onSubmit={() => onBanPeserta()}
      />
      <SwalX
        title={intl.formatMessage({ id: 'DATATABLE.CONFIRM_DELETE' })}
        message={intl.formatMessage({ id: 'DATATABLE.MESSAGE_DELETE' })}
        show={isConfirmDeleteOpen}
        loading={isLoadingDelete}
        onHide={() => setIsConfirmDeleteOpen(false)}
        onSubmit={() => deleteItem()}
      />
      <KTCard>
        <KTCardBody className="py-4">
          {detailData?.status != 'published' && (
            <div className={'mt-4'} style={{ marginRight: 55 }}>
              <ListToolbar
                buttonText={'Buat Entri Baru'}
                setShowModalCreate={setIsShowModalCreate}
                setDefaultData={setDetailData}
                isImport={true}
                importText={'Upload Peserta'}
                setShowModalImport={setShowModalImport}
              />
            </div>
          )}
          <TableXWrpper
            key={key}
            title={"Item Auction"}
            urlIndex={API_URL + "/api/auction/peserta?auction_id=" + id}
            columns={tableColumns}
            isForceCreateButton={true}
            onCreateClick={() => {
              setIsShowModalCreate(true)
            }}
            readonly
          />
        </KTCardBody>
      </KTCard>
      <Modal size={"lg"} show={isShowModalCreate} onHide={() => setIsShowModalCreate(false)}>
        <Formik initialValues={{}} validationSchema={validationSchema} onSubmit={onSubmit}>
          {({ errors, touched, values, setFieldValue }: any) => (
            <>
              <Form>
                <Modal.Header closeButton>
                  <Modal.Title>
                    Daftarkan <b>Vendor</b> Peserta Auction
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className={"form-group mb-4"}>
                    <FormikSelect2
                      name="vendor"
                      label="Rekanan"
                      optionsUrl={`${API_URL}/api/auction/vendor`}
                      unique_property={"id"}
                      getOptionValue={(opt: any) => opt}
                      getOptionLabel={(opt: any) => opt?.vendor_no + " (" + opt?.name + ")"}
                      required
                    />
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <div>
                    <button className={"btn btn-danger me-3 btn-sm"} onClick={() => {
                      setIsShowModalCreate(false)
                    }}>
                      <i className={"fa fa-times"}></i> Batal
                    </button>
                    <button type='submit' className='btn btn-primary me-3 btn-sm' disabled={isLoadingSave}>
                      {isLoadingSave && <Spinner animation={"border"} size={"sm"} className={"me-2"} />}
                      <i className={"fa fa-save"}></i> Simpan Draft
                    </button>
                  </div>
                </Modal.Footer>
              </Form>
            </>
          )}
        </Formik>
      </Modal>
      <Formik
        innerRef={formikFile}
        initialValues={initialValuesFile}
        validationSchema={validationSchema}
        onSubmit={handleFileUpload}
        enableReinitialize
      >
        {({ errors, touched, values, setFieldValue }) => (
          <>
            <Modal size='lg' show={isShowModalImport} onHide={() => setIsShowModalCreate(false)} centered>
              <Form>
                <Modal.Header closeButton>
                  <Modal.Title>Import Excel</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div>
                    <input
                      onChange={fileOnChange}
                      name='media'
                      type='file'
                      className='form-control'
                      ref={fileInputRef}
                    />
                  </div>
                  {previewData.length > 0 && (
                    <div>
                      <h4 style={{ marginTop: '25px' }}>Preview Excel Data:</h4>
                      <div style={{ maxHeight: '400px', overflowY: 'scroll' }}>
                        <table className='table' style={{ maxWidth: '100%', border: '1px solid #ccc', borderCollapse: 'collapse' }}>
                          <thead style={{ textAlign: 'center', border: '1px solid #ccc', position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1, }}>
                            <tr>
                              <th style={{ width: 50 }}>No</th>
                              <th>Kode Vendor</th>
                            </tr>
                          </thead>
                          <tbody style={{ textAlign: 'center', border: '1px solid #ccc' }}>
                            {previewData.map((row: any, rowIndex: number) => (
                              <tr key={rowIndex}>
                                <td>{rowIndex + 1}</td>
                                <td>{row}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type='button'
                    className='btn btn-secondary'
                    onClick={handleReset}
                  >
                    Clear
                  </button>
                  <button onClick={() => setShowModalImport(false)} className='btn btn-danger' type='button'>
                    Cancel
                  </button>
                  <button className='btn btn-primary' type='submit'>
                    Simpan
                  </button>
                </Modal.Footer>
              </Form>
            </Modal>
          </>
        )}
      </Formik>
    </>
  );
};

export { PesertaAuction };

