import { createRoot } from "react-dom/client";
// Axios
import { Chart, registerables } from "chart.js";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
// Apps
import { MetronicI18nProvider } from "./_metronic/i18n/Metronici18n";
import * as Sentry from "@sentry/react";
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import "nouislider/dist/nouislider.css";
import "./_metronic/assets/sass/style.scss";
import "./_metronic/assets/sass/plugins.scss";
import "./_metronic/assets/sass/style.react.scss";
import { AppRoutes } from "./app/routing/AppRoutes";
import { AuthProvider } from "./app/modules/auth";
import { ThemeModeProvider } from "./_metronic/partials/layout/theme-mode/ThemeModeProvider";
import "react-quill/dist/quill.snow.css";
import "react-datepicker/dist/react-datepicker.css";
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */



Chart.register(...registerables);

if(process.env.REACT_APP_API_URL === "https://procurement.pupuk-indonesia.com") {
  Sentry.init({
    dsn: "https://ae8103fb6a250d2d559cdcd84e2ebd7e@o4506416027992064.ingest.sentry.io/4506573084164096",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['https://procurement.pupuk-indonesia.com'],
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const queryClient = new QueryClient();
const container = document.getElementById("root");
if (container) {
  // @ts-ignore
  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      <>
        <MetronicI18nProvider>
          <ThemeModeProvider>
            <AuthProvider>
              <AppRoutes />
            </AuthProvider>
          </ThemeModeProvider>
        </MetronicI18nProvider>
        <ReactQueryDevtools initialIsOpen={false} position={"bottom-right"} />
      </>
    </QueryClientProvider>
  );
}
