import {KTCard, KTCardBody} from '../../../../../../_metronic/helpers'
import React, {useEffect, useState} from 'react'
import TableXWrpper from "../../../../../components/shared/TableXWrapper";
import { useNavigate } from "react-router-dom";
import { getEnv } from "../../../../../../helpers/procurex";
import {StatusBadgeAanwijzing} from '../../../admin/aanwijzing/AanwijzingList'

interface IPropsStatusBadge {
  status: string;
}

const AanwijzingVendorListDraft = () => {
  const urlIndex = getEnv('tender')+"/vendor/aanwijzing";
  const navigate = useNavigate();
  const tableColumns = [
    {
      Header: 'Aksi',
      accessor: 'aksi',
      width: 50,
      className: 'text-center',
      Cell: (props: any) => {
        const row = props.row.original;
        return (
          <>
            <a href={"#"} title={"Edit"} onClick={() => {
              navigate("/tender/vendor/aanwijzing/"+row.uuid);
            }}>
              <i className="fa fs-3 fa-eye"></i>
            </a>
          </>
        )
      }
    },
    {
      Header: "status",
      accessor: "status",
      width: 100,
      Cell: (props: any) => {
        return (
          <>
            <StatusBadgeAanwijzing status={props.row.original.status} isHideStatusDescription={true} />
          </>
        )
      }
    },
    {Header: 'Reference', accessor: 'reference', width: 180, disableCreate: true, disableEdit: true, className: 'text-center'},
    {Header: 'Source', accessor: 'source', width: 100, disableCreate: true, disableEdit: true, className: 'text-center'},
    {Header: 'Title', accessor: 'title', width: 300},
    {Header: 'Start At', accessor: 'start_at', width: 180, className:'text-center'},
    {Header: 'End At', accessor: 'end_at', width: 180, className:'text-center'},
    {Header: 'Created At', accessor: 'created_at', width: 180, className:'text-center'},

  ]
  return (
    <>
      <KTCard>
        <KTCardBody className='py-4'>
          <TableXWrpper
              readonly={true}
              title={"Proposal Tender"}
              urlIndex={urlIndex}
              columns={tableColumns}
              isForceCreateButton={true}
              hasAnySearch={true}
          />
        </KTCardBody>
      </KTCard>
    </>
  )
}

export {AanwijzingVendorListDraft}
