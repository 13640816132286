import {FC} from 'react'

type Props = {
  proc: Proc,
  onClick: Function,
}

export interface Proc {
  title: string
  id: string | number
  created_at: string
  category: string,
  group: any,
  type: any
}

const ProcCard: FC<Props> = (props) => {
  const {proc} = props

  return (
    <div className='card border'>
      <div className='card-body'>
        <a href={"#"} onClick={(e)=>{e.preventDefault();props.onClick(proc)}}><h3>{proc.title}</h3></a>
        <p style={{color: '#bbb'}}>{proc.category}</p>
        <div className='d-flex justify-content-between'>
          <p>{proc.created_at}</p>
          <div>
            <span className='badge badge-warning me-3'>{proc?.group?.name}</span>
            <span className='badge badge-success'>{proc?.type?.name}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProcCard
