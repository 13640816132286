import React, { useEffect, useRef, useState } from "react";
import TableXWrpper from "../../../../../components/shared/TableXWrapper";
import { Modal } from "react-bootstrap";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import FormikSelect2 from "../../../../../components/shared/form/FormikSelect2";
import axios from "axios";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import { getEnv } from "../../../../../../helpers/procurex";
import Swal from "sweetalert2";
import { useAxiosPost } from "../../../../../../hooks/useAxios";

const StatusBadge = ({ status }: any) => {
  switch (status) {
    case "banned":
      return <span className={"badge badge-danger"}>Banned</span>;
    case "active":
      return <span className={"badge badge-success"}>Active</span>;
    case "registered":
      return <span className={"badge badge-info"}>Registered</span>;
    default:
      return <span className={"badge badge-warning"}>{status}</span>;
  }
};

const StatusFreezeBadge = ({ status }: any) => {
  switch (status) {
    case "freeze":
      return <span className={"badge badge-danger"}>Freeze</span>;
    case "normal":
      return <span className={"badge badge-success"}>Normal</span>;
    default:
      return <span className={"badge badge-warning"}>{status}</span>;
  }
}

const FormImport = ({ show, handleClose, auction, onAfterSubmit }: any) => {
  const formikFile = useRef({} as any)
  const validationSchema = Yup.object({});
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [previewData, setPreviewData] = useState([])
  const [templateUrl, setTemplateUrl] = useState('https://procurement.pupuk-indonesia.com/api/media/media/9a9c656e-c275-4153-838c-cb347af39bdd/file2')
  const [initialValuesFile, setInitialValuesFile] = useState({
    media: null,
  })
  let [file, setFile] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const handleFileUpload = async () => {
    try {
      setIsLoading(true)
      let bodyFormData = new FormData();
      bodyFormData.append('auction_id', `${auction?.id}`);
      bodyFormData.append('media', file);

      const response = await axios({
        method: "post",
        url: `${getEnv('auction')}/peserta/import`,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })

      if (response.status === 200) {
        toast.success(response.status + ': Berhasil Import Data', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
        handleClose(false)
        onAfterSubmit()
        setIsLoading(false)

      }
    } catch (error: any) {
      if (error.response) {
        window.scrollTo(0, 0)
        toast.error(error.response.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      } else if (error.request) {
        console.log("Request made but no response received:", error.request);
      } else {
        console.log("Error:", error.message);
      }
      setIsLoading(false)
    }
  };

  const fileOnChange = (e: any) => {
    let file = e.target.files[0];
    if (file) {
      if (formikFile.current) formikFile.current.setFieldValue('media', file.name);
      setFile(file);

      const reader = new FileReader();
      reader.onload = (event: any) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData: any = XLSX.utils.sheet_to_json(sheet, { header: 1, blankrows: true, defval: "" });
        setPreviewData(jsonData.slice(1));
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleReset = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
      setPreviewData([])
    }
  };

  useEffect(() => {
    setPreviewData([])
  }, [show])

  return (
    <>
      <Formik
        innerRef={formikFile}
        initialValues={initialValuesFile}
        validationSchema={validationSchema}
        onSubmit={handleFileUpload}
        enableReinitialize
      >
        {({ errors, touched, values, setFieldValue }) => (
          <>
            <Modal size={"lg"} show={show} onHide={() => handleClose(false)}>
              <Form>
                <Modal.Header closeButton>
                  <Modal.Title>Upload Vendor</Modal.Title>
                </Modal.Header>
                <Modal.Body className={"py-2"}>
                  <div>
                    <strong>Petunjuk Upload</strong>
                    <ol>
                      <li>
                        Download File Template
                        <a href={templateUrl} target="download" rel="noreferrer">
                          <strong> disini</strong>
                        </a>
                      </li>
                      <li>Gunakan template yang tersedia tanpa menambah atau mengurangi kolom</li>
                      <li>Isi kolom sesuai dengan data yang diminta</li>
                      <li>Setelah selesai mengisi, simpan file dengan format .xlsx</li>
                      <li>Upload file yang telah diisi</li>

                    </ol>
                  </div>
                  <hr />
                  <input
                    onChange={fileOnChange}
                    name='media'
                    type='file'
                    className='form-control'
                    ref={fileInputRef}
                  />
                  {previewData.length > 0 && (
                    <div>
                      <h4 style={{ marginTop: '25px' }}>Preview Excel Data:</h4>
                      <div style={{ maxHeight: '400px', overflowY: 'scroll' }}>
                        <table className='table' style={{ maxWidth: '100%', border: '1px solid #ccc', borderCollapse: 'collapse' }}>
                          <thead style={{ textAlign: 'center', border: '1px solid #ccc', position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1, }}>
                            <tr>
                              <th style={{ width: 50 }}><strong>No</strong></th>
                              <th className={"text-start"}><strong>Kode Vendor</strong></th>
                            </tr>
                          </thead>
                          <tbody style={{ textAlign: 'center', border: '1px solid #ccc' }}>
                            {previewData.map((row: any, rowIndex: number) => (
                              <tr key={rowIndex}>
                                <td>{rowIndex + 1}</td>
                                <td className={"text-start"}>{row}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <button onClick={() => handleClose(false)} className='btn btn-danger btn-sm' type='button' disabled={isLoading}>
                    <i className={"fa fa-times"}></i>
                    Batal
                  </button>
                  <button
                    className='btn btn-primary btn-sm'
                    type='submit'
                    disabled={isLoading}
                  >
                    {isLoading && (<i className={"fa fa-spinner fa-spin me-2"}></i>)}
                    {!isLoading && (<i className={"fa fa-save me-2"}></i>)}
                    Submit
                  </button>
                </Modal.Footer>
              </Form>
            </Modal>
          </>
        )}
      </Formik>
    </>
  )
}


const FormAdd = ({ show, handleClose, auction, onAfterSubmit }: any) => {
  const validationSchema = Yup.object({});
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosPost(getEnv("auction") + `/peserta`);
  const onSubmit = async (values: any) => {
    const payload = {
      auction_id: auction?.id,
      vendor: values.vendor
    }
    doSubmit(payload, undefined, toast).then(() => {
      handleClose(false);
      onAfterSubmit();
    })
  }
  return (
    <Modal show={show} onHide={() => handleClose(false)}>
      <Formik initialValues={{}} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ errors, touched, values, setFieldValue }: any) => (
          <>
            <Form>
              <Modal.Header closeButton>
                <Modal.Title>
                  Tambah Peserta Auction
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className={"form-group mb-4"}>
                  <FormikSelect2
                    name="vendor"
                    label="Pilih Vendor"
                    optionsUrl={`${getEnv('auction')}/peserta/list-vendor/` + auction?.id}
                    unique_property={"id"}
                    getOptionValue={(opt: any) => opt}
                    getOptionLabel={(opt: any) => opt?.vendor_no + " - " + opt?.name}
                    search_property={"name"}
                    required
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div>
                  <button className={"btn btn-danger me-3 btn-sm"} onClick={() => {
                    handleClose(false)
                  }} disabled={isLoading}>
                    <i className={"fa fa-times"}></i> Batal
                  </button>
                  <button type='submit' className='btn btn-primary me-3 btn-sm' disabled={isLoading}>
                    {isLoading && (<i className={"fa fa-spinner fa-spin me-2"}></i>)}
                    {!isLoading && (<i className={"fa fa-save me-2"}></i>)}
                    Simpan
                  </button>
                </div>
              </Modal.Footer>
            </Form>
          </>
        )}
      </Formik>
    </Modal>
  )
}

const PesertaAuctionTab = ({ auction, onSave }: any) => {
  const [tableKey, setTableKey] = useState(0);
  const [urlIndex, setUrlIndex] = useState(getEnv("auction") + `/peserta?auction_id=${auction?.id}`);
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosPost(urlIndex);
  const [isShowUploadModal, setIsShowUploadModal] = useState(false);
  const [isShowAddModal, setIsShowAddModal] = useState(false);
  const tableColumns = [
    {
      Header: "Action",
      width: 220,
      className: "text-center",
      Cell: (props: any) => (
        <>
          <div className="d-flex w-100 justify-content-center">
            <div className={"btn-group"}>
              {props?.cell?.row?.original.banned && (
                <button
                  className={"btn btn-sm btn-light-success text-nowrap"}
                  type={"button"}
                  onClick={() => {
                    handleBan(props?.cell?.row?.original?.id, 0);
                  }}
                  disabled={isLoading}
                >
                  {isLoading && (<i className={"fa fa-spinner fa-spin me-2"}></i>)}
                  {!isLoading && (<i className={"fa fa-check-circle me-2"}></i>)}
                  UNBAN
                </button>
              )}
              {!props?.cell?.row?.original.banned && (
                <button
                  className={"btn btn-sm btn-light-danger text-nowrap"}
                  type={"button"}
                  onClick={() => {
                    handleBan(props?.cell?.row?.original?.id, 1);
                  }}
                  disabled={isLoading}
                >
                  {isLoading && (<i className={"fa fa-spinner fa-spin me-2"}></i>)}
                  {!isLoading && (<i className={"fa fa-ban me-2"}></i>)}
                  BAN
                </button>
              )}
              {auction?.no_tender == null &&
                <button
                  className={"btn btn-sm btn-light-danger text-nowrap"}
                  type={"button"}
                  onClick={() => {
                    handleDelete(props?.cell?.row?.original?.id);
                  }}
                  disabled={isLoading || !isCreateEnabled()}
                >
                  {isLoading && (<i className={"fa fa-spinner fa-spin me-2"}></i>)}
                  {!isLoading && (<i className={"fa fa-trash me-2"}></i>)}
                  Delete
                </button>
              }
            </div>
          </div>
        </>
      )
    },
    {
      Header: "Rekanan", className: "text-start",
      width: 500,
      Cell: (props: any) => {
        return (
          <>
            <span>
              {props?.cell?.row?.original.vendor.no} - {props?.cell?.row?.original.vendor.name}
            </span>
          </>
        );
      }
    },
    {
      Header: "Status", className: "text-center",
      Cell: (props: any) => {
        return (
          <>
            <div className="d-flex w-100 justify-content-center">
              <StatusBadge status={props?.cell?.row?.original?.status} />
            </div>
          </>
        );
      }
    },
    {
      Header: "Status Freeze", className: "text-center",
      Cell: (props: any) => {
        return (
          <>
            <div className="d-flex w-100 justify-content-center">
              <StatusFreezeBadge status={props?.cell?.row?.original.status_freeze} />
            </div>
          </>
        );
      }
    }
  ];

  const handleDelete = (id: any) => {
    Swal.fire({
      title: "Apakah Anda Yakin?",
      text: "Anda akan menghapus vendor ini!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, Hapus",
      cancelButtonText: "Tidak"
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        try {
          const newurl = `${getEnv("auction")}/peserta/${id}`;
          doSubmit({
            _method: "DELETE"
          }, newurl, toast).then(() => {
            handleAfterSubmit();
          });
        } catch (e) {
          console.log(e);
        }
      }
    });
  };

  const handleBan = (id: any, state: number) => {
    Swal.fire({
      title: "Apakah Anda Yakin?",
      text: `Anda akan ${state === 1 ? 'BAN' : 'UNBAN'} vendor ini!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, BAN",
      cancelButtonText: "Tidak"
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        try {
          const newurl = `${getEnv("auction")}/peserta/${id}/banned/${state}`;
          doSubmit({
            _method: "PUT"
          }, newurl, toast).then(() => {
            handleAfterSubmit();
          });
        } catch (e) {
          console.log(e);
        }
      }
    });
  };

  const isCreateEnabled = () => {
    return auction?.status === "draft";
  }

  const handleAfterSubmit = () => {
    onSave && onSave();
    setTableKey(tableKey + 1);
  }

  return (
    <>
      <TableXWrpper
        key={tableKey}
        title={"Item Auction"}
        urlIndex={urlIndex}
        columns={tableColumns}
        isForceCreateButton={true}
        hideSelection={true}
        disablePaddingCard={true}
        disableUrlChanged={true}
        readonly
        headerButtons={
          <div className={"d-flex gap-2"}>
            {auction?.no_tender == null &&
              <button
                className={"btn btn-sm btn-primary"}
                type={"button"}
                disabled={!isCreateEnabled()}
                onClick={() => {
                  setIsShowAddModal(true);
                }}
              >
                <i className={"fa fa-plus"}></i>
                Tambah Vendor
              </button>
            }

            {/* <button
            className={"btn btn-sm btn-success"}
            type={"button"}
            disabled={!isCreateEnabled()}
            onClick={() => {
              setIsShowUploadModal(true);
            }}
          >
            <i className={"fa fa-upload"}></i>
            Upload Vendor
          </button> */}
          </div>
        }
      />
      <FormImport
        show={isShowUploadModal}
        handleClose={setIsShowUploadModal}
        auction={auction}
        onAfterSubmit={() => {
          handleAfterSubmit();
        }}
      />
      <FormAdd
        show={isShowAddModal}
        handleClose={setIsShowAddModal}
        auction={auction}
        onAfterSubmit={() => {
          handleAfterSubmit();
        }}
      />
    </>
  );
};

export { PesertaAuctionTab };

