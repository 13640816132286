import {ButtonGroup, Col, Modal, Row} from 'react-bootstrap'
import {MetronicErrorMessage, toastError, toastSuccess} from '../../../../../../_metronic/helpers'
import {Field, Form, Formik} from 'formik'
import {useEffect, useState} from 'react'
import {useUploadMedia} from '../../../../../../hooks/MediaUploadHook'
import {useParams} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {ApiUrl} from '../../../../../config/Api'

import FormikUploadFile from '../../../../../components/shared/form/FormikUploadFile'
import * as Yup from 'yup'
import axios from 'axios'
import {useAuth} from '../../../../auth'
import Swal from 'sweetalert2'
import { PageAlertX } from '../../../../../components/shared/PageAlertX'
import { getEnv } from '../../../../../../helpers/procurex'

interface Props {
  data: any
}

const ConfirmationPO = ({data}: Props) => {
  const intl = useIntl()
  const {id} = useParams()
  const {currentUser} = useAuth()
  const {upload} = useUploadMedia()
  const [po, setPo] = useState<any>(null)
  const [itemId, setItemId] = useState(0)
  const [keyBuyer, setKeyBuyer] = useState(0)
  const [keyRekanan, setKeyRekanan] = useState(0)
  const [attachmentRekanan, setAttachmentRekanan] = useState<any>([])
  const [attachmentBuyer, setAttachmentBuyer] = useState<any>([])
  const [previewModal, setPreviewModal] = useState(false)
  const [loadingBuyer, setLoadingBuyer] = useState(false)
  const [loadingRekanan, setLoadingRekanan] = useState(false)
  const [previewExt, setPreviewExt] = useState({value: '', ext: ''})
  const [flagProperties, setFlagProperties] = useState<any>("");

  const validationSchemaBuyer = Yup.object({
    deskripsi_buyer: Yup.string().required('The field is required'),
    file_buyer_url: Yup.string().required('The field is required'),
  })

  const validationSchemaRekanan = Yup.object({
    deskripsi_rekanan: Yup.string().required('The field is required'),
    file_rekanan_url: Yup.string().required('The field is required'),
  })

  const defaultValueBuyer = {
    deskripsi_buyer: '',
    file_buyer_url: '',
  }

  const defaultValueRekanan = {
    deskripsi_rekanan: '',
    file_rekanan_url: '',
  }

  const [initialValuesBuyer, setInitialValuesBuyer] = useState(defaultValueBuyer)
  const [initialValuesRekanan, setInitialValuesRekanan] = useState(defaultValueRekanan)

  const onSubmitBuyer = async (values: any) => {
    try {
      setLoadingBuyer(true)

      if (typeof values?.file_buyer_url !== 'string') {
        let responseFile = await upload('25', values?.file_buyer_url)
        values.file_buyer_url = responseFile.url
      }

      const data = {
        type: 'buyer',
        purchase_order_id: id,
        description: values?.deskripsi_buyer,
        media_url: values?.file_buyer_url,
        is_replace: false
      }

      let response: any
      if (itemId) {
        response = await axios.put(`${ApiUrl}/api/tender/po-attachment/${itemId}`, data)
      } else {
        response = await axios.post(`${ApiUrl}/api/tender/po-attachment`, data)
      }

      toastSuccess(response?.data?.meta?.message)

      loadAttachmentBuyer()
      setInitialValuesBuyer(defaultValueBuyer)
      setKeyBuyer(keyBuyer + 1)
      setItemId(0)
    } catch (error: any) {
      toastError(error?.response?.data?.meta?.message || 'Terjadi kesalahan')
    } finally {
      setLoadingBuyer(false)
    }
  }

  const onSubmitRekanan = async (values: any) => {
    try {
      setLoadingRekanan(true)

      if (typeof values?.file_rekanan_url !== 'string') {
        let responseFile = await upload('25', values?.file_rekanan_url)
        values.file_rekanan_url = responseFile.url
      }

      const data = {
        type: 'rekanan',
        purchase_order_id: id,
        description: values?.deskripsi_rekanan,
        media_url: values?.file_rekanan_url,
        is_replace: false
      }

      let response: any
      if (itemId) {
        response = await axios.put(`${ApiUrl}/api/tender/po-attachment/${itemId}`, data)
      } else {
        response = await axios.post(`${ApiUrl}/api/tender/po-attachment`, data)
      }

      toastSuccess(response?.data?.meta?.message)
      loadAttachmentRekanan()
      setInitialValuesRekanan(defaultValueRekanan)
      setKeyRekanan(keyRekanan + 1)
      setItemId(0)
    } catch (error: any) {
      toastError(error?.response?.data?.meta?.message || 'Terjadi kesalahan')
    } finally {
      setLoadingRekanan(false)
    }
  }

  const deleteItem = (uuid: any) => {
    Swal.fire({
      title: 'Apakah Anda Yakin?',
      text: 'Anda akan menghapus item ini!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ya, Hapus',
      cancelButtonText: 'Tidak',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios.delete(`${ApiUrl}/api/tender/po-attachment/${uuid}`)

          toastSuccess(response?.data?.meta?.message)
          loadAttachmentBuyer()
          loadAttachmentRekanan()
          setItemId(0)
        } catch (error: any) {
          toastError(error?.response?.data?.meta?.message || 'Terjadi kesalahan')
        }
      }
    })
  }

  const preview = (value: string) => {
    window.open(value, '_blank')
  }

  const loadPo = () => {
    axios
      .get(`${ApiUrl}/api/tender/po/${id}`)
      .then((res) => {
        setPo(res.data.data)
      })
      .catch((error) => {
          console.error(error)
      })
      .finally(() => {
      })
  }

  useEffect(() => {
    loadPo()
  }, [id])

  const loadAttachmentBuyer = () => {
    return axios
      .get(
        `${ApiUrl}/api/tender/po-attachment?filter[type]=buyer&filter[purchase_order_id]=${po?.id}`
      )
      .then((res) => {
        setAttachmentBuyer(res.data.data)
      })
      .catch((error) => console.error(error))
  }

  const loadAttachmentRekanan = () => {
    return axios
      .get(
        `${ApiUrl}/api/tender/po-attachment?filter[type]=rekanan&filter[purchase_order_id]=${po?.id}`
      )
      .then((res) => {
        setAttachmentRekanan(res.data.data)
      })
      .catch((error) => console.error(error))
  }

  useEffect(() => {
    if (po?.id) {
      loadAttachmentBuyer()
      loadAttachmentRekanan()
    }
  }, [po])

  const getFlag = () => {
    axios.get(
      getEnv("tender") + `/log?perPage=25&page=1&sort=-id&filter[subject_id]=${po?.id}&filter[log_name]=Purchase Order&filter[description]=rejected`
    ).then((res:any)=>{
      if(res?.data?.data.length > 0){
        setFlagProperties(res?.data?.data[0]?.properties?.command)
      }
    })
  }

  useEffect(() => {
    if(po && po?.status === "Rejected Confirmation") getFlag()
  }, [po]);

  return (
    <>
      <Modal
        key={'modal'}
        show={previewModal}
        onHide={() => {
          setPreviewModal(false)
        }}
        size={'lg'}
      >
        <Modal.Header closeButton={true}>
          <h4>Preview Lampiran</h4>
        </Modal.Header>
        <Modal.Body className='overflow-visible'>
          <object width='100%' height='700' data={previewExt.value} type='application/pdf'>
            {' '}
          </object>
          {(previewExt.ext === 'jpg' || previewExt.ext === 'png' || previewExt.ext === 'jpeg') && (
            <>
              <img className='w-100' src={previewExt.value} alt='image' />
            </>
          )}
        </Modal.Body>
      </Modal>

      {(po?.status === "Rejected Confirmation" && flagProperties) && 
        <PageAlertX title={"Rejected"} variant={"danger"} html={
          <>
            {flagProperties}
          </>
        } />
      }

      <div className='card mb-5'>
        <div className='card-body'>
          <h3>Document Konfirmasi</h3>
          <hr />
          <Row>
            <Col lg={6}>
              <div className='card border shadow-sm'>
                <div className='card-body'>
                  <h4 className='text-center'>Buyer Upload Document</h4>
                  <hr className='mb-5' />
                  <Formik
                    key={keyBuyer}
                    onSubmit={onSubmitBuyer}
                    initialValues={initialValuesBuyer}
                    validationSchema={validationSchemaBuyer}
                  >
                    {({errors, touched, values, setFieldValue}) => (
                      <>
                        <Form id='form-buyer'>
                          <div className='mb-10'>
                            <label className='required form-label'>Deskripsi</label>
                            <Field
                              name='deskripsi_buyer'
                              type='text'
                              className='form-control'
                              disabled={
                                !currentUser?.has_roles?.toString().includes('buyer') ||
                                po?.status === 'Release' ||
                                po?.status === 'Done'
                              }
                            />
                            <MetronicErrorMessage name='deskripsi_buyer' />
                          </div>
                          <div className='mb-10'>
                            <FormikUploadFile
                              label='Lampiran'
                              name='file_buyer_url'
                              disabled={
                                !currentUser?.has_roles?.toString().includes('buyer') ||
                                po?.status === 'Release' ||
                                po?.status === 'Done'
                              }
                            />
                          </div>
                          <div className='mb-10 d-flex justify-content-end'>
                            <button
                              type='submit'
                              className='btn btn-sm btn-primary'
                              disabled={
                                loadingBuyer ||
                                !currentUser?.has_roles?.toString().includes('buyer') ||
                                po?.status === 'Release' ||
                                po?.status === 'Done'
                              }
                            >
                              {loadingBuyer && (
                                <span className='spinner-border spinner-border-sm me-1' />
                              )}
                              Simpan
                            </button>
                          </div>
                        </Form>
                      </>
                    )}
                  </Formik>

                  <div className='mt-5'>
                    <div className={'table-responsive'}>
                      <table className={'table table-header-gray'}>
                        <thead>
                          <tr>
                            <th className={'border px-2'} style={{width: '120px'}}>
                              <strong>Action</strong>
                            </th>
                            <th className={'border text-nowrap'} style={{width: '80px'}}>
                              <strong>Type</strong>
                            </th>
                            <th className={'border text-nowrap'}>
                              <strong>Deskripsi</strong>
                            </th>
                            <th className={'border text-nowrap'} style={{width: '90px'}}>
                              <strong>Document</strong>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {attachmentBuyer?.map((item: any, index: number) => {
                            return (
                              <tr key={index}>
                                <td
                                  className={'border px-2 py-2 text-center'}
                                  style={{
                                    verticalAlign: 'top',
                                    left: 0,
                                    zIndex: 1,
                                    backgroundColor: '#fff',
                                  }}
                                >
                                  {currentUser?.has_roles?.toString().includes('buyer') && (
                                    <>
                                      {(po?.status === 'PO Approved' ||
                                        po?.status === 'Rejected Release') && (
                                        <ButtonGroup size={'sm'} aria-label='Action'>
                                          <button
                                            type='button'
                                            className='btn btn-sm btn-light-success text-nowrap'
                                            onClick={() => {
                                              setInitialValuesBuyer({
                                                deskripsi_buyer: item?.description,
                                                file_buyer_url: item?.media_url,
                                              })
                                              setItemId(item?.uuid)
                                              setKeyBuyer(keyBuyer + 1)
                                              const form = document?.getElementById('form-buyer')
                                              if (form) {
                                                window.scrollTo(
                                                  0,
                                                  form.offsetTop ? form.offsetTop - 75 : 0
                                                )
                                              }
                                            }}
                                          >
                                            <i className={'fa fa-edit'} />
                                          </button>
                                          <button
                                            type='button'
                                            className='btn btn-sm btn-light-danger text-nowrap'
                                            onClick={() => {
                                              deleteItem(item?.uuid)
                                            }}
                                          >
                                            <i className={'fa fa-trash'} />
                                          </button>
                                        </ButtonGroup>
                                      )}
                                    </>
                                  )}
                                </td>
                                <td className={'border py-4 text-center'}>
                                  {item?.type?.replace(/^\w/, (c: any) => c.toUpperCase())}
                                </td>
                                <td className={'border py-4 text-center'}>{item?.description}</td>
                                <td className={'border py-4 text-center'}>
                                  <a
                                    href={'#'}
                                    title={'Edit'}
                                    onClick={() => preview(item?.media_url)}
                                  >
                                    <i className='fa fs-5 fa-eye'></i>
                                  </a>
                                </td>
                              </tr>
                            )
                          })}
                          {attachmentBuyer?.length === 0 && (
                            <tr>
                              <td className={'border py-4 text-center'} colSpan={4}>
                                Tidak ada data!
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className='card border shadow-sm'>
                <div className='card-body'>
                  <h4 className='text-center'>Rekanan Upload Document</h4>
                  <hr className='mb-5' />
                  <Formik
                    key={keyRekanan}
                    onSubmit={onSubmitRekanan}
                    initialValues={initialValuesRekanan}
                    validationSchema={validationSchemaRekanan}
                  >
                    {({errors, touched, values, setFieldValue}) => (
                      <>
                        <Form id='form-rekanan'>
                          <div className='mb-10'>
                            <label className='required form-label'>Deskripsi</label>
                            <Field
                              name='deskripsi_rekanan'
                              type='text'
                              className='form-control'
                              disabled={
                                currentUser?.has_roles?.toString().includes('buyer') ||
                                po?.status !== 'Release'
                              }
                            />
                            <MetronicErrorMessage name='deskripsi_rekanan' />
                          </div>
                          <div className='mb-10'>
                            <FormikUploadFile
                              label='Lampiran'
                              name='file_rekanan_url'
                              disabled={
                                currentUser?.has_roles?.toString().includes('buyer') ||
                                po?.status !== 'Release'
                              }
                            />
                          </div>
                          <div className='mb-10 d-flex justify-content-end'>
                            <button
                              type='submit'
                              className='btn btn-sm btn-primary'
                              disabled={
                                loadingRekanan ||
                                currentUser?.has_roles?.toString().includes('buyer') ||
                                po?.status !== 'Release'
                              }
                            >
                              {loadingRekanan && (
                                <span className='spinner-border spinner-border-sm me-1' />
                              )}
                              Simpan
                            </button>
                          </div>
                        </Form>
                      </>
                    )}
                  </Formik>

                  <div className='mt-5'>
                    <div className={'table-responsive'}>
                      <table className={'table table-header-gray'}>
                        <thead>
                          <tr>
                            <th className={'border px-2'} style={{width: '120px'}}>
                              <strong>Action</strong>
                            </th>
                            <th className={'border text-nowrap'} style={{width: '80px'}}>
                              <strong>Type</strong>
                            </th>
                            <th className={'border text-nowrap'}>
                              <strong>Deskripsi</strong>
                            </th>
                            <th className={'border text-nowrap'} style={{width: '90px'}}>
                              <strong>Document</strong>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {attachmentRekanan?.map((item: any, index: number) => {
                            return (
                              <tr key={index}>
                                <td
                                  className={'border px-2 py-2 text-center'}
                                  style={{
                                    verticalAlign: 'top',
                                    left: 0,
                                    zIndex: 1,
                                    backgroundColor: '#fff',
                                  }}
                                >
                                  {!currentUser?.has_roles?.toString().includes('buyer') && (
                                    <>
                                      {po?.status === 'Release' && (
                                        <ButtonGroup size={'sm'} aria-label='Action'>
                                          <button
                                            type='button'
                                            className='btn btn-sm btn-light-success text-nowrap'
                                            onClick={() => {
                                              setInitialValuesRekanan({
                                                deskripsi_rekanan: item?.description,
                                                file_rekanan_url: item?.media_url,
                                              })
                                              setItemId(item?.uuid)
                                              setKeyRekanan(keyRekanan + 1)
                                              const form = document?.getElementById('form-rekanan')
                                              if (form) {
                                                window.scrollTo(
                                                  0,
                                                  form.offsetTop ? form.offsetTop - 75 : 0
                                                )
                                              }
                                            }}
                                          >
                                            <i className={'fa fa-edit'} />
                                          </button>
                                          <button
                                            type='button'
                                            className='btn btn-sm btn-light-danger text-nowrap'
                                            onClick={() => {
                                              deleteItem(item?.uuid)
                                            }}
                                          >
                                            <i className={'fa fa-trash'} />
                                          </button>
                                        </ButtonGroup>
                                      )}
                                    </>
                                  )}
                                </td>
                                <td className={'border py-4 text-center'}>
                                  {item?.type?.replace(/^\w/, (c: any) => c.toUpperCase())}
                                </td>
                                <td className={'border py-4 text-center'}>{item?.description}</td>
                                <td className={'border py-4 text-center'}>
                                  <a
                                    href={'#'}
                                    title={'Edit'}
                                    onClick={() => preview(item?.media_url)}
                                  >
                                    <i className='fa fs-5 fa-eye'></i>
                                  </a>
                                </td>
                              </tr>
                            )
                          })}
                          {attachmentRekanan?.length === 0 && (
                            <tr>
                              <td className={'border py-4 text-center'} colSpan={4}>
                                Tidak ada data!
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default ConfirmationPO
