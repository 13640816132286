import { ID, KTCard, KTCardBody } from '../../../../../../_metronic/helpers'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import TableXWrpper from "../../../../../components/shared/TableXWrapper";
import { ApiUrl } from '../../../../../config/Api'
import { getEnv } from "../../../../../../helpers/procurex";
import { useIntl } from 'react-intl'
import SelectX from '../../../../../components/shared/form/SelectX'
import { MetronicErrorMessage, toastError } from '../../../../../../_metronic/helpers'
import SwalX from '../../../../../components/shared/SwalX'
import { InputNumber } from "rsuite";
import { Field, Form, Formik } from 'formik'
import { toast } from 'react-toastify'
import axios from 'axios'
import { Button, ButtonGroup, Col, Modal, Row } from "react-bootstrap";
import DatepickerX2 from "../../../../../components/shared/form/DatepickerX2";
import { useAuth } from "../../../../../../app/modules/auth";

const SAPDailyStockviewPageList = () => {
    const { currentUser } = useAuth();
    const intl = useIntl()
    const tablecolumns = [
        {
            Header: "MATERIAL NUMBER",
            accessor: "material_number",
            width: 300,
        },
        {
            Header: "PLANT",
            accessor: "plant",
            width: 200,
        },
        {
            Header: "COMPANY CODE",
            accessor: "company_code",
            width: 150,
        },
        {
            Header: "QUANTITY",
            accessor: "quantity",
            width: 200,
        },
        {
            Header: "TANGGAL",
            accessor: "tanggal",
            width: 150,
            Cell: ({ value }) => {
                if (!value) return ''; // Handle null or undefined values
                const date = new Date(value);
                const day = date.getDate().toString().padStart(2, '0'); // Get day and pad with leading zero if necessary
                const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get month (+1 because month index starts from 0) and pad with leading zero if necessary
                const year = date.getFullYear(); // Get full year
                return `${day}-${month}-${year}`;
            }
        },
    ];
    const [isShowModal, setIsShowModal] = useState(false)
    const [isShowDetail, setIsShowDetail] = useState(false)
    const [key, setKey] = useState(0)
    const [id, setId] = useState('')
    const [item, setItem] = useState<any>(null)
    const [isConfirmDialog, setIsConfirmDialog] = useState(false)
    const [modalData, setModalData] = useState('')

    const [totalSafetyStock, setTotaSafetyStock] = useState(0);
    const [totalQTY, setTotalQTY] = useState(0);
    const [totalContract, setTotalContract] = useState(0);

    const handleOnTotalSafetyStockChange = (score: number) => {
        setTotaSafetyStock(score)
    }
    const handleOnTotalContractChange = (score: number) => {
        setTotalContract(score)
    }
    const handleOnTotalQTYChange = (score: number) => {
        setTotalQTY(score)
    }

    const [selectedMaterial, setSelectedMaterial] = useState(null);
    const [selectedPlant, setSelectedPlant] = useState(null);
    const [selectedCompany, setSelectedCompany] = useState(null);

    const handleMaterialSelect = (selectedValue: any) => {
        setSelectedMaterial(selectedValue);
    };

    const handlePlantSelect = (selectedValue: any) => {
        setSelectedPlant(selectedValue);
    };

    const handleCompanySelect = (selectedValue: any) => {
        setSelectedCompany(selectedValue);
    };

    const [itemsMasterMaterial, setItemsMasterMaterial] = useState<any>([])
    const [itemsMasterCompany, setItemsCompanyStock] = useState<any>([])
    const [ItemsTransportasi, setItemsTransportasi] = useState<any>([])
    const [ItemsPlant, setItemsPlant] = useState<any>([])
    const [ItemsSloc, setItemsSloc] = useState<any>([])
    const [itemLoading, setItemLoading] = useState(false)
    const excludedRoles = ['admin_anper', 'DaanExp_Anper', 'Gudang_Bahan_Baku_Anper', 'Managerial_PI', 'RendalProd_Anper'];

    const loadItem = () => {
        axios
            .get(getEnv("masterdata") + `/master-material`)
            .then((res) => {
                setItemsMasterMaterial(res.data.data)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setItemLoading(false)
            })
        axios
            .get(getEnv("masterdata") + `/company-stock`)
            .then((res) => {
                setItemsCompanyStock(res.data.data)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setItemLoading(false)
            })
        axios
            .get(getEnv("masterdata") + `/transportation?perPage=142&page=1`)
            .then((res) => {
                setItemsTransportasi(res.data.data)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setItemLoading(false)
            })
        axios
            .get(getEnv("masterdata") + `/material-plant-all?filter[active_app]=X&company_code=${company_code_user_first}`)
            .then((res) => {
                setItemsPlant(res.data.data)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setItemLoading(false)
            })
    }

    useEffect(() => {
        loadItem();
    }, [])

    const [formindex, setFormIndex] = useState(0)

    let company_code_user = currentUser.company_code;
    let company_code_user_first = currentUser.company_code[0];
    let role = currentUser.has_roles;

    return (
        <>
            <KTCard className='card border mt-5'>
                <KTCardBody >
                    <TableXWrpper
                        title={"SAP Daily Stock Plant"}
                        key={formindex}
                        readonly
                        urlIndex={getEnv("stockview") + `/sap-daily-stock-list?company_code=${company_code_user}`}
                        columns={tablecolumns}
                    />
                </KTCardBody>
            </KTCard>
        </>
    )
}

export { SAPDailyStockviewPageList }
