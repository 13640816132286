import React, { FC, useState } from "react";
import FormCreateProposalTender from "./FormCreateProposalTender";
import { useNavigate } from "react-router-dom";
import { ButtonGroup } from "react-bootstrap";
import FormClosePR from "./FormClosePR";
import { toast } from "react-toastify";
import {toastError} from '../../../../../../_metronic/helpers'
import FormSinkronSAP from "./FormSinkronSAP";
import FormCancelPR from "./FormCancelPR";

type IProps = {
  selectedRows: any
  afterSubmit: Function,
  afterSync?: Function
}
const ButtonToolbarProposalTender : FC<IProps> = (props) => {
  const [showModalCreate, setShowModalCreate] = useState(false)
  const [showModalSinkronSAP, setShowModalSinkronSAP] = useState(false)
  const [showModalClose, setShowModalClose] = useState(false)
  const [showModalCancel, setShowModalCancel] = useState(false)
  const navigation = useNavigate()

  const showModalCreateProposalTender = () => {
    let error = false;

    props.selectedRows.map((row) => {
      // cek currency must be same
      if (row.original?.currency_id !== props.selectedRows[0].original?.currency_id) {
        toastError("Error, currency must be the same");
        error = true;
      }

    })
    if (!error) setShowModalCreate(true);
  }

  return (
    <>
      <ButtonGroup>
        <button
          className="btn btn-sm btn-info shadow-sm fw-bold"
          disabled={props.selectedRows.length === 0}
          onClick={() => setShowModalSinkronSAP(true)}
        >
          <i className={"fa fa-repeat fa-fw me-2"}></i>
          Sinkron SAP
        </button>
        <button
          className="btn btn-sm btn-primary shadow-sm fw-bold"
          disabled={props.selectedRows.length === 0}
          onClick={() => showModalCreateProposalTender()}
        >
          <i className={"fa fa-plus fa-fw me-2"}></i>
          Buat Tender
        </button>
        <button
          className="btn btn-sm btn-danger shadow-sm fw-bold"
          disabled={props.selectedRows.length === 0}
          onClick={() => setShowModalClose(true)}
        >
          <i className={"fa fa-plane-up fa-fw me-2"}></i>
          Close PR
        </button>
        <button
          className="btn btn-sm btn-warning shadow-sm fw-bold"
          disabled={props.selectedRows.length === 0}
          onClick={() => setShowModalCancel(true)}
        >
          <i className={"fa fa-ban fa-fw me-2"}></i>
          PR Batal
        </button>
      </ButtonGroup>
      {/*<button*/}
      {/*  className="btn btn-sm btn-light-danger shadow-sm fw-bold"*/}
      {/*  disabled={props.selectedRows.length === 0}*/}
      {/*  onClick={() => setShowModalReturn(true)}*/}
      {/*>*/}
      {/*  <i className="fas fa-redo fa-fw me-2"></i>*/}
      {/*  Kembalikan PR*/}
      {/*</button>*/}

      <FormCreateProposalTender
        show={showModalCreate}
        closeModal={setShowModalCreate}
        selectedRows={props.selectedRows}
        onSuccessCreate={(e:any) => {
          navigation(`/tender/admin/proposal-tender/detail/${e?.data?.uuid}`)
        }}
      />

      <FormSinkronSAP
        show={showModalSinkronSAP}
        closeModal={setShowModalSinkronSAP}
        selectedRows={props.selectedRows}
        onSuccessClose={props.afterSync}
      />

      <FormClosePR
        show={showModalClose}
        closeModal={setShowModalClose}
        selectedRows={props.selectedRows}
        onSuccessClose={props.afterSync}
      />

      <FormCancelPR
        show={showModalCancel}
        closeModal={setShowModalCancel}
        selectedRows={props.selectedRows}
        onSuccessClose={props.afterSync}
      />

    </>
  )
}

export default ButtonToolbarProposalTender