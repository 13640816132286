import {KTCard, KTCardBody} from '../../../../../../_metronic/helpers'
import React from 'react'
import TableXWrpper from "../../../../../components/shared/TableXWrapper";
import { useNavigate } from "react-router-dom";
import { getEnv } from "../../../../../../helpers/procurex";

const PraQualificationVendorListDraft = () => {
  const urlIndex = getEnv('tender')+"/vendor/pq";
  const navigate = useNavigate();
  const tableColumns = [
    {
      Header: 'Action',
      accessor: 'action',
      width: 50,
      className: 'text-center',
      Cell: (props: any) => {
        const row = props.row.original;
        return (
          <>
            <a href={"#"} title={"Edit"} onClick={() => {
              navigate("/tender/vendor/pra-qualification/"+row.uuid);
            }}>
              <i className="fa fs-3 fa-eye"></i>
            </a>
          </>
        )
      }
    },
    {Header: 'status', accessor: 'status', width: 200, className: 'text-center'},
    {Header: 'No', accessor: 'no', width: 200, disableCreate: true, disableEdit: true, className: 'text-center'},
    {Header: 'Title', accessor: 'title', width: 300},
    {Header: 'Reference', accessor: 'reference', width: 200, className:'text-center'},
    {Header: 'Purchasing Group', accessor: 'purchasing_group_id', width: 150, className:'text-center'},
    {Header: 'Project Type', accessor: 'project_type', width: 150, className:'text-center'},
    {Header: 'Start At', accessor: 'start_at', width: 150, className:'text-center', Cell(props: any) {
      const row = props.row.original.jadwals.filter((item: any) => item.description === 'Pra Qualification');
      return (
        <>
          {row[0]?.start_at}
        </>
      )
    }},
    {Header: 'End At', accessor: 'end_at', width: 150, className:'text-center', Cell(props: any) {
        const row = props.row.original.jadwals.filter((item: any) => item.description === 'Pra Qualification');
        return (
          <>
            {row[0]?.end_at}
          </>
        )
      }},
    {Header: 'Created At', accessor: 'created_at', width: 150, className:'text-center text-nowrap'},
  ]
  return (
    <>
      <KTCard>
        <KTCardBody className='py-4'>
          <TableXWrpper
              readonly={true}
              title={"Proposal Tender"}
              urlIndex={urlIndex}
              columns={tableColumns}
              isForceCreateButton={true}
          />
        </KTCardBody>
      </KTCard>
    </>
  )
}

export {PraQualificationVendorListDraft}
