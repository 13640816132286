import {FC, useRef, useState} from 'react'
import {Col, Container, Modal, OverlayTrigger, Row, Tooltip} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {NavLink} from 'react-router-dom'
import NavigationBar from '../components/NavigationBar'
import {Field, Form, Formik} from 'formik'
import SelectX from '../../../components/shared/form/SelectX'
import FormikPublicSelect from './components/FormikPublicSelect'
import axios from 'axios'
import * as Yup from 'yup'
import {KTSVG, MetronicErrorMessage, toastError, toastSuccess} from '../../../../_metronic/helpers'
import {useUploadMedia} from '../../../../hooks/MediaUploadHook'
import {toast} from 'react-toastify'
import clsx from 'clsx'
import FormikSelect2 from '../../../components/shared/form/FormikSelect2'
import FormikUploadFile from '../../../components/shared/form/FormikUploadFile'
import {Button} from 'rsuite'

const API_URL = process.env.REACT_APP_API_URL

const validationSchema = Yup.object({
  name: Yup.string().required('The field is required'),
  president_director_name: Yup.string().required('The field is required'),
  company_email: Yup.string().required('The field is required').email('Invalid email'),
  company_phone: Yup.string().required('The field is required'),
  pic_name: Yup.string().required('The field is required'),
  pic_email: Yup.string().required('The field is required').email('Invalid email'),
  pic_phone: Yup.string().required('The field is required'),
  tdp_no: Yup.string().when('company_type', {
    is: (company_type: any) =>
      company_type && company_type.code !== 'Z017' && company_type.code !== 'Z018',
    then: Yup.string().required('The field is required'),
    otherwise: Yup.string().nullable(),
  }),
  npwp_no: Yup.string().when('vendor_type', {
    is: (vendor_type: any) => vendor_type && vendor_type.id !== 2,
    then: Yup.string()
      .required('The field is required')
      // .min(16, 'Must be exactly 16 digits')
      // .max(16, 'Must be exactly 16 digits')
      .matches(/^[0-9]+$/, 'Must be only digits'),
    otherwise: Yup.string().nullable(),
  }),
  npwp_file_url: Yup.string().when('vendor_type', {
    is: (vendor_type: any) => vendor_type && vendor_type.id !== 2,
    then: Yup.string().required('The field is required'),
    otherwise: Yup.string().nullable(),
  }),
  tdp_file_url: Yup.string().when('company_type', {
    is: (company_type: any) =>
      company_type && company_type.code !== 'Z017' && company_type.code !== 'Z018',
    then: Yup.string().required('The field is required'),
    otherwise: Yup.string().nullable(),
  }),
  purchasing_org: Yup.object().required('The field is required'),
  branch_center: Yup.object().required('The field is required'),
  vendor_type: Yup.object().required('The field is required'),
  province: Yup.object().when('vendor_type', {
    is: (vendor_type: any) => vendor_type && vendor_type.id !== 2,
    then: Yup.object().required('The field is required'),
    otherwise: Yup.object().nullable(),
  }),
  country: Yup.object().required('The field is required'),
  district: Yup.mixed().required('The field is required'),
  sub_district: Yup.object().when('vendor_type', {
    is: (vendor_type: any) => vendor_type && vendor_type.id !== 2,
    then: Yup.object().required('The field is required'),
    otherwise: Yup.object().nullable(),
  }),
  village: Yup.object().when('vendor_type', {
    is: (vendor_type: any) => vendor_type && vendor_type.id !== 2,
    then: Yup.object().required('The field is required'),
    otherwise: Yup.object().nullable(),
  }),
  postal_code: Yup.object().when('vendor_type', {
    is: (vendor_type: any) => vendor_type && vendor_type.id !== 2,
    then: Yup.object().required('The field is required'),
    otherwise: Yup.object().nullable(),
  }),
  parent: Yup.object().when('branch_center', {
    is: (branch_center: any) => branch_center && branch_center.id === 2,
    then: Yup.object().required('The field is required'),
    otherwise: Yup.object().nullable(),
  }),
  street: Yup.string().required('The field is required'),
})

const VendorRegister: FC = () => {
  const intl = useIntl()
  const {upload} = useUploadMedia()
  const [indexForm, setFormIndex] = useState(0)
  let [errorMessage, setErrorMessage] = useState('')
  let [fileTdp, setFileTdp] = useState<any>(null)
  const [loading, setLoading] = useState(false)

  const initialValues: any = {
    name: '',
    president_director_name: '',
    company_email: '',
    company_phone: '',
    company_website: '',
    pic_name: '',
    pic_email: '',
    pic_phone: '',
    tdp_no: '',
    npwp_no: '',
    npwp_file_url: '',
    tdp_file_url: '',
    purchasing_org: '',
    branch_center: '',
    vendor_type: '',
    company_type: '',
    country: '',
    province: '',
    district: '',
    sub_district: '',
    village: '',
    address: '',
    postal_code: '',
    parent_id: '',
    parent: '',
    street: '',
    street_1: '',
    street_2: '',
    street_3: '',
  }

  const onSubmit = async (values: any, {resetForm}: any) => {
    setLoading(true)
    let data = {...values}

    try {
      if (data.vendor_type?.id === 2) {
        data.district = {
          name: data.district,
        }
      }

      if (data.parent?.id) data.parent_id = data.parent.id

      if (typeof data.npwp_file_url !== 'string') {
        let responseFile = await upload('20', data.npwp_file_url)

        data.npwp_file_url = responseFile.url
      }

      if (typeof data.tdp_file_url !== 'string') {
        let responseFile = await upload('20', data.tdp_file_url)

        data.tdp_file_url = responseFile.url
      }

      const response = await axios.post(`${API_URL}/api/vendor/public/register-vendor`, data)

      if (response.status === 201 || response.status === 200) {
        setShow(true)
        // toast.success(
        //   response?.status + ': ' + 'Pendaftaran Vendor Berhasil Silahkan Cek Email PIC Anda',
        //   {
        //     position: 'top-right',
        //     autoClose: 5000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //     theme: 'light',
        //   }
        // )
        // resetForm(initialValues)
        setFormIndex(indexForm + 1)
        setLoading(false)
        return true
      }
    } catch (error: any) {
      console.error(error)

      toastError(error.response?.data?.meta?.message || 'Terjadi kesalahan')
      setLoading(false)
    }
  }

  const formik = useRef({} as any)

  const fileTdpOnChange = (e: any) => {
    let file = e.target.files[0]

    if (file) {
      if (formik.current) formik.current.setFieldValue('tdp_file_url', file.name)
      setFileTdp(file)
    }
  }

  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <>
      <NavigationBar />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <i className='fa fa-check-circle text-success me-4' aria-hidden='true'></i>
            Berhasil
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Pendaftaran vendor behasil, silahkan cek email PIC anda</Modal.Body>
        <Modal.Footer>
          <button type='button' className='btn btn-secondary' onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <div
        style={{
          height: '500px',
          backgroundColor: '#777',
          backgroundImage:
            'linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0)), url(/background_sign_up.jpg)',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <Container>
          <Row>
            <Col>
              <p
                style={{
                  color: 'white',
                  fontSize: '28px',
                  fontWeight: '550',
                  textAlign: 'left',
                  paddingTop: '160px',
                }}
              >
                Registrasi Vendor Procurement System <br /> Pupuk Indonesia (Persero)
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <main>
        <Container>
          <Row>
            <Col>
              <div className='card' style={{marginTop: '-80px'}}>
                <div className='card-body'>
                  <Formik
                    key={indexForm}
                    innerRef={formik}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    {({values, setFieldValue}) => (
                      <Form>
                        <div className='card border'>
                          <div className='card-body'>
                            <h6>Data Perusahaan</h6>
                            <div className='row mt-8'>
                              <div className='col-lg-4'>
                                <div className='mb-10'>
                                  <FormikPublicSelect
                                    name='purchasing_org'
                                    label='Perusahaan PI Group'
                                    optionsUrl={API_URL + '/api/masterdata/public/companies'}
                                    getOptionValue={(opt: any) => opt}
                                    getOptionLabel={(opt: any) => `${opt.comp_code} - ${opt.name}`}
                                    search_property={'name'}
                                  />
                                </div>
                                <div className='mb-10'>
                                  <FormikSelect2
                                    not_required
                                    name='company_type'
                                    label='Badan Usaha'
                                    optionsUrl={
                                      API_URL + '/api/vendor/public/company-type?sort=name'
                                    }
                                    getOptionValue={(opt: any) => opt}
                                    getOptionLabel={(opt) => `${opt.name}`}
                                    search_property={'name'}
                                  />
                                </div>
                                {values.branch_center?.id === 2 && (
                                  <div className='mb-10'>
                                    <FormikPublicSelect
                                      name='parent'
                                      label='Vendor Pusat'
                                      optionsUrl={`${API_URL}/api/vendor/public/vendor`}
                                      getOptionValue={(opt: any) => opt}
                                      getOptionLabel={(opt) => `${opt.vendor_no} - ${opt.name}`}
                                      search_property={'filter[vendor_no_name]'}
                                    />
                                  </div>
                                )}
                              </div>
                              <div className='col-lg-4'>
                                <div className='mb-10'>
                                  <label htmlFor='' className='required form-label'>
                                    Perusahaan Pusat/Cabang
                                  </label>
                                  <SelectX
                                    onChange={(e: any) => setFieldValue('branch_center', e)}
                                    options={[
                                      {
                                        label: 'Pusat',
                                        value: {
                                          id: 1,
                                          name: 'Pusat',
                                        },
                                      },
                                      {
                                        label: 'Cabang',
                                        value: {
                                          id: 2,
                                          name: 'Cabang',
                                        },
                                      },
                                    ]}
                                  />
                                  <MetronicErrorMessage name='branch_center' />
                                </div>
                                <div className='mb-10'>
                                  <label htmlFor='' className='required form-label'>
                                    Nama Perusahaan
                                  </label>
                                  <Field className='form-control' name='name' />
                                  <MetronicErrorMessage name='name' />
                                </div>
                              </div>
                              <div className='col-lg-4'>
                                <div className='mb-10'>
                                  <label htmlFor='' className='required form-label'>
                                    Tipe Perusahaan
                                  </label>
                                  <SelectX
                                    onChange={(e: any) => setFieldValue('vendor_type', e)}
                                    options={[
                                      {
                                        label: 'Dalam Negeri',
                                        value: {
                                          id: 1,
                                          name: 'Dalam Negeri',
                                        },
                                      },
                                      {
                                        label: 'Luar Negeri',
                                        value: {
                                          id: 2,
                                          name: 'Luar Negeri',
                                        },
                                      },
                                    ]}
                                  />
                                  <MetronicErrorMessage name='vendor_type' />
                                </div>
                                <div className='mb-10'>
                                  <label htmlFor='' className='required form-label'>
                                    Nama Direktur Utama
                                  </label>
                                  <Field className='form-control' name='president_director_name' />
                                  <MetronicErrorMessage name='president_director_name' />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='card border mt-3'>
                          <div className='card-body'>
                            <h6>Detail Perusahaan</h6>
                            <div className='row mt-8'>
                              <div className='col-lg-4'>
                                <div className='mb-10'>
                                  <label htmlFor='' className='required form-label'>
                                    Email Perusahaan
                                  </label>
                                  <Field
                                    className='form-control'
                                    name='company_email'
                                    type='email'
                                  />
                                  <MetronicErrorMessage name='company_email' />
                                </div>
                                <div className='mb-10'>
                                  <label htmlFor='' className='required form-label'>
                                    Nama PIC
                                  </label>
                                  <Field className='form-control' name='pic_name' />
                                  <MetronicErrorMessage name='pic_name' />
                                </div>
                              </div>
                              <div className='col-lg-4'>
                                <div className='mb-10'>
                                  <label htmlFor='' className='required form-label'>
                                    No Telepon Perusahaan
                                  </label>
                                  <Field className='form-control' name='company_phone' />
                                  <MetronicErrorMessage name='company_phone' />
                                </div>
                                <div className='mb-10'>
                                  <label htmlFor='' className='required form-label'>
                                    Email PIC
                                  </label>
                                  <Field className='form-control' name='pic_email' type='email' />
                                  <MetronicErrorMessage name='pic_email' />
                                </div>
                              </div>
                              <div className='col-lg-4'>
                                <div className='mb-10'>
                                  <label htmlFor='' className='form-label'>
                                    Website Perusahaan
                                  </label>
                                  <Field className='form-control' name='company_website' />
                                  <MetronicErrorMessage name='company_website' />
                                </div>
                                <div className='mb-10'>
                                  <label htmlFor='' className='required form-label'>
                                    No HP PIC
                                  </label>
                                  <Field className='form-control' name='pic_phone' />
                                  <MetronicErrorMessage name='pic_phone' />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='card border mt-3'>
                          <div className='card-body'>
                            <h6>Alamat Perusahaan</h6>
                            <div className='row mt-8'>
                              <div className='col-lg-4'>
                                <div className='mb-10'>
                                  <div className='d-flex align-items-center justify-content-between'>
                                    <label htmlFor='' className='required form-label'>
                                      <span>Alamat 1</span>
                                      <OverlayTrigger
                                        placement='right'
                                        overlay={
                                          <Tooltip id='button-tooltip-2'>
                                            Penulisan alamat max 40 karakter, jika melebihi silahkan
                                            lanjut ke field alamat 2 dan seterusnya
                                          </Tooltip>
                                        }
                                      >
                                        <i className='bi bi-patch-question-fill ms-3'></i>
                                      </OverlayTrigger>
                                    </label>
                                    <div>
                                      <span
                                        className={
                                          'text-sm ' +
                                          (values?.street?.length !== 40
                                            ? 'text-success'
                                            : 'text-danger')
                                        }
                                      >
                                        {values?.street?.length}/40
                                      </span>
                                    </div>
                                  </div>
                                  <Field className='form-control' name='street' maxLength={40} />
                                  <MetronicErrorMessage name='street' />
                                </div>
                                <div className='mb-10'>
                                  <div className='d-flex align-items-center justify-content-between'>
                                    <label htmlFor='' className='form-label'>
                                      Alamat 2
                                    </label>
                                    <div>
                                      <span
                                        className={
                                          'text-sm ' +
                                          (values?.street_1?.length !== 40
                                            ? 'text-success'
                                            : 'text-danger')
                                        }
                                      >
                                        {values?.street_1?.length}/40
                                      </span>
                                    </div>
                                  </div>
                                  <Field
                                    maxLength={40}
                                    disabled={!values.street}
                                    rows={10}
                                    className='form-control'
                                    name='street_1'
                                  />
                                  <MetronicErrorMessage name='street_1' />
                                </div>
                                <div className='mb-10'>
                                  <div className='d-flex align-items-center justify-content-between'>
                                    <label htmlFor='' className='form-label'>
                                      Alamat 3
                                    </label>
                                    <div>
                                      <span
                                        className={
                                          'text-sm ' +
                                          (values?.street_2?.length !== 40
                                            ? 'text-success'
                                            : 'text-danger')
                                        }
                                      >
                                        {values?.street_2?.length}/40
                                      </span>
                                    </div>
                                  </div>
                                  <Field
                                    maxLength={40}
                                    disabled={!values.street_1 || !values.street}
                                    rows={10}
                                    className='form-control'
                                    name='street_2'
                                  />
                                  <MetronicErrorMessage name='street_2' />
                                </div>
                                <div className='mb-10'>
                                  <div className='d-flex align-items-center justify-content-between'>
                                    <label htmlFor='' className='form-label'>
                                      Alamat 4
                                    </label>
                                    <div>
                                      <span
                                        className={
                                          'text-sm ' +
                                          (values?.street_3?.length !== 40
                                            ? 'text-success'
                                            : 'text-danger')
                                        }
                                      >
                                        {values?.street_3?.length}/40
                                      </span>
                                    </div>
                                  </div>
                                  <Field
                                    maxLength={40}
                                    disabled={!values.street_2 || !values.street}
                                    rows={10}
                                    className='form-control'
                                    name='street_3'
                                  />
                                  <MetronicErrorMessage name='street_3' />
                                </div>
                              </div>
                              <div className='col-lg-4'>
                                <div className='mb-10'>
                                  {values.vendor_type?.id === 1 && (
                                    <>
                                      <FormikPublicSelect
                                        name='country'
                                        label='Negara'
                                        optionsUrl={
                                          API_URL + '/api/masterdata/public/countries?code=ID'
                                        }
                                        getOptionValue={(opt: any) => opt}
                                        getOptionLabel={(opt: any) => `${opt.code} - ${opt.name}`}
                                        search_property={'name'}
                                      />
                                    </>
                                  )}
                                  {values.vendor_type?.id === 2 && (
                                    <>
                                      <FormikPublicSelect
                                        name='country'
                                        label='Negara'
                                        optionsUrl={API_URL + '/api/masterdata/public/countries'}
                                        getOptionValue={(opt: any) => opt}
                                        getOptionLabel={(opt: any) => `${opt.code} - ${opt.name}`}
                                        search_property={'name'}
                                      />
                                    </>
                                  )}
                                </div>
                                {values.vendor_type?.id === 2 && (
                                  <div className='mb-10'>
                                    <label htmlFor='' className='required form-label'>
                                      Kota / Kabupaten
                                    </label>
                                    <Field name='district' type='text' className='form-control' />
                                    <MetronicErrorMessage name='district' />
                                  </div>
                                )}
                                {values.vendor_type?.id !== 2 && (
                                  <div className='mb-10'>
                                    <FormikPublicSelect
                                      key={values?.province?.id || 'district1'}
                                      name='district'
                                      label='Kota/Kabupaten'
                                      optionsUrl={
                                        API_URL +
                                        `/api/masterdata/public/districts?region_code=${
                                          values?.province?.code || ''
                                        }`
                                      }
                                      getOptionValue={(opt: any) => opt}
                                      getOptionLabel={(opt: any) => `${opt.name}`}
                                      search_property={'name'}
                                      isDisabled={!values.province}
                                    />
                                  </div>
                                )}
                                <div className='mb-10'>
                                  <FormikPublicSelect
                                    not_required={values.vendor_type?.id === 2}
                                    key={values?.sub_district?.code || 'village1'}
                                    name='village'
                                    label='Kelurahan'
                                    optionsUrl={
                                      API_URL +
                                      `/api/masterdata/public/villages?sub_district_code=${
                                        values?.sub_district?.code || ''
                                      }`
                                    }
                                    getOptionValue={(opt: any) => opt}
                                    getOptionLabel={(opt: any) => `${opt.name}`}
                                    search_property={'name'}
                                    isDisabled={!values.sub_district}
                                  />
                                </div>
                              </div>
                              <div className='col-lg-4'>
                                <div className='mb-10'>
                                  <FormikPublicSelect
                                    not_required={values.vendor_type?.id === 2}
                                    key={values?.country?.code || 'region1'}
                                    name='province'
                                    label='Provinsi'
                                    optionsUrl={
                                      API_URL +
                                      `/api/masterdata/public/regions?country_code=${
                                        values?.country?.code || ''
                                      }`
                                    }
                                    getOptionValue={(opt: any) => opt}
                                    getOptionLabel={(opt: any) => `${opt.name}`}
                                    search_property={'name'}
                                    isDisabled={!values.country}
                                  />
                                </div>
                                <div className='mb-10'>
                                  <FormikPublicSelect
                                    not_required={values.vendor_type?.id === 2}
                                    key={values?.district?.code || 'subdistrict1'}
                                    name='sub_district'
                                    label='Kecamatan'
                                    optionsUrl={
                                      API_URL +
                                      `/api/masterdata/public/sub-districts?district_code=${
                                        values?.district?.code || ''
                                      }`
                                    }
                                    getOptionValue={(opt: any) => opt}
                                    getOptionLabel={(opt: any) => `${opt.name}`}
                                    search_property={'name'}
                                    isDisabled={!values.district}
                                  />
                                </div>
                                <div className='mb-10'>
                                  <FormikPublicSelect
                                    not_required={values.vendor_type?.id === 2}
                                    key={values?.sub_district?.code || 'postalcode1'}
                                    name='postal_code'
                                    label='Kode Pos'
                                    optionsUrl={
                                      API_URL +
                                      `/api/masterdata/public/postal-codes?sub_district_code=${
                                        values?.sub_district?.code || ''
                                      }`
                                    }
                                    getOptionValue={(opt: any) => opt}
                                    getOptionLabel={(opt: any) => `${opt.name}`}
                                    search_property={'name'}
                                    isDisabled={!values.sub_district}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='card border mt-3'>
                          <div className='card-body'>
                            <h6>Dokumen Perusahaan</h6>
                            <div className='row mt-8'>
                              <div className='col-lg-3'>
                                <div className='mb-10'>
                                  <label
                                    htmlFor=''
                                    className={clsx(
                                      'form-label',
                                      `${values.vendor_type?.id !== 2 ? 'required' : ''}`
                                    )}
                                  >
                                    No NPWP
                                  </label>
                                  <Field className='form-control' name='npwp_no' />
                                  <MetronicErrorMessage name='npwp_no' />
                                </div>
                              </div>
                              <div className='col-lg-3'>
                                <div className='mb-10'>
                                  <FormikUploadFile
                                    label='File NPWP'
                                    name='npwp_file_url'
                                    not_required={values.vendor_type?.id === 2}
                                  />
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                      <span role='alert'>File Type : pdf, jpg, png</span>
                                      <br />
                                      <span role='alert'>
                                        Maksimal file yang diupload berukuran 2MB
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='col-lg-3'>
                                <div className='mb-10'>
                                  <label
                                    htmlFor=''
                                    className={clsx(
                                      'form-label',
                                      `${
                                        values.company_type?.code !== 'Z018' &&
                                        values.company_type?.code !== 'Z017' &&
                                        values.company_type
                                          ? 'required'
                                          : ''
                                      }`
                                    )}
                                  >
                                    No NIB
                                  </label>
                                  <Field className='form-control' name='tdp_no' />
                                  <MetronicErrorMessage name='tdp_no' />
                                </div>
                              </div>
                              <div className='col-lg-3'>
                                <div className='mb-10'>
                                  <FormikUploadFile
                                    label='File NIB'
                                    name='tdp_file_url'
                                    not_required={
                                      !(
                                        values.company_type?.code !== 'Z018' &&
                                        values.company_type?.code !== 'Z017' &&
                                        values.company_type
                                      )
                                    }
                                  />
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                      <span role='alert'>File Type : pdf, jpg, png</span>
                                      <br />
                                      <span role='alert'>
                                        Maksimal file yang diupload berukuran 2MB
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='mt-8 d-flex justify-content-end'>
                          <button type='submit' className='btn btn-primary' disabled={loading}>
                            {loading && <span className='spinner-border spinner-border-sm me-1' />}
                            Submit
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </main>
      <footer className='mt-10 text-center py-5'>
        © 2023 PT Pupuk Indonesia (Persero) Tbk | All Rights Reserved
      </footer>
    </>
  )
}

export {VendorRegister}
