import {useState, useEffect} from 'react'
import {Carousel} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import TaskTable from '../../modules/dashboard/components/TaskTable'
import WidgetListApp from '../../modules/dashboard/components/WidgetListApp'
import {useAuth} from '../../modules/auth'
import {useNavigate} from 'react-router-dom'
import { darkModeClass } from "../../../helpers/utils";

const DashboardPage = () => {
  const [index, setIndex] = useState(0)
  // useEffect(()=>{
  //     axios.get('https://procurement-dev.pupuk-indonesia.com/api/mr-sr/__error')
  // },[])
  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex)
  }



  useEffect(() => {

  }, []);

  return (
    <>
      {/* begin::Row */}
      <div className='row g-5 g-xl-8 mb-5'>
        <div className='col-md-8'>
          <div className='card'>
            <div className='card-header'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold text-dark'>Berita</span>
                <span className={`mt-1 fw-semibold fs-7 ${darkModeClass()}`}>
                  Menampilkan berita terbaru{' '}
                </span>
              </h3>
            </div>
            <div className='card-body'>
              <Carousel activeIndex={index} onSelect={handleSelect} indicators={false}>
                <Carousel.Item>
                  <img
                    className='d-block w-100'
                    src='https://images.unsplash.com/photo-1560493676-04071c5f467b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1374&q=80'
                    alt='First slide'
                    style={{height: '350px'}}
                  />
                  <div>
                    <h3 className='mt-3'>
                      Selamat Datang di Procurement System Pupuk Indonesia (Persero)
                    </h3>
                    {/* <p style={{color: '#666'}}>22 Agustus 2022</p> */}
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className='d-block w-100'
                    src='https://images.unsplash.com/photo-1560493676-04071c5f467b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1374&q=80'
                    alt='First slide'
                    style={{height: '350px'}}
                  />
                  <div>
                    <h3 className='mt-3'>
                      Selamat Datang di Procurement System Pupuk Indonesia (Persero)
                    </h3>
                    {/* <p style={{color: '#666'}}>22 Agustus 2022</p> */}
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className='d-block w-100'
                    src='https://images.unsplash.com/photo-1560493676-04071c5f467b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1374&q=80'
                    alt='First slide'
                    style={{height: '350px'}}
                  />
                  <div>
                    <h3 className='mt-3'>
                      Selamat Datang di Procurement System Pupuk Indonesia (Persero)
                    </h3>
                    {/* <p style={{color: '#666'}}>22 Agustus 2022</p> */}
                  </div>
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
        </div>
        <WidgetListApp />
      </div>
      {/* end::Row */}
      <div className='row g-5 g-xl-8 mb-5'>
        <div className='col'>
          <div className='card'>
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold text-dark'>Daftar Task</span>
                <span className={`mt-1 fw-semibold fs-7 ${darkModeClass()}`}>
                  Tugas yang perlu kamu lihat
                </span>
              </h3>
            </div>
            <div className='card-body'>
              <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6'>
                <li className='nav-item'>
                  <a className={`nav-link active ${darkModeClass()}`} data-bs-toggle='tab' href='#kt_tab_pane_1'>
                    MR/SR Online
                  </a>
                </li>
                {/*<li className='nav-item'>*/}
                {/*  <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_2'>*/}
                {/*    Vendor Management*/}
                {/*  </a>*/}
                {/*</li>*/}
                {/*<li className='nav-item'>*/}
                {/*  <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_3'>*/}
                {/*    Stock View*/}
                {/*  </a>*/}
                {/*</li>*/}
              </ul>
              <div className='tab-content' id='myTabContent'>
                <div className='tab-pane fade active show' id='kt_tab_pane_1' role='tabpanel'>
                  <TaskTable />
                </div>
                {/*<div className='tab-pane fade' id='kt_tab_pane_2' role='tabpanel'>*/}
                {/*  <TaskTable />*/}
                {/*</div>*/}
                {/*<div className='tab-pane fade' id='kt_tab_pane_3' role='tabpanel'>*/}
                {/*  <TaskTable />*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const DashboardWrapper = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (currentUser?.has_roles.includes('vendor')) {
      navigate('/vendor/dashboard')
    }
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
