import AanwijzingVendorDetail from "./AanwijzingVendorDetail"
import {useParams} from 'react-router-dom'

const AanwijzingVendorDetailFromMenu = () => {
  const {uuid} = useParams()
  return (
    <div>
      <AanwijzingVendorDetail aanwijzingUuid={uuid} />
    </div>
  )
}

export default AanwijzingVendorDetailFromMenu;