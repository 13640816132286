import React, { useState } from "react";
import QuotationVendorTab from "./QuotationVendorTab";
import { Nav } from "rsuite";

const InputPenawaranPage = ({ proposalTender, onRefresh }: any) => {
  const [selectedTab, setSelectedTab] = useState("dua-tahap-penawaran-administrasi-teknis");

  const checkIsDuaTahap = () => {
    return proposalTender?.metode_penyampaian === "2 Tahap";
  };

  return (
    <>
      <div className={"card"}>
        <div className={"card-body"}>
          <div className={"d-flex justify-content-between"}>
            <h3>Input Penawaran Komersil</h3>
          </div>
          <hr />
          <Nav appearance={"subtle"} activeKey={selectedTab} onSelect={(e: any) => {
            setSelectedTab(e);
          }} style={{ marginBottom: 20 }}>
            {checkIsDuaTahap() && (
              <Nav.Item eventKey={"dua-tahap-penawaran-administrasi-teknis"}>
                Penawaran Komersil
              </Nav.Item>
            )}
          </Nav>

          {selectedTab === "dua-tahap-penawaran-administrasi-teknis" && (
            <QuotationVendorTab proposalTender={proposalTender} onRefresh={onRefresh} quotationStep={2} />
          )}

        </div>
      </div>
    </>
  );
};

export default InputPenawaranPage;