import { useAxiosGet } from "../../../../../hooks/useAxios";
import { getEnv } from "../../../../../helpers/procurex";
import { useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { ProposalTenderForm } from "./ProposalTenderForm";
import ButtonToolbar from "./components/ButtonToolbar";

const ProposalTenderVendorDetail = () => {

  const [{ doSubmit, isLoading, data }] = useAxiosGet(
    getEnv('tender')+'vendor/published'
  )

  const {uuid} = useParams();

  useEffect(()=>{
    if(uuid) getDetail()
  },[uuid])

  const getDetail = () => {
    // @ts-ignore
    doSubmit(
      {},
      getEnv('tender')+"/vendor/published/"+uuid
    ).then(r => {});
  }

  return (
    <>
      <ProposalTenderForm
        proposalTender={data}
        source={"Proposal Tender"}
        onReload={()=>getDetail()}
        ButtonToolbar={
          <ButtonToolbar proposalTender={data} />
        }
      />
    </>
  )
}

export default ProposalTenderVendorDetail;