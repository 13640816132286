import React, { useEffect, useState } from "react";
import { formatCurrency } from "../../../../../../../_metronic/helpers";
import TableXWrapper from "../../../../../../components/shared/TableXWrapper";
import { getEnv, parseNumberToCurrency, stringPadZero } from "../../../../../../../helpers/procurex";
import ButtonToolbar from "../ButtonToolbar";
import { useIntl } from "react-intl";
import { useAxiosGet } from "../../../../../../../hooks/useAxios";
import {Placeholder, Popover, Whisper} from 'rsuite'
import { Button } from "react-bootstrap";
import axios from "axios";
import { PageAlertX } from "../../../../../../components/shared/PageAlertX";
import { useProposalTender } from "../../core/_proposalTenderProvider";

const GetPRSpeakerText = ({pr, lineNo, speaker}:any)=>{
  const [selectedDetail, setSelectedDetail] = useState<any>({});
  const [{ doSubmit, isLoading: isLoadingGetDetail, isError, data, isSuccess }] = useAxiosGet(
    getEnv("tender")+`/purchase-requisition?filter[no]=${pr}`
  )

  useEffect(() => {
    doSubmit({},undefined,undefined).then((res:any)=>{
      res?.data?.filter((i:any)=>{
        axios
          .get(getEnv("tender")+`/purchase-requisition/${i?.uuid}`)
          .then((res) => {
            if (speaker === 'header') {
              setSelectedDetail(res?.data?.data)
            } else {
              // setSelectedDetail(res?.data?.data?.items[0]?.poTexts[0])
              setSelectedDetail(res?.data?.data?.items?.filter((i:any)=>i?.line_no === lineNo)[0]?.poTexts[0])
            }
          })
          .catch((error) => {
          })
          .finally(() => {
          })
      })
    })
  }, []);

  return (
    <>
      {isLoadingGetDetail && (<i className={"fa fa-spin fa-spinner"}></i>)}
      <p style={{whiteSpace: "pre-wrap"}}>
        {selectedDetail?.text || ''}
      </p>
    </>
  )
}

const ItemPRPage = () => {
  const {proposalTender} = useProposalTender();
  const [urlIndex, setUrlIndex] = useState("")
  const intl = useIntl()
  const [retenderReason, setRetenderReason] = useState<any>(null);
  const [{ doSubmit:doSubmitGet}] = useAxiosGet(
    getEnv("tender")
  );
  // `/tender/re-tender/list?filter[proposal_tender_id]=${proposalTender?.id}&filter[status]=PROCESS,DONE`

  const speakerPopover = (pr:any) => {
    return (
      <Popover title={`Header Text ( ${pr} )`}>
        <p>
          <span className={"text-muted"} style={{whiteSpace: "pre-wrap"}}>
            <GetPRSpeakerText pr={pr} lineNo={""} speaker={'header'}/>
          </span>
        </p>
      </Popover>
    )
  }

  const speakerPopoverItemText = (pr:any, line_no:any) => {
    return (
      <Popover title={`Material PO Text ( ${line_no} )`}>
        <p>
          <span className={"text-muted"} style={{whiteSpace: "pre-wrap"}}>
            <GetPRSpeakerText pr={pr} lineNo={line_no} speaker={'detail'}/>
          </span>
        </p>
      </Popover>
    )
  }

  const tableColumns = [
    { 
      Header: "Status", 
      accessor: "retender", 
      width: 200, 
      className: 'text-center',
      Cell: (props: any) => {
        let badgeColor: any
        if (props?.row?.original?.retender === "DONE") {
          badgeColor = 'badge badge-danger fw-bolder'
        } else if (props?.row?.original?.retender === "PROCESS") {
          badgeColor = 'badge badge-warning fw-bolder'
        } else {
          badgeColor = 'badge badge-success fw-bolder'
        }

        return (
          <>
            <div className='text-center'>
              <span className={`${badgeColor}`}>{props?.row?.original?.last_status_pr}</span>
            </div>
          </>
        )
      }
    },
    {
      Header: intl.formatMessage({id:'Header PR SAP'}),
      accessor: "no_pr",
      width: 300,
      Cell: (props: any) => {
        const data = props.cell.row.original
        return (
          <>
            <div>
              <b>Nomor PR: </b><i className="text-highlight">{data?.no_pr}</i>
            </div>
            <div
            dangerouslySetInnerHTML={{
              __html: data?.text?.replace(/\n/g, "<br />"),
            }}></div>
          </>
        )
      }
    },
    { Header: intl.formatMessage({id:"Tipe Dokumen"}) , accessor: "document_type", width: 100, className: "text-center" },
    { Header: intl.formatMessage({id:'Sumber'}), accessor: "source", width: 100, className: "text-center" },
    { Header: intl.formatMessage({id:'Status'}), accessor: "status", width: 150, className: "text-center" },
    {
      Header: intl.formatMessage({id:"Material PO Text"}),
      accessor: "line_no",
      width: 300,
      Cell: (props: any) => {
        const data = props.cell.row.original
        return (
          <>
            <div>
              <b>Item:</b> <i className="text-highlight">{data?.line_no}</i>
            </div>
            <div>
              <b>Short Text:</b> <i className="text-highlight">{data?.short_text}</i>
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: data?.poTexts?.replace(/\n/g, "<br />"),
              }}
            ></div>
          </>
        )
      }
    },
    { Header: "A", accessor: "account_assignment_category", width: 80, className: 'text-center' },
    {Header: 'I', accessor: 'purchaseRequisitionItem.item_category', width: 80, className: 'text-center'},
    {
      Header: intl.formatMessage({id:'Service Line'}),
      accessor: 'service_line.service_no',
      width: 150,
      className: 'text-center',
      Cell: (props: any) => {
        const data = props.cell.row.original
        return (
          <>
            {data.service_line?.map((v: any, i: number) => {
              return (
                <div key={'srv-line'+v?.uuid +'-'+i}>
                  {stringPadZero(v.line_no)} - {v.short_text}
                </div>
              )
            })}
          </>
        )
      }
    },
    {Header: intl.formatMessage({id:'Material No'}), accessor: 'material_id', width: 150, className: 'text-center'},
    {Header: intl.formatMessage({id:'Short Text'}), accessor: 'short_text', width: 250},
    {Header: intl.formatMessage({id:"Kuantitas"}), accessor: 'quantity', className: 'text-end', width: 200, Cell: (props: any) => {
      const data = props.cell.row.original
        return formatCurrency(data.quantity)
      }
    },
    {Header: intl.formatMessage({id:"Satuan"}), accessor: 'uom_id', width: 50, className: 'text-center'},
    {Header: intl.formatMessage({id:"Harga Satuan"}), accessor: 'valuation_price', className: 'text-end', width: 200, Cell: (props: any) => {
      const data = props.cell.row.original
        return formatCurrency(data.valuation_price)
      }
    },
    {Header: intl.formatMessage({id:"Mata Uang"}), accessor: 'currency_id', width: 150, className: 'text-center'},
    {Header: 'Per', accessor: 'per', className: 'text-end', width: 200, Cell: (props: any) => {
      const data = props.cell.row.original
        return formatCurrency(data.per)
      }
    },
    {Header: intl.formatMessage({id:'Total Harga'}), accessor: 'total_value', width: 200, className: 'text-end', Cell: (props: any) => {
      const data = props.cell.row.original
        return formatCurrency(data.total_value ?? '0')
      }
    },
    {Header: intl.formatMessage({id:'Delivery Date'}), accessor: 'delivery_date', width: 150, className: 'text-center'},
    {Header: intl.formatMessage({id:'Material Group'}), accessor: 'material_group_id', width: 150, className: 'text-center'},
    {Header: intl.formatMessage({id:'Plant'}), accessor: 'plant_id', width: 100, className: 'text-center'},
    {Header: intl.formatMessage({id:'Purchasing Group'}), accessor: 'purchasing_group_id', width:150, className: 'text-center'},
    {Header: intl.formatMessage({id:'Requisitioner'}), accessor: 'requisitioner', width: 150, className: 'text-center'},
    {Header: intl.formatMessage({id:'Track. No'}), accessor: 'tracking_number', width: 150, className: 'text-center'},
    {Header: intl.formatMessage({id:'No. Contract'}), accessor: 'agreement_id', width: 150, className: 'text-center'},
    {Header: intl.formatMessage({id:'Item Contract'}), accessor: 'agreement_item_id', width: 150, className: 'text-center'},
  ];

  useEffect(() => {
    if(proposalTender?.id){
      setUrlIndex(getEnv('tender') + `/tender-item?filter[proposal_tender_id]=${proposalTender?.id}`)
      doSubmitGet({},`${getEnv('tender')}/tender/re-tender/list?filter[proposal_tender_id]=${proposalTender?.id}&filter[status]=PROCESS,DONE`,undefined).then((res:any)=> {
        if (res?.data?.length > 0) {
          setRetenderReason(res?.data[0]?.cancel_reason)
        }
      })
    }
  }, [proposalTender])
  const isShowRetenderReason = () => {
    const showRetenderReason = [
      "Submit for Approval Retender"
    ]
    return showRetenderReason.includes(proposalTender.status_detail);
  }

  if(!urlIndex) {
    return <Placeholder.Grid rows={8} columns={12} />
  }

  return (
    <>
      {isShowRetenderReason() &&
        <PageAlertX title={intl.formatMessage({id:'Alasan Retender'})} variant={"warning"} html={
          <>
            <p className={"text-dark"}>{retenderReason}</p>
          </>
        } />
      }

      <div className={""}>
          <div className={"d-flex justify-content-between"}>
            <div><h3>{intl.formatMessage({id:'Item PR'})}</h3></div>
          </div>
          <hr />

        <div className={"row"}>
          <div className={"col-md-4 col-sm-12"}>
            <div className={"form-group"}>
              <label className={"form-label"}>{intl.formatMessage({id:'Total Nilai Tender'})}</label>
              <input type={"text"} className={"form-control form-control-sm"} disabled={true} value={parseNumberToCurrency(proposalTender?.total_nilai_tender,proposalTender?.items[0]?.currency_id)} />
            </div>
          </div>
        </div>
        
        <div className={"row"}>
          <div className={"col-md-4 col-sm-12"}>
            <div className={"form-group"}>
              <label className={"form-label"}>{intl.formatMessage({id:'Jumlah Item Tender'})}</label>
              <input type={"text"} className={"form-control form-control-sm"} disabled={true} 
                value={proposalTender?.items?.length} 
              />
            </div>
          </div>

        </div>

        <hr />
        <div className='row'>
          <TableXWrapper
            readonly={true}
            title={"Proposal Tender"}
            urlIndex={urlIndex}
            columns={tableColumns}
            isForceCreateButton={true}
            disableUrlChanged={true}
            disablePaddingCard={true}
          />
        </div>

      </div>
    </>
  );
};

export default ItemPRPage
