import { Outlet, Route, Routes } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../../_metronic/layout/core'
import React, { useState, useEffect } from 'react'
import Select2FormX from '../../../../components/shared/form/Select2FormX'
import DatepickerX2 from "../../../../components/shared/form/DatepickerX2";
import SelectX from '../../../../components/shared/form/SelectX'
import { useIntl } from "react-intl";
import { ApiUrl } from '../../../../config/Api'
import { getEnv } from "../../../../../helpers/procurex";
import TableXWrapper from '../../../../components/shared/TableXWrapper'
import axios, { AxiosResponse } from 'axios'
import { Field, Form, Formik } from 'formik'
import { toast } from 'react-toastify'
import {
    MonitoringAkurasiDataListStockview,
} from './components/MonitoringAkurasiDataListStockview'

const masterData = {
    title: 'Monitoring Akurasi',
    path: '/stockview/transaction/monitoring-akurasi-data',
    isSeparator: false,
    isActive: false,
}
const separator = {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
}
const breadcrumbs: Array<PageLink> = [masterData, separator]
const childBreadcrumbs: Array<PageLink> = [
    masterData,
    separator,
    {
        title: 'Stockview Monitoring Akurasi',
        path: '/stockview/transaction/monitoring-akurasi-data',
        isSeparator: false,
        isActive: false,
    },
    separator,
]

const MonitoringAkurasiDataStockview = () => {
    const intl = useIntl();
    const initialValues = {
        tanggal: '',
    }

    const [formindex, setFormIndex] = useState(0)
    const refFormik = React.useRef<any>();
    const [loading, setLoading] = useState(true)
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (values: any) => {
        setLoading(true);
        try {
            if (values.tanggal) {
                const date = new Date(values.tanggal);
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');

                values.tanggal = `${year}-${month}-${day}`;
            }

            const requests = [];

            requests.push(axios.post(`${getEnv("stockview")}/monitoring-akurasi-data`, values));

            const responses = await Promise.all(requests);

            responses.forEach(response => {
                if (response.status === 200 || response.status === 201) {
                    toast.success(response?.status + ': ' + response?.data.meta.message, {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'light',
                    });
                }
            });

        } catch (error) {
            console.error('Error processing records:', error);
            if (error.response) {
                // Server responded with a status other than 200 range
                toast.error(error.response.data?.meta?.message || 'Terjadi kesalahan', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                });
            } else if (error.request) {
                // Request was made but no response received
                console.error('Request error:', error.request);
                toast.error('Failed to get response from the server', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                });
            } else {
                // Something else caused an error
                console.error('General error:', error.message);
                toast.error('Terjadi kesalahan', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                });
            }
        } finally {
            setLoading(false);
        }
    };

    const getLastDayOfMonth = (date) => {
        const year = date.getFullYear();
        const month = date.getMonth();
        const nextMonth = new Date(year, month + 1, 1);
        return new Date(nextMonth.getTime() - (24 * 60 * 60 * 1000));
    };

    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    index
                    element={
                        <>
                            <PageTitle breadcrumbs={breadcrumbs}>Monitoring Akurasi Data Stockview</PageTitle>
                            <div className='card'>
                                <div className='card-body'>
                                    <p className="text-muted mb-3">Halaman ini bertujuan sebagai tools penarikan data untuk pengecekan akurasi.</p>
                                    <Formik
                                        key={formindex}
                                        innerRef={refFormik}
                                        initialValues={initialValues}
                                        onSubmit={handleSubmit}
                                        enableReinitialize
                                        validateOnChange={true}
                                    >
                                        {({ touched, values, setFieldValue }: any) => (
                                            <Form>
                                                <div className='card my-2'>
                                                    <div className='card-body'>
                                                        <div className='row'>
                                                            <div className='col-lg-6'>
                                                                <div className='mb-6'>
                                                                    <label htmlFor='' className='form-label required'>
                                                                        Bulan
                                                                    </label>
                                                                    <DatepickerX2
                                                                        onChange={(date: any) => {
                                                                            const selectedDate = new Date(date);
                                                                            const lastDayOfMonth = getLastDayOfMonth(selectedDate);
                                                                            setFieldValue("tanggal", lastDayOfMonth);
                                                                        }}
                                                                        size={"lg"}
                                                                        value={values.tanggal}
                                                                        format={"MM-yyyy"}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-6'>
                                                                <div className='mb-4 mt-9'>
                                                                    <button type="submit" className={"btn btn-primary btn-sm fw-bold"}>
                                                                        {isLoading && <i className={"fa fa-spinner fa-spin"}></i>}
                                                                        {!isLoading && <i className={"fa fa-arrow-down"}></i>}
                                                                        {intl.formatMessage({ id: "PULL" })}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                            <MonitoringAkurasiDataListStockview />
                        </>
                    }
                ></Route>
            </Route>
        </Routes>
    )
}

export default MonitoringAkurasiDataStockview
