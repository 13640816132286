import {KTCard, KTCardBody} from '../../../../../_metronic/helpers'
import React, { useEffect } from "react";
import TableXWrpper from "../../../../components/shared/TableXWrapper";
import { useNavigate } from "react-router-dom";
import { getEnv } from "../../../../../helpers/procurex";
import { StatusBadgeMonitoringTender, StatusBadgePenawaranVendor } from '../../admin/monitoring/components/MonitoringTenderList';
import { ButtonDetailApprovalOnTable } from "../../../../components/shared/ApprovalX";
import { useIntl } from "react-intl";

interface IProps {
  status: string;
}
const ProposalTenderList = ({status}:IProps) => {
  const urlIndex = getEnv('tender')+`/vendor/published`;
  const [tableIndex, setTableIndex] = React.useState(0);
  const navigate = useNavigate();
  const intl = useIntl();
  const tableColumns = [
    {
      Header: intl.formatMessage({id:'Aksi'}),
      accessor: 'action',
      width: 50,
      className: 'text-center',
      Cell: (props: any) => {
        const row = props.row.original;
        return (
          <>
            <a href={"#"} title={"Edit"} onClick={() => {
              navigate("/tender/vendor/tender/" + row.uuid);
            }}>
              <i className="fa fs-3 fa-eye"></i>
            </a>

            <ButtonDetailApprovalOnTable
              source={"TENDER"}
              company_id={row.company.id}
              object_id={row.id}
              code={row.routing_approval_config_code} />
          </>
        )
      }
    },
    {
      Header: intl.formatMessage({ id: "Status Tender" }),
      accessor: "status",
      width: 250,
      Cell: (props: any) => {
        return (
          <>
          <StatusBadgeMonitoringTender status={props.row.original.status} />
          </>
        )
      }
    },
    {
      Header: intl.formatMessage({id:'Status Penawaran Vendor'}), 
      accessor: 'status_vendor_quote', 
      width: 150,
      Cell: (props: any) => {
        return (
          <>
          <StatusBadgePenawaranVendor status={props.row.original.status_vendor_quote} />
          </>
        )
      } 
    },
    {Header: intl.formatMessage({id:'Perusahaan'}), accessor: 'company.name', width: 200, disableCreate: true, disableEdit: true, className: 'text-center'},
    {Header: intl.formatMessage({id:'No'}), accessor: 'no', width: 200, disableCreate: true, disableEdit: true, className: 'text-center'},
    {Header: intl.formatMessage({id:'Judul'}), accessor: 'title', width: 300},
    {Header: intl.formatMessage({id:'Referensi'}), accessor: 'reference', width: 200, className:'text-center'},
    {Header: intl.formatMessage({id:'Purchasing Group'}), accessor: 'purchasing_group_id', width: 150, className:'text-center'},
    {Header: intl.formatMessage({id:'Dibuat Pada'}), accessor: 'created_at', width: 150, className:'text-center'},
  ]
  useEffect(() => {
    setTableIndex(tableIndex + 1);
  }, []);
  return (
    <>
      <KTCard>
        <KTCardBody className='py-4'>
          <TableXWrpper
            key={tableIndex}
              readonly={true}
              title={"Proposal Tender"}
              urlIndex={urlIndex}
              columns={tableColumns}
              isForceCreateButton={true}
              defaultFilter={'sort=-updated_at'}
              hasAnySearch={true}
          />
        </KTCardBody>
      </KTCard>
    </>
  )
}

export {ProposalTenderList}
