import React, {ChangeEvent, useRef, useState} from 'react'
import {useField, FieldAttributes} from 'formik'
import {useUploadMedia} from '../../../../hooks/MediaUploadHook'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

interface FormikUploadFileProps extends FieldAttributes<any> {
  label: string
  not_required?: boolean
  mediaTypeId?: string
  disabled?: boolean
  accept?: string
  multiple?: boolean
  withTooltip?: boolean
  redactionTooltip?: string
}

const FormikUploadFile: React.FC<FormikUploadFileProps> = ({
  label,
  not_required,
  mediaTypeId,
  disabled,
  accept,
  multiple = false,
  withTooltip = false,
  redactionTooltip,
  ...props
}) => {
  const {upload} = useUploadMedia()
  const [field, meta, helpers] = useField(props.name as string)
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [tempUrl, setTempUrl] = useState<any>('')

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (! multiple) {
      const file = event.currentTarget.files?.[0]
      if (file) {
        // let responseFile = await upload(mediaTypeId, file)
        // helpers.setValue(responseFile.url)
        const objectURL = URL.createObjectURL(file)

        setTempUrl(objectURL)
        helpers.setValue(file)
      }
    } else {
      helpers.setValue(Array.from(event.currentTarget.files || []))
    }
  }

  const handleUploadButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  return (
    <div className='file-upload'>
      <div className='d-flex justify-content-between'>
        <label
          className={`form-label ${!not_required ? 'required' : ''}`}
          htmlFor={props.id || props.name}
        >
          {label}
          {withTooltip &&
            <OverlayTrigger
              placement='right'
              overlay={
                <Tooltip id='button-tooltip-2'>
                  {redactionTooltip}
                </Tooltip>
              }
            >
              <i className='bi bi-patch-question-fill ms-3'></i>
            </OverlayTrigger>
          }
        </label>
        {field.value && !multiple && (
          <div>
            <a
              href={typeof field.value === 'string' ? field.value : tempUrl}
              target='_blank'
              rel='noreferrer'
              className='text-sm'
            >
              Preview
            </a>
          </div>
        )}
      </div>
      <input
        type='file'
        onChange={handleFileChange}
        style={{display: 'none'}} // Sembunyikan input tipe 'file' yang sebenarnya
        ref={fileInputRef}
        accept={accept}
        multiple={multiple}
      />
      <div className='d-flex'>
        <input
          className='form-control'
          disabled
          value={typeof field.value === 'string' ? field.value : (Array.isArray(field.value) ? field.value.map(value => value?.name).join(', ') : field.value?.name || '')}
        />
        {!disabled && (
          <button
            disabled={disabled}
            className='ms-1 btn btn-light-primary btn-sm'
            type='button'
            onClick={handleUploadButtonClick}
          >
            <i className='fas fa fa-upload'></i>
          </button>
        )}
      </div>
      <div className='d-flex'>
        <div className='text-danger'>
          {meta.touched && meta.error ? <div className='error'>{meta.error}</div> : null}
        </div>
      </div>
    </div>
  )
}

export default FormikUploadFile
