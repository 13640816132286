/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from "react";
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {Languages} from './Languages'
import {toAbsoluteUrl} from '../../../helpers'
import { darkModeClass } from "../../../../helpers/utils";
import { StatusOnline } from "../../../layout/components/header/StatusOnline";

const HeaderUserMenu: FC = () => {
  const {currentUser, logout} = useAuth()
  const [environtment, setEnvirontment] = useState('')
  useEffect(() => {
    if(process.env.REACT_APP_API_URL === 'https://procurement-qa.pupuk-indonesia.com'){
      setEnvirontment('QA')
    } else if(process.env.REACT_APP_API_URL === 'https://procurement-dev.pupuk-indonesia.com'){
      setEnvirontment('DEV')
    }
  }, []);
  return (
    <>
      <div className={"d-flex flex-row"}>
        <a
          href="#"
          data-kt-menu-trigger="hover"
          data-kt-menu-placement="bottom-start"
          data-kt-menu-overflow="false"
          className={`fs-6 fw-bold ${darkModeClass()} d-flex flex-row`}
          style={{
            marginRight: environtment ? "80px" : "0"
          }}
        >
          <i className={`fa fa-user-circle fs-2 ${darkModeClass()} me-2`}></i>


          Hi, {(currentUser?.name || "")}

          {/*Hi, a*/}
        </a>
        <div
          className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
          data-kt-menu="true"
          data-popper-placement="bottom-start"
        >
          <div className="menu-item px-3">
            <div className="menu-content d-flex align-items-center px-3">
              <div className="symbol symbol-50px me-5">
                <img alt="Logo" src={toAbsoluteUrl("/media/icons/user.png")} />
              </div>

              <div className="d-flex flex-column">
                <div className="fw-bolder d-flex align-items-center fs-5">{currentUser?.name}</div>
                <a href="#" className={`fw-bold fs-7 ${darkModeClass()}`}>
                  {currentUser?.username}
                </a>
              </div>
            </div>
          </div>

          <div className='separator my-2'></div>

          <div className='menu-item px-5 my-1'>
            <Link to='/account/settings' className='menu-link px-5'>
              Account Settings
            </Link>
          </div>

          <div className='menu-item px-5'>
            <a onClick={logout} className='menu-link px-5'>
              Sign Out
            </a>
          </div>
        </div>
      </div>
    </>

  )
}

export { HeaderUserMenu }
