import React from "react";
import { useNavigate } from "react-router-dom";
import { getEnv } from "../../../../../../helpers/procurex";
import { Modal } from "react-bootstrap";
import { Nav, Placeholder } from "rsuite";
import useRemoteFileAsObjectUrl from "../../../../../../hooks/useRemoteFileAsObjectUrl";
import ModalEvaluasi from "./ModalEvaluasi";
import { useIntl } from "react-intl";

interface IProps {
  proposalTender?: any;
  isLoading?: any;
  onCancel?: any;
  onDraft?: any;
  onLanjutPQ?: any;
  onSubmit?: any;
  onPublishPQ?: any;
  onApprove?: any;
}

const ButtonToolbar = ({
                         proposalTender,
                         isLoading,
                         onCancel,
                         onDraft,
                         onLanjutPQ,
                         onSubmit,
                         onPublishPQ,
                         onApprove
                       }: IProps) => {

  const navigation = useNavigate();
  const [showModal, setShowModal] = React.useState(false);
  const [showModalSPPH, setShowModalSPPH] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState("");
  const [menuTab, setMenuTab] = React.useState([] as any);
  const [selectedItem, setSelectedItem] = React.useState<any>(null);
  const { objectUrl, isLoading: isLoadingObjectUrl, setSourceUrl } = useRemoteFileAsObjectUrl(selectedItem);
  const intl = useIntl();
  const isShowInformation = () => {
      return true;
  }

  const isShowVendorResult = () => {
    const ProposalTenderStatusVendorNotPassed = ['REGISTRATION-QUATATION'];
    const ProposalTenderStatusAfterNego = ['Negotiation','Laporan Pengadaan','Pengumuman Pemenang','Penunjukan Pemenang','Purchase Order','Closed'];

    if(ProposalTenderStatusAfterNego.includes(proposalTender?.status)){
      return true;
    }
    return false;
  }

  const initiateTab = () => {
    const tmp = [
      { eventKey: "indo", label: intl.formatMessage({id:"Indonesia"}) },
      { eventKey: "eng", label: intl.formatMessage({id:"English"}) },
    ]
    let tmpSelectedTab = "";
    tmp.forEach((item:any) => {
      if(!item?.disabled && tmpSelectedTab === ""){
        tmpSelectedTab = item.eventKey;
        setSelectedTab(item.eventKey);
        return false;
      }
    })
    setMenuTab(tmp);
  }

  React.useEffect(() => {
    if(proposalTender){
      initiateTab();
    }
  }, [proposalTender]);

  React.useEffect(() => {
    if(selectedTab === 'indo'){
      setSelectedItem(getEnv('tender')+`/evaluation/quotation/generate-surat-permintaan-penawaran-harga/${proposalTender?.uuid}/${proposalTender?.tender_vendor_uuid}`)
    } else {
      setSelectedItem(getEnv('tender')+`/evaluation/quotation/generate-surat-permintaan-penawaran-harga/${proposalTender?.uuid}/${proposalTender?.tender_vendor_uuid}/en`)
    }
  }, [selectedTab]);

  const BadgeHasilEvaluasi = ({title, status, onClick}:any) => {
    if(status === "PASSED"){
      return (
        <a href={'javascript:;'} onClick={(e) => {
          e.preventDefault()
          setShowModal(true)
        }} className={`badge badge-primary d-flex justify-content-end`}>
          <i className={'fa fa-check-circle me-2 text-white'}></i>
          {intl.formatMessage({id: title})}: {status}
        </a>
      )
    } else if(status === "FAILED"){
      return (
        <a href={'javascript:;'} onClick={(e) => {
          e.preventDefault()
          setShowModal(true)
        }} className={`badge badge-danger text-white d-flex justify-content-end`}>
          <i className={'fa fa-check-circle me-2 text-white'}></i>
          {intl.formatMessage({id: title})}: {status}
        </a>
      )
    }  else {
      return (
        <a href={'javascript:;'} onClick={(e) => {
          e.preventDefault()
          setShowModal(true)
        }} className={`badge badge-warning text-white d-flex justify-content-end`}>
          <i className={'fa fa-info-circle me-2 text-white'}></i>
          {intl.formatMessage({id: title})}: {status}
        </a>
      )
    }
  }

  return (
    <>
      <div className={'d-flex flex-row gap-2 justify-content-end'}>
        <button className={'btn btn-sm btn-danger me-2'} onClick={() => setShowModalSPPH(true)}>
          <i className={'fa fa-file-pdf'}></i>
          Download SPPH
        </button>
        <Modal show={showModalSPPH} size={'lg'} onHide={() => {
          setShowModalSPPH(false)
        }}>
          <Modal.Header closeButton={true}>
          <Modal.Title>Preview SPPH</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Nav appearance={"subtle"} activeKey={selectedTab} onSelect={(e:any)=>{setSelectedTab(e)}} style={{ marginBottom: 20 }}>
              {menuTab.map((item:any, index:number) => {
                return (
                  <Nav.Item eventKey={item.eventKey} key={index} disabled={item?.disabled}>
                    {item.label}
                  </Nav.Item>
                );
              })}
            </Nav>
            {(isLoadingObjectUrl)
              ? <Placeholder.Graph height={700} active={isLoadingObjectUrl} />
              : selectedItem && objectUrl
                ? <>
                  <object width="100%" style={{
                    height: "700px"
                  }} data={objectUrl} type="application/pdf">
                    {" "}
                  </object>
                </>
                : <div className={"bg-secondary w-100"} style={{ "height": 700, "opacity": "0.6" }}></div>
            }
          </Modal.Body>
        </Modal>
        {/*{isShowInformation() && (*/}
        {/*  <button className={"btn btn-sm btn-primary me-2"} disabled={true}>*/}
        {/*    <i className={"fa fa-info-circle"}></i>*/}
        {/*    {proposalTender?.vendor_status}*/}
        {/*  </button>*/}
        {/*)}*/}

        {isShowVendorResult() && (
          <>
            <div className={"d-flex flex-column gap-1"}>
              <BadgeHasilEvaluasi title={"Evaluasi Teknis"} status={proposalTender?.status_evaluation_technical} onClick={() => setShowModal(true)} />
              <BadgeHasilEvaluasi title={"Evaluasi Komersil"} status={proposalTender?.status_evaluation_commercial} onClick={() => setShowModal(true)} />

            </div>

            <ModalEvaluasi
              proposalTender={proposalTender}
              show={showModal}
              onClose={() => setShowModal(false)}
            />
          </>
        )}

      </div>
    </>
  );
};

export default ButtonToolbar;