import React, { useEffect, useState } from "react";
import { PageAlertX } from "../../../../../components/shared/PageAlertX";
import axios from "axios";
import { getEnv } from "../../../../../../helpers/procurex";
import { useIntl } from "react-intl";


const FlagRejected = ({proposalTender, type}:any) => {
  const [flag, setFlag] = useState<any>("");
  const [flagProperties, setFlagProperties] = useState<any>({} as any);
  const intl = useIntl();
  const getFlag = () => {
    axios.get(
      getEnv("tender") + `/log?perPage=25&page=1&sort=-id&filter[subject_id]=${proposalTender?.id}&filter[log_name]=${type}&filter[description]=rejected`
    ).then((res:any)=>{
      if(res?.data?.data.length > 0){
        setFlag(res?.data?.data[res?.data?.data.length - 1]?.description);
        setFlagProperties(res?.data?.data[res?.data?.data.length - 1]?.properties)
      }
    })
  }

  useEffect(() => {
    if(proposalTender && proposalTender?.status_detail === "Rejected") getFlag()
  }, [proposalTender]);

  return (
    <>
      {flag && (
        <PageAlertX title={"Rejected"} variant={"danger"} html={
          <>
            {flag}:
            <p>{flagProperties?.comment}</p>
          </>
        } />
      )}

    </>
  )
}

export default FlagRejected;