import { Spinner } from "react-bootstrap";
import { formatNumberToDecimalAuction, getEnv, multiplyNumber, parseNumberToCurrency } from "../../../../../helpers/procurex";
import { useEffect, useState } from "react";
import { useAxiosPost } from "../../../../../hooks/useAxios";
import { toast } from "react-toastify";
import CurrencyInput from "react-currency-input-field";
import { useParams } from "react-router";
import axios from "axios";
import { Placeholder } from "rsuite";
import Swal from "sweetalert2";


const BeritaAcaraAuctionVendorTab = ({ auction, tabulasi }: any) => {
  const { uuid } = useParams();
  const [isLoadingBeritaAcara, setIsLoadingBeritaAcara] = useState(false);
  const [urlPdf, setUrlPdf] = useState("");
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosPost(
    getEnv("auction") + `/as-vendor/approve-berita-acara`
  );
  const getBeritaAcara = () => {
    setIsLoadingBeritaAcara(true);
    const newUrl = getEnv("auction") + `/as-vendor/auction/generate-berita-acara/${auction?.id}`;
    axios.get(newUrl, {
      responseType: "blob"
    })
      .then((response) => {
        setIsLoadingBeritaAcara(false);
        const url = URL.createObjectURL(response.data);
        setUrlPdf(url);
      })
      .catch((error) => {
        setIsLoadingBeritaAcara(false);
      })

  };

  const onApproveConfirmation = () => {
    Swal.fire({
      title: "Apakah Anda Yakin?",
      text: "Anda setuju dengan berita acara ini?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, Setuju",
      cancelButtonText: "Tidak"
    }).then(async (result) => {
      if (result.isConfirmed) {
        const newUrl = getEnv("auction") + `/as-vendor/approve-berita-acara/${auction?.id}/1`;
        doSubmit({ _method: 'PUT' }, newUrl, toast).then((res: any) => {
          getBeritaAcara();
        }).catch((err: any) => {
          console.log(err);
        })
      }
    })
  }

  const onRejectConfirmation = () => {
    Swal.fire({
      title: "Apakah Anda Yakin?",
      text: "Anda tolak berita acara ini?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, Tolak",
      cancelButtonText: "Tidak"
    }).then(async (result) => {
      if (result.isConfirmed) {
        const newUrl = getEnv("auction") + `/as-vendor/approve-berita-acara/${auction?.id}/0`;
        doSubmit({ _method: 'PUT' }, newUrl, toast).then((res: any) => {
        }).catch((err: any) => {
          console.log(err);
        })
      }
    })
  }

  useEffect(() => {
    getBeritaAcara();
  }, []);

  return (
    <>
      <div className={'row'} style={{ marginBottom: 20 }}>
        <div className={'col-md-12 text-end'}>
          {(tabulasi?.vendor_approve_berita_acara == null && tabulasi?.status == 'done' && !tabulasi?.is_banned) &&
            <>
                
              <button disabled={isLoading} onClick={() => onApproveConfirmation()} className="btn btn-sm btn-success me-3">
                {isLoading && <Spinner animation={"border"} size={"sm"} className={"me-2"}></Spinner>}
                {!isLoading && <i className={"fa fa-check"}></i>}
                Approve
              </button>
              <button disabled={isLoading} onClick={() => onRejectConfirmation()} className='btn btn-sm btn-redblood'>
                {isLoading && <Spinner animation={"border"} size={"sm"} className={"me-2"}></Spinner>}
                {!isLoading && <i className={"fa fa-times"}></i>}
                Reject
              </button>
            </>
          }
        </div>
      </div>
      <div className={'row'}>
        <div className={'col-md-12'}>
          {(isLoadingBeritaAcara)
            ? <Placeholder.Graph height={700} active={isLoadingBeritaAcara} />
            : urlPdf
              ? <>
                <object width="100%" style={{
                  height: "700px"
                }} data={urlPdf} type="application/pdf">
                  {" "}
                </object>
              </>
              : <div className={"bg-secondary w-100"} style={{ "height": 700, "opacity": "0.6" }}></div>
          }
        </div>
      </div>
    </>
  );
};

export { BeritaAcaraAuctionVendorTab };
