import { BeritaAcaraPengumumanPemenangTab } from "./BeritaAcaraPengumumanPemenangTab";

const PengumumanPemenangPage = ({ proposalTender, onRefresh }: any) => {
  return (
    <>
      <div className={"card"}>
        <div className={"card-body"}>
          <div className={"d-flex justify-content-between"}>
            <div><h3>Awarding</h3></div>
          </div>
          <hr />
          <BeritaAcaraPengumumanPemenangTab
            proposalTender={proposalTender}
            onRefresh={onRefresh}
          />
        </div>
      </div>
    </>
  );
};

export default PengumumanPemenangPage;