/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, NavLink} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login, setUpPassword} from '../core/_requests'
import {useAuth} from '../core/Auth'
import {useIntl} from 'react-intl'
import {setAuth} from '../core/AuthHelpers'
import {toast} from 'react-toastify'
import axios from 'axios'
import {getEnv} from '../../../../helpers/procurex'

const loginSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .min(6, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password confirmation is required'),
})

const initialValues = {
  password: '',
  password_confirmation: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function CreatePassword() {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const [isShowPassword, setIsShowPassword] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const urlSearchParams = new URLSearchParams(window.location.search)
  const showSidebar = async () => {
    // hide sidebar
    const elementToClick = document.getElementById('kt_aside_toggle')

    if (elementToClick && elementToClick.classList.contains('active')) {
      // Memicu klik pada elemen
      elementToClick.click()
    }
  }
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const uuid = urlSearchParams.get('uuid')

        if (!uuid) {
          // Reset Password
          await axios
            .post(getEnv('sso') + '/reset-password', {
              password: values.password,
              password_confirmation: values.password_confirmation,
              email: urlSearchParams.get('email'),
              token: urlSearchParams.get('token'),
            })
            .then((response) => {
              setIsSuccess(true)
            })
        } else {
          // Create Password for new Vendor
          const res = await setUpPassword(
            uuid,
            urlSearchParams.get('token'),
            values.password,
            values.password_confirmation
          )
          if (res.status === 200 || res.status === 201) {
            setIsSuccess(true)
          }
        }
      } catch (error: any) {
        toast.error(error.response?.data?.meta?.message || 'Something wrong')
      } finally {
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      <div className='text-center mb-10'>
        <img src='https://www.pupuk-indonesia.com/assets/img/logo.png' alt='' />
      </div>
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Reset Password</h1>
      </div>
      {/* begin::Heading */}
      {!isSuccess && (
        <>
          {formik.status ? (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          ) : null}

          {/* begin::form group */}
          <div className='fv-row mb-8'>
            <label className='form-label fs-6 fw-bolder text-dark'>New Password</label>
            <div className={'input-group'}>
              <input
                placeholder='Password'
                type={isShowPassword ? 'text' : 'password'}
                autoComplete='off'
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.password && formik.errors.password,
                  },
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              <div className='input-group-append'>
                <button
                  type={'button'}
                  className={
                    'btn btn-default border border-secondary rounded-0 rounded-end input-group-text'
                  }
                  onClick={() => setIsShowPassword(!isShowPassword)}
                >
                  <i className={'fa fa-eye'}></i>
                </button>
              </div>
            </div>
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-8'>
            <label className='form-label fs-6 fw-bolder text-dark'>New Password Confirmation</label>
            <div className={'input-group'}>
              <input
                placeholder='Password'
                type={isShowPassword ? 'text' : 'password'}
                autoComplete='off'
                {...formik.getFieldProps('password_confirmation')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid':
                      formik.touched.password_confirmation && formik.errors.password_confirmation,
                  },
                  {
                    'is-valid':
                      formik.touched.password_confirmation && !formik.errors.password_confirmation,
                  }
                )}
              />
              <div className='input-group-append'>
                <button
                  type={'button'}
                  className={
                    'btn btn-default border border-secondary rounded-0 rounded-end input-group-text'
                  }
                  onClick={() => setIsShowPassword(!isShowPassword)}
                >
                  <i className={'fa fa-eye'}></i>
                </button>
              </div>
            </div>
            {formik.touched.password_confirmation && formik.errors.password_confirmation && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password_confirmation}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::form group */}

          {/* begin::Wrapper */}
          <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
            <div />

            {/* begin::Link */}
            <Link to='/auth' className='link-primary'>
              Return to login
            </Link>
            {/* end::Link */}
          </div>
          {/* end::Wrapper */}

          {/* begin::Action */}
          <div className='d-grid mb-10'>
            <button
              type='submit'
              id='kt_sign_in_submit'
              className='btn btn-primary'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Submit</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Tunggu sebentar...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Action */}
        </>
      )}
      {isSuccess && (
        <div className='text-center mb-10'>
          <img src='/media/auth/ok.png' alt='' className={'w-50'} />
          <h4>Create New Password Successfully.</h4>

          <Link to='/auth' className='link-primary'>
            Return to login
          </Link>
        </div>
      )}

      {/* <div className="d-grid mb-10">
        <NavLink to="/auth/registration" className="btn btn-outline btn-outline-primary">
          {intl.formatMessage({ id: "HOMEPAGE.LOGIN_REGISTER" })}
        </NavLink>
      </div> */}

      {/* <div className='text-gray-500 text-center fw-semibold fs-6'>
        Daftar jadi vendor?{' '}
        <Link to='/auth/registration' className='link-primary'>
          Daftar
        </Link>
      </div> */}
    </form>
  )
}
