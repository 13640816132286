import React, {FC, useEffect, useState} from 'react'
import Form from 'react-bootstrap/Form';

type Props = {
    type: string,
    size: string,
    placeholder: string,
    model: string,
    onChange: any,
    value?: any,
    focus?: boolean,
    readonly?: boolean
}
const InputFormX: FC<Props> = (props) => {
    const {type,size,model,placeholder,onChange,value,focus,readonly} = props;
    const [_value,_setValue] = useState(value)

    useEffect(()=>{
        props.onChange(model,_value)
    },[_value])
    return (
        <>
            <Form.Control
                autoFocus={focus?true:false}
                type={type}
                size={"sm"}
                placeholder={placeholder}
                onChange={(e)=>_setValue(e.currentTarget.value)}
                value={_value ?? ''}
                readOnly={readonly}
                disabled={readonly}
            >

            </Form.Control>
        </>
    )
}

export default InputFormX;