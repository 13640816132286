import { getEnv, parseNumberToCurrency, parseNumberToFloat } from "../../../../../../helpers/procurex";
import { useEffect, useState } from "react";
import FormikSelect2 from "../../../../../components/shared/form/FormikSelect2";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useAxiosGet } from "../../../../../../hooks/useAxios";
import { Spinner } from "react-bootstrap";


const RowRiwayat = ({ item, index }: any) => {
  return (
    <>
      <thead>
        <tr>
          <th className={"border text-center px-2"} style={{ width: "50px" }}>No</th>
          <th className={"border text-start px-2"}>Item Name</th>
          <th className={"border text-start px-2"}>Currency</th>
          <th className={"border text-start px-2"}>Quantity</th>
          <th className={"border text-start px-2"}>Unit Price</th>
          <th className={"border text-start px-2"}>Total Price</th>
          <th className={"border text-start px-2"}>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr key={"row-riwayat" + index}>
          <td className={"border text-center px-2"}>{index + 1}</td>
          <td className={"border text-start px-2"}>{item.nama}</td>
          <td className={"border text-start px-2"}>{item.currency}</td>
          <td className={"border text-end px-2"}>{item.quantity}</td>
          <td className={"border text-end px-2"}>{parseNumberToCurrency(item?.unit_price, item.currency)}</td>
          <td className={"border text-end px-2"}>{parseNumberToCurrency(item?.total_price, item.currency)}</td>
          <td className={"border text-end px-2"}></td>
        </tr>
      </tbody>
      <thead>
        <tr>
          <th className={"border text-start px-2"} colSpan={2}>Date</th>
          <th className={"border text-start px-2"}>Vendor</th>
          <th className={"border text-start px-2"}>Quantity</th>
          <th className={"border text-start px-2"}>Unit Price</th>
          <th className={"border text-start px-2"}>Total Price</th>
          <th className={"border text-start px-2"}></th>
        </tr>
      </thead>
      <tbody>
        {item?.details?.map((history: any, index2: number) => {
          return (
            <tr key={"tfoot-riwayat-" + index + '-' + index2}>
              <td className={"border text-center px-2"} colSpan={2}>{history.created_at}</td>
              <td className={"border text-start px-2"}>{history.peserta.vendor.name}</td>
              <td className={"border text-end px-2"}>{item.quantity}</td>
              <td className={"border text-end px-2"}>{parseNumberToCurrency(history.unit_price, item.currency)}</td>
              <td className={"border text-end px-2"}>{parseNumberToCurrency(history.total_price, item.currency)}</td>
              <td className={"border text-start px-2"}>{history.status}</td>
            </tr>
          );
        })}
      </tbody>
    </>
  );
};

const RowRiwayatMvb = ({ item, index }: any) => {
  return (
    <>
      <thead>
        <tr>
          <th className={"border text-center px-2"} style={{ width: "50px" }}>No</th>
          <th className={"border text-start px-2"}>Item Name</th>
          <th className={"border text-start px-2"}>Currency</th>
          <th className={"border text-start px-2"}>Quantity</th>
          <th className={"border text-start px-2"}>Unit Price</th>
          <th className={"border text-start px-2"}>Total Price</th>
          <th className={"border text-start px-2"}>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr key={"row-riwayat" + index}>
          <td className={"border text-center px-2"}>{index + 1}</td>
          <td className={"border text-start px-2"}>{item.nama}</td>
          <td className={"border text-start px-2"}>{item.currency}</td>
          <td className={"border text-end px-2"}>{item.quantity}</td>
          <td className={"border text-end px-2"}>{parseNumberToCurrency(item?.unit_price, item.currency)}</td>
          <td className={"border text-end px-2"}>{parseNumberToCurrency(item?.total_price, item.currency)}</td>
          <td className={"border text-end px-2"}></td>
        </tr>
      </tbody>
      <thead>
        <tr>
          <th className={"border text-start px-2"} colSpan={3}>Date</th>
          <th className={"border text-start px-2"}>Vendor</th>
          <th className={"border text-start px-2"}>Variable</th>
          <th className={"border text-start px-2"}>Value</th>
          <th className={"border text-start px-2"}></th>
        </tr>
      </thead>
      <tbody>
        {item?.details?.map((history: any, index2: number) => {
          return (
            <tr key={"tfoot-riwayat-" + index + '-' + index2}>
              <td className={"border text-center px-2"} colSpan={3}>{history.created_at}</td>
              <td className={"border text-start px-2"}>{history.peserta.vendor.name}</td>
              <td className={"border text-start px-2"}>{history.param_setting_mvb_variable?.detail_template?.variable_name}</td>
              <td className={"border text-end px-2"}>{parseNumberToFloat(history.unit_price, 2)}</td>
              <td className={"border text-start px-2"}>{history.status}</td>
            </tr>
          );
        })}
      </tbody>
    </>
  );
};

const RowRiwayatAuction = ({ item, index }: any) => {
  return (
    <>
      <tr key={"row-riwayat-auction" + index}>
        <td className={"border text-center px-2"}>{index + 1}</td>
        <td className={"border text-start px-2"}>{item.description}</td>
        <td className={"border text-start px-2"}>{item.created_at_format}</td>
        <td className={"border text-start px-2"}>{item.username}</td>
      </tr>
    </>
  );
};

const RiwayatAuctionTab = ({ title, tabulasi }: any) => {
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosGet(
    getEnv("auction") + "/history-bid/" + tabulasi?.auction_uuid
  );
  const validationSchema = Yup.object({});

  const [historyBid, setHistoryBid] = useState([]);
  const [historyBidOld, setHistoryBidOld] = useState([]);
  const [historyAuction, setHistoryAuction] = useState([]);
  const [historyAuctionOld, setHistoryAuctionOld] = useState([]);

  const onSubmit = async (values: any) => {

  }

  const changeVendor = async (values: any) => {
    let filterDataHistoryBid = JSON.parse(JSON.stringify(historyBidOld));
    if (values != undefined) {
      filterDataHistoryBid?.forEach((v: any) => v.details = v.details.filter((p: any) => p.peserta.id == values.id))
    }
    setHistoryBid(filterDataHistoryBid);

    let filterDataHistoryAuction = JSON.parse(JSON.stringify(historyAuctionOld));
    if (values != undefined) {
      filterDataHistoryAuction = filterDataHistoryAuction?.filter((p: any) => p.peserta_id == values.id);
    }
    setHistoryAuction(filterDataHistoryAuction);
  }

  const getHistory = async () => {
    let url = getEnv("auction") + "/history-bid/" + tabulasi?.auction_uuid;
    doSubmit(undefined, url, undefined).then((res: any) => {
      setHistoryBid(res.data);
      setHistoryBidOld(res.data);
    });
  };

  const getAuctionHistory = async () => {
    let url = getEnv("auction") + "/auction/history/" + tabulasi?.auction_uuid;
    doSubmit(undefined, url, undefined).then((res: any) => {
      setHistoryAuction(res.data);
      setHistoryAuctionOld(res.data);
    });
  };

  useEffect(() => {
    getHistory();
    getAuctionHistory();
  }, []);
  return (
    <>
      <button
        type={"button"}
        onClick={() => {
          getHistory();
        }}
        className='btn mb-10 btn-sm btn-light-info mx-1 fw-bold'>
        {isLoading && <Spinner animation={"border"} size={"sm"} className={"me-2"}></Spinner>}
        {!isLoading && <i className={"fa fa-refresh"}></i>}
        Refresh</button>
      {historyBidOld?.length > 0 &&
        <>
          <Formik initialValues={{}} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ errors, touched, values, setFieldValue }: any) => (
              <>
                <Form>
                  <div className={"form-group mb-4"}>
                    <FormikSelect2
                      name="vendor"
                      label="Filter Vendor"
                      optionsUrl={`${getEnv('auction')}/peserta/?auction_id=${tabulasi?.id}`}
                      unique_property={"id"}
                      getOptionValue={(opt: any) => opt}
                      getOptionLabel={(opt: any) => opt?.vendor?.no + " - " + opt?.vendor?.name}
                      search_property={"vendor->name"}
                      onChangeValue={(e: any) => {
                        changeVendor(e);
                      }}
                      required
                    />
                  </div>
                </Form>
              </>
            )}
          </Formik>
          <h3>{title}</h3>
          <div className={"table-responsive"}>
            <table className={"table table-header-gray"}>
              {tabulasi?.template_id == null && historyBid?.map((item: any, index: number) => {
                return (
                  <RowRiwayat key={'row-riwayat-' + index} item={item} index={index} />
                );
              })}
              {tabulasi?.template_id != null && historyBid?.map((item: any, index: number) => {
                return (
                  <RowRiwayatMvb key={'row-riwayat-' + index} item={item} index={index} />
                );
              })}
            </table>
          </div>
        </>
      }
      <h3>Riwayat Auction</h3>
      <div className={"table-responsive"}>
        <table className={"table table-header-gray"}>
          <thead>
            <tr>
              <th className={"border text-center px-2"} style={{ width: "50px" }}>No</th>
              <th className={"border text-start px-2"}>Deskripsi</th>
              <th className={"border text-start px-2"}>Tanggal</th>
              <th className={"border text-start px-2"}>Username</th>
            </tr>
          </thead>
          <tbody>
            {historyAuction?.map((item: any, index: number) => {
              return (
                <RowRiwayatAuction key={'row-riwayat-auction-' + index} item={item} index={index} />
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export { RiwayatAuctionTab };
