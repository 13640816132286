import {additionNumber, getEnv, parseNumberToCurrency} from '../../../../../../../../helpers/procurex'
import React, {useEffect, useState} from 'react'
import TableXWrapper from '../../../../../../../components/shared/TableXWrapper'
import {useAxiosGet, useAxiosPost} from '../../../../../../../../hooks/useAxios'
import {toast} from 'react-toastify'
import PreviewAttachment from '../../../../../../../components/PreviewAttachment'
import {Placeholder} from 'rsuite'
import {useIntl} from 'react-intl'
import axios from 'axios'
import EvaluasiAdministrasi from '../../EvaluasiAdministrasi'
import EvaluasiTeknis from '../../EvaluasiTeknis'
import EvaluasiKomersil from '../../EvaluasiKomersil'

const StatusBadgePassedFailed = ({ status }: any) => {
  return (
    <span
      className={`badge ${
        status === "PASSED"
          ? "bg-success"
          : status === "NOT-EVALUATE"
            ? "bg-warning"
            : "bg-danger"
      } text-white`}
    >
      {status}
    </span>
  );
};



const InformasiRekanan = ({ quotation, proposalTender }: any) => {
  const intl = useIntl();
  const [expiredAt, setExpiredAt] = useState<any>("");
  const [bidBondAttachment, setBidBondAttachment] = useState(null);

  const getExpiredAt = () => {
    const date = new Date(quotation?.date);
    date.setDate(date.getDate() + quotation?.validity);
    const expiredAt = date.toISOString().slice(0,10);
    setExpiredAt(expiredAt)
  }

  useEffect(() => {
    getExpiredAt();
  }, [quotation]);

  return (
    <>
      <div className={"row"}>
        <div className={"col-sm-12 col-md-6"}>
          <div className={"form-group mb-3"}>
            <label htmlFor="">{intl.formatMessage({ id: "Nama Rekanan" })}</label>
            <input type="text" className={"form-control form-control-sm"} value={quotation?.vendor?.vendor?.name}
                   disabled={true} />
          </div>
          <div className={"form-group mb-3"}>
            <label htmlFor="">{intl.formatMessage({ id: "Nomor Penawaran" })}</label>
            <input type="text" className={"form-control form-control-sm"} value={quotation?.quotation_number}
                   disabled={true} />
          </div>
          <div className={"form-group mb-3"}>
            <label htmlFor="">{intl.formatMessage({ id: "Tanggal Quotation" })}</label>
            <input type="text" className={"form-control form-control-sm"} value={quotation?.date} disabled={true} />
          </div>
          <div className={"form-group mb-3"}>
            <label htmlFor="">{intl.formatMessage({ id: "Berlaku Hingga Tanggal" })}</label>
            <input type="text" className={"form-control form-control-sm"} value={expiredAt}
                   disabled={true} />
          </div>
          <div className={"form-group mb-3"}>
            <label htmlFor="">{intl.formatMessage({ id: "Masa Berlaku Penawaran" })}</label>
            <div className={"input-group"}>
              <input type="text" className={"form-control form-control-sm"} value={quotation?.validity}
                     disabled={true} />
              <div className="input-group-append">
                <span className="input-group-text">{intl.formatMessage({ id: "Hari" })}</span>
              </div>
            </div>
          </div>
        </div>
        <div className={"col-sm-12 col-md-6"}>
          <div className={"row mb-3"}>
            <div className={"col-md-4"}>
              <div className={"form-group mb-3"}>
                <label htmlFor="">{intl.formatMessage({ id: "Incoterm" })}</label>
                <input type="text" className={"form-control form-control-sm"} value={quotation?.incoterm?.code}
                       disabled={true} />
              </div>
            </div>
            <div className={"col-md-8"}>
              <div className={"form-group mb-3"}>
                <label htmlFor="">{intl.formatMessage({ id: "Deskripsi" })}</label>
                <input type="text" className={"form-control form-control-sm"} value={quotation?.incoterm?.name}
                       disabled={true} />
              </div>
            </div>
          </div>
          <div className={"form-group mb-3"}>
            <label htmlFor="">{intl.formatMessage({ id: "Jaminan Penawaran (Bid Bond)" })}</label>
            <div className={"input-group"}>
              <input type="text" className={"form-control  form-control-sm"}
                     value={parseNumberToCurrency(quotation?.bid_bond_value)} disabled={true} />
              <div className="input-group-append">
                <button
                  className={"btn btn-primary btn-sm"}
                  type={"button"}
                  onClick={()=>{
                    setBidBondAttachment(quotation?.bid_bond_media_url)
                  }}
                >
                  <i className={"fa fa-eye"}></i>
                </button>
              </div>
              <PreviewAttachment previewExt={bidBondAttachment} onHide={()=>{setBidBondAttachment(null)}} />
            </div>

          </div>
          <div className={"form-group mb-3"}>
            <label htmlFor="">{intl.formatMessage({ id: "Masa Berlaku Jaminan Penawaran (Bid Bond)" })}</label>
            <input type="text" className={"form-control form-control-sm"} value={quotation?.bid_bond_masa_berlaku}
                   disabled={true} />
          </div>
          <div className={"form-group mb-3"}>
            <label htmlFor="">{intl.formatMessage({ id: "Additional Cost" })}</label>
            <input type="text" className={"form-control form-control-sm"} value={""} disabled={true} />
          </div>
        </div>
      </div>
    </>
  );
};

const HasilAkhirEvaluasi = ({ formIndex, proposalTender, quotation }: any) => {
  const [dataItems, setDataItems] = useState([]);
  const [currentProposalTender, setCurrentProposalTender] = useState(null);
  const [selectedVendor, setSelectedVendor] = useState(proposalTender);
  const [statusEvaluation, setStatusEvaluation] = useState("NOT-EVALUATE");
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosGet(
    getEnv("tender") + `/evaluation/item/${proposalTender?.uuid}/${quotation?.vendor?.uuid}`
  );
  const [summary, setSummary] = useState(null);
  const [nilaiTeknis, setNilaiTeknis] = useState(0);
  const [nilaiKomersil, setNilaiKomersil] = useState(0);
  const [nilaiAmbangBatas, setNilaiAmbangBatas] = useState(0);
  const [nilaiAkhir, setNilaiAkhir] = useState(0);
  const [{ doSubmit: doSubmitSave, isLoading: isLoadingSave }] = useAxiosPost(
    getEnv("tender") + `/evaluation/administration/syarat`
  );
  const intl = useIntl();
  const getItems = () => {
    doSubmit({}, undefined, undefined, () => {
    }).then((res: any) => {
      const _items = res.data;
      setDataItems(_items);
    }).catch((err: any) => {
    });
  };

  const getSelectedVendor = () => {
    // @ts-ignore
    axios
      .get(getEnv("tender")+ `/dur-vendor?filter[proposal_tender_id]=${proposalTender?.id}&filter[vendor_id]=${quotation?.vendor_id}`)
      .then((res) => {
        const _selectedVendor = res.data.data?.find((vendor: any) => {
          return (vendor?.vendor_id).toString() === (quotation?.vendor_id).toString();
        });
        setStatusEvaluation(_selectedVendor?.status_evaluation);
        setSelectedVendor(_selectedVendor?.vendor);
        setNilaiAkhir(_selectedVendor?.score);
      })
  };

  const getNilaiTeknisKomersil = () => {
    // @ts-ignore
    const configuration = currentProposalTender?.syarats?.find((syarat: any) => syarat?.type === "configuration");

    if (proposalTender?.order_placement === "Paket" && proposalTender?.metode_evaluasi === "Sistem Nilai") {
      getSummaryEvaluation();
    } else {
      if (configuration) {
        // @ts-ignore
        setNilaiTeknis(configuration?.bobot_teknis);
        setNilaiKomersil(configuration?.bobot_komersial);
      }
    }
    // @ts-ignore
    setNilaiAmbangBatas(configuration?.ambang_batas);
  };

  /**
   * paket, ambil dari proposalTender?.vendors?.evaluation_technical
   */
  const checkIsPaketSistemGugur = () => {
    return proposalTender?.order_placement === "Paket" &&
      proposalTender?.metode_evaluasi === "Sistem Gugur";
  };

  const checkIsPaketSistemNilai = () => {
    return proposalTender?.order_placement === "Paket" &&
      proposalTender?.metode_evaluasi === "Sistem Nilai";
  };

  const checkIsItemizeSistemGugur = () => {
    return proposalTender?.order_placement === "Itemize" &&
      proposalTender?.metode_evaluasi === "Sistem Gugur";
  };

  const checkIsItemizeSistemNilai = () => {
    return proposalTender?.order_placement === "Itemize" &&
      proposalTender?.metode_evaluasi === "Sistem Nilai";
  };

  const checkIsPaket = () => {
    return proposalTender?.order_placement === "Paket";
  };

  const checkIsItemize = () => {
    return proposalTender?.order_placement === "Itemize";
  };

  const getProposalTender = () => {
    const newUrl = getEnv("tender") + `/tender/${proposalTender?.uuid}`;
    doSubmit({}, newUrl, undefined, () => {
    }).then((res: any) => {
      const _proposalTender = res.data;
      setCurrentProposalTender(_proposalTender);
    }).catch((err: any) => {
      toast.error("Get Evaluasi Teknis: " + err?.response?.data?.meta?.message);
    });
  };

  const getSummaryEvaluation = () => {
    const newUrl = getEnv("tender") + `/evaluation/summary/${proposalTender?.uuid}`;
    doSubmit({}, newUrl, undefined, () => {
    }).then((res: any) => {
      const data = res.data?.filter((item: any) => item?.vendor_id === quotation?.vendor_id)[0];
      setNilaiTeknis(data?.technical_value);
      setNilaiKomersil(data?.commercial_value);
      setNilaiAkhir(additionNumber(data?.technical_value, data?.commercial_value));
    }).catch((err: any) => {
      toast.error("Get Evaluasi Teknis: " + err?.response?.data?.meta?.message);
    });
  };


  useEffect(() => {
    if (currentProposalTender) {
      getItems();
      getSelectedVendor();
      getNilaiTeknisKomersil();
    }
  }, [currentProposalTender]);

  useEffect(() => {
    getProposalTender();
    // setCurrentProposalTender(proposalTender)
    // console.log(quotation)
  }, []);

  const handleSaveEvaluateVendor = () => {
    const evaluateVendorURL = getEnv("tender") + `/evaluation/vendor/${proposalTender?.uuid}/${quotation?.vendor?.uuid}`;
    doSubmitSave({}, evaluateVendorURL, toast, () => {}).then((res: any) => {
    }).catch((err: any) => {});
  }

  const getConfigurationNilaiTeknis = () => {
    const configuration = proposalTender?.syarats?.filter((v: any) => v.type === "configuration");
    if (configuration.length > 0) {
      return configuration[0].bobot_teknis;
    }
    return null;
  };

  const getConfiguratioNilaiKomersil = () => {
    const configuration = proposalTender?.syarats?.filter((v: any) => v.type === "configuration");
    if (configuration.length > 0) {
      return configuration[0].bobot_komersial;
    }
    return null;
  };

  const getConfigurationAmbangBatas = () => {
    const configuration = proposalTender?.syarats?.filter((v: any) => v.type === "configuration");
    if (configuration.length > 0) {
      return configuration[0].ambang_batas;
    }
    return null;
  };

  return (
    <>
      <h3>{intl.formatMessage({ id: "Hasil Akhir Evaluasi" })}</h3>
      {isLoading && (
        <Placeholder.Grid rows={5} columns={5} active={true} />
      )}
      {!isLoading && (
        <div className={"table-responsive"}>
          <table className={"table table-bordered table-header-gray"}>
            <thead>
            <tr>
              <th className={"text-center border px-2"} style={{
                verticalAlign: "top", position: "sticky", minWidth: "20px", left: 0, zIndex: 1
              }}>No
              </th>
              <th className={"text-start border"} style={{
                verticalAlign: "top", position: "sticky", minWidth: "200px", left: 30, zIndex: 1
              }}>Nama Rekanan
              </th>
              <th className={"text-start border"} style={{
                minWidth: "300px"
              }}>Deskripsi
              </th>
              {(checkIsPaketSistemGugur() || checkIsItemizeSistemGugur()) && (
                <th className={"text-center border"} colSpan={2}>Kesimpulan</th>
              )}
              {checkIsPaketSistemNilai() && (
                <>
                  <th className={"text-center border"}>
                    Nilai Teknis, Proporsi Bobot
                  </th>
                  <th className={"text-center border"}>
                    Nilai Komersil, Proporsi Bobot
                  </th>
                  <th className={"text-center border"}>
                    Nilai Total
                  </th>
                  <th className={"text-center border"}>
                    Ambang Batas Teknis
                  </th>

                  <th className={"text-center border px-2"}>
                    Kesimpulan
                  </th>
                </>
              )}
              {checkIsItemizeSistemNilai() && (
                <>
                  <th className={"text-center border"}>
                    Nilai Teknis, Proporsi Bobot
                  </th>
                  <th className={"text-center border"}>
                    Nilai Komersil, Proporsi Bobot
                  </th>

                  <th className={"text-center border"}>
                    Nilai Total
                  </th>
                  <th className={"text-center border"}>
                    Kesimpulan
                  </th>
                </>
              )}
            </tr>
            </thead>
            <tbody>
            {dataItems.map((item: any, index: number) => {
              return (
                <tr key={"hasil-akhir-evaluasi" + index}>
                  {index === 0 && (
                    <td className={"border text-center px-2"} style={{
                      verticalAlign: "top",
                      position: "sticky",
                      minWidth: "20px",
                      left: 0,
                      zIndex: 1,
                      backgroundColor: "#fff"
                    }} rowSpan={dataItems?.length}>
                      1
                    </td>
                  )}
                  {index === 0 && (
                    <td className={"border px-2"} style={{
                      verticalAlign: "top",
                      position: "sticky",
                      minWidth: "200px",
                      left: 30,
                      zIndex: 1,
                      backgroundColor: "#fff"
                    }} rowSpan={dataItems?.length}>
                      {/* @ts-ignore */}

                      {/*{(checkIsPaketSistemGugur() || checkIsPaketSistemNilai()) ? selectedVendor?.name:'n'}*/}
                      {selectedVendor?.name}
                    </td>
                  )}
                  <td className={"border px-2"}>{item?.short_text}</td>
                  {(checkIsPaketSistemGugur() && index === 0) && (
                    <>
                      <td className={"border text-center"} rowSpan={dataItems?.length}>
                        <input type={"checkbox"} className={"form-check-input"} disabled={true}
                               checked={statusEvaluation === "PASSED"} /> Lolos
                      </td>
                      <td className={"border text-center"} rowSpan={dataItems?.length}>
                        <input type={"checkbox"} className={"form-check-input"} disabled={true}
                               checked={statusEvaluation === "FAILED"} /> Tidak Lolos
                      </td>
                    </>
                  )}

                  {(checkIsPaketSistemNilai() && index === 0) && (
                    <>
                      <td className={"border text-center px-2"} rowSpan={dataItems?.length}>
                        {nilaiTeknis} / {getConfigurationNilaiTeknis()}
                      </td>
                      <td className={"border text-center px-2"} rowSpan={dataItems?.length}>
                        {nilaiKomersil} / {getConfiguratioNilaiKomersil()}
                      </td>
                      <td className={"border text-center px-2"} rowSpan={dataItems?.length}>
                        {nilaiAkhir}
                      </td>
                      <td className={"border text-center px-2"} rowSpan={dataItems?.length}>
                        {nilaiAmbangBatas}
                      </td>
                      <td className={"border text-center px-2 text-nowrap"} rowSpan={dataItems?.length}>
                        <StatusBadgePassedFailed status={statusEvaluation} />
                      </td>
                    </>
                  )}

                  {(checkIsItemizeSistemGugur()) && (
                    <>
                      <td className={"border"}>
                        <input type={"checkbox"} className={"form-check-input"}
                               checked={item?.status === "PASSED"} /> Lolos
                      </td>
                      <td className={"border"}>
                        <input type={"checkbox"} className={"form-check-input"}
                               checked={item?.status !== "PASSED"} /> Tidak Lolos
                      </td>
                    </>
                  )}

                  {(checkIsItemizeSistemNilai()) && (
                    <>
                      <td className={"border text-center"}>
                        {item?.score_technical}
                      </td>
                      <td className={"border text-center"}>
                        {item?.score_commercial}
                      </td>
                      <td className={"border text-center"}>
                        <strong>{(Number(item?.score_technical) + Number(item?.score_commercial)).toFixed(2)}</strong>
                      </td>
                      <td className={"border text-center"}>
                        <StatusBadgePassedFailed status={item?.status} />
                      </td>
                    </>
                  )}

                </tr>
              );
            })}
            </tbody>
          </table>

        </div>
      )}

    </>
  );
};

const DetailPenawaran = ({ proposalTender, quotation, onBack }: any) => {
  const [formIndex, setFormIndex] = useState(0);
  const [currentQuotation, setCurrentQuotation] = useState(null as any);
  const [isDisabled, setIsDisabled] = useState(false);
  const intl = useIntl();
  const [{ doSubmit, isLoading }] = useAxiosGet(
    getEnv("tender") + `/evaluation/quotation-detail/${proposalTender?.uuid}/${quotation?.vendor?.uuid}`
  );
  const [{doSubmit: doSubmitPost, isLoading: isLoadingPost}] =  useAxiosPost("");
  const [evaluationData, setEvaluationData] = useState(null as any);
  const handleOnChangeEvaluasiKomersil = () => {
    setFormIndex(formIndex + 1);
  };

  const getQuotation = () => {
    // console.log(quotation)
    doSubmit({}, undefined, undefined).then((res: any) => {
      setCurrentQuotation(res?.data[0]);
    });
  };

  const onChangeEvaluationData = async (field: string, value: any) => {
    setEvaluationData({ ...evaluationData, [field]: value });
  };

  const checkIsDisabled =  () => {
    const allowedStatus = ["Evaluasi Administrasi", "Evaluasi Teknikal", "Evaluasi Komersil"];
    setIsDisabled(!allowedStatus.includes(proposalTender?.status));
  };

  const handleOnSaveEvaluation = async () => {

    // region save evaluation administrasi
    if (evaluationData?.evaluation_administration) {
      // region note
      await doSubmitPost({
        note: evaluationData?.evaluation_administration?.note || "-",
        type: "Evaluasi Administrasi"
      }, getEnv("tender") + `/evaluation/notes/${quotation?.vendor?.uuid}`, undefined);
      // endregion

      //region attachment
      evaluationData?.evaluation_administration?.attachments?.forEach(async (attachment: any) => {
        if(attachment.current_id) return;
        const payload = {
          description: attachment?.media_type?.file_name,
          type: "Evaluasi Administrasi",
          media_type: attachment?.media_type,
          media_url: attachment?.media_url
        }
        await doSubmitPost(payload, getEnv("tender") + `/evaluation/attachment/${quotation?.vendor?.uuid}`, undefined);
      });
      //endregion
    }
    // endregion

    //region save evaluation teknis
    if (evaluationData?.evaluation_technical) {
      await doSubmitPost({
        note: evaluationData?.evaluation_technical?.note || "-",
        type: "Evaluasi Teknikal"
      }, getEnv("tender") + `/evaluation/notes/${quotation?.vendor?.uuid}`, undefined);
      //region attachment
      evaluationData?.evaluation_technical?.attachments?.forEach(async (attachment: any) => {
        if(attachment.current_id) return;
        const payload = {
          description: attachment?.media_type?.file_name,
          type: "Evaluasi Teknikal",
          media_type: attachment?.media_type,
          media_url: attachment?.media_url
        }
        await doSubmitPost(payload, getEnv("tender") + `/evaluation/attachment/${quotation?.vendor?.uuid}`, undefined);
      });
      //endregion
    }
    //endregion

    //region save evaluation komersil
    if (evaluationData?.evaluation_commercial) {
      await doSubmitPost({
        note: evaluationData?.evaluation_commercial?.note || "-",
        type: "Evaluasi Komersil"
      }, getEnv("tender") + `/evaluation/notes/${quotation?.vendor?.uuid}`, undefined);
      //region attachment
      evaluationData?.evaluation_commercial?.attachments?.forEach(async (attachment: any) => {
        if(attachment.current_id) return;
        const payload = {
          description: attachment?.media_type?.file_name,
          type: "Evaluasi Komersil",
          media_type: attachment?.media_type,
          media_url: attachment?.media_url
        }
        await doSubmitPost(payload, getEnv("tender") + `/evaluation/attachment/${quotation?.vendor?.uuid}`, undefined);
      });
      //endregion
    }
    //endregion

    //region delete attachment
    if (evaluationData?.evaluation_administration_deleted) {
      evaluationData?.evaluation_administration_deleted?.forEach(async (attachment: any) => {
        await doSubmitPost({
          _method: "DELETE"
        }, getEnv("tender") + `/evaluation/attachment/${quotation?.vendor?.uuid}/${attachment}`, undefined);
      });
    }
    if (evaluationData?.evaluation_technical_deleted) {
      evaluationData?.evaluation_technical_deleted?.forEach(async (attachment: any) => {
        await doSubmitPost({
          _method: "DELETE"
        }, getEnv("tender") + `/evaluation/attachment/${quotation?.vendor?.uuid}/${attachment}`, undefined);
      });
    }
    if (evaluationData?.evaluation_commercial_deleted) {
      evaluationData?.evaluation_commercial_deleted?.forEach(async (attachment: any) => {
        await doSubmitPost({
          _method: "DELETE"
        }, getEnv("tender") + `/evaluation/attachment/${quotation?.vendor?.uuid}/${attachment}`, undefined).then((res: any) => {});
      });
    }
    //endregion

    toast.success(intl.formatMessage({ id: "Berhasil menyimpan data" }));
  };

  useEffect(() => {
    // console.log(quotation?.vendor?.uuid)
    getQuotation();
    checkIsDisabled();
  }, []);


  return (
    <>
      <div className={"row"}>
        <div className={"col-md-6 mb-5"}>
          <button className={"btn btn-sm btn-danger"} type={"button"} onClick={() => onBack()}>
            <i className={"fa fa-arrow-left"}></i> {intl.formatMessage({ id: "Kembali ke list" })}
          </button>
        </div>
        <div className={"col-md-6"}>
        </div>
        <div className={"col-md-12"}>

          {isLoading && (
            <>
              <Placeholder.Graph active={true} height={700} />
            </>
          )}
          {(currentQuotation && !isLoading) && (
            <>
              <InformasiRekanan proposalTender={proposalTender} quotation={currentQuotation} />
              <hr />
              <EvaluasiAdministrasi
                proposalTender={proposalTender}
                quotation={currentQuotation}
                onChange={() => handleOnChangeEvaluasiKomersil()}
                onChangeEvaluationData={onChangeEvaluationData}
              />
              <EvaluasiTeknis
                proposalTender={proposalTender}
                quotation={currentQuotation}
                onChange={() => handleOnChangeEvaluasiKomersil()}
                onChangeEvaluationData={onChangeEvaluationData}
              />

              <hr />
              <EvaluasiKomersil
                proposalTender={proposalTender}
                quotation={currentQuotation}
                onChange={() => handleOnChangeEvaluasiKomersil()}
                onChangeEvaluationData={onChangeEvaluationData}
              />

              <hr />
              <HasilAkhirEvaluasi key={formIndex} proposalTender={proposalTender} quotation={currentQuotation} />

            </>
          )}
        </div>
      </div>
    </>
  );
};

const AdministrasiTeknisSatuSampulTab = ({ proposalTender, onQuotationChanged, onChangeEvaluationData }: any) => {
  const urlIndex = getEnv("tender") + `/evaluation/quotation/${proposalTender.uuid}`;
  const intl = useIntl();
  const [selectedRows, setSelectedRows] = useState(null);
  const tableColumns = [
    {
      Header: "#",
      accessor: "line_no",
      width: 100,
      className: "text-center",
      Cell: (props: any) => {
        return (
          <>
            <a href={"#"} title={"Lihat Penawaran"} onClick={(e: any) => {
              e.preventDefault();
              setSelectedRows(props.row.original);
            }} className={"btn btn-primary btn-sm"}>
              <i className={"fa fa-eye"}></i>
            </a>
          </>
        );
      }
    },
    {
      Header: intl.formatMessage({ id: "ID Vendor" }),
      accessor: "vendor_id",
      width: 100,
      className: "text-start",
      Cell: (props: any) => {
        return (
          <>
            {props.row.original.vendor_id}
          </>
        );
      }
    },
    {
      Header: intl.formatMessage({ id: "Nama Vendor" }),
      accessor: "vendor.vendor.name",
      width: 300,
      className: "text-start"
    },
    {
      Header: intl.formatMessage({ id: "Administrasi" }),
      accessor: "vendor.status_evaluation_administration",
      width: 150,
      className: "text-center",
      Cell: (props: any) => {
        return (
          <>
            {!props.row.original.vendor.status_evaluation_administration && (
              <span className={"badge badge-warning"}>NOT-EVALUATE</span>
            )}
            {props.row.original.vendor.status_evaluation && (
              <StatusBadgePassedFailed status={props.row.original.vendor.status_evaluation_administration} />
            )}
          </>
        );
      }
    },
    {
      Header: intl.formatMessage({ id: "Teknis" }),
      accessor: "vendor.status_evaluation_technical",
      width: 150,
      className: "text-center",
      Cell: (props: any) => {
        return (
          <>
            {!props.row.original.vendor.status_evaluation_technical && (
              <span className={"badge badge-warning"}>NOT-EVALUATE</span>
            )}
            {props.row.original.vendor.status_evaluation_technical && (
              <StatusBadgePassedFailed status={props.row.original.vendor.status_evaluation_technical} />
            )}
          </>
        );
      }
    },
    {
      Header: intl.formatMessage({ id: "Komersil" }),
      accessor: "vendor.status_evaluation_commercial",
      width: 150,
      className: "text-center",
      Cell: (props: any) => {
        return (
          <>
            {!props.row.original.vendor.status_evaluation_commercial && (
              <span className={"badge badge-warning"}>NOT-EVALUATE</span>
            )}
            {props.row.original.vendor.status_evaluation_commercial && (
              <StatusBadgePassedFailed status={props.row.original.vendor.status_evaluation_commercial} />
            )}
          </>
        );
      }
    },
    {
      Header: intl.formatMessage({ id: "Status Akhir" }),
      accessor: "vendor.status_evaluation",
      width: 150,
      className: "text-center",
      Cell: (props: any) => {
        return (
          <>
            {!props.row.original.vendor.status_evaluation && (
              <span className={"badge badge-warning"}>NOT-EVALUATE</span>
            )}
            {props.row.original.vendor.status_evaluation && (
              <StatusBadgePassedFailed status={props.row.original.vendor.status_evaluation} />
            )}
          </>
        );
      }
    },
    {
      Header: intl.formatMessage({ id: "Tanggal Quotation" }),
      accessor: "updated_at",
      className: "text-center",
      width: 200
    }

  ];

  useEffect(() => {
    // onQuotationChanged(selectedRows)
  }, [selectedRows]);

  return (
    <>
      {selectedRows && (
        <DetailPenawaran
          proposalTender={proposalTender}
          quotation={selectedRows}
          onChangeEvaluationData={onChangeEvaluationData}
          onBack={() => {
            setSelectedRows(null);
          }}
        />
      )}
      {!selectedRows && (
        <TableXWrapper
          readonly={true}
          title={"Proposal Tender"}
          urlIndex={urlIndex}
          columns={tableColumns}
          isForceCreateButton={true}
          disableUrlChanged={true}
        />
      )}

    </>
  );
};

export {
  AdministrasiTeknisSatuSampulTab,
};