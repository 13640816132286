import {Nav, Placeholder} from 'rsuite'
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import axios from 'axios'
import {useMutation, useQuery} from 'react-query'
import GambaranUmumTab from './components/GambaranUmumTab'
import ProsesTab from './components/ProsesTab'
import SummaryTab from './components/SummaryTab'
import {useNavigate, useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {formatDateTime, getEnv} from '../../../../../helpers/procurex'
import {Modal, Spinner} from 'react-bootstrap'
import {StatusBadgeAanwijzing} from '../../admin/aanwijzing/AanwijzingList'
import {BeritaAcaraAanwijzing} from './components/BeritaAcaraAanwijzing'

const fetchAaanwijzingProposalTender = (proposalTenderId: any) => {
  return axios.get(`${getEnv('tender')}/aanwijzing?filter[proposal_tender_id]=${proposalTenderId}`)
}

const fetchCreateAaanwijzingProposalTender = (proposalTender: any) => {
  return axios.post(`${getEnv('tender')}/aanwijzing`, {
    proposal_tender_id: proposalTender.id,
    source: 'PROCUREX',
    reference: proposalTender.no,
  })
}

export const isAlreadyGiveFeedback = (_aanwijzing: any) => {
  const allowedStatusAanwijzing = [
    'CLOSED',
    'DONE',
  ]
  if (!allowedStatusAanwijzing?.includes(_aanwijzing?.status)) return true

  const allowedStatus = [
    'APPROVE',
    'REJECT',
  ]
  return allowedStatus?.includes(_aanwijzing?.status_vendor_approve_ba)
}

const AanwijzingVendorDetail = ({aanwijzingUuid, isShowBackButton=true}: any) => {
  const [selectedTab, setSelectedTab] = useState('gambaran-umum')
  const [aanwijzing, setAanwijzing] = useState({} as any)
  const intl = useIntl()
  const [isPaktaIntegritasShow, setIsPaktaIntegritasShow] = useState(false)
  const navigate = useNavigate()
  const [menuTab, setMenuTab] = useState([])

  const isTabEnabled = () => {
    const allowedStatus = [
      'LIVE',
      'CLOSED',
      'DONE',
    ]
    return allowedStatus.includes(aanwijzing?.status)
  }

  const isSummaryEnabled = () => {
    const allowedStatus = [
      'CLOSED',
      'DONE',
    ]
    return allowedStatus.includes(aanwijzing?.status)
  }

  const isBAEnabled = () => {
    const allowedStatus = [
      'CLOSED',
      'DONE',
    ]
    return allowedStatus.includes(aanwijzing?.status)
  }

  const generateTab = () => {
    return [
      {
        eventKey: 'gambaran-umum',
        disable: false,
        label: intl.formatMessage({id: 'Gambaran Umum'}),
      },
      {
        eventKey: 'proses',
        disable: !isTabEnabled(),
        label: intl.formatMessage({id: 'Proses'}),
      },
      {
        eventKey: 'summary',
        disable: !isSummaryEnabled(),
        label: intl.formatMessage({id: 'Summary'}),
      },
      {
        eventKey: 'memo',
        disable: !isBAEnabled(),
        label: intl.formatMessage({id: 'Berita Acara'}),
      },
    ]
  }


  //region get aanwijzing
  const {uuid} = useParams()
  const {isLoading: isLoadingGet, refetch: refetchAanwijzing} = useQuery('get-aanwijzing-from-uuid', {
    queryFn: () => axios.get(`${getEnv('tender')}/vendor/aanwijzing/${aanwijzingUuid}`),
    onSuccess: (res) => {
      setAanwijzing(res.data.data)
    },
    onError: (error) => {
      console.log(error)
    },
  })
  //endregion

  // @ts-ignore
  //endregion

  const showTab = () => {
    if (selectedTab === 'gambaran-umum') {
      return <GambaranUmumTab
        aanwijzing={aanwijzing}
        onSubmitForApproval={() => fetchAanwijzing()}
      />
    } else if (selectedTab === 'proses') {
      return <ProsesTab
        aanwijzing={aanwijzing}
      />
    } else if (selectedTab === 'summary') {
      return <SummaryTab
        aanwijzing={aanwijzing}
        onRefresh={() => {
          fetchAanwijzing()
        }}
      />
    } else if (selectedTab === 'memo') {
      return <BeritaAcaraAanwijzing
        aanwijzing={aanwijzing}
      />
    }
  }

  const fetchAanwijzing = () => {
    refetchAanwijzing()
  }

  const isShowSubmitForApproval = () => {
    return aanwijzing?.status === 'OPEN' ||
      aanwijzing?.status === 'REJECTED'
    // checkStatus()
  }

  const isOnApproval = () => {
    return aanwijzing?.status === 'SUBMITTED'
  }


  //region register aanwijzing
  const {mutate: mutateRegistration, isLoading: isLoadingRegistration} = useMutation({
    mutationKey: ['aanwijzing', 'registration', aanwijzing?.uuid],
    mutationFn: ({
                   uuid,
                   payload,
                 }: any) => axios.post(getEnv('tender') + `/vendor/aanwijzing/invitation/${uuid}`, payload),
    onSuccess: (res) => {
      toast.success('Successfully register aanwijzing')
      setIsPaktaIntegritasShow(false)
    },
    onError: (err: any) => {
      // @ts-ignore
      toast.error(err)
    },
  })
  const handleOnRegistration = () => {
    const payload = {
      uuid: aanwijzing?.uuid,
      payload: {
        response: true,
      },
    }
    mutateRegistration(payload)
  }
  //endregion

  useEffect(() => {
    fetchAanwijzing()
  }, [])

  useEffect(() => {
    setMenuTab(generateTab)

    if (aanwijzing?.status === 'LIVE' && aanwijzing?.status_vendor === 'WAITING') {
      setIsPaktaIntegritasShow(true)
    }

    if (aanwijzing?.status_vendor === 'REJECT_QUOTATION' || aanwijzing?.status_vendor === 'REJECT_INVITATION') {
      navigate('/tender/vendor/aanwijzing')
    }

  }, [aanwijzing])

  if (isLoadingGet || !aanwijzing) {
    return <div className={'card card-body'}>
      <Placeholder.Paragraph rows={20} active />
    </div>
  }

  return (
    <>
      <div className={'d-flex justify-content-between mb-3 gap-2'}>
        <div className={'d-flex flex-column gap-2'}>
          <h2 className={'py-0 my-0'}>{aanwijzing?.title || '[UNTITLED]'}</h2>
          <div>
            <span className={'me-2'}>No: <strong>{aanwijzing?.reference}</strong></span>
            <span>| <strong>({aanwijzing?.company?.comp_code} - {aanwijzing?.company?.name})</strong></span>

          </div>
          <div>
            <span className={'badge badge-primary'}>#{aanwijzing?.source}</span>
          </div>
          <div className={'col-md-12 d-flex gap-3'}>
            {isShowBackButton && (
              <button className={'btn btn-danger btn-sm'} type={'button'} onClick={() => {
                navigate('/tender/vendor/aanwijzing')
              }}>
                <i className={'fa fa-arrow-left'}></i> Kembali Ke List
              </button>
            )}
          </div>
        </div>

        <div className={'d-flex flex-column gap-2 text-end'}>
          <div>
            <StatusBadgeAanwijzing status={aanwijzing?.status} isHideStatusDescription={true} />
          </div>
          <div>
            <strong>{formatDateTime(aanwijzing?.start_at)} - {formatDateTime(aanwijzing?.end_at)}</strong>
          </div>


        </div>
      </div>
      <div className={'card card-body'}>
        <Nav appearance={'subtle'} activeKey={selectedTab} onSelect={(e: any) => {
          setSelectedTab(e)
        }} style={{marginBottom: 20}} className={''}>
          {menuTab.map((item: any, index: number) => {
            return (
              <Nav.Item eventKey={item.eventKey} key={index} disabled={!aanwijzing || item?.disable}>
                {item.label}
              </Nav.Item>
            )
          })}
        </Nav>


        {showTab()}


      </div>

      <Modal
        show={isPaktaIntegritasShow}
        size={'lg'}
        onBackdropClick={() => {
        }}
      >
        <Modal.Header>
          <h2>Pakta Integritas</h2>
        </Modal.Header>
        <Modal.Body>
          <p>
            <table>
              <tbody>
              <tr>
                <th>{intl?.formatMessage({id: 'Judul Aanwijzing'})}</th>
                <td className={'px-2'}>:</td>
                <td>{aanwijzing?.title}</td>
              </tr>
              <tr>
                <th>{intl?.formatMessage({id: 'Nomor Referensi'})}</th>
                <td className={'px-2'}>:</td>
                <td>{aanwijzing?.reference}</td>
              </tr>
              <tr>
                <th>{intl?.formatMessage({id: 'Jadwal'})}</th>
                <td className={'px-2'}>:</td>
                <td>{(aanwijzing?.start_at)} - {aanwijzing?.end_at}</td>
              </tr>
              <tr>
                <th>{intl?.formatMessage({id: 'Pernyataan Integritas'})}</th>
                <td className={'px-2'}>:</td>
                <td></td>
              </tr>
              </tbody>
            </table>
          </p>
          <p>
            Kami yang bertandatangan di bawah ini, dengan ini menyatakan dan berjanji untuk menjalankan seluruh proses
            pengadaan barang dan jasa dengan penuh integritas dan transparansi. Kami berkomitmen untuk tidak melakukan,
            atau mencoba melakukan, tindakan yang melanggar hukum, etika, atau aturan yang berlaku dalam pelaksanaan
            pengadaan ini. Kami juga berjanji untuk menghindari segala bentuk suap, gratifikasi, atau bentuk lain dari
            korupsi yang dapat merugikan semua pihak yang terlibat.
          </p>

          <p>
            Kami berjanji untuk menjaga kerahasiaan seluruh informasi yang diperoleh selama proses pengadaan. Informasi
            tersebut tidak akan kami gunakan untuk tujuan yang tidak sah atau merugikan pihak lain. Kami juga
            berkomitmen untuk tidak menyebarkan informasi yang tidak benar atau menyesatkan yang dapat mempengaruhi
            integritas dan hasil dari proses pengadaan ini.
          </p>

          <p>
            Kami menyatakan kesediaan untuk bekerja sama sepenuhnya dengan pihak yang berwenang dalam hal pengawasan dan
            audit atas pelaksanaan pengadaan ini. Jika terbukti melanggar pakta integritas ini, kami bersedia menerima
            sanksi sesuai dengan ketentuan yang berlaku, termasuk tetapi tidak terbatas pada pencabutan hak untuk
            mengikuti proses pengadaan di masa mendatang dan tuntutan hukum sesuai peraturan perundang-undangan yang
            berlaku.
          </p>

          <p>
            Dengan menekan tombol "Setuju" di bawah ini, Anda menyatakan telah membaca, memahami, dan menyetujui semua
            isi dari Pakta Integritas ini.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button
            type={'button'}
            className={'btn btn-danger'}
            disabled={isLoadingRegistration}
            onClick={() => {
              navigate('/tender/vendor/aanwijzing')
            }}
          >
            <i className={'fa fa-times'}></i>
            {intl.formatMessage({id: 'Tidak Mengikuti'})}
          </button>
          <button
            type={'button'}
            className={'btn btn-primary'}
            disabled={isLoadingRegistration}
            onClick={handleOnRegistration}
          >
            {isLoadingRegistration && (
              <Spinner animation="border" role="status" size={'sm'} />
            )}
            {!isLoadingRegistration && (
              <i className={'fa fa-thumbs-up'}></i>
            )}

            {intl.formatMessage({id: 'Mengikuti'})}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default AanwijzingVendorDetail