import {getEnv} from '../../../../../../helpers/procurex'
import React, {createContext, useContext, useEffect, useState} from 'react'
import {useMutation, useQuery} from 'react-query'
import axios from 'axios'
import {toast} from 'react-toastify'
import {useParams} from 'react-router-dom'

const API_URL = getEnv('tender')

//region context
const AanwijzingContext = createContext({
  aanwijzing: null as any,
  isLoading: null as boolean,
  isLoadingSubmitForApproval: null as boolean,
  isLoadingSaveDraft: null as boolean,
  refetchAanwijzing: () => {},
  setAanwizingUuid: () => {},
  mutateSubmitForApproval: () => {},
  mutateSaveDraft: () => {},
} as any)

const AanwijzingProvider = ({children}: any) => {
  const [aanwijzing, setAanwijzing] = useState(null)
  const [aanwizingUuid, setAanwizingUuid] = useState("")

  //region get aanwijzing
  const {refetch: refetchAanwijzing,isLoading} = useQuery({
    enabled: false,
    queryKey: "aanwijzing",
    queryFn: () => axios.get(API_URL+`/aanwijzing/${aanwizingUuid}`),
    onSuccess:(res) => {
      setAanwijzing(res?.data?.data)
    }
  })

  useEffect(() => {
    if(aanwizingUuid){
      refetchAanwijzing().then(r => {})
    }
  }, [aanwizingUuid])
  // endregion


  //region submit for approval
  const {mutate:mutateSubmitForApproval, isLoading: isLoadingSubmitForApproval} = useMutation({
    mutationKey: ["aanwijzing","submit"],
    mutationFn: ({payload}:any) => axios.post(API_URL+`/aanwijzing/submit/${aanwizingUuid}`,payload),
  })
  //endregion

  //region save draft

  const {mutate:mutateSaveDraft, isLoading:isLoadingSaveDraft} = useMutation({
    mutationKey: ["aanwijzing","store"],
    mutationFn: ({payload}:any) => axios.put(`${getEnv('tender')}/aanwijzing/${payload.uuid}`,payload),
  })
  //endregion

  return (
    <AanwijzingContext.Provider value={{
      aanwijzing,
      refetchAanwijzing,
      setAanwizingUuid,
      mutateSubmitForApproval,
      isLoading,
      isLoadingSubmitForApproval,
      mutateSaveDraft,
      isLoadingSaveDraft,
    }}>
      {children}
    </AanwijzingContext.Provider>
  )
}

const useAanwijzing = () => {
  return useContext(AanwijzingContext)
}
//endregion

export {
  AanwijzingProvider,
  useAanwijzing,

}

