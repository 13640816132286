import { Modal } from 'react-bootstrap'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { getEnv } from '../../../../helpers/procurex'
import { useMutation } from 'react-query'
import axios from 'axios'
import { Button, Input, InputGroup, Placeholder } from 'rsuite'
import debounce from 'lodash/debounce'
import { useLocation } from 'react-router-dom'

const ModalDocument = ({ documentType }: any) => {
  const [show, setShow] = useState(false);
  const intl = useIntl();
  const [listDocument, setListDocument] = useState<any>([])
  const [search, setSearch] = useState("")
  const searchInputRef = useRef<any>(null)
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)


  const { mutate, isLoading, data } = useMutation({
    mutationKey: "getDocument",
    mutationFn: ({ payload, isLoadMore }: any) => axios.get(
      getEnv('masterdata') + '/document',
      // @ts-ignore
      {
        params: {
          'filter[type]': documentType,
          'filter[description]': search,
          'page': page,
          perPage: 10
        }
      }
    ),
    onSuccess: (data, variables) => {
      if (variables.isLoadMore) {
        setListDocument(prevDocs => [...prevDocs, ...data?.data?.data])
      } else {
        setListDocument(data?.data?.data)
      }

      setTimeout(() => {
        searchInputRef.current?.focus();
      }, 0);

      setHasMore(data?.data?.meta?.current_page < data?.data?.meta?.last_page)
    }
  })

  const debouncedSearch = useCallback(
    debounce((searchTerm: string) => {
      setPage(1)
      mutate({ search: searchTerm });
    }, 300),
    []
  );

  useEffect(() => {
    debouncedSearch(search);
  }, [search, debouncedSearch]);

  useEffect(() => {
    if (show) {
      mutate({})
    } else {
      setListDocument([])
      setPage(1)
      setHasMore(true)
    }
  }, [show, documentType])

  const handleLoadMore = () => {
    setPage(prevPage => prevPage + 1)
    mutate({ isLoadMore: true })
  }

  // hide sementara user guide di stockview karena user merasa terganggu
  const location = useLocation();

  if (location.pathname.startsWith('/stockview')) {

    return null;
  }
  // end

  return (
    <>
      <div className="menu-item ps-4 hover-white"
        style={{
          position: 'absolute',
          bottom: 40,
        }}
      >
        <a
          href={'javascript:;'}
          onClick={() => setShow(true)}
          className="menu-title text-white "
          style={{
            position: 'fixed',
            fontSize: '1.2em'
          }}
        >
          <i className="fa fa-book me-5"></i>
          User Guide
        </a>
      </div>

      <Modal show={show} size="lg" onHide={() => setShow(false)}>
        <Modal.Header closeButton={true}>
          <Modal.Title>User Guide</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className={"mb-10"}>
              <InputGroup inside>
                <Input
                  placeholder={"Search User Guide"}
                  autoFocus={true}
                  disabled={isLoading}
                  value={search}
                  ref={searchInputRef}
                  onChange={(v) => setSearch(v)}
                />
                <InputGroup.Button>
                  <i className={"fa fa-search"} />
                </InputGroup.Button>
              </InputGroup>
            </div>
            {isLoading && (
              <Placeholder.Paragraph rows={10} active style={{ marginTop: 30 }} />
            )}
            {!isLoading && (
              <div style={{
                maxHeight: 500,
                overflowY: "scroll"
              }}>
                {listDocument?.map((v: any) => {
                  return (
                    <div key={v.id} className={"border-bottom py-5"}>
                      <div className={"d-flex justify-content-between"}>
                        <h6>{v?.description}</h6>
                      </div>
                      <div className={"d-flex justify-content-between"}>
                        <div className={"d-flex gap-10"}>
                          <div>
                            <span
                              className={'text-muted'}>{intl?.formatMessage({ id: 'Last Update' })}</span> : {v?.updated_at}
                          </div>
                          <div>
                            <span className={'text-muted'}>{intl?.formatMessage({ id: 'Type' })}</span> :
                            <span style={{}}> {v?.type}</span>
                          </div>
                        </div>


                        <div>
                          <a href={v?.media_url} target={'_blank'} style={{
                            color: 'blueviolet',
                          }} className={'btn btn-default  border btn-xs'}>
                            <i className={"fa fa-link"} style={{
                              color: 'blueviolet',
                            }}></i>
                            {intl?.formatMessage({ id: 'Download' })}
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}

                {hasMore && (
                  <div className="text-center mt-3">
                    <button onClick={handleLoadMore} className={'btn btn-info btn btn-sm'} disabled={isLoading}>
                      {isLoading ? 'Loading...' : 'Load More'}
                      <i className={'fa fa-chevron-down ms-2'}></i>
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className={"btn btn-primary btn-sm"} onClick={() => setShow(false)}>
            <i className={"fa fa-thumbs-up"}></i>
            {intl.formatMessage({ id: "OK" })}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalDocument;