import React, { useEffect, useState } from "react";
import { capitalizeFirstChar, getEnv, parseNumberToCurrency, parseNumberToFloat } from "../../../../../../helpers/procurex";
import { useAxiosGet, useAxiosPost } from "../../../../../../hooks/useAxios";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import moment from "moment/moment";
import { Placeholder } from "rsuite";
import { Spinner } from "react-bootstrap";

const StatusBadge = ({ item }: any) => {
  if (item?.banned) return <strong className={"text-dark"}>Banned</strong>;
  if (item?.freeze) return <strong className={"text-dark"}>Freeze</strong>;
  return <strong className={"text-dark"}>{capitalizeFirstChar(item?.status)}</strong>;

};

const PesertaAuction = ({ auction, isLoading, peserta }: any) => {

  const [{ doSubmit: doSubmitPost, isLoading: isLoadingPost }] = useAxiosPost(
    getEnv("auction")
  );


  const getStatusColor = (item: any) => {
    if (item.banned) return "#ff686b";
    if (item.freeze) return "#a2d2ff";
    if (item.status === "active") return "rgb(71 231 144)";
    if (item.status === "rejected") return "#e4e6ef";
    if (item.status === "inactive") return "rgb(255 212 58)";
    if (item.status === "registered") return "lightyellow";
    return "#ccd5ae";
  };

  const isBtnFreezeShow = (item: any) => {
    return !(item.freeze);
  };

  const isBtnBanShow = (item: any) => {
    return !(item.banned);
  };

  const handleFreeze = (id: any, state: number) => {
    const newUrl = `${getEnv("auction")}/peserta/${id}/freeze/${state}`;
    doSubmitPost({ _method: "PUT" }, newUrl).then(() => {
      toast.success(`Berhasil ${state === 1 ? "Freeze" : "Unfreeze"} peserta`);
    });
  };

  const handleBan = (id: any, state: number) => {
    const newUrl = `${getEnv("auction")}/peserta/${id}/banned/${state}`;
    doSubmitPost({ _method: "PUT" }, newUrl).then(() => {
      toast.success(`Berhasil ${state === 1 ? "Ban" : "Unbanned"} peserta`);
    });
  };

  const isBtnEnabled = () => {
    const allowedStatus = ["ongoing", "published"];
    return allowedStatus.includes(auction?.status);
  };

  useEffect(() => {
  }, []);

  return (
    <>
      {isLoading && (
        <>
          <Placeholder.Paragraph rows={1} active />
          <Placeholder.Grid active columns={4} />
        </>
      )}
      {!isLoading && (
        <>
          <h3>Peserta Auction</h3>
          <div className={"table-responsive"}>
            <table className={"table table-header-gray"}>
              <thead>
                <tr>
                  <th className={"border text-center px-2"} style={{ width: "50px" }}>No</th>
                  <th className={"border text-start px-2 "}>Vendor</th>
                  <th className={"border text-center px-2"} style={{ width: 200 }}>Status</th>
                  <th className={"border text-center px-2"} style={{ width: 200 }}>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {peserta?.length === 0 && (
                    <td className={"border text-center px-2"} colSpan={4}>
                      Tidak ada data
                    </td>
                  )}
                </tr>
                {peserta?.map((item: any, index: number) => {
                  return (
                    <tr key={index}>
                      <td className={"border text-center px-2"}>{index + 1}</td>
                      <td className={"border text-start px-2 blink"}>{item.vendor.no} - {item.vendor.name}</td>
                      <td
                        className={`border text-center`}
                        style={{ backgroundColor: getStatusColor(item) }}
                      >
                        <StatusBadge item={item} />
                      </td>
                      <td className={"border text-center p-2"}>
                        <div className={"btn-group"}>
                          {auction?.freeze_time_in_minutes > 0 && isBtnFreezeShow(item) && (
                            <button
                              className={"btn btn-sm btn-light-danger text-nowrap"}
                              type={"button"}
                              onClick={() => {
                                handleFreeze(item.id, 1);
                              }}
                              disabled={!isBtnEnabled()}
                            >
                              {isLoadingPost && (<i className={"fa fa-spinner fa-spin me-2"}></i>)}
                              Freeze
                            </button>
                          )}
                          {auction?.freeze_time_in_minutes > 0 && !isBtnFreezeShow(item) && (
                            <button
                              className={"btn btn-sm btn-light-danger text-nowrap"}
                              type={"button"}
                              onClick={() => {
                                handleFreeze(item.id, 0);
                              }}
                              disabled={!isBtnEnabled()}
                            >
                              {isLoadingPost && (<i className={"fa fa-spinner fa-spin me-2"}></i>)}
                              Unfreeze
                            </button>
                          )}
                          {isBtnBanShow(item) && (
                            <button
                              className={"btn btn-sm btn-light-danger text-nowrap"}
                              type={"button"}
                              onClick={() => {
                                handleBan(item.id, 1);
                              }}
                              disabled={!isBtnEnabled()}
                            >
                              {isLoadingPost && (<i className={"fa fa-spinner fa-spin me-2"}></i>)}
                              Ban
                            </button>
                          )}
                          {!isBtnBanShow(item) && (
                            <button
                              className={"btn btn-sm btn-light-danger text-nowrap"}
                              type={"button"}
                              onClick={() => {
                                handleBan(item.id, 0);
                              }}
                              disabled={!isBtnEnabled()}
                            >
                              {isLoadingPost && (<i className={"fa fa-spinner fa-spin me-2"}></i>)}
                              Unbanned
                            </button>
                          )}

                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
};

const RowTabulasiPackage = ({ peserta, index }: any) => {
  return (
    <>
      {peserta?.items?.map((itemAuction: any, index2: number) => {
        return (
          <tr key={"item-bid-" + index2}>
            {
              index2 == 0 &&
              <td className={"border text-center align-middle px-2"} rowSpan={peserta?.items?.length}>{index + 1}</td>
            }
            {
              index2 == 0 &&
              <td className={"border text-center text-nowrap align-middle px-2"} rowSpan={peserta?.items?.length}>{peserta?.submit_date}</td>
            }
            {
              index2 == 0 &&
              <td className={"border text-start text-nowrap align-middle px-2"} rowSpan={peserta?.items?.length}>{peserta?.peserta?.vendor?.name}</td>
            }
            <td className={"border text-start text-nowrap px-2"}>{itemAuction?.nama}</td>
            {
              index2 == 0 &&
              <td className={"border text-center align-middle px-2"} rowSpan={peserta?.items?.length}>{itemAuction?.currency}</td>
            }

            <td className={"border text-end px-2"}>{itemAuction?.kuantitas}</td>
            <td className={"border text-end px-2"}>{parseNumberToCurrency(itemAuction?.unit_price, itemAuction.currency)}</td>
            <td className={"border text-end px-2"}>{parseNumberToCurrency(itemAuction?.bid_price, itemAuction.currency)}</td>
            <td className={"border text-end px-2"}>{parseNumberToCurrency(itemAuction?.bid_total_price, itemAuction.currency)}</td>
            {
              index2 == 0 &&
              <td className={"border text-center align-middle px-2"} rowSpan={peserta?.items?.length}>{parseNumberToCurrency(peserta?.total_price, itemAuction.currency)}</td>
            }
            {
              index2 == 0 &&
              <td className={"border text-center align-middle px-2"} rowSpan={peserta?.items?.length}>{peserta?.ranking} {(peserta?.ranking === 1) && <i className={"fa fa-trophy fs-3"} style={{ color: "gold" }}></i>}</td>
            }
            <td className={"border text-start px-2"}></td>
          </tr>
        );
      })
      }
    </>
  );
};

const RowTabulasiItemized = ({ auction, item, index }: any) => {
  return (
    <div className={"m-0 p-0"}>
      <table className={"table table-header-gray"}>
        <thead>
          <tr>
            <th className={"border text-center px-2"} style={{ width: "50px" }}>No</th>
            <th className={"border text-start px-2"}>Item Name</th>
            <th className={"border text-start px-2"} style={{ width: "200px" }}>Currency</th>
            <th className={"border text-end px-2"} style={{ width: "100px" }}>Quantity</th>
            <th className={"border text-end px-2"} style={{ width: "150px" }}>Unit Price</th>
            <th className={"border text-end px-2"} style={{ width: "150px" }}>Total Price</th>
            <th className={"border text-center px-2"} style={{ width: "100px" }}>Ranking</th>
            <th className={"border text-center px-2"} style={{ width: "100px" }}>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr key={index}>
            <td className={"border text-center px-2"}>{index + 1}</td>
            <td className={"border text-start px-2"}>{item.nama}</td>
            <td className={"border text-start px-2"}>{item.currency}</td>
            <td className={"border text-end px-2"}>{item.quantity}</td>
            <td className={"border text-end px-2"}>{parseNumberToCurrency(item?.unit_price, item.currency)}</td>
            <td className={"border text-end px-2"}>{parseNumberToCurrency(item?.total_price, item.currency)}</td>
            <td className={"border text-center px-2"}>
              {item.ranking}
            </td>
            <td className={"border text-center px-2"}>
            </td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <th className={"border text-start px-2"} colSpan={2}>Submitted Date</th>
            <th className={"border text-start px-2"}>Vendor</th>
            <th className={"border text-end px-2"}>Quantity</th>
            <th className={"border text-end px-2"}>Unit Price</th>
            <th className={"border text-end px-2"}>Total Price</th>
            <th className={"border text-center px-2"}></th>
            <th className={"border text-center px-2"}></th>
          </tr>
        </thead>
        <tbody>
          {item?.peserta?.map((bid: any, index2: number) => {
            return (
              <tr key={"tfoot-" + bid?.id + '-' + index2}>
                <td className={"border text-start px-2"} colSpan={2}>{bid.last_bid_at}</td>
                <td className={"border text-start px-2 d-flex flex-column"}>
                  <div><small>{bid.vendor_no}</small></div>
                  <div><small>{bid.vendor_name}</small></div>
                </td>
                <td className={"border text-end px-2"}>{bid.quantity}</td>
                <td className={"border text-end px-2"}>{parseNumberToCurrency(bid.unit_price, item.currency)}</td>
                <td className={"border text-end px-2"}>{parseNumberToCurrency(bid.total_price, item.currency)}</td>
                <td className={"border text-center px-2"}>
                  {bid.ranking} {(bid?.ranking === 1) && <i className={"fa fa-trophy fs-3"} style={{ color: "gold" }}></i>}
                </td>
                <td className={"border text-start px-2"}></td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const RowTabulasiItemizedMvb = ({ auction, item, index }: any) => {
  return (
    <div className={"m-0 p-0"}>
      <table className={"table table-header-gray"}>
        <thead>
          <tr>
            <th className={"border text-center px-2"} style={{ width: "50px" }}>No</th>
            <th className={"border text-start px-2"}>Item Name</th>
            <th className={"border text-start px-2"} style={{ width: "200px" }}>Currency</th>
            <th className={"border text-end px-2"} style={{ width: "100px" }}>Quantity</th>
            <th className={"border text-end px-2"} style={{ width: "150px" }}>Unit Price</th>
            <th className={"border text-end px-2"} style={{ width: "150px" }}>Total Price</th>
            <th className={"border text-center px-2"} style={{ width: "100px" }}>Ranking</th>
          </tr>
        </thead>
        <tbody>
          <tr key={index}>
            <td className={"border text-center px-2"}>{index + 1}</td>
            <td className={"border text-start px-2"}>{item.nama}</td>
            <td className={"border text-start px-2"}>{item.currency}</td>
            <td className={"border text-end px-2"}>{item.quantity}</td>
            <td className={"border text-end px-2"}>{parseNumberToCurrency(item?.unit_price, item.currency)}</td>
            <td className={"border text-end px-2"}>{parseNumberToCurrency(item?.total_price, item.currency)}</td>
            <td className={"border text-center px-2"}>
              {item.ranking}
            </td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <th className={"border text-start px-2"} colSpan={2}>Submitted Date</th>
            <th className={"border text-start px-2"}>Vendor</th>
            <th className={"border text-start px-2"} colSpan={2}>Variable</th>
            <th className={"border text-end px-2"}>Value</th>
            <th className={"border text-center px-2"}></th>
          </tr>
        </thead>
        <tbody>
          {item?.peserta?.map((bid: any, index2: number) => {
            return bid?.variables?.map((variable: any, index3: number) => {
              return (
                <tr key={"tfoot-" + bid?.id + '-' + index3}>
                  {index3 == 0 &&
                    <>
                      <td className={"border text-start px-2"} colSpan={2} rowSpan={bid?.variables?.length}>{bid.last_bid_at}</td>
                      <td className={"border text-start px-2"} rowSpan={bid?.variables?.length}>
                        <div><small>{bid.vendor_no}</small></div>
                        <div><small>{bid.vendor_name}</small></div>
                      </td>
                    </>
                  }
                  <td className={"border text-start px-2"} colSpan={2}>{variable?.variable_name}</td>
                  <td className={"border text-end px-2"}>{parseNumberToFloat(variable?.value, 2)}</td>
                  {index3 == 0 &&
                    <>
                      <td className={"border align-middle text-center px-2"} rowSpan={bid?.variables?.length}>
                        {bid.ranking} {(bid?.ranking === 1) && <i className={"fa fa-trophy fs-3"} style={{ color: "gold" }}></i>}
                      </td>
                    </>
                  }
                </tr>
              );
            })

          })}
        </tbody>
      </table>
    </div>
  );
};

const Tabulasi = ({ auction, tabulasi, getTabulasi, isLoading }: any) => {
  useEffect(() => {
  }, []);

  return (
    <>
      <h3>Tabulasi Auction
        <button
          type={"button"}
          onClick={() => {
            getTabulasi();
          }}
          className='btn btn-sm btn-light-info mx-1 fw-bold'>
          {isLoading && <Spinner animation={"border"} size={"sm"} className={"me-2"}></Spinner>}
          {!isLoading && <i className={"fa fa-refresh"}></i>}
          Refresh</button>
      </h3>
      <div className={"table-responsive"} id={"table-tabulasi"}>
        {auction?.template_id != null && auction?.is_package == false && tabulasi?.map((item: any, index: number) => {
          return (
            <RowTabulasiItemizedMvb auction={auction} key={"tabulasi-" + index} item={item} index={index} />
          );
        })}
        {auction?.template_id == null && auction?.is_package == false && tabulasi?.map((item: any, index: number) => {
          return (
            <RowTabulasiItemized auction={auction} key={"tabulasi-" + index} item={item} index={index} />
          );
        })}
        {auction?.template_id == null && auction?.is_package == true && (
          <>
            <div className={"m-0 p-0"}>
              <table className={"table table-header-gray"}>
                <thead>
                  <tr>
                    <th className={"border text-center px-2"} style={{ width: "50px" }}>No</th>
                    <th className={"border text-center px-2"}>Submit Date</th>
                    <th className={"border text-center px-2"}>Rekanan</th>
                    <th className={"border text-center px-2"}>Item Name</th>
                    <th className={"border text-center px-2"}>Currency</th>
                    <th className={"border text-center px-2"}>Quantity</th>
                    <th className={"border text-center px-2"}>Unit Price HPS</th>
                    <th className={"border text-center px-2"}>Unit Price</th>
                    <th className={"border text-center px-2"}>Total Price</th>
                    <th className={"border text-center px-2"}>Total Paket</th>
                    <th className={"border text-center px-2"}>Ranking</th>
                    <th className={"border text-center px-2"}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {tabulasi?.map((item: any, index: number) => {
                    return (
                      <RowTabulasiPackage key={"tabulasi-" + index} peserta={item} index={index} />
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        )
        }
      </div>
    </>
  );
};

const RowRankingItemized = ({ item, index }: any) => {
  return (
    <>
      <table className={"table table-header-gray"}>
        <thead>
          <tr>
            <th className={"border text-center px-2"} style={{ width: "50px" }}>No</th>
            <th className={"border text-start px-2"}>Item Name</th>
            <th className={"border text-start px-2"} style={{ width: "150px" }}>Currency</th>
            <th className={"border text-end px-2"} style={{ width: "200px" }}>Quantity</th>
            <th className={"border text-end px-2"} style={{ width: "150px" }}>Unit Price</th>
            <th className={"border text-end px-2"} style={{ width: "150px" }}>Total Price</th>
          </tr>
        </thead>
        <tbody>
          <tr key={index}>
            <td className={"border text-center px-2"}>{index + 1}</td>
            <td className={"border text-start px-2"}>{item.nama}</td>
            <td className={"border text-start px-2"}>{item.currency}</td>
            <td className={"border text-end px-2"}>{item.quantity}</td>
            <td className={"border text-end px-2"}>{parseNumberToCurrency(item?.unit_price, item.currency)}</td>
            <td className={"border text-end px-2"}>{parseNumberToCurrency(item?.subtotal, item.currency)}</td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <th className={"border text-center px-2"} colSpan={2}>Ranking</th>
            <th className={"border text-start px-2"}>Kode Vendor</th>
            <th className={"border text-start px-2"}>Nama Vendor</th>
            <th className={"border text-end px-2"}>Unit Price</th>
            <th className={"border text-end px-2"}>Total Price</th>
          </tr>
        </thead>
        <tbody>
          {item?.ranking?.map((bid: any, index2: number) => {
            return (
              <tr key={"tfoot-" + bid.vendor_no + '-' + index2}>
                <td className={"border text-center px-2"} colSpan={2}>{bid.ranking}</td>
                <td className={"border text-start px-2"}>{bid.vendor_no}</td>
                <td className={"border text-start px-2"}>{bid.vendor_name}</td>
                <td className={"border text-end px-2"}>{parseNumberToCurrency(bid.unit_price, item.currency)}</td>
                <td className={"border text-end px-2"}>{parseNumberToCurrency(bid.total_price, item.currency)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

const RowRankingPackage = ({ item, index }: any) => {
  return (
    <>
      <tr key={index}>
        <td className={"border text-center px-2"}>{item?.ranking} </td>
        <td className={"border text-start text-nowrap px-2"}>{item?.peserta?.vendor?.name}</td>
        <td className={"border text-end text-nowrap px-2"}>{parseNumberToCurrency(item?.total_price, item?.currency)}</td>
      </tr>
    </>
  );
};

const RankingAuction = ({ title, auction, ranking }: any) => {


  useEffect(() => {
  }, []);

  return (
    <>
      <h3>{title}</h3>
      <div className={"table-responsive"}>
        {auction?.is_package == false && ranking?.map((item: any, index: number) => {
          return (
            <RowRankingItemized key={'row-ranking-' + index} item={item} index={index} />
          );
        })}


        {auction?.is_package == true && (
          <>
            <div className={"m-0 p-0"}>
              <table className={"table table-header-gray"}>
                <thead>
                  <tr>
                    <th className={"border text-center px-2"}>Ranking</th>
                    <th className={"border text-center px-2"}>Vendor</th>
                    <th className={"border text-center px-2"}>Harga Penawaran</th>
                  </tr>
                </thead>
                <tbody>
                  {ranking?.map((item: any, index: number) => {
                    return (
                      <RowRankingPackage key={"ranking-" + index} item={item} index={index} />
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        )
        }
      </div>
    </>
  );
};


const TabulasiAuctionPage = ({ auction, tabulasi, getTabulasi, isLoading }: any) => {
  useEffect(() => {
  }, []);

  return (
    <>
      {!tabulasi && (
        <>
          <Placeholder.Paragraph rows={1} active />
          <Placeholder.Grid active columns={4} />
        </>
      )}
      <PesertaAuction auction={auction} peserta={tabulasi?.peserta} />
      {tabulasi?.tabulasi.length > 0 &&
        <>
          <hr />
          <Tabulasi getTabulasi={() => getTabulasi()} auction={auction} tabulasi={tabulasi?.tabulasi} isLoading={isLoading} />
        </>
      }
      {tabulasi?.ranking.length > 0 &&
        <>
          <hr />
          <RankingAuction title={"Ranking"} auction={auction} ranking={tabulasi?.ranking} />
        </>
      }

    </>
  );
};

export { TabulasiAuctionPage };
