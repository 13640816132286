import * as Yup from "yup";
import React, { useEffect, useRef, useState } from "react";
import { Field, Form, Formik } from "formik";
import { getEnv, parseStringToBool } from "../../../../../../helpers/procurex";
import { useAxiosGet, useAxiosPost } from "../../../../../../hooks/useAxios";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import FormAttachment from "../../../../../components/shared/form/FormAttachment";
import QuotationVendorTab from "./QuotationVendorTab";
import { Nav, Radio, RadioGroup } from "rsuite";
import { useIntl } from "react-intl";
import { checkIsComplete1Sampul, checkIsComplete2Sampul } from "./SideLeftLayout";
import { MetronicErrorMessage } from "../../../../../../_metronic/helpers";

const FormRegisterQuotationVendor = ({ proposalTender, onRefresh }: any) => {
  const [initialValues, setInitialValues] = useState({
    proposal_tender_id: proposalTender?.uuid,
    proposal_tender_no: proposalTender?.no,
    description: proposalTender?.title,
    date: "",
    response: "",
    attachment: proposalTender?.vendor_rejection?.media_url ?? "",
    note: proposalTender?.vendor_rejection?.note ?? "",
  });
  const intl = useIntl();
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosPost(
    getEnv("tender") + `/vendor/quotation-registration/${proposalTender.uuid}`
  );
  const formSchemaValidation = Yup.object().shape({
    proposal_tender_id: Yup.string().required("Required"),
    response: Yup.string().required(`${intl.formatMessage({id:'Input Penawaran'})} is required`),
    note: Yup.string().when('response', {
      is: (response: any) => response && response === "0",
      then: Yup.string().required(`${intl.formatMessage({id:'Alasan Tidak Registrasi Penawaran'})} is required`),
      otherwise: Yup.string().nullable(),
    }),
    attachment: Yup.mixed().when('response', {
      is: (response: any) => response && response === "0",
      then: Yup.mixed()
        .test('is-required', 'File is required', (value) => {
          return value !== "";
        })
        .required('File is required'),
      otherwise: Yup.mixed().nullable(),
    }),
  });
  const [vendorStatus, setVendorStatus] = useState(proposalTender?.vendor_status);
  const formik = useRef({} as any);
  const [formIndex,setFormIndex] = useState(1)

  const checkIsFormEnabled = () => {
    const proposalTenderStatus = [
      "Published",
      "Penawaran",
      "Evaluasi Administrasi",
      "Negotiation"
    ]
    return vendorStatus === "REGISTRATION" &&
      proposalTender?.published_action?.quotation;
    // return proposalTender?.vendor_status === "REGISTRATION" &&
    //   proposalTender?.status === "Penawaran" &&
    //   proposalTender?.status_detail === "Quotation";
  };

  const getJadwalRegistrasiTender = () => {
    let date = "";
    proposalTender?.jadwals.map((jadwal: any) => {
      if (proposalTender?.metode_penyampaian === "2 Tahap" && jadwal.description === "Penawaran Teknikal") {
        date = jadwal.start_at + " - " + jadwal.end_at;
      } else if(jadwal.description === "Penawaran"){
        date = jadwal.start_at + " - " + jadwal.end_at;
      }
    });
    return date;
  };

  const handleSubmit = (values: any, { setStatus, setSubmitting }: any) => {
    if (!proposalTender?.published_action?.quotation && parseStringToBool(values.response)) {
      Swal.fire({
        title: intl.formatMessage({id:'Tidak dalam masa Input Penawaran'}),
        icon: "error",
      }).then((result) => {
      });
    } else {
      Swal.fire({
        title: intl.formatMessage({id:'Apakah Anda yakin'})+"?",
        html: parseStringToBool(values.response) ?
          intl.formatMessage({id: 'Anda akan mengikuti proses input penawaran'}) :
          intl.formatMessage({id: 'Anda tidak akan mengikuti proses input penawaran'}),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: intl.formatMessage({id:'Ya, Submit'}),
        cancelButtonText: intl.formatMessage({id:'Tidak'}),
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          const payload = values;
          if (values.attachment) {
            // @ts-ignore
            payload["media_type"] = {
              ...values.attachment,
              type: values.attachment.type.name
            };
            // @ts-ignore
            payload["media_url"] = values.attachment.url;
          }
          doSubmit(payload, null, toast).then((res: any) => {
            if(values.response === "1") {
              setVendorStatus("REGISTRATION-QUOTATION");
            } else {
              setVendorStatus("REJECTED-QUOTATION");
            }
            onRefresh && onRefresh(false, values.response === "1");

          });
        }
      });
    }
  };

  const initForm = () => {
    // default value berdasarkan status
    const trueStatus = ["REGISTRATION-QUOTATION","NEGOTIATION","NEGOTIATION_PROCESS","WINNER"];
    const falseStatus = ["REJECT-QUOTATION"]
    if(trueStatus.includes(proposalTender?.vendor_status)){
      setInitialValues({
        ...initialValues,
        date: getJadwalRegistrasiTender(),
        response: "1"
      });
    } else if(falseStatus.includes(proposalTender?.vendor_status)){
      setInitialValues({
        ...initialValues,
        date: getJadwalRegistrasiTender(),
        response: "0"
      });
    } else {
      setInitialValues({
        ...initialValues,
        date: getJadwalRegistrasiTender(),
        response: ""
      });
    }
    setFormIndex(formIndex+1)
  }

  useEffect(() => {
    initForm();
  }, [proposalTender]);
  return (
    <>
      <Formik
        innerRef={formik}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={formSchemaValidation}
        enableReinitialize
        validateOnChange={true}
        key={formIndex}
      >
        {({ errors, touched, values, setFieldValue, resetForm, submitForm }: any) => (
          <Form id="form-register-tender">
            <div className={"row"}>
              <div className={"col-md-8 col-sm-12 form-group mb-3"}>
                <div className={"form-group mb-4"}>
                  <label className="required form-label">
                    {intl.formatMessage({id:'No. Proposal Tender'})}
                  </label>
                  <Field
                    name="proposal_tender_no"
                    type="text"
                    className="form-control"
                    disabled={true}
                  />

                </div>
                <div className={"form-group mb-4"}>
                  <label className="required form-label">
                    {intl.formatMessage({id:'Deskripsi'})}
                  </label>
                  <Field
                    name="description"
                    type="text"
                    as={"textarea"}
                    className="form-control"
                    disabled={true}
                  />

                </div>
                <div className={"form-group mb-4"}>
                  <label className="required form-label">
                    {intl.formatMessage({id:'Jadwal'})}
                  </label>
                  <Field
                    name="date"
                    type="text"
                    className="form-control"
                    disabled={true}
                  />
                </div>
                <div className={"form-group mb-4"}>
                  <label className="required form-label">
                    {intl.formatMessage({id:'Input Penawaran'})}
                  </label>
                  <RadioGroup name="radioList" className={"gap-0"} value={values?.response}>
                    <Radio
                      value="1"
                      className={""}
                      style={{marginBottom:'-10px'}}
                      onChange={(e:any)=>{
                        setFieldValue("note", "")
                        setFieldValue("attachment", "")
                        setFieldValue("response",e)
                      }}
                      readOnly={!checkIsFormEnabled()}
                    >
                      {intl.formatMessage({id:'Mengikuti Input Penawaran'})}
                    </Radio>
                    <Radio
                      value="0"
                      className={""}
                      onChange={(e:any)=>setFieldValue("response",e)}
                      readOnly={!checkIsFormEnabled()}
                    >
                      {intl.formatMessage({id:'Tidak Mengikuti Input Penawaran'})}
                    </Radio>
                  </RadioGroup>
                  <MetronicErrorMessage name={`response`} />
                </div>
                {(values.response === "0") && (
                  <>
                    <div className={"form-group mb-4"}>
                      <label className="required form-label">
                        {intl.formatMessage({id:'Alasan Tidak Registrasi Penawaran'})}
                      </label>
                      <Field
                        name="note"
                        type="text"
                        as={"textarea"}
                        className="form-control"
                        disabled={parseStringToBool(values.response) || !checkIsFormEnabled()}
                      />
                      <MetronicErrorMessage name={`note`} />
                    </div>

                    <FormAttachment
                      media_type_filter={"TENDER_ATTACHMENT"}
                      onlyFile={true}
                      afterSubmit={(e: any) => {
                        setFieldValue("attachment", e);
                      }}
                      isDisabled = {parseStringToBool(values.response) || !checkIsFormEnabled()}
                      previewUrl={values?.attachment}
                      withPreviewButton={true}
                      overWriteErrMessage={true}
                    />
                    <MetronicErrorMessage name={`attachment`} />
                  </>

                )}


                <div className={"form-group mb-4 text-end"}>
                  <button
                    type="submit"
                    className="btn btn-primary btn btn-sm"
                    disabled={isLoading || !checkIsFormEnabled()}
                  >
                    {isLoading && (
                      <i className={"fa fa-spinner fa-spin"}></i>
                    )}
                    {!isLoading && (
                      <i className={"fa fa-save"}></i>
                    )}
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

const InputPenawaranPage = ({ proposalTender, onRefresh, penawaranType }: any) => {
  const [selectedTab, setSelectedTab] = useState("register");
  const [quotation, setQuotation] = useState(null as any);
  const [currentProposalTender, setCurrentProposalTender] = useState(proposalTender);
  const [renderKey,setRenderKey] = useState(0)
  const [vendorStatus, setVendorStatus] = useState(proposalTender?.vendor_status);
  const [{ doSubmit: doGetDetail, isLoading: isLoadingDetail, data: dataDetail }] = useAxiosGet(
    getEnv("tender") + `/vendor/quotation?filter[proposal_tender_id]=${proposalTender?.id}`
  );
  const [isCompleteTeknis, setIsCompleteTeknis] = useState(true);
  const [isCompleteKomersil, setIsCompleteKomersil] = useState(true);
  const [isCompleteSatuSampul, setIsCompleteSatuSampul] = useState(true);
  const [isCompleteRegistration, setIsCompleteRegistration] = useState(true);
  const [{ doSubmit, isLoading, data }] = useAxiosGet(
    getEnv('tender')+'vendor/published'
  )
  const intl = useIntl();

  const checkIsSatuSampul = () => {
    return proposalTender?.metode_penyampaian === "1 Sampul";
  };

  const checkIsDuaSampul = () => {
    return proposalTender?.metode_penyampaian === "2 Sampul";
  };

  const checkIsDuaTahap = () => {
    return proposalTender?.metode_penyampaian === "2 Tahap";
  };

  const isInputPenawaranEnabled = () => {
    const allowedVendorStatus = ["REGISTRATION-QUOTATION","NEGOTIATION","NEGOTIATION_PROCESS","WINNER"];
    return allowedVendorStatus.includes(vendorStatus);
  }

  const isPenawaranAdministrasiTeknisEnabled = () => {
    const allowedVendorStatus = ["REGISTRATION-QUOTATION","NEGOTIATION","NEGOTIATION_PROCESS","WINNER"];
    return allowedVendorStatus.includes(vendorStatus);
  }

  const isPenawaranKomersilDuaSampulEnabled = () => {
    const allowedStatus = ["Penawaran Komersil"];
    return allowedStatus.includes(proposalTender?.status);
    // return proposalTender?.quotation?
  }

  const getQuotation = () => {
    // doGetDetail({},undefined, undefined).then((res: any) => {
    //   if(res?.data?.length > 0){
    //     setQuotation(res?.data[0])
    //     setRenderKey(renderKey+1);
    //   }
    // })
    onRefresh && onRefresh(true);
    doGetDetail({},undefined, undefined).then((res: any) => {
      if(res?.data?.length > 0){
        setQuotation(res?.data[0])
        setRenderKey(renderKey+1);
      }
    });
  }

  // const NeedAttentionNav = async (uuid: string, type_penawaran: string, setVariable) => {
  //   if(uuid == null) setVariable(false);
  //   const { data } = await axios.get(getEnv("tender") + `/vendor/quotation/quotation-status/${uuid}/${type_penawaran}`);
  //   if(data.meta.code ===  403 ){
  //     setVariable(true);
  //   }
  //   setVariable(false);
  // }

  const handleOnRefresh = async (isNeedRefresh:boolean, isApprove:boolean) => {
    doSubmit(
      {},
      getEnv('tender')+"/vendor/published/"+proposalTender?.uuid
    ).then(r => {
      if(isApprove){
        setVendorStatus("REGISTRATION-QUOTATION");
        setCurrentProposalTender({
          //@ts-ignore
          ...r?.data,
          vendor_status: "REGISTRATION-QUOTATION"
        })
      } else {
        setVendorStatus("REJECT-QUOTATION");
        setCurrentProposalTender({
          //@ts-ignore
          ...r?.data,
          vendor_status: "REJECT-QUOTATION"
        })
      }
      onRefresh && onRefresh(isNeedRefresh);
    });


  }

  useEffect(() => {
    getQuotation();
    setRenderKey(renderKey+1);
    if(!currentProposalTender) return ;
    if(proposalTender.metode_penyampaian === "1 Sampul"){
      setIsCompleteSatuSampul(checkIsComplete1Sampul(currentProposalTender));

      if(currentProposalTender.status === "Penawaran" && currentProposalTender?.vendor_status === "REGISTRATION"){
        setIsCompleteRegistration(false);
      } else {
        setIsCompleteRegistration(true)
      }

    } else if(proposalTender.metode_penyampaian === "2 Sampul"){
      setIsCompleteTeknis(checkIsComplete2Sampul(currentProposalTender,1));
      setIsCompleteKomersil(checkIsComplete2Sampul(currentProposalTender,2));
    } else if(proposalTender?.metode_penyampaian === "2 Tahap") {
      setIsCompleteTeknis(checkIsComplete2Sampul(currentProposalTender,1));
      setIsCompleteKomersil(checkIsComplete2Sampul(currentProposalTender,2));
    }


  }, [proposalTender, proposalTender?.vendor_status, currentProposalTender]);

  const getSisaWaktuPenawaran = (jadwals) => {
    let jadwal = [];
    if(proposalTender?.status === "Penawaran Teknikal")
    {
      jadwal = jadwals?.filter((v)=>v.description === "Penawaran Teknikal")
    }
    return jadwal.length > 0 ? jadwal[0]?.end_at : "";
  }

  return (
    <>
      <div className={"card"}>
        <div className={"card-body"} key={renderKey}>
          <div className={"d-flex justify-content-between"}>
            <h3>{intl.formatMessage({id:'Input Penawaran'})}</h3>
            {getSisaWaktuPenawaran(proposalTender?.jadwals) && (
              <div>
                <strong>
                  {intl.formatMessage({id: 'Quotation Berakhir Pada'})}: &nbsp;
                  {getSisaWaktuPenawaran(proposalTender?.jadwals)}
                </strong>
              </div>
            )}

          </div>
          <hr />
          <Nav appearance={'subtle'} activeKey={selectedTab} onSelect={(e: any) => {
            setSelectedTab(e)
          }} style={{marginBottom: 20}}>
          <Nav.Item eventKey={"register"} className={!isCompleteRegistration ? "red-border" : ""}>
              {intl.formatMessage({id:'Registrasi Input Penawaran'})}
            {!isCompleteRegistration && (
              <small className="error-counter">
                <i className={"fa fa-exclamation-circle text-danger text-sm blink_me"}></i>
              </small>
            )}
            </Nav.Item>

            {checkIsSatuSampul() && (
              <Nav.Item eventKey={"quotation"} disabled={!isInputPenawaranEnabled()} className={!isCompleteSatuSampul ? "red-border" : ""}>
                {intl.formatMessage({id:'Penawaran Administrasi, Teknis & Komersil'})}
                {!isCompleteSatuSampul && (
                  <small className="error-counter">
                    <i className={"fa fa-exclamation-circle text-danger text-sm blink_me"}></i>
                  </small>
                )}
              </Nav.Item>
            )}

            {checkIsDuaSampul() && (
              <>
                <Nav.Item
                  eventKey={"dua-sampul-penawaran-administrasi-teknis"}
                  disabled={!isPenawaranAdministrasiTeknisEnabled()} className={!isCompleteTeknis ? "red-border" : ""}
                >
                  {intl.formatMessage({id:'Penawaran Administrasi, Teknis'})}

                  {!isCompleteTeknis && (
                    <small className="error-counter">
                      <i className={"fa fa-exclamation-circle text-danger text-sm blink_me"}></i>
                    </small>
                  )}

                </Nav.Item>


                <Nav.Item eventKey={"dua-sampul-penawaran-komersil"} disabled={!quotation}
                          className={!isCompleteKomersil ? "red-border" : ""} >
                  {intl.formatMessage({id:'Penawaran Komersil'})}

                  {!isCompleteKomersil && (
                    <small className="error-counter">
                      <i className={"fa fa-exclamation-circle text-danger text-sm blink_me"}></i>
                    </small>
                  )}
                </Nav.Item>
              </>
            )}

            {checkIsDuaTahap() && (
              <Nav.Item eventKey={"dua-tahap-penawaran-administrasi-teknis"} disabled={!isPenawaranAdministrasiTeknisEnabled()} className={!isCompleteTeknis ? "red-border" : ""} > {intl.formatMessage({id:'Penawaran Administrasi, Teknis'})}
                {!isCompleteTeknis && (
                  <small className="error-counter">
                    <i className={"fa fa-exclamation-circle text-danger text-sm blink_me"}></i>
                  </small>
                )}
              </Nav.Item>
            )}

          </Nav>
          {selectedTab === "register" && (
            <FormRegisterQuotationVendor proposalTender={currentProposalTender} onRefresh={handleOnRefresh} />
          )}
          {selectedTab === "quotation" && (
            <QuotationVendorTab
              proposalTender={currentProposalTender}
              onRefresh={()=>handleOnRefresh(false,true)}
              quotationStep={0}
            />
          )}
          {selectedTab === "dua-sampul-penawaran-administrasi-teknis" && (
            <QuotationVendorTab
              proposalTender={currentProposalTender}
              onRefresh={()=>handleOnRefresh(true,true)}
              quotationStep={1}
            />
          )}
          {selectedTab === "dua-sampul-penawaran-komersil" && (
            <QuotationVendorTab
              proposalTender={currentProposalTender}
              onRefresh={()=>handleOnRefresh(true,true)}
              quotationStep={2}
            />
          )}
          {selectedTab === "dua-tahap-penawaran-administrasi-teknis" && (
            <QuotationVendorTab
              proposalTender={currentProposalTender}
              onRefresh={()=>handleOnRefresh(true,true)}
              quotationStep={1}
            />
          )}


          {/*<Tabs>*/}
          {/*  <Tab title={"Registrasi Penawaran"} eventKey={"register"} className={"py-4"}>*/}
          {/*    <FormRegisterQuotationVendor proposalTender={proposalTender} onRefresh={onRefresh} />*/}
          {/*  </Tab>*/}
          {/*  {checkIsSatuSampul() && (*/}
          {/*    <Tab title={"Penawaran Administrasi, Teknis & Komersil"} eventKey={"quotation"} className={"py-4"} disabled={false}>*/}
          {/*      <QuotationVendorTab proposalTender={proposalTender} onRefresh={onRefresh} quotationStep={0} />*/}
          {/*    </Tab>*/}
          {/*  )}*/}
          {/*  {checkIsDuaSampul() && (*/}
          {/*    <Tab title={"Penawaran Administrasi, Teknis"} eventKey={"dua-sampul-penawaran-administrasi-teknis"} className={"py-4"} disabled={false}>*/}
          {/*      <QuotationVendorTab proposalTender={proposalTender} onRefresh={onRefresh} quotationStep={0} />*/}
          {/*    </Tab>*/}
          {/*  )}*/}
          {/*  {checkIsDuaSampul() && (*/}
          {/*    <Tab title={"Penawaran Komersil"} eventKey={"dua-sampul-penawaran-komersil"} className={"py-4"} disabled={false}>*/}
          {/*      <QuotationVendorTab proposalTender={proposalTender} onRefresh={onRefresh}  quotationStep={1} />*/}
          {/*    </Tab>*/}
          {/*  )}*/}

          {/*  {checkIsDuaTahap() && (*/}
          {/*    <Tab title={"Penawaran Administrasi, Teknis"} eventKey={"dua-tahap-penawaran-komersil"} className={"py-4"} disabled={false}>*/}
          {/*      <QuotationVendorTab proposalTender={proposalTender} onRefresh={onRefresh} quotationStep={0} />*/}
          {/*    </Tab>*/}
          {/*  )}*/}


          {/*</Tabs>*/}
        </div>
      </div>
    </>
  );
};

export default InputPenawaranPage;