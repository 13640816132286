import { Field, Form, Formik } from 'formik'
import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { Link, useNavigate, useParams } from 'react-router-dom'
import SelectX from '../../../../../components/shared/form/SelectX'
import { ApiUrl } from '../../../../../config/Api'
import { getEnv } from "../../../../../../helpers/procurex";
import { InputNumber } from "rsuite";
import axios from 'axios'
import { useIntl } from "react-intl";
import Swal from "sweetalert2";
interface CreateProps {
    backUrl?: string
}
const CreateTransportation = ({ backUrl }: CreateProps) => {

    const initialValues = {
        name: '',
        category: '',
        capacity: '0',
        imo: '',
    }

    const formSchemaValidation = Yup.object().shape({

        name: Yup.string().required('Nama is required'),
        category: Yup.string().required('Category is required'),
        capacity: Yup.number().required('Capacity is required').positive('Capacity must be positive'),
    });

    const intl = useIntl();
    const refFormik = React.useRef<any>();

    const [formData, setFormData] = React.useState([]);

    const [loading, setLoading] = useState(true)
    const [isLoading, setIsLoading] = useState(false);
    const [key, setKey] = useState(0)

    const [totalCapacity, setTotalCapacity] = useState(0);

    const handleOnTotalCapacity = (score: number) => {
        setTotalCapacity(score)
    }

    const [selectedCategory, setSelectedCategory] = useState(null);

    const handleCategory = (selectedValue: any) => {
        setSelectedCategory(selectedValue);
    };

    const TableRow = ({ no, name, capacity, imo, category, onDelete, ...props }: any) => {
        return (
            <tr>
                <td className={"border text-center"}>{no}</td>
                <td className={"border"}>{name}</td>
                <td className={"border"}>{capacity}</td>
                <td className={"border"}>{imo}</td>
                <td className={"border"}>{category}</td>
                <td className={"border text-center"}>
                    <button className={"btn btn-sm btn-secondary"} onClick={() => onDelete()}>
                        <i className={"fa fa-trash text-danger"}></i>
                    </button>
                </td>
            </tr>
        );
    };

    const [formindex, setFormIndex] = useState(0)

    const navigate = useNavigate();

    const handleBack = async () => {
        Swal.fire({
            title: `Data Belum Tersimpan`,
            html: `Apakah Anda Yakin Ingin Keluar ?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: `Ya, Keluar!`,
            confirmButtonColor: "#999999",
            cancelButtonColor: "#258141",
            cancelButtonText: `Cancel`,
        }).then((result) => {
            if (result.isConfirmed) {
                navigate('/stockview/masterdata/transportation')
            }
        })
    }

    const handleBackClick = () => {
        console.log(formData);

        if (formData.length !== 0) {
            handleBack();
        } else {
            navigate('/stockview/masterdata/transportation');
        }
    };

    const handleAddRow = async (values: any) => {
        const newData = {
            name: values.name,
            category: values.category,
            imo: values.imo,
            capacity: values.capacity,
        };
        setFormData([...formData, newData]);
        setFormIndex(formindex + 1)
        // @ts-ignore

    };

    const handleDeleteRow = async (index: number) => {
        const newData = formData.filter((item: any, i: number) => i !== index);
        // @ts-ignore
        setFormData(newData);
    };

    const handleSubmit = async () => {
        setLoading(true)
        try {
            const data = {
                ...formData
            };

            const response = await axios.post(getEnv("stockview") + `/transportation`, data)

            if (response.status === 200 || response.status === 201) {
                toast.success(response?.status + ': ' + response?.data.meta.message, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                })
            }
            setFormIndex(formindex + 1)
        } catch (error: any) {
            console.error(error)
            toast.error(error?.data?.meta?.message || 'Terjadi kesalahan', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            })
        } finally {
            setLoading(false)
        }
    }

    return (
        <Formik
            key={formindex}
            innerRef={refFormik}
            initialValues={initialValues}
            onSubmit={handleAddRow}
            validationSchema={formSchemaValidation}
            enableReinitialize
            validateOnChange={true}
        >
            {({ errors, touched, values, setFieldValue }: any) => (
                <Form>
                    <div className='d-flex justify-content-between mb-6'>
                        <div>
                            <button className='btn btn-secondary btn-sm fw-bold' onClick={handleBackClick}>
                                Back To list
                            </button>
                        </div>
                        <div className='d-flex gap-4'>
                            <button className={"btn btn-primary btn-sm"} disabled={isLoading}
                                onClick={() => handleSubmit()}>
                                {isLoading && <i className={"fa fa-spinner fa-spin"}></i>}
                                {!isLoading && <i className={"fa fa-save"}></i>}
                                {intl.formatMessage({ id: "Simpan" })}
                            </button>
                        </div>
                    </div>

                    <div className='card my-5'>
                        <div className='card-header d-flex align-items-center'>
                            <b>FORM ADD TRANSPORTATION</b>
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <div className='mb-6'>
                                        <label htmlFor='' className='required form-label'>
                                            Name
                                        </label>
                                        <Field className='form-control' name="name" placeholder="input name.." />
                                        {(errors.name && touched.name) && (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{errors.name}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='mb-6'>
                                        <label htmlFor='' className='required form-label'>
                                            Capacity
                                        </label>
                                        <InputNumber
                                            defaultValue={totalCapacity}
                                            min={0}
                                            name="capacity"
                                            className={"w-100 text-end"}
                                            onChange={(e: any) => setFieldValue('capacity', e)}
                                        />
                                        {(errors.capacity && touched.capacity) && (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{errors.capacity}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='mb-6'>
                                        <label htmlFor='' className='required form-label'>
                                            Category
                                        </label>
                                        <SelectX
                                            onChange={(e: any) => setFieldValue('category', e)}
                                            options={[
                                                { label: 'Shipping', value: 'Shipping' },
                                                { label: 'Truck', value: 'Truck' },
                                            ]}
                                        />
                                        {(errors.category && touched.category) && (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{errors.category}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='mb-6'>
                                        <label htmlFor='' className='form-label'>
                                            Nomor IMO
                                        </label>
                                        <Field className='form-control' name="imo" placeholder="input imo.." />
                                        {(errors.imo && touched.imo) && (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{errors.imo}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='d-flex justify-content-end gap-4'>
                                    <button type={"submit"} className={"btn btn-primary btn-sm fw-bold"}>
                                        {isLoading && <i className={"fa fa-spinner fa-spin"}></i>}
                                        {!isLoading && <i className={"fa fa-plus"}></i>}
                                        {intl.formatMessage({ id: "Tambah" })}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"col-md-12 mt-5"}>
                        <table className={"table table-bordered"}>
                            <thead>
                                <tr>
                                    <th className={"border text-center"}><strong>{intl.formatMessage({ id: "No" })}</strong></th>
                                    <th className={"border text-center"}><strong>{intl.formatMessage({ id: "Name" })}</strong></th>
                                    <th className={"border text-center"}><strong>{intl.formatMessage({ id: "Capacity" })}</strong></th>
                                    <th className={"border text-center"}><strong>{intl.formatMessage({ id: "Nomor IMO" })}</strong></th>
                                    <th className={"border text-center"}><strong>{intl.formatMessage({ id: "Category" })}</strong></th>
                                    <th className={"border text-center"}><strong>{intl.formatMessage({ id: "Aksi" })}</strong></th>
                                </tr>
                            </thead>
                            <tbody>
                                {formData.length === 0 && (
                                    <tr>
                                        <td colSpan={6} className={"text-center"}>
                                            <i>{intl.formatMessage({ id: "Tidak ada data" })}</i>
                                        </td>
                                    </tr>
                                )}
                                {formData.map((item: any, index: number) => {
                                    return (
                                        <TableRow
                                            key={"adm-" + index}
                                            no={index + 1}
                                            name={item.name}
                                            capacity={item.capacity}
                                            imo={item.imo}
                                            category={item.category}
                                            onDelete={() => handleDeleteRow(index)}
                                        />
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export { CreateTransportation }