import { useEffect, useState } from "react";
import TableXWrpper from "../../../../../components/shared/TableXWrapper";
import { KTCard, KTCardBody } from "../../../../../../_metronic/helpers";
import { useParams } from "react-router-dom";
import { formatNumber } from "../../../../../../helpers/procurex";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import axios from "axios";
import TableX from "../../../../../components/shared/TableX";

const RiwayatAuction = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const params = useParams();
    const [key, setKey] = useState(0)
    const idParam = params['*'];
    const [isLoadingFetch, setIsLoadingFetch] = useState(false)
    const [detailData, setDetailData]: any = useState([{
        nama: "",
        kuantitas: "",
        currency: 0,
        unit_price: 0,
        total_price: 0,
        bid: [],
    }]);
    const tableColumns = [
        {
            Header: "Date", accessor: "date", className: "text-center",
            Cell: (props: any) => {
                return (
                    <span>
                        {moment(props?.cell?.row?.original?.date).format('DD MMM YYYY HH:mm:ss.SSS')}
                    </span>
                )
            }
        },
        { Header: "Vendor", accessor: "vendor", className: "text-center" },
        { Header: "QTY", accessor: "kuantitas", className: "text-center" },
        {
            Header: "Unit Price", accessor: "unit_price", className: "text-center",
            Cell: (props: any) => {
                return (
                    <span>
                        {formatNumber(props?.cell?.row?.original?.unit_price)}
                    </span>
                )
            }
        },
        {
            Header: "Total Price", accessor: "total_price", className: "text-center",
            Cell: (props: any) => {
                return (
                    <span>
                        {formatNumber(props?.cell?.row?.original?.total_price)}
                    </span>
                )
            }
        },
        { Header: "Status", accessor: "status", className: "text-center" },
    ];

    const fetchDetailData = async () => {
        setIsLoadingFetch(true);
        try {
            const response: any = await axios.get(`${API_URL}/api/auction/auction/history-bid/${idParam}`)
            setDetailData(response.data?.data);

            setIsLoadingFetch(false);

        } catch (e) {
            setIsLoadingFetch(false);
            console.log(e)
        }
    }

    useEffect(() => {
        fetchDetailData();
    }, [])

    return (
        <>
            <div className={'mt-4'} >
                <>
                    <Row>
                        <Col>
                            <div className='d-flex justify-content-start mt-3' style={{ marginLeft: 30 }} data-kt-materialrequest-table-toolbar='base'>
                                <label className="form-label mb-3">ITEM PARAMETER</label>
                            </div>
                        </Col>
                    </Row>
                    <hr style={{ marginLeft: 30, marginRight: 30 }} />
                    {detailData?.length > 0 ? (
                        detailData?.map((item: any, index: any) => (
                            <div key={index}>
                                <Row>
                                    <Col>
                                        <div className='d-flex justify-content-start mt-3' style={{ marginLeft: 30 }} data-kt-materialrequest-table-toolbar='base'>
                                            <label className="form-label mb-3">Item {index + 1} - {item?.nama}</label>
                                        </div>
                                    </Col>
                                </Row>
                                <hr style={{ marginLeft: 30, marginRight: 30 }} />
                                <Row>
                                    <Col>
                                        <div className='d-flex justify-content-start mt-3' style={{ marginLeft: 30 }} data-kt-materialrequest-table-toolbar='base'>
                                            <label className="form-label mb-3">Quantity : {item?.kuantitas} | Unit Price : {item?.currency} {formatNumber(item?.unit_price)} | Total Price: {item?.currency} {formatNumber(item?.total_price)}</label>
                                        </div>
                                    </Col>
                                </Row>
                                <hr style={{ marginLeft: 30, marginRight: 30 }} />
                                <Row>
                                    <Col>
                                        <TableX
                                            key={key}
                                            data={item?.bid ?? []}
                                            meta={{
                                                perPage: item?.meta?.per_page || item?.bid?.length,
                                                currentPage: item?.meta?.current_page,
                                                total: item?.bid?.length,
                                            }}
                                            columns={tableColumns}
                                            isLoading={isLoadingFetch}
                                        />
                                    </Col>
                                </Row>

                                <hr style={{ marginLeft: 30, marginRight: 30 }} />
                            </div>
                        ))
                    ) : (
                        <></>
                    )
                    }

                </>
                <hr style={{ marginLeft: 30, marginRight: 30 }} />
            </div>

        </>
    );
};

export { RiwayatAuction };
