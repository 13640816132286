import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {EvaluationTabList} from './components/EvaluationIncomingList'
import {EvaluationIncomingDetail} from './components/EvaluationIncomingDetail'

export const EvaluationPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route path='/' element={<Navigate to={'./incoming'} />}></Route>
        <Route path='/incoming' element={<EvaluationTabList />}></Route>
        <Route path='/proses' element={<></>}></Route>
        <Route path={'/:uuid'} element={<EvaluationIncomingDetail />}></Route>
      </Route>
    </Routes>
  )
}
