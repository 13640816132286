import React, {useEffect, useState} from 'react'
import {Spinner} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {getMomentFromTimestamp} from '../../../../helpers/procurex'

/* eslint-disable jsx-a11y/anchor-is-valid */
const AsideToolbar = () => {
  const intl = useIntl()
  const PusherJS = require('pusher-js')
  const [isLoadingTime, setIsLoadingTime] = useState(false)
  // const [isLoadingTime, responseDataTime, responseErrorTime, getDataTime] = useGetServerTime()
  const [dateLabel, setDateLabel] = useState('')
  const [timeLabel, setTimelabel] = useState('')
  const [currentTimeStamp, setCurrentTimestamp] = useState(0)
  const [timezoneLabel, setTimezoneLabel] = useState('WIB')
  const initSocket = () => {
    let client = new PusherJS(process.env.REACT_APP_WEBSOCKET_APP_KEY, {
      wsHost: process.env.REACT_APP_WEBSOCKET_URL,
      // wsHost:'procurement.pupuk-indonesia.com',
      wsPort: process.env.REACT_APP_WEBSOCKET_PORT,
      cluster: process.env.REACT_APP_WEBSOCKET_CLUSTER,
      forceTLS: process.env.REACT_APP_WEBSOCKET_FORCETLS,
      encrypted: process.env.REACT_APP_WEBSOCKET_ENCRYPTED,
      disableStats: true,
      enabledTransports: ['ws', 'wss'],
      logToConsole: true,
    })
    const channel = client.subscribe('server-time')
    channel.bind('App\\Events\\ServerTimeEvent', function (data: any) {
      setCurrentTimestamp(data.message)
    })

    // if(Intl.DateTimeFormat().resolvedOptions().timeZone === "Asia/Jakarta") {
    //   setTimezoneLabel('WIB')
    // } else if(Intl.DateTimeFormat().resolvedOptions().timeZone === "Asia/Makassar") {
    //   setTimezoneLabel('WITA')
    // } else if(Intl.DateTimeFormat().resolvedOptions().timeZone === "Asia/Jayapura") {
    //   setTimezoneLabel('WIT')
    // }

  }

  useEffect(() => {
    initSocket()
  }, [])

  // useEffect(() => {
  //   if (!responseDataTime.timestamp) return
  //   // setCurrentTimestamp(responseDataTime.timestamp * 1000)
  // }, [responseDataTime])

  useEffect(() => {
    if (currentTimeStamp <= 0) return
    formatDateTime()
  }, [currentTimeStamp])
  const formatDateTime = () => {
    // const time = new Date(currentTimeStamp)
    // const time = new Date(getTimestampInClientTimeZone(currentTimeStamp))
    const time = getMomentFromTimestamp(currentTimeStamp)
    const _dateLabel =
      intl.formatMessage({ id: 'DAY.' + time.day() }) +
      ', ' +
      (time.date() < 10 ? '0' + time.date() : time.date()) +
      ' ' +
      intl.formatMessage({ id: 'MONTH.' + time.month() }) +
      ' ' +
      time.year();

    const _timeLabel =
      (time.hours() < 10 ? '0' + time.hours() : time.hours()) +
      ':' +
      (time.minutes() < 10 ? '0' + time.minutes() : time.minutes()) +
      ':' +
      (time.seconds() < 10 ? '0' + time.seconds() : time.seconds());
    // @ts-ignore
    setDateLabel(_dateLabel)
    setTimelabel(_timeLabel)
  }

  return (
    <>
      {/*begin::User*/}
      <div className='aside-user d-flex align-items-sm-center justify-content-center pt-5'>
        {/*begin::Wrapper*/}
        <div className='aside-user-info flex-row-fluid flex-wrap ms-5'>
          {/*begin::Section*/}
          <div className='d-flex'>
            {/*begin::Info*/}
            <div className='flex-grow-1 me-2 text-center'>
              {isLoadingTime && (
                <Spinner animation={'border'} color={'white'} style={{color: 'white'}} />
              )}
              {!isLoadingTime && (
                <>
                  <a href='#' className='text-white text-center text-hover-primary fs-1 fw-bold'>
                    {timeLabel} {timezoneLabel}
                  </a>

                  <span className='text-white fw-bold d-block fs-8'>{dateLabel}</span>
                </>
              )}

              {/*begin::Label*/}
              {/*<div className='d-flex align-items-center text-success fs-9'>*/}
              {/*  <span className='bullet bullet-dot bg-success me-1'></span>online*/}
              {/*</div>*/}
              {/*end::Label*/}
            </div>
            {/*end::Info*/}
          </div>
          {/*end::Section*/}
        </div>
        {/*end::Wrapper*/}
      </div>
      {/*end::User*/}

      {/*begin::Aside search*/}

      {/*end::Aside search*/}
    </>
  )
}

export {AsideToolbar}
