import { ID, KTCard, KTCardBody } from '../../../../../../_metronic/helpers'
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import TableXWrpper from "../../../../../components/shared/TableXWrapper";
import { ApiUrl } from '../../../../../config/Api'
import { getEnv } from "../../../../../../helpers/procurex";
import { useAuth } from "../../../../../../app/modules/auth";
import { Button, ButtonGroup, Col, Modal, Row } from "react-bootstrap";

const PPICTransactionStockviewList = () => {
    const { currentUser } = useAuth();

    const tablecolumns = [
        {
            Header: "TANGGAL",
            accessor: "tanggal",
            width: 150,
            Cell: ({ value }) => {
                if (!value) return '';
                const date = new Date(value);
                const day = date.getDate().toString().padStart(2, '0');
                const month = (date.getMonth() + 1).toString().padStart(2, '0');
                const year = date.getFullYear();
                return `${day}-${month}-${year}`;
            }
        },
        {
            Header: "STATUS",
            accessor: "status",
            width: 150,
        },
        {
            Header: "JENIS",
            accessor: "jenis",
            width: 150,
        },
        {
            Header: "PENERIMAAN PROGNOSAN",
            accessor: "penerimaan_prognosa",
            width: 150,
        },
        {
            Header: "PENERIMAAN REALISASI",
            accessor: "penerimaan_realisasi",
            width: 150,
        },
        {
            Header: "STOCK PROGNOSA",
            accessor: "stok_prognosa",
            width: 150,
        },
        {
            Header: "STOCK REALISASI",
            accessor: "stok_realisasi",
            width: 150,
        },
        {
            Header: "KONSUMSI PROGNOSA",
            accessor: "konsumsi_prognosa",
            width: 150,
        },
        {
            Header: "KONSUMSI REALISASI",
            accessor: "konsumsi_realisasi",
            width: 150,
        },
        {
            Header: "PRODUK",
            accessor: "produk",
            width: 150,
        },
        {
            Header: "TARGET",
            accessor: "target",
            width: 150,
        },
        {
            Header: "REALISASI KUMULATIF",
            accessor: "realisasi_kumulatif",
            width: 150,
        },
        {
            Header: "REALISASI HARIAN",
            accessor: "realisasi_harian",
            width: 150,
        },
        {
            Header: "PROGNOSA HARIAN",
            accessor: "prognosa_harian",
            width: 150,
        },
    ];
    const [formIndex, setFormIndex] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [isShowImportModal, setIsShowImportModal] = useState(false)

    const handleButtonClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    let company_code = currentUser.company_code;
    let role = currentUser.has_roles;
    const excludedRoles = ['admin_anper', 'DaanExp_Anper', 'Gudang_Bahan_Baku_Anper', 'Perencanaan_Pengadaan_Anper', 'Managerial_PI'];

    return (
        <>
            <KTCard className='card border mt-5'>
                <KTCardBody >
                    <TableXWrpper
                        title={"MR/SR Unit Kerja"}
                        key={formIndex}
                        readonly
                        urlIndex={getEnv("stockview") + `/ppic-transaction`}
                        columns={tablecolumns}
                    />
                </KTCardBody>
            </KTCard>
        </>
    )
}

export { PPICTransactionStockviewList }
