import {ID, KTCard, KTCardBody} from '../../../../../_metronic/helpers'
import React, {useState} from 'react'
import TableXWrpper from "../../../../components/shared/TableXWrapper";

const MediaList = () => {
	const [urlIndex, setUrlIndex] = useState(process.env.REACT_APP_API_URL + "/api/media/media");
	const tableColumns = [
		{Header: 'ID', accessor: 'id', width: 50, disableCreate: true, disableEdit: true, disableIndex: true},
		{Header: 'Mime', accessor: 'mime', width: 300},
		{Header: 'Size (bytes)', accessor: 'size_in_bytes', width: 100, className:'text-center'},
		{Header: 'File', accessor: 'url', Cell: (row:any)=>{
			return (
				<>
					<a href={row.cell.row.original.url} className="btn btn-xs btn-info" target={"_blank"} ><i className="fa fa-link"></i></a>
				</>
			)
			}, width: 100, className:'text-center'}
	]
	return (
		<>
			<KTCard>
				<KTCardBody className='py-4'>
					<TableXWrpper
						title={"News"}
						urlIndex={urlIndex}
						columns={tableColumns}
					/>
				</KTCardBody>
			</KTCard>
		</>
	)
}

export {MediaList}
