import React, { useEffect, useRef, useState } from "react";
import {getEnv} from "../../../../../../../../helpers/procurex";
import {useParams} from "react-router-dom";
import {Placeholder} from "rsuite";
import useRemoteFileAsObjectUrl from "../../../../../../../../hooks/useRemoteFileAsObjectUrl";
import { toastError, toastSuccess } from "../../../../../../../../_metronic/helpers";
import { Form, Formik } from "formik";
import { Modal, Spinner } from "react-bootstrap";
import FormikUploadFile from "../../../../../../../components/shared/form/FormikUploadFile";
import * as Yup from "yup";
import axios from 'axios'
import { useUploadMedia } from "../../../../../../../../hooks/MediaUploadHook";
import { toast } from "react-toastify";
import useGet from "../../../../../../../../hooks/useGet";
import { useIntl } from "react-intl";
import { useAxiosPost } from "../../../../../../../../hooks/useAxios";
import FormNotesBA from "../../FormNotesBA";
const TENDER_BASE_URL = getEnv("tender");


export const BeritaAcaraTab = ({proposalTender}: any) => {
  const { uuid: proposalTenderUuid } = useParams()
  const refUploadFile = useRef<any>(null)
  const { upload } = useUploadMedia()
  const [key, setKey] = useState();
  const [showNotes, setShowNotes] = useState(false);
  const isBADURDisabled = () => {
    return false;
  }

  const isBABidOpeningDisabled = () => {
    const disabledStatus = ["Draft","DUR","Penawaran"];
    return disabledStatus.includes(proposalTender?.status);
  }

  const [items,setItems] = useState([] as any);

  const [selectedItemIndex, setSelectedItemIndex] = useState<number|null>(0);
  const selectedItem = selectedItemIndex !== null ? items[selectedItemIndex] : null
  const { objectUrl, isLoading: isLoadingObjectUrl, setSourceUrl } = useRemoteFileAsObjectUrl(selectedItem?.url);
  const [{doGetDetail, data: objectData, isLoading: isLoadingGetDetail}] = useGet();
  const [{doGetDetail: doGetVendors, data: vendors, isLoading: isLoadingGetVendors}] = useGet();
  const [renderKey, setRenderKey] = useState(0)
  const defaultValue = {
    file: '',
    item: ''
  }
  const intl = useIntl()
  const [showModalReplace, setShowModalReplace] = useState(false)
  const [initialValues, setInitialValues] = useState<any>(defaultValue)
  const validationSchema = Yup.object({
    file: Yup.string().required('The field is required')
  })
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const replaceFile = async (values: any) => {
    setLoadingSubmit(true)
    try {
      const _url = initialValues?.item?.url || '';
      doGetDetail(_url, toast).then(async (res:any)=>{
        const urlDocx = res?.data?.url_docx;
        try { await axios.delete(urlDocx?.replace('/file2', '')) } catch {}

        if (typeof values?.file !== 'string') {
          let responseFile = await upload('25', values?.file)
          // values.file = responseFile.url
          const data = {
              url_docx: responseFile.url
          }
          const response = await axios.put(`${initialValues?.item?.url}`, data)
          toastSuccess(response?.data?.meta?.message)
          refUploadFile?.current?.resetForm()
          // @ts-ignore
          setKey(key + 1)
          setSelectedItemIndex(
            items.findIndex((item:any) => item.url === initialValues?.item?.url)
          )
          setShowModalReplace(false)
        }
      })
    } catch (error: any) {
      toastError(error?.response?.data?.meta?.message || 'Terjadi kesalahan')
    }
    setLoadingSubmit(false)
  }

  const setItemsData = () => {
    const tmp = [{
        title: "Bid Tab Nego",
        subTitle:"",
        url: `${TENDER_BASE_URL}/negotiation/generate-berita-acara/${proposalTender?.negotiation?.uuid}`,
        url_submit_ba_note: `${getEnv("tender")}/bid/generate-berita-acara/${proposalTender?.uuid}`,
        url_submit_pic: "",
        disabled: isBADURDisabled(),
        isShowPIC: false,
        contact: {}
      },
    ]
    const ba_nego_vendors = vendors?.vendors;
    if (Array.isArray(ba_nego_vendors)) {
      ba_nego_vendors?.every((vendor: any) => tmp.push({
        title: `BA Negosiasi Vendor ${vendor?.vendor.name}`,
        subTitle: '',
        url: `${TENDER_BASE_URL}/negotiation/generate-berita-acara-negosiasi-vendor/${proposalTender?.uuid}/${vendor?.vendor_id}`,
        url_submit_ba_note: `${getEnv("tender")}/negotiation/generate-berita-acara-negosiasi-vendor/${proposalTender?.uuid}/${vendor?.vendor_id}`,
        url_submit_pic: `${getEnv("tender")}/dur-vendor/contact/${vendor?.uuid}`,
        disabled: false,
        isShowPIC: true,
        contact: vendor?.contact
      }))
    }

    setItems(tmp)
  }

  const getData = () => {
    setItemsData()
    doGetVendors(`${TENDER_BASE_URL}/negotiation/${proposalTender?.negotiation?.uuid}`, toast)
  }

  useEffect(() => {
    // @ts-ignore
    if(objectData && objectData?.url_pdf){
      // @ts-ignore
      setSourceUrl(objectData?.url_pdf)
    }
  }, [objectData]);

  useEffect(() => {
    if(proposalTenderUuid){
      getData()
    }
  },[proposalTenderUuid])

  useEffect(() => {
    setItemsData()
  }, [vendors]);

  return (
    <div className={"row"}>
      <div className={"col-md-3"}>
        {items && items?.map((item:any, index:number) => (
          <div className={"mb-2 btn-group-vertical w-100"} key={"ba-tab-"+index}>
            <div className="btn-group" role="group" aria-label="Basic example">
            <button
              key={index}
              className={"btn btn-sm w-100"}
              style={{
                backgroundColor: `${selectedItemIndex === index ? "var(--bs-primary)" : "var(--bs-secondary)"}`,
                color: `${selectedItemIndex === index ? "white" : "black"}`,
                padding: "11px",
                border: "#333"
              }}
              disabled={item.url === null || isLoadingGetDetail || item?.disabled}
              onClick={() => {
                setSelectedItemIndex(index)
              }}
            >
              <div>{item?.title}</div>
              <div>{item?.subTitle}</div>
            </button>
              <button
                className={"btn btn-primary btn-sm"}
                style={{
                  backgroundColor: `${selectedItemIndex === index ? "var(--bs-primary)" : "var(--bs-secondary)"}`,
                  color: `${selectedItemIndex === index ? "white" : "black"}`,
                  padding: "11px",
                  border: "#333"
                }}
                onClick={() => {
                  setSelectedItemIndex(index)
                  setShowNotes(true)
              }}
              >
                <i
                  className={"fa fa-file-edit"}
                  style={{
                    color: `${selectedItemIndex === index ? "white" : "black"}`,
                  }}
                ></i>
              </button>
            </div>
            {/*<div className={"btn-group w-100"}>*/}
            {/*  /!*<button className={"btn btn-sm btn-danger"} type={"button"} onClick={()=>handleOnDelete()}>*!/*/}
            {/*  /!*  <i className={"fa fa-trash"}></i>*!/*/}
            {/*  /!*</button>*!/*/}
            {/*  <button className={"btn btn-sm btn-info"} onClick={()=>handleOnEdit(index)} disabled={isLoadingObjectUrl || isLoadingGetDetail || item?.disabled}>*/}
            {/*    {(isLoadingGetDetail || isLoadingObjectUrl) ? <span className='spinner-border spinner-border-sm me-1' /> : <i className={"fa fa-edit"}></i> }*/}
            {/*  </button>*/}
            {/*  <button className={"btn btn-sm btn-success"} onClick={()=>handleOnDownload(index)} disabled={isLoadingObjectUrl || isLoadingGetDetail|| item?.disabled}>*/}
            {/*    {(isLoadingGetDetail || isLoadingObjectUrl) ? <span className='spinner-border spinner-border-sm me-1' /> : <i className={"fa fa-download"}></i> }*/}
            {/*  </button>*/}
            {/*</div>*/}
            {/*<hr />*/}
          </div>
        ))}
        {isLoadingGetVendors && (
          <>
            <Placeholder.Graph height={40}/>
            <Placeholder.Graph height={40}/>
            <Placeholder.Graph height={40}/>
          </>
        )}
      </div>
      <div className={"col-md-9"} key={renderKey}>
        {(isLoadingObjectUrl || isLoadingGetDetail)
          ? <Placeholder.Graph height={700} active={isLoadingObjectUrl || isLoadingGetDetail} />
          : selectedItem && objectUrl
            ? <>
              <object width="100%" style={{
                height: "700px"
              }} data={objectUrl} type="application/pdf">
                {" "}
              </object>
            </>
            : <div className={"bg-secondary w-100"} style={{ "height": 700, "opacity": "0.6" }}></div>
        }
      </div>
      <Formik
        key={key}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={replaceFile}
        ref={refUploadFile}
      >
        {({ errors, touched, values, setFieldValue }) => (
          <>
            <Modal show={showModalReplace} onHide={()=>setShowModalReplace(false)} centered>
              <Form>
                <Modal.Body>
                  <div className='mb-10'>
                    <FormikUploadFile
                      label={intl.formatMessage({id:"Lampiran"})}
                      name='file'
                    />
                  </div>
                  <div
                    className='d-flex justify-content-end mb-3 gap-3'
                    data-kt-materialrequest-table-toolbar='base'
                  >
                    <button
                      onClick={()=>setShowModalReplace(false)}
                      className='btn btn-sm btn-danger'
                      type='button'
                      disabled={loadingSubmit}
                    >
                      <i className="fas fa-times" />
                      {intl.formatMessage({id:"Batal"})}
                    </button>
                    <button
                      className='btn btn-sm btn-primary'
                      type='submit'
                      disabled={loadingSubmit || isLoadingGetDetail || isLoadingObjectUrl}
                    >
                      {(isLoadingGetDetail || isLoadingObjectUrl || loadingSubmit) ? <span className='spinner-border spinner-border-sm me-1' /> : <i className={"fa fa-save"}></i> }
                      {intl.formatMessage({id:"Submit"})}
                    </button>
                  </div>
                </Modal.Body>
              </Form>
            </Modal>
          </>
        )}
      </Formik>
      <FormNotesBA
        show={showNotes}
        onClose={()=>setShowNotes(false)}
        currentBA={selectedItem}
        proposalTender={proposalTender}
        onSaveNotes={(values:any)=>{
          getData()
          setRenderKey(renderKey+1)
        }}
      />
    </div>
  );
};