import{ useState } from "react";
import TableXWrpper from "../../../components/shared/TableXWrapper";
import { KTCard, KTCardBody } from "../../../../_metronic/helpers";
import { useIntl } from "react-intl";
import { NavLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import SwalX from "../../../components/shared/SwalX";

const AuctionVendorList = () => {
  const urlIndex = process.env.REACT_APP_API_URL;

  const [key, setKey] = useState(0)

  const tableColumns = [
    {
      Header: 'Action',
      width: 200,
      Cell: (props: any) => (
        <>
          <div className='d-flex w-100 justify-content-center'>
            <NavLink to={'/auction/draft/' + props?.cell?.row?.original?.id} className='p-3'>
              <i className={'fa fa-eye'} />
            </NavLink>
          </div>
        </>
      ),
    },
    { Header: "No Auction", accessor: "no_auction", width: 250, className: "text-center" },
    { Header: "Nama", accessor: "nama", width: 250, className: "text-center" },
    { Header: "Qty", accessor: "kuantitas", width: 200, className: "text-center" },
    { Header: "Total Price", accessor: "total_price", width: 150, className: "text-center" },
    { Header: "Status", accessor: "status", width: 500, className: "text-center" },
  ];

  return (
    <>
      <KTCard>
        <KTCardBody className="py-4">
          <TableXWrpper
            key={key}
            readonly
            urlIndex={urlIndex + "/api/auction/as-vendor/auction"}
            columns={tableColumns}
          />
        </KTCardBody>
      </KTCard>
    </>
  );
};

export { AuctionVendorList };
