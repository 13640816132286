import { Field, Form, Formik } from 'formik'
import React, { useState, useRef, useEffect } from 'react'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom'
import SelectX from '../../../../../components/shared/form/SelectX'
import { ApiUrl } from '../../../../../config/Api'
import { getEnv } from "../../../../../../helpers/procurex";
import { InputNumber } from "rsuite";
import axios from 'axios'
import { useIntl } from "react-intl";
import SwalX from '../../../../../components/shared/SwalX'
import Swal from "sweetalert2";
import DatepickerX2 from "../../../../../components/shared/form/DatepickerX2";
import { useAuth } from "../../../../auth";

const CreateJenisPublikasi = () => {
    const { currentUser } = useAuth();

    const initialValues = {
        nama_jenis: ''
    }

    const intl = useIntl();
    const refFormik = React.useRef<any>();
    const [itemsMasterJenisPublikasi, setItemsMasterJenisPublikasi] = useState<any>([])
    const [itemLoading, setItemLoading] = useState(false)

    // const validationSchema = Yup.object({});
    const formSchemaValidation = Yup.object().shape({
        nama_jenis: Yup.string().required('Nama Jenis is required'),
    });

    const loadItem = () => {
        axios
            .get(getEnv("stockview") + `/jenis-publikasi`)
            .then((res) => {
                setItemsMasterJenisPublikasi(res.data.data)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setItemLoading(false)
            })
    }

    useEffect(() => {
        loadItem();
    }, [])

    const [formData, setFormData] = React.useState([]);

    // const [initialValues, setInitialvalues] = useState<any>(defaultValue)
    const [loading, setLoading] = useState(true)
    const [isLoading, setIsLoading] = useState(false);
    const [key, setKey] = useState(0)

    const [totalConsumption, setTotalConsumption] = useState(0);

    const handleOnTotalChange = (score: number) => {
        setTotalConsumption(score)
    }

    const [selectedMaterial, setSelectedMaterial] = useState(null);
    const [selectedCompanyCode, setSelectedCompanyCode] = useState(null);

    const handleMaterialSelect = (selectedValue: any) => {
        setSelectedMaterial(selectedValue);
    };

    const handleCompanySelect = (selectedValue: any) => {
        setSelectedCompanyCode(selectedValue);
    };
    const fileRef = useRef<HTMLInputElement | null>()

    const resetInputFile = () => {
        if (fileRef.current) {
            fileRef.current.value = ''
        }
    }

    const TableRow = ({ no, nama_jenis, onDelete, ...props }: any) => {
        return (
            <tr>
                <td className={"border text-center"}>{no}</td>
                <td className={"border"}>{nama_jenis}</td>
                <td className={"border text-center"}>
                    <button className={"btn btn-sm btn-secondary"} onClick={() => onDelete()}>
                        <i className={"fa fa-trash text-danger"}></i>
                    </button>
                </td>
            </tr>
        );
    };

    const [formindex, setFormIndex] = useState(0)
    const navigate = useNavigate();

    const handleBackClick = () => {
        if (formData.length !== 0) {
            handleBack();
        } else {
            navigate('/stockview/masterdata/jenis-publikasi');
        }
    };

    const handleAddRow = async (values: any) => {
        const newData = {
            nama_jenis: values.nama_jenis
        };

        setFormData([...formData, newData]);
        // refFormik.current?.resetForm();
        setFormIndex(formindex + 1);
        // @ts-ignore
    };

    const handleDeleteRow = async (index: number) => {
        const newData = formData.filter((item: any, i: number) => i !== index);
        // @ts-ignore
        setFormData(newData);
    };

    const handleSubmit = async () => {
        setLoading(true)
        try {
            const data = {
                ...formData
            };

            const response = await axios.post(getEnv("stockview") + `/jenis-publikasi`, data)

            if (response.status === 200 || response.status === 201) {
                toast.success(response?.status + ': ' + response?.data.meta.message, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                })
            }
            setFormIndex(formindex + 1)
        } catch (error: any) {
            console.error(error)
            toast.error(error?.data?.meta?.message || 'Terjadi kesalahan', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            })
        } finally {
            setLoading(false)
        }
    };

    const handleBack = async () => {
        Swal.fire({
            title: `Data Belum Tersimpan`,
            html: `Apakah Anda Yakin Ingin Keluar ?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: `Ya, Keluar!`,
            confirmButtonColor: "#999999",
            cancelButtonColor: "#258141",
            cancelButtonText: `Cancel`,
        }).then((result) => {
            if (result.isConfirmed) {
                navigate('/stockview/masterdata/jenis-publikasi')
            }
        })
    }

    let company_code_user = currentUser.company_code;
    let jenis_api = "list";

    return (
        <>
            <Formik
                key={formindex}
                innerRef={refFormik}
                initialValues={initialValues}
                onSubmit={handleAddRow}
                validationSchema={formSchemaValidation}
                enableReinitialize
                validateOnChange={true}
            >
                {({ errors, touched, values, setFieldValue }: any) => (
                    <Form>
                        <div className='d-flex justify-content-between mb-6'>
                            <div>
                                <button className='btn btn-secondary btn-sm fw-bold' onClick={handleBackClick}>
                                    Back To list
                                </button>
                            </div>
                            <div className='d-flex gap-4'>
                                <button className={"btn btn-primary btn-sm"} disabled={isLoading}
                                    onClick={() => handleSubmit()}>
                                    {isLoading && <i className={"fa fa-spinner fa-spin"}></i>}
                                    {!isLoading && <i className={"fa fa-save"}></i>}
                                    {intl.formatMessage({ id: "Simpan" })}
                                </button>
                            </div>
                        </div>

                        <div className='card my-5'>
                            <div className='card-header d-flex align-items-center'>
                                <b>FORM ADD JENIS PUBLIKASI</b>
                            </div>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='mb-6'>
                                        <label htmlFor='' className='form-label required'>
                                            Nama Jenis
                                        </label>
                                        <Field className='form-control' name="nama_jenis" />
                                        {(errors.nama_jenis && touched.nama_jenis) && (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{errors.nama_jenis}</div>
                                            </div>
                                        )}
                                    </div>
                                    <div className='d-flex justify-content-end gap-4'>
                                        <button type={"submit"} className={"btn btn-primary btn-sm fw-bold"}>
                                            {isLoading && <i className={"fa fa-spinner fa-spin"}></i>}
                                            {!isLoading && <i className={"fa fa-plus"}></i>}
                                            {intl.formatMessage({ id: "Tambah" })}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"col-md-12 mt-5"}>
                            <table className={"table table-bordered"}>
                                <thead>
                                    <tr>
                                        <th className={"border text-center"}><strong>No</strong></th>
                                        <th className={"border text-center"}><strong>Nama Jenis</strong>
                                        </th>
                                        <th className={"border text-center"}><strong>Aksi</strong></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {formData.length === 0 && (
                                        <tr>
                                            <td colSpan={3} className={"text-center"}>
                                                <i>Tidak ada data</i>
                                            </td>
                                        </tr>
                                    )}
                                    {formData.map((item: any, index: number) => {
                                        return (
                                            <TableRow
                                                key={"adm-" + index}
                                                no={index + 1}
                                                nama_jenis={item.nama_jenis}
                                                onDelete={() => handleDeleteRow(index)}
                                            />
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export { CreateJenisPublikasi }
