import axios from "axios";
import { getEnv, parseDBDateToJSFormat } from "../../../../../helpers/procurex";
import { useMutation, useQuery } from "react-query";
import ForumComponent from "../../../../components/shared/ForumComponent";
import React, { useState } from "react";
import DokumenPengadaanPage from "../proposal-tender/components/tahapan_proposal/DokumenPengadaanPage";
import { useIntl } from "react-intl";
import { Accordion, Modal } from "react-bootstrap";
import DokumenKlarifikasiComponent from "./DokumenKlarifikasiComponent";
import { useAuth } from "../../../auth";
import moment from "moment";
import { toast } from "react-toastify";
import PreviewAttachment from "../../../../components/PreviewAttachment";
import { Placeholder } from "rsuite";


const fetchAanwijzingForum = (payload:any) => {

}

const fetchStoreAanwijzingForum = (payload:any) => {
  return axios.post(`${getEnv('tender')}/aanwijzing-forum`,payload)
}
const fetchChatList = (aanwijzing_id:number) => {
  return axios.get(`${getEnv('tender')}/aanwijzing-forum?perPage=1000&filter[aanwijzing_id]=${aanwijzing_id}`)
}
const fetchAttachmentList = (aanwijzing_id:number) => {
  return axios.get(`${getEnv('tender')}/aanwijzing-attachment?perPage=1000&filter[aanwijzing_id]=${aanwijzing_id}`)

}
const ForumKlarifikasi = ({aanwijzing}:any) => {
  const [chatList, setChatList] = useState([])
  const [key, setKey] = useState(0)
  const {currentUser} = useAuth()
  const [showModalAttachment, setShowModalAttachment] = React.useState(false);
  const [attachmentList, setAttachmentList] = useState([] as any)
  const [previewAttachment, setPreviewAttachment] = useState(null)
  const [selectedAttachment, setSelectedAttachment] = useState(null)
  const [isSound, setIsSound] = useState(true);

  //region store data
  const onSuccessStore = (data:any) => {}
  const onErrorStore = (error:any) => {
    toast.error(error?.response?.data?.message)
  }
  const {mutate:mutateStore, isLoading:isLoadingStore} = useMutation("store-aanwijzing-forum",(payload)=>fetchStoreAanwijzingForum(payload),{
    onSuccess: onSuccessStore,
    onError: onErrorStore
  })
  const handleOnSend = (data:any) => {
    // @ts-ignore
    mutateStore({
      aanwijzing_id: aanwijzing.uuid,
      reply_to: data?.reply_to,
      aanwijzing_attachment_id: selectedAttachment?.uuid,
      comment: data.message
    })
    setSelectedAttachment(null)
  }
  //endregion

  //region get chat list
  const {isLoading} = useQuery("chat-list",()=>fetchChatList(aanwijzing.id),{
    refetchInterval: false,
    refetchOnWindowFocus: false,
    onSuccess: (data:any) => {
      const tmp = []
      data?.data?.data?.map((item:any) => {
        const payload = generatePayload(item)
        tmp.push(payload)
      })
      setChatList(tmp)
      setKey(key+1)
      initiateSocket()
    },
    onError: (error:any) => {
      console.log(error)
    }
  })

  function generatePayload(item:any){
    return {
      id: item?.id,
      type: item?.participant_type,
      from: `${item?.participant?.username} - ${item?.participant?.name}`,
      message: item?.comment,
      time: moment(parseDBDateToJSFormat(item?.created_at)).format("HH:mm"),
      date: moment(parseDBDateToJSFormat(item?.created_at)).format("DD MMMM YYYY"),
      isFromMe: currentUser?.id === item?.participant?.id,
      reply_to_message: item?.reply_to?.comment,
      reply_to_type: item?.reply_to?.participant_type,
      reply_to_from: item?.reply_to?.participant?.username,
      reply_to_id: item?.reply_to?.id,
      attachment_type: item?.rks_document?.type,
      attachment_url: item?.rks_document?.media_url,
      attachment_description: item?.rks_document?.description,
    }
  }
  //endregion

  //region websocket
  const PusherJS = require("pusher-js");
  const initiateSocket = () => {
    let client = new PusherJS(process.env.REACT_APP_WEBSOCKET_APP_KEY, {
      wsHost: process.env.REACT_APP_WEBSOCKET_URL,
      wsPort: process.env.REACT_APP_WEBSOCKET_PORT,
      cluster: process.env.REACT_APP_WEBSOCKET_CLUSTER,
      forceTLS: process.env.REACT_APP_WEBSOCKET_FORCETLS,
      encrypted: process.env.REACT_APP_WEBSOCKET_ENCRYPTED,
      disableStats: true,
      enabledTransports: ["ws", "wss"],
      logToConsole: true
    });
    const handleSound = (isFromMe, sound) => {
      if (!sound) return;

      let audioElement = null;
      if(!isFromMe){
        audioElement = document.getElementById('newMessageSound') as HTMLAudioElement;
      } else {
        audioElement = document.getElementById('sentMessageSound') as HTMLAudioElement;
      }
      audioElement.play();
    }

    const channel = client.subscribe("aanwijzing.forum."+aanwijzing.uuid);
    channel.bind("App\\Events\\AanwijzingForumSubmitEvent", function (data: any) {
      setChatList(prevChatList => {
        const payload = generatePayload(data.data)
        toast.success("New message from "+data.data.participant.username);
        handleSound(payload.isFromMe, isSound)
        return [...prevChatList, payload];
      });

    });
  };

  //endregion

  //region get attachment list
  const {isLoading:isLoadingAttachment, refetch: refetchAttachmentList} = useQuery("attachment-list",()=>fetchAttachmentList(aanwijzing.id),{
    refetchInterval: false,
    refetchOnWindowFocus: false,
    onSuccess: (data:any) => {
      setAttachmentList(data?.data?.data)
    },
    onError: (error:any) => {
      console.log(error)
    }
  })
  //endregion

  return (
    <>
      <div className={"mb-3 text-end d-none"}>
        <a href={"javascript:;"} onClick={() => {
          setIsSound(!isSound);
        }}>
          {isSound && (
            <i className={"fa fa-volume-up fs-2"}></i>
          )}
          {!isSound && (
            <i className={"fa fa-volume-mute fs-2"}></i>
          )}
        </a>
      </div>
      <ForumComponent
        key={key}
        chatList={chatList}
        onSend={handleOnSend}
        onAttach={() => {
          setSelectedAttachment(null);
          setShowModalAttachment(true);
          refetchAttachmentList();
        }}
        disabled={isLoading || isLoadingStore || aanwijzing.status !== "LIVE"}
        attachment={<>
          {selectedAttachment && (
            <>
              <div className={"p-3 border-start border-end border-bottom"}>
                <div className={"d-flex justify-content-between"}>
                  <div><span className={"text-muted"}>Reply To:</span></div>
                  <div>
                    <a href={"javascript:;"} onClick={() => {
                      setSelectedAttachment(null);
                    }}>
                      <i className={"fa fs-2 fa-times-circle text-danger"}></i>
                    </a>
                  </div>
                </div>
                <div className={"d-flex flex-row gap-3"}>
                  <div>
                    <a href={"javascript:;"} target={"_blank"} onClick={(e: any) => {
                      e.preventDefault();
                      setPreviewAttachment(selectedAttachment?.media_url);
                    }}>
                      <i className={"fa fa-file fa-2x"}></i>
                    </a>
                  </div>
                  <div>
                    <div><span className={"badge badge-primary"}>{selectedAttachment?.type}</span></div>
                    <div>{selectedAttachment?.description}</div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>}
        isLoading={isLoading || isLoadingStore}
      />
      <audio id="newMessageSound">
        <source src="/livechat.mp3" type="audio/mpeg" />
      </audio>
      <audio id="sentMessageSound">
        <source src="/sent.mp3" type="audio/mpeg" />
      </audio>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModalAttachment}
        onHide={() => setShowModalAttachment(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Select Attachment
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {isLoadingAttachment && (
              <>
                <Placeholder.Paragraph rows={10} />
              </>
            )}
            {attachmentList.map((item: any, index: number) => {
              return (
                <div key={"attachment-list-" + index} className={"p-3 rounded d-flex flex-row gap-3 mb-3"} style={{
                  border: selectedAttachment === item ? "3px solid #0e365b" : "1px solid #ccc",
                  position: "relative"
                }} onClick={() => {
                  setSelectedAttachment(item);
                }}>
                  <div>
                    <a href={"javascript:;"} target={"_blank"} onClick={(e: any) => {
                      e.preventDefault();
                      setPreviewAttachment(item?.media_url);
                    }}>
                      <i className={"fa fa-file fa-2x"}></i>
                    </a>
                  </div>
                  <div>
                    <div><span className={"badge badge-primary"}>{item?.type}</span></div>
                    <div>{item?.description}</div>
                  </div>
                  {selectedAttachment === item && (
                    <div style={{ position: "absolute", top: "5px", right: "5px", color: "green" }}>
                      <i className={"fa fa-check-circle fs-2 text-primary"}></i>
                    </div>
                  )}
                </div>
              );
            })}

          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className={"btn btn-danger btn-sm"} onClick={() => setShowModalAttachment(false)}>
            <i className={"fa fa-times"}></i>
            Cancel
          </button>
          <button className={"btn btn-primary btn-sm"} onClick={() => setShowModalAttachment(false)}>
            <i className={"fa fa-check"}></i>
            Select
          </button>
        </Modal.Footer>
      </Modal>
      <PreviewAttachment
        previewExt={previewAttachment}
        onHide={() => setPreviewAttachment(null)}
      />
    </>
  )
}


const ProsesTab = ({ source, proposalTender, aanwijzing }: any) => {
  const intl = useIntl();
  const [activeCollapse, setActiveCollapse] = useState(["0", "1", "2", "3", "4", "5"]);
  const [isExpandAll, setIsExpandAll] = useState(true);



  return (
    <>
      <Accordion
        activeKey={activeCollapse}
        defaultActiveKey={isExpandAll ? [] : ["0", "1", "2", "3", "4", "5"]}
        alwaysOpen={true}
        onSelect={(e: any) => {
          setActiveCollapse(e);
        }}
      >
        {source === "Tender" && (
          <Accordion.Item
            eventKey="0"
            className={"mb-4"}
            style={{
            }}
          >
            <Accordion.Header>
              {intl.formatMessage({ id: "Dokumen Pengadaan" })}*
            </Accordion.Header>
            <Accordion.Body>
              <DokumenPengadaanPage
                hideForm={true}
                hideTitle={true}
              />
            </Accordion.Body>
          </Accordion.Item>
        )}

        <Accordion.Item
          eventKey="1"
          className={"mb-4"}
          style={{
          }}
        >
          <Accordion.Header>
            {intl.formatMessage({ id: "Attachment" })}*
          </Accordion.Header>
          <Accordion.Body>
            <DokumenKlarifikasiComponent
              aanwijzing={aanwijzing}
              proposalTender={proposalTender}
            />
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item
          eventKey="2"
          className={"mb-4"}
        >
          <Accordion.Header className={"d-flex justify-content-between gap-3"}>
            <div>{intl.formatMessage({ id: "Forum Klarifikasi" })}*</div>

          </Accordion.Header>
          <Accordion.Body>
            <ForumKlarifikasi
              aanwijzing={aanwijzing}
            />
          </Accordion.Body>

        </Accordion.Item>
      </Accordion>
    </>
  )
}

export default ProsesTab;