import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";

import {AuctionList} from './list/AuctionList'
import { AuctionDraft } from "./list/components/details/AuctionDraft";
import { TabVendorAuction } from "./vendor/TabVendorAuction";
import { AuctionVendorList } from "./vendor/AuctionVendorList";

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Auction',
    path: '/auction/vendor',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const masterData = {
  title: 'Auction',
  path: '/auction/vendor',
  isSeparator: false,
  isActive: false,
}
const separator = {
  title: '',
  path: '',
  isSeparator: true,
  isActive: false,
}
const breadcrumbs: Array<PageLink> = [masterData, separator]
const AuctionVendorPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route index element={<><PageTitle breadcrumbs={breadcrumbs}>Auction</PageTitle><AuctionVendorList /></>}></Route>
        <Route element={<Outlet />}>
          <Route path={"/"} element={
            <>
              <PageTitle breadcrumbs={breadcrumbs}>Auction</PageTitle>
              <TabVendorAuction />
            </>
          }></Route>
        </Route>
      </Route>
    </Routes>
  )
}

export default AuctionVendorPage