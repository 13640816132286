import { Outlet, Route, Routes } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import React, { useState, useEffect, useRef } from 'react'
import { throttle } from '../../../../_metronic/assets/ts/_utils'
import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'

const masterData = {
    title: 'User Guide',
    path: '/stockview/user-guide',
    isSeparator: false,
    isActive: false,
}
const separator = {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
}
const breadcrumbs: Array<PageLink> = [masterData, separator]
const childBreadcrumbs: Array<PageLink> = [
    masterData,
    separator,
    {
        title: 'Stockview User Guide',
        path: '/stockview/user-guide',
        isSeparator: false,
        isActive: false,
    },
    separator,
]

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const UserGuideStockview = () => {
    const [numPages, setNumPages] = useState(null)
    const [pageNumber, setPageNumber] = useState(1)
    const [initialWidth, setInitialWidth] = useState<any>(null)
    const pdfWrapper = useRef<any>()

    const setPdfSize = () => {
        if (pdfWrapper && pdfWrapper.current) {
            setInitialWidth(pdfWrapper.current.getBoundingClientRect().width)
        }
    }

    useEffect(() => {
        window.addEventListener('resize', () => {
            throttle(3000, setPdfSize, 3000)
        })
        setPdfSize()
        return () => {
            window.removeEventListener('resize', () => {
                throttle(3000, setPdfSize, 3000)
            })
        }
    }, [])

    const previewExt = {
        value: '/pdf/User_Guide_STOCKVIEW.pdf',
        ext: 'pdf',
    }

    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    index
                    element={
                        <>
                            <PageTitle breadcrumbs={breadcrumbs}>User Guide Stockview</PageTitle>
                            <div className='card'>
                                <div className='card-body'>
                                    <div ref={pdfWrapper} className="pdf-wrapper">
                                        <object width='100%' height='700' data={previewExt.value} type='application/pdf'>
                                            {' '}
                                        </object>
                                        {(previewExt.ext === 'jpg' || previewExt.ext === 'png' || previewExt.ext === 'jpeg') && (
                                            <>
                                                <img className='w-100' src={previewExt.value} alt='image' />
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                ></Route>
            </Route>
        </Routes>
    )
}

export default UserGuideStockview
