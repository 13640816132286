import { useMutation, useQuery } from 'react-query'
import axios from 'axios'
import { Modal } from 'react-bootstrap'
import { Form, Formik } from 'formik'
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import * as Yup from 'yup'
import { FormikProps } from 'formik/dist/types'
import { toastError, toastSuccess } from '../../../../_metronic/helpers'
import { getImportMasterByCode, storeImport } from './_requests'
import { downloadFile } from './_utils'

export interface ImportExcelMainModalProps {
  masterCode: string;
  show: boolean;
  onHide: Function;
  additionalPayload?: object;
  onInvalidImport?: Function;
}

export interface ImportExcelMainModalRef {
  formikRef: React.MutableRefObject<FormikProps<any> | null>;
}

export const ImportExcelMainModal = forwardRef((
  { masterCode, show, onHide, additionalPayload, onInvalidImport }: ImportExcelMainModalProps,
  ref: React.Ref<ImportExcelMainModalRef>
) => {
  const [isShowModal, setIsShowModal] = useState(show)
  const fileRef = useRef<HTMLInputElement | null>()
  const formikRef = useRef<FormikProps<any> | null>()

  const { data: importMaster, isLoading: isLoadingImportMaster } = useQuery(
    ["get-import-master", masterCode],
    () => getImportMasterByCode(masterCode),
    { enabled: isShowModal }
  );

  const { mutate: submitImport, isLoading: isLoadingSubmitImport } = useMutation({
    mutationFn: (values: any) => storeImport({
      ...values,
      ...(additionalPayload || {}),
      master_code: masterCode,
    }),
    onSuccess: (response: any) => {
      onHide && onHide()
      toastSuccess(response.data.meta.message)
    },
    onError: (error: any) => {
      console.error(error)

      if (!axios.isAxiosError(error)) {
        return
      }

      if (error.response.status === 422 && error.response?.data?.data?.status === 'validation_failed' && onInvalidImport) {
        onInvalidImport(error.response.data.data)
      } else {
        toastError(error.response.data?.meta?.message || 'Terjadi kesalahan')
      }
    },
  })

  const onHideHandler = () => {
    setIsShowModal(false)

    onHide && onHide();
  }

  const resetInputFile = () => {
    if (fileRef.current) {
      fileRef.current.value = ''
    }
  }

  useImperativeHandle(ref, () => ({
    formikRef,
    resetForm: () => {
      formikRef.current?.resetForm()
    },
  }), [])

  useEffect(() => {
    setIsShowModal(show)
  }, [show])


  return (
    <Modal show={isShowModal} onHide={() => onHideHandler()} size="lg">
      <Formik
        innerRef={formikRef}
        initialValues={{
          file: ''
        }}
        validationSchema={Yup.object().shape({
          file: Yup.mixed().required('File is required')
        })}
        onSubmit={values => submitImport(values)}
        onReset={() => resetInputFile()}
      >
        {({ touched, errors, setFieldValue }) => (
          <Form>
            <Modal.Header closeButton>
              <Modal.Title>Import Excel</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div>
                <div className="input-group mb-3">
                  <input
                    type="file"
                    name="file"
                    className="form-control"
                    ref={fileRef}
                    onChange={event => setFieldValue('file', event.target.files[0])}
                  />

                  <button type="button" onClick={() => downloadFile(importMaster?.data?.data?.template_url)} className="btn btn-primary" disabled={isLoadingImportMaster}>
                    {isLoadingImportMaster && <i className="fa fa-spinner fa-spin"></i>}
                    {!isLoadingImportMaster && <i className="fa fa-download" />}
                    {' '}Download Template
                  </button>
                </div>
                <small className="form-text">
                  Pastikan format file sesuai dengan template.
                </small>
              </div>
              {touched.file && errors.file && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {typeof errors.file === 'string' && (
                      <span role="alert">{errors.file}</span>
                    )}
                    {Array.isArray(errors.file) && errors.file.map(
                      (item: any) => <span role="alert">{item}</span>
                    )}
                  </div>
                </div>
              )}
            </Modal.Body>

            <Modal.Footer>
              <button type="button" className={"btn btn-secondary"} onClick={onHideHandler}>
                Tutup
              </button>

              <button className="btn btn-primary" type="submit" disabled={isLoadingSubmitImport}>
                {isLoadingSubmitImport && <span className="spinner-border spinner-border-sm me-1" />}
                Import
              </button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
})