import {useEffect, useState} from 'react'
import Select from 'react-select'

interface Props {
	options?: Array<any>
	defaultValue?: any,
	onChange?: Function
	onValue?: boolean
	isDisabled?: boolean
	border?: boolean
	borderRadius?: any
	placeholder?: string
}

export function getSelectStylesControl(baseStyles: any & {label?: string}, props?: any, border?: boolean, borderRadius?: any) {
  return {
    ...baseStyles,
    border: border === false ? 'none' : '1px lightgray solid',
    borderRadius: borderRadius ? borderRadius : '4px',
    width: '100%',
    backgroundColor: props?.isDisabled ? 'var(--kt-input-disabled-bg)' : 'transparent',
    padding: '0.2rem',
  }
}

const SelectX = ({options, defaultValue, onChange, onValue, isDisabled, border, borderRadius, placeholder}: Props) => {
	let [value, setValue] = useState('')
	const [indexKey,setIndexKey] = useState(1)
	const [initialValue, setInitialValue] = useState(null);

	useEffect(() => {
		if (defaultValue) {
			// @ts-ignore
			if(typeof defaultValue === 'string' && options.length > 0){
				const val = options?.filter((opt: any) => opt.value === defaultValue)
				// @ts-ignore
				setInitialValue(val[0])
				setIndexKey(indexKey+1)
			} else {
				const val = options?.filter((opt: any) => opt.value.id === defaultValue?.id)
				// @ts-ignore
				if (val?.length > 0) {
					// @ts-ignore
					setInitialValue(val[0])
					setIndexKey(indexKey+1)
				}
			}

		} else {
			setInitialValue(null)
			setIndexKey(indexKey+1)
		}
	}, [defaultValue])

	const handleChange = (e: any) => {
		setValue(e)
		// @ts-ignore
		if (onValue && onChange) {
			onChange(e)
		} else if(!onValue && onChange){
			onChange(e?.value)
		}
		// return false
	}

	// @ts-ignore
	return (
		<>
			<Select
				key={"select-key-"+indexKey}
				styles={{
					control: (baseStyles, props) => getSelectStylesControl(baseStyles, props, border, borderRadius),
				}}
				options={options}
				defaultValue={initialValue}
				onChange={(e: any) => handleChange(e)}
				isDisabled={isDisabled}
				isClearable={true}
				placeholder={placeholder}
			/>
		</>
	)
}

export default SelectX
