import axios from "axios"
import * as Yup from 'yup'
import FormikUploadFile from "../../../../../components/shared/form/FormikUploadFile"

import { ApiUrl } from "../../../../../config/Api"
import { useState } from "react"
import { useUploadMedia } from "../../../../../../hooks/MediaUploadHook"
import { Field, Form, Formik } from "formik"
import { Col, Modal, Row } from "react-bootstrap"
import { MetronicErrorMessage, toastError, toastSuccess } from "../../../../../../_metronic/helpers"

const validationSchema = Yup.object({
  no_po: Yup.string().required('The field is required').matches(/^[0-9]+$/, 'Must be only digits'),
  attachment: Yup.string().nullable().required('The field is required'),
  notes: Yup.string().nullable().required('The field is required')
})

const FormClosePR = ({show,closeModal,selectedRows, onSuccessClose}:any) => {
  const {upload} = useUploadMedia()
  const [isLoading, setIsLoading] = useState(false)

  const initialValues = {
    no_po: '',
    attachment: '',
    notes: ''
  }

  const handleSubmit = async (values: any) => {
    try {
      setIsLoading(true)
      let data = {...values}

      if (typeof data.attachment !== 'string') {
        let responseFile = await upload('25', data.attachment)
        data.attachment = responseFile.url
      }

      let pr_items: string[] = []
      selectedRows?.map((row: any) => {
        pr_items.push(row?.original?.uuid)
      })

      const payloads = {
        closed_pr_uuid: pr_items,
        closed_pr_reason: data?.notes,
        closed_pr_media_url: data?.attachment,
        closed_pr_po_number_in_sap: data?.no_po
      }

      const response = await axios.put(`${ApiUrl}/api/tender/purchase-requisition-item-tender/closed`, payloads)

      toastSuccess(response?.data?.meta?.message || 'Berhasil close PR')
      closeModal(false)
      onSuccessClose()

    } catch (error: any) {
        toastError(error?.response?.data?.meta?.message || 'Terjadi kesalahan')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
    <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ errors, touched, values, handleSubmit }) => (
          <Form>
            <>
            <Modal show={show} size={"lg"}>
              <Modal.Header closeButton onHide={()=>closeModal(false)}>
                <Modal.Title>Close PR</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className={"table-responsive"} style={{
                  maxHeight: "500px",
                }}>
                  <table className='table table-bordered table-hover table-striped'>
                    <thead>
                    <tr>
                      <td>No</td>
                      <td>Nomor SAP</td>
                      <td>Nomor PR</td>
                      <td>Short Text</td>
                      <td className={'text-center'}>Purchasing Group</td>
                    </tr>
                    </thead>
                    <tbody>
                    {selectedRows?.map((row: any, index: number) => {
                      return (
                        <tr key={'reject-dispo-' + row?.original?.id}>
                          <td>{index + 1}</td>
                          <td>{row?.original?.no}</td>
                          <td>{row?.original?.no}</td>
                          <td>{row?.original?.short_text}</td>
                          <td className={'text-center'}>{row?.original?.purchasing_group_id}</td>
                        </tr>
                      )
                    })}
                    </tbody>
                  </table>
                </div>
                <Row>
                  <Col lg='6'>
                    <div className="my-10">
                      <label className="form-label mb-3 required" htmlFor="note">
                        No PO
                      </label>
                      <Field
                        name={"no_po"}
                        className={"form-control"}
                      />
                      <MetronicErrorMessage name='no_po' />
                    </div>
                  </Col>
                  <Col lg='6'>
                    <div className="my-10">
                      <FormikUploadFile
                        mediaTypeId='20'
                        label={"Lampiran Close PR"}
                        name='attachment'
                      />
                    </div>
                  </Col>
                </Row>
                <div className="">
                  <label className="form-label mb-3 required" htmlFor="note">
                    Catatan close PR
                  </label>
                  <Field
                    as={"textarea"}
                    name={"notes"}
                    className={"form-control"}
                  />
                  <MetronicErrorMessage name='notes' />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type='button'
                  onClick={() => {
                    closeModal(false)
                  }}
                  disabled={isLoading}
                  className='btn btn-danger'>
                  <i className="fa fa-times"></i>
                  Cancel
                </button>
                <button
                  type='button'
                  onClick={() => {
                    handleSubmit()
                  }}
                  disabled={isLoading}
                  className='btn btn-primary'>
                  {isLoading && <i className='fa fa-spinner fa-spin'></i>}
                  {!isLoading && <i className="fa fa-save"></i>}
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
            </>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default FormClosePR