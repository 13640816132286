import React, { useEffect, useState } from "react";
import { useGetJadwalTender, usePostJadwalTender } from "../../../admin/hooks/proposalTenderHook";
import "rsuite/dist/rsuite-no-reset.min.css";
import { parseDBDateToJSFormat } from "../../../../../../helpers/procurex";
import DateRangePickerX from "../../../../../components/shared/form/DateRangePickerX";
import { useIntl } from "react-intl";

const RowJadwalTender = ({ label, name, type, selectedData, proposalTender, ...props }: any) => {
  const [isSaveDisabled,setIsSaveDisabled] = React.useState<boolean>(false);
  const [index,setIndex] = useState(0)
  // @ts-ignore
  const [dateJadwal,setDateJadwal] = useState<[Date, Date]>([]);
  const [initialDateJadwal,setInitialDateJadwal] = useState<[Date, Date]>([new Date(), new Date()]);

  useEffect(()=>{
    selectedData?.data?.map((item:any)=>{
      if(item.description === type){
        // @ts-ignore
        setDateJadwal([parseDBDateToJSFormat(item.start_at),parseDBDateToJSFormat(item.end_at)])
        setInitialDateJadwal([parseDBDateToJSFormat(item.start_at),parseDBDateToJSFormat(item.end_at)])
        setIndex(index+1)
        setIsSaveDisabled(true)
      }
    })
  },[selectedData])



  useEffect(()=>{
    if(dateJadwal && dateJadwal.length === 2) {
      // @ts-ignore
      if (dateJadwal[0].getTime() === initialDateJadwal[0].getTime() && dateJadwal[1].getTime() === initialDateJadwal[1].getTime()) {
        setIsSaveDisabled(true)
      } else {
        setIsSaveDisabled(false)
      }
    }
  },[dateJadwal])

  return (
    <tr key={index}>
      <td width={100}>{label} *</td>
      <td width={100}>
        <DateRangePickerX defaultValue={dateJadwal} disabled={true} onChange={(e:any)=>{
          setDateJadwal(e)
          setIsSaveDisabled(false)
        }} />
      </td>
    </tr>
  )
}
interface IProps {
  proposalTender?: any
}

const JadwalTenderPage = ({proposalTender}:IProps) => {
  const [{ doGetJadwal, isLoading, isError, data, isSuccess }]= useGetJadwalTender();
  const intl = useIntl();
  const [jadwalProsesTender, setJadwalProsesTender] = React.useState<any>([
    { label: intl.formatMessage({id:"Pengumuman"}), name: "pengumuman", type: "Pengumuman" },
    { label: intl.formatMessage({id:"Registrasi"}), name: "registrasi", type: "Registrasi" },
    { label: intl.formatMessage({id:"Aanwijzing"}), name: "aanwijzing", type: "Aanwijzing" },
    { label: intl.formatMessage({id:"Penawaran Teknikal"}), name: "penawaran_teknikal", type: "Penawaran Teknikal" },
    { label: intl.formatMessage({id:"Penawaran Komersil"}), name: "penawaran_komersil", type: "Penawaran Komersil" },
    { label: intl.formatMessage({id:"Penawaran Teknikal & Komersil"}), name: "penawaran_teknikal_komersil", type: "Penawaran" },
  ]);
  const [jadwalPQ, setJadwalPQ] = React.useState<any>([
    { label: "Pra Qualification", name: "pra_qualification", type: "Pra Qualification" },
  ]);

  const genereateJadwal = () => {
    let temp = jadwalProsesTender;
    // Kondisional untuk menampilkan jadwal tender sesuai metode aanwijzing
    if (proposalTender.metode_aanwijzing === "Tanpa Aanwijzing") {
      temp = temp.filter((v: any) => v.name !== "aanwijzing");
    }

    // Kondisional untuk menampilkan jadwal tender sesuai metode penyampaian
    if (proposalTender.metode_penyampaian === "1 Sampul" || proposalTender.metode_penyampaian === "2 Sampul") {
      temp = temp.filter((v: any) => v.name !== "penawaran_teknikal");
      temp = temp.filter((v: any) => v.name !== "penawaran_komersil");
    } else {
      temp = temp.filter((v: any) => v.name !== "penawaran_teknikal_komersil");
    }
    setJadwalProsesTender(temp);
  };

  const isShowJadwalPQ = () => {
    if(proposalTender?.praqualification && proposalTender?.status === "Pra Qualification"){
      return true;
    }
    return false;
  }


  useEffect(() => {
    if (proposalTender?.id) {
      doGetJadwal(proposalTender?.id);
      genereateJadwal();
    }
  }, [proposalTender]);


  return (
    <>
      <div className={"card"}>
        <div className={"card-body"}>
          <div className={"d-flex justify-content-between"}>
            <div><h3>{intl.formatMessage({id:"Jadwal Tender"})}</h3></div>

          </div>
          <hr />
          {/*<div className={"alert alert-warning"}>*/}
          {/*  <strong className={"text-dark"}>Information</strong>*/}
            {/*<p className={'text-dark'}>*/}
            {/*  Jika menggunakan metode Pra Qualification, maka jadwal tender yang bisa diatur <strong>hanya Pra Qualification</strong>.<br/>*/}
            {/*  Konfigurasi jadwal tender yang lain menunggu proses Pra Qualification selesai(s/d selesai <strong>Evaluasi PQ</strong>)*/}
            {/*</p>*/}
          {/*</div>*/}
                <table className={"table table-bordered"}>
                  <thead>
                  <tr>
                    <th><strong>{intl.formatMessage({id:"Proses"})}</strong></th>
                    <th><strong>{intl.formatMessage({id:"Jadwal"})}</strong></th>
                  </tr>
                  </thead>
                  <tbody>
                  {!isShowJadwalPQ() && (
                    <>
                      {jadwalProsesTender.map((v:any)=> {
                        return (
                          <RowJadwalTender key={'proses-'+v.name} type={v.type} label={v.label} name={v.name} proposalTender={proposalTender} selectedData={data} />
                        )
                      })}
                    </>
                  )}
                  {isShowJadwalPQ() && (
                    <>
                      {jadwalPQ.map((v:any)=> {
                        return (
                          <RowJadwalTender key={'proses-'+v.name} type={v.type} label={v.label} name={v.name} proposalTender={proposalTender} selectedData={data} />
                        )
                      })}
                    </>
                  )}


                  </tbody>
                </table>

        </div>
      </div>
    </>
  )
}

export default JadwalTenderPage