import React, { useEffect, useMemo, useRef, useState } from "react";
import {getEnv} from "../../../../../../../helpers/procurex";
import {useParams} from "react-router-dom";
import {Placeholder} from "rsuite";
import useRemoteFileAsObjectUrl from "../../../../../../../hooks/useRemoteFileAsObjectUrl";
import { toastError, toastSuccess } from "../../../../../../../_metronic/helpers";
import { Form, Formik } from "formik";
import { Modal } from "react-bootstrap";
import FormikUploadFile from "../../../../../../components/shared/form/FormikUploadFile";
import * as Yup from "yup";
import { ApiUrl } from "../../../../../../config/Api";
import axios from 'axios'
import { useUploadMedia } from "../../../../../../../hooks/MediaUploadHook";
import { toast } from "react-toastify";
import useGet from "../../../../../../../hooks/useGet";
import formikUploadFile from "../../../../../../components/shared/form/FormikUploadFile";
import { useIntl } from "react-intl";
const TENDER_BASE_URL = getEnv("tender");

export const BeritaAcaraTab = ({proposalTender}: any) => {
  const { uuid: proposalTenderUuid } = useParams()
  const refUploadFile = useRef<any>(null)
  const { upload } = useUploadMedia()
  const [key, setKey] = useState();

  const isBADURDisabled = () => {
    return false;
  }

  const isBABidOpeningDisabled = () => {
    const disabledStatus = ["Draft","DUR","Penawaran"];
    return disabledStatus.includes(proposalTender?.status);
  }

  const isBAPaktaDURDisabled = () => {
    const disabledStatus = ["Draft","DUR"];
    return disabledStatus.includes(proposalTender?.status);
  }

  const [items,setItems] = useState([] as any);
  const [approvalSchema, setApprovalSchema] = React.useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState<number|null>(null);
  const selectedItem = selectedItemIndex !== null ? items[selectedItemIndex] : null
  const { objectUrl, isLoading: isLoadingObjectUrl, setSourceUrl } = useRemoteFileAsObjectUrl(selectedItem?.url);
  const [{doGetDetail, data: objectData, isLoading: isLoadingGetDetail}] = useGet();
  const defaultValue = {
    file: '',
    item: ''
  }
  const intl = useIntl()
  const [showModalReplace, setShowModalReplace] = useState(false)
  const [initialValues, setInitialValues] = useState<any>(defaultValue)
  const validationSchema = Yup.object({
    file: Yup.string().required('The field is required')
  })
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const handleOnEdit = (index:number) => {
    setInitialValues({
      file: '',
      item: items[index]
    })
    setShowModalReplace(true)
  }

  const handleOnDownload = (index:number) => {
    const _url = items[index]?.url || '';
    doGetDetail(_url, toast).then((res:any)=>{
      const urlDocx = res?.data?.url_docx;
      fetch(urlDocx)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${items[index]?.title}.docx`)
          if (document.body) {
            document.body.appendChild(link)
            link.click()
            link.parentNode?.removeChild(link)
            toastSuccess('Berhasil mengunduh file')
          }
        })
        .catch((error) => {
          console.error('Gagal mengunduh file:', error)
          toastError('Gagal mengunduh file')
        })
    })

  }

  const replaceFile = async (values: any) => {
    setLoadingSubmit(true)
    try {
      const _url = initialValues?.item?.url || '';
      doGetDetail(_url, toast).then(async (res:any)=>{
        const urlDocx = res?.data?.url_docx;
        try { await axios.delete(urlDocx?.replace('/file2', '')) } catch {}

        if (typeof values?.file !== 'string') {
          let responseFile = await upload('25', values?.file)
          // values.file = responseFile.url
          const data = {
              url_docx: responseFile.url
          }
          const response = await axios.put(`${initialValues?.item?.url}`, data)
          toastSuccess(response?.data?.meta?.message)
          refUploadFile?.current?.resetForm()
          // @ts-ignore
          setKey(key + 1)
          setSelectedItemIndex(
            items.findIndex((item:any) => item.url === initialValues?.item?.url)
          )
          setShowModalReplace(false)
        }
      })
    } catch (error: any) {
      toastError(error?.response?.data?.meta?.message || 'Terjadi kesalahan')
    }
    setLoadingSubmit(false)
  }

  const getDetail = () => {
    const _url = selectedItem?.url || '';
    doGetDetail(_url, toast).then((res:any)=>{
    })
  }

  const getApprovalSchema = async () => {
    axios.get(getEnv("approval") + "/routing-approval-config/resource", {
      params: {
        company_id: proposalTender?.company?.id,
        source: "TENDER",
        code: proposalTender?.routing_approval_config_code
      }
    }).then((res) => {
      const hasDirektur = res.data.data[0].details.some((item: any) => item.jabatan === "Direktur")
      setApprovalSchema(hasDirektur);
    });
  }

  const setItemsData = () => {
    let tmp = [
      {"title": "BA DUR", "subTitle":"", "url": `${TENDER_BASE_URL}/dur/generate-berita-acara/${proposalTenderUuid}`, disabled: isBADURDisabled()},
      {"title": "BA Bid Opening", "subTitle":"", "url": `${TENDER_BASE_URL}/bid/generate-berita-acara/${proposalTenderUuid}`, disabled: isBABidOpeningDisabled()},
      {"title": "Bid Tab Penawaran", "subTitle":"", "url": `${TENDER_BASE_URL}/evaluation/quotation/generate-berita-acara/${proposalTenderUuid}`, disabled: isBABidOpeningDisabled()},
    ]

    if (proposalTender?.company_code === 'C000') {
      tmp.push(
        {"title": "Pakta Integritas Dur", "subTitle":"", "url": `${TENDER_BASE_URL}/dur/generate-pakta-dur/${proposalTenderUuid}`, disabled: isBAPaktaDURDisabled()}
      )
    }

    setItems(tmp)
  }

  useEffect(() => {
    // @ts-ignore
    if(objectData && objectData?.url_pdf){
      // @ts-ignore
      setSourceUrl(objectData?.url_pdf)
    }
  }, [objectData]);

  useEffect(() => {
    if(proposalTenderUuid){
      setItemsData()
    }
  },[proposalTenderUuid, approvalSchema])

  useEffect(() => {
    getApprovalSchema()
    setSelectedItemIndex(0)
  }, []);



  return (
    <div className={"row"}>
      <div className={"col-md-3"}>
        {items && items?.map((item:any, index:number) => (
          <div className={"mb-2 btn-group-vertical w-100"}>
            <button
              key={index}
              className={"btn btn-sm w-100"}
              style={{
                backgroundColor: `${selectedItemIndex === index ? "var(--bs-primary)" : "var(--bs-secondary)"}`,
                color: `${selectedItemIndex === index ? "white" : "black"}`,
                padding: "11px",
                border: "#333"
              }}
              disabled={item.url === null || item?.disabled}
              onClick={() => setSelectedItemIndex(index)}
            >
              <div>{item?.title}</div>
              <div>{item?.subTitle}</div>
            </button>
            {/*<div className={"btn-group w-100"}>*/}
            {/*  /!*<button className={"btn btn-sm btn-danger"} type={"button"} onClick={()=>handleOnDelete()}>*!/*/}
            {/*  /!*  <i className={"fa fa-trash"}></i>*!/*/}
            {/*  /!*</button>*!/*/}
            {/*  <button className={"btn btn-sm btn-info"} onClick={()=>handleOnEdit(index)} disabled={isLoadingObjectUrl || isLoadingGetDetail || item?.disabled}>*/}
            {/*    {(isLoadingGetDetail || isLoadingObjectUrl) ? <span className='spinner-border spinner-border-sm me-1' /> : <i className={"fa fa-edit"}></i> }*/}
            {/*  </button>*/}
            {/*  <button className={"btn btn-sm btn-success"} onClick={()=>handleOnDownload(index)} disabled={isLoadingObjectUrl || isLoadingGetDetail|| item?.disabled}>*/}
            {/*    {(isLoadingGetDetail || isLoadingObjectUrl) ? <span className='spinner-border spinner-border-sm me-1' /> : <i className={"fa fa-download"}></i> }*/}
            {/*  </button>*/}
            {/*</div>*/}
            {/*<hr />*/}
          </div>
        ))}
      </div>
      <div className={"col-md-9"}>
        {(isLoadingObjectUrl || isLoadingGetDetail)
          ? <Placeholder.Graph height={700} active={isLoadingObjectUrl || isLoadingGetDetail} />
          : selectedItem && objectUrl
            ? <>
              <object width="100%" style={{
                height: "700px"
              }} data={objectUrl} type="application/pdf">
                {" "}
              </object>
            </>
            : <div className={"bg-secondary w-100"} style={{ "height": 700, "opacity": "0.6" }}></div>
        }
      </div>
      <Formik
        key={key}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={replaceFile}
        ref={refUploadFile}
      >
        {({ errors, touched, values, setFieldValue }) => (
          <>
            <Modal show={showModalReplace} onHide={()=>setShowModalReplace(false)} centered>
              <Form>
                <Modal.Body>
                  <div className='mb-10'>
                    <FormikUploadFile
                      label={intl.formatMessage({id:"Lampiran"})}
                      name='file'
                    />
                  </div>
                  <div
                    className='d-flex justify-content-end mb-3 gap-3'
                    data-kt-materialrequest-table-toolbar='base'
                  >
                    <button
                      onClick={()=>setShowModalReplace(false)}
                      className='btn btn-sm btn-danger'
                      type='button'
                      disabled={loadingSubmit}
                    >
                      <i className="fas fa-times" />
                      {intl.formatMessage({id:"Batal"})}
                    </button>
                    <button
                      className='btn btn-sm btn-primary'
                      type='submit'
                      disabled={loadingSubmit || isLoadingGetDetail || isLoadingObjectUrl}
                    >
                      {(isLoadingGetDetail || isLoadingObjectUrl || loadingSubmit) ? <span className='spinner-border spinner-border-sm me-1' /> : <i className={"fa fa-save"}></i> }
                      {intl.formatMessage({id:"Submit"})}
                    </button>
                  </div>
                </Modal.Body>
              </Form>
            </Modal>
          </>
        )}
      </Formik>
    </div>
  );
};