import React, { useCallback, useEffect, useState } from "react";
import { useAxiosGet } from "../../../../../../../../hooks/useAxios";
import { getEnv, parseNumberToCurrency, subtractNumber,formatNumberToDecimal } from "../../../../../../../../helpers/procurex";
import { useIntl } from "react-intl";
import { Placeholder } from "rsuite";

const StatusBadgePassedFailed = ({ status }: any) => {
  return (
    <span
      className={`badge ${
        status === "PASSED" ? "bg-success" : "bg-danger"
      } text-white`}
    >
      {status}
    </span>
  );
};

const HasilEvaluasi = ({ proposalTender }: any) => {
  const [dataItems, setDataItems] = useState([] as any);
  const [{ doSubmit, isLoading }] = useAxiosGet(
    getEnv("tender") + `/evaluation/summary/${proposalTender?.uuid}`
  );
  const intl = useIntl();

  const getItems = useCallback(() => {
    const newUrl = getEnv("tender") + `/dur-vendor?filter[proposal_tender_id]=${proposalTender?.id}&filter[status_tender]=NEGOTIATION,NEGOTIATION_PROCESS,WINNER&perPage=1000`;
    doSubmit({}, newUrl, undefined).then((res: any) => {
      if(proposalTender?.metode_penyampaian === '1 Sampul') {
        const data = res?.data?.filter((item: any) => {
          return item?.status_evaluation_administration === "PASSED" &&
            item?.status_evaluation_technical === "PASSED" &&
            item?.status_evaluation_commercial === "PASSED"
        });
        setDataItems(data);
      } else if (proposalTender?.metode_penyampaian === '2 Sampul'){
        let data = res?.data?.filter((item: any) => {
          return item?.status_evaluation_administration === "PASSED" &&
            item?.status_evaluation_commercial === "PASSED"
        });

        data = data.map((item: any) => {
          if (item?.status_evaluation_administration === "PASSED") {
            return { ...item, status_evaluation_technical: "PASSED" }
          }
          return item;
        });

        setDataItems(data);
      } else {
        setDataItems(res?.data);
      }
    });
  },[proposalTender?.id, proposalTender?.metode_penyampaian]);

  useEffect(() => {
    getItems();
  }, [getItems]);

  return (
    <>
      <h4>{intl.formatMessage({id:"Hasil Evaluasi"})}</h4>
      <hr />
      <div className={"table-responsive"} style={{
        maxHeight: 400,
      }}>
        {isLoading && (
          <Placeholder.Grid rows={3} columns={5} />
        )}
        {!isLoading && (
          <table className={"table table-header-gray"}>
            <thead>
            <tr>
              <th className={"border px-2 text-center"} style={{ width: "5%" }}>
                {intl.formatMessage({ id: "No" })}
              </th>
              <th className={"border px-2 text-start"} style={{ width: "35%" }}>
                {intl.formatMessage({ id: "Rekanan" })}
              </th>
              <th className={"border px-2 text-center"} style={{ width: "20%" }}>
                {intl.formatMessage({ id: "Evaluasi Administrasi" })}
              </th>
              <th className={"border px-2 text-center"} style={{ width: "20%" }}>
                {intl.formatMessage({ id: "Evaluasi Teknis" })}
              </th>
              <th className={"border px-2 text-center"} style={{ width: "20%" }}>
                {intl.formatMessage({ id: "Evaluasi Komersial" })}
              </th>
            </tr>
            </thead>
            <tbody>
            {dataItems?.length === 0 && (
              <tr>
                <td className={"border text-center"} colSpan={5}>Tidak ada data</td>
              </tr>
            )}
            {dataItems?.map((item: any, index: number) => {
              return (
                <tr key={"hasil-evaluasi-" + index}>
                  <td className={"border px-2 text-center"}>{index + 1}</td>
                  <td className={"border px-2 text-start"}>
                    <strong><small>{item?.vendor_id}</small></strong><br />
                    <small>{item?.vendor?.name}</small>
                  </td>
                  <td className={"border text-center px-2"}>
                    <StatusBadgePassedFailed status={item?.status_evaluation_administration} />
                  </td>
                  <td className={"border text-center px-2"}>
                    <StatusBadgePassedFailed status={item?.status_evaluation_technical} />
                  </td>
                  <td className={"border text-center px-2"}>
                    <StatusBadgePassedFailed status={item?.status_evaluation_commercial} />
                  </td>
                </tr>
              );
            })}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};

const TRVendorItem = ({ vendor, index }: any) => {
  return (
    <tr>
      <td className={"border px-2 text-center"}>{vendor?.rank}</td>
      <td className={"border px-2 text-start"}>
        <div>{vendor?.vendor_id}</div>
        <div>{vendor?.vendor}</div>
      </td>

      <td className={"border px-2 text-end"}>
        {parseNumberToCurrency(vendor?.initial_bid_price?.price, vendor?.currency?.code)}
      </td>
      <td className={"border px-2 text-center"}>{vendor?.initial_bid_price?.quantity}</td>
      <td className={"border px-2 text-end"}>{parseNumberToCurrency(vendor?.initial_bid_price?.net_price, vendor?.currency?.code)}</td>
      <td className={"border px-2 text-end"}>{parseNumberToCurrency(vendor?.initial_bid_price?.additional_cost, vendor?.currency?.code)}</td>
      <td className={"border px-2 text-end bg-light-warning"}>
        <strong>{parseNumberToCurrency(vendor?.initial_bid_price?.sub_total, vendor?.currency?.code)}</strong>
      </td>

      <td className={"border px-2 text-end"}>{parseNumberToCurrency(vendor?.final_bid_price?.price, vendor?.currency?.code)}</td>
      <td className={"border px-2 text-center"}>{vendor?.final_bid_price?.quantity}</td>
      <td className={"border px-2 text-end"}>{parseNumberToCurrency(vendor?.final_bid_price?.net_price, vendor?.currency?.code)}</td>
      <td className={"border px-2 text-end"}>{parseNumberToCurrency(vendor?.final_bid_price?.additional_cost, vendor?.currency?.code)}</td>
      <td className={"border px-2 text-end bg-light-primary"}>
        <strong>{parseNumberToCurrency(vendor?.final_bid_price?.sub_total, vendor?.currency?.code)}</strong>
      </td>

      <td className={"border px-2 text-end"}>{vendor?.efisien}</td>
      <td className={"border px-2 text-end bg-light-danger"}>
        {parseNumberToCurrency(
          subtractNumber(
            vendor?.final_bid_price?.sub_total,
            vendor?.initial_bid_price?.sub_total
          ),
          vendor?.currency?.code
        )}
      </td>
    </tr>
  );
};

const TRVendor = ({ item, index }: any) => {
  return (
    <>
      <tr>
        <td
          className={"border px-2 text-start"}
          rowSpan={item?.vendors?.length + 1}
          style={{
            minWidth: '200px',
            verticalAlign: 'middle',
            position: 'sticky',
            left: 0,
            zIndex: 1,
            backgroundColor: 'white',
          }}
        >
          <div className={"mb-2"}><small className={"text-muted"}>Description</small><br />{item?.description}</div>
          <div className={"mb-2"}><small className={"text-muted"}>No PR / Line No: </small><br />{item?.no_pr} / {item?.line_no}</div>
          <div className={"mb-2"}><small className={"text-muted"}>Harga Satuan PR</small><br />{parseNumberToCurrency(item?.valuation_price_pr, item?.currency_pr)}</div>
          <div className={"mb-2"}><small className={"text-muted"}>Posisi</small><br />
          <span className="badge badge-dark">{item?.current_position_user}</span></div>
          <div className={"mb-2"}><small className={"text-muted"}>Status Item</small><br />
            <span className={`badge ${item?.status_item_pr === "ACTIVE" ? "bg-success" : "bg-danger"} text-white`}>{item?.status_item_pr}</span>
          </div>

        </td>
        {/*/!*<td className={"border px-2 text-start"} rowSpan={item?.vendors?.length + 1}>*!/*/}
        {/*/!*  <strong>{item?.no_pr}</strong>*!/*/}
        {/*/!*</td>*!/*/}
        {/*<td className={"border px-2 text-start"} rowSpan={item?.vendors?.length + 1}></td>*/}
        {/*<td className={"border px-2 text-start"} rowSpan={item?.vendors?.length + 1}>{item?.material}</td>*/}
      </tr>
      {item?.vendors?.map((vendor: any, index2: number) => {
        return (
          <TRVendorItem vendor={vendor} index={index2} key={"vendor-rekap-" + index2} />
        );
      })}
    </>
  )
}

const RekapNegosiasi = ({ proposalTender }: any) => {
  const [dataItems, setDataItems] = useState([] as any);
  const [{ doSubmit, isLoading }] = useAxiosGet(
    getEnv("tender") + `/negotiation/summary/${proposalTender?.negotiation?.uuid}`
  );
  const getItems = useCallback(() => {
    doSubmit({}, undefined, undefined).then((res: any) => {
      setDataItems(res?.data);
    });
  }, []);

  useEffect(() => {
    getItems();
  }, [getItems]);

  return (
    <>
      <h3>Rekap Negosiasi</h3>
      <div className={"table-responsive"}>
        {isLoading && (
          <Placeholder.Grid rows={3} columns={10} />
        )}
        {!isLoading && (
          <table className={"table table-header-gray"}>
            <thead>
            <tr>
              <th
                className={"border px-2 text-center"}
                style={{
                  width: '200px',
                  verticalAlign: 'middle',
                  position: 'sticky',
                  left: 0,
                  zIndex: 1,
                  // backgroundColor: 'white',
                }}
                rowSpan={2}>
                Deskripsi
              </th>
              {/*<th className={"border px-2 text-center text-nowrap"} style={{}} rowSpan={2}>Nomor PR</th>*/}
              {/*<th className={"border px-2 text-center text-nowrap"} style={{}} rowSpan={2}>PR Item</th>*/}
              {/*<th className={"border px-2 text-center text-nowrap"} style={{}} rowSpan={2}>Material Code</th>*/}
              <th className={"border px-2 text-center text-nowrap"} style={{ width: "50" }} rowSpan={2}>Rank</th>
              <th className={"border px-2 text-center text-nowrap"} style={{ minWidth: "200px" }} rowSpan={2}>Rekanan</th>
              <th className={"border px-2 text-center text-nowrap"} style={{}} rowSpan={1} colSpan={5}>Harga Penawaran
                Awal
              </th>
              <th className={"border px-2 text-center text-nowrap"} style={{}} rowSpan={1} colSpan={5}>Harga Penawaran
                Akhir
              </th>
              <th className={"border px-2 text-center text-nowrap"} style={{}} rowSpan={2}>Selisih OE (%)</th>
              <th className={"border px-2 text-center text-nowrap"} style={{}} rowSpan={2}>Selisih Harga Awal</th>
            </tr>
            <tr>
              <td className={"border px-2 text-end text-nowrap"} style={{backgroundColor:'#f0f2f5'}}>Harga Satuan Awal</td>
              <td className={"border px-2 text-end text-nowrap"} style={{backgroundColor:'#f0f2f5'}}>Qty</td>
              <td className={"border px-2 text-end text-nowrap"} style={{backgroundColor:'#f0f2f5'}}>Nett Price</td>
              <td className={"border px-2 text-end text-nowrap"} style={{backgroundColor:'#f0f2f5'}}>Add Cost</td>
              <td className={"border px-2 text-end text-nowrap"} style={{backgroundColor:'#f0f2f5'}}>Subtotal</td>
              <td className={"border px-2 text-end text-nowrap"} style={{backgroundColor:'#f0f2f5'}}>Harga Satuan Awal</td>
              <td className={"border px-2 text-end text-nowrap"} style={{backgroundColor:'#f0f2f5'}}>Qty</td>
              <td className={"border px-2 text-end text-nowrap"} style={{backgroundColor:'#f0f2f5'}}>Nett Price</td>
              <td className={"border px-2 text-end text-nowrap"} style={{backgroundColor:'#f0f2f5'}}>Add Cost</td>
              <td className={"border px-2 text-end text-nowrap"} style={{backgroundColor:'#f0f2f5'}}>Subtotal</td>
            </tr>
            </thead>
            <tbody>
            {dataItems?.length === 0 && (
              <tr>
                <td className={"border text-center"} colSpan={17}>Tidak ada data</td>
              </tr>
            )}
            {dataItems?.map((item: any, index: number) => {
              return (
                <TRVendor item={item} index={index} key={"vendor-rekap-" + index} />
              );
            })}
            </tbody>
          </table>
        )}

      </div>
    </>
  );
};
const ResumeNegosiasi = ({ proposalTender }: any) => {
  return (
    <>
      <HasilEvaluasi proposalTender={proposalTender} />

      <hr />
      <RekapNegosiasi proposalTender={proposalTender} />
    </>
  );
};

export default ResumeNegosiasi;