import {ClarificationTenderItemModel, ClarificationTenderItemStatus} from '../../../core/_models'
import {useAuth, UserModel} from '../../../../auth'
import {Button, Modal} from 'react-bootstrap'
import {Field, Form, Formik} from 'formik'
import * as Yup from 'yup'
import React, {useId, useState} from 'react'
import {MetronicErrorMessage, toastError, toastSuccess} from '../../../../../../_metronic/helpers'
import FormikUploadFile from '../../../../../components/shared/form/FormikUploadFile'
import {useIntl} from 'react-intl'
import {useSyncPrMutation} from '../core/_queries'
import {useUploadMedia} from '../../../../../../hooks/MediaUploadHook'
import {MediaModel} from '../../../../../../_models'
import {AxiosError} from 'axios'
import {useQueryClient} from 'react-query'

const allowedStatuses: ClarificationTenderItemStatus[] = [
  'On Progress',
  'Reject Approval Return',
  'Reject Receive',
  'Reject Approval Evaluator',
  'Reject Approval Alihkan',
  'Cancel End User',
]

function isAllowedToSyncPr(
  clarificationTenderItem: ClarificationTenderItemModel,
  currentUser: UserModel
) {
  return (
    clarificationTenderItem !== undefined &&
    clarificationTenderItem.step === 'evaluator' &&
    allowedStatuses.includes(clarificationTenderItem.status) &&
    currentUser.has_roles.find((role) => role.includes('perencana'))
  )
}

interface EvaluationSyncPrModalProps {
  clarificationTenderItem: ClarificationTenderItemModel
  show: boolean
  onHide: () => void
}

const EvaluationSyncPrModal = ({
  clarificationTenderItem,
  show,
  onHide,
}: EvaluationSyncPrModalProps) => {
  const intl = useIntl()
  const ids = {note: useId()}
  const {upload} = useUploadMedia()
  const queryClient = useQueryClient()

  const {mutate: syncPr, isLoading: isLoadingSyncPr} = useSyncPrMutation()
  const onHideFn = () => !isLoadingSyncPr && onHide()

  return (
    <Formik
      initialValues={{
        attachment: '',
        note: '',
      }}
      validationSchema={Yup.object().shape({
        attachment: Yup.string().label('Attachment').required(),
        note: Yup.string().label('Catatan Sinkronisasi PR').required(),
      })}
      onSubmit={async ({attachment, note}, {resetForm}) => {
        const PR_TENDER_OTHERS_MEDIA_TYPE_ID = '25'
        const media: MediaModel = await upload(PR_TENDER_OTHERS_MEDIA_TYPE_ID, attachment)

        syncPr(
          {
            clarification_tender_item_id: clarificationTenderItem.uuid,
            attachment_url: media.url,
            note: note,
          },
          {
            onSuccess: async (response) => {
              await queryClient.invalidateQueries(['clarification-tender-items', clarificationTenderItem.uuid, 'results'])
              toastSuccess(response.data.meta.message)
              onHide()
              resetForm()
            },
            onError: (error: AxiosError) => {
              toastError(
                error.response.data?.meta?.message ||
                  error.response.data?.message ||
                  'Terjadi kesalahan'
              )
            },
          }
        )
      }}
    >
      {() => (
        <Modal size='lg' show={show} onHide={onHideFn}>
          <Form>
            <Modal.Header closeButton={!isLoadingSyncPr}>
              <h4>Sinkronisasi PR</h4>
            </Modal.Header>

            <Modal.Body>
              {/*region No PR & Item PR*/}
              <div className='row mb-4'>
                <div className='col-md-6'>
                  <label className='form-label mb-3'>Nomor PR</label>

                  <input
                    name='no_pr'
                    value={clarificationTenderItem.no_pr}
                    className='form-control'
                    disabled
                  />
                </div>
                <div className='col-md-6'>
                  <label className='form-label mb-3'>Item PR</label>

                  <input
                    name='line_no'
                    value={clarificationTenderItem.line_no}
                    className='form-control'
                    disabled
                  />
                </div>
              </div>
              {/*endregion*/}

              {/*region Attachment*/}
              <div className='mb-4'>
                <FormikUploadFile
                  label={intl.formatMessage({id: 'Attachment'})}
                  name='attachment'
                />
              </div>
              {/*endregion*/}

              {/*region Note*/}
              <div className='mb-4'>
                <label className='form-label mb-3 required' htmlFor={ids.note}>
                  Catatan Sinkronisasi PR
                </label>

                <Field as='textarea' name='note' id={ids.note} className={'form-control'} />

                <MetronicErrorMessage name='note' />
              </div>
              {/*endregion*/}
            </Modal.Body>

            <Modal.Footer>
              <Button type='button' variant='danger' onClick={onHideFn} disabled={isLoadingSyncPr}>
                Cancel
              </Button>
              <Button type='submit' variant='primary' disabled={isLoadingSyncPr}>
                Sync
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </Formik>
  )
}

interface EvaluationSyncPrModalButtonProps {
  clarificationTenderItem: ClarificationTenderItemModel
}

const EvaluationSyncPrModalButton = ({
  clarificationTenderItem,
}: EvaluationSyncPrModalButtonProps) => {
  const {currentUser} = useAuth()
  const [isShowModal, setIsShowModal] = useState(false)

  const allowedToSyncPr = isAllowedToSyncPr(clarificationTenderItem, currentUser)

  if (!allowedToSyncPr) {
    return <></>
  }

  return (
    <>
      <button className={'btn btn-sm btn-success me-3'} onClick={() => setIsShowModal(true)}>
        Sinkron PR
      </button>

      <EvaluationSyncPrModal
        clarificationTenderItem={clarificationTenderItem}
        show={isShowModal}
        onHide={() => setIsShowModal(false)}
      />
    </>
  )
}

export default EvaluationSyncPrModalButton
