import {KTCard, KTCardBody, formatCurrency} from '../../../../../../_metronic/helpers'
import React, {useEffect, useState} from 'react'
import TableXWrpper from '../../../../../components/shared/TableXWrapper'
import {NavLink, useNavigate} from 'react-router-dom'
import {getEnv} from '../../../../../../helpers/procurex'
import {useIntl} from 'react-intl'
import {darkModeClass} from '../../../../../../helpers/utils'
import {getUrlDetailTender} from '../../proposal-tender/ProposalTenderList'

interface IPropsStatusBadge {
  status: string;
}

const StatusBadgeMonitoringTender = ({status}: IPropsStatusBadge) => {
  const [badge, setBadge] = useState('badge-primary')
  useEffect(() => {
    if (status === 'Draft') setBadge('badge-info')
    if (status === 'DUR') setBadge('badge-primary')
  }, [])
  return (
    <div className={'text-start'}>
      <span className={`badge ${badge}`}>{status}</span>
    </div>
  )
}

const StatusBadgePenawaranVendor = ({status}: IPropsStatusBadge) => {
  const [badge, setBadge] = useState('badge-info')
  return (
    <div className={'text-start text-wrap'} style={{}}>
      <div className={`p-1 rounded-3 ${badge}`}>
        <small><strong>{status}</strong></small>
      </div>
    </div>
  )
}

const MonitoringTenderList = () => {
  const urlIndex = getEnv('tender') + '/tender?sort=-updated_at&source=monitoring'
  const navigate = useNavigate()
  const intl = useIntl()
  const tableColumns = [
    {
      Header: intl.formatMessage({id: 'Aksi'}),
      accessor: 'action',
      width: 50,
      className: 'text-start fixed-column',
      Cell: (props: any) => {
        const row = props.row.original
        return (
          <>
            <NavLink to={getUrlDetailTender(row)}
                     title={'Edit'} className={`me-2 ${darkModeClass()}`}
                     onClick={() => {
                       localStorage.setItem('backTo', window.location.pathname)
                     }}
            >
              <i className={`fa fs-3 fa-eye ${darkModeClass()}`}></i>
            </NavLink>
          </>
        )
      },
    },
    {
      Header: intl.formatMessage({id: 'Status'}),
      accessor: 'status_monitoring',
      className: 'text-start text-nowrap',
      width: 320,
      Cell: (props: any) => {
        return (
          <>
            <StatusBadgeMonitoringTender
              status={`${props.row.original.status_monitoring}`} />
          </>
        )
      },
    },
    {
      Header: intl.formatMessage({id: 'Notes'}),
      accesor: 'notes',
      className: 'text-start text-nowrap',
      width: 150,
      Cell: (props: any) => {
        return (
          <>
            {props?.row?.original?.notes?.split('\n')?.map((item: any, index: number) => {
              return (
                <div key={index}>{item}</div>
              )
            })}
          </>
        )
      },
    },
    {
      Header: intl.formatMessage({id: 'No'}),
      accessor: 'no',
      width: 180,
      disableCreate: true,
      disableEdit: true,
      className: 'text-center fixed-column',
    },
    {
      Header: intl.formatMessage({id: 'No PR'}),
      accessor: 'pr_header_numbers',
      width: 120,
      disableCreate: true,
      disableEdit: true,
      className: 'text-center',
      Cell: (props: any) => {
        return (
          <>
            {props?.row?.original?.pr_header_numbers?.split(',')?.map((item: any, index: number) => {
              return (
                <div key={index}>{item}</div>
              )
            })}
          </>
        )
      },
    },
    {
      Header: intl.formatMessage({id: 'Jenis Permintaan'}),
      accessor: 'account_assignment_used',
      width: 200,
      className: 'text-center',
    },
    {
      Header: intl.formatMessage({id: 'Perusahaan Peminta'}),
      accessor: 'company_code_requestor',
      width: 200,
      Cell: (props: any) => {
        const data = props.cell.row.original
        return (
          <>
            {data?.company_code_requestor && data?.company_code_requestor.length > 0 && data?.company_code_requestor?.map((item: any, index: number) => {
              return (
                <div key={index}>{item}</div>
              )
            })}
          </>
        )
      },
    },
    {
      Header: intl.formatMessage({id: 'Judul'}),
      accessor: 'title',
      width: 200,
    },
    {
      Header: intl.formatMessage({id: 'Nilai Tender'}),
      accessor: 'total_nilai_tender', className: 'text-end', width: 150, Cell: (props: any) => {
        const data = props.cell.row.original
        return formatCurrency(data.total_nilai_tender)
      },
    },
    {
      Header: intl.formatMessage({id: 'Mata Uang'}),
      accessor: 'currency_id', className: 'text-center', width: 90,
      // Cell: (props: any) => {
      //   const data = props.cell.row.original
      //   return data?.items[0]?.currency_id
      // }
    },
    {
      Header: intl.formatMessage({id: 'Tanggal Pengumuman'}),
      accessor: 'start_at_pengumuman',
      width: 200,
      className: 'text-center',
      // Cell: (props: any) => {
      //   const data = props.cell.row.original
      //   const value = data?.
      //                 jadwals?.filter((item: any) => item.description === "Pengumuman")
      //                 .map((item: any) => item.start_at)
      //   return value;
      // }
    },
    {Header: intl.formatMessage({id: 'Referensi'}), accessor: 'reference', width: 150, className: 'text-center'},
    {
      Header: intl.formatMessage({id: 'Purchasing Group'}),
      accessor: 'purchasing_group_id',
      width: 125,
      className: 'text-center',
    },
    {
      Header: intl.formatMessage({id: 'Buyer'}),
      accessor: 'created_by.name',
      width: 200,
      disableCreate: true,
      disableEdit: true,
      className: 'text-center',
      Cell: (props: any) => {
        return (
          <>
            {props.row.original.created_by.username} - {props.row.original.created_by.name}
          </>
        )
      },
    },
    {Header: intl.formatMessage({id: 'Dibuat Pada'}), accessor: 'created_at', width: 200, className: 'text-center'},
  ]
  return (
    <>
      <KTCard>
        <KTCardBody className="py-4">
          <TableXWrpper
            readonly={true}
            title={'Proposal Tender'}
            urlIndex={urlIndex}
            columns={tableColumns}
            isForceCreateButton={true}
            hasAnySearch={true}
          />
        </KTCardBody>
      </KTCard>
    </>
  )
}

export {MonitoringTenderList, StatusBadgeMonitoringTender, StatusBadgePenawaranVendor}
