import React, { FC, useEffect, useState } from 'react';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';

type IProps = {
    label?: string,
    startDate?: any,
    endDate?: any,
    isCountDown?: boolean,
    meta?: string
}

const Timer: FC<IProps> = (props) => {

    const { label, startDate, endDate, isCountDown, meta } = props

    const [formated, setFormated]: any = useState('00:00:00');

    useEffect(() => {
        // let now = moment();
        // let end = moment(endDate);
        // const timeLeft = moment(end.diff(now));

        // let now = moment();
        // now.locale('id');
        // now.add(7, 'hours');
        // let end = moment(endDate);
        // let timeLeft = moment(end.diff(now));
        let start = moment();
        if(startDate) {
            (moment(startDate) < moment()) ? start = moment() : moment(startDate);
        }
        let end = moment(endDate);
        let timeLeft = moment(end.diff(start));
        /*if(meta=="test") {
            if(startDate) console.log("startDate : " + start.toLocaleString());
            console.log("endDate : " + end.toLocaleString());
        }*/

        if(isCountDown) {
            if (end > start) {
                setTimeout(() => {
                    const totalSecondTimeLeft = timeLeft.unix();
                    const day = Math.floor(totalSecondTimeLeft/86400);
                    const hour = Math.floor((totalSecondTimeLeft % 86400)/3600);
                    let timeLeftString = (day>0) ? day + ' Hari ' : '';
                    timeLeftString += hour + ' Jam ' + timeLeft.minute() + ' Menit ' + timeLeft.second() + ' Detik';
                    if(!startDate)
                    {
                        setFormated(timeLeftString);
                    } else {
                        if(moment(startDate) > moment()) {
                            setFormated(endDate.format('D MMMM YYYY, HH:mm:ss'));
                        } else if(moment(startDate) < moment()) {
                            setFormated(timeLeftString);
                        }
                    }
                    //setFormated(timeLeft.format('D [Hari] HH [Jam] mm [Menit] ss [Detik]'))
                }, 1000);
            } else {
                setFormated(endDate.format('D MMMM YYYY, HH:mm:ss'));
            }
        } else {
            setFormated(endDate.format('D MMMM YYYY, HH:mm:ss'));
        }
    }, [startDate, endDate, formated]);

    return (
        <React.Fragment>
            <div style={{ background: '#f1faff', borderRadius: 10, boxShadow: 'rgba(0, 0, 0, 0.075) 3px 3px 8px 0px', width: 550, marginBottom: 5 }}>
                {label && (
                    <Row>
                        <div style={{ textAlign: 'center', marginBottom: -5, paddingTop: 5 }}>
                            <h6>{`${label}`}</h6>
                        </div>
                    </Row>
                )}
                <Row>
                    <div style={{ textAlign: 'center', paddingBottom: 5 }}>
                        <label className='display-6' >{`${formated}`}</label>
                    </div>
                </Row>
            </div>
        </React.Fragment>
    );

}

export default Timer;
