import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useAxiosGet } from "../../../../hooks/useAxios";
import { getEnv } from "../../../../helpers/procurex";
import parse from "html-react-parser";

const AnnouncementVendorModal = () => {
  const [show, setShow] = useState(false);
  const intl = useIntl();
  const [lastVersion, setLastVersion] = useState("");
  const [{ doSubmit, isLoading }] = useAxiosGet("");
  const getAnnouncement = () => {
    doSubmit({}, getEnv('masterdata') + '/announcements?filter[is_show_on_pop_up]=true').then((res: any) => {
      setLastVersion(res.data[0].content);
    });
  }

  useEffect(() => {
    getAnnouncement();
  }, []);

  useEffect(() => {
    if(window.location.pathname === "/vendor/dashboard"){
      setShow(true);
    }
  }, [lastVersion]);

  return (
    <>
      <Modal show={show} size={"xl"} centered onHide={() => setShow(false)}>
        <Modal.Header closeButton={true}>
          <Modal.Title>Announcement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={"row"}>
            <div className={"col-9"} style={{
              maxHeight: '400px',
              overflow: 'auto'
            }}>
              {parse(lastVersion)}
            </div>
            <div className={"col-3 text-center"}>
              <img src="/media/illustrations/dozzy-1/16.png" alt="update" style={{
                width: '100%'
              }} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className={"btn btn-primary btn-sm"} onClick={() => setShow(false)}>
            <i className={"fa fa-thumbs-up"}></i>
            {intl.formatMessage({ id: "OK" })}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AnnouncementVendorModal;