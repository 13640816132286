import { formatCurrency, KTCard, KTCardBody } from "../../../../../../_metronic/helpers";
import React, { useState } from "react";
import TableXWrpper from "../../../../../components/shared/TableXWrapper";
import ButtonToolbarProposalTender from "./ButtonToolbarProposalTender";
import { getEnv, stringPadZero } from "../../../../../../helpers/procurex";
import { useAuth } from "../../../../auth";
import { useIntl } from "react-intl";
import { darkModeClass } from "../../../../../../helpers/utils";
import FormDisplayPRDetail from "../../purchasing-requisition/components/FormDisplayPRDetail";


const PurchasingRequisitionProcessList = () => {
  const [tableIndex, setTableIndex] = useState(0);
  const { currentUser } = useAuth();
  const urlIndex = getEnv('tender')+ `/purchase-requisition-item-tender?filter[current_user]=${currentUser?.username}&filter[status]=AVAILABLE`;
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsDetails, setSelectedRowsDetails] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const intl = useIntl();


  const tableColumns = [
    {
      Header: intl.formatMessage({id:"Aksi"}),
      accessor: "action",
      width: 50,
      className: "text-start fixed-column",
      Cell: (props: any) => {
        const row = props.cell.row.original;
        return (
          <>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href={"javascript:;"} title={"Edit"} onClick={(e:any) => {
              e.preventDefault();
              setSelectedRowsDetails([row])
              setShowModal(true)
            }}
              className={`me-2 ${darkModeClass()}`}>
              <i className={`fa fs-3 fa-eye ${darkModeClass()}`}></i>
            </a>
          </>
        );
      }
    },
    { Header: "Nomor PR", accessor: "no", width: 150, className: "text-center" },
    { Header: "Doc Type", accessor: "document_type_id", width: 100, className: "text-center" },
    { Header: "Source PR", accessor: "source", width: 100, className: "text-center" },
    { Header: "Status", accessor: "status", width: 100, className: "text-center", Cell: (props: any) => {
      const data = props.cell.row.original
        let badge = "badge-light-danger"
        if(data.status === 'SUBMITTED')
          badge = "badge-light-primary"
        else if(data.status === 'OPEN')
          badge = "badge-light-success"

        return (<span className={`badge ${badge}`}>{data.status}</span>)
      }
    },        { Header: "Line Item", accessor: "line_no", width: 100, className: "text-center" },
    { Header: "A", accessor: "account_assignment_category", width: 50, className: "text-center" },
    {Header: 'I', accessor: 'purchaseRequisitionItem.item_category', width: 100},
    {
      Header: 'Srv. Line Item',
      accessor: 'service_line.service_no',
      width: 250,
      Cell: (props: any) => {
        const data = props.cell.row.original
        return (
          <>
            {data.service_line?.map((v: any, i: number) => {
              return (
                <div key={i}>
                  {stringPadZero(v.line_no)} - {v.short_text}
                </div>
              )
            })}
          </>
        )
      }
    },     
    {Header: 'Material Number', accessor: 'material_id', width: 100},
    { Header: "Short Text", accessor: "short_text", width: 250 },
    {
      Header: "Qty", accessor: "quantity", className: "text-end", width: 200, Cell: (props: any) => {
        const data = props.cell.row.original;
        return formatCurrency(data.quantity);
      }
    },    
    { Header: "Unit", accessor: "uom_id", width: 50, className: "text-center" },
    {
      Header: "Harga Satuan", accessor: "valuation_price", className: "text-end", width: 200, Cell: (props: any) => {
        const data = props.cell.row.original;
        return formatCurrency(data.valuation_price);
      }
    },
    { Header: "Currency", accessor: "currency_id", width: 150, className: "text-center" },
    {
      Header: "Per", accessor: "per", className: "text-end", width: 200, Cell: (props: any) => {
        const data = props.cell.row.original;
        return formatCurrency(data.per);
      }
    },       
    {
      Header: "Total Harga", accessor: "total_value", width: 200, className: "text-end", Cell: (props: any) => {
        const data = props.cell.row.original;
        return formatCurrency(data.total_value ?? "0");
      }
    },
    { Header: "Delivery Date", accessor: "delivery_date", width: 150, className: "text-center" },
    { Header: "Material Group", accessor: "material_group_id", width: 150, className: "text-center" },
    { Header: "Plant", accessor: "plant_id", width: 100, className: "text-center" },
    { Header: "Sloc", accessor: "sloc", width: 100, className: "text-center" },
    { Header: "Purchasing Group", accessor: "purchasing_group_id", width: 250 },
    { Header: "Requisitioner", accessor: "requisitioner", width: 150, className: "text-center" },
    { Header: "Track. No", accessor: "tracking_number", width: 150, className: "text-center" },
    { Header: "No. Contract", accessor: "agreement_id", width: 150, className: "text-center" },
    { Header: "Item Contract", accessor: "agreement_item_id", width: 150, className: "text-center" }
  ];
  
  return (
    <>
      <FormDisplayPRDetail
        show={showModal}
        closeModal={() => setShowModal(false)}
        selectedRows={{
          ...selectedRowsDetails[0],
          short_text: selectedRowsDetails[0]?.short_text,
          no_pr: selectedRowsDetails[0]?.no,
          line_no: selectedRowsDetails[0]?.line_no,
          purchasing_group_id: selectedRowsDetails[0]?.purchasing_group,
          id_pr_header: selectedRowsDetails[0]?.purchaseRequisitionItem?.purchaseRequisition?.id,
        }}
      />

      <KTCard>
        <KTCardBody className="py-4">

          <TableXWrpper
            key={tableIndex}
            readonly={true}
            title={"Proposal Tender"}
            urlIndex={urlIndex}
            columns={tableColumns}
            isForceCreateButton={true}
            urlPageCreate={"/tender/tahapan_proposal-tender/create"}
            onSelectedRows={(e: any) => {
              setSelectedRows(e);
            }}
            headerButtons={
              <ButtonToolbarProposalTender
                selectedRows={selectedRows}
                afterSubmit={() => {
                }}
                afterSync={() => {
                  setTableIndex(tableIndex + 1);
                }}
              />
            }
          />
        </KTCardBody>
      </KTCard>
    </>
  );
};

export { PurchasingRequisitionProcessList };
