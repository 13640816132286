import { useEffect, useState } from "react";
// import Pusher from '../../config/PusherInit';
import Echo from "laravel-echo";
export default function SoketiTestPage() {
  const [data,setData] = useState<any>("");
  const PusherJS = require('pusher-js');
  const [event,setEvent] = useState<any>("App\\Events\\AuctionVendorEvent");
  const [subscribed,setSubscribed] = useState<any>("auction.vendor.9aa31383-95dd-41b0-a26d-11b7b5b8df0d.9aa314aa-9a58-4c4a-b796-39a6c4a8b13c");

  let client = new PusherJS('app-key', {
    wsHost: 'ws2.alterindonesia.com',
    wsPort: 443,
    cluster: 'mt1',
    forceTLS: false,
    encrypted: false,
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
    logToConsole: true,
  });

  const channel = client.subscribe(subscribed);
  channel.bind(event, function(data:any) {
    setData(data);
    console.log(data.message)
  });
  return (
    <div>
      <h1>Soketi Test Page</h1>
      {/*Event:*/}
      {/*<input type="text" onChange={(e)=>setEvent(e.target.value)} value={event} placeholder={"App\\Events\\TestEvent"}/><br />*/}
      {/*Subcribe: <input type="text" onChange={(e)=>setSubscribed(e.target.value)} value={subscribed} placeholder={subscribed}/> <br />*/}
      {/*<br />*/}
      {JSON.stringify(data)}
    </div>
  )
}