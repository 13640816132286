import React, {useEffect, useState} from 'react'
import TableXWrapper from '../../../../../../components/shared/TableXWrapper'
import {getEnv} from '../../../../../../../helpers/procurex'
import {useIntl} from 'react-intl'
import {ButtonGroup, Modal, Spinner} from 'react-bootstrap'
import useRemoteFileAsObjectUrl from '../../../../../../../hooks/useRemoteFileAsObjectUrl'
import {Nav, Placeholder} from 'rsuite'
import {BeritaAcaraTab} from './BeritaAcaraTab'
import BottomToolbarWrapper from '../../../../../../components/shared/BottomToolbarWrapper'
import {useAxiosPost} from '../../../../../../../hooks/useAxios'
import Swal from 'sweetalert2'
import {toast} from 'react-toastify'
import {useNavigate} from 'react-router-dom'
import {useProposalTender} from '../../core/_proposalTenderProvider'

const StatusBadge = ({status}:any) => {
  const statusDanger = [
    "Tolak Undangan",
    "Reject Quote",
  ]
  const statusWarning = [
    "No Quote",
    "No Respon",
    "Quote Teknis & No Respon Komersial",
  ]

  if(statusDanger.includes(status))
    return (
      <>
        <span className="badge bg-danger"
              onClick={() => alert('!')}
        >{status}</span>
      </>
    );
  else if(statusWarning.includes(status))
    return <span className="badge bg-warning">{status}</span>
  else
    return <span className="badge bg-info">{status}</span>
}


export const TableRekanan = () => {
  const {proposalTender} = useProposalTender();
  const [urlIndex,setUrlIndex] = useState("")
  const [selectedRows, setSelectedRows] = useState([]);
  const intl = useIntl();
  const [selectedItem, setSelectedItem] = React.useState<any>(null);
  const { objectUrl, isLoading: isLoadingObjectUrl, setSourceUrl } = useRemoteFileAsObjectUrl(selectedItem);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [showModalSPPH, setShowModalSPPH] = React.useState(false);
  const [showModalAttachment, setShowModalAttachment] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState("");
  const [menuTab, setMenuTab] = React.useState([] as any);
  const [uuidVendor, setUuidVendor] = React.useState(null);
  const [previewExt, setPreviewExt] = useState({value: '', ext: ''})
  const navigate = useNavigate();

  useEffect(() => {
    if(proposalTender){
      setUrlIndex(getEnv("tender") + "/dur-vendor?filter[proposal_tender_id]=" + proposalTender?.id+"&filter[status_tender]=INVITED,REGISTRATION-QUOTATION,REJECT-INVITATION,REGISTRATION,REJECT-QUOTATION,NEGOTIATION,NEGOTIATION_PROCESS,WINNER")
    }
  }, [proposalTender])
  const AlasanMenolak = ({data}:any) => {
    let value = (data?.vendor_rejection[0]?.media_url ?? data?.vendor_rejection[1]?.media_url) || "";
    let ext = data?.vendor_rejection[0]?.media_url?.split('.')?.pop() || "";
    let vendor_rejection = (data?.vendor_rejection[0]?.note ?? data?.vendor_rejection[1]?.note) || "";

    return (
      <>
        <span style={{cursor: "pointer", textDecoration: "underline"}}
            onClick={() =>{
              setShowModalAttachment(true)
              setPreviewExt({value, ext})
            }}
        >
          {vendor_rejection?.split("\n")?.map((item: any, index: number) => {
            return (
              <div key={index}>{item}</div>
            );
          })}
        </span>
      </>
    )
  }

  const tableColumns = [
    {
      Header: 'SPPH',
      accessor: 'url',
      className: 'text-center',
      width: 70,
      Cell: (props: any) => (
        <>
          <ButtonGroup size={'sm'} aria-label='Action'>
            <button
              type='button'
              className='btn btn-sm btn-secondary'
              onClick={()=>{
                setUuidVendor(props.row.original?.uuid)
                setShowModalSPPH(true)
              }}
            >
              <i className={'fa fa-eye'} />
            </button>
          </ButtonGroup>
        </>
      ),
    },
    { Header: intl.formatMessage({id:"ID Vendor"}), accessor: "vendor_id", width: 100, className: "text-start", Cell: (props: any) => {
        return (
          <>
            {props.row.original.vendor_id}
          </>
        )
      }
    },
    { Header: intl.formatMessage({id:"Nama Vendor"}), accessor: "vendor.name", width: 300, className: "text-start"},
    {
      Header: intl.formatMessage({id:"Status"}),
      accessor: "status_tender_description",
      width: 150,
      className: "text-center",
      Cell: (props: any) => {
        return (
          <>
            <StatusBadge status={props.row.original.status_tender_description} />
          </>
        )
      }
    },
    {
      Header: intl.formatMessage({id:"Alasan Menolak"}),
      accessor: "alasan_tidak_lolos",
      width: 200,
      className: "text-center",
      Cell: (props: any) => {
        let data = props.row.original;
        return (
          <>
            <AlasanMenolak data={data} />
          </>
        )
      }
    },
    { Header: intl.formatMessage({id:"Dibuat Pada"}), accessor: "updated_at", width: 200, className: "text-center" },
  ];

  const initiateTab = () => {
    const tmp = [
      { eventKey: "indo", label: intl.formatMessage({id:"Indonesia"}) },
      { eventKey: "eng", label: intl.formatMessage({id:"English"}) },
    ]
    let tmpSelectedTab = "";
    tmp.forEach((item:any) => {
      if(!item?.disabled && tmpSelectedTab === ""){
        tmpSelectedTab = item.eventKey;
        setSelectedTab(item.eventKey);
        return false;
      }
    })
    setMenuTab(tmp);
  }

  useEffect(() => {
    if(proposalTender){
      initiateTab();
      const _date = proposalTender?.jadwals.find((jadwal:any) => jadwal.description === "Registrasi")
      setStartDate(_date ? _date.start_at : "")
      setEndDate(_date ? _date.end_at : "")
    }
  }, [proposalTender]);

  useEffect(() => {
    console.log(uuidVendor)
    if (uuidVendor) {
      console.log('uuidVendor')
      if(selectedTab === 'indo'){
        setSelectedItem(getEnv('tender')+`/evaluation/quotation/generate-surat-permintaan-penawaran-harga/${proposalTender?.uuid}/${uuidVendor}`)
      } else {
        setSelectedItem(getEnv('tender')+`/evaluation/quotation/generate-surat-permintaan-penawaran-harga/${proposalTender?.uuid}/${uuidVendor}/en`)
      }
    }
  }, [selectedTab,uuidVendor]);

  return (
    <>
      <Modal show={showModalSPPH} size={"lg"}  onHide={() => {setShowModalSPPH(false)}}>
        <Modal.Header closeButton={true}>
          <Modal.Title>Preview SPPH</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Nav appearance={"subtle"} activeKey={selectedTab} onSelect={(e:any)=>{setSelectedTab(e)}} style={{ marginBottom: 20 }}>
            {menuTab.map((item:any, index:number) => {
              return (
                <Nav.Item eventKey={item.eventKey} key={index} disabled={item?.disabled}>
                  {item.label}
                </Nav.Item>
              );
            })}
          </Nav>
          {(isLoadingObjectUrl)
            ? <Placeholder.Graph height={700} active={isLoadingObjectUrl} />
            : selectedItem && objectUrl
              ? <>
                <object width="100%" style={{
                  height: "700px"
                }} data={objectUrl} type="application/pdf">
                  {" "}
                </object>
              </>
              : <div className={"bg-secondary w-100"} style={{ "height": 700, "opacity": "0.6" }}></div>
          }
        </Modal.Body>
      </Modal>

      <Modal show={showModalAttachment} size={"lg"}  onHide={() => {setShowModalAttachment(false)}}>
        <Modal.Header closeButton={true}>
          <Modal.Title>Preview Attachment Rejection</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <object width='100%' height='700' data={previewExt.value} type='application/pdf'>
            {' '}
          </object>
          {(previewExt.ext === 'jpg' ||
            previewExt.ext === 'png' ||
            previewExt.ext === 'jpeg') && (
            <>
              <img className='w-100' src={previewExt.value} alt='image' />
            </>
          )}
        </Modal.Body>
      </Modal>

      <div className={"row"}>
        <div className={"col-md-6"}>
          <div className={"form-group mb-5"}>
            <label htmlFor=""><strong>{intl.formatMessage({id:"Waktu Mulai Registrasi"})}</strong></label>
            {/*<DatepickerX2 value={startDate} onChange={(e: any) => {}} readonly={true} format={"yyyy-MM-dd hh:ii:ss"} />*/}
            <input className={"form-control"} value={startDate} disabled={true} />
          </div>
          <div className={"form-group mb-3"}>
            <label htmlFor=""><strong>{intl.formatMessage({ id: "Waktu Selesai Registrasi" })}</strong></label>
            {/*<DatepickerX2 value={endDate} onChange={(e: any) => {*/}
            {/*}} readonly={true} format={"yyyy-MM-dd hh:ii:ss"} />*/}
            <input className={"form-control"} value={endDate} disabled={true} />
          </div>
        </div>
      </div>
      <hr />

      <TableXWrapper
        readonly={true}
        key={urlIndex}
        title={"Proposal Tender"}
        urlIndex={urlIndex}
        columns={tableColumns}
        isForceCreateButton={true}
        // onSelectedRows={(e: any) => {
        //   setSelectedRows(e);
        // }}
        disableUrlChanged={true}
        disablePaddingCard={true}
        headerButtons={
        <div>
          <button className={"btn btn-sm btn-primary"} onClick={() => {
            navigate("/tender/admin/proposal-tender/detail/" + proposalTender?.uuid + "/dur")
          }}>
            {intl.formatMessage({id:"Lihat DUR"})}
          </button>
        </div>
        }
      />
    </>
  )
}

const PesertaTenderPage = ({proposalTender,onQuotationChanged}:any) => {
  const [selectedTab, setSelectedTab] = useState("tabel-rekanan");
  const [menuTab, setMenuTab] = useState([] as any);
  const intl = useIntl();
  const [isMounted, setIsMounted] = React.useState(false);

  const initiateTab = () => {
    const tmp = [
      { eventKey: "tabel-rekanan", label: intl.formatMessage({id:"Tabel Rekanan"}) },
      { eventKey: "berita-acara", label: intl.formatMessage({id:"Berita Acara"}) },
    ]
    let tmpSelectedTab = "";
    tmp.forEach((item:any) => {
      if(!item?.disabled && tmpSelectedTab === ""){
        tmpSelectedTab = item.eventKey;
        // setSelectedTab(item.eventKey);
        return false;
      }
    })
    setMenuTab(tmp);
  }

  useEffect(() => {
    if(proposalTender?.status_detail == 'Submit for Approval DUR' && !isMounted){
      setSelectedTab('berita-acara')
      setIsMounted(true)
    }
  }, [menuTab, isMounted, selectedTab]);

  const showTab = () => {
    switch (selectedTab) {
      case "tabel-rekanan":
        return <TableRekanan />;
      case "berita-acara":
        return <BeritaAcaraTab proposalTender={proposalTender} />;
      default:
        return <></>;
    }
  }

  const isShowBidOpening = () => {
    return proposalTender?.status === "Bid Opening" &&
      (proposalTender?.status_detail === "Bid Opening" ||
       proposalTender?.status_detail === "Rejected");
  }

  const  [{ doSubmit, isLoading }] = useAxiosPost(
    getEnv("tender") + `/evaluation/administration/syarat`
  )

  const onBidOpening = () => {
    
    Swal.fire({
      title: intl.formatMessage({id:"Apakah Anda yakin"})+"?",
      text: intl.formatMessage({id:"Anda akan membuka penawaran"}),
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Ya, submit",
      cancelButtonText: "Tidak"
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        doSubmit(
          {},
          getEnv("tender") + `/bid/submit/${proposalTender.uuid}`,
          toast
        ).then((res: any) => {
          onQuotationChanged && onQuotationChanged();
        }).finally(() => {
        });
      } else {
      }
    });
  }

  useEffect(() => {
    if(proposalTender){
      initiateTab();
    }
  }, [proposalTender]);

  return (
    <>
      <div className={""}>
        <div className={"d-flex justify-content-between"}>
          <div><h3>{intl.formatMessage({id:"Peserta Tender"})}</h3></div>
        </div>
        <hr />
        <Nav appearance={"subtle"} activeKey={selectedTab} onSelect={(e:any)=>{setSelectedTab(e)}} style={{ marginBottom: 20 }}>
          {menuTab.map((item:any, index:number) => {
            return (
              <Nav.Item eventKey={item.eventKey} key={index} disabled={item?.disabled}>
                {item.label}
              </Nav.Item>
            );
          })}
        </Nav>

        {showTab()}

        <BottomToolbarWrapper onGoBack={()=>{}} children={
          <>
            {isShowBidOpening() && (
              <button
                type={"button"}
                disabled={isLoading}
                className={"btn btn-primary me-3"}
                onClick={() => onBidOpening()}
              >
                {isLoading ? (
                  <Spinner animation="border" role="status" size={"sm"} />
                ) : (
                  <i className={"fa fa-envelope-open"}></i>
                )}
                { proposalTender?.metode_penyampaian === '1 Sampul' ?
                  intl.formatMessage({id:"Pembukaan Penawaran"})
                  : proposalTender?.metode_penyampaian_step === 0
                    ? intl.formatMessage({id:"Pembukaan Penawaran Teknis"})
                    : intl.formatMessage({id:"Pembukaan Penawaran Komersil"})
                }
              </button>
            )}
          </>
        } />

      </div>
    </>
  );
};

export default PesertaTenderPage
