import {ID, KTCard, KTCardBody, KTSVG, QUERIES} from '../../../../../_metronic/helpers'
import React, {useState} from 'react'
import TableXWrpper from "../../../../components/shared/TableXWrapper";

const MasterDataIncotermList = () => {
  const [urlIndex,setUrlIndex] = useState(process.env.REACT_APP_API_URL+"/api/masterdata/incoterm");
  const tableColumns = [
    {Header: 'ID', accessor: 'id', width: 50, disableCreate: true, disableEdit: false, className:'text-center'},
    {Header: 'Code', accessor: 'code', width: 150, className:'text-center', tdClass:'text-center'},
    {Header: 'Name', accessor: 'name', width: 150},
    {Header: 'Active', accessor: 'is_active', width: 200, type:'boolean'},
  ]
  return (
    <>
      <KTCard>
        <KTCardBody className='py-4'>
          <TableXWrpper
              title={"Masterdata Plant"}
              urlIndex={urlIndex}
              columns={tableColumns}
          />
        </KTCardBody>
      </KTCard>
    </>
  )
}

export {MasterDataIncotermList}
