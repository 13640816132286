import {parseDateTimeToDBFormat, parseDBDateToJSFormat} from '../../../../../helpers/procurex'
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import * as Yup from 'yup'
import {Field, Form, Formik} from 'formik'
import DateRangePickerX from '../../../../components/shared/form/DateRangePickerX'
import {Spinner} from 'react-bootstrap'
import {toast} from 'react-toastify'
import VendorList from './VendorList'
import BottomToolbarWrapper from '../../../../components/shared/BottomToolbarWrapper'
import {useAanwijzing} from './core/_aanwijzingProvider'
import PanitiaList from './PanitiaList'


const GambaranUmumTab = ({proposalTender,isLoading, onSubmitForApproval, onClickSubmitForApproval}:any) => {
  const formik = useRef({} as any);
  const [formIndex, setFormIndex] = React.useState(0);

  // @ts-ignore
  const [defaultJadwal, setDefaultJadwal] = React.useState<[Date, Date]>([]);
  const [initialValues,setInitialValues] = useState({
    title: "",
    source: "",
    note: "",
    media: "",
    reference: "",
    // metode_aanwijzing: "",
    jadwal: "",
  });
  const formSchemaValidation = Yup.object().shape({
    title: Yup.string().required('Title harus diisi'),
    // metode_aanwijzing: Yup.string().required("Metode Aanwijzing harus diisi"),
    reference: Yup.string().nullable(),
    media: Yup.string().nullable(),
    note: Yup.string().nullable(),
    jadwal: Yup.string().required("Jadwal harus diisi")
  });
  const {aanwijzing, mutateSaveDraft, isLoadingSaveDraft} = useAanwijzing();


  //region store data
  const handleSaveDraft = async (values: any) => {
    const dateJadwal = JSON.parse(values.jadwal);
    const payload = {
      ...values,
      uuid: aanwijzing?.uuid,
      metode_aanwijzing: "E-Aanwijzing",
      // @ts-ignore
      start_at: parseDateTimeToDBFormat(dateJadwal[0]),
      // @ts-ignore
      end_at: parseDateTimeToDBFormat(dateJadwal[1]),
    }
    mutateSaveDraft({
      payload:payload
    },{
      onSuccess:(data) => {
        toast.success(data.data.meta.message)
        onSubmitForApproval && onSubmitForApproval()
      }
    })
  }

  //endregion


  useEffect(() => {
    if(aanwijzing){
      setInitialValues({
        ...initialValues,
        title: aanwijzing.title,
        reference: aanwijzing.reference,
        media: aanwijzing.media,
        note: aanwijzing.note,
        // metode_aanwijzing: aanwijzing.metode_aanwijzing,
        source: aanwijzing.source,
      })
      if(aanwijzing.start_at && aanwijzing.end_at) {
        setDefaultJadwal([parseDBDateToJSFormat(aanwijzing.start_at), parseDBDateToJSFormat(aanwijzing.end_at)])
      }

      //region set form enabled

      //endregion
      setFormIndex(formIndex+1)
    } else {
    }
  },[aanwijzing])

  const isFormEnabled = useMemo(()=>{
    const aanwijzingStatusAllowed = ["DRAFT","OPEN","REJECTED"];
    return aanwijzingStatusAllowed.includes(aanwijzing?.status)
  },[aanwijzing?.status])

  const handleOnSubmitForApproval = () => {
    const values = formik.current.values;
    const dateJadwal = JSON.parse(values.jadwal);
    const payload = {
      ...values,
      uuid: aanwijzing?.uuid,
      metode_aanwijzing: "E-Aanwijzing",
      // @ts-ignore
      start_at: parseDateTimeToDBFormat(dateJadwal[0]),
      // @ts-ignore
      end_at: parseDateTimeToDBFormat(dateJadwal[1]),
    }
    mutateSaveDraft({
      payload:payload
    },{
      onSuccess:(data) => {
        toast.success(data.data.meta.message)
        onClickSubmitForApproval && onClickSubmitForApproval();
      }
    })

  }

  return (
    <>
      <div className={"d-flex justify-content-between"}>


      </div>
      <Formik
        innerRef={formik}
        initialValues={initialValues}
        onSubmit={handleSaveDraft}
        validationSchema={formSchemaValidation}
        enableReinitialize
        validateOnChange={true}
        key={formIndex}
      >
        {({ errors, touched, values, setFieldValue, resetForm, submitForm }: any) => (
          <Form id="form-hps">
            <div className={"card mb-4"}>
              <div className={"card-body"}>
                <div className={"row"}>
                  <div className={"col-md-6 col-sm-12 col-lg-6"}>
                    <div className={"form-group mb-3"}>
                      <label className="required form-label">
                        Title
                      </label>
                      <Field
                        name="title"
                        type="text"
                        className="form-control"
                        disabled={!isFormEnabled}
                      />
                      {(errors.title && touched.title) && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.title}</div>
                        </div>
                      )}
                    </div>

                    {/*<div className={"form-group mb-3"}>*/}
                    {/*  <FormikSelect3*/}
                    {/*    label="Metode Aanwijzing"*/}
                    {/*    name="metode_aanwijzing"*/}
                    {/*    optionsUrl={`${getEnv('masterdata')}/enumeration?filter[type]=TENDER_METODE_AANWIJZING`}*/}
                    {/*    getOptionValue={(opt: any) => opt.value}*/}
                    {/*    getOptionLabel={(opt: any) =>*/}
                    {/*      `${opt.value}`*/}
                    {/*    }*/}
                    {/*    isDisabled={!isFormEnabled}*/}
                    {/*  />*/}
                    {/*  {(errors.metode_aanwijzing && touched.metode_aanwijzing) && (*/}
                    {/*    <div className="fv-plugins-message-container">*/}
                    {/*      <div className="fv-help-block">{errors.metode_aanwijzing}</div>*/}
                    {/*    </div>*/}
                    {/*  )}*/}
                    {/*</div>*/}

                    <div className={"form-group mb-3"}>
                      <label className="form-label">
                        Media
                      </label>
                      <Field
                        name="media"
                        type="text"
                        className="form-control"
                        label={"media"}
                        disabled={!isFormEnabled}
                        placeholder={"Ex: Zoom Link: http://zoom.us"}
                      />
                      {
                        (errors.media && touched.media) && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{errors.media}</div>
                          </div>
                        )
                      }
                    </div>

                    <div className={"form-group mb-3"}>
                      <label className="form-label required">
                        Jadwal
                      </label>
                      <DateRangePickerX defaultValue={defaultJadwal} onChange={(e: any) => {
                        if (e && e.length === 2) {
                          setFieldValue("jadwal", JSON.stringify(e))
                        } else {
                          setFieldValue("jadwal", "")
                        }
                      }} disabled={!isFormEnabled} />
                      {(errors.jadwal && touched.jadwal) && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.jadwal}</div>
                        </div>
                      )}
                    </div>

                  </div>
                  <div className={"col-md-6 col-sm-12 col-lg-6"}>
                    <div className={"form-group mb-3"}>
                      <label className="required form-label">
                        Source
                      </label>
                      <Field
                        name="source"
                        type="text"
                        className="form-control"
                        disabled={true}
                      />
                      {(errors.source && touched.source) && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.source}</div>
                        </div>
                      )}
                    </div>
                    <div className={"form-group mb-3"}>
                      <label className="form-label">
                        Nomor Referensi
                      </label>
                      <Field
                        name="reference"
                        type="text"
                        className="form-control"
                        label={"Incoterm"}
                        disabled={!isFormEnabled}
                      />
                      {
                        (errors.reference && touched.reference) && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{errors.reference}</div>
                          </div>
                        )
                      }
                    </div>
                    <div className={"form-group mb-3"}>
                      <label className="form-label">
                        Notes
                      </label>
                      <Field
                        name="note"
                        type="text"
                        className="form-control"
                        label={"note"}
                        disabled={!isFormEnabled}
                        as={"textarea"}
                      />
                      {
                        (errors.note && touched.note) && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{errors.note}</div>
                          </div>
                        )
                      }
                    </div>


                  </div>
                  {/*  End Col-6 */}

                  <div className={"col-md-12 col-sm-12 col-lg-12"}>

                  </div>
                </div>

              </div>
            </div>

            <div className={"card mb-4"}>
              <div className={'card-body'}>
                <div className={'col-md-12 col-sm-12 col-lg-12 mt-10 mb-10'}>
                  <VendorList />
                </div>
              </div>
            </div>

            <div className={"card"}>
              <div className={'card-body'}>
                <div className={'col-md-12 col-sm-12 col-lg-12 mt-10 mb-10'}>
                  <PanitiaList />
                </div>
              </div>
            </div>

            {/*  End Row */}

            <BottomToolbarWrapper>
              <div className={"form-group mb-3 text-end d-flex justify-content-end"}>
                <button
                  type="submit"
                  className="btn btn-info me-2"
                  onClick={() => {
                    handleSaveDraft(values)
                  }}
                  disabled={!isFormEnabled || isLoadingSaveDraft}
                >
                  {(isLoading || isLoadingSaveDraft) ? (
                    <Spinner animation="border" size="sm" className={"me-2"} />
                  ) : (
                    <i className="fa fa-save"></i>
                  )}
                  Save Draft
                </button>

                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleOnSubmitForApproval}
                  disabled={!isFormEnabled}
                >
                  {(isLoading || isLoadingSaveDraft) ? (
                    <Spinner animation="border" size="sm" className={"me-2"} />
                  ) : (
                    <i className="fa fa-paper-plane"></i>
                  )}
                  Submit for Approval
                </button>
              </div>
            </BottomToolbarWrapper>
          </Form>
        )}
      </Formik>


    </>
  )
}

export default GambaranUmumTab;