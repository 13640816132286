import axios from 'axios'
import React, {useEffect, useState} from 'react'
import {Col, Modal, Nav, Row} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import ProcCard, {Proc} from './ProcCard'
import {ApiUrl} from '../../../config/Api'
import * as Yup from 'yup'
import {Form, Formik} from 'formik'
import FormikSelect2 from '../../../components/shared/form/FormikSelect2'

const validationSchema = Yup.object().shape({})
const TenderingList = () => {
  const intl = useIntl()
  let [procs, setProcs] = useState<Proc[]>([])
  let [loading, setLoading] = useState<boolean>(true)
  const [initialValues, setInitialValues] = useState<any>({})
  const [newsType, setNewsType] = useState<any>(null)
  const [newsGroup, setNewsGroup] = useState<any>(null)
  const [title, setTittle] = useState<string>('')
  const [selectedNews, setSelectedNews] = useState<any>(null)
  useEffect(() => {
    loadData()
  }, [newsType, newsGroup, title])

  const loadData = async () => {
    let url = `${ApiUrl}/api/masterdata/public/news`
    let params = '?'
    if (newsType) params += 'news_type_id=' + newsType?.id
    if (newsGroup) params += '&news_group_id=' + newsGroup?.id
    if (title) params += '&title=' + title

    const response = await axios.get(`${url}${params}`)

    setProcs(response.data.data)
    setLoading(false)
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={() => {}}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({errors, touched, values, setFieldValue, resetForm}: any) => (
          <Form id='form-hps'>
            {/* Tabs */}
            <Nav className='justify-content-center' activeKey='/home'>
              <Nav.Item>
                <Nav.Link className='text-primary font-weight-bold'>
                  {intl.formatMessage({id: 'HOMEPAGE.ALL'})}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey='link-1'>
                  {intl.formatMessage({id: 'HOMEPAGE.OPEN_REGISTRATION'})}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey='link-2'>{intl.formatMessage({id: 'HOMEPAGE.CLOSED'})}</Nav.Link>
              </Nav.Item>
            </Nav>
            {/* End Tabs */}

            {/* Filtering */}
            <div className='row'>
              <div className='col-sm-12 col-md-4 col-lg-4'>
                <input
                  type='text'
                  className='form-control form-control-white'
                  placeholder={intl.formatMessage({id: 'HOMEPAGE.PROCUREMENT_SEARCH'})}
                  onChange={(e) => {
                    if (e.target.value.length < 3) {
                      setTittle('')
                      return
                    }
                    setTittle(e.target.value)
                  }}
                />
              </div>
              <div className='col-sm-12 col-md-4 col-lg-4'>
                <FormikSelect2
                  label=''
                  name='type'
                  optionsUrl={`${process.env.REACT_APP_API_URL}/api/masterdata/public/news-types`}
                  getOptionValue={(opt: any) => opt}
                  getOptionLabel={(opt: any) => `${opt.name}`}
                  onChangeValue={(val: any) => {
                    setNewsType(val)
                  }}
                  placeholder={'Pilih Tipe Pengadaan'}
                  isClearable={true}
                />
              </div>
              <div className='col-sm-12 col-md-4 col-lg-4'>
                <FormikSelect2
                  label=''
                  name='group'
                  optionsUrl={`${process.env.REACT_APP_API_URL}/api/masterdata/public/news-groups`}
                  getOptionValue={(opt: any) => opt}
                  getOptionLabel={(opt: any) => `${opt.name}`}
                  onChangeValue={(val: any) => {
                    setNewsGroup(val)
                  }}
                  placeholder={'Pilih Kelompok'}
                  isClearable={true}
                />
              </div>
            </div>
            {/* End Filtering */}

            <Row className='mt-10'>
              {procs?.map((proc) => (
                <>
                  <Col key={proc.id} lg='6' className='mb-5'>
                    <ProcCard proc={proc} onClick={(e: any) => setSelectedNews(e)}></ProcCard>
                  </Col>
                </>
              ))}
              {loading && <Col className='p-10 text-center'>Loading...</Col>}
              {procs?.length < 1 && !loading && <Col className='p-10 text-center'>Data Kosong</Col>}
            </Row>
          </Form>
        )}
      </Formik>
      <Modal
        show={selectedNews ? true : false}
        animation={true}
        onHide={() => {
          setSelectedNews(null)
        }}
        size={'lg'}
      >
        <Modal.Header closeButton>
          <Modal.Title>{selectedNews?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={'d-flex justify-content-between mb-4'}>
            <div>
              <small className={'text-muted text-sm'}>
                <i>{selectedNews?.created_at}</i>
              </small>
            </div>
            <div>
              <span className='badge badge-warning me-3'>{selectedNews?.group?.name}</span>
              <span className='badge badge-success me-3'>{selectedNews?.type?.name}</span>
            </div>
          </div>
          <div dangerouslySetInnerHTML={{__html: selectedNews?.description}} />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default TenderingList
