import React, { useEffect, useState } from "react";
import { useAxiosGet, useAxiosPost } from "../../../../../../hooks/useAxios";
import {
  additionNumber,
  formatNumberToDecimal,
  getEnv, isGreaterEqualNumber,
  multiplyNumber,
  parseNumberToCurrency,
  subtractNumber
} from "../../../../../../helpers/procurex";
import { Nav, Placeholder } from "rsuite";
import { toast } from "react-toastify";
import FormAttachment from "../../../../../components/shared/form/FormAttachment";
import PreviewAttachment from "../../../../../components/PreviewAttachment";
import CurrencyInput from "react-currency-input-field";
import Swal from "sweetalert2";
import { useIntl } from "react-intl";
import {Modal, Spinner} from 'react-bootstrap'
import {formatCurrency} from '../../../../../../_metronic/helpers'

const ColumnServiceNego = ({ proposalTender, item, service, onAfterSave }: any) => {
  const intl = useIntl();
  const [price, setPrice] = useState<any>(service?.net_price);
  const [disabled, setDisabled] = useState<any>(false);
  const [showKonfirmasiNego,setShowKonfirmasiNego] = useState(false)
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosPost(
    getEnv("tender") + `/vendor/negotiation/item-service/${proposalTender?.uuid}`
  );

  const isCanSubmit = () => {
    if(service?.status_negotiation === "OPEN"){
      // const previousNetPrice = service?.previous_negotiation?.net_price || null;
      // const currentNetPrice = service?.current_negotiation?.net_price || null;
      // if(service?.previous_negotiation?.net_price !== service?.current_negotiation?.net_price){
      // if (previousNetPrice !== currentNetPrice){
      //   return false;
      // }
      return !service?.is_submitted;
    }
    if(service?.status_negotiation === "APPROVE"){
      return false;
    }
    return true;
  }

  const handleSave = () => {
    Swal.fire({
      title: "Apakah Anda yakin?",
      text: "Anda akan mensubmit nego ini",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Ya, submit",
      cancelButtonText: "Tidak"
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const payload = {
          negotiation_item_id: item?.uuid,
          quantity: service?.quantity,
          price: formatNumberToDecimal(price),
        };
        const newUrl = getEnv("tender") + `/vendor/negotiation/item-service/${service?.uuid}`;
        doSubmit(payload, newUrl, toast).then((res: any) => {
          onAfterSave && onAfterSave();
          Swal.fire({
            title: intl.formatMessage({id:"Congratulation"}),
            text:  intl.formatMessage({id:"Your negotiation has been submitted"})  || "",
            icon: "success"
          });
        });
      } else {
      }
    });

  }

  const getStatusBadge = () => {
    if(service?.status_negotiation === "OPEN" && service?.is_submitted) {
      return (
        <span className={'badge badge-primary'}>SUBMITTED</span>
      )
    }
    return (
      <span className={'badge badge-success'}>
        {service?.status_negotiation}
      </span>
    )
  }

  return (
    <>
    <tr>
      <td className={'border'} style={{
        verticalAlign: 'top', position: 'sticky', minWidth: '20px', left: 0, zIndex: 1,
      }}></td>
      <td className={'border text-start px-2 bg-white'} colSpan={1} style={{
        minWidth: '315px',
        verticalAlign: 'top', position: 'sticky', left: 20, zIndex: 1,
      }}>{service?.short_text}</td>
      <td className={'border px-2 text-end'}>{service?.quantity}</td>
      <td className={'border px-2 text-center'}>{service?.uom_id}</td>
      <td className={'border px-2 text-center'}>{}</td>
      <td className={'border px-2 text-end'}>
        <ColumnNego
          amount={service?.previous_negotiation?.net_price}
          currency={service?.currency_id}
          onChange={(e: any) => setPrice(e)}
          value={formatNumberToDecimal(service?.current_negotiation?.net_price, 0)}
          disabled={item?.status_negotiation === 'APPROVE' || disabled || service?.is_submitted}
        />
      </td>
      <td className={'border px-2 text-end text-center'}>{service?.delivery_time ?? '-'} hari</td>
      <td className={'border px-2 text-end'}></td>
      <td className={'border px-2 text-end'}></td>
      <td className={"border px-2 text-center"} style={{
      verticalAlign: 'top', position: 'sticky', minWidth: '20px', right: 62, zIndex: 1, backgroundColor: '#fff'
      }}>
      {getStatusBadge()}
    </td>
    {/*<td className={"border px-2 text-center"}></td>*/}
    <td className={'border px-2 text-center'} style={{
      verticalAlign: 'top', position: 'sticky', minWidth: "20px", right: 0, zIndex: 1, backgroundColor: "#fff"
        }}>

          {item?.status_negotiation === "APPROVE" && (
            <span className={"badge badge-success"}>APPROVED</span>
          )}
          {item?.status_negotiation !== "APPROVE" && (
            <button
              className={"btn btn-sm btn-primary"}
              type={"button"}
              onClick={() => setShowKonfirmasiNego(true)}
              disabled={isLoading || !isCanSubmit()}>
              {isLoading && (
                <i className={"fa fa-spinner fa-spin"}></i>
              )}
              {!isLoading && (
                <i className={"fa fa-save"}></i>
              )}
            </button>
          )}
        </td>

      </tr>
      <Modal show={showKonfirmasiNego}>
        <Modal.Header>
          <h4>{intl.formatMessage({id:"Konfirmasi Nego"})}</h4>
        </Modal.Header>
        <Modal.Body>
          <>
            <table className="table table-striped">
              <tbody>
              <tr>
                <td><strong>{intl.formatMessage({id: 'Short Text'})}</strong></td>
                <td className={"text-end"}>{service?.short_text}</td>
              </tr>
              <tr>
                <td><strong>{intl.formatMessage({id: 'Quantity'})}</strong></td>
                <td className={"text-end"}>{service?.quantity} {service?.uom_id}</td>
              </tr>
              <tr>
                <td><strong>{intl.formatMessage({id: 'Currency'})}</strong></td>
                <td className={"text-end"}>{service?.currency_id}</td>
              </tr>
              <tr>
                <td><strong>{intl.formatMessage({id: 'Harga Satuan Sebelum Nego'})}</strong></td>
                <td className={"text-end"}>{formatCurrency(service?.previous_negotiation?.net_price)}</td>
              </tr>
              <tr>
                <td><strong>{intl.formatMessage({id: 'Harga Satuan Setelah Nego'})}</strong></td>
                <td className={'text-end'}>
                  <strong>{formatCurrency(price)}</strong></td>
              </tr>
              </tbody>
            </table>

          </>
        </Modal.Body>
        <Modal.Footer>
          <>
            <button
              className={'btn btn-danger'}
              type={'button'}
              onClick={() => {
                setShowKonfirmasiNego(false)
              }}
              disabled={isLoading}
            >
              <i className={"fa fa-times me-1"}></i>
              {intl.formatMessage({id:"Cancel"})}
            </button>
            <button
              className={'btn btn-primary'}
              type={'button'}
              onClick={() => {
                handleSave()
              }}
              disabled={isLoading}
            >
              {!isLoading && <i className={'fa fa-check me-1'}></i>}
              {isLoading && <Spinner size={"sm"} className={"me-1"} /> }
              Submit
            </button>
          </>
        </Modal.Footer>


      </Modal>
    </>
  );
};

const ColumnNego = ({ amount, value, currency, disabled, onChange }: any) => {
  const [amount1, setAmount1] = useState<any>(amount);
  const [amount2, setAmount2] = useState<any>(value || 0);
  const [amount3, setAmount3] = useState<any>(0);
  const [isRedLabel, setIsRedLabel] = useState<any>(false);

  const calculateAmount = (a: any) => {
    setAmount2(a);
    setAmount3(subtractNumber(a, amount1));
    setIsRedLabel(isGreaterEqualNumber(a, amount1));
    onChange && onChange(a);
  };

  const handleAmount2 = (e: any) => {
    // calculateAmount(e.target.value)
    calculateAmount(e);
  };

  useEffect(() => {
    calculateAmount(amount2);
  }, []);

  useEffect(() => {
    calculateAmount(value);
  }, [value]);


  return (
    <>
      <div>
        {parseNumberToCurrency(amount1, currency)}
        {/*<CurrencyInput*/}
        {/*  className="form-control form-control-sm text-end"*/}
        {/*  groupSeparator="."*/}
        {/*  decimalSeparator=","*/}
        {/*  decimalsLimit={0}*/}
        {/*  value={amount1}*/}
        {/*  style={{*/}
        {/*    minWidth: "100px"*/}
        {/*  }}*/}
        {/*  disabled={true}*/}
        {/*/>*/}
      </div>
      <div>
        {/*<input type="text" value={amount2} className="text-end" onChange={handleAmount2} disabled={disabled} />*/}
        <CurrencyInput
          className="form-control form-control-sm text-end"
          groupSeparator="."
          decimalSeparator=","
          decimalsLimit={2}
          value={amount2}
          onValueChange={(value, name) => {
            handleAmount2(value);
          }}
          style={{
            minWidth: "100px"
          }}
          disabled={disabled}
        />
      </div>
      <div className={"pt-2 " + (isRedLabel ? "text-success" : "text-danger")}>
        <strong>{parseNumberToCurrency(amount3, currency)}</strong>
        {/*<CurrencyInput*/}
        {/*  className="form-control form-control-sm text-end"*/}
        {/*  groupSeparator="."*/}
        {/*  decimalSeparator=","*/}
        {/*  decimalsLimit={2}*/}
        {/*  value={amount3}*/}
        {/*  style={{*/}
        {/*    minWidth: "100px"*/}
        {/*  }}*/}
        {/*  disabled={true}*/}
        {/*/>*/}
      </div>
    </>
  );
};

const TRNego = ({ item, index, proposalTender, onAfterSave }: any) => {
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosPost(
    getEnv("tender") + `/vendor/negotiation/item/${proposalTender?.negotiation?.uuid}`
  );
  const [quantity, setQuantity] = useState(item?.quantity);
  const [nettPrice, setNettPrice] = useState(0);
  const [addCost, setAddCost] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [price, setPrice] = useState(0);
  const intl = useIntl()
  const [showPreview, setShowPreview] = useState(false)
  const [showKonfirmasiNego,setShowKonfirmasiNego] = useState(false)

  const handleSave = () => {
    const payload = {
      negotiation_id: proposalTender?.negotiation?.uuid,
      quantity: item?.quantity,
      price: formatNumberToDecimal(price),
      additional_cost: formatNumberToDecimal(addCost)
    };
    const newUrl = getEnv("tender") + `/vendor/negotiation/item/${item?.uuid}`;
    doSubmit(payload, newUrl, toast).then((res: any) => {
      onAfterSave && onAfterSave()
      Swal.fire({
        title: intl.formatMessage({id:"Congratulation"}),
        text:  intl.formatMessage({id:"Your negotiation has been submitted"})  || "",
        icon: "success"
      });
    });
  };

  const calculate = () => {
    const _nettPrice = multiplyNumber(quantity, price);
    const _addCost = addCost;
    const _subtotal = additionNumber(_nettPrice, _addCost);
    setNettPrice(_nettPrice);
    setSubTotal(_subtotal);
  };

  useEffect(() => {
    calculate();
  }, [price, addCost, subTotal]);

  const isFormEnabled = () => {
    if(proposalTender?.status === "Negotiation" && proposalTender?.status_detail !== "Proses Negosiasi") return false;
    if(item?.status_negotiation === "OPEN") {
      // return (item?.previous_negotiation?.net_price === item?.current_negotiation?.net_price)
      return !item?.is_submitted;
    }
    if(item?.status_negotiation === "APPROVE") return false;
    return true;
  }

  if(proposalTender?.status_detail === "Auction"){
    return (
      <>
      <tr key={index}>
      <td className={"border px-2 text-start"} colSpan={14}>
        <i>Negotiation via Auction</i>
      </td>
      </tr>
      </>
    )
  }


  return (
    <>
      <tr key={index}>
        <td className={"border px-2 text-center bg-white"} style={{
          verticalAlign: "top", position: "sticky", minWidth: "20px", left: 0, zIndex: 1
        }}>{index + 1}</td>
        <td className={'border px-2 bg-white'} style={{
          minWidth: '300px',
          verticalAlign: 'top', position: 'sticky', left: 30, zIndex: 1,
        }}>
          <div className={"border-bottom mb-2"}>
            <small className={"text-muted"}>{intl.formatMessage({id:"Short Text"})}</small><br/> {item.short_text}
          </div>
          <div className={'border-bottom mb-2'}>
            <small className={'text-muted'}>{intl.formatMessage({id:"Quantity"})}</small><br />{quantity}
          </div>
          <div className={'border-bottom mb-2'}>
            <small className={"text-muted"}>UOM</small><br />{item?.uom_id}
          </div>
          <div className={'border-bottom mb-2'}>
            <small className={"text-muted"}>{intl?.formatMessage({id:"Currency"})}</small><br />{item?.currency_id}
          </div>
        </td>
        {/*<td className={'border px-2 text-end'}>{quantity}</td>*/}
        {/*<td className={"border px-2 text-center"}>{item?.uom_id}</td>*/}
        {/*<td className={"border px-2 text-center"}>{item?.currency_id}</td>*/}
        <td className={"border px-2 text-end text-nowrap"}>
          {/*Unit Price*/}
          {item?.serviceLines?.length === 0 && (
            <ColumnNego
              amount={item?.previous_negotiation?.price}
              currency={item?.quotation?.currency_id}
              onChange={(e: any) => setPrice(e)}
              disabled={!isFormEnabled()}
              value={formatNumberToDecimal(item?.current_negotiation?.price, 0)}
            />
          )}


        </td>
        <td className={"border px-2 text-end text-nowrap"}>
          {/*Nett Price*/}
          {item?.serviceLines?.length === 0 && (
            <ColumnNego
              amount={item?.previous_negotiation?.net_price}
              currency={item?.currency_id}
              disabled={true}
              value={formatNumberToDecimal(nettPrice, 0)}
            />
          )}
        </td>
        <td className={"border px-2 text-end text-nowrap"}>
          {/*Add cost*/}
          {item?.serviceLines?.length === 0 && (
            <ColumnNego
              amount={item?.previous_negotiation?.additional_cost}
              currency={item?.quotation?.currency_id}
              onChange={(e: any) => setAddCost(e)}
              // disabled={!isFormEnabled()}
              disabled={true}
              value={formatNumberToDecimal(item?.current_negotiation?.additional_cost, 0)}
            />
          )}
        </td>
        <td className={"border px-2 text-end text-nowrap"}>
          {/*{item?.serviceLines?.length === 0 && (*/}
            <ColumnNego
              amount={item?.previous_negotiation?.total}
              disabled={true}
              currency={item?.quotation?.currency_id}
              value={formatNumberToDecimal(subTotal, 0)}
            />
          {/*)}*/}
        </td>
        <td className={"border px-2 text-center text-dark"}>{item?.delivery_time} hari</td>
        <td className={"border px-2 text-center"}>{item?.remark || "-"}</td>
        <td className={"border px-2 text-start"}>
          <textarea className={"form-control form-control-sm"} disabled={true} rows={8}>{item?.rejection_note || "-"}</textarea>
          <a href={"javascript:;"} className={"mt-2 text-primary"} onClick={()=>setShowPreview(true)}>
            <i className={"fa fa-eye"}></i> Preview
          </a>

        </td>
        <td className={"border px-2 text-center"} style={{
          verticalAlign: "top", position: "sticky", minWidth: "20px", right: 62, zIndex: 1, backgroundColor: "#fff"
        }}>
          {item?.status_negotiation === "REJECT" && (
            <span className={"badge badge-danger"}>REJECT</span>
          )}
          {(item?.status_negotiation !== "REJECT" && item?.is_submitted) && (
            <span className={"badge badge-info"}>
              Submitted
            </span>
          )}
          {(item?.status_negotiation !== "REJECT" && !item?.is_submitted) && (
            <span className={"badge badge-success"}>
              {item?.status_negotiation}
            </span>
          )}
        </td>
        <td className={"border px-2 text-center"} style={{
          verticalAlign: "top", position: "sticky", minWidth: "20px", right: 0, zIndex: 1, backgroundColor: "#fff"
        }}>
          {item?.serviceLines?.length === 0 && (
            <button
              className={"btn btn-sm btn-primary"}
              type={"button"}
              onClick={() => setShowKonfirmasiNego(true)}
              disabled={
                isLoading
                || !isFormEnabled()
            }>
              {isLoading && (
                <i className={"fa fa-spinner fa-spin"}></i>
              )}
              {!isLoading && (
                <i className={"fa fa-save"}></i>
              )}
            </button>
          )}
        </td>
      </tr>
      {item?.serviceLines?.length > 0 && (
        <>
          {item?.serviceLines?.map((v: any, i: number) => {
            return (
              <ColumnServiceNego
                key={'service-line'+i}
                item={item}
                proposalTender={proposalTender}
                service={v}
                onAfterSave={onAfterSave}
              />
            );
          })}
        </>
      )}
      <Modal show={showPreview}>
        <Modal.Header>
          <h4>Rejection Note</h4>
        </Modal.Header>
        <Modal.Body>
          <>
            <p>
              {item?.rejection_note}
            </p>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            className={'btn btn-primary'}
            type={'button'}
            onClick={() => {
              setShowPreview(false)
            }}
          >
              <i className={'fa fa-thumbs-up'}></i>
            Ok
          </button>
        </Modal.Footer>


      </Modal>

      <Modal show={showKonfirmasiNego}>
        <Modal.Header>
          <h4>{intl.formatMessage({id:"Konfirmasi Nego"})}</h4>
        </Modal.Header>
        <Modal.Body>
          <>
            <table className="table table-striped">
              <tbody>
              <tr>
                <td><strong>{intl.formatMessage({id: 'Short Text'})}</strong></td>
                <td className={"text-end"}>{item?.short_text}</td>
              </tr>
              <tr>
                <td><strong>{intl.formatMessage({id: 'Quantity'})}</strong></td>
                <td className={"text-end"}>{item?.quantity} {item?.uom_id}</td>
              </tr>
              <tr>
                <td><strong>{intl.formatMessage({id: 'Currency'})}</strong></td>
                <td className={"text-end"}>{item?.currency_id}</td>
              </tr>
              <tr>
                <td><strong>{intl.formatMessage({id: 'Harga Satuan Sebelum Nego'})}</strong></td>
                <td className={"text-end"}>{formatCurrency(item?.previous_negotiation?.price)}</td>
              </tr>
              <tr>
                <td><strong>{intl.formatMessage({id: 'Sub Total Sebelum Nego'})}</strong></td>
                <td className={"text-end"}>{formatCurrency(item?.previous_negotiation?.total)}</td>
              </tr>
              <tr>
                <td><strong>{intl.formatMessage({id: 'Harga Satuan Setelah Nego'})}</strong></td>
                <td className={'text-end'}>
                  <strong>{formatCurrency(price)}</strong></td>
              </tr>
              <tr>
              <td><strong>{intl.formatMessage({id: 'Sub Total Setelah Nego'})}</strong></td>
                <td className={'text-end'}>
                  <strong>{formatCurrency(nettPrice)}</strong></td>
              </tr>
              </tbody>
            </table>

          </>
        </Modal.Body>
        <Modal.Footer>
          <>
            <button
              className={'btn btn-danger'}
              type={'button'}
              onClick={() => {
                setShowKonfirmasiNego(false)
              }}
              disabled={isLoading}
            >
              <i className={"fa fa-times me-1"}></i>
              {intl.formatMessage({id:"Cancel"})}
            </button>
            <button
              className={'btn btn-primary'}
              type={'button'}
              onClick={() => {
                handleSave()
              }}
              disabled={isLoading}
            >
              {!isLoading && <i className={'fa fa-check me-1'}></i>}
              {isLoading && <Spinner size={"sm"} className={"me-1"} /> }
              Submit
            </button>
          </>
        </Modal.Footer>


      </Modal>
    </>
  );
};

const ProsesNego = ({proposalTender}: any) => {
  const [dataItems, setDataItems] = useState<any>([])
  const [renderKey, setRenderKey] = useState(0)
  const [jadwalMulaiNego, setJadwalMulaiNego] = useState<any>('')
  const [jadwalSelesaiNego, setJadwalSelesaiNego] = useState<any>('')
  const [attachment, setAttachment] = useState<any>([] as any)
  const intl = useIntl()
  const [{doSubmit, isLoading, isError, data, isSuccess}] = useAxiosGet(
    getEnv('tender') + `/vendor/negotiation/${proposalTender.negotiation?.uuid}`,
  )

  const getNegotiation = async () => {
    doSubmit({}, undefined, undefined).then((res: any) => {
      setDataItems(res?.data?.items)
      setJadwalMulaiNego(res?.data?.start_at)
      setJadwalSelesaiNego(res?.data?.end_at)
      setAttachment(res?.data?.attachments)
      setRenderKey(renderKey + 1)
    })
  }

  useEffect(() => {
    getNegotiation()
  }, [])

  return (
    <>
    <div className={"row py-5"}>
        <div className={"col-md-6"}>
          
          <div className={"form-group mb-4"}>
            <label className="required form-label">
              {intl.formatMessage({id:"Jadwal Mulai Sesi Negosiasi"})}
            </label>
            <input type="text" className="form-control" disabled={true} value={jadwalMulaiNego} />
          </div>

          <div className={"form-group mb-4"}>
            <label className="required form-label">
              {intl.formatMessage({id:"Jadwal Selesai Sesi Negosiasi"})}
            </label>
            <input type="text" className="form-control" disabled={true} value={jadwalSelesaiNego} />
          </div>

          <div className={"form-group mb-4"}>
            <label className="required form-label">
              {intl.formatMessage({id:"Catatan"})}
            </label>
            <textarea className="form-control" disabled={true} style={{whiteSpace:'pre-wrap'}}>{proposalTender?.negotiation?.note}</textarea>
          </div>

        </div>
        <div className={"col-md-6"}>
          <div className={"form-group mb-4"}>
            <label className="required form-label">
              {intl.formatMessage({id:"Jenis Negosiasi"})}
            </label>
            <input type="text" className="form-control" disabled={true} value={"-"} />
          </div>
          <div className={"form-group mb-4"}>
            <label className="required form-label">
              {intl.formatMessage({id:"Order Placement"})}
            </label>
            <input type="text" className="form-control" disabled={true} value={proposalTender?.order_placement} />
          </div>
        </div>
      </div>
      <hr />
      <div>
        <div className={'d-flex justify-content-between mb-3'}>
          <h3>{intl.formatMessage({id: 'Proses Negosiasi'})}</h3>
          <div>
            <button className={'btn btn-sm btn-primary'} type={'button'} onClick={() => getNegotiation()}
                    disabled={isLoading}>
              <i className={'fa fa-refresh'}></i> {intl.formatMessage({id: 'Refresh'})}
            </button>
          </div>
        </div>

        {isLoading && (
          <Placeholder.Grid rows={5} columns={8} active />
        )}
        {!isLoading && (
          <div className={'table-responsive'} key={renderKey}>

            <table className={'table table-header-gray'}>
              <thead>
              <tr>
                <th className={'border px-2 text-center'} style={{
                  verticalAlign: 'top', position: 'sticky', minWidth: '20px', left: 0, zIndex: 1,
                }}>
                  {intl.formatMessage({id: 'No'})}
                </th>
                <th className={'border px-2'} style={{
                  minWidth: '300px',
                  verticalAlign: 'top', position: 'sticky', left: 30, zIndex: 1,
                }}>{intl.formatMessage({id: 'Deskripsi'})}
                </th>
                {/*<th className={'border px-2 text-end'}>*/}
                {/*  {intl.formatMessage({id: 'QTY'})}*/}
                {/*</th>*/}
                {/*<th className={'border px-2 text-center'}>*/}
                {/*  {intl.formatMessage({id: 'UOM'})}*/}
                {/*</th>*/}
                {/*<th className={'border px-2 text-center'}>*/}
                {/*  {intl.formatMessage({id: 'Mata Uang'})}*/}
                {/*</th>*/}
                <th className={'border px-2 text-end text-nowrap'}>
                  {intl.formatMessage({id: 'Harga Satuan'})}
                </th>
                <th className={'border px-2 text-end text-nowrap'}>
                  {intl.formatMessage({id: 'Nett Price'})}
                </th>
                <th className={'border px-2 text-end text-nowrap'}>
                  {intl.formatMessage({id: 'Add Cost'})}
                </th>
                <th className={'border px-2 text-end text-nowrap'}>
                  {intl.formatMessage({id: 'Sub Total'})}
                </th>
                <th className={'border px-2 text-center text-nowrap'}>
                  {intl.formatMessage({id: 'Waktu Pengiriman'})}
                </th>
                <th className={'border px-2 text-center'}>
                  {intl.formatMessage({id: 'Catatan'})}
                </th>
                <th className={'border px-2 text-center text-nowrap'} style={{
                  minWidth: 200,
                }}>
                  {intl.formatMessage({id: 'Rejection Note'})}
                </th>
                <th className={'border px-2 text-center'} style={{
                  verticalAlign: 'top', position: 'sticky', minWidth: '20px', right: 62, zIndex: 1,
                }}>
                  {intl.formatMessage({id: 'Status'})}
                </th>
                <th className={'border px-2 text-center'} style={{
                  verticalAlign: 'top', position: 'sticky', minWidth: '20px', right: 0, zIndex: 1,
                }}>
                  {intl.formatMessage({id: 'Aksi'})}
                </th>
              </tr>
              </thead>
              <tbody>
              {dataItems?.length === 0 && (
                <tr>
                  <td className={'text-center border px-2'} colSpan={14}>
                    {intl.formatMessage({id: 'Tidak ada data'})}
                  </td>
                </tr>
              )}
              {dataItems.map((v: any, i: number) => {
                return (
                  <TRNego
                    key={i}
                    item={v}
                    index={i}
                    proposalTender={proposalTender}
                    onAfterSave={getNegotiation}
                  />
                )
              })}
              </tbody>
            </table>
          </div>
        )}

      </div>
      {(attachment.length > 0 && dataItems.length > 0) && (
        <>
          <hr />
          <div>
            <DokumenNego attachment={attachment} proposalTender={proposalTender} />
          </div>
        </>
      )}

    </>
  );
};

const DokumenNego = ({proposalTender, attachment}: any) => {
  const [syarats, setSyarats] = React.useState(attachment);
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosPost(
    getEnv("tender") + `/vendor/negotiation/requirement/${proposalTender?.negotiation?.uuid}`
  );
  const intl = useIntl();
  const [previewAttachment, setPreviewAttachment] = React.useState("");
  const isFormEnabled = () => {
    return true;
  };
  const handlePreviewAttachment = (e: any, url: any) => {
    e.preventDefault();
    setPreviewAttachment(url);
  };

  const handleAfterSubmit = (e: any, item: any, index: number) => {
    const payload = {
      negotiation_attachment_id: item.uuid,
      media_url: e.url,
      media_type: e.type
    };
    doSubmit(payload, null, toast).then((res: any) => {
      setSyarats((prevState: any) => {
        prevState[index].media_url = e.url;
        return prevState;
      });
    });
  };

  useEffect(() => {
  }, []);


  return (
    <>
      <h3>{intl.formatMessage({id:'Dokumen Negosiasi'})}</h3>
      <div className={"table-responsive"}>
        <table className={"table table-header-gray"}>
          <thead>
          <tr>
            <th className={"border px-2 text-center"} style={{
              width: "50px"
            }}>
              {intl.formatMessage({id:"No"})}
            </th>
            <th className={"border px-2 text-start"}>{intl.formatMessage({id:"Deskripsi"})}</th>
            <th className={"border px-2 text-center"}>{intl.formatMessage({id:'Mandatory'})}</th>
            <th className={"border px-2 text-center"}>{intl.formatMessage({id:'File'})}</th>
          </tr>
          </thead>
          <tbody>
          {syarats?.map((v: any, i: number) => {
            return (
              <tr key={"nego-attachment-" + i}>
                <td className={"border px-2 text-center"}>{i + 1}</td>
                <td className={"border px-2 text-start"}>{v?.description}</td>
                <td className={"border px-2 text-center"}>{v?.required ? "Ya" : "Tidak"}</td>
                <td className={"border px-2 text-start"}>
                  <FormAttachment
                    isSmall={true}
                    isHideLabel={true}
                    media_type_filter={"pq_vendor_attachment"}
                    onlyFile={true}
                    isLoading={isLoading}
                    afterSubmit={(e: any) => handleAfterSubmit(e, v, i)}
                    isDisabled={!isFormEnabled()}
                  />
                  {v?.media_url && (
                    <>
                      <a href={"#"} onClick={(e: any) => handlePreviewAttachment(e, v.media_url)}
                         className={"text-primary"}>
                        <small><i className={"fa fa-eye"}></i> Lihat File</small>
                      </a>
                    </>
                  )}
                </td>
              </tr>
            );
          })}
          </tbody>
        </table>
        <PreviewAttachment previewExt={previewAttachment} onHide={() => setPreviewAttachment("")} />
      </div>
    </>
  );
};

const RiwayatNego = ({proposalTender}: any) => {
  const [history, setHistory] = useState([] as any);
  const [{ doSubmit, isLoading }] = useAxiosGet(
    getEnv("tender") + `/vendor/negotiation/history/${proposalTender?.negotiation?.uuid}`
  );
  const intl = useIntl();

  const getHistory = async () => {
    doSubmit({},undefined,undefined).then((res:any)=>{
      setHistory(res?.data)
    })
  }

  useEffect(() => {
    getHistory()
  }, []);

  return (
    <>
      <div className={"table-responsive"}>
        <table className={"table table-header-gray"}>
          <thead>
          <tr>
            <th className={"border px-2"} style={{width:50, position:"sticky", left:0}}>
              {intl.formatMessage({id:"No"})}
            </th>
            <th className={"border px-2"} style={{minWidth: 200, position:"sticky", left:30}}>
              {intl.formatMessage({id:"Item"})}
            </th>
            <th className={"border px-2"} style={{minWidth: 250}}>
              {intl.formatMessage({id:"Negosiasi Saat Ini"})}
            </th>
            <th className={"border px-2"}  colSpan={history[0]?.histories?.length+1}>
              {intl.formatMessage({id:"Riwayat Negosiasi"})}
            </th>
          </tr>
          </thead>
          <tbody>
          {history?.length === 0 && (
            <tr>
              <td className={"border text-center"} colSpan={4}>
                {intl.formatMessage({id:"Tidak ada data"})}
              </td>
            </tr>
          )}
          {history?.map((item: any, index: number) => {
            return (
              <tr key={"history-negosiasi-"+index}>
                <td className={"border px-2 text-center"} style={{position:"sticky", left:0, backgroundColor: "white"}}>{index+1}</td>
                <td className={"border px-2 text-start"} style={{position:"sticky", left:30, backgroundColor: "white"}}>{item?.short_text}</td>
                <td className={"border px-2 text-start"}>
                  <div>
                    <div className={"border-bottom mb-2 d-flex flex-column"}>
                      <div><strong>{intl.formatMessage({id:'Waktu Submit'})} :</strong></div>
                      <div>-</div>
                    </div>
                    <div className={"border-bottom mb-2 d-flex flex-column"}>
                      <div><strong>{intl.formatMessage({id:'Harga Sebelum Nego / Total'})}: </strong></div>
                      <div>{parseNumberToCurrency(item?.previous_negotiation?.price)} / {parseNumberToCurrency(item?.previous_negotiation?.sub_total)}</div>
                    </div>
                    <div className={"border-bottom mb-2 d-flex flex-column"}>
                      <div><strong>{intl.formatMessage({id:'Harga Nego / Total'})}: </strong></div>
                      <div className={"bg-warning"}>{parseNumberToCurrency(item?.current_negotiation?.price)} / {parseNumberToCurrency(item?.current_negotiation?.sub_total)}</div>
                    </div>
                    <div className={"border-bottom mb-2 d-flex flex-column"}>
                      <div><strong>{intl.formatMessage({id:'Kuantitas'})}: </strong></div>
                      <div>{(item?.previous_negotiation?.quantity)}</div>
                    </div>
                    <div className={"border-bottom mb-2 d-flex flex-column"}>
                      <div><strong>{intl.formatMessage({id:'Waktu Pengiriman'})} : </strong></div>
                      <div>{(item?.histories[0]?.delivery_time)}</div>
                    </div>
                    <div className={"text-start"}>
                      {!item?.process && (
                        <span className={"badge badge-success"}>Status Approved</span>
                      )}
                      {item?.process && (
                        <span className={"badge badge-info"}>Pending</span>
                      )}
                    </div>
                  </div>
                </td>
                {item?.histories?.reverse()?.map((historyItem: any, index2: number) => {
                  return (
                    <td className={"border px-2 text-start"} key={"history-negosiasi-item-"+index2} style={{minWidth: 250}}>
                      <div>
                        <div className={"border-bottom mb-2 d-flex flex-column"}>
                          <div><strong>{intl.formatMessage({id:'Sesi'})} / {intl.formatMessage({id:'Waktu Submit'})}  :</strong></div>
                          <div>
                            {historyItem?.session + 1} /
                          </div>
                        </div>
                        <div className={"border-bottom mb-2 d-flex flex-column"}>
                          <div><strong>{intl.formatMessage({id:'Harga Sebelum Nego / Total'})}: </strong></div>
                          <div>{parseNumberToCurrency(historyItem?.previous_price)} / {parseNumberToCurrency(historyItem?.previous_sub_total)}</div>
                        </div>
                        <div className={"border-bottom mb-2 d-flex flex-column"}>
                          <div><strong>{intl.formatMessage({id:'Harga Nego / Total'})}: </strong></div>
                          <div className={"bg-warning"}>{parseNumberToCurrency(historyItem?.price)} / {parseNumberToCurrency(historyItem?.sub_total)}</div>
                        </div>
                        <div className={"border-bottom mb-2 d-flex flex-column"}>
                          <div><strong>{intl.formatMessage({id:'Kuantitas'})} : </strong></div>
                          <div>{(historyItem?.quantity)}</div>
                        </div>
                        <div className={"border-bottom mb-2 d-flex flex-column"}>
                          <div><strong>{intl.formatMessage({id:'Waktu Pengiriman'})} : </strong></div>
                          <div>{(historyItem?.delivery_time)}</div>
                        </div>
                        <div className={"text-start"}>
                          {!historyItem?.process && (
                            <span className={"badge badge-success"}>Status Approved</span>
                          )}
                          {historyItem?.process && (
                            <span className={"badge badge-info"}>Pending</span>
                          )}
                        </div>
                      </div>
                    </td>
                  )
                })}
              </tr>
            )
          })}
          </tbody>
        </table>
      </div>
    </>
  )
}

const NegotiationVendorPage = ({ proposalTender, onRefresh }: any) => {
  const [selectedTab, setSelectedTab] = useState<any>("prosesNego");
  const [currentProposalTender, setCurrentProposalTender] = useState(proposalTender);
  const intl = useIntl();

  useEffect(() => {
    setCurrentProposalTender(proposalTender);
  }, []);

  return (
    <div className={"card"}>
      <div className={"card-body"}>
        <div className={"d-flex justify-content-between"}>
          <h3>{intl.formatMessage({id:"Negosiasi"})}</h3>
        </div>
        <hr />
        <Nav appearance={"subtle"} activeKey={selectedTab} onSelect={(e: any) => {
          setSelectedTab(e);
        }} style={{ marginBottom: 20 }}>
          <Nav.Item eventKey={"prosesNego"}>
            {intl.formatMessage({id:"Proses Negosiasi"})}
          </Nav.Item>
          <Nav.Item eventKey={"riwayatNego"}>
            {intl.formatMessage({id:"Riwayat Negosiasi"})}
          </Nav.Item>

        </Nav>

        {selectedTab === "prosesNego" && (
          <ProsesNego
            proposalTender={currentProposalTender}
          />
        )}

        {selectedTab === "riwayatNego" && (
          <RiwayatNego
            proposalTender={currentProposalTender}
          />
        )}
      </div>
    </div>
  );
};

export default NegotiationVendorPage;