import { getEnv } from '../../../../../../helpers/procurex';
import { Col, Row } from 'react-bootstrap'
import { useEffect, useState } from 'react';

import axios from 'axios';
import TableXWrapper from '../../../../../components/shared/TableXWrapper'

interface Props {
  id?: any
}

const BadgeUser = ({causerId}:any)=>{
  const [user,setUser] = useState({} as any)

  const getCauser = () => {
    axios.get(getEnv('sso') + `/user/${causerId}`).then(function (response: any) {
      setUser(response?.data?.data)
    })
  }

  useEffect(()=>{
    getCauser()
  },[])

  return (
    <>
      {user && (
        user?.name + " - " + user?.username
      )}
    </>
  )
}

const TitleUser = ({data}:any)=>{
  const [user,setUser] = useState({} as any)
  const [vendor,setVendor] = useState(null as any)

  const getCauser = () => {
    axios.get(getEnv('sso') + `/user/${data?.causer_id}`).then(function (response: any) {
      setUser(response?.data?.data)
      setVendor(/Vendor/.test(data?.description))
    })
  }

  useEffect(()=>{
    getCauser()
  },[])

  return (
    <>
      {user && (
        vendor
          ? "Vendor"
          : user?.employee
            ? (user?.employee?.pos_kategori)
            : ""
      )}
    </>
  )
}

const History = ({id}: Props) => {

  const urlIndex = getEnv("tender") + `/log?filter[subject_id]=${id}&filter[log_name]=Purchase Order`
  const tableColumns = [
    {Header: 'ID', accessor: 'id', width: 50, disableCreate: true, disableEdit: true},
    {
      Header: 'Pegawai',
      accessor: 'causer_id',
      width: 300,
      Cell: (props: any) => {
        return (
          <BadgeUser causerId={props?.cell?.row?.original?.causer_id} />
        )
      },
    },
    {
      Header: 'Jabatan',
      accessor: 'properties',
      width: 300,
      Cell: (props: any) => {
        return (
          <TitleUser data={props?.cell?.row?.original} />
        )
      },
    },
    {Header: 'Status', accessor: 'description', width: 500},
    {Header: 'Komentar', accessor: 'comment', width: 500},
    {
      Header: 'Tanggal',
      accessor: 'created_at',
      width: 175,
      Cell: (props: any) => {
        const data = props.cell.row.original

        return Intl.DateTimeFormat('id-ID', {dateStyle: 'medium', timeStyle: 'medium'}).format(
          new Date(data.created_at)
        )
      },
    },
  ]
  
  return (
    <>
      <div className='card'>
        <div className='card-body'>
          <Row>
            <Col>
              <TableXWrapper
                readonly
                title={'History'}
                urlIndex={urlIndex}
                columns={tableColumns}
              />
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default History
