import React, {useEffect, useState} from 'react'
import {useField} from 'formik'
import axios from 'axios'
import {GetOptionLabel, GetOptionValue, Options} from 'react-select/dist/declarations/src'
import {AsyncPaginate} from 'react-select-async-paginate'

interface FormikSelectProps {
  label?: any
  name: string
  optionsUrl: string
  getOptionLabel: GetOptionLabel<React.SetStateAction<any>>
  getOptionValue: GetOptionValue<React.SetStateAction<any>>
  onChange?: (value: any) => void
  onChangeValue?: Function
  unique_property?: string
  border?: boolean
  fontSize?: string
  width?: string
  defaultValue?: any
  search_property?: string | Array<string>
  not_required?: boolean
  [key: string]: any
  isDisabled?: boolean
  isOptionDisabled?: (option: null, selectValue: Options<null>) => boolean
}

const FormikPublicSelect: React.FC<FormikSelectProps> = ({
  label,
  optionsUrl,
  getOptionLabel,
  getOptionValue,
  onChange,
  unique_property,
  search_property,
  defaultValue,
  not_required,
  isDisabled,
  isOptionDisabled,
  ...props
}) => {
  const [field, meta, {setValue: setFormikFieldValue}] = useField(props)
  const [key, setKey] = useState(1)
  const [initValue, setInitValue] = useState(null)
  const handleChangeData = (newValue: any) => {
    if (props.isMulti) {
      let values = newValue.map((item: any) => ({
        ...item.value,
      }))

      setFormikFieldValue(values || null)
      if (props.onChangeValue) {
        // @ts-ignore
        props.onChangeValue(values)
      }
    } else {
      if (props.onChangeValue) {
        // @ts-ignore
        props.onChangeValue(newValue?.value)
      }
      setFormikFieldValue(newValue?.value || null)
    }
  }

  const loadData = async (searchQuery: string, loadedOptions: any, {page}: any) => {
    const config: any = {
      params: {
        page: page,
      },
    }

    if (search_property !== undefined) {
      if (typeof search_property === 'string') {
        config.params[`${search_property}`] = searchQuery
      } else {
        for (const property of search_property) {
          config.params[`${property}`] = searchQuery
        }
      }
    }

    const response = await axios.get(optionsUrl, config)
    const responseJSON = response.data.data

    return {
      options: responseJSON.map((option: any) => ({
        value: getOptionValue(option),
        label: getOptionLabel(option),
      })),
      hasMore: response.data.meta.current_page < response.data.meta.last_page,
      additional: {
        page: searchQuery ? 2 : page + 1,
      },
    }
  }

  useEffect(() => {
    if (meta.value) {
      let defaultValue = meta.value
      if (!props.isMulti) {
        defaultValue = {
          value: getOptionValue(meta.value),
          label: getOptionLabel(meta.value),
        }
      } else {
        defaultValue = meta.value.map((item: any) => ({
          value: getOptionValue(item),
          label: getOptionLabel(item),
        }))
      }
      // @ts-ignore
      setInitValue(defaultValue)

      setKey(key + 1)
    } else {
      setInitValue(null)
      setKey(key + 1)
    }
  }, [meta.value])

  return (
    <>
      <div className='form-group' key={key}>
        {label && (
          <label htmlFor={props.name} className={`form-label ${!not_required ? 'required' : ''}`}>
            {label}
          </label>
        )}

        <AsyncPaginate
          isDisabled={isDisabled}
          debounceTimeout={500}
          loadOptions={loadData}
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              backgroundColor: isDisabled ? '#eff2f5' : 'transparent',
              padding: '0.2rem',
              border: props.border === false ? 'none' : '1px lightgray solid',
              fontSize: props.fontSize ? props.fontSize : '9.5pt',
              width: props.width ? props.width : '100%',
              borderRadius: props.borderRadius ? props.borderRadius : '4px',
            }),
            menuList: (base) => ({
              ...base,
              fontSize: props.fontSize ? props.fontSize : '9.5pt',
            }),
          }}
          {...props}
          isOptionDisabled={isOptionDisabled}
          defaultValue={initValue}
          onChange={handleChangeData}
          onBlur={field.onBlur}
          isClearable={true}
          additional={{
            page: 1,
          }}
        />

        {meta.touched && meta.error ? (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{meta.error}</span>
            </div>
          </div>
        ) : null}
      </div>
    </>
  )
}

export default FormikPublicSelect
