import {Navigate, Route, Routes} from 'react-router-dom'
import {FC, lazy, Suspense} from 'react'
import {WithChildren} from '../../_metronic/helpers'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import TopBarProgress from 'react-topbar-progress-indicator'
// import AnnouncementCategoryPage from '../modules/master-data/announcement-category/AnnouncementCategoryPage'

const MasterDataRoutes = () => {
  const AnnouncementCategoriesPage = lazy(
    () => import('../modules/master-data/announcement-categories/AnnouncementCategoriesPage')
  )
  return (
    <Routes>
      <Route
        path='announcement-categories/*'
        element={
          <SuspensedView>
            <AnnouncementCategoriesPage />
          </SuspensedView>
        }
      />
      <Route index element={<Navigate to='/master-data/announcement-categories' />} />
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export default MasterDataRoutes
