import {Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import React from 'react'
import {
  MasterDataIncotermList,
} from './components/MasterDataIncotermList'

const masterData = {
  title: 'Master Data',
  path: 'master-data',
  isSeparator: false,
  isActive: false,
}
const separator = {
  title: '',
  path: '',
  isSeparator: true,
  isActive: false,
}
const breadcrumbs: Array<PageLink> = [masterData, separator]
const childBreadcrumbs: Array<PageLink> = [
  masterData,
  separator,
  {
    title: 'Masterdata Incoterm',
    path: 'procure-x/masterdata/incoterm',
    isSeparator: false,
    isActive: false,
  },
  separator,
]

const MasterDataIncotermPage = () => {

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          index
          element={
            <>
              <PageTitle breadcrumbs={breadcrumbs}>Masterdata Incoterm</PageTitle>
              <MasterDataIncotermList />
            </>
          }
        ></Route>
      </Route>
    </Routes>
  )
}

export default MasterDataIncotermPage
