import {Link} from 'react-router-dom'
import {createElement} from 'react'

interface Props {
  tabs: Array<{
    value: number
    text: string
    total: number
    href?: string
    preventDefault?: boolean
  }>
  currentTab: number
  setCurrentTab: React.Dispatch<React.SetStateAction<number>>
  hideBadge?: boolean
}

const TableTabs = ({tabs, currentTab, setCurrentTab, hideBadge}: Props) => {
  return (
    <>
      <div
        className='d-flex justify-content-between w-100 overflow-auto align-items-center'
        style={{boxShadow: '0px 4px 3px rgba(99, 93, 93, 0.25)'}}
      >
        {tabs.map((tab: any) =>
          createElement(
            tab.href && !tab.disabled ? Link : 'div',
            {
              to: tab.href ? tab.href : '#',
              key: tab.value,
              className: `p-5 text-center flex-fill ${
                tab.value === currentTab
                  ? 'fw-bold border-info border-3 border-bottom bg-light-info text-info'
                  : ''
              } ${!tab.disabled ? 'hover-cursor-pointer' : 'bg-secondary'}`,
              onClick: (e) => {
                if(tab?.disabled) return ;
                const preventDefault = tab.preventDefault !== false

                preventDefault && e.preventDefault()

                if (!tab.disabled) {
                  setCurrentTab(tab.value)
                }
              },
              role: 'link',
              'aria-disabled': tab?.disabled,
              ...(!tab.disabled && {disabled: tab?.disabled}),
            },
            <>
              {tab.text}{' '}
              {hideBadge !== true && (
                <>
                  {tab.text !== 'All' && tab.text !== 'Final PR' ? (
                    <span className='badge badge-square badge-info'>{tab.total}</span>
                  ) : (
                    ''
                  )}
                </>
              )}
            </>
          )
        )}
      </div>
    </>
  )
}

export default TableTabs
