import React, { useState } from "react";
import TableXWrapper from "../../../../../components/shared/TableXWrapper";
import { formatCurrency } from "../../../../../../_metronic/helpers";
import { getEnv, parseNumberToCurrency, stringPadZero } from "../../../../../../helpers/procurex";
import ButtonToolbar from "./ButtonToolbar";
import { useIntl } from "react-intl";

const ItemPRPage = ({proposalTender}:any) => {
  const urlIndex = getEnv('tender') + `/tender-item?filter[proposal_tender_id]=${proposalTender?.id}`
  const [selectedRows, setSelectedRows] = useState([]);
  const tableColumns = [
    // { Header: "Nomor PR", accessor: "no_pr", width: 150, className: "text-center" },
    // { Header: "Doc Type", accessor: "document_type", width: 100, className: "text-center" },
    // { Header: "Source PR", accessor: "source", width: 100, className: "text-center" },
    // { Header: "Status", accessor: "status", width: 150, className: "text-center" },
    {
      Header: "Material PO Text",
      accessor: "line_no",
      width: 300,
      Cell: (props: any) => {
        const data = props.cell.row.original
        return (
          <>
            <div>
              <b>Item:</b> <i className="text-highlight">{data?.line_no}</i>
            </div>
            <div>
              <b>Short Text:</b> <i className="text-highlight">{data?.short_text}</i>
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: data?.poTexts?.replace(/\n/g, "<br />"),
              }}
            ></div>
          </>
        )
      }
    },
    // { Header: "A", accessor: "account_assignment_category", width: 80, className: 'text-center' },
    // {Header: 'I', accessor: 'purchaseRequisitionItem.item_category', width: 80, className: 'text-center'},
    {
      Header: 'Srv. Line Item',
      accessor: 'service_line.service_no',
      width: 150,
      className: 'text-center',
      Cell: (props: any) => {
        const data = props.cell.row.original
        return (
          <>
            {data.service_line?.map((v: any, i: number) => {
              return (
                <div key={'srv-line'+v?.uuid +'-'+i}>
                  {stringPadZero(v.line_no)} - {v.short_text}
                </div>
              )
            })}
          </>
        )
      }
    },    
    {Header: 'Material Number', accessor: 'material_id', width: 150, className: 'text-center'},
    {Header: 'Short Text', accessor: 'short_text', width: 250},
    {Header: 'Qty', accessor: 'quantity', className: 'text-end', width: 200, Cell: (props: any) => {
      const data = props.cell.row.original
        return formatCurrency(data.quantity)
      }
    },
    {Header: 'Unit', accessor: 'uom_id', width: 50, className: 'text-center'},
    // {Header: 'Harga Satuan', accessor: 'valuation_price', className: 'text-end', width: 200, Cell: (props: any) => {
    //   const data = props.cell.row.original
    //     return formatCurrency(data.valuation_price)
    //   }
    // },
    {Header: 'Currency', accessor: 'currency_id', width: 150, className: 'text-center'},
    {Header: 'Per', accessor: 'per', className: 'text-end', width: 200, Cell: (props: any) => {
      const data = props.cell.row.original
        return formatCurrency(data.per)
      }
    },
    // {Header: 'Total Harga', accessor: 'total_value', width: 200, className: 'text-end', Cell: (props: any) => {
    //   const data = props.cell.row.original
    //     return formatCurrency(data.total_value ?? '0')
    //   }
    // },
    {Header: 'Delivery Date', accessor: 'delivery_date', width: 150, className: 'text-center'},
    {Header: 'Material Group', accessor: 'material_group_id', width: 150, className: 'text-center'},
    {Header: 'Plant', accessor: 'plant_id', width: 100, className: 'text-center'},
    // {Header: 'Prchsng Grp', accessor: 'purchasing_group_id', width:150, className: 'text-center'},
    // {Header: 'Requisitioner', accessor: 'requisitioner', width: 150, className: 'text-center'},
    // {Header: 'Track. No', accessor: 'tracking_number', width: 150, className: 'text-center'},
    // {Header: 'No. Contract', accessor: 'agreement_id', width: 150, className: 'text-center'},
    // {Header: 'Item Contract', accessor: 'agreement_item_id', width: 150, className: 'text-center'},
  ];
  return (
    <>

      <div className={"card"}>
        <div className={"card-body"}>
          <div className={"d-flex justify-content-between"}>
            <div><h3>{useIntl().formatMessage({id:'Item PR'})}</h3></div>
          </div>
          <hr />
          <TableXWrapper
            readonly={true}
            title={"Proposal Tender"}
            urlIndex={urlIndex}
            columns={tableColumns}
            isForceCreateButton={true}
            onSelectedRows={(e: any) => {
              setSelectedRows(e);
            }}
            disableUrlChanged={true}
            disablePaddingCard={true}
          />
        </div>
      </div>
    </>
  );
};

export default ItemPRPage
