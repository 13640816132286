import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import Dashboard from './dashboard/Dashboard'
import { List } from './po/list/List'
import Create from './po/create/Create'
import MonitoringPOPage from './monitoring/MonitoringPOPage'

const listBreadcrumbs: Array<PageLink> = [
  {
    title: 'Purchase Order',
    path: '/contract-management',
    isSeparator: false,
    isActive: false,
  },
]

const ContractPage = () => {
  return (
    <Routes>
      <Route index element={<Navigate to='/contract-management/dashboard' />} />
      <Route element={<Outlet />}>
        <Route
          path='/dashboard'
          element={
            <>
              <PageTitle breadcrumbs={listBreadcrumbs}>Dashboard</PageTitle>
              <Dashboard />
            </>
          }
        />
      </Route>

      <Route element={<Outlet />}>
        <Route
          path='/po'
          element={
            <>
              <PageTitle breadcrumbs={listBreadcrumbs}>Purchase Order List</PageTitle>
              <List />
            </>
          }
        />
      </Route>
      <Route element={<Outlet />}>
        <Route
          path='/po/create'
          element={
            <>
              <PageTitle breadcrumbs={listBreadcrumbs}>Purchase Order Create</PageTitle>
              <Create />
            </>
          }
        />
      </Route>
      <Route element={<Outlet />}>
        <Route
          path='/po/:id'
          element={
            <>
              <PageTitle breadcrumbs={listBreadcrumbs}>Purchase Order</PageTitle>
              <Create />
            </>
          }
        />
      </Route>
      <Route element={<Outlet />}>
        <Route
          path='/monitoring'
          element={
            <>
              <PageTitle breadcrumbs={listBreadcrumbs}>Monitoring PO</PageTitle>
              <MonitoringPOPage />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default ContractPage
