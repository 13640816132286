/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useLayout} from '../core'
import {useAxiosGet} from '../../../hooks/useAxios'
import {getEnv} from '../../../helpers/procurex'
import {Modal} from 'react-bootstrap'
import {VersioningPage} from '../../../app/pages/dashboard/VersioningPage'

const Footer: FC = () => {
  const {classes} = useLayout()
  const [lastVersion,setLastVersion] = useState(null)
  const [isShowModal,setIsShowModal] = useState(false)

  const [{ doSubmit, isLoading }] = useAxiosGet(getEnv('masterdata') + 'changelog');
  const getVersionList = () => {
    doSubmit({}, getEnv('masterdata') + 'changelog?sort=-id').then((res: any) => {
      setLastVersion(res.data[0]);
    });
  }

  useEffect(() => {
    getVersionList();
  }, []);
  return (
    <div className='footer py-4 d-flex flex-lg-column' id='kt_footer'>
      {/* begin::Container */}
      <div
        className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        {/* begin::Copyright */}
        <div className='text-dark order-2 order-md-1'>
          <span className='text-muted fw-bold me-2'>{new Date().getFullYear()} &copy;</span>
          <a href='#' className='text-gray-800 text-hover-primary'>
            PT Pupuk Indonesia (Persero)
          </a>
        </div>
        {/* end::Copyright */}

        {/* begin::Nav */}
        <ul className='menu menu-gray-600 menu-hover-primary fw-bold order-1'>
          <li className='menu-item'>
            <a
              href="javascript:;"
              className='menu-link ps-0 pe-2'
              onClick={()=>setIsShowModal(true)}
            >
              <i className={"fa fa-code me-2"}></i>
              Version {lastVersion?.version_no}
            </a>
          </li>

        </ul>
        <Modal size={"xl"} show={isShowModal} onHide={()=>setIsShowModal(false)}>
          <Modal.Header closeButton={true} onHide={()=>setIsShowModal(false)}>
            <h3>Change Log of Procure X</h3>
          </Modal.Header>
          <Modal.Body>
            <VersioningPage />
          </Modal.Body>
          <Modal.Footer>
            <button className={"btn btn-primary"} onClick={()=>setIsShowModal(false)}>
              <i className={"fa fa-thumbs-up me-1"}></i> OK
            </button>
          </Modal.Footer>
        </Modal>
        {/* end::Nav */}
      </div>
      {/* end::Container */}
    </div>
  )
}

export {Footer}
