import AanwijzingDetail from './AanwijzingDetail'
import React from 'react'
import {useParams} from 'react-router-dom'

const AanwijzingFromMenu = () => {
  const {uuid} = useParams()
  return (
    <>
      <AanwijzingDetail key={uuid} aanwijzingUuid={uuid} />
    </>
  )
}

export default AanwijzingFromMenu