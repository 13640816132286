import {Nav} from 'rsuite'
import React, {Fragment, ReactNode, useState} from 'react'
import {useIntl} from 'react-intl'
import {ClarificationTenderItemModel} from '../../../core/_models'
import {ClarificationTenderItemFilters, useClarificationTenderItemsQuery} from '../core/_queries'
import {FetchListParams} from '../../../../../../_models'

//region ClarificationTenderItemPrInfo
export const ClarificationTenderItemPrInfo = ({
  tenderItems,
  showBuyerNote = true,
  showBuyerAttachment = true,
}: {
  tenderItems: readonly ClarificationTenderItemModel[]
  showBuyerNote?: boolean
  showBuyerAttachment?: boolean
}) => {
  const intl = useIntl()

  const firstTenderItem = tenderItems?.length > 0 ? tenderItems[0] : null
  const buyerAttachments = firstTenderItem?.buyer_attachments ?? []
  const buyerAttachnentsLength = buyerAttachments.length

  return (
    <>
      <section title='Parameter Evaluasi' className='mb-4'>
        <h5>{intl.formatMessage({id: 'Parameter Evaluasi'})}</h5>

        <table className={'table table-bordered table-striped table-header-gray mb-0'}>
          <thead>
            <tr>
              <th className='px-2 border text-center' style={{width: 50}}>
                {intl?.formatMessage({id: 'No'})}
              </th>
              <th className='px-2 border'>{intl?.formatMessage({id: 'Parameter Evaluasi'})}</th>
            </tr>
          </thead>
          <tbody>
            {firstTenderItem?.parameters.map((parameter, index) => (
              <tr key={index}>
                <td className='px-2 border text-center'>{index + 1}</td>
                <td className='px-2 border'>{parameter}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>

      {showBuyerNote && (
        <section title='Note dari Buyer' className='mb-4'>
          <label className='form-label mb-3' htmlFor='buyer_note'>
            {intl.formatMessage({id: 'Note dari Buyer'})}
          </label>

          <textarea
            name='buyer_note'
            className={'form-control'}
            defaultValue={firstTenderItem?.clarification?.buyer_note}
            disabled
          />
        </section>
      )}

      {firstTenderItem.step === 'end_user' && (
        <section title='Note dari Evaluator' className='mb-4'>
          <label className='form-label mb-3' htmlFor='to_end_user_note'>
            {intl.formatMessage({id: 'Note dari Evaluator'})}
          </label>

          <textarea
            name='to_end_user_note'
            className={'form-control'}
            defaultValue={firstTenderItem?.to_end_user_note}
            disabled
          />
        </section>
      )}

      {showBuyerAttachment && (
        <section title='Attachment' className='mb-4'>
          <label className='form-label mb-3' htmlFor='attachment'>
            {intl.formatMessage({id: 'Attachment'})}
          </label>

          {buyerAttachments?.length === 0 && (
            <div className='d-flex'>
              <input className={'form-control'} disabled />
            </div>
          )}

          <div className='row row-cols-1 gy-1'>
            {buyerAttachments?.map((attachment: any, index: number) => (
              <div className="col">
                <div key={index} className="d-flex">
                  <input className={'form-control'} defaultValue={attachment?.type + (buyerAttachnentsLength > 1 ? ` ${index + 1}` : '')} disabled />

                  <a
                    href={attachment?.media_url}
                    className="btn btn-light-primary btn-sm pt-4"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fas fa fa-download"></i>
                  </a>
                </div>
              </div>
            ))}
          </div>
        </section>
      )}

      <section title='Kategori Evaluasi' className='mb-4'>
        <label className='form-label mb-3' htmlFor='categories'>
          {intl.formatMessage({id: 'Kategori Evaluasi'})}
        </label>

        <input
          name='categories'
          defaultValue={firstTenderItem?.categories.join(', ')}
          className={'form-control'}
          disabled
        />
      </section>
    </>
  )
}
//endregion

//region ClarificationTenderItemPrTable
const ClarificationTenderItemPrTable = ({
  tenderItems,
}: {
  tenderItems: readonly ClarificationTenderItemModel[]
}) => {
  const intl = useIntl()

  return (
    <section title='Item PR yang di Evaluasi' className='mb-10'>
      <h5>{intl.formatMessage({id: 'Item PR yang di Evaluasi'})}</h5>

      <table className={'table table-bordered table-striped table-header-gray mb-0'}>
        <thead>
          <tr>
            <th className='px-2 border text-center' style={{width: 50}}>
              {intl?.formatMessage({id: 'No'})}
            </th>
            <th className='px-2 border'>{intl?.formatMessage({id: 'Nomor PR'})}</th>
            <th className='px-2 border'>{intl?.formatMessage({id: 'Item PR'})}</th>
            <th className='px-2 border'>{intl?.formatMessage({id: 'Short Text'})}</th>
            <th className='px-2 border'>{intl?.formatMessage({id: 'Purchasing Group'})}</th>
          </tr>
        </thead>
        <tbody>
          {tenderItems.map((item, index) => (
            <tr key={index}>
              <td className='px-2 border text-center'>{index + 1}</td>
              <td className='px-2 border'>{item.no_pr}</td>
              <td className='px-2 border'>{item.line_no}</td>
              <td className='px-2 border'>{item.short_text}</td>
              <td className='px-2 border'>
                {item.purchasing_group
                  ? `${item.purchasing_group.purch_group_id} - ${item.purchasing_group.purch_group_name}`
                  : '-'}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  )
}
//endregion

//region ClarificationTenderItemVendorTable
const ClarificationTenderItemVendorTable = ({
  tenderItems,
}: {
  tenderItems: readonly ClarificationTenderItemModel[]
}) => {
  const intl = useIntl()

  const firstVendor = Array.isArray(tenderItems[0].vendors) ? tenderItems[0].vendors[0] : null
  const firstSyarat = Array.isArray(firstVendor?.syarat_evaluations)
    ? firstVendor.syarat_evaluations[0]
    : null
  const syaratTexts = {
    administration: intl.formatMessage({id: 'Admin'}),
    technical: intl.formatMessage({id: 'Teknis'}),
    commercial: intl.formatMessage({id: 'Komersial'}),
  }

  return (
    <section title='Dokumen Penawaran Vendor' className='mb-10'>
      <h5>{intl.formatMessage({id: 'Dokumen Penawaran Vendor'})}</h5>

      <table className={'table table-bordered table-striped table-header-gray mb-0'}>
        <thead>
          <tr>
            <th className='px-2 border text-center' style={{width: 50}}>
              {intl?.formatMessage({id: 'No'})}
            </th>
            <th className='px-2 border'>{intl?.formatMessage({id: 'PR'})}</th>
            {/*<th className='px-2 border'>{intl?.formatMessage({id: 'Item PR'})}</th>*/}
            {/*<th className='px-2 border'>{intl?.formatMessage({id: 'Short Text'})}</th>*/}
            <th className='px-2 border'>{intl?.formatMessage({id: 'Vendor'})}</th>
            <th colSpan={2} className='px-2 border'>
              {intl?.formatMessage({id: 'Dokumen Penawaran'})}
            </th>
          </tr>
        </thead>
        <tbody>
          {tenderItems.map((item, itemIndex) => {
            const rowspan = item.vendors?.reduce(
              (acc, cur) => acc + cur.syarat_evaluations.length,
              0
            )

            return (
              <Fragment key={itemIndex}>
                <tr key={itemIndex}>
                  <td rowSpan={rowspan} className='px-2 border text-center'>
                    {itemIndex + 1}
                  </td>
                  {/*<td rowSpan={rowspan} className='px-2 border'>*/}
                  {/*  {item.no_pr}*/}
                  {/*</td>*/}
                  <td rowSpan={rowspan} className="px-2 border">
                    <div className={'border-bottom d-flex flex-column'}>
                      <small><strong className={''}>Nomor PR</strong></small>
                      {item?.no_pr}
                    </div>
                    <div className={'border-bottom d-flex flex-column'}>
                      <small><strong className={''}>Item PR</strong></small>
                      {item?.line_no}
                    </div>
                    <div className={'border-bottom d-flex flex-column'}>
                      <small><strong className={''}>Short Text</strong></small>
                      {item?.short_text}
                    </div>
                  </td>
                  {/*<td rowSpan={rowspan} className='px-2 border'>*/}
                  {/*  {item.short_text}*/}
                  {/*</td>*/}
                  <td rowSpan={firstVendor?.syarat_evaluations.length} className="px-2 border">
                    <div className={'d-flex flex-column'}>
                      <small><strong className={''}>{firstVendor?.vendor_id}</strong></small>
                      {firstVendor?.vendor.name}
                    </div>

                  </td>
                  <td className="px-2 border">
                    <div className={'d-flex flex-column'}>
                      {syaratTexts[firstSyarat?.type]} - {firstSyarat?.description}
                      <a href={firstSyarat?.media_url} target="_blank" rel="noreferrer">
                        <i className="fa fa-chevron-right"></i> <strong>Lihat File</strong>
                      </a>
                    </div>
                  </td>
                </tr>

                {item.vendors?.map((vendor, vendorIndex) => {
                  const firstVendorSyarat = Array.isArray(vendor.syarat_evaluations)
                    ? vendor.syarat_evaluations[0]
                    : null

                  return (
                    <Fragment key={`${itemIndex}-vendor-${vendorIndex}`}>
                      {vendorIndex > 0 && (
                        <tr key={`${itemIndex}-vendor-${vendorIndex}`}>
                          <td rowSpan={vendor.syarat_evaluations.length} className="px-2 border">
                            <div className={'d-flex flex-column'}>
                              <small><strong className={''}>{vendor?.vendor_id}</strong></small>
                              <div>{vendor?.vendor.name}</div>
                            </div>
                          </td>
                          <td className="px-2 border">
                            <div className={'d-flex flex-column'}>
                              {syaratTexts[firstVendorSyarat?.type]} -{' '}
                              {firstVendorSyarat?.description}
                              <a href={firstVendorSyarat?.media_url} target="_blank" rel="noreferrer">
                                <i className="fa fa-chevron-right"></i> <strong>Lihat File</strong>
                              </a>
                            </div>
                          </td>

                        </tr>
                      )}

                      {vendor.syarat_evaluations.map(
                        (syarat: any, syaratIndex: number) =>
                          syaratIndex > 0 && (
                            <tr key={`${itemIndex}-vendor-${vendorIndex}-syarat-${syaratIndex}`}>
                              <td className="px-2 border">
                                <div className='d-flex flex-column'>
                                  {syaratTexts[syarat?.type]} - {syarat.description}
                                  <a href={firstSyarat?.media_url} target="_blank" rel="noreferrer">
                                    <i className="fa fa-chevron-right"></i> <strong>Lihat File</strong>
                                  </a>
                                </div>
                              </td>
                            </tr>
                          )
                      )}
                    </Fragment>
                  )
                })}
              </Fragment>
            )
          })}
        </tbody>
      </table>
    </section>
  )
}
//endregion

export type EvaluationTenderItemsModalInfoTabs = 'item-pr' | 'document-vendor'

interface IProps {
  clarificationTenderItemsFetchParams: FetchListParams<ClarificationTenderItemFilters>
  initialTab?: EvaluationTenderItemsModalInfoTabs
  queryOptions?: {
    enabled?: boolean
    placeholderData?: ClarificationTenderItemModel[]
  }
  children?: ReactNode
  afterItemPrTable?: ReactNode
  showBuyerNote?: boolean
  showBuyerAttachment?: boolean
  initialData?: ClarificationTenderItemModel[]
}

export default function EvaluationTenderItemsModalInfo({
  initialTab,
  clarificationTenderItemsFetchParams,
  queryOptions,
  children,
  afterItemPrTable,
  showBuyerNote,
  showBuyerAttachment,
  initialData,
}: IProps) {
  const intl = useIntl()
  const [selectedTab, setSelectedTab] = useState<EvaluationTenderItemsModalInfoTabs>(
    initialTab || 'item-pr'
  )
  const itemUuids = clarificationTenderItemsFetchParams?.filters?.uuid ?? []

  const queryInfo = useClarificationTenderItemsQuery(
    {
      ...queryOptions,
      enabled: queryOptions.enabled && itemUuids.length > 0,
    },
    {
      ...clarificationTenderItemsFetchParams,
      include: ['clarification', 'vendors', 'purchasingGroup', 'buyerAttachments'],
    }
  )

  const tenderItems = queryInfo.data ?? initialData

  return (
    <>
      <Nav
        justified
        appearance={'subtle'}
        activeKey={selectedTab}
        onSelect={setSelectedTab}
        style={{marginBottom: 20}}
      >
        <Nav.Item eventKey={'item-pr'} className={'d-flex justify-content-center'}>
          <div>{intl?.formatMessage({id: 'Item PR'})}</div>
        </Nav.Item>
        <Nav.Item eventKey={'document-vendor'} className={'d-flex justify-content-center'}>
          <div>{intl?.formatMessage({id: 'Dokumen Vendor'})}</div>
        </Nav.Item>
      </Nav>

      <div className='p-6 pt-0'>
        <ClarificationTenderItemPrInfo
          tenderItems={tenderItems}
          showBuyerNote={showBuyerNote}
          showBuyerAttachment={showBuyerAttachment}
        />

        <hr />

        {selectedTab === 'item-pr' && (
          <>
            <ClarificationTenderItemPrTable tenderItems={tenderItems} />

            {afterItemPrTable}
          </>
        )}

        {selectedTab === 'document-vendor' && (
          <ClarificationTenderItemVendorTable tenderItems={tenderItems} />
        )}

        {children}
      </div>

    </>
  )
}
