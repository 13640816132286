/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, {FC} from 'react'
import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import {useLang, setLanguage} from '../../../i18n/Metronici18n'
import { darkModeClass } from "../../../../helpers/utils";

const languages = [
  {
    lang: 'en',
    name: 'English',
    flag: toAbsoluteUrl('/media/flags/united-states.svg'),
  },
  {
    lang: 'id',
    name: 'Indonesia',
    flag: toAbsoluteUrl('/media/flags/indonesia.svg'),
  },
]

const Languages: FC = () => {
  const lang = useLang()
  const currentLanguage = languages.find((x) => x.lang === lang)
  return (
    <>
      <a
        href="#"
        className={clsx("", "")}
        data-kt-menu-trigger={"hover"}
        data-kt-menu-attach="parent"
        data-kt-menu-placement={"bottom"}
      >
        <img
          className="w-20px h-20px rounded-1 ms-2"
          src={currentLanguage?.flag}
          alt="metronic"
        />
      </a>

      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px'
        data-kt-menu='true'
      >
        {languages.map((l) => (
          <div
            className="menu-item px-3"
            key={l.lang}
            onClick={() => {
              setLanguage(l.lang);
            }}
          >
            <a
              href="#"
              className={clsx(`menu-link d-flex px-5 ${darkModeClass()}`, { active: l.lang === currentLanguage?.lang })}
            >
              <span className="symbol symbol-20px me-4">
                <img className="rounded-1" src={l.flag} alt="metronic" />
              </span>
              {l.name}
            </a>
          </div>
        ))}
      </div>
    </>

  )
}

export { Languages }
