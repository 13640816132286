import {ButtonGroup, Modal} from 'react-bootstrap'
import TableXWrapper from '../../../../../components/shared/TableXWrapper'
import React, {useState} from 'react'
import {pdfjs} from 'react-pdf'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import {getEnv} from '../../../../../../helpers/procurex'
import PreviewAttachment from '../../../../../components/PreviewAttachment'
import {useIntl} from 'react-intl'

interface AttachmentProps {
  proposalTender: any
}

const DokumenPengadaanPage = ({proposalTender}: any) => {
  let [key, setKey] = useState(0)
  const [pageNumber, setPageNumber] = useState(1)
  const [previewModal, setPreviewModal] = useState(false)
  const [previewExt, setPreviewExt] = useState({value: '', ext: ''})
  const [indexForm, setFormIndex] = useState(1)
  const urlIndex = getEnv('tender') + '/tender-attachment'
  const intl = useIntl()
  const [previewAttachment, setPreviewAttachment] = useState<any>(null)
  const tableColumns = [
    {
      Header: intl.formatMessage({id: 'Aksi'}),
      accessor: 'media_url',
      className: 'text-center',
      width: 150,
      Cell: (props: any) => (
        <>
          <ButtonGroup size={'sm'} aria-label='Action'>
            <button
              type='button'
              className='btn btn-sm btn-secondary'
              onClick={() => preview(props.cell.row.values.media_url)}
            >
              <i className={'fa fa-eye'} />
            </button>
          </ButtonGroup>
        </>
      ),
    },
    {Header: intl.formatMessage({id: 'Tipe Lampiran'}), accessor: 'media_type.name', width: 250},
    {Header: intl.formatMessage({id: 'Deskripsi'}), accessor: 'description', width: 450},
  ]

  const preview = (value: string) => {
    window.open(value, '_blank')
  }

  return (
    <>
      <Modal
        key={'modal-' + key}
        show={previewModal}
        onHide={() => {
          setPreviewModal(false)
          setPageNumber(1)
        }}
        size={'lg'}
      >
        <Modal.Header closeButton={true}>
          <h4>Preview Attachment</h4>
        </Modal.Header>
        <Modal.Body className='overflow-visible'>
          <object width='100%' height='700' data={previewExt.value} type='application/pdf'>
            {' '}
          </object>
          {(previewExt.ext === 'jpg' || previewExt.ext === 'png' || previewExt.ext === 'jpeg') && (
            <>
              <img className='w-100' src={previewExt.value} alt='image' />
            </>
          )}
        </Modal.Body>
      </Modal>

      <div className='card' key={indexForm}>
        <div className='card-body'>
          <div className={'d-flex justify-content-between'}>
            <div>
              <h3>{intl.formatMessage({id: 'Dokumen Pengadaan'})}</h3>
            </div>
          </div>
          <hr />

          <div className='card border'>
            <div className='card-body'>
              <TableXWrapper
                readonly
                title={'Test'}
                urlIndex={urlIndex}
                columns={tableColumns}
                defaultFilter={`filter[proposal_tender_id]=${proposalTender?.id}&filter[is_skv]=1`}
                disableUrlChanged={true}
                disablePaddingCard={true}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DokumenPengadaanPage
