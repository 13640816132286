import axios from "axios";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Modal, Row, Tab, Tabs } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import SwalX from "../../../components/shared/SwalX";
import { KTCard, KTCardBody } from "../../../../_metronic/helpers";
import { TabulasiVendorAuction } from "./TabulasiVendorAuction";
import SwalTnMAuctionVendor from "../../../components/shared/SwalTnMAuctionVendor";
import Timer from "../../../components/Timer";
import Countdown from "react-countdown";
import { RiwayatAuctionVendor } from "./RiwayatAuctionVendor";

const TabVendorAuction = () => {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const params = useParams();
  const id = params?.['*'];
  const [isConfirmTnM, setIsConfirmTnM] = useState(false);
  const [isLoadingTnM, setIsLoadingTnM] = useState(false);
  const navigate = useNavigate();
  const [detailData, setDetailData] = useState({
    nama: '',
    status: '',
    nomor: '',
    type: '',
    metode_evaluasi: '',
    variant: '',
    perusahaan: '',
    batas_awal: '',
    batas_akhir: '',
    catatan: '',
    petunjuk: '',
    startDate: moment(),
    endDate: moment(),
  })

  const fetchDetailData = async () => {
    const response: any = await axios.get(`${BASE_URL}/api/auction/as-vendor/auction/${id}`)

    let endDates = moment(response.data?.data?.batas_akhir, 'YYYY-MM-DD hh:mm:ss')
    let startDates = moment(moment(response.data?.data?.batas_awal).toDate())
    let details = {
      nama: response?.data?.data?.nama,
      status: response?.data?.data?.status,
      nomor: response?.data?.data?.no_auction,
      type: response?.data?.data?.tipe_auction?.value,
      metode_evaluasi: response.data?.data?.metode_evaluasi?.value,
      variant: response.data?.data?.metode_peringkat?.value,
      perusahaan: response?.data?.data?.perusahaaan,
      batas_awal: response.data?.data?.batas_awal ? moment(response.data?.data?.batas_awal).format('DD MMMM yyyy HH:mm:ss') : '',
      batas_akhir: response.data?.data?.batas_akhir ? moment(response.data?.data?.batas_akhir).format('DD MMMM yyyy HH:mm:ss') : '',
      catatan: response.data?.data?.catatan,
      petunjuk: response.data?.data?.petunjuk,
      status_peserta: response?.data?.data?.status_peserta,
      startDate: startDates,
      endDate: endDates
    }
    
    if (details.status_peserta == 'inactive') {
      setIsConfirmTnM(true)
    }
    setDetailData(details);
  }

  const onApprove = async () => {
    setIsLoadingTnM(true)
    try {
      const response: any = await axios.put(`${BASE_URL}/api/auction/as-vendor/approve/${id}/1`)
      if (response?.status === 201 || response?.status === 200) {
        toast.success(response?.status + ': ' + response?.data.meta.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
        setIsConfirmTnM(false)
        return true
      }
    } catch (error) {
      setIsLoadingTnM(false)
      console.error(error)
    } finally {
      setIsLoadingTnM(false)
    }
  }

  const onReject = async () => {
    setIsLoadingTnM(true)
    try {
      const response: any = await axios.put(`${BASE_URL}/api/auction/as-vendor/approve/${id}/0`)
      if (response?.status === 201 || response?.status === 200) {
        toast.success(response?.status + ': ' + response?.data.meta.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
        setIsConfirmTnM(false)
        return true
      }
    } catch (error) {
      setIsLoadingTnM(false)
      console.error(error)
    } finally {
      setIsLoadingTnM(false)
      navigate(-1)
    }
  }

  useEffect(() => {
    fetchDetailData();
  }, [])

  return (
    <>
      <SwalTnMAuctionVendor
        title={'Petunjuk & Peraturan'}
        message=""
        htmlMessage={detailData?.petunjuk}
        show={isConfirmTnM}
        btnConfirmLabel="Approve"
        btnConfirmVariant={"success"}
        btnConfirmIcon={"fa fa-paper-plane"}
        loading={isLoadingTnM}
        btnCancelLabel="Reject"
        onHide={() => onReject()}
        onSubmit={() => onApprove()
        }
      />
      <div className="mt-10" style={{
        position: 'fixed',
        left: '57%', top: '15%',
        transform: 'translate(-50%, -50%)'
      }}>
        <div className='d-flex justify-content-center' data-kt-materialrequest-table-toolbar='base'>
          <Timer label="Waktu Sisa Auction" endDate={detailData?.endDate}></Timer>
        </div>
        {/* <div className='d-flex justify-content-center' data-kt-materialrequest-table-toolbar='base'>
          <Timer label="Waktu Freeze" endDate={detailData?.endDate}></Timer>
        </div> */}
      </div>
      <div className="mb-10 mt-10">
        <Row>
          <Col>
            <div className='d-flex justify-content-start' data-kt-materialrequest-table-toolbar='base'>
              <label className="fs-2 font-weight-bold" style={{ fontWeight: '600' }}>[SIMULASI] {detailData?.nama}</label>
            </div>
          </Col>
          <Col>
            <div className='d-flex justify-content-end mb-3' data-kt-materialrequest-table-toolbar='base'>
              <label className="fs-2 font-weight-bold" style={{ fontWeight: '700' }}>{detailData?.status}</label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className='d-flex justify-content-start' data-kt-materialrequest-table-toolbar='base'>
              <label>No</label>
              <label className="form-label">: {detailData?.nomor}</label>
              <div style={{ marginLeft: 10 }} />
              <label>Type Auction </label>
              <label className="form-label">: {detailData?.type}</label>
            </div>
          </Col>
          <Col>
            <div className='d-flex justify-content-end mb-3' data-kt-materialrequest-table-toolbar='base'>
              <label className="font-weight-bold" style={{ fontWeight: '700' }}>{detailData?.batas_awal} - {detailData?.batas_akhir}</label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className='d-flex justify-content-start' data-kt-materialrequest-table-toolbar='base'>
              <label className="form-label">{detailData?.perusahaan}</label>
            </div>
          </Col>
          <Col>
            <div className='d-flex justify-content-end' data-kt-materialrequest-table-toolbar='base'>
              <label>{detailData?.catatan}</label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className='d-flex justify-content-start' data-kt-materialrequest-table-toolbar='base'>
              <label>Metode Evaluasi </label>
              <label className="form-label">: {detailData?.metode_evaluasi}</label>
              <div style={{ marginLeft: 10 }} />
              <label>Variant </label>
              <label className="form-label">: {detailData?.variant}</label>
            </div>
          </Col>
        </Row>
      </div>
      <KTCard>
        <KTCardBody className="py-3">
          <Tabs defaultActiveKey={"tabulasi-vendor"} style={{ marginTop: 30 }}>
            <Tab eventKey="tabulasi-vendor" title={"Tabulasi"} tabIndex={0}>
              <TabulasiVendorAuction />
            </Tab>
            <Tab eventKey="riwayat-vendor" title={"Riwayat"}>
              <RiwayatAuctionVendor />
            </Tab>
            <Tab eventKey="pemenang-lelang-vendor" disabled color="" title={"Pemenang Lelang"}>
              Pemenang Lelang
            </Tab>
            <Tab eventKey="pendandatanganan-vendor" disabled title={"Pendandatanganan"}>
              Pendandatanganan
            </Tab>
          </Tabs>
        </KTCardBody>
      </KTCard>
    </>
  );
};

export { TabVendorAuction };
