import axios from "axios";
import { useState } from "react";

const useGet = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState<any>(null);
  const [isSuccess, setIsSuccess] = useState(false);

  const doGetDetail = async (url: string, _toast: any) => {
    return new Promise(async (resolve, reject) => {
      setIsLoading(true);
      setIsError(false);
      setIsSuccess(false);
      setData(null);
      try {
        const response = await axios.get(url);
        if (response.status === 200 || response.status === 201) {
          setIsSuccess(true);
          setData(response.data.data);
          resolve(response.data)
        } else {
          setIsError(true);
          _toast.error("Gagal Ambil Data");
          reject(new Error("Gagal Mengambil Data"))
        }
      } catch (error) {
        setIsError(true);
        // @ts-ignore
        _toast.error(error?.response?.data?.meta?.message);
        reject(error);
      } finally {
        setIsLoading(false);
      }
    });
  };
  return [{ doGetDetail, isLoading, isError, data, isSuccess }];
};

export default useGet;