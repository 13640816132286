import React, {FC, useEffect, useState} from 'react'
import { useIntl } from 'react-intl';
import Select2FormX from './Select2FormX';

enum IFormSize {
    sm = "sm",
    "md" = "md",
    "lg" = "lg",
    "xl" = "xl"
}

type Props = {
    type: string,
    size: string,
    placeholder: string,
    model: string,
    onChange: any,
    value?: any,
    focus?: boolean,
    readonly?: boolean
}

type IRecord = {
  service_id: number,
  service_name: string,
  route: string
}

const ServiceRouteMenu: FC<Props> = (props) => {
    const {type,size,model,placeholder,onChange,value,focus,readonly} = props;
    const [_value,_setValue] = useState(value)
    const [routeKey,setRouteKey] = useState(1);
    const [fieldService,setFieldService] = useState({});
    const [fieldRoute,setFieldRoute] = useState({});
    const [records,setRecords] = useState<Array<IRecord>>([])
    const [tableKey,setTableKey] = useState(1);
    const [listPermission,setListPermission] = useState([]);

    const optionsService = {
      url: process.env.REACT_APP_API_URL+"/api/auth/service",
      key: 'id',
      label: 'name',
      return_only_id: false
    }
    const [optionsRoute,setOptionRoute] = useState({
      url: '',
      key: 'route',
      label: 'route',
      return_only_id: false
    })

    useEffect(()=>{
      if(value) {
        setRecords(value.routes)
      }
    },[value])


    useEffect(()=>{
      onChange(model,records)
    },[records])
    
    const onServiceChange = (e:any) => {
        if(e !== fieldService) {
            let tmp = optionsRoute;
            tmp.url = e.data.url + "/routes";
            setOptionRoute(tmp)
            setRouteKey(routeKey + 1);
            setFieldService(e);
        }
    }

    const onSubmitForm = (e:any) => {
      e.preventDefault();
      let tmp = {} as IRecord;
      // @ts-ignore
      tmp.service_id = fieldService.data.id;
      // @ts-ignore
      tmp.service_name = fieldService.data.name;
      // @ts-ignore
      tmp.route = fieldRoute.data.route;
      let arr = records;
      if(tmp.route.includes('.index')){
        let x = tmp.route.replace(".index","")
          const allPermission = listPermission.filter((y:any)=>y.route.includes(x));
        allPermission.map((v:any)=>{
            // @ts-ignore
            arr.push({
                service_id: tmp.service_id,
                service_name: tmp.service_name,
                route: v.route
            });
        })
      } else {
          arr.push(tmp);
      }

      setRecords(arr)
      setTableKey(tableKey+1)
    }

    const onDeleteRoute = (e:any) =>{
        const _record = records.filter(v=>v!=e)
        setRecords(_record)
    }
    

    return (
        <div className='border p-2'>
          <table className='table table-striped table-bordered border p-2'>
            <thead>
              <tr>
                <th className='text-center' style={{width:'45%'}}><strong>Service</strong></th>  
                <th className='text-center' style={{width:'45%'}}><strong>Permission</strong></th>  
                <th className='text-center'><strong>#</strong></th>
              </tr>  
              <tr>
                <th>
                  <Select2FormX
                    onChange={onServiceChange}
                    model={""}
                    name={""}
                    options={optionsService}
                  /> 
                </th>  
                <th key={routeKey}>
                <Select2FormX
                    onChange={(e:any)=>setFieldRoute(e)}
                    model={""}
                    name={""}
                    options={optionsRoute}
                    disabled={Object.keys(fieldService).length > 0 ? false : true }
                    onGetResponseList={(e:any)=>setListPermission(e)}
                  />   
                </th>  
                <th className='text-center'>
                  <button className='btn btn-primary btn-sm btn-xs' type='button' onClick={onSubmitForm}>
                    {useIntl().formatMessage({id:'Submit'})}
                  </button>
                </th>
              </tr>  
            </thead>  
            <tbody key={tableKey}>
              {records.map((v:IRecord)=>{
                return (
                  <tr key={'record-'+v.route+v.service_name}>
                    <th className='text-center'>{v.service_name}</th>
                    <th className='text-center'>{v.route}</th>
                    <th className='text-center'>
                        <button className='btn btn-danger btn-sm btn-xs text-center' type='button' onClick={()=>onDeleteRoute(v)}>
                            <i className={"fa fa-trash"}/>
                        </button>
                    </th>
                  </tr>
                )
              })}
            </tbody> 
          </table>  
        </div>
    )
}

export default ServiceRouteMenu;