import React, {FC, useEffect, useState} from 'react'
import Select from 'react-select'

import {useGetSelectDetail, useGetSelectList} from "../core/hook";
type IOptions = {
	url: string,
	key: string,
	label: string,
	sublabel?: string,
	return_only_id?: boolean //jika true, maka return data hanya berupa id saja, jika tidak, maka object return
}
type Props = {
	name?: string,
	model?: string,
	onChange?: any,
	onGetResponseList?: any,
	value?: any,
	defaultValue?: any,
	focus?: boolean,
	options: IOptions,
	disabled?: boolean,
}
const Select2FormX: FC<Props> = (props) => {
	const {model,onChange,value,focus, name, options, onGetResponseList, defaultValue} = props;
	const [currentValue,setCurrentValue] = useState({})
	const [ isLoading, responseData, responseError, getData] = useGetSelectList();
	const [ isLoadingDetail, responseDataDetail, responseErrorDetail, getDataDetail] = useGetSelectDetail();
	const [optionsData,setOptionsData] = useState([])
	const [search,setSearch] = useState("")
	const [isMounted,setIsMounted] = useState(false)
	const [perPage,setPerPage] = useState(2)
	const makeId = (length=10) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
		return result;
	}
	const [renderKey,setRenderKey] = useState(makeId());

	useEffect(()=>{
		if(options && options.url && !isMounted) {
			loadData()
		}
	},[options])

	// useEffect(()=>{
	// 	console.log(defaultValue)
	// },[defaultValue])

	useEffect(()=>{
		if(!responseDataDetail) return ;

		setRenderKey(makeId())
		const val = responseDataDetail?.data
		if(val){
			setCurrentValue({
				value: val[options.key],
				label: val[options.label],
				data: val
			})
			onChange(options.return_only_id === true ? val[options.key] : val)
		}
	},[responseDataDetail])

	useEffect(()=>{
		if(!responseData) return ;
		if(!responseData.data) return ;
		if(responseData.data.length === 0) return ;
		// @ts-ignore
		let tmp = [];
		responseData.data.map((v:any)=>{
			//@ts-ignore
			tmp.push({
				value: v[options.key],
				label: v[options.label],
				data: v
			})
		})
		try {
			onGetResponseList(responseData?.data)
		} catch(e){

		}
		//@ts-ignore
		setOptionsData(tmp)
	},[responseData])

	useEffect(()=>{
		if(defaultValue) loadCurrentValue()
	},[defaultValue])

	const loadData = async()=>{
		let url = options.url;
		setIsMounted(true)
		getData(url);
	}

	const loadCurrentValue = async()=>{
		let url = options.url+`/${defaultValue}`;
		getDataDetail(url);
	}

	return (
		<div className={"w-100"}>
			{/*{JSON.stringify(optionsData)}*/}
			<Select
				key={renderKey}
				onChange={(e:any)=>onChange(options.return_only_id === true ? e.value : e)}
				isLoading={isLoading || isLoadingDetail}
				defaultValue={currentValue}
				isClearable
				isDisabled={props.disabled}
				options={optionsData}
				onInputChange={(e)=>setSearch(e)}
				onMenuScrollToBottom={() =>  console.log('loadmore')}
			/>
		</div>
	)
}

export default Select2FormX;