import { Outlet, Route, Routes } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import ApexChart from './components/ApexChart'
import { getEnv } from '../../../../helpers/procurex'
import TableXWrapper from '../../../components/shared/TableXWrapper'
import DatepickerX2 from '../../../components/shared/form/DatepickerX2'
import { useIntl } from 'react-intl'
import axios from 'axios'
import { toPng } from 'html-to-image'
import { useAuth } from '../../../../app/modules/auth'

const masterData = {
  title: 'Dashboard',
  path: 'stockview/dashboard',
  isSeparator: false,
  isActive: false,
}
const separator = {
  title: '',
  path: '',
  isSeparator: true,
  isActive: false,
}
const breadcrumbs: Array<PageLink> = [masterData, separator]
const childBreadcrumbs: Array<PageLink> = [
  masterData,
  separator,
  {
    title: 'Stockview Dashboard',
    path: 'stockview/dashboard',
    isSeparator: false,
    isActive: false,
  },
  separator,
]

type KedatanganData = {
  No: number
  'Material Number': string
  Plant: string
  Sloc: string
  Quantity: number
  ETA: string
  Reference: string
  'Moda Transportasi': string
  'Nama Transportasi': string
  'Status Kedatangan': string
}

const DashboardStockviewPage: React.FC = () => {
  const { currentUser } = useAuth();
  let company_chart = currentUser.company_code;
  let company_chart_plant = currentUser.company_code.charAt(0);

  const intl = useIntl();
  const tablecolumns = [
    {
      Header: "Material Number",
      accessor: "material_number",
      width: 260,
    },
    {
      Header: "Quantity (Ton)",
      accessor: "qty",
      width: 100,
    },
    {
      Header: "Reference",
      accessor: "reference",
    },
    {
      Header: "Moda Transportasi",
      accessor: "moda_transportasi",
      width: 140,
    },
    {
      Header: "Nama Transportasi",
      accessor: "nama_transportasi",
      width: 140,
    },
    {
      Header: "ETA",
      accessor: "eta",
      width: 100,
    },
    {
      Header: "Status Kedatangan",
      accessor: "status_kedatangan",
      width: 120,
    },
    {
      Header: "Plant",
      accessor: "plant"
    },
  ];

  const optionMaterial = {
    url: process.env.REACT_APP_API_URL + '/api/masterdata/materials',
    key: 'id',
    label: 'material_desc',
    return_only_id: true,
  }
  const optionCompany = {
    url: process.env.REACT_APP_API_URL + '/api/masterdata/companies',
    key: 'id',
    label: 'comp_code_name',
    return_only_id: true,
  }

  // const filterMaterialCompany = process.env.REACT_APP_API_STOCKVIEW_URL + '/grouping';
  // const filterMaterialCompany = process.env.REACT_APP_API_URL + '/api/masterdata/grouping';

  const [items, setItems] = useState<any>([])
  const [itemsMaping, setItemsMaping] = useState<any>([])
  const [itemsCompanyStock, setItemsCompanyStock] = useState<any>([])
  const [itemsMainCardStock, setItemsMainCardStock] = useState<any>([]);
  // const [itemsKedatanganStock, setItemsKedatanganStock] = useState<any>([]);
  const [itemsIDGrouping, setIDGrouping] = useState<any>({}); // Initialize as an empty object
  const [itemsMainChartStock, setItemsMainChartStock] = useState<any>([]);
  const [modalData, setModalData] = useState('')
  const [itemLoading, setItemLoading] = useState(false)

  const [formIndex, setFormIndex] = useState(0);
  const [activeTab, setActiveTab] = useState(0) // State to track the active tab
  const [kedatanganValue, setUrlIndexKedatangan] = useState('')

  const [activeCompany, setActiveCompany] = useState<any>([]);

  const handleTabClick = (index: any) => {
    setActiveTab(index)
  }

  const defaultGroupingId = '10';
  const defaultCompany = company_chart == 'A000' ? 'B000' : company_chart;
  const defaultCompanyPlant = company_chart == 'A000' ? 'B' : company_chart_plant;


  const PostingDate = new Date().toLocaleDateString('en-GB', { day: '2-digit', month: 'long', year: 'numeric' });

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showDatePickerKedatangan, setShowDatePickerKedatangan] = useState(false);

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedDateKedatangan, setSelectedDateKedatangan] = useState<Date | null>(null);

  const [urlIndex, setUrlIndex] = useState("");

  const handleTabClickMaping = (index: number, company_code: string, id_grouping: string) => {
    setActiveCompany({ index, company_code, id_grouping });

    axios.get(getEnv("stockview") + `/dashboardStock-main/grouping/${id_grouping}`)
      .then((res) => {
        setIDGrouping(res.data.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setItemLoading(false);
      });

    axios.post(getEnv("stockview") + `/cardDashboard-main/main-card-dashboard`, {
      grouping_id: id_grouping, // Use id_grouping for grouping_id
      company: company_code, // Use company_code for company
      posting_date: PostingDate // Use PostingDate variable for posting_date
    })
      .then((res) => {
        setItemsMainCardStock(res.data.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setItemLoading(false);
      });

    let companyFix = company_code.charAt(0);

    setUrlIndexKedatangan(getEnv("stockview") + `/kedatangan-list?filter[plant]=${companyFix}&filter[grouping_id]=${id_grouping}`)
    setFormIndex(formIndex + 1);
    setItemLoading(true);
  };

  const handleDateChange = (newDate: Date | null) => {
    setSelectedDate(newDate);

    if (newDate) {
      axios.post(getEnv("stockview") + `/cardDashboard-main/main-card-dashboard`, {
        grouping_id: activeCompany.id_grouping || defaultGroupingId,
        company: activeCompany.company_code || defaultCompany,
        posting_date: newDate
      })
        .then((res) => {
          setItemsMainCardStock(res.data.data);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setItemLoading(false);
        });
      setItemLoading(true);
    }
  };

  const handleDateChangeKedatangan = (newDate: Date | null) => {
    setSelectedDateKedatangan(newDate);

    if (newDate) {
      axios.post(getEnv("stockview") + `/cardDashboard-main/main-card-dashboard`, {
        grouping_id: activeCompany.id_grouping || defaultGroupingId,
        company: activeCompany.company_code || defaultCompany,
        posting_date: newDate
      })
        .then((res) => {
          setItemsMainCardStock(res.data.data);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setItemLoading(false);
        });
      setItemLoading(true);
    }
  };

  const loadItem = () => {
    axios
      .get(getEnv("stockview") + `/grouping`)
      .then((res) => {
        setItems(res.data.data)
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setItemLoading(false)
      })
    axios
      .get(getEnv("stockview") + `/grouping-maping?company_code=${company_code}`)
      .then((res) => {
        setItemsMaping(res.data.data)
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setItemLoading(false)
      })
    axios
      .get(getEnv("stockview") + `/company-stock?perPage=50&page=1`)
      .then((res) => {
        setItemsCompanyStock(res.data.data)
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setItemLoading(false)
      })
  }

  useEffect(() => {
    setActiveCompany({ defaultCompany, defaultGroupingId });

    axios.get(getEnv("stockview") + `/dashboardStock-main/grouping/${defaultGroupingId}`)
      .then((res) => {
        setIDGrouping(res.data.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setItemLoading(false);
      });

    axios.post(getEnv("stockview") + `/cardDashboard-main/main-card-dashboard`, {
      grouping_id: defaultGroupingId,
      company: defaultCompany,
      posting_date: PostingDate
    })
      .then((res) => {
        setItemsMainCardStock(res.data.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setItemLoading(false);
      });

    loadItem();
    setUrlIndexKedatangan(getEnv("stockview") + `/kedatangan-list?filter[plant]=${defaultCompanyPlant}&filter[grouping_id]=${defaultGroupingId}`);
    setFormIndex(formIndex + 1);
  }, [])

  // Mapping Company logic
  let companyShortCode = "";
  if (activeCompany.company_code === "B000") {
    companyShortCode = "PKG";
  } else if (activeCompany.company_code === "C000") {
    companyShortCode = "PKC";
  } else if (activeCompany.company_code === "D000") {
    companyShortCode = "PKT";
  } else if (activeCompany.company_code === "E000") {
    companyShortCode = "PIM";
  } else if (activeCompany.company_code === "F000") {
    companyShortCode = "PSP";
  }

  // Mapping Company logic Default
  let companyShortCodeDefault = "";
  if (activeCompany.defaultCompany === "B000") {
    companyShortCode = "PKG";
  } else if (activeCompany.defaultCompany === "C000") {
    companyShortCode = "PKC";
  } else if (activeCompany.defaultCompany === "D000") {
    companyShortCode = "PKT";
  } else if (activeCompany.defaultCompany === "E000") {
    companyShortCode = "PIM";
  } else if (activeCompany.defaultCompany === "F000") {
    companyShortCode = "PSP";
  }

  const [date, setDate] = React.useState<Date | null>(new Date());

  let dateTime = new Date()
  const options: any = { day: 'numeric', month: 'long', year: 'numeric' }
  let formattedDate = dateTime.toLocaleDateString('en-GB', options)
  // Convert the formatted date to uppercase
  formattedDate = formattedDate.toUpperCase()

  const SelectedDateCustom = new Date(selectedDate ? selectedDate : '').toLocaleDateString('en-GB', { day: '2-digit', month: 'long', year: 'numeric' }).toUpperCase();

  //button filter chart
  const [activeButtonChart, setActiveButtonChart] = useState('all');

  const handleClickButtonChart = (value: any) => {
    setActiveButtonChart(value);
  };

  const containerRef = useRef<HTMLDivElement>(null);

  const captureScreen = useCallback(() => {
    if (containerRef.current === null) {
      return
    }

    toPng(containerRef.current, { cacheBust: true, })
      .then((dataUrl) => {
        const link = document.createElement('a')
        link.download = 'screenshot-stockview.png'
        link.href = dataUrl
        link.click()
      })
      .catch((err) => {
        console.log(err)
      })
  }, [containerRef])

  let company_code = currentUser.company_code;

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          index
          element={
            <>
              <PageTitle breadcrumbs={breadcrumbs}>Dashboard Stockview</PageTitle>
              <div ref={containerRef}>
                <div className="bg-white p-3">
                  <div className="row">
                    {/* Form Section */}
                    <div className="col-md-11">
                      <form>
                        <div>
                          <ul className='nav nav-tabs nav-tabs-line' id='myTab' role='tablist'>
                            {items.map((item: any, index: number) => (
                              <li className='nav-item' style={{ minWidth: '70px', cursor: 'pointer' }} key={index}>
                                <a
                                  className={`nav-link text-center ${index === activeTab ? 'active' : ''} nav-group`}
                                  id={`tab-${index}`}
                                  data-toggle='tab'
                                  data-name={item.name_grouping}
                                  data-id={item.id}
                                  // href={''}
                                  role='tab'
                                  aria-controls={`group-${index}`}
                                  aria-selected={index === activeTab}
                                  onClick={() => handleTabClick(index)}
                                >
                                  {item.code_grouping}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>

                        {/* Tab Content */}
                        <div className='tab-content'>
                          {items.map((item: any, index: number) => (
                            <div
                              className={`tab-pane fade ${index === activeTab ? 'show active' : ''}`}
                              id={`group-${index}`}
                              role='tabpanel'
                              aria-labelledby={`tab-${index}`}
                              key={index}
                            >
                              <div className='row'>
                                <div className='col-sm-4'>
                                  <ul className='nav nav-tabs nav-tabs-line' id={`myCompany-${index}`} role='tablist'>
                                    {itemsMaping
                                      .filter((mapItem: any) => mapItem.id_grouping === item.id)
                                      .map((mapItem: any, mapIndex: number) => (
                                        <li className='nav-item' style={{ minWidth: '40px', cursor: 'pointer' }} key={mapIndex}>
                                          <a
                                            className={`nav-link ${mapIndex === 0 ? 'active' : ''}`}
                                            id={`tab-company-${index}-${mapIndex}`}
                                            data-company={mapItem.company_code}
                                            data-bs-toggle='tab'
                                            data-bs-target={`#tab-company-${index}-${mapIndex}`}
                                            role='tab'
                                            // href={''}
                                            aria-controls={`tab-company-${index}-${mapIndex}`}
                                            aria-selected={mapIndex === 0}
                                            onClick={() => handleTabClickMaping(index, mapItem.company_code, mapItem.id_grouping)}
                                          >
                                            <img
                                              src={`../company/${mapItem.company_code}.png`}
                                              alt={`Group ${item.code_grouping} Image`}
                                              width='30px'
                                            />
                                          </a>
                                        </li>
                                      ))}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </form>
                    </div>

                    {/* Button Section */}
                    <div className="col-md-1">
                      <button className="btn btn-primary" onClick={captureScreen} title="Screenshot Layar"><i className="fa fa-camera" aria-hidden="true"></i></button>
                    </div>
                  </div>
                </div>

                <div className={'bg-white p-3 mt-5'}>
                  <div className={'row'}>
                    <div className={'col-6'}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p className={'text-lg'} style={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
                          Stock Per Tanggal {selectedDate ? SelectedDateCustom : formattedDate}
                        </p>
                        <button
                          type="button"
                          className="btn btn-link"
                          title="Filter Stock Material"
                          style={{ marginLeft: '5px', marginTop: '-15px' }}
                          onClick={() => setShowDatePicker(!showDatePicker)}
                        >
                          <i className="fa fa-calendar" style={{ fontSize: '18px' }}></i>
                        </button>
                        {showDatePicker && (
                          <DatepickerX2
                            onChange={handleDateChange}
                            value={selectedDate}
                            size={"sm"}
                            format={"dd MMMM yyyy"}
                          />
                        )}
                      </div>
                      <div>
                        {itemLoading ? (
                          <div className="text-start">
                            <p>Loading... <span className='spinner-border h-15px w-15px align-middle text-gray-400' /></p>
                          </div>
                        ) : (
                          <h1 className={'font-bold'}>
                            {parseFloat(itemsMainCardStock).toLocaleString('id-ID', { minimumFractionDigits: 3, maximumFractionDigits: 3 })} Ton
                          </h1>
                        )}
                      </div>
                    </div>
                    <div className={'col-6'}>
                      {itemLoading ? (
                        <div className="text-end">
                          <p>Loading... <span className='spinner-border h-15px w-15px align-middle text-gray-400' /></p>
                        </div>
                      ) : (
                        <h1 className={'font-bold text-end mt-8 mb-8'}>
                          {itemsIDGrouping ? `${itemsIDGrouping.name_grouping} ${companyShortCode || companyShortCodeDefault}` : 'No Title'}
                        </h1>
                      )}
                    </div>
                  </div>
                </div>
                <div className={'bg-white p-3 mt-5'}>
                  <h4>Posisi Stock Per Material</h4>
                  <div className="d-flex justify-content-md-end mb-2">
                    <div className="btn-group mb-3 mb-md-0" role="group" aria-label="Basic example">
                      <button type="button" id="today" className={`relative px-5 py-2.5 transition-all ease-in duration-75 ${activeButtonChart === 'today' ? 'btn btn-primary' : ''}`} onClick={() => handleClickButtonChart('today')}>Today</button>
                      <button type="button" id="week" className={`relative px-5 py-2.5 transition-all ease-in duration-75 d-none d-md-block ${activeButtonChart === 'week' ? 'btn btn-primary' : ''}`} onClick={() => handleClickButtonChart('week')}>Week</button>
                      <button type="button" id="month" className={`relative px-5 py-2.5 transition-all ease-in duration-75 ${activeButtonChart === 'month' ? 'btn btn-primary' : ''}`} onClick={() => handleClickButtonChart('month')}>Month</button>
                      <button type="button" id="year" className={`relative px-5 py-2.5 transition-all ease-in duration-75 ${activeButtonChart === 'year' ? 'btn btn-primary' : ''}`} onClick={() => handleClickButtonChart('year')}>Year</button>
                      <button type="button" id="all" className={`relative px-5 py-2.5 transition-all ease-in duration-75 ${activeButtonChart === 'all' ? 'btn btn-primary' : ''}`} onClick={() => handleClickButtonChart('all')}>All</button>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-12'>
                      {/* <AmCharts5Chart /> */}
                      <ApexChart DataActiveCompany={activeCompany} DataDateSelected={selectedDate} TypeSelectedChart={activeButtonChart} />
                    </div>
                  </div>
                </div>
                <div className={'bg-white p-3 mt-5'}>
                  <h4>Kedatangan</h4>
                  {/* <span className={'text-help'}>
                  Posisi stok yang ditampilkan di akumulasikan dari data SAP dan data manual inputan
                  di aplikasi Stockview.
                </span> */}
                  <div className='card border mt-5'>
                    <TableXWrapper
                      key={formIndex}
                      readonly
                      urlIndex={kedatanganValue}
                      columns={tablecolumns}
                    />
                  </div>
                </div>
              </div>
            </>
          }
        ></Route>
      </Route>
    </Routes>
  )
}

export default DashboardStockviewPage
