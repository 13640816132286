import * as Yup from 'yup'
import React, {createContext, useCallback, useContext, useMemo, useState} from 'react'
import TableXWrpper from '../../../../../components/shared/TableXWrapper'
import {Button, Modal} from 'react-bootstrap'
import {Column} from 'react-table'
import {Field, Form, Formik} from 'formik'
import {IColumns} from '../../../../../components/shared/TableX'
import {IntlShape, useIntl} from 'react-intl'
import {
  KTCard,
  KTCardBody,
  MetronicErrorMessage,
  toastError,
  toastSuccess,
} from '../../../../../../_metronic/helpers'
import {ClarificationTenderItemModel, ClarificationTenderItemStatus} from '../../../core/_models'
import {getEnv} from '../../../../../../helpers/procurex'
import {useAuth, UserModel} from '../../../../auth'
import {PaginatedResponse, Response} from '../../../../../../_models'
import axios, {AxiosError, AxiosResponse} from 'axios'
import {QueryClient, useMutation, useQueryClient} from 'react-query'
import {Link, useLocation} from 'react-router-dom'
import {listParamsTransformer} from '../../../../../../helpers/utils'
import EvaluationTenderItemsModalInfo from './ClarificationTenderItemsModalInfo'
import FormikSelect2 from '../../../../../components/shared/form/FormikSelect2'
import TableTabs from '../../../../mr-sr/request/list/components/header/TableTabs'
import {useHashNumberValueByPrefix} from '../../../../../../_metronic/layout/components/core/hooks'
import {
  useClarificationTenderItemAlihkanMutation,
  useClarificationTenderItemCountsQuery,
  useGetRoutingApprovalConfigForSubmitReturnQuery,
  useSubmitReturnMutation,
} from '../core/_queries'
import {ApprovalX} from '../../../../../components/shared/ApprovalX'
import BatchApproval from './BatchApproval'
import {EvaluationSendToEndUserModal} from './EvaluationSendToEndUserModal'
import {EvaluationAlihkanModal} from './EvaluationAlihkanModal'

type EvaluationUserFilterKey = 'incoming' | 'on_process' | 'completed'

type EvaluationUserFilter = {[key in EvaluationUserFilterKey]: ClarificationTenderItemFilters}

const TENDER_API_URL = getEnv('tender')

const CAN_SUBMIT_STATUSES: Set<ClarificationTenderItemStatus> = new Set([
  'On Progress',
  'Reject Approval Return',
  'Reject Receive',
  'Reject Approval Evaluator',
  'Reject Approval Alihkan',
  'Cancel End User',
  'On Progress End User',
  'Reject Approval Return End User',
] as ClarificationTenderItemStatus[])

const CAN_UPDATE_APPROVAL_STATUSES: Set<ClarificationTenderItemStatus> = new Set([
  'Submit Approval Return',
  'Submit Approval Evaluator',
  'Submit Approval Return End User',
  'Submit Approval Alihkan',
] as ClarificationTenderItemStatus[])

const CAN_SEND_TO_EVALUATOR_STATUSES: Set<ClarificationTenderItemStatus> = new Set([
  'On Progress',
  'Reject Approval Return',
  'Reject Receive',
  'Reject Approval Evaluator',
  'Reject Approval Alihkan',
  'Cancel End User',
] as ClarificationTenderItemStatus[])

const CAN_ALIHKAN_STATUSES: Set<ClarificationTenderItemStatus> = new Set([
  'On Progress',
  'Reject Approval Return',
  'Reject Receive',
  'Reject Approval Evaluator',
  'Reject Approval Alihkan',
  'Cancel End User',
] as ClarificationTenderItemStatus[])

const canSubmitFn = (user: UserModel, item: ClarificationTenderItemModel) => {
  if ([user, item].some((value) => value === undefined || value === null)) {
    return false
  }

  if (user.username !== item.current_user) {
    return false
  }

  return CAN_SUBMIT_STATUSES.has(item.status)
}

const canUpdateApprovalFn = (user: UserModel, item: ClarificationTenderItemModel) => {
  if ([user, item].some((value) => value === undefined || value === null)) {
    return false
  }

  if (user.username !== item.current_user) {
    return false
  }

  return CAN_UPDATE_APPROVAL_STATUSES.has(item.status)
}

const canSendToEvaluatorFn = (user: UserModel, item: ClarificationTenderItemModel) => {
  if ([user, item].some((value) => value === undefined || value === null)) {
    return false
  }

  if (user.username !== item.current_user) {
    return false
  }

  return CAN_SEND_TO_EVALUATOR_STATUSES.has(item.status)
}

const canAlihkanFn = (user: UserModel, item: ClarificationTenderItemModel) => {
  if ([user, item].some((value) => value === undefined || value === null)) {
    return false
  }

  if (user.username !== item.current_user) {
    return false
  }

  return CAN_ALIHKAN_STATUSES.has(item.status)
}

//region Context & Provider
type EvaluationListContextType = {
  selectedItems: ClarificationTenderItemModel[]
  setSelectedItems: (items: ClarificationTenderItemModel[]) => void
  canSubmit: boolean | undefined
  canUpdateApproval: boolean | undefined
  canSendToEvaluator: boolean | undefined
  canAlihkan: boolean | undefined
}

const EvaluationListContext = createContext<EvaluationListContextType>({
  selectedItems: [],
  setSelectedItems: () => {},
  canSubmit: undefined,
  canUpdateApproval: undefined,
  canSendToEvaluator: undefined,
  canAlihkan: undefined,
})

const EvaluationListProvider = ({children}) => {
  const {currentUser} = useAuth()
  const [selectedItems, setSelectedItems] = useState<ClarificationTenderItemModel[]>([])

  const firstCompanyId = selectedItems?.[0]?.company_id

  const {canSubmit, canUpdateApproval, canSendToEvaluator, canAlihkan} = useMemo(() => {
    if (selectedItems.length <= 0) {
      return {
        canSubmit: undefined,
        canUpdateApproval: undefined,
        canSendToEvaluator: undefined,
        canAlihkan: undefined,
      }
    }

    return {
      canSubmit: selectedItems.every((item) => {
        return item.company_id === firstCompanyId && canSubmitFn(currentUser, item)
      }),
      canUpdateApproval: selectedItems.every((item) => {
        return canUpdateApprovalFn(currentUser, item)
      }),
      canSendToEvaluator: selectedItems.every((item) => {
        return item.company_id === firstCompanyId && canSendToEvaluatorFn(currentUser, item)
      }),
      canAlihkan: selectedItems.every((item) => {
        return item.company_id === firstCompanyId && canAlihkanFn(currentUser, item)
      }),
    }
  }, [currentUser, firstCompanyId, selectedItems])

  return (
    <EvaluationListContext.Provider
      value={{
        selectedItems,
        setSelectedItems,
        canSubmit,
        canUpdateApproval,
        canSendToEvaluator,
        canAlihkan,
      }}
    >
      {children}
    </EvaluationListContext.Provider>
  )
}
//endregion

//region ClarificationTenderItemsDispositionModalWithButtons
//region Fetch & useQuery
interface ClarificationTenderItemFilters {
  status?: ClarificationTenderItemStatus[]
  uuid?: string[]
  current_user?: string
  evaluator_or_end_user?: string
  is_complete_for_me?: 1
}
const useClarificationTenderItemDispositionMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (data: any) => axios.post(`${TENDER_API_URL}/clarification/tender-item/disposition`, data),
    {
      onSuccess: async (response: AxiosResponse<Response<any>>) => {
        await queryClient.invalidateQueries(['clarification-tender-items', 'count'])
        toastSuccess(response.data.meta.message)
      },
      onError: (error: AxiosError) => {
        toastError(
          error.response.data?.meta?.message || error.response.data?.message || 'Terjadi kesalahan'
        )
      },
    }
  )
}

//endregion

type DispositionActionType = 'APPROVE' | 'REJECT'

interface ClarificationTenderItemsDispositionProps {
  items: ClarificationTenderItemModel[]
  currentUsername: string
  onDispositionSuccess?: () => void
}

const ClarificationTenderItemsDispositionModalWithButtons = ({
  items,
  currentUsername,
  onDispositionSuccess,
}: ClarificationTenderItemsDispositionProps) => {
  const intl = useIntl()
  const [isFinalApprove, setIsFinalApprove] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [actionType, setActionType] = useState<DispositionActionType>('APPROVE')

  const {isLoading: isDispositionLoading, mutate: disposition} =
    useClarificationTenderItemDispositionMutation()

  const onHide = () => !isDispositionLoading && setShowModal(false)
  const disableApproveButton = items.length < 1 || items[0].step === 'end_user'

  return (
    <>
      <div className='d-inline-block mb-2'>
        <Button
          variant='primary'
          size='sm'
          className='shadow-sm fw-bold me-2'
          disabled={disableApproveButton}
          onClick={() => {
            setIsFinalApprove(false)
            setActionType('APPROVE')
            setShowModal(true)
          }}
        >
          <i className='fas fa-hand-holding-hand fa-fw'></i>
          {disableApproveButton
            ? intl.formatMessage({id: 'Disposisi Dokumen'})
            : intl.formatMessage({id: 'Disposisi ({count}) Dokumen'}, {count: items.length})}
        </Button>

        <Button
          variant='info'
          size='sm'
          className='shadow-sm fw-bold me-2'
          disabled={items.length < 1}
          onClick={() => {
            setIsFinalApprove(true)
            setActionType('APPROVE')
            setShowModal(true)
          }}
        >
          <i className='fas fa-arrow-down fa-fw'></i>
          {items.length < 1
            ? intl.formatMessage({id: 'Terima Dokumen'})
            : intl.formatMessage({id: 'Terima ({count}) Dokumen'}, {count: items.length})}
        </Button>

        <Button
          variant='danger'
          size='sm'
          className='shadow-sm fw-bold me-2'
          disabled={items.length < 1}
          onClick={() => {
            setActionType('REJECT')
            setShowModal(true)
          }}
        >
          <i className='fas fa-redo fa-fw me-2'></i>
          {items.length < 1
            ? intl.formatMessage({id: 'Kembalikan Dokumen'})
            : intl.formatMessage({id: 'Kembalikan ({count}) Dokumen'}, {count: items.length})}
        </Button>
      </div>

      <Modal size='lg' show={showModal} onHide={onHide}>
        <Formik
          onSubmit={async (values: any) => {
            disposition(
              {
                clarification_tender_item_ids: items?.map((item) => item.uuid),
                action: actionType,
                ...values,
                next_user_id: values.next_user_id?.username,
              },
              {
                onSuccess: () => {
                  onHide()
                  onDispositionSuccess && onDispositionSuccess()
                },
              }
            )
          }}
          initialValues={{
            comment: '',
            next_user_id: '',
          }}
          validationSchema={Yup.object().shape({
            comment: Yup.string().label(intl.formatMessage({id: 'Catatan Disposisi'})),
            next_user_id: Yup.object()
              .when({
                is: () => !isFinalApprove && actionType === 'APPROVE',
                then: (schema) => schema.required(),
              })
              .label(intl.formatMessage({id: 'Tujuan Disposisi'})),
          })}
        >
          {() => (
            <Form>
              <Modal.Header closeButton={!isDispositionLoading}>
                <h4>
                  {actionType === 'REJECT' && intl.formatMessage({id: 'Kembalikan Evaluasi'})}
                  {actionType === 'APPROVE' &&
                    (!isFinalApprove
                      ? intl.formatMessage({id: 'Dispo Eval ke Subordinate'})
                      : intl.formatMessage({id: 'Terima Evaluasi'}))}
                </h4>
              </Modal.Header>
              <Modal.Body className='p-0'>
                <EvaluationTenderItemsModalInfo
                  clarificationTenderItemsFetchParams={{
                    filters: {
                      uuid: items.map((item) => item.uuid),
                      current_user: currentUsername,
                    },
                  }}
                  queryOptions={{
                    enabled: showModal && items.length > 0,
                  }}
                  initialData={items}
                  afterItemPrTable={
                    <>
                      {(!isFinalApprove || actionType === 'REJECT') && (
                        <section title='Note Disposisi' className='mb-4'>
                          <label className='form-label mb-3' htmlFor='buyer_note'>
                            {intl.formatMessage({id: 'Catatan Disposisi'})}
                          </label>

                          <Field as='textarea' name='comment' className={'form-control'} />

                          <MetronicErrorMessage name='comment' />
                        </section>
                      )}

                      {!isFinalApprove && actionType === 'APPROVE' && (
                        <section title='Tujuan Disposisi'>
                          <label className='form-label required' htmlFor='next_user_id'>
                            {intl.formatMessage({id: 'Tujuan Disposisi'})}
                          </label>

                          <FormikSelect2
                            name='next_user_id'
                            optionsUrl={`${getEnv(
                              'sso'
                            )}/user?for=clarification-tender-item-disposition`}
                            getOptionLabel={(opt: UserModel) => {
                              return `${opt.username} - ${opt.name}`
                            }}
                            getOptionValue={(opt: any) => opt}
                            unique_property='id'
                            search_property={'username'}
                          />
                        </section>
                      )}
                    </>
                  }
                />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  type='button'
                  variant='danger'
                  disabled={isDispositionLoading}
                  onClick={onHide}
                >
                  {intl.formatMessage({id: 'Batal'})}
                </Button>
                <Button type='submit' variant='secondary' disabled={isDispositionLoading}>
                  {actionType === 'REJECT' && intl.formatMessage({id: 'Kembalikan'})}
                  {actionType === 'APPROVE' &&
                    (!isFinalApprove
                      ? intl.formatMessage({id: 'Disposisi'})
                      : intl.formatMessage({id: 'Terima'}))}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
}
//endregion

//region ClarificationTenderItemsAlihkanModalWithButtons
const ClarificationTenderItemsAlihkanModalWithButtons = ({
  items,
  currentUsername,
  onSuccess,
}: {
  items: ClarificationTenderItemModel[]
  currentUsername: string
  onSuccess?: () => void
}) => {
  const intl = useIntl()
  const queryClient = useQueryClient()
  const [showModal, setShowModal] = useState(false)

  const {isLoading: isAlihkanLoading, mutate: alihkan} = useClarificationTenderItemAlihkanMutation()

  const onHide = () => setShowModal(false)
  const isLoading = isAlihkanLoading
  const disableAlihkanButton = items.length < 1 || items[0].step === 'end_user' || isLoading

  return (
    <>
      <div className='d-inline-block mb-2'>
        <Button
          variant='warning'
          size='sm'
          className='shadow-sm fw-bold me-2'
          disabled={disableAlihkanButton}
          onClick={() => {
            setShowModal(true)
          }}
        >
          <i className='fas fa-arrow-right fa-fw'></i>
          {items.length < 1
            ? intl.formatMessage({id: 'Alihkan Dokumen'})
            : intl.formatMessage({id: 'Alihkan ({count}) Dokumen'}, {count: items.length})}
        </Button>
      </div>

      <Modal size='lg' show={showModal} onHide={onHide}>
        <Formik
          onSubmit={async (values: {user: UserModel}) => {
            alihkan(
              {
                clarification_tender_item_ids: items?.map((item) => item.uuid),
                user_id: Number(values.user?.id),
              },
              {
                onSuccess: async (response: AxiosResponse<Response<any>>) => {
                  await queryClient.invalidateQueries(['clarification-tender-items', 'count'])
                  toastSuccess(response.data.meta.message)
                  onHide()
                  onSuccess && onSuccess()
                },
                onError: (error: AxiosError) => {
                  toastError(
                    error.response.data?.meta?.message ||
                      error.response.data?.message ||
                      'Terjadi kesalahan'
                  )
                },
              }
            )
          }}
          initialValues={{
            user: undefined,
          }}
          validationSchema={Yup.object().shape({
            user: Yup.object()
              .required()
              .label(intl.formatMessage({id: 'Tujuan Pengalihan'})),
          })}
        >
          {() => (
            <Form>
              <Modal.Header closeButton={!isLoading}>
                <h4>{intl.formatMessage({id: 'Alihkan Evaluasi'})}</h4>
              </Modal.Header>
              <Modal.Body className='p-0'>
                <EvaluationTenderItemsModalInfo
                  clarificationTenderItemsFetchParams={{
                    filters: {
                      uuid: items.map((item) => item.uuid),
                      current_user: currentUsername,
                    },
                  }}
                  queryOptions={{
                    enabled: showModal && items.length > 0,
                  }}
                  initialData={items}
                  afterItemPrTable={
                    <section title='Tujuan Pengalihan'>
                      <label className='form-label required' htmlFor='user'>
                        {intl.formatMessage({id: 'Tujuan Pengalihan'})}
                      </label>

                      <FormikSelect2
                        name='user'
                        optionsUrl={`${getEnv('sso')}/user?for=clarification-tender-item-alihkan`}
                        getOptionLabel={(opt: UserModel) => `${opt.username} - ${opt.name}`}
                        getOptionValue={(opt: any) => opt}
                        unique_property='id'
                        search_property={['name', 'username']}
                      />
                    </section>
                  }
                />
              </Modal.Body>
              <Modal.Footer>
                <Button type='button' variant='danger' disabled={isLoading} onClick={onHide}>
                  {intl.formatMessage({id: 'Batal'})}
                </Button>
                <Button type='submit' variant='secondary' disabled={isLoading}>
                  {intl.formatMessage({id: 'Alihkan'})}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
}
//endregion

//region IncomingHeaderButtons
const IncomingHeaderButtons = ({
  enableAlihkan,
  onDispositionSuccess,
  onAlihkanSuccess,
}: {
  enableAlihkan?: boolean
  onDispositionSuccess?: () => void
  onAlihkanSuccess?: () => void
}) => {
  const {currentUser} = useAuth()
  const {selectedItems: items} = useContext(EvaluationListContext)

  return (
    <>
      <ClarificationTenderItemsDispositionModalWithButtons
        items={items}
        currentUsername={currentUser?.username}
        onDispositionSuccess={onDispositionSuccess}
      />

      {enableAlihkan && (
        <ClarificationTenderItemsAlihkanModalWithButtons
          items={items}
          currentUsername={currentUser?.username}
          onSuccess={onAlihkanSuccess}
        />
      )}
    </>
  )
}
//endregion

//region SubmitModalWithButton
const SubmitModalWithButton = ({onSuccess}: {onSuccess?: () => void}) => {
  const intl = useIntl()
  const {selectedItems: items, canSubmit} = useContext(EvaluationListContext)
  const [isShowModal, setIsShowModal] = useState(false)

  const itemUuids = useMemo(() => items.map((item) => item.uuid), [items])
  const routingApprovalConfigQuery = useGetRoutingApprovalConfigForSubmitReturnQuery(itemUuids, {
    enabled: isShowModal && items.length > 0,
    retry: false,
    onError: (error: AxiosError) => {
      setIsShowModal(false)
      toastError(
        error.response.data?.meta?.message || error.response.data?.message || 'Terjadi kesalahan'
      )
    },
  })

  const submitMutation = useSubmitReturnMutation({
    onSuccess: (response) => {
      toastSuccess(response.data.meta.message)
      setIsShowModal(false)
      onSuccess && onSuccess()
    },
    onError: (error: AxiosError) => {
      toastError(
        error.response.data?.meta?.message || error.response.data?.message || 'Terjadi kesalahan'
      )
    },
  })

  const onSubmit = useCallback(
    (values: {approval: any[]}) => {
      submitMutation.mutate({
        clarification_tender_item_ids: itemUuids,
        approvers: values.approval.map((item: any) => ({
          step: item.step,
          jabatan: item.jabatan,
          type: 'APPROVAL',
          user_id: item.extra_info.user.user_id,
          extra_info: item.extra_info,
        })),
      })
    },
    [itemUuids, submitMutation]
  )

  const routingApprovalConfig = routingApprovalConfigQuery.data
  const isButtonDisabled =
    canSubmit === false ||
    items.length < 1 ||
    routingApprovalConfigQuery.isFetching ||
    submitMutation.isLoading
  const firstItem = items?.[0]

  return (
    <>
      <div className='d-inline-block mb-2'>
        <Button
          variant='primary'
          size='sm'
          className='shadow-sm fw-bold me-2'
          disabled={isButtonDisabled}
          onClick={() => {
            setIsShowModal(true)
          }}
        >
          <i className='fas fa-paper-plane fa-fw me-1'></i>
          {items.length < 1 || canSubmit !== true
            ? intl.formatMessage({id: 'Submit'})
            : intl.formatMessage({id: 'Submit ({count})'}, {count: items.length})}
        </Button>

        <ApprovalX
          show={isShowModal && !routingApprovalConfigQuery.isFetching}
          schema={routingApprovalConfig?.details}
          onHide={() => setIsShowModal(false)}
          onSubmit={onSubmit}
          isLoading={submitMutation.isLoading}
          source={'TENDER'}
          object_id={firstItem?.id}
          company_id={firstItem?.company_id}
          code={firstItem?.routing_approval_config_code}
          readOnly={false}
        />
      </div>
    </>
  )
}
//endregion

//region SendToEndUserModalWithButton
const SendToEndUserModalWithButton = ({onSuccess}: {onSuccess?: () => void}) => {
  const {selectedItems, canSendToEvaluator} = useContext(EvaluationListContext)
  const [isShowModal, setIsShowModal] = useState(false)

  const isButtonDisabled = canSendToEvaluator === false || selectedItems.length < 1

  return (
    <>
      <div className='d-inline-block mb-2'>
        <Button
          variant='info'
          size='sm'
          className='shadow-sm fw-bold me-2'
          disabled={isButtonDisabled}
          onClick={() => setIsShowModal(true)}
        >
          <i className='fas fa-user fa-fw me-1'></i>
          {selectedItems.length < 1 || canSendToEvaluator !== true
            ? 'Evaluasi End User'
            : `(${selectedItems.length}) Evaluasi End User`}
        </Button>

        <EvaluationSendToEndUserModal
          clarificationTenderItems={selectedItems}
          show={isShowModal}
          onHide={() => setIsShowModal(false)}
          onSuccess={onSuccess}
        />
      </div>
    </>
  )
}
//endregion

//region SendToEndUserModalWithButton
const AlihkanModalWithButton = ({onSuccess}: {onSuccess?: () => void}) => {
  const {selectedItems, canAlihkan} = useContext(EvaluationListContext)
  const [isShowModal, setIsShowModal] = useState(false)

  const isButtonDisabled = canAlihkan === false || selectedItems.length < 1

  return (
    <>
      <div className='d-inline-block mb-2'>
        <Button
          variant='danger'
          size='sm'
          className='shadow-sm fw-bold me-2'
          disabled={isButtonDisabled}
          onClick={() => setIsShowModal(true)}
        >
          <i className='fas fa-arrow-right fa-fw me-1'></i>
          {selectedItems.length < 1 || canAlihkan !== true
            ? 'Alihkan'
            : `Alihkan (${selectedItems.length})`}
        </Button>

        <EvaluationAlihkanModal
          clarificationTenderItems={selectedItems}
          show={isShowModal}
          onHide={() => setIsShowModal(false)}
          onSuccess={onSuccess}
        />
      </div>
    </>
  )
}
//endregion

//region OnProcessHeaderButtons
const OnProcessHeaderButtons = ({enableAlihkan, onSuccess}) => {
  const {selectedItems, canUpdateApproval} = useContext(EvaluationListContext)

  const approvalItems = useMemo(() => {
    return selectedItems.map((item: ClarificationTenderItemModel) => ({
      source: 'TENDER',
      object_id: item.id,
      company_id: item.company_id,
      code: item.routing_approval_config_code,
    }))
  }, [selectedItems])

  return (
    <>
      <SubmitModalWithButton onSuccess={onSuccess} />

      <SendToEndUserModalWithButton onSuccess={onSuccess} />

      {enableAlihkan && <AlihkanModalWithButton onSuccess={onSuccess} />}

      <div className='d-inline-block text-nowrap'>
        <BatchApproval
          enabled={canUpdateApproval === true}
          items={approvalItems}
          onSuccess={onSuccess}
          buttonSize='sm'
        />
      </div>
    </>
  )
}
//endregion

//region EvaluationIncomingList
const getTableColumns = (
  intl: IntlShape,
  queryClient: QueryClient,
  currentUrl: string
): IColumns[] | Column<ClarificationTenderItemModel>[] => [
  {
    Header: intl.formatMessage({id: 'Aksi'}),
    accessor: 'name',
    searchable: false,
    orderable: false,
    disableSortBy: true,
    width: 100,
    Cell: ({row}) => (
      <div className={'text-center'}>
        <Link
          to={`/mr-sr/evaluasi-penawaran/${row?.original?.uuid}`}
          state={{previous: currentUrl}}
          onClick={() => {
            queryClient.invalidateQueries([
              'clarification-tender-items',
              row?.original?.uuid,
              'results',
            ])
          }}
        >
          <i className={'fa fa-search fs-2'} />
        </Link>
      </div>
    ),
  },
  {
    Header: intl.formatMessage({id: 'Status'}),
    accessor: 'status_description',
    width: 250,
  },
  {
    Header: 'No Tender',
    accessor: 'no_tender',
    orderable: false,
    disableSortBy: true,
    width: 125,
  },
  {
    Header: 'Company',
    accessor: 'company_code',
    orderable: false,
    disableSortBy: true,
    width: 100,
  },
  {
    Header: intl.formatMessage({id: 'Nomor PR'}),
    accessor: 'no_pr',
    orderable: false,
    disableSortBy: true,
    width: 150,
  },
  {
    Header: intl.formatMessage({id: 'Item PR'}),
    accessor: 'line_no',
    orderable: false,
    disableSortBy: true,
    width: 150,
  },
  {
    Header: intl.formatMessage({id: 'Short Text'}),
    accessor: 'short_text',
    orderable: false,
    disableSortBy: true,
    width: 150,
  },
  {
    Header: intl.formatMessage({id: 'Kategori Evaluasi'}),
    accessor: 'categories',
    width: 250,
    orderable: false,
    disableSortBy: true,
    Cell: ({value}) => value.join(', '),
  },
  {
    Header: intl.formatMessage({id: 'Parameter'}),
    accessor: 'parameters',
    orderable: false,
    disableSortBy: true,
    width: 200,
    Cell: ({value}) => (
      <>
        {Array.isArray(value) && value.length > 0 && (
          <ul>
            {value.map((item: string, index: number) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        )}
      </>
    ),
  },
  {
    Header: intl.formatMessage({id: 'Buyer'}),
    accessor: 'created_by',
    orderable: false,
    disableSortBy: true,
    width: 250,
    Cell: ({value}) =>
      typeof value !== 'string' && value?.username ? `${value.username} - ${value.name}` : value,
  },
]

interface EvaluationIncomingListProps {
  filterKey: EvaluationUserFilterKey
  filters: ClarificationTenderItemFilters
}

const EvaluationIncomingList = ({filterKey, filters}: EvaluationIncomingListProps) => {
  const {currentUser} = useAuth()
  const intl = useIntl()
  const location = useLocation()
  const queryClient = useQueryClient()
  const context = useContext(EvaluationListContext)
  const [tableKey, setTableKey] = useState(0)
  const [hasEvalutorItem, setHasEvalutorItem] = useState(false)
  const defaultFilter = new URLSearchParams(listParamsTransformer({filters: filters})).toString()
  const columns = useMemo(
    () => getTableColumns(intl, queryClient, `${location.pathname}${location.hash}`) as IColumns[],
    [intl, location.hash, location.pathname, queryClient]
  )

  const checkboxConditionDisabled = useCallback(
    (row: ClarificationTenderItemModel, selectedFlatRows: ClarificationTenderItemModel[]) => {
      const firstRow = selectedFlatRows[0]
      const noSelectedRows = selectedFlatRows.length < 1
      const sameCompany = row.company_id === firstRow?.company_id

      switch (true) {
        case canSubmitFn(currentUser, row):
          return noSelectedRows || (sameCompany && canSubmitFn(currentUser, firstRow))

        case canUpdateApprovalFn(currentUser, row):
          return noSelectedRows || canUpdateApprovalFn(currentUser, firstRow)

        case canSendToEvaluatorFn(currentUser, row):
          return noSelectedRows || (sameCompany && canSendToEvaluatorFn(currentUser, firstRow))

        case canAlihkanFn(currentUser, row):
          return noSelectedRows || (sameCompany && canAlihkanFn(currentUser, firstRow))
      }

      return false
    },
    [currentUser]
  )

  const onSelectedRows = useCallback(
    (rows: any) => {
      if (filterKey === 'completed') {
        return
      }

      context.setSelectedItems(rows.map((row: any) => row.original))
    },
    [context, filterKey]
  )

  return (
    <KTCard>
      <KTCardBody className='py-4'>
        <TableXWrpper
          checkboxConditionDisabled={
            filterKey === 'on_process' ? checkboxConditionDisabled : undefined
          }
          onSelectedRows={onSelectedRows}
          key={tableKey}
          readonly={true}
          urlIndex={`${TENDER_API_URL}/clarification/tender-item`}
          defaultFilter={defaultFilter}
          columns={columns}
          disablePaddingCard={true}
          onAfterLoadData={(response: PaginatedResponse<ClarificationTenderItemModel>) => {
            setHasEvalutorItem(
              response.data?.some((item: ClarificationTenderItemModel) => item.step === 'evaluator')
            )
          }}
          headerButtons={
            <>
              {filterKey === 'incoming' && (
                <IncomingHeaderButtons
                  enableAlihkan={hasEvalutorItem}
                  onDispositionSuccess={() => {
                    setTableKey(tableKey + 1)
                    context.setSelectedItems([])
                  }}
                  onAlihkanSuccess={() => {
                    setTableKey(tableKey + 1)
                    context.setSelectedItems([])
                  }}
                />
              )}

              {filterKey === 'on_process' && (
                <OnProcessHeaderButtons
                  enableAlihkan={hasEvalutorItem}
                  onSuccess={() => {
                    setTableKey(tableKey + 1)
                    context.setSelectedItems([])
                  }}
                />
              )}
            </>
          }
        />
      </KTCardBody>
    </KTCard>
  )
}
//endregion

const getFilters = (currentUsername: string): EvaluationUserFilter => ({
  incoming: {
    current_user: currentUsername,
    status: ['Draft', 'Draft End User'],
  },
  on_process: {
    current_user: currentUsername,
    status: [
      'On Progress',
      'Submit Approval Return',
      'Reject Approval Return',
      'Reject Receive',
      'Submit Approval Evaluator',
      'Reject Approval Evaluator',
      'Submit Approval Alihkan',
      'Reject Approval Alihkan',
      'Cancel End User',
      'On Progress End User',
      'Submit Approval Return End User',
      'Reject Approval Return End User',
    ],
  },
  completed: {
    is_complete_for_me: 1,
  },
})

export const EvaluationTabList = () => {
  const auth = useAuth()
  const intl = useIntl()
  const filters = useMemo(() => getFilters(auth.currentUser.username), [auth.currentUser.username])
  const {data: counts, refetch: refetchCounts} = useClarificationTenderItemCountsQuery(
    auth.currentUser.username,
    {groups: filters}
  )
  const initialSelectedTab = useHashNumberValueByPrefix('tab', 1, [1, 2, 3])
  const [currentTabNumber, setCurrentTabNumber] = useState(initialSelectedTab - 1)
  const tabs = [
    {
      value: 0,
      text: intl?.formatMessage({id: 'Evaluasi Masuk'}),
      total: counts?.incoming,
      href: `/mr-sr/evaluasi-penawaran/incoming#tab=1`,
      preventDefault: false,
    },
    {
      value: 1,
      text: intl?.formatMessage({id: 'Proses Evaluasi'}),
      total: counts?.on_process,
      href: `/mr-sr/evaluasi-penawaran/incoming#tab=2`,
      preventDefault: false,
    },
    {
      value: 2,
      text: intl?.formatMessage({id: 'Evaluasi Selesai'}),
      total: counts?.completed,
      href: `/mr-sr/evaluasi-penawaran/incoming#tab=3`,
      preventDefault: false,
    },
  ]

  return (
    <>
      <KTCard className={'mt-0'}>
        <TableTabs
          tabs={tabs}
          currentTab={currentTabNumber}
          setCurrentTab={async (tabNumber: any) => {
            setCurrentTabNumber(tabNumber)
            await refetchCounts()
          }}
        />
        <KTCardBody className='py-4'>
          <EvaluationListProvider>
            {currentTabNumber === 0 && (
              <EvaluationIncomingList filterKey={'incoming'} filters={filters['incoming']} />
            )}
            {currentTabNumber === 1 && (
              <EvaluationIncomingList filterKey={'on_process'} filters={filters['on_process']} />
            )}
            {currentTabNumber === 2 && (
              <EvaluationIncomingList filterKey={'completed'} filters={filters['completed']} />
            )}
          </EvaluationListProvider>
        </KTCardBody>
      </KTCard>
    </>
  )
}
