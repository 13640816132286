import {FC} from 'react'
import {useLang} from './Metronici18n'
import {IntlProvider} from 'react-intl'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/de'
import '@formatjs/intl-relativetimeformat/locale-data/es'
import '@formatjs/intl-relativetimeformat/locale-data/fr'
import '@formatjs/intl-relativetimeformat/locale-data/ja'
import '@formatjs/intl-relativetimeformat/locale-data/zh'

import enMessages from './messages/en.json'
import idMessages from './messages/id.json'
import enMessagesTender from './messages/enTender.json';
import idMessagesTender from './messages/idTender.json';
import {WithChildren} from '../helpers'

const allMessages = {
  en: {
    ...enMessages,
    ...enMessagesTender
  },
  id: {
    ...idMessages,
    ...idMessagesTender
  },
}

const I18nProvider: FC<WithChildren> = ({children}) => {
  const locale = useLang()
  const messages = allMessages[locale]

  return (
    <IntlProvider locale={locale} messages={messages} onError={(err) => {
        // Opsional: tambahkan custom error handling
        if (err.code === 'MISSING_TRANSLATION') {
          console.warn('Missing translation:', err.message);
          return;
        }
        throw err;
    }}>
      {children}
    </IntlProvider>
  )
}

export {I18nProvider}
