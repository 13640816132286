import { ButtonGroup, Col, Row } from 'react-bootstrap'
import { MetronicErrorMessage, toastError, toastSuccess } from '../../../../../../_metronic/helpers'
import { Field, Form, Formik } from 'formik'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { ApiUrl } from '../../../../../config/Api'

import FormikSelect2 from '../../../../../components/shared/form/FormikSelect2'
import TableXWrapper from '../../../../../components/shared/TableXWrapper'
import CurrencyInput from 'react-currency-input-field'
import Swal from 'sweetalert2'
import * as Yup from 'yup'
import axios from 'axios'

const validationSchema = Yup.object({})

interface Props {
  data: any
  header: any
  disabled: any
}

const AdditionalCostPO = ({ data, header, disabled }: Props) => {
  const [key, setKey] = useState(0)
  const [loading, setLoading] = useState(false)
  const [indexForm, setFormIndex] = useState(0)

  const columns = [
    {
      Header: 'Action',
      accessor: 'actions',
      width: 220,
      Cell: (props: any) => (
        <>
          {(header?.status === "Draft" || header?.status === "PO Rejected") &&
            <ButtonGroup size={'sm'} aria-label='Action'>
              <button
                className={"btn btn-sm btn-light-success text-nowrap"}
                type={"button"}
                onClick={() => {
                  setFormIndex(indexForm + 1)
                  setInitialValues(props?.cell?.row?.original)
                  const form = document?.getElementById('form-additionalCostPO')
                  if (form) {
                    window.scrollTo(0, form.offsetTop ? form.offsetTop - 75 : 0)
                  }
                }}
              >
                <i className={"fa fa-edit me-2"}></i>
                Edit
              </button>
              <button
                className={"btn btn-sm btn-light-danger text-nowrap"}
                type={"button"}
                onClick={() => {
                  deleteItem(props?.cell?.row?.original?.uuid)
                }}
              >
                <i className={"fa fa-trash me-2"}></i>
                Delete
              </button>
            </ButtonGroup>
          }
        </>
      ),
    },
    {
      Header: 'Conditional Type',
      accessor: 'conditional_type_code_description',
      width: 200
    },
    {Header: 'Amount', accessor: 'amount_format', width: 200},
    {
      Header: 'Satuan',
      accessor: 'uom_uom_name',
      width: 200
    },
    {
      Header: 'Vendor',
      accessor: 'vendor_no_name',
      width: 200
    },
  ]

  const [initialValues, setInitialValues] = useState({
    uuid: '',
    conditional_type: '',
    amount: '',
    uom: '',
    vendor: '',
  })

  const onSubmit = async (values: any) => {
    try {
      setLoading(true)

      const value = {
        purchase_order_item_id: data?.uuid,
        conditional_type_id: values?.conditional_type?.id,
        uom_id: values?.uom?.uom,
        vendor_id: values?.vendor?.vendor_no,
        amount: values?.amount ? parseFloat(values?.amount).toFixed(3) : "0.000"
      }

      let response: any
      if (initialValues?.uuid) {
        response = await axios.put(`${ApiUrl}/api/tender/po-additional-cost/${initialValues?.uuid}`, value)
      } else {
        response = await axios.post(`${ApiUrl}/api/tender/po-additional-cost`, value)
      }

      toastSuccess(response?.data?.meta?.message)
      setKey(key + 1)
    } catch (error: any) {
      toastError(error?.response?.data?.meta?.message || 'Terjadi kesalahan')
    } finally {
      setLoading(false)
    }
  }

  const deleteItem = (id: any) => {
    Swal.fire({
      title: "Apakah Anda Yakin?",
      text: "Anda akan menghapus item ini!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, Hapus",
      cancelButtonText: "Tidak"
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios.delete(`${ApiUrl}/api/tender/po-additional-cost/${id}`)

          toastSuccess(response?.data?.meta?.message)
          setKey(key + 1)
        } catch (error: any) {
          toastError(error?.response?.data?.meta?.message || 'Terjadi kesalahan')
        }
      }
    })
  }

  return (
    <>
      <div className='card border mt-5'>
        <div className='card-body'>
          <Formik
            key={indexForm}
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ values, setFieldValue }) => (
              <>
                <Form id='form-additionalCostPO'>
                  <Row>
                    <Col>
                      <div className='mb-10'>
                        <label htmlFor='exampleFormControlInput1' className='form-label'>
                          <span>Additional Cost (dari penawaran)</span>
                        </label>
                        <CurrencyInput
                          className='form-control'
                          groupSeparator='.'
                          decimalSeparator=','
                          decimalsLimit={2}
                          step={1}
                          value={data?.additional_cost || 0}
                          disabled
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3}>
                      <div className='mb-10'>
                        <label htmlFor='' className='form-label required'>
                          Conditional Type
                        </label>
                        <FormikSelect2
                          name={`conditional_type`}
                          optionsUrl={`${ApiUrl}/api/masterdata/conditional-types`}
                          getOptionValue={(opt: any) => opt}
                          getOptionLabel={(opt: any) => `${opt.code} - ${opt.description}`}
                          search_property={'code_description'}
                          isDisabled={header?.status !== 'Draft' && header?.status !== 'PO Rejected'}
                        />
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className='mb-10'>
                        <label
                          htmlFor='exampleFormControlInput1'
                          className='form-label required'
                        >
                          Amount
                        </label>
                        <CurrencyInput
                          className='form-control'
                          groupSeparator='.'
                          decimalSeparator=','
                          decimalsLimit={2}
                          step={1}
                          onValueChange={(value: any) => {
                            setFieldValue(`amount`, value)
                          }}
                          value={values?.amount}
                          disabled={header?.status !== 'Draft' && header?.status !== 'PO Rejected'}
                        />
                        <MetronicErrorMessage name='amount' />
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className='mb-10'>
                        <label htmlFor='' className='form-label required'>
                          Satuan
                        </label>
                        <FormikSelect2
                          name='uom'
                          optionsUrl={`${ApiUrl}/api/masterdata/satuan`}
                          getOptionValue={(opt: any) => opt}
                          getOptionLabel={(opt: any) => `${opt.uom} - ${opt.uom_name}`}
                          unique_property={'id'}
                          search_property='uom_uom_name'
                          isDisabled={header?.status !== 'Draft' && header?.status !== 'PO Rejected'}
                        />
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className='mb-10'>
                        <label htmlFor='' className='form-label required'>
                          Vendor
                        </label>
                        <FormikSelect2
                          name='vendor'
                          optionsUrl={`${ApiUrl}/api/vendor/vendor?filter[status_vendor]=active`}
                          getOptionValue={(opt: any) => opt}
                          getOptionLabel={(opt: any) => `${opt.vendor_no} - ${opt.name}`}
                          search_property='name'
                          isDisabled={header?.status !== 'Draft' && header?.status !== 'PO Rejected'}
                        />
                      </div>
                    </Col>

                  </Row>

                  <div className='d-flex justify-content-end mt-5'>
                    <button disabled={loading || header?.status !== 'Draft' && header?.status !== 'PO Rejected'} type='submit' className='btn btn-primary'>
                      {loading ? <span className='spinner-border spinner-border-sm me-1'/> : <i className="fas fa-save"></i>}
                      Save
                    </button>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </div>
      </div>

      <div className='card border mt-5'>
        <div className='card-body'>
          <TableXWrapper
            readonly
            key={key}
            urlIndex={`${ApiUrl}/api/tender/po-additional-cost?filter[purchase_order_item_id]=${data?.id}`}
            columns={columns}
          />
        </div>
      </div>
    </>
  )
}

export default AdditionalCostPO
