import {Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../../_metronic/layout/core'
import React, {useState} from 'react'
import {GoodMovementStockviewList} from './components/GoodMovementStockviewList'

const masterData = {
    title: 'Goods Movement',
    path: '/stockview/transaction/goods-movement',
    isSeparator: false,
    isActive: false,
}
const separator = {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
}
const breadcrumbs: Array<PageLink> = [masterData, separator]
const childBreadcrumbs: Array<PageLink> = [
    masterData,
    separator,
    {
        title: 'Stockview Goods Movement',
        path: '/stockview/transaction/goods-movement',
        isSeparator: false,
        isActive: false,
    },
    separator,
]

const GoodMovementStockview = () => {
    const tablecolumns = [
        {
            Header: "POSTING DATE",
            accessor: "post_date",
            width: 100,
        },
        {
            Header: "MATERIAL NUMBER",
            accessor: "material_number",
            width: 200,
        },
        {
            Header: "MOVEMENT TYPE",
            accessor: "movement_type",
            width: 100,
        },
        {
            Header: "PLANT",
            accessor: "plant",
            width: 200,
        },
        {
            Header: "SLOC",
            accessor: "sloc",
            width: 200,
        },
        {
            Header: "QUANTITY (TON)",
            accessor: "qty",
            width: 100,
        },
        {
            Header: "COMPANY CODE",
            accessor: "company_code",
            width: 100,
        },
        {
            Header: "REFERENCE",
            accessor: "reference",
            width: 180,
        },
    ];

    const [formIndex, setFormIndex] = useState(0);

    const optionMaterial = {
        url: process.env.REACT_APP_API_URL + '/api/masterdata/materials',
        key: 'id',
        label: 'material_desc',
        return_only_id: true,
    }
    const optionCompany = {
        url: process.env.REACT_APP_API_URL + '/api/masterdata/companies',
        key: 'id',
        label: 'comp_code_name',
        return_only_id: true,
    }

    // @ts-ignore
    const chartSeries = [
        {
            name: 'North',
            data: [],
        },
    ]

    const chartOptions = {}

    const [activeTab, setActiveTab] = useState(0) // State to track the active tab
    const [items, setItems] = useState<any>([])
    const [itemsMaping, setItemsMaping] = useState<any>([])
    const [itemsCompanyStock, setItemsCompanyStock] = useState<any>([])
    const [itemsMainCardStock, setItemsMainCardStock] = useState<any>([]);
    const [activeCompany, setActiveCompany] = useState<any>([]);
    const [itemsIDGrouping, setIDGrouping] = useState<any>({});
    const [itemLoading, setItemLoading] = useState(false)

    const handleTabClick = (index: any) => {
        setActiveTab(index)
    }

    let dateTime = new Date()
    const options: any = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        // timeZoneName: 'short',
        timeZone: 'Asia/Jakarta', // Set the time zone to WIB
    }

    let formattedDateTime = dateTime.toLocaleDateString('en-GB', options)

    // Convert the formatted date and time to uppercase
    formattedDateTime = formattedDateTime.toUpperCase()

    const [textareaValue, setTextareaValue] = useState('')

    const handleTextareaChange = (e: any) => {
        setTextareaValue(e.target.value)
    }

    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    index
                    element={
                        <>
                            <PageTitle breadcrumbs={breadcrumbs}>Goods Movement Stockview</PageTitle>
                            <div className='card'>
                                <div className='card-body'>
                                    <h6 className="card-title">In Out / Good Movement</h6>
                                    <p className="text-muted mb-3">Halaman ini bertujuan sebagai tools monitoring pergerakan harian keluar masuk
                                        barang di masing-masing anak perusahaan. Ada beberapa transaksi yang anda bisa lakukan, yaitu
                                        tambahkan manual transasi in-out, sinkronisasi dari SAP, update data eksisting, dan juga melakukan
                                        delete. Ada dua sumber data, yaitu SAP (PKT) dan Manual (PKG, PSP, PKC, PIM).</p>
                                </div>
                            </div>
                            {/* <div className='card border mt-5'> */}
                            <GoodMovementStockviewList />
                            {/* <TableXWrapper
                                    key={formIndex}
                                    readonly
                                    urlIndex={`${ApiUrl}/api/transmovement-list/transmovement-list`}
                                    columns={tablecolumns}
                                    headerButtons={
                                        <>
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                title="Add Data"
                                                onClick={handleButtonClick}
                                            >
                                                ADD Data
                                            </button>
                                        </>
                                    }
                                /> */}
                            {/* </div> */}
                        </>
                    }
                ></Route>
            </Route>
        </Routes>
    )
}

export default GoodMovementStockview
