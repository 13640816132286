import { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { getEnv } from "../../../../../helpers/procurex";

const useSyncPR = () => {
  const url = process.env.REACT_APP_API_URL + "/api/tender/purchase-requisition";
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);

  const doSubmit = async (values: any, _toast: any) => {
    if (values.opsi === "no_pr") {
      values.start_date = moment().startOf('month').format('YYYY-MM-DD')
      values.end_date = moment().endOf('month').format('YYYY-MM-DD')
    }
    //return promise
    setIsLoading(true);
    setIsError(false);
    setIsSuccess(false);
    setData({});
    try {
      const response = await axios.post(url, values);
      if (response.status === 200 || response.status === 201) {
        setIsSuccess(true);
        setData(response.data);
        _toast.success(response.data.meta.message);
      } else {
        setIsError(true);
        _toast.error("Gagal Sync PR");
      }
    } catch (error) {
      setIsError(true);
      // @ts-ignore
      if (error?.response && error?.response.data && error?.response.data.errors) {
        // @ts-ignore
        const validationErrors = error.response.data.errors;

        // Display validation errors using toast
        Object.keys(validationErrors).forEach((field) => {
          const errorMessages = validationErrors[field].join(", ");
          _toast.error(`${field}: ${errorMessages}`);
        });
        // @ts-ignore
      } else if(error?.response?.data?.meta?.message) {
        // @ts-ignore
        _toast.error(error?.response?.data?.meta?.message);
      } else {
        // If it's not a validation error, display a generic error message
        _toast.error("An error occurred");
      }
    } finally {
      setIsLoading(false);
    }
  };
  return [{ doSubmit, isLoading, isError, data, isSuccess }];
};

const usePostingPRToAvailable = () => {
  const url = getEnv('tender') + "/purchase-requisition-item-tender";
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);

  const doSubmit = async (values: any, _toast: any, afterStore: Function) => {
    //return promise
    setIsLoading(true);
    setIsError(false);
    setIsSuccess(false);
    setData({});
    try {
      const response = await axios.post(url, values);
      if (response.status === 200 || response.status === 201) {
        setIsSuccess(true);
        setData(response.data);
        _toast.success(response.data.meta.message);
        afterStore()
      } else {
        setIsError(true);
        _toast.error("Gagal Buat Proposal Tender");
      }
    } catch (error) {
      setIsError(true);
      // @ts-ignore
      _toast.error(error?.response?.data?.meta?.message);
    } finally {
      setIsLoading(false);
    }
  };
  return [{ doSubmit, isLoading, isError, data, isSuccess }];
};

const useCreateProposalTender = () => {
  const url = getEnv('tender')+"/tender";
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);

  const doSubmit = async (values: any, _toast: any, afterStore: Function) => {
    //return promise
    setIsLoading(true);
    setIsError(false);
    setIsSuccess(false);
    setData({});
    try {
      const response = await axios.post(url, values);
      if (response.status === 200 || response.status === 201) {
        setIsSuccess(true);
        setData(response.data);
        _toast.success(response.data.meta.message);
        afterStore(response.data)
      } else {
        setIsError(true);
        _toast.error("Gagal Buat Proposal Tender");
      }
    } catch (error) {
      setIsError(true);
      // @ts-ignore
      _toast.error(error?.response?.data?.meta?.message);
    } finally {
      setIsLoading(false);
    }
  };
  return [{ doSubmit, isLoading, isError, data, isSuccess }];
};

const useGetDetailProposalTender = () => {
  const url = getEnv('tender') + "/tender";
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);

  const doGetDetail = async (uuid:String,_toast:any) => {
    //return promise
    setIsLoading(true);
    setIsError(false);
    setIsSuccess(false);
    setData(null);
    try {
      const response = await axios.get(url+"/"+uuid);
      if (response.status === 200 || response.status === 201) {
        setIsSuccess(true);
        setData(response.data.data);
      } else {
        setIsError(true);
        _toast.error("Gagal Ambil Detail Proposal Tender");
      }
    } catch (error) {
      setIsError(true);
      // @ts-ignore
      _toast.error(error?.response?.data?.meta?.message);
    } finally {
      setIsLoading(false);
    }
  };
  return [{ doGetDetail, isLoading, isError, data, isSuccess }];
}

const useGetDetailAanwijzing = () => {
  const url = getEnv('tender') + "/aanwijzing";
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);

  const doGetDetail = async (uuid:String,_toast:any) => {
    //return promise
    setIsLoading(true);
    setIsError(false);
    setIsSuccess(false);
    setData({});
    try {
      const response = await axios.get(url+"/"+uuid);
      if (response.status === 200 || response.status === 201) {
        setIsSuccess(true);
        setData(response.data.data);
      } else {
        setIsError(true);
        _toast.error("Gagal Ambil Detail Proposal Tender");
      }
    } catch (error) {
      setIsError(true);
      // @ts-ignore
      _toast.error(error?.response?.data?.meta?.message);
    } finally {
      setIsLoading(false);
    }
  };
  return [{ doGetDetail, isLoading, isError, data, isSuccess }];
}

const usePostJadwalTender = () => {
  const url = getEnv('tender') + "/tender-jadwal";
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);

  const doSubmit = async (values: any, _toast: any, afterStore: Function) => {
    //return promise
    setIsLoading(true);
    setIsError(false);
    setIsSuccess(false);
    setData({});
    try {
      let _url = url;
      if(values?.description === 'Pra Qualification'){
        _url = getEnv('tender') + "/pq-jadwal"
      }
      if(values?.current_id) {
        values._method = "PUT"
        _url = _url + "/" + values.current_id
      }
      const response = await axios.post(_url, values);
      if (response.status === 200 || response.status === 201) {
        setIsSuccess(true);
        setData(response.data);
        _toast.success(response.data.meta.message);
        afterStore()
      } else {
        setIsError(true);
        _toast.error("Gagal Buat Proposal Tender");
      }
    } catch (error) {
      setIsError(true);
      // @ts-ignore
      _toast.error(error?.response?.data?.meta?.message);
    } finally {
      setIsLoading(false);
    }
  };
  return [{ doSubmit, isLoading, isError, data, isSuccess }];
};

const useGetJadwalTender = () => {
  const url = getEnv('tender')+ "/tender-jadwal";
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);

  const doGetJadwal = async (id:any) => {
    //return promise
    setIsLoading(true);
    setIsError(false);
    setIsSuccess(false);
    setData({});
    try {
      const response = await axios.get(url,{
        params: {
          'filter[proposal_tender_id]': id
        }
      });
      if (response.status === 200 || response.status === 201) {
        setIsSuccess(true);
        setData(response.data);
      } else {
        setIsError(true);
      }
    } catch (error) {
      setIsError(true);
      // @ts-ignore
    } finally {
      setIsLoading(false);
    }
  };
  return [{ doGetJadwal, isLoading, isError, data, isSuccess }];
};

const usePostDisposisi = () => {
  const url = getEnv('tender') + "/purchase-requisition-item-tender/disposition";
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);

  const doSubmit = async (values: any, _toast: any, afterStore: Function) => {
    //return promise
    setIsLoading(true);
    setIsError(false);
    setIsSuccess(false);
    setData({});
    try {
      const response = await axios.post(url, values);
      if (response.status === 200 || response.status === 201) {
        setIsSuccess(true);
        setData(response.data);
        _toast.success(response.data.meta.message);
        afterStore()
      } else {
        setIsError(true);
        _toast.error("Gagal Melakukan Disposisi");
      }
    } catch (error) {
      setIsError(true);
      // @ts-ignore
      _toast.error(error?.response?.data?.meta?.message);
    } finally {
      setIsLoading(false);
    }
  };
  return [{ doSubmit, isLoading, isError, data, isSuccess }];
};

const usePostPanitiaPengadaan = () => {
  const url = getEnv('tender') + "/tender-panitia";
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);

  const doSubmit = async (values: any, _toast: any, afterStore: Function) => {
    //return promise
    setIsLoading(true);
    setIsError(false);
    setIsSuccess(false);
    setData({});
    try {
      const response = await axios.post(url, values);
      if (response.status === 200 || response.status === 201) {
        setIsSuccess(true);
        setData(response.data);
        _toast.success(response.data.meta.message);
        afterStore()
      } else {
        setIsError(true);
        _toast.error("Gagal Melakukan Disposisi");
      }
    } catch (error) {
      setIsError(true);
      // @ts-ignore
      _toast.error(error?.response?.data?.meta?.message);
    } finally {
      setIsLoading(false);
    }
  };
  return [{ doSubmit, isLoading, isError, data, isSuccess }];
};

const usePutDraftTender = (uuid:String) => {
  const url = getEnv('tender') + "/tender/"+uuid;
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);

  const doSubmit = async (values: any, _toast?: any, afterStore?: Function) => {
    //return promise
    setIsLoading(true);
    setIsError(false);
    setIsSuccess(false);
    setData({});
    try {
      const response = await axios.put(url, values);
      if (response.status === 200 || response.status === 201) {
        setIsSuccess(true);
        setData(response.data);
        _toast && _toast.success(response.data.meta.message);
        afterStore && afterStore()
      } else {
        setIsError(true);
        _toast.error("Gagal Melakukan Disposisi");
      }
    } catch (error) {
      setIsError(true);
      // @ts-ignore
      _toast.error(error?.response?.data?.meta?.message);
    } finally {
      setIsLoading(false);
    }
  };
  return [{ doSubmit, isLoading, isError, data, isSuccess }];
};

const useDeleteDraftTender = (uuid:String) => {
  const url = getEnv('tender') + "/tender/cancel/"+uuid;
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);

  const doSubmit = async (_toast?: any, afterStore?: Function) => {
    return new Promise(async (resolve, reject) => {
      setIsLoading(true);
      setIsError(false);
      setIsSuccess(false);
      setData({});
      try {
        const response = await axios.post(url);
        if (response.status === 200 || response.status === 201) {
          setIsSuccess(true);
          setData(response.data);
          _toast && _toast.success(response.data.meta.message);
          afterStore && afterStore()
          resolve(response.data);
        } else {
          setIsError(true);
          _toast.error("Gagal Melakukan cancel draft");
          reject(new Error("Gagal Melakukan Cancel Draft"));
        }
      } catch (error) {
        setIsError(true);
        // @ts-ignore
        _toast.error(error?.response?.data?.meta?.message);
        reject(error);
      } finally {
        setIsLoading(false);
      }

    })
    //return promise
  };
  return [{ doSubmit, isLoading, isError, data, isSuccess }];
};

const useGetApprovalSchema = () => {
  const url = getEnv('tender') + "/dur/routing-approval-code";
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);

  const doGetDetail = async (uuid:string, toast?: any) => {

      return new Promise(async (resolve, reject) => {
        if(uuid === undefined) {
          resolve("")
          return ;
        }
        setIsLoading(true);
        setIsError(false);
        setIsSuccess(false);
        setData({});
        try {
          const response = await axios.get(url + "/" + uuid);
          if (response.status === 200 || response.status === 201) {
            setIsSuccess(true);
            setData(response.data.data);
            resolve(response.data.data);
          } else {
            setIsError(true);
            toast && toast.error("Gagal Ambil Detail Proposal Tender");
            reject(response);
          }
        } catch (error) {
          setIsError(true);
          // @ts-ignore
          toast && toast.error(error?.response?.data?.meta?.message);
          reject(error);
        } finally {
          setIsLoading(false);
        }
      });

  };
  return [{ doGetDetail, isLoading, isError, data, isSuccess }];
}

const usePostPersyaratan = (isPQ=false) => {
  const url = getEnv('tender') + (isPQ ? "/pq-syarat" : "/tender-syarat");
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);

  const doSubmit = async (values: any, _toast: any, afterStore: Function) => {
    //return promise
    setIsLoading(true);
    setIsError(false);
    setIsSuccess(false);
    setData({});
    try {
      const response = await axios.post(url, values);
      if (response.status === 200 || response.status === 201) {
        setIsSuccess(true);
        setData(response.data);
        _toast.success(response.data.meta.message);
        afterStore()
      } else {
        setIsError(true);
        _toast.error("Gagal Melakukan Disposisi");
      }
    } catch (error) {
      setIsError(true);
      // @ts-ignore
      _toast.error(error?.response?.data?.meta?.message);
    } finally {
      setIsLoading(false);
    }
  };
  return [{ doSubmit, isLoading, isError, data, isSuccess }];
};

const usePostPRAttachment = () => {
  const url = getEnv('tender') + "/purchase-requisition-attachment";
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);

  const doSubmit = async (values: any, _toast: any, afterStore: Function) => {
    //return promise
    setIsLoading(true);
    setIsError(false);
    setIsSuccess(false);
    setData({});
    try {
      const response = await axios.post(url, values);
      if (response.status === 200 || response.status === 201) {
        setIsSuccess(true);
        setData(response.data);
        _toast.success(response.data.meta.message);
        afterStore()
      } else {
        setIsError(true);
        _toast.error("Gagal Upload Attachment");
      }
    } catch (error) {
      setIsError(true);
      // @ts-ignore
      _toast.error(error?.response?.data?.meta?.message);
    } finally {
      setIsLoading(false);
    }
  };
  return [{ doSubmit, isLoading, isError, data, isSuccess }];
};

const useSyncVendorSAP = () => {
  const url = getEnv('tender') + "/dur-vendor";
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);

  const doSubmit = async (uuid: any, _toast?: any, afterStore?: Function) => {
    //return promise
    setIsLoading(true);
    setIsError(false);
    setIsSuccess(false);
    setData({});
    try {
      const response = await axios.post(url, {
        proposal_tender_id: uuid
      });
      if (response.status === 200 || response.status === 201) {
        setIsSuccess(true);
        setData(response.data);
        _toast && _toast.success(response.data.meta.message);
        afterStore && afterStore()
      } else {
        setIsError(true);
        _toast.error("Gagal Melakukan Disposisi");
      }
    } catch (error) {
      setIsError(true);
      // @ts-ignore
      _toast.error(error?.response?.data?.meta?.message);
    } finally {
      setIsLoading(false);
    }
  };
  return [{ doSubmit, isLoading, isError, data, isSuccess }];
};

const useSubmitTender = (uuid: string) => {
  const url = getEnv('tender') + "/dur/submit/" + uuid;
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);

  const doSubmit = async (values: any, _toast?: any, afterStore?: Function) => {
    return new Promise(async (resolve, reject) => {
      setIsLoading(true);
      setIsError(false);
      setIsSuccess(false);
      setData({});

      try {
        const response = await axios.post(url, values);
        if (response.status === 200 || response.status === 201) {
          _toast && _toast.success(response.data.meta.message);
          afterStore && afterStore();
          setIsSuccess(true);
          resolve(response.data);
        } else {
          _toast && _toast.error("Gagal Mensubmit PQ");
          setIsError(true);
          reject(new Error("Gagal Melakukan Disposisi"));
        }
      } catch (error) {
        // @ts-ignore
        _toast && _toast.error(error?.response?.data?.meta?.message);
        setIsError(true);
        reject(error);
      } finally {
        setIsLoading(false);
      }
    });
  };

  return [{ doSubmit, isLoading, isError, data, isSuccess }];
};

const useDeletePRAttachment = () => {
  const url = getEnv('tender') + "/purchase-requisition-attachment";
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);

  const doSubmit = async (uuid:string, _toast?: any, afterStore?: Function) => {
    return new Promise(async (resolve, reject) => {
      setIsLoading(true);
      setIsError(false);
      setIsSuccess(false);
      setData({});
      try {
        const response = await axios.delete(url+`/${uuid}`);
        if (response.status === 200 || response.status === 201) {
          setIsSuccess(true);
          setData(response.data);
          _toast && _toast.success(response.data.meta.message);
          afterStore && afterStore()
          resolve(response.data);
        } else {
          setIsError(true);
          _toast.error("Gagal Melakukan cancel draft");
          reject(new Error("Gagal Melakukan Cancel Draft"));
        }
      } catch (error) {
        setIsError(true);
        // @ts-ignore
        _toast.error(error?.response?.data?.meta?.message);
        reject(error);
      } finally {
        setIsLoading(false);
      }

    })
    //return promise
  };
  return [{ doSubmit, isLoading, isError, data, isSuccess }];
};

const usePostCreateAanwijzing = () => {
  const url = getEnv('tender') + "/aanwijzing";
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);

  const doSubmit = async (values: any, _toast?: any, afterStore?: Function) => {
    return new Promise(async (resolve, reject) => {
      setIsLoading(true);
      setIsError(false);
      setIsSuccess(false);
      setData({});

      try {
        const response = await axios.post(url, values);
        if (response.status === 200 || response.status === 201) {
          _toast && _toast.success(response.data.meta.message);
          afterStore && afterStore(response?.data);
          setIsSuccess(true);
          resolve(response.data);
        } else {
          _toast && _toast.error("Gagal Mensubmit PQ");
          setIsError(true);
          reject(new Error("Gagal Melakukan Disposisi"));
        }
      } catch (error) {
        // @ts-ignore
        _toast && _toast.error(error?.response?.data?.meta?.message);
        setIsError(true);
        reject(error);
      } finally {
        setIsLoading(false);
      }
    });
  };

  return [{ doSubmit, isLoading, isError, data, isSuccess }];
};

const useCancelAaanwijzing = (uuid:String) => {
  const url = getEnv('tender') + "/aanwijzing/cancel/"+uuid;
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);

  const doSubmit = async (_toast?: any, afterStore?: Function) => {
    return new Promise(async (resolve, reject) => {
      setIsLoading(true);
      setIsError(false);
      setIsSuccess(false);
      setData({});
      try {
        const response = await axios.post(url);
        if (response.status === 200 || response.status === 201) {
          setIsSuccess(true);
          setData(response.data);
          _toast && _toast.success(response.data.meta.message);
          afterStore && afterStore()
          resolve(response.data);
        } else {
          setIsError(true);
          _toast.error("Gagal Melakukan cancel draft");
          reject(new Error("Gagal Melakukan Cancel Draft"));
        }
      } catch (error) {
        setIsError(true);
        // @ts-ignore
        _toast.error(error?.response?.data?.meta?.message);
        reject(error);
      } finally {
        setIsLoading(false);
      }

    })
    //return promise
  };
  return [{ doSubmit, isLoading, isError, data, isSuccess }];
};

const usePutDraftAanwijzing = (uuid:String) => {
  const url = getEnv('tender') + "/aanwijzing/"+uuid;
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);

  const doSubmit = async (values: any, _toast?: any, afterStore?: Function) => {
    //return promise
    setIsLoading(true);
    setIsError(false);
    setIsSuccess(false);
    setData({});
    try {
      const response = await axios.put(url, values);
      if (response.status === 200 || response.status === 201) {
        setIsSuccess(true);
        setData(response.data);
        _toast && _toast.success(response.data.meta.message);
        afterStore && afterStore()
      } else {
        setIsError(true);
        _toast.error("Gagal Melakukan Disposisi");
      }
    } catch (error) {
      setIsError(true);
      // @ts-ignore
      _toast.error(error?.response?.data?.meta?.message);
    } finally {
      setIsLoading(false);
    }
  };
  return [{ doSubmit, isLoading, isError, data, isSuccess }];
};

const useSaveSummary = () => {
  const url = getEnv('tender') + "/aanwijzing-summary";
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);

  const doSubmit = async (payload: any, _toast?: any, afterStore?: Function) => {
    return new Promise(async (resolve, reject) => {
      setIsLoading(true);
      setIsError(false);
      setIsSuccess(false);
      setData({});
      try {
        const response = await axios.post(url, payload);
        if (response.status === 200 || response.status === 201) {
          setIsSuccess(true);
          setData(response.data);
          _toast && _toast.success(response.data.meta.message);
          afterStore && afterStore()
          resolve(response.data);
        } else {
          setIsError(true);
          _toast.error("Gagal Melakukan save summary");
          reject(new Error("Gagal Melakukan save summary"));
        }
      } catch (error) {
        setIsError(true);
        // @ts-ignore
        _toast.error(error?.response?.data?.meta?.message);
        reject(error);
      } finally {
        setIsLoading(false);
      }

    })
    //return promise
  };
  return [{ doSubmit, isLoading, isError, data, isSuccess }];
};

const useSaveForum = () => {
  const url = getEnv('tender') + "/aanwijzing-forum";
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);

  const doSubmit = async (payload: any, _toast?: any, afterStore?: Function) => {
    return new Promise(async (resolve, reject) => {
      setIsLoading(true);
      setIsError(false);
      setIsSuccess(false);
      setData({});
      try {
        const response = await axios.post(url, payload);
        if (response.status === 200 || response.status === 201) {
          setIsSuccess(true);
          setData(response.data);
          _toast && _toast.success(response.data.meta.message);
          afterStore && afterStore()
          resolve(response.data);
        } else {
          setIsError(true);
          _toast.error("Gagal Melakukan save summary");
          reject(new Error("Gagal Melakukan save summary"));
        }
      } catch (error) {
        setIsError(true);
        // @ts-ignore
        _toast.error(error?.response?.data?.meta?.message);
        reject(error);
      } finally {
        setIsLoading(false);
      }

    })
    //return promise
  };
  return [{ doSubmit, isLoading, isError, data, isSuccess }];
};

const useGetForum = (id:any) => {
  const url = getEnv('tender') + "/aanwijzing-forum";
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);

  const doGetDetail = async (_toast:any, afterLoad?: Function) => {
    //return promise
    setIsLoading(true);
    setIsError(false);
    setIsSuccess(false);
    setData([]);
    try {
      const response = await axios.get(url,{
        params: {
          'filter[aanwijzing_id]': id
        }
      });
      if (response.status === 200 || response.status === 201) {
        setIsSuccess(true);
        setData(response.data.data);
        afterLoad && afterLoad(response.data.data)
      } else {
        setIsError(true);
        _toast.error("Gagal Ambil Detail Proposal Tender");
      }
    } catch (error) {
      setIsError(true);
      // @ts-ignore
      _toast.error(error?.response?.data?.meta?.message);
    } finally {
      setIsLoading(false);
    }
  };
  return [{ doGetDetail, isLoading, isError, data, isSuccess }];
}

const useDeleteForum = () => {
  const url = getEnv('tender') + "/aanwijzing-forum";
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);

  const doSubmit = async (uuid:string, _toast?: any, afterStore?: Function) => {
    return new Promise(async (resolve, reject) => {
      setIsLoading(true);
      setIsError(false);
      setIsSuccess(false);
      setData({});
      try {
        const response = await axios.delete(url+`/${uuid}`);
        if (response.status === 200 || response.status === 201) {
          setIsSuccess(true);
          setData(response.data);
          _toast && _toast.success(response.data.meta.message);
          afterStore && afterStore()
          resolve(response.data);
        } else {
          setIsError(true);
          _toast.error("Gagal Melakukan cancel draft");
          reject(new Error("Gagal Melakukan Cancel Draft"));
        }
      } catch (error) {
        setIsError(true);
        // @ts-ignore
        _toast.error(error?.response?.data?.meta?.message);
        reject(error);
      } finally {
        setIsLoading(false);
      }

    })
    //return promise
  };
  return [{ doSubmit, isLoading, isError, data, isSuccess }];
};

const useGetApprovalAanwijzingSchema = () => {
  const url = getEnv('tender') + "/aanwijzing/routing-approval-code";
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);

  const doGetDetail = async (uuid:string, toast?: any) => {

    return new Promise(async (resolve, reject) => {
      if(uuid === undefined) {
        resolve("")
        return ;
      }
      setIsLoading(true);
      setIsError(false);
      setIsSuccess(false);
      setData({});
      try {
        const response = await axios.get(url + "/" + uuid);
        if (response.status === 200 || response.status === 201) {
          setIsSuccess(true);
          setData(response.data.data);
          resolve(response.data.data);
        } else {
          setIsError(true);
          toast && toast.error("Gagal Ambil Detail Proposal Tender");
          reject(response);
        }
      } catch (error) {
        setIsError(true);
        // @ts-ignore
        toast.error(error?.response?.data?.meta?.message);
        reject(error);
      } finally {
        setIsLoading(false);
      }
    });

  };
  return [{ doGetDetail, isLoading, isError, data, isSuccess }];
}

const useSubmitAanwijzing = (uuid: string) => {
  const url = getEnv('tender') + "/aanwijzing/submit/" + uuid;
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);

  const doSubmit = async (values: any, _toast?: any, afterStore?: Function) => {
    return new Promise(async (resolve, reject) => {
      setIsLoading(true);
      setIsError(false);
      setIsSuccess(false);
      setData({});

      try {
        const response = await axios.post(url, values);
        if (response.status === 200 || response.status === 201) {
          _toast && _toast.success(response.data.meta.message);
          afterStore && afterStore();
          setIsSuccess(true);
          resolve(response.data);
        } else {
          _toast && _toast.error("Gagal Mensubmit PQ");
          setIsError(true);
          reject(new Error("Gagal Melakukan Disposisi"));
        }
      } catch (error) {
        // @ts-ignore
        _toast && _toast.error(error?.response?.data?.meta?.message);
        setIsError(true);
        reject(error);
      } finally {
        setIsLoading(false);
      }
    });
  };

  return [{ doSubmit, isLoading, isError, data, isSuccess }];
};

const useSubmitDraft = (uuid: string) => {
  const url = getEnv('tender') + "/dur/submit/" + uuid;
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);

  const doSubmit = async (values: any, _toast?: any, afterStore?: Function) => {
    return new Promise(async (resolve, reject) => {
      setIsLoading(true);
      setIsError(false);
      setIsSuccess(false);
      setData({});

      try {
        const response = await axios.post(url, values);
        if (response.status === 200 || response.status === 201) {
          _toast && _toast.success(response.data.meta.message);
          afterStore && afterStore();
          setIsSuccess(true);
          resolve(response.data);
        } else {
          _toast && _toast.error("Gagal Mensubmit Proposal Tender");
          setIsError(true);
          reject(new Error("Gagal Melakukan Submit Proposal Tender"));
        }
      } catch (error) {
        // @ts-ignore
        _toast && _toast.error(error?.response?.data?.meta?.message);
        setIsError(true);
        reject(error);
      } finally {
        setIsLoading(false);
      }
    });
  };

  return [{ doSubmit, isLoading, isError, data, isSuccess }];
};




export {
  useSyncPR,
  usePostingPRToAvailable,
  useCreateProposalTender,
  useGetDetailProposalTender,
  usePostJadwalTender,
  useGetJadwalTender,
  usePostDisposisi,
  usePostPanitiaPengadaan,
  usePutDraftTender,
  useGetApprovalSchema,
  usePostPersyaratan,
  usePostPRAttachment,
  useDeleteDraftTender,
  useSyncVendorSAP,
  useSubmitTender,
  useDeletePRAttachment,
  usePostCreateAanwijzing,
  useGetDetailAanwijzing,
  useCancelAaanwijzing,
  usePutDraftAanwijzing,
  useSaveSummary,
  useSaveForum,
  useGetForum,
  useDeleteForum,
  useGetApprovalAanwijzingSchema,
  useSubmitAanwijzing,
  useSubmitDraft
};