import { getEnv } from "../../../../../../helpers/procurex";
import TableXWrapper from "../../../../../components/shared/TableXWrapper";
import { useIntl } from "react-intl";

const BidOpeningPage = ({ proposalTender, onRefresh }: any) => {
  const intl = useIntl();
  const urlIndex = getEnv("tender") + "/dur-vendor?filter[proposal_tender_id]=" + proposalTender.id+"&filter[status_tender]=INVITED,REGISTRATION-QUOTATION,REJECT-INVITATION,REGISTRATION,REJECT-QUOTATION,NEGOTIATION,NEGOTIATION_PROCESS,WINNER";
  const tableColumns = [
    { Header: intl.formatMessage({id:"ID Vendor"}), accessor: "vendor_id", width: 200, className: "text-start", Cell: (props: any) => {
        return (
          <>
            {props.row.original.vendor_id}
          </>
        )
      }
    },
    { Header: intl.formatMessage({id:"Nama Vendor"}), accessor: "vendor.name", width: 400, className: "text-start"}
  ];

  return (
    <>
      <div className={"card"}>
        <div className={"card-body"}>
          <div className={"d-flex justify-content-between"}>
            <div><h3>Bid Opening</h3></div>
          </div>
          <hr />
          <TableXWrapper
            readonly={true}
            title={"Proposal Tender"}
            urlIndex={urlIndex}
            columns={tableColumns}
            isForceCreateButton={true}
            disableUrlChanged={true}
            disablePaddingCard={true}
          />
        </div>
      </div>
    </>
  );
};

export default BidOpeningPage;