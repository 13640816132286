// @ts-ignore

import React, { useEffect, useMemo, useState } from "react";
import { Nav, Placeholder } from "rsuite";
import { useAxiosGet, useAxiosPost } from "../../../../../../../hooks/useAxios";
import {formatNumberToDecimal, getEnv, parseNumberToCurrency} from '../../../../../../../helpers/procurex'
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import FormikSelect2 from "../../../../../../components/shared/form/FormikSelect2";
import { Form, Formik } from "formik";
import * as Yup from 'yup'
import CurrencyInput from "react-currency-input-field";
import { BeritaAcaraAwardingTab } from "./BeritaAcaraAwardingTab";
import FlagRejected from "../FlagRejected";
import { useIntl } from "react-intl";
import axios from 'axios'
import { ApprovalX } from "../../../../../../components/shared/ApprovalX";
import BottomToolbarWrapper from '../../../../../../components/shared/BottomToolbarWrapper'
import {useMutation} from 'react-query'
import { useNavigate } from "react-router-dom";

const TrItemDetail = ({ proposalTender, item, vendor, onCheckAwarding, formik, checkIsEnableAwarding }: any) => {
  const intl = useIntl();
  const [isChecked, setIsChecked] = useState(false)

  const handleOnCheckAwarding = (e:any) => {
    setIsChecked(e.target.checked)
    axios.post(getEnv('tender')+`/awarding-reporting/awarding/${proposalTender?.uuid}`,{
      proposal_tender_vendor_id: vendor?.vendor_uuid,
      awarding: e.target.checked,
      negotiation_items: [
        vendor?.negotiation_item_id
      ]
    })
    onCheckAwarding("check",vendor?.negotiation_item_id, vendor?.vendor_uuid, e.target.checked);
  }

  const debounce = (func, delay) => {
    let debounceTimer;
    return function() {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    }
  }



  const handleOnChangeFinalQuantity = (value:any) => {
    // Set value
    // @ts-ignore
    formik?.current?.setFieldValue(`item.${vendor?.vendor_uuid}.${vendor?.negotiation_item_id}.qty`, value)

    //validate with current vendor final quantity
    const currentValue = parseFloat(value || 0);
    const itemValue = parseFloat(vendor?.final_bid_price?.quantity || 0);
    const price = parseFloat(vendor?.final_bid_price?.net_price || 0)
    // if (currentValue > itemValue) {
    //   formik?.current?.setFieldError(`item.${vendor?.vendor_uuid}.${vendor?.negotiation_item_id}.qty`, "Invalid Qty");
    //   return ;
    // }
    formik?.current?.setFieldError(`item.${vendor?.vendor_uuid}.${vendor?.negotiation_item_id}.qty`, null);
    formik?.current?.setFieldTouched(`item.${vendor?.vendor_uuid}.${vendor?.negotiation_item_id}.qty`, true);


    // @ts-ignore
    const debounceAutoSave = debounce(()=>{
      const payload = {
        proposal_tender_vendor_id: vendor?.vendor_uuid,
        negotiation_item_id: vendor?.negotiation_item_id,
        quantity: formatNumberToDecimal((value).replace(",","."))
      }
      axios.post(getEnv('tender') + "/awarding-reporting/quantity-winner/"+proposalTender?.uuid,payload)
    },1000)

    debounceAutoSave()
    formik?.current?.setFieldValue(`item.${vendor?.vendor_uuid}.${vendor?.negotiation_item_id}.subtotal`, price * currentValue)
  }

  useEffect(() => {
    setIsChecked(vendor?.awarding)
  },[])

  return (
    <>
      <tr>
        <td className={"border px-2 text-center"}>{item?.line_no}</td>
        <td className={"border px-2 text-center"}>{item?.material}</td>
        <td className={"border px-2 text-center"}>{item?.plant}</td>
        <td className={"border px-2 text-start"}>
          <div><strong><small>{vendor?.vendor_id}</small></strong></div>
          <div><small>{vendor?.vendor}</small></div>
        </td>
        <td className={"border px-2 text-center"}></td>
        <td className={"border px-2 text-center"}>
          <input
            disabled={!checkIsEnableAwarding()}
            type={"checkbox"}
            className={"form-check-input"}
            onClick={(e: any) => {
              handleOnCheckAwarding(e);
            }}
            defaultChecked={vendor?.awarding}
          />
        </td>
        <td className={"border px-2 text-center"}>{vendor?.rank}</td>
        <td className={"border px-2 text-end bg-light-primary"}>
          {parseNumberToCurrency(vendor?.initial_bid_price?.price,proposalTender?.items[0]?.currency_id)}
        </td>
        <td className={"border px-2 text-end bg-light-primary"}>
          {vendor?.initial_bid_price?.quantity}

        </td>
        <td className={"border px-2 text-end bg-light-primary"}>
          {parseNumberToCurrency(vendor?.initial_bid_price?.net_price,proposalTender?.items[0]?.currency_id)}
        </td>
        <td className={"border px-2 text-end bg-light-primary"}>
          {parseNumberToCurrency(vendor?.initial_bid_price?.additional_cost,proposalTender?.items[0]?.currency_id)}
        </td>
        <td className={"border px-2 text-end bg-light-primary"}>
          <strong>{parseNumberToCurrency(vendor?.initial_bid_price?.sub_total,proposalTender?.items[0]?.currency_id)}</strong>
        </td>
        <td className={"border px-2 text-end bg-light-success"}>
          {parseNumberToCurrency(vendor?.final_bid_price?.price,proposalTender?.items[0]?.currency_id)}
        </td>
        <td className={"border px-2 text-end bg-light-success"}>
          {/*{vendor?.final_bid_price?.quantity}*/}
          {(vendor?.final_bid_price?.quantity)}
        </td>
        <td className={"border px-2 text-end bg-light-success"}>
          {parseNumberToCurrency(vendor?.final_bid_price?.net_price,proposalTender?.items[0]?.currency_id)}
        </td>
        <td className={"border px-2 text-end bg-light-success"}>
          {parseNumberToCurrency(vendor?.final_bid_price?.additional_cost,proposalTender?.items[0]?.currency_id)}
        </td>
        <td className={"border px-2 text-end bg-light-success"}>
          <strong>{parseNumberToCurrency(vendor?.final_bid_price?.sub_total,proposalTender?.items[0]?.currency_id)}</strong>
        </td>
        <td className={"bg-light-danger"} style={{
          position: "sticky", right: 200, zIndex: 1, backgroundColor: "#fff", width: 200
        }}>
          <CurrencyInput
            className="form-control form-control-sm text-end"
            groupSeparator="."
            decimalSeparator=","
            decimalsLimit={2}
            defaultValue={
              // formik?.current?.values[`item.${vendor?.vendor_uuid}.${vendor?.negotiation_item_id}.qty`]
              (vendor?.final_bid_price?.quantity)
            }
            onValueChange={(value)=>handleOnChangeFinalQuantity(value)}
            style={{
              minWidth: "100px"
            }}
            disabled={!isChecked}
          />
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              {formik?.current?.errors['item']?.[vendor?.vendor_uuid]?.[vendor?.negotiation_item_id]?.['qty']}
            </div>
          </div>
        </td>
        <td className={"border px-2 text-end bg-light-success"} style={{
          position: "sticky", right: 0, zIndex: 1, backgroundColor: "#fff", width: 200
        }}>
          <strong>{parseNumberToCurrency(formik?.current?.values[`item.${vendor?.vendor_uuid}.${vendor?.negotiation_item_id}.subtotal`],proposalTender?.items[0]?.currency_id)}</strong>
          {/*{JSON.stringify(formik?.current?.values)}*/}
        </td>
      </tr>
    </>
  );
};

const TrItem = ({ proposalTender, item, onCheckAwarding, formik, checkIsEnableAwarding }: any) => {
  const [vendors,setVendors] = useState(item?.vendors)

  const handleOnCheckAwarding = async(field:string, item_uuid: string, vendor_uuid: string, checked: boolean) => {
    onCheckAwarding(field,item_uuid, vendor_uuid, checked);
    let formVendors = formik?.current?.values?.vendors || {};
    if (!Object.keys(formVendors).includes(vendor_uuid)) {
      formVendors[vendor_uuid] = []
    }
    if (checked) {
      formVendors[vendor_uuid].push(item_uuid)
    } else {
      formVendors[vendor_uuid] = formVendors[vendor_uuid].filter((item: any) => item !== item_uuid)
    }
    formik?.current?.setFieldValue("vendors", formVendors)
  }
  return (
    <>
      <tr>
        <td className={"border px-2"} rowSpan={item?.vendors?.length + 1} style={{
          position: "sticky", left: 0, zIndex: 1, backgroundColor: "#fff", minWidth: 200
        }}>
          {item?.description}
        </td>
        <td className={"border px-2 text-center"} rowSpan={item?.vendors?.length + 1} style={{
          // position: "sticky", left: 0, zIndex: 1, backgroundColor: "#fff", minWidth: 100
        }}>
          {item?.no_pr}
        </td>
      </tr>
      {vendors?.map((vendor: any, index: number) => {
        return (
          <TrItemDetail
            proposalTender={proposalTender}
            vendor={vendor}
            item={item}
            key={"tr-item-detail-" + index}
            onCheckAwarding={handleOnCheckAwarding}
            formik={formik}
            checkIsEnableAwarding={checkIsEnableAwarding}
          />
        );
      })}
    </>
  );
};

const TrItemPaketDetail = ({ proposalTender, item, vendor, onCheckAwarding }: any) => {
  return (
    <>
      <tr>
        <td className={"border px-2"}></td>
        <td className={"text-start border px-2"} style={{
          minWidth: 200
        }}>{item?.description}</td>
        <td className={"text-start border px-2"}>{item?.no_pr}</td>
        <td className={"text-start border px-2"}>{item?.line_no}</td>
        <td className={"text-start border px-2"}>{item?.material}</td>
        <td className={"text-start border px-2"}>{item?.plant}</td>
        <td className={"text-center border px-2"}>{item?.award && <i className="fa fa-check fa-check-square text-success fs-2"></i>}</td>
        <td className={"text-center border px-2"}>{item?.rank}</td>
        <td className={"text-end border px-2"}>
          {parseNumberToCurrency(item?.initial_bid_price?.sub_total,proposalTender?.items[0]?.currency_id)}
        </td>
        <td className={"text-end border px-2"}>
          {parseNumberToCurrency(item?.final_bid_price?.sub_total,proposalTender?.items[0]?.currency_id)}
        </td>
      </tr>
    </>
  )
}

const TrItemPaket = ({ proposalTender, item, onCheckPaket, checkIsEnableAwarding }: any) => {
  const isChecked = () => {
    const exists = item?.items?.filter((item:any) => item?.award === true).length === item?.items?.length;
    if(exists) {
      // onCheckPaket(item, true)
      return true
    }
    // onCheckPaket(item, false)
    return false
  }
  return (
    <>
      <tr>
        <td className={"text-center border px-2"}>
          <div className={"form-check"}>
            <input
              disabled={!checkIsEnableAwarding()}
              type={"checkbox"}
              className={"form-check-input"}
              defaultChecked={isChecked()}
              onChange={(e:any)=>onCheckPaket(item, e.target.checked)}
            />
          </div>
        </td>
        <td className={"text-start border px-2"} colSpan={10}>
          <strong>{item?.vendor_id}</strong> - {item?.vendor_name}
        </td>
      </tr>
      {item?.items?.map((item: any, index: number) => {
        return (
          <TrItemPaketDetail
            item={item}
            key={"tr-item-detail-" + index}
            proposalTender={proposalTender}
            // onCheckAwarding={onCheckAwarding}
          />
        );
      })}
    </>
  )
}

const TableAwardingPaket = ({ proposalTender, items, onCheckPaket, checkIsEnableAwarding, onRefresh }: any) => {
  const [isLoading,setIsLoading] = useState(false)
  const [currentItems,setCurrentItems] = useState([])
  const [awardingData,setAwardingData] = useState({})
  const [currentWinner,setCurrentWinner] = useState({})

  const isFormEnabled = () => {
    const allowedStatus = [
      "Laporan Pengadaan"
    ];
    const allowedStatusDetail = [
      "Awarding",
      "Rejected"
    ];
    return allowedStatus.includes(proposalTender?.status) &&
      allowedStatusDetail.includes(proposalTender?.status_detail);
  };

  const handleOnCheck = (vendor:any, state:boolean) => {
    // @ts-ignore
    if(currentWinner?.proposal_tender_vendor_id) {
      // @ts-ignore
      if(currentWinner?.proposal_tender_vendor_id === vendor?.uuid){
        Swal.fire({
          title: "Apakah anda yakin?",
          text: "Anda akan me-revoke vendor ini dari WINNER?",
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Ya, lanjutkan',
          cancelButtonText: 'Tidak',
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            //revoke winner
            mutate({
              payload: currentWinner
            })
          } else {
            onRefresh && onRefresh()
          }
        })
        return ;
      }

      Swal.fire(
        'Whoopss, Tender ini telah memiliki pemenang, silahkan revoke WINNER terlebih dahulu',
        "",
        "error"
      );
      onRefresh && onRefresh()
      return ;
    }

    const payload = {
      proposal_tender_vendor_id: vendor?.uuid,
      awarding: state,
      negotiation_items: vendor?.items?.map((v:any)=>v.uuid)
    }
    setAwardingData(payload)
  }


  const {mutate,isLoading: isLoadingSave} = useMutation({
    mutationKey: ["awarding","save",proposalTender?.uuid],
    mutationFn: ({payload}:any) => axios.post(
      getEnv('tender')+"/awarding-reporting/awarding/"+proposalTender?.uuid,
      payload
    ),
    onSuccess: (res:any)=> {
      toast.success("Successfully save awarding")
      onRefresh && onRefresh()
    }, onError:(err:any) => {
      toast.error(err?.response?.data?.meta?.message)
    }
  })

  const handleOnSaveAwarding = () => {
    mutate({
      payload: awardingData
    })
  }

  useEffect(() => {
    setCurrentItems(items?.filter((i:any)=>{
      if (i.items?.length === 0){
        return ;
      }
      return i;
    }))
  }, [proposalTender,items])

  useEffect(() => {
    currentItems?.map((v:any)=>{
      v?.items?.map((i:any)=>{
        if(i?.award){
          const payload = {
            proposal_tender_vendor_id: v?.uuid,
            awarding: false,
            negotiation_items: [
              i?.uuid
            ]
          }
          setCurrentWinner(payload)
        }
      })
    })
  }, [currentItems])

  return (
    <>
      <div className={'table-responsive'}>
        <table className={'table table-header-gray'}>
          <thead>
          <tr>
            <th className={'border px-2'}>#</th>
            <th className={'border px-2'}>Deskripsi</th>
            <th className={'border px-2 text-center text-nowrap'} rowSpan={2}>Nomor PR</th>
            <th className={'border px-2 text-center text-nowrap'} rowSpan={2}>PR Item</th>
            <th className={'border px-2 text-center text-nowrap'} rowSpan={2}>Material Code</th>
            <th className={'border px-2 text-center text-nowrap'} rowSpan={2}>Plant</th>
            <th className={'border px-2 text-center'} rowSpan={2}>Award</th>
            <th className={'border px-2 text-center'} rowSpan={2}>Rank</th>
            <th className={'border px-2 text-nowrap text-center bg-light-info'} colSpan={1}>Harga Penawaran Awal</th>
            <th className={'border px-2 text-nowrap text-center bg-light-success'} colSpan={1}>Harga Penawaran Akhir
            </th>
          </tr>
          </thead>
          <tbody>
          {items.length === 0 && (
            <tr>
              <td colSpan={12} className={'border px-2 text-center'}>Tidak ada data</td>
            </tr>
          )}
          {currentItems?.map((item: any, index: number) => {
            return (
              <TrItemPaket
                item={item} key={'proses-awarding-' + index}
                onCheckPaket={handleOnCheck}
                proposalTender={proposalTender}
                checkIsEnableAwarding={checkIsEnableAwarding}
              />
            )
          })}
          </tbody>
        </table>


      </div>
      <div className={'d-flex flex-row justify-content-end align-items-end mt-3'}>
        <button
          className={'btn btn-primary'}
          type={'button'}
          onClick={() => handleOnSaveAwarding()}
          disabled={!isFormEnabled()}
        >
          {isLoading && (<i className={'fa fa-spinner fa-spin me-2'}></i>)}
          {!isLoading && (<i className={'fa fa-save me-2'}></i>)}
          Save Awarding
        </button>
      </div>
    </>
  );
};

const ProsesAwardingPaket = ({proposalTender, afterSaveDraft, onRefresh}: any) => {
  const navigate = useNavigate();
  const [currentProposalTender, setCurrentProposalTender] = useState(proposalTender)
  const [dataItem, setDataItem] = React.useState<any>([])
  const [selectedItems, setSelectedItems] = React.useState<any>([])
  const [vendorItems, setVendorItems] = React.useState<any>([] as any[])
  const [{doSubmit, isLoading, isError, data, isSuccess}] = useAxiosGet(
    getEnv("tender") + `/negotiation/summary/${proposalTender?.negotiation?.uuid}?proses_awarding=true`
  );
  const [isCanSaveAwarding, setIsCanSaveAwarding] = useState(false)
  const [isCanSubmitForApproval, setIsCanSubmitForApproval] = useState(false)
  const [{
    doSubmit: doSubmitAwarding,
    isLoading: isLoadingAwarding,
    isError: isErrorAwarding,
    data: dataAwarding,
    isSuccess: isSuccessAwarding
  }] = useAxiosPost(
    getEnv("tender") + `/awarding-reporting/awarding/${proposalTender?.uuid}`
  );
  const getData = () => {
    let url = getEnv("tender") + `/negotiation/summary/${proposalTender?.negotiation?.uuid}?proses_awarding=true`;
    if(proposalTender?.order_placement !== "Paket") {
      url = getEnv("tender") + `/negotiation/summary/${proposalTender?.negotiation?.uuid}`;
    }
    doSubmit(null, url, undefined).then((res: any) => {
      const data = res?.data;
      const _selectedItems = [] as any[];
      data.map((item: any) => {
        if(item?.items?.length > 0) {
          const isItemChecked = item?.items?.filter((item: any) => item?.award === true).length > 0;
          if(isItemChecked) _selectedItems.push(item)
        }
      })
      setSelectedItems(_selectedItems)
      setDataItem(data);
    });
  };

  const handleOnCheckPaket = async (vendor:any, state:boolean) => {
    if (state) {
      setSelectedItems([...selectedItems, vendor])
    } else {
      setSelectedItems(selectedItems.filter((item: any) => item.uuid !== vendor.uuid))
    }
  }

  const getProposalTender = () => {
    const newUrl = getEnv("tender") + `/tender/${proposalTender?.uuid}`
    doSubmit({}, newUrl, undefined, () => {}).then((res: any) => {
      const _proposalTender = res.data;
      setCurrentProposalTender(_proposalTender)
    }).catch((err:any)=>{
      toast.error("Get Evaluasi Teknis: "+err?.response?.data?.meta?.message)
    });
  }

  const isFormEnabled = () => {
    const allowedStatus = [
      "Laporan Pengadaan"
    ];
    const allowedStatusDetail = [
      "Awarding",
      "Rejected"
    ];
    return allowedStatus.includes(currentProposalTender?.status) &&
      allowedStatusDetail.includes(currentProposalTender?.status_detail);
  };

  const checkIsEnableAwarding = () => {
    const allowedStatus = [
      "Laporan Pengadaan"
    ];
    const allowedStatusDetail = [
      "Awarding",
      "Rejected"
    ];
    return allowedStatus.includes(proposalTender?.status) && allowedStatusDetail.includes(proposalTender?.status_detail);
  }

  const isShowSubmitReportAwarding = () => {
    const allowedStatus = [
      "Laporan Pengadaan"
    ];
    const allowedStatusDetail = [
      "Awarding",
      "Rejected"
    ];
    return allowedStatus.includes(currentProposalTender?.status) &&
      allowedStatusDetail.includes(currentProposalTender?.status_detail);
  };

  const handleOnSaveAwarding = () => {
    Swal.fire({
      title: "Konfirmasi",
      text: "Apakah anda yakin ingin submit Awarding?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, Lanjutkan ",
      cancelButtonText: "Tidak"
    }).then(async (result) => {
      if (result.isConfirmed) {
        dataItem.map((item: any) => {
          if(item?.items?.length > 0) {
            const negotiation_items = item?.items?.map((item: any) => item?.uuid)
            const exists = selectedItems?.find((selectedItem: any) => selectedItem?.uuid === item?.uuid)
            let payload = {
              proposal_tender_vendor_id: item?.uuid,
              awarding: exists ? true : false,
              negotiation_items: negotiation_items
            };
            // const payload = {
            //   proposal_tender_vendor_id: item?.vendor_uuid,
            //   awarding: true,
            //   negotiation_items: item?.items
            // };
            // console.log(payload);

            doSubmitAwarding(payload, undefined, toast).then((res: any) => {
              getData();
              getProposalTender()
              setIsCanSaveAwarding(false);
              setIsCanSubmitForApproval(false)
              onRefresh && onRefresh()
            });
          }
        });

      }
    });

  };

  useEffect(() => {
    if (proposalTender) {
      getData();
      getProposalTender()
    }
  }, [proposalTender]);

  useEffect(() => {
    setIsCanSaveAwarding(isFormEnabled())
    setIsCanSubmitForApproval(isShowSubmitReportAwarding())
  }, [currentProposalTender])

  const [approvalSchemaSubmitAwarding, setApprovalSchemaSubmitAwarding] = React.useState([]);
  const [isShowApprovalSchmeaSubmitAwarding, setIsShowApprovalSchemaSubmitAwarding] = React.useState(false);

  const isReNegoEnabled = () => {
    const allowedStatus = [
      'Awarding',
      'Rejected',
    ]
    return allowedStatus.includes(currentProposalTender?.status_detail) && currentProposalTender?.status === "Laporan Pengadaan";
  }

  const [{ doSubmit: doSubmitPQ, isLoading: isLoadingPQ }] = useAxiosPost(getEnv("tender") + "/pq/submit");
  const [{ doSubmit: doSubmitReNego, isLoading: isLoadingReNego }] = useAxiosPost(getEnv("tender") + `/awarding-reporting/rollback/${proposalTender?.uuid}`);
  const [{ doSubmit: doSubmitGet, isLoading: isLoadingGet }] = useAxiosGet(
    getEnv("tender") + "/negotiation"
  );

  const onSubmitReportAwarding = () => {
    const newUrl = getEnv("tender") + `/awarding-reporting/routing-approval-code/${proposalTender?.uuid}`;
    doSubmitGet({}, newUrl, undefined).then((res: any) => {
      setApprovalSchemaSubmitAwarding(res.data?.details);
      setIsShowApprovalSchemaSubmitAwarding(true);
    })
    setIsShowApprovalSchemaSubmitAwarding(true);
  };

  const onSubmitReNego = () => {
    Swal.fire({
      title: "Konfirmasi",
      text: "Apakah anda yakin ingin kembali ke proses Nego?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, Lanjutkan ",
      cancelButtonText: "Tidak"
    }).then(async (result) => {
      if (result.isConfirmed) {
        doSubmitReNego({},undefined,toast).then((res:any)=> {
          window.location.reload();
        })
      }
    })
  };

  const handleOnSubmitApprovalSubmitAwarding = async (values: any) => {
    const payload = {
      approvers: values.approval.map((item: any) => {
        return {
          step: item.step,
          jabatan: item.jabatan,
          type: "APPROVAL",
          user_id: item.extra_info.user.user_id,
          extra_info: item.extra_info
        };
      })
    };
    const newUrl = getEnv("tender") + `/awarding-reporting/submit/${proposalTender?.uuid}`;
    doSubmitPQ(payload, newUrl, toast, () => {}).then((data: any) => {
      setIsShowApprovalSchemaSubmitAwarding(false);
      // afterSaveDraft && afterSaveDraft();a
      getProposalTender()
      setCurrentProposalTender({
        ...currentProposalTender,
        status_detail: "Submit for Approval"
      })
      onRefresh && onRefresh()
    })
  };

  return (
    <>
      <ApprovalX
        title={'Laporan Pengadaan'}
        show={isShowApprovalSchmeaSubmitAwarding}
        schema={approvalSchemaSubmitAwarding}
        onHide={() => {
          setIsShowApprovalSchemaSubmitAwarding(false)
        }}
        onSubmit={(data: any) => {
          handleOnSubmitApprovalSubmitAwarding(data)
        }}
        isLoading={false}
        source={'TENDER'}
        object_id={proposalTender?.id}
        company_id={proposalTender?.company?.id}
        code={'tender_procurement_reporting'}
        readOnly={isLoadingPQ}
      />

      <h3>Proses Awarding</h3>
      {isLoading && (
        <Placeholder.Grid active columns={5} rows={5} />
      )}

      <div className={'table-responsive'}>
        <TableAwardingPaket
          proposalTender={proposalTender}
          items={dataItem}
          onCheckPaket={handleOnCheckPaket}
          checkIsEnableAwarding={checkIsEnableAwarding}
          onRefresh={onRefresh}
        />
      </div>


      <BottomToolbarWrapper children={
          <>
            {isReNegoEnabled() &&
              <button
                type={'button'}
                disabled={isLoading}
                className={'btn btn-lg btn-danger me-3'}
                onClick={() => onSubmitReNego()}
              >
                {isLoading ? (
                  <i className="fa fa-spinner fa-spin"></i>
                ) : (
                  <i className={'fa fa-undo'}></i>
                )}
                Kembali Ke Nego
              </button>
            }
            {isCanSubmitForApproval &&
              <button
                type={'button'}
                disabled={isLoading}
                className={'btn btn-lg btn-primary me-3'}
                onClick={() => onSubmitReportAwarding()}
              >
                {isLoading ? (
                  <i className="fa fa-spinner fa-spin"></i>
                ) : (
                  <i className={'fa fa-paper-plane'}></i>
                )}
                Submit Laporan Pengadaan
              </button>
            }
          </>
      } />
    </>
  );
};

const ProsesAwardingItemize = ({proposalTender, afterSaveDraft, onRefresh}: any) => {
  const navigate = useNavigate();
  const [dataItem, setDataItem] = React.useState<any>([])
  const [currentProposalTender, setCurrentProposalTender] = React.useState<any>([])
  const [selectedItems, setSelectedItems] = React.useState<any>([])
  const [vendorItems, setVendorItems] = React.useState<any>([] as any[])
  const [{doSubmit, isLoading, isError, data, isSuccess}] = useAxiosGet(
    getEnv('tender') + `/negotiation/summary/${proposalTender?.negotiation?.uuid}?proses_awarding=true`,
  )
  const formik = React.useRef<any>()
  const [formKey, setFormKey] = useState(0)
  const [{
    doSubmit: doSubmitAwarding,
    isLoading: isLoadingAwarding,
    isError: isErrorAwarding,
    data: dataAwarding,
    isSuccess: isSuccessAwarding,
  }] = useAxiosPost(
    getEnv('tender') + `/awarding-reporting/awarding/${proposalTender?.uuid}`,
  )
  const [selectedVendor, setSelectedVendor] = React.useState<any>([])
  const [initialValues, setInitialValues] = React.useState<any>({})
  const formValidationSchema = Yup.object().shape({})

  const getProposalTender = () => {
    const newUrl = getEnv("tender") + `/tender/${proposalTender?.uuid}`
    doSubmit({}, newUrl, undefined, () => {}).then((res: any) => {
      const _proposalTender = res.data;
      setCurrentProposalTender(_proposalTender)
    }).catch((err:any)=>{
      toast.error("Get Evaluasi Teknis: "+err?.response?.data?.meta?.message)
    });
  }

  const getData = () => {
    let url = getEnv('tender') + `/negotiation/summary/${proposalTender?.negotiation?.uuid}?proses_awarding=true`
    if (proposalTender?.order_placement !== 'Paket') {
      url = getEnv('tender') + `/negotiation/summary/${proposalTender?.negotiation?.uuid}`
    }
    doSubmit(null, url, undefined).then((res: any) => {
      const data = res?.data
      const _selectedItems = [] as any[]
      let _initialValues = {} as any
      data.map((item: any) => {
        if (item?.items?.length > 0) {
          const isItemChecked = item?.items?.filter((item: any) => item?.award === true).length > 0;
          if(isItemChecked) _selectedItems.push(item)
        }

        item?.vendors?.map((vendor: any) => {
          // _initialValues[item?.no_pr].push({
          //   vendor_uuid: vendor?.vendor_uuid,
          //   item_uuid: vendor?.negotiation_item_id,
          //   quantity: 2,
          //   subtotal: 1
          // })
          // _initialValues[`item.${item?.no_pr}`] = item?.no_pr
          // _initialValues[`item.${item?.no_pr}.${vendor?.vendor_uuid}`] = vendor?.vendor_uuid
          // _initialValues[`item.${item?.no_pr}.${vendor?.vendor_uuid}.${vendor?.negotiation_item_id}`] = vendor?.negotiation_item_id;
          _initialValues[`item.${vendor?.vendor_uuid}.${vendor?.negotiation_item_id}.qty`] = 10;
          _initialValues[`item.${vendor?.vendor_uuid}.${vendor?.negotiation_item_id}.subtotal`] = vendor?.final_bid_price?.sub_total;
        })
      })
      setSelectedItems(_selectedItems)
      setDataItem(data);
      setInitialValues(_initialValues);
      // setFormKey(formKey+1)
    });
  };

  const handleOnCheck = (field:string, item_uuid: string, vendor_uuid: string, checked: boolean) => {
    if(field === "check") {
      // Menemukan apakah vendor sudah ada dalam vendorItems
      const existingVendor = vendorItems.find((vendor: any) => vendor.vendor_uuid === vendor_uuid);

      // Jika vendor sudah ada, update items
      if (existingVendor) {
        const updatedVendorItems = vendorItems.map((vendor: any) => {
          if (vendor.vendor_uuid === vendor_uuid) {
            return {
              ...vendor,
              items: checked ? [...vendor.items, item_uuid] : vendor.items.filter((item: any) => item !== item_uuid)
            };
          }
          return vendor;
        });

        setVendorItems(updatedVendorItems);
      } else {
        // Jika vendor belum ada, tambahkan vendor baru ke vendorItems
        const newVendor = {
          vendor_uuid: vendor_uuid,
          items: checked ? [item_uuid] : []
        };

        setVendorItems([...vendorItems, newVendor]);
      }
    } else {
    }
  };

  const handleOnPublish = () => {
    Swal.fire({
      title: "Konfirmasi",
      text: "Anda akan melakukan awarding?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, Lanjutkan ",
      cancelButtonText: "Tidak"
    }).then(async (result) => {
      if (result.isConfirmed) {
        vendorItems.map((item: any) => {
          const payload = {
            proposal_tender_vendor_id: item?.vendor_uuid,
            awarding: true,
            negotiation_items: item?.items
          };
          // console.log(payload)
          // if(payload?.negotiation_items?.length > 0){
            doSubmitAwarding(payload, undefined, toast).then((res: any) => {
              getData();
            });
          // }
        });

      }
    });

  };

  useEffect(() => {
    if (proposalTender) {
      getData();
      getProposalTender()
    }
  }, [proposalTender]);

  const handleOnSave = async () => {

  }

  const isShowSubmitReportAwarding = () => {
    const allowedStatus = [
      "Laporan Pengadaan"
    ];
    const allowedStatusDetail = [
      "Awarding",
      "Rejected"
    ];
    return allowedStatus.includes(currentProposalTender?.status) &&
      allowedStatusDetail.includes(currentProposalTender?.status_detail);
  };

  const checkIsEnableAwarding = () => {
    const allowedStatus = [
      "Laporan Pengadaan"
    ];
    const allowedStatusDetail = [
      "Awarding",
      "Rejected"
    ];
    return allowedStatus.includes(currentProposalTender?.status) && allowedStatusDetail.includes(currentProposalTender?.status_detail);
  }

  const isReNegoEnabled = () => {
    const allowedStatus = [
      'Awarding',
      'Rejected',
    ]
    return allowedStatus.includes(currentProposalTender?.status_detail) && currentProposalTender?.status === "Laporan Pengadaan";
  }

  const [approvalSchemaSubmitAwarding, setApprovalSchemaSubmitAwarding] = React.useState([]);
  const [isShowApprovalSchmeaSubmitAwarding, setIsShowApprovalSchemaSubmitAwarding] = React.useState(false);

  const [{ doSubmit: doSubmitPQ, isLoading: isLoadingPQ }] = useAxiosPost(getEnv("tender") + "/pq/submit");
  const [{ doSubmit: doSubmitReNego, isLoading: isLoadingReNego }] = useAxiosPost(getEnv("tender") + `/awarding-reporting/rollback/${proposalTender?.uuid}`);
  const [{ doSubmit: doSubmitGet, isLoading: isLoadingGet }] = useAxiosGet(
    getEnv("tender") + "/negotiation"
  );

  const onSubmitReportAwarding = () => {
    const newUrl = getEnv("tender") + `/awarding-reporting/routing-approval-code/${proposalTender?.uuid}`;
    doSubmitGet({}, newUrl, undefined).then((res: any) => {
      setApprovalSchemaSubmitAwarding(res.data?.details);
      setIsShowApprovalSchemaSubmitAwarding(true);
    })
    setIsShowApprovalSchemaSubmitAwarding(true);
  };

  const handleOnSubmitApprovalSubmitAwarding = async (values: any) => {
    const payload = {
      approvers: values.approval.map((item: any) => {
        return {
          step: item.step,
          jabatan: item.jabatan,
          type: "APPROVAL",
          user_id: item.extra_info.user.user_id,
          extra_info: item.extra_info
        };
      })
    };
    const newUrl = getEnv("tender") + `/awarding-reporting/submit/${proposalTender?.uuid}`;
    doSubmitPQ(payload, newUrl, toast, () => {}).then((data: any) => {
      setIsShowApprovalSchemaSubmitAwarding(false);
      afterSaveDraft && afterSaveDraft();
    })
  };

  const onSubmitReNego = () => {
    Swal.fire({
      title: "Konfirmasi",
      text: "Apakah anda yakin ingin kembali ke proses Nego?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, Lanjutkan ",
      cancelButtonText: "Tidak"
    }).then(async (result) => {
      if (result.isConfirmed) {
        doSubmitReNego({},undefined,toast).then((res:any)=> {
          window.location.reload();
        })
      }
    })
  };

  return (
    <>
      <ApprovalX
        title={"Laporan Pengadaan"}
        show={isShowApprovalSchmeaSubmitAwarding}
        schema={approvalSchemaSubmitAwarding}
        onHide={() => {
          setIsShowApprovalSchemaSubmitAwarding(false);
        }}
        onSubmit={(data: any) => {
          handleOnSubmitApprovalSubmitAwarding(data);
        }}
        isLoading={false}
        source={"TENDER"}
        object_id={proposalTender?.id}
        company_id={proposalTender?.company?.id}
        code={"tender_procurement_reporting"}
        readOnly={isLoadingPQ}
      />

      <h3>Proses Awarding</h3>
      {isLoading && (
        <Placeholder.Grid active columns={5} rows={5} />
      )}

      {!isLoading && (
        <Formik
          innerRef={formik}
          key={`awarding-itemize-${formKey}`}
          initialValues={initialValues}
          onSubmit={handleOnSave}
          validationSchema={formValidationSchema}
          enableReinitialize
          validateOnChange={true}
        >
          {({ errors, touched, values, setFieldValue, resetForm, submitForm }: any) => (

            <Form id="form-awarding" noValidate={true} className={"needs-validation"}>
              <>
                <div className={"table-responsive"}>
                  <table className={"table table-header-gray"}>
                    <thead>
                    <tr>
                      <th className={"border px-2"} rowSpan={2} style={{
                        position: "sticky", left: 0, zIndex: 1, backgroundColor: "#f0f2f5", minWidth: 200
                      }}>
                        Deskripsi
                      </th>
                      <th className={"border px-2 text-center text-nowrap"} rowSpan={2}>Nomor PR</th>
                      <th className={"border px-2 text-center text-nowrap"} rowSpan={2}>PR Item</th>
                      <th className={"border px-2 text-center text-nowrap"} rowSpan={2}>Material Code</th>
                      <th className={"border px-2 text-center text-nowrap"} rowSpan={2}>Plant</th>
                      <th className={"border px-2 text-center"} rowSpan={2}>Rekanan</th>
                      <th className={"border px-2 text-center"} rowSpan={2}>Retender</th>
                      <th className={"border px-2 text-center"} rowSpan={2}>Award</th>
                      <th className={"border px-2 text-center"} rowSpan={2}>Rank</th>
                      <th className={"border px-2 text-nowrap text-center bg-light-info"} colSpan={5}>Harga Penawaran
                        Awal
                      </th>
                      <th className={"border px-2 text-nowrap text-center bg-light-success"} colSpan={5}>Harga
                        Penawaran Akhir
                      </th>
                      <th className={"border px text-center"} colSpan={2} style={{
                        position: "sticky", right: 0, zIndex: 1, width: 400
                      }}>
                        Final
                      </th>
                    </tr>
                    <tr>
                      <th className={"border px-2 text-end text-nowrap bg-light-info"}>Harga Satuan</th>
                      <th className={"border px-2 text-end text-nowrap bg-light-info"}>Qty</th>
                      <th className={"border px-2 text-end text-nowrap bg-light-info"}>Net Price</th>
                      <th className={"border px-2 text-end text-nowrap bg-light-info"}>Add Cost</th>
                      <th className={"border px-2 text-end text-nowrap bg-light-info"}>Subtotal</th>

                      <th className={"border px-2 text-end text-nowrap bg-light-success"}>Harga Satuan</th>
                      <th className={"border px-2 text-end text-nowrap bg-light-success"}>Qty</th>
                      <th className={"border px-2 text-end text-nowrap bg-light-success"}>Net Price</th>
                      <th className={"border px-2 text-end text-nowrap bg-light-success"}>Add Cost</th>
                      <th className={"border px-2 text-end text-nowrap bg-light-success"}>Subtotal</th>
                      <th className={"border px-2 text-end text-nowrap bg-light-danger"} style={{
                        position: "sticky", right: 200, zIndex: 1, backgroundColor: "#fff", width: 200
                      }}>Quantity
                      </th>
                      <th className={"border px-2 text-end text-nowrap bg-light-danger"} style={{
                        position: "sticky", right: 0, zIndex: 1, backgroundColor: "#fff", minWidth: 200
                      }}>Subtotal
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    {dataItem.length === 0 && (
                      <tr>
                        <td colSpan={16} className={"border px-2 text-center"}>Tidak ada data</td>
                      </tr>
                    )}
                    {dataItem.map((item: any, index: number) => {
                      return <TrItem
                        item={item}
                        key={"proses-awarding-" + index}
                        onCheckAwarding={handleOnCheck}
                        formik={formik}
                        proposalTender={proposalTender}
                        checkIsEnableAwarding={checkIsEnableAwarding}
                      />;
                    })}
                    </tbody>
                  </table>


                  <div className={"col-md-12 col-sm-12"}>
                    <div className={"fixed-bottom row"} style={{
                      zIndex: 1
                    }}>
                      <div className={"col-md-12 bg-white px-20 py-2 gap-3"}>
                        <div className={"text-end"}>
                        {isReNegoEnabled() &&
                          <button
                            type={'button'}
                            disabled={isLoading}
                            className={'btn btn-lg btn-danger me-3'}
                            onClick={() => onSubmitReNego()}
                          >
                            {isLoading ? (
                              <i className="fa fa-spinner fa-spin"></i>
                            ) : (
                              <i className={'fa fa-undo'}></i>
                            )}
                            Kembali Ke Nego
                          </button>
                        }
                          {!checkIsEnableAwarding &&
                            <button
                              className={"btn btn-primary btn-lg me-3"}
                              type={"button"}
                              onClick={() => handleOnPublish()}
                              disabled={!checkIsEnableAwarding()}
                            >
                              {isLoading && (<i className={"fa fa-spinner fa-spin me-2"}></i>)}
                              {!isLoading && (<i className={"fa fa-save me-2"}></i>)}
                              Set Awarding
                            </button>
                          }
                          {isShowSubmitReportAwarding() &&
                            <button
                              type={"button"}
                              disabled={isLoading}
                              className={"btn btn-lg btn-primary me-3"}
                              onClick={() => onSubmitReportAwarding()}
                            >
                              {isLoading ? (
                                <i className="fa fa-spinner fa-spin"></i>
                              ) : (
                                <i className={"fa fa-paper-plane"}></i>
                              )}
                              Submit Laporan Pengadaan
                            </button>
                          }
                        </div>
                      </div>
                    </div>
                  </div>


                </div>
              </>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

const AwardingTab = ({ proposalTender, afterSaveDraft, onRefresh }: any) => {
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosPost(
    getEnv("tender") + `/awarding-reporting/output/${proposalTender?.uuid}`
  );
  const formik = React.useRef<any>();
  const initialValues = {
    output:{
      "id": 390,
      "type": "TENDER_AWARDING_OUTPUT_DOC",
      "value": "PO",
      "description": {
        "name": "Purchase Order"
      },
      "is_active": true,
      "created_at": null,
      "updated_at": null,
      "action": {
        "edit": false,
        "delete": false
      }
    }
  };

  const formSchemaValidation = Yup.object().shape({});

  const isFormEnabled = () => {
    const allowedStatus = ["Laporan Pengadaan"];
    const allowedStatusDetail = ["Awarding","Rejected"];
    return allowedStatus.includes(proposalTender?.status) && allowedStatusDetail.includes(proposalTender?.status_detail);
  };

  const handleOnSave = async (values: any) => {
    console.log(values);
    // if(outputDoc === ""){
    //   toast.error("Silahkan pilih output doc terlebih dahulu")
    //   return;
    // }
    const payload = {
      _method: "PUT",
      output: "Purchase Order"
    };
    doSubmit(payload, undefined, toast).then((res: any) => {
    });
  };

  return (
    <>
      <div className={"row"}>
        <div className={"col-md-6 col-sm-12 mb-10"}>
          {proposalTender && (
            <FlagRejected proposalTender={proposalTender} type={"Awarding"} />
          )}
          {proposalTender && (
            <FlagRejected proposalTender={proposalTender} type={"Laporan Pengadaan"} />
          )}

          <Formik
            innerRef={formik}
            key={`formik-tender-parameter-pengadaan-`}
            initialValues={initialValues}
            onSubmit={handleOnSave}
            validationSchema={formSchemaValidation}
            enableReinitialize
            validateOnChange={true}

          >
            {({ errors, touched, values, setFieldValue, resetForm, submitForm }: any) => (
              <Form id="form-awarding">
                <>
                  <div className={"form-group mb-4"}>
                    <label>Deskripsi Tender</label>
                    <input type={"text"} className={"form-control form-control-sm"} disabled={true}
                           value={proposalTender?.title} />
                  </div>
                  <div className={"form-group mb-4"}>
                    <FormikSelect2
                      name="output"
                      label="Output Doc"
                      isDisabled={!isFormEnabled()}
                      optionsUrl={
                        getEnv("masterdata") + "/enumeration?filter[type]=TENDER_AWARDING_OUTPUT_DOC"
                      }
                      onChange={(e: any) => {
                        setFieldValue("output", e?.value);
                        // setOutputDoc(e?.value)
                      }}
                      unique_property={"id"}
                      getOptionValue={(opt) => opt}
                      getOptionLabel={(opt) => opt?.description?.name}
                      search_property={"value"}
                      required
                    />
                  </div>
                  <div className={"form-group text-end"}>
                    <button
                      className={"btn btn-sm btn-primary"}
                      type={"button"}
                      disabled={!isFormEnabled()}
                      onClick={submitForm}
                    >
                      <i className={"fa fa-save me-2"}></i>
                      Simpan
                    </button>
                  </div>
                </>
              </Form>
            )}
          </Formik>

        </div>

        {/*<div className={"col-md-12 col-sm-12 mb-10"}>*/}
        {/*  <RekapEvaluasi proposalTender={proposalTender}/>*/}
        {/*</div>*/}

        <div className={"col-md-12 col-sm-12 mb-10"}>
          {proposalTender?.order_placement === "Paket" && (
            <ProsesAwardingPaket proposalTender={proposalTender} afterSaveDraft={afterSaveDraft} onRefresh={onRefresh} />
          )}
          {proposalTender?.order_placement !== "Paket" && (
            <ProsesAwardingItemize proposalTender={proposalTender} afterSaveDraft={afterSaveDraft} onRefresh={onRefresh}/>
          )}

        </div>

        <div className={"col-md-12 col-sm-12 mb-10"}>
          {/*<RekapAwarding proposalTender={proposalTender}/>*/}
        </div>
      </div>
    </>
  );
};

const AwardingPage = ({ proposalTender, afterSaveDraft, onRefresh }: any) => {
  const [selectedTab, setSelectedTab] = React.useState("awarding");

  const showTab = () => {
    switch (selectedTab) {
      case "awarding":
        return <AwardingTab proposalTender={proposalTender} afterSaveDraft={afterSaveDraft} onRefresh={onRefresh}/>;
      case "memo":
        return <BeritaAcaraAwardingTab proposalTender={proposalTender} />;
      default:
        return <></>;
    }
  };
  return (
    <div>
      <div className={"d-flex justify-content-between"}>
        <div><h3>Awarding</h3></div>
      </div>
      <hr />
      <Nav appearance={"subtle"} activeKey={selectedTab} onSelect={(e: any) => {
        setSelectedTab(e);
      }} style={{ marginBottom: 20 }}>
        <Nav.Item eventKey={"awarding"}>
          Awarding
        </Nav.Item>
        <Nav.Item eventKey={"memo"}>
          Memo
        </Nav.Item>
      </Nav>

      {showTab()}
    </div>
  );
};

export default AwardingPage;