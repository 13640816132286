import axios from 'axios'
import {getEnv} from '../../../../helpers/procurex'

const getImportMasterByCode = (code: string) => {
  return axios.get(`${getEnv('excel_importer')}/import-master/${code}`)
}

const storeImport = (values: any) => {
  const formData = new FormData()

  formData.append('file', values.file)
  formData.append('master_code', values.master_code)

  return axios.post(`${getEnv('excel_importer')}/import`, formData)
}

export {getImportMasterByCode, storeImport}