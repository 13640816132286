import {useIntl} from 'react-intl'
import {Nav} from 'rsuite'
import {
  formatDateTimeToDBFormat,
  getEnv,
  parseNumberToFloat,
} from '../../../../../../../../helpers/procurex'
import {createContext, ReactNode, useCallback, useContext, useMemo, useState} from 'react'
import TableXWrapper from '../../../../../../../components/shared/TableXWrapper'
import {Column} from 'react-table'
import {IColumns} from '../../../../../../../components/shared/TableX'
import BottomToolbarWrapper from '../../../../../../../components/shared/BottomToolbarWrapper'
import {Button} from 'react-bootstrap'
import {EvaluationIncomingForm} from '../../../../evaluation/components/EvaluationIncomingDetail'
import {useClarificationTenderItemQuery} from '../../../../evaluation/core/_queries'
import EvaluationSendToEvaluatorModal from './EvaluasiSendToEvaluatorModal'
import {ApprovalX, ButtonProsesApproval} from '../../../../../../../components/shared/ApprovalX'
import {useProposalTender} from '../../../core/_proposalTenderProvider'
import {ClarificationTenderItemStatus, ProposalTenderItemModel} from '../../../../../core/_models'
import {useAuth} from '../../../../../../auth'
import BatchApproval from '../../../../evaluation/components/BatchApproval'
import BeritaAcaraTabContent from '../../../../evaluation/components/BeritaAcaraTabContent'

const API_URL = getEnv('tender')

const CAN_CREATE_EVALUATION_STATUSES: ClarificationTenderItemStatus[] = [
  'Reject Approval',
  'Cancel',
  'Complete',
]

const CAN_UPDATE_APPROVAL_EVALUATION_STATUSES: ClarificationTenderItemStatus[] = [
  'Submit Approval',
  'Receive',
]

export const EvaluasiUserTabContext = createContext({
  proposalTender: null,
  refetchProposalTender: () => {},

  clarificationTenderItem: null,
  setClarificationTenderItem: () => {},

  panel: 'detail',
  setPanel: () => {},

  headerText: null,
  setHeaderText: () => {},

  selectedProposalTenderItems: [],
  setSelectedProposalTenderItems: () => {},

  canCreateEvaluation: false,
  canUpdateApprovalEvaluation: false,
} as any)

interface EvaluasiUserTabProviderProps {
  proposalTender: any
  children: ReactNode
}

const EvaluasiUserTabProvider = ({proposalTender, children}: EvaluasiUserTabProviderProps) => {
  const {currentUser} = useAuth()
  const [panel, setPanel] = useState<'detail' | 'history'>()
  const [headerText, setHeaderText] = useState(null)
  const [clarificationTenderItem, setClarificationTenderItem] = useState(null)
  const [selectedProposalTenderItems, setSelectedProposalTenderItems] = useState(
    [] as ProposalTenderItemModel[]
  )
  const {refetchProposalTender} = useProposalTender()

  const canCreateEvaluation = useMemo(() => {
    if (currentUser?.username !== proposalTender?.created_by?.username) {
      return false
    }

    if (selectedProposalTenderItems.length === 0) {
      return undefined
    }

    return (
      selectedProposalTenderItems.length > 0 &&
      selectedProposalTenderItems.every(
        (item) =>
          item.latest_clarification_tender_item === null ||
          CAN_CREATE_EVALUATION_STATUSES.includes(item.latest_clarification_tender_item?.status)
      )
    )
  }, [currentUser?.username, proposalTender?.created_by?.username, selectedProposalTenderItems])

  const canUpdateApprovalEvaluation = useMemo(() => {
    if (selectedProposalTenderItems.length === 0) {
      return undefined
    }

    return (
      selectedProposalTenderItems.length > 0 &&
      selectedProposalTenderItems.every(
        (item) =>
          item.latest_clarification_tender_item !== null &&
          currentUser?.username === item.latest_clarification_tender_item?.current_user &&
          CAN_UPDATE_APPROVAL_EVALUATION_STATUSES.includes(
            item.latest_clarification_tender_item?.status
          )
      )
    )
  }, [currentUser?.username, selectedProposalTenderItems])

  return (
    <EvaluasiUserTabContext.Provider
      value={{
        proposalTender,
        refetchProposalTender,

        clarificationTenderItem,
        setClarificationTenderItem,

        panel,
        setPanel,

        headerText,
        setHeaderText,

        selectedProposalTenderItems,
        setSelectedProposalTenderItems,

        canCreateEvaluation,
        canUpdateApprovalEvaluation,
      }}
    >
      {children}
    </EvaluasiUserTabContext.Provider>
  )
}

//region Subcomponents
const EvaluasiUserDetail = () => {
  const evaluasiUserTabContext = useContext(EvaluasiUserTabContext)

  const {data: clarificationTenderItem, isLoading} = useClarificationTenderItemQuery(
    evaluasiUserTabContext.clarificationTenderItem.uuid,
    {
      include: [
        'poText',
        'vendors.clarification_attachments',
        'evaluator_results.notes',
        'evaluator_results.params',
      ],
    },
    {staleTime: Infinity}
  )

  return (
    <EvaluationIncomingForm
      clarificationTenderItem={clarificationTenderItem}
      isReadOnly={true}
      hideBottomToolbar={true}
      isLoading={isLoading}
    />
  )
}

const EvaluasiUserDetailButtons = () => {
  const context = useContext(EvaluasiUserTabContext)
  const {clarificationTenderItem, setHeaderText, setClarificationTenderItem} = context
  const [isShowApprovalSchema, setIsShowApprovalSchema] = useState(false)
  const onApproval = CAN_UPDATE_APPROVAL_EVALUATION_STATUSES.includes(
    clarificationTenderItem?.status
  )

  return (
    context.panel === 'detail' &&
    onApproval && (
      <div className='ms-auto align-top'>
        <div>
          <ButtonProsesApproval
            onShowDetailApproval={() => {
              setIsShowApprovalSchema(true)
            }}
            source={'TENDER'}
            object_id={clarificationTenderItem.id}
            company_id={clarificationTenderItem.company_id}
            code={clarificationTenderItem.routing_approval_config_code}
            readOnly={true}
            onSubmit={() => {
              setHeaderText(null)
              setClarificationTenderItem(null)
            }}
          />

          <ApprovalX
            show={isShowApprovalSchema}
            onHide={() => setIsShowApprovalSchema(false)}
            source={'TENDER'}
            object_id={clarificationTenderItem.id}
            company_id={clarificationTenderItem.company_id}
            code={clarificationTenderItem.routing_approval_config_code}
            readOnly={true}
          />
        </div>
      </div>
    )
  )
}

const EvaluasiUserDetailHistory = () => {
  const intl = useIntl()
  const evaluasiUserTabContext = useContext(EvaluasiUserTabContext)
  const urlIndex = `${API_URL}/clarification/tender-item/logs`
  const tableColumns: IColumns[] | Column<any>[] = [
    {
      Header: intl.formatMessage({id: 'Pengirim'}),
      accessor: 'causer',
      searchable: false,
      orderable: false,
      disableSortBy: true,
      width: 260,
      Cell: ({value}) => (value ? `${value.name} (${value.username})` : ''),
    },
    {
      Header: intl.formatMessage({id: 'Kegiatan'}),
      accessor: 'description',
      orderable: false,
      disableSortBy: true,
      width: 650,
    },
    {
      Header: intl.formatMessage({id: 'Komentar'}),
      accessor: 'properties.comment',
      orderable: false,
      disableSortBy: true,
      width: 200,
      Cell: ({value}) => (Array.isArray(value) ? value.join(', ') : value),
    },
    {
      Header: intl.formatMessage({id: 'Tanggal Aksi'}),
      accessor: 'created_at',
      width: 150,
      className: 'text-center',
      Cell: ({value}) => (value ? formatDateTimeToDBFormat(value) : ''),
    },
  ]

  evaluasiUserTabContext.setHeaderText(
    intl.formatMessage(
      {id: 'Riwayat Evaluasi PR Item'},
      {
        no_pr: evaluasiUserTabContext.clarificationTenderItem.no_pr,
        line_no: evaluasiUserTabContext.clarificationTenderItem.line_no,
        short_text: evaluasiUserTabContext.clarificationTenderItem.short_text,
      }
    )
  )

  return (
    <TableXWrapper
      urlIndex={urlIndex}
      defaultFilter={`sort=-created_at&include=causer&filter[proposal_tender_item_id]=${evaluasiUserTabContext.clarificationTenderItem.proposal_tender_item_id}`}
      columns={tableColumns}
      disableUrlChanged={true}
      hideSearch={false}
      hideExportAs={false}
      disablePaddingCard={true}
      readonly={true}
    />
  )
}

const EvaluasiUserTable = ({proposalTenderId}: {proposalTenderId: number | null | undefined}) => {
  const {currentUser} = useAuth()
  const intl = useIntl()
  const context = useContext(EvaluasiUserTabContext)
  const urlIndex = `${API_URL}/tender-item?filter[proposal_tender_id]=${proposalTenderId}&include=latestClarificationTenderItem.completionPercentage`
  const tableColumns: IColumns[] | Column<ProposalTenderItemModel>[] = [
    {
      Header: intl.formatMessage({id: 'Aksi'}),
      accessor: 'uuid',
      searchable: false,
      orderable: false,
      disableSortBy: true,
      width: 150,
      className: 'text-center',
      Cell: (props) => (
        <div className='text-nowrap'>
          <>
            <button
              title={intl.formatMessage({id: 'Detail'})}
              className={'btn btn-info btn-sm'}
              disabled={props.row.original.latest_clarification_tender_item === null}
              onClick={() => {
                const tenderItem = props.row.original.latest_clarification_tender_item
                context.setPanel('detail')
                context.setClarificationTenderItem(tenderItem)
                context.setHeaderText(
                  `Evaluasi PR ${tenderItem.no_pr} Item ${tenderItem.line_no} (${tenderItem.short_text})`
                )
              }}
            >
              <i className={'fa fa-search'}></i>
            </button>
            <button
              title={intl.formatMessage({id: 'Riwayat'})}
              className={'btn btn-info btn-sm ms-2'}
              disabled={props.row.original.latest_clarification_tender_item === null}
              onClick={() => {
                const tenderItem = props.row.original.latest_clarification_tender_item
                context.setPanel('history')
                context.setClarificationTenderItem(tenderItem)
                context.setHeaderText(
                  intl.formatMessage(
                    {id: 'Riwayat Evaluasi PR Item'},
                    {
                      no_pr: tenderItem.no_pr,
                      line_no: tenderItem.line_no,
                      short_text: tenderItem.short_text,
                    }
                  )
                )
              }}
            >
              <i className={'fa fa-history'}></i>
            </button>
          </>
        </div>
      ),
    },
    {
      Header: intl.formatMessage({id: 'No. PR'}),
      accessor: 'no_pr',
      orderable: false,
      disableSortBy: true,
      width: 100,
      className: 'text-center',
    },
    {
      Header: intl.formatMessage({id: 'Item PR'}),
      accessor: 'line_no',
      orderable: false,
      disableSortBy: true,
      width: 75,
      className: 'text-center',
    },
    {
      Header: intl.formatMessage({id: 'Deskripsi PR'}),
      accessor: 'short_text',
      orderable: true,
      disableSortBy: true,
      width: 150,
      className: 'text-left',
    },
    {
      Header: intl.formatMessage({id: 'Penawaran Vendor'}),
      accessor: 'penawaran_vendor',
      orderable: false,
      disableSortBy: true,
      width: 350,
      className: 'text-left',
      Cell:({value,row})=>{
        const item_quotations = row?.original?.quotation_items
        console.log('item_quotations',item_quotations)
        return (
          <>
            {item_quotations?.map((item:any)=>{
              return (
                <>
                  <div className={"d-flex mb-2 align-items-start gap-2"}>
                    <div>{item?.vendor}</div> :
                    <div className={"badge badge-secondary"}>{item?.quote}</div>
                  </div>
                </>
              )
            })}
          </>
        )
      }
    },
    {
      Header: intl.formatMessage({id: 'Status'}),
      accessor: 'latest_clarification_tender_item.status_description',
      orderable: false,
      disableSortBy: true,
      width: 200,
      Cell: ({value, row}) => {
        const completionPercentage = row.original?.latest_clarification_tender_item?.completion_percentage
          const classBadge = value?.toLowerCase().includes('ditolak')
          ? 'badge bg-danger text-start text-wrap'  
          : value?.toLowerCase().includes('approval')
          ? 'badge bg-success text-start text-wrap'  
          : 'badge bg-primary text-start text-wrap'; 

          return (
            <div className='mb-2 text-start text-wrap'>
              <div>
                <div className={classBadge}>{value}</div> 
                <div>
                  {completionPercentage !== undefined
                    ? <small>({parseNumberToFloat(completionPercentage)}%)</small>
                    : ''}
                </div>
              </div>
            </div>
          );
          
      },
    },
    {
      Header: intl.formatMessage({id: 'Kategori Evaluasi'}),
      accessor: 'latest_clarification_tender_item.categories',
      orderable: false,
      disableSortBy: true,
      width: 150,
      Cell: ({value}) => (
        <>
          {Array.isArray(value) && value.length > 0 && (
            <ul>
              {value.map((item: string, index: number) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          )}
        </>
      ),
    },
    {
      Header: intl.formatMessage({id: 'Parameter'}),
      accessor: 'latest_clarification_tender_item.parameters',
      orderable: false,
      disableSortBy: true,
      width: 200,
      Cell: ({value}) => (
        <>
          {Array.isArray(value) && value.length > 0 && (
            <ul>
              {value.map((item: string, index: number) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          )}
        </>
      ),
    },
    {
      Header: intl.formatMessage({id: 'Tujuan Evaluasi'}),
      accessor: 'latest_clarification_tender_item.clarification_evaluator.employee.dept_title',
      searchable: false,
      orderable: false,
      disableSortBy: true,
      width: 250,
    },
    {
      Header: intl.formatMessage({id: 'Posisi Dokumen'}),
      accessor: 'latest_clarification_tender_item.current_user_data',
      searchable: false,
      orderable: false,
      disableSortBy: true,
      width: 250,
      Cell: ({value}) => (value ? `${value?.username} - ${value?.name}` : ''),
    },
    {
      Header: intl.formatMessage({id: 'Tanggal Kirim'}),
      accessor: 'latest_clarification_tender_item.created_at',
      searchable: false,
      orderable: false,
      disableSortBy: true,
      width: 100,
      className: 'text-center',
      Cell: ({value}) => (value ? formatDateTimeToDBFormat(value) : ''),
    },
  ]

  // TODO: cleaner implementation
  const checkboxConditionDisabled = useCallback(
    (row: ProposalTenderItemModel) => {
      const rowStatus = row.latest_clarification_tender_item?.status
      const rowCanCreateEvaluation = [...CAN_CREATE_EVALUATION_STATUSES, undefined].includes(
        rowStatus
      )

      if (rowCanCreateEvaluation && context.canCreateEvaluation !== false) {
        return true
      }

      const rowCanApproveEvaluation = CAN_UPDATE_APPROVAL_EVALUATION_STATUSES.includes(rowStatus)

      if (rowCanApproveEvaluation && context.canUpdateApprovalEvaluation !== false) {
        return currentUser?.username === row.latest_clarification_tender_item?.current_user
      }

      return false
    },
    [context.canCreateEvaluation, context.canUpdateApprovalEvaluation, currentUser?.username]
  )

  const onSelectedRows = useCallback(
    (selectedRows: any) => {
      const selectedIds = selectedRows.map((row: any) => row?.original?.id)
      const currentIds = context.selectedProposalTenderItems.map((item: any) => item?.id)
      const isEqual =
        selectedIds.length === currentIds.length &&
        selectedIds.every((value: any, index: string | number) => value === currentIds?.[index])

      if (isEqual) {
        return
      }

      context.setSelectedProposalTenderItems(selectedRows.map((row: any) => row.original))
    },
    [context]
  )

  if (!proposalTenderId) {
    return <></>
  }

  return (
    <TableXWrapper
      checkboxConditionDisabled={checkboxConditionDisabled}
      onSelectedRows={onSelectedRows}
      urlIndex={urlIndex}
      columns={tableColumns}
      disableUrlChanged={true}
      hideSearch={false}
      hideExportAs={false}
      disablePaddingCard={true}
      readonly={true}
    />
  )
}
//endregion

const EvaluasiTabContent = () => {
  const {
    proposalTender,
    refetchProposalTender,
    clarificationTenderItem,
    setClarificationTenderItem,
    panel,
    setHeaderText,
    selectedProposalTenderItems,
    setSelectedProposalTenderItems,
    canCreateEvaluation,
    canUpdateApprovalEvaluation,
  } = useContext(EvaluasiUserTabContext)
  const intl = useIntl()
  const [tableKey, setTableKey] = useState(1)
  const [isShowSentToEvaluatorModal, setIsShowSentToEvaluatorModal] = useState(false)

  const approvalItems = useMemo(() => {
    return selectedProposalTenderItems
      .filter((item: ProposalTenderItemModel) => item.latest_clarification_tender_item)
      .map((item: ProposalTenderItemModel) => ({
        source: 'TENDER',
        object_id: item.latest_clarification_tender_item.id,
        company_id: item.latest_clarification_tender_item.company_id,
        code: item.latest_clarification_tender_item.routing_approval_config_code,
      }))
  }, [selectedProposalTenderItems])

  return (
    <>
      {/*region Back To List Button */}
      {clarificationTenderItem !== null && (
        <div className={'mb-8 d-flex'}>
          <div>
            <button
              className={'btn btn-danger btn-sm'}
              type={'button'}
              onClick={() => {
                setHeaderText(null)
                setClarificationTenderItem(null)
              }}
            >
              <i className={'fa fa-arrow-left'} />
              {intl.formatMessage({id: 'Kembali ke list'})}
            </button>
          </div>

          <EvaluasiUserDetailButtons />
        </div>
      )}
      {/*endregion*/}

      {clarificationTenderItem === null && (
        <EvaluasiUserTable key={tableKey} proposalTenderId={proposalTender.id} />
      )}

      {clarificationTenderItem && panel === 'detail' && <EvaluasiUserDetail />}
      {clarificationTenderItem && panel === 'history' && <EvaluasiUserDetailHistory />}

      {clarificationTenderItem === null && (
        <BottomToolbarWrapper
          children={
            <>
              <BatchApproval
                enabled={canUpdateApprovalEvaluation === true}
                items={approvalItems}
                onSuccess={async () => {
                  setTableKey((current) => current + 1)
                  setSelectedProposalTenderItems([])
                }}
              />

              <Button
                type={'button'}
                variant={'primary'}
                className={'me-3'}
                disabled={!canCreateEvaluation}
                onClick={() => setIsShowSentToEvaluatorModal(true)}
              >
                {intl.formatMessage({id: 'Buat Evaluasi ke Evaluator'})}
              </Button>

              <EvaluationSendToEvaluatorModal
                proposalTenderItems={selectedProposalTenderItems}
                show={isShowSentToEvaluatorModal}
                onHide={() => setIsShowSentToEvaluatorModal(false)}
                onSuccess={async () => {
                  setTableKey((current) => current + 1)
                  await refetchProposalTender()
                }}
              />
            </>
          }
        />
      )}
    </>
  )
}

const EvaluasiUserTabContent = () => {
  const {proposalTender, clarificationTenderItem, headerText} = useContext(EvaluasiUserTabContext)
  const intl = useIntl()
  const [selectedTab, setSelectedTab] = useState('evaluasi')

  const menuTab = [
    {eventKey: 'evaluasi', label: 'Evaluasi'},
    {eventKey: 'berita-acara', label: 'Berita Acara'},
  ]

  return (
    <div>
      <h3>{headerText || intl.formatMessage({id: 'Permintaan Evaluasi ke Evaluator'})}</h3>
      <hr />

      {clarificationTenderItem === null && (
        <Nav
          appearance={'subtle'}
          activeKey={selectedTab}
          onSelect={(e: any) => setSelectedTab(e)}
          style={{marginBottom: 20}}
          justified
        >
          {menuTab.map((item: any, index: number) => {
            return (
              <Nav.Item eventKey={item.eventKey} key={index}>
                {item.label}
              </Nav.Item>
            )
          })}
        </Nav>
      )}

      {selectedTab === 'evaluasi' && <EvaluasiTabContent />}
      {selectedTab === 'berita-acara' && (
        <BeritaAcaraTabContent proposalTenderUuid={proposalTender?.uuid} />
      )}
    </div>
  )
}

export const EvaluasiUserTab = ({proposalTender}) => {
  return (
    <EvaluasiUserTabProvider proposalTender={proposalTender}>
      <EvaluasiUserTabContent />
    </EvaluasiUserTabProvider>
  )
}
