import {useEffect, useState} from "react";
import axios from "axios";
import {useLocation} from 'react-router-dom'

interface IMeta {
	message: string,
	code: number
}
interface IResponse {
	data: any,
	meta: IMeta
}

export const useGetSideMenu = ()=> {
	const url = process.env.REACT_APP_API_URL+"/api/auth/module"
	const [isLoadingSideMenu,setIsLoading] = useState<boolean>(false);
	const [responseDataSideMenu, setResponseData] = useState<any>({})
	const [responseErrorSideMenu,setResponseError] = useState<any>({})

	const getDataSideMenu = async(path: string):Promise<void> => {
		setIsLoading(true)
		setResponseData({})
		try {
			const response =  await axios.get<IResponse>(url+`?filter[path]=${path}&include=menus,menus.submenus&filter[status]=ACTIVE&sort=order_no&only_me=1`)
			setResponseData(response.data);
		} catch(e) {
			setResponseError(e)
		} finally {
			setIsLoading(false)
		}
	}

	return [ isLoadingSideMenu, responseDataSideMenu, responseErrorSideMenu, getDataSideMenu] as const;
}

export const useGetServerTime = ()=> {
	const url = process.env.REACT_APP_API_URL+"/api/auth/time"
	const [isLoadingTime,setIsLoading] = useState<boolean>(false);
	const [responseDataTime, setResponseData] = useState<any>({})
	const [responseErrorTime,setResponseError] = useState<any>({})

	useEffect(()=>{
		getDataTime();
	},[])
	const getDataTime = async():Promise<void> => {
		setIsLoading(true)
		setResponseData({})
		try {
			const response =  await axios.get<IResponse>(url)
			setResponseData(response.data);
		} catch(e) {
			setResponseError(e)
		} finally {
			setIsLoading(false)
		}
	}

	return [ isLoadingTime, responseDataTime, responseErrorTime, getDataTime] as const;
}

export const useGetNotificationList = (isUnread:boolean)=> {
	const url = process.env.REACT_APP_API_URL+`/api/task/notifications${!isUnread?'/read':''}?sort=-id`
	const [isLoadingNotification,setIsLoading] = useState<boolean>(false);
	const [responseDataNotification, setResponseData] = useState<any>([])
	const [responseErrorNotification,setResponseError] = useState<any>(null)
	const [totalNotification,setTotalNotification] = useState<number>(0)
	const getDataNotification = async():Promise<void> => {
		setIsLoading(true)
		setResponseData([])
		setTotalNotification(0)
		try {
			const response =  await axios.get(url)
			setResponseData(response.data.data);
			setTotalNotification(response?.data?.meta?.total)
		} catch(e) {
			setResponseError(e)
		} finally {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		getDataNotification();
	// 		const interval = setInterval(() => {
	// 			getDataNotification();
	// 		}, 30000)
	// 		return () => clearInterval(interval)
	//
	},[]);

	return [ isLoadingNotification, totalNotification, responseDataNotification, responseErrorNotification, getDataNotification] as const;
}

export const useHashValueByPrefix = (prefix: string) => {
	const {hash} = useLocation()
	const matches = hash.match(new RegExp(`#.*&?${prefix}=([^&]+)(&|$)`))

	return matches ? matches[1] : null
}

export const useHashNumberValueByPrefix = (prefix: string, defaultValue: number, allowedValues?: number[]) => {
	const tabValue = Number(useHashValueByPrefix(prefix))

	return !isNaN(tabValue) && (allowedValues === undefined || allowedValues.includes(tabValue))
		? tabValue
		: defaultValue
}
