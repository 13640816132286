import { DateRangePicker } from "rsuite";
import React, { useEffect, useState } from "react";
import { validate } from "webpack";

interface IProps {
  defaultValue?: [Date, Date] | null,
  onChange?: (value: [Date, Date]) => void
  disabled?: boolean
  className?: string
  style?: any
}
export default function DateRangePickerX({defaultValue, onChange, disabled, className, style}:IProps) {
  // @ts-ignore
  const [date,setDate] = useState<[Date, Date]>(defaultValue || null);
  const [index,setIndex] = useState(0)

  useEffect(() => {
    setIndex(index+1)
  }, [defaultValue]);

  useEffect(() => {
    // @ts-ignore
    onChange(date)
  }, [date]);

  return (
    <>
      <DateRangePicker
        // key={index}
        format="dd/MM/yyyy HH:mm:ss"
        value={date}
        onChange={(e:any)=>{
          setDate(e)
        }}
        style={{
          width: '100%',
          zIndex: 9999,
          ...style
        }}
        className={className}
        readOnly={disabled}
        placement="bottomStart"
      />
    </>
  )
}