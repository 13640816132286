import { Outlet, Route, Routes } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../../_metronic/layout/core'
import React, { useState, useEffect } from 'react'
import {
    SAPDailyStockviewPageList,
} from './components/SAPDailyStockviewPageList'

const masterData = {
    title: 'SAP Daily Stock Plant',
    path: '/stockview/transaction/sap-daily',
    isSeparator: false,
    isActive: false,
}
const separator = {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
}
const breadcrumbs: Array<PageLink> = [masterData, separator]
const childBreadcrumbs: Array<PageLink> = [
    masterData,
    separator,
    {
        title: 'Stockview SAP Daily Stock Plant',
        path: '/stockview/transaction/sap-daily',
        isSeparator: false,
        isActive: false,
    },
    separator,
]

const SAPDailyStockviewView = () => {
    // @ts-ignore
    const chartSeries = [
        {
            name: 'North',
            data: [],
        },
    ]

    const chartOptions = {}

    const [activeTab, setActiveTab] = useState(0) // State to track the active tab
    const [items, setItems] = useState<any>([])
    const [itemLoading, setItemLoading] = useState(false)

    const handleTabClick = (index: any) => {
        setActiveTab(index)
    }

    let dateTime = new Date()
    const options: any = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        // timeZoneName: 'short',
        timeZone: 'Asia/Jakarta', // Set the time zone to WIB
    }

    let formattedDateTime = dateTime.toLocaleDateString('en-GB', options)

    // Convert the formatted date and time to uppercase
    formattedDateTime = formattedDateTime.toUpperCase()

    const [textareaValue, setTextareaValue] = useState('')

    const handleTextareaChange = (e: any) => {
        setTextareaValue(e.target.value)
    }

    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    index
                    element={
                        <>
                            <PageTitle breadcrumbs={breadcrumbs}>SAP Daily Stock Plant Stockview</PageTitle>
                            <div className='card'>
                                <div className='card-body'>
                                    <p className="text-muted mb-3">Halaman ini bertujuan sebagai tools monitoring Data SAP masuk ke dalam Stockview mengacu pada setiap plantnya.</p>
                                </div>
                            </div>
                            <SAPDailyStockviewPageList />
                        </>
                    }
                ></Route>
            </Route>
        </Routes>
    )
}

export default SAPDailyStockviewView
