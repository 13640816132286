import {KTCard, KTSVG} from '../../../../../../../_metronic/helpers'

interface Props {
  tabs: Array<{
    value: number
    text: string
    total: number
  }>
  currentTab: number
  setCurrentTab: React.Dispatch<React.SetStateAction<number>>
}

const TableTabs = ({tabs, currentTab, setCurrentTab}: Props) => {
  return (
    <>
      <div
        className='d-flex justify-content-between w-100 overflow-auto align-items-center'
        style={{boxShadow: '0px 4px 3px rgba(99, 93, 93, 0.25)'}}
      >
        {tabs.map((tab: any) => (
          <div
            key={tab.value}
            className={`p-5 text-center flex-fill ${
              tab.value === currentTab
                ? 'fw-bold border-info border-3 border-bottom bg-light-info text-info'
                : ''
            } ${!tab.disabled ? 'hover-cursor-pointer' : 'bg-secondary'}`}
            onClick={() => (!tab.disabled ? setCurrentTab(tab.value) : false)}
          >
            {tab.text}{' '}
            {tab.text != 'All' && tab.text != 'Final PR' ? <span className="badge badge-square badge-info">{tab.total}</span> : ''}
          </div>
        ))}
      </div>
    </>
  )
}

export default TableTabs
