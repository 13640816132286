import {getEnv, parseNumberToCurrency, parseNumberToFloat} from '../../../../../../../../helpers/procurex'
import React, { useEffect, useState } from "react";
import TableXWrapper from "../../../../../../../components/shared/TableXWrapper";
import { useAxiosGet, useAxiosPost } from "../../../../../../../../hooks/useAxios";
import { toast } from "react-toastify";
import PreviewAttachment from "../../../../../../../components/PreviewAttachment";
import { useIntl } from "react-intl";
import axios from "axios";
import EvaluasiAdministrasi from "../../EvaluasiAdministrasi";
import EvaluasiTeknis from "../../EvaluasiTeknis";

const StatusBadgePassedFailed = ({ statusValue }: any) => {
  return (
    <span
      className={`badge ${
        statusValue === "PASSED" ? "bg-success" : "bg-danger"
      } text-white`}
    >
      {statusValue}
    </span>
  );
}
// const ProsesEvaluasiAdministrasi = ({ proposalTender, quotation, onChangeEvaluationData }: any) => {
//   const [syarats, setSyarats] = useState( []);
//   const [isCheckAll, setIsCheckAll] = useState(false);
//   const [isUncheckAll, setIsUncheckAll] = useState(false);
//   const [previewAttachment, setPreviewAttachment] = useState( null);
//   const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosGet(
//     getEnv("tender") + `/evaluation/administration/${proposalTender?.uuid}/${quotation?.vendor?.uuid}`
//   )
//   const  [{ doSubmit: doSubmitSave, isLoading: isLoadingSave }] = useAxiosPost(
//     getEnv("tender") + `/evaluation/administration/syarat`
//   )
//   const [renderKey, setRenderKey] = useState(0);
//
//   const updateSyarat = (index:number, state:string) => {
//     const newSyarats = syarats.map((syarat:any, i:number)=>{
//       if(i === index){
//         syarat.status = state;
//       }
//       return syarat;
//     })
//     // @ts-ignore
//     setSyarats(newSyarats)
//   }
//
//   const saveSyarat = () => {
//     // @ts-ignore
//     const requirements = [];
//     syarats.forEach((syarat:any)=>{
//       requirements.push({
//         id: syarat.uuid,
//         evaluation: syarat.status === "PASSED",
//         description: ""
//       })
//     })
//     const payload = {
//       proposal_tender_vendor_id: quotation?.vendor?.uuid,
//       // @ts-ignore
//       requirements: requirements
//     }
//     doSubmitSave(payload, getEnv("tender") + `/evaluation/administration/syarat/${proposalTender?.uuid}`, toast, () => {}).then((res: any) => {
//       console.log(res)
//     });
//   }
//
//   const handleCheckAll = (e:any) => {
//     if(e.target.checked){
//       setIsCheckAll(true);
//       setIsUncheckAll(false);
//       const newSyarats = syarats.map((syarat:any)=>{
//         syarat.status = "PASSED";
//         return syarat;
//       })
//       // @ts-ignore
//       setSyarats(newSyarats)
//     }
//   }
//
//   const handleUncheckAll = (e:any) => {
//     if(e.target.checked){
//       setIsCheckAll(false);
//       setIsUncheckAll(true);
//       const newSyarats = syarats.map((syarat:any)=>{
//         syarat.status = "FAILED";
//         return syarat;
//       })
//       // @ts-ignore
//       setSyarats(newSyarats)
//     }
//   }
//
//   const isFormEnabled = () => {
//     const allowedStatus = [
//       "Evaluasi Administrasi",
//       "Evaluasi Teknikal",
//     ]
//     return allowedStatus.includes(proposalTender?.status) && !isLoadingSave
//   }
//
//   useEffect(() => {
//       doSubmit({}, undefined, undefined, () => {
//       }).then((res: any) => {
//         console.log(res.data)
//         setSyarats(res.data)
//       }).catch((err: any) => {
//         toast.error("Get Syarat: " + err?.response?.data?.meta?.message)
//       });
//   }, []);
//
//   useEffect(() => {
//     if(syarats?.length > 0 && renderKey > 0) saveSyarat()
//     if(syarats?.length > 0) setRenderKey(renderKey + 1)
//   }, [syarats]);
//   return (
//     <div className={"mb-10"}>
//       <h3>Proses Evaluasi Administrasi</h3>
//
//         <table className={"table table-bordered table-striped table-header-gray"}>
//         <thead>
//         <tr>
//           <th className={"text-center border"} rowSpan={2}><strong>No</strong></th>
//           <th className={"text-left border"} rowSpan={2}><strong>Deskripsi</strong></th>
//           <th className={"text-center border"} rowSpan={2}><strong>Mandatory</strong></th>
//           <th className={"text-left border"} rowSpan={2}><strong>Dokumen</strong></th>
//           <th className={"text-center border"} colSpan={2}><strong>Hasil Evaluasi</strong></th>
//         </tr>
//         <tr>
//           <th className={"text-center"}>
//             <input type={"checkbox"} className={'form-check-input'} checked={isCheckAll} disabled={!isFormEnabled()} onChange={handleCheckAll}/> Semua
//           </th>
//           <th className={"text-center"}>
//             <input type={"checkbox"} className={'form-check-input'} checked={isUncheckAll} disabled={!isFormEnabled()} onChange={handleUncheckAll} /> Semua
//           </th>
//         </tr>
//         </thead>
//         <tbody>
//         {syarats.map((syarat: any, index: number) => {
//           return (
//             <tr key={'evaluasi-administrasi'+index}>
//               <td className={"text-center border"}>{index + 1}</td>
//               <td className={"text-left border"}>{syarat.description}</td>
//               <td className={"text-center border"}>{syarat?.required_attachment?'Ya':'Tidak'}</td>
//               <td className={"text-left border"}>
//                 {syarat?.media_url && (
//                   <div className={"input-group input-group-sm"}>
//                     <input type={"text"} className={"form-control form-control-sm"} value={syarat?.description} disabled={true} />
//                     <button type={"button"} className={"btn btn-primary btn-sm"} disabled={isLoading} onClick={()=>setPreviewAttachment(
//                       syarat?.media_url
//                     )}>
//                       <i className={"fa fa-eye"}></i>
//                     </button>
//                   </div>
//                 )}
//                 {!syarat?.media_url &&
//                   <div className={"text-gray"} style={{
//                     fontStyle: 'italic',
//                     color: '#a1a5b7'
//                   }}><i className={"fa fa-file"}></i> Belum ada file tersedia.</div>
//                 }
//               </td>
//               <td className={"border text-center"}>
//                 <div className={"d-flex align-items-center justify-content-center gap-2"}>
//                   {isLoadingSave && (
//                     <i className={"fa fa-spinner fa-spin"}></i>
//                   )}
//                   <input type={"checkbox"} className={'form-check-input'} checked={syarat?.status === 'PASSED'} disabled={!isFormEnabled()} onChange={(e:any)=>{
//                     updateSyarat(index, "PASSED")
//                   }} /> Diterima
//                 </div>
//               </td>
//               <td className={"border text-center"}>
//                 <div className={"d-flex align-items-center justify-content-center gap-2"}>
//                   {isLoadingSave && (
//                     <i className={"fa fa-spinner fa-spin"}></i>
//                   )}
//                   <input type={"checkbox"} className={'form-check-input'} checked={syarat?.status === 'FAILED'} disabled={!isFormEnabled()} onChange={(e:any)=>{
//                     updateSyarat(index, "FAILED")
//                   }}  /> Tidak Diterima
//                 </div>
//               </td>
//             </tr>
//           );
//         })}
//         </tbody>
//       </table>
//       <NoteAttachmentEvaluation
//         proposalTender={proposalTender}
//         quotation={quotation}
//         onChange={onChangeEvaluationData}
//         fieldKey={"evaluation_administration"}
//       />
//       <PreviewAttachment previewExt={previewAttachment} onHide={setPreviewAttachment} />
//     </div>
//   );
// };

// const ProsesEvaluasiTeknis = ({ proposalTender, quotation, onChange, onChangeEvaluationData }: any) => {
//   const [dataItems, setDataItems] = useState<any>([]);
//   const [isCheckAll, setIsCheckAll] = useState(false);
//   const [isUncheckAll, setIsUncheckAll] = useState(false);
//   const [selectedVendor, setSelectedVendor] = useState( null);
//   const [scorePaketSistemNilai, setScorePaketSistemNilai] = useState( 0);
//   const [statusEvaluationTechnical, setStatusEvaluationTechnical] = useState( "NOT-EVALUATE" );
//   const [debounceTimer, setDebounceTimer] = useState<any>(null);
//   const [isMountedSistemNilai, setIsMountedSistemNilai] = useState(false);
//
//   const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosGet(
//     getEnv("tender") + `/evaluation/item/${proposalTender?.uuid}/${quotation?.vendor?.uuid}`
//   )
//
//   const  [{ doSubmit: doSubmitSave, isLoading: isLoadingSave }] = useAxiosPost(
//     getEnv("tender") + `/evaluation/administration/syarat`
//   )
//
//   const debounce = (func: any, delay: number) => {
//     if (debounceTimer) {
//       clearTimeout(debounceTimer);
//     }
//     const timer = setTimeout(func, delay);
//     setDebounceTimer(timer);
//   };
//
//   const handleOnCheck = (index:number, state: boolean) => {
//     const newItems = dataItems.map((item:any, i:number)=>{
//       if(i === index){
//         item.technical_status = state;
//         if(isPaketSistemGugur()) submitIfPaketSistemGugur(state)
//         if(isItemizeSistemGugur()) submitIfItemizeSistemGugur(item, state, "-")
//       }
//       return item;
//     })
//     // @ts-ignore
//     setDataItems(newItems)
//   }
//
//   const handleOnScoreChange = (score: number) => {
//     setScorePaketSistemNilai(score)
//   }
//
//   const isFormEnabled = () => {
//     const allowedStatus = [
//       "Evaluasi Administrasi",
//       "Evaluasi Teknikal",
//     ]
//     return allowedStatus.includes(proposalTender?.status) && !isLoadingSave
//   }
//
//   const submitIfPaketSistemGugur = (state:boolean) => {
//     setStatusEvaluationTechnical(state ? "PASSED" : "FAILED")
//     doSubmitSave({
//       proposal_tender_vendor_id: quotation?.vendor?.uuid,
//       evaluation: state
//     }, getEnv("tender") + `/evaluation/technical/item/${proposalTender?.uuid}`,
//       toast, () => {}).then((res: any) => {})
//   }
//
//   const submitIfItemizeSistemGugur = (item:any, state:boolean, notes:string) => {
//     const payload = {
//       proposal_tender_vendor_id: quotation?.vendor?.uuid,
//       quotation_item_id: item?.uuid,
//       evaluation: state,
//       note: notes
//     }
//     doSubmitSave(payload, getEnv("tender") + `/evaluation/technical/item/${proposalTender?.uuid}`,
//       toast, () => {}).then((res: any) => {
//       dataItems.forEach((item2:any)=>{
//         if(item2?.uuid === item?.uuid){
//           item.technical_status = state ? "PASSED" : "FAILED";
//         }
//       })
//       onChange && onChange()
//     })
//   }
//
//   const submitIfPaketSistemNilai = () => {
//     doSubmitSave({
//         proposal_tender_vendor_id: quotation?.vendor?.uuid,
//         score: (scorePaketSistemNilai)
//       }, getEnv("tender") + `/evaluation/technical/item/${proposalTender?.uuid}`,
//       toast, () => {}).then((res: any) => {
//         onChange && onChange()
//     })
//   }
//
//   const submitIfItemizeSistemNilai = (item:any, score:any, notes:string) => {
//     console.log("submitIfItemizeSistemNilai",item,score)
//   }
//
//   const handleOnChangeScoreItemizeSistemNilai = (item:any, score:any) => {
//       const payload = {
//         proposal_tender_vendor_id: quotation?.vendor?.uuid,
//         quotation_item_id: item?.uuid,
//         score: score,
//         note: "-"
//       }
//       doSubmitSave(payload, getEnv("tender") + `/evaluation/technical/item/${proposalTender?.uuid}`, toast, () => {}).then((res: any) => {
//         // dataItems.forEach((item2:any)=>{
//         //   if(item2?.uuid === item?.uuid){
//         //     item.score_technical = score;
//         //   }
//         // })
//         onChange && onChange()
//       })
//   }
//
//   const handleCheckAll = (e:any) => {
//
//   }
//
//   const handleUncheckAll = (e:any) => {
//
//   }
//
//   const isPaketSistemGugur = () => {
//     return proposalTender?.order_placement === "Paket" &&
//       proposalTender?.metode_evaluasi === "Sistem Gugur";
//   }
//
//   const isPaketSistemNilai = () => {
//     return proposalTender?.order_placement === "Paket" &&
//       proposalTender?.metode_evaluasi === "Sistem Nilai";
//   }
//
//   const isItemizeSistemGugur = () => {
//     return proposalTender?.order_placement === "Itemize" &&
//       proposalTender?.metode_evaluasi === "Sistem Gugur";
//   }
//
//   const isItemizeSistemNilai = () => {
//     return proposalTender?.order_placement === "Itemize" &&
//       proposalTender?.metode_evaluasi === "Sistem Nilai";
//   }
//
//   // Get selected vendor status based on quotation and proposal tender
//   const getSelectedVendor = () => {
//     axios
//       .get(getEnv("tender") + `/dur-vendor?filter[proposal_tender_id]=${proposalTender?.id}&perPage=1000`)
//       .then((res) => {
//         const _selectedVendor = res.data.data?.find((vendor:any)=>{
//           return (vendor?.vendor_id).toString() === (quotation?.vendor_id).toString()
//         })
//         setSelectedVendor(_selectedVendor)
//         setStatusEvaluationTechnical(_selectedVendor?.status_evaluation_technical)
//         setScorePaketSistemNilai(_selectedVendor?.score)
//         console.log(">",_selectedVendor)
//       })
//   }
//
//   const getItems = () => {
//     doSubmit({}, undefined, undefined, () => {}).then((res: any) => {
//       const _items = res.data;
//       // jika paket sistem gugur, maka default technical_status ambil dari status vendor di proposal tender
//       if(isPaketSistemGugur()){
//         _items.forEach((item:any)=>{
//           // @ts-ignore
//           item.technical_status = selectedVendor?.status_evaluation_technical === "PASSED"
//         })
//       }
//       setDataItems(_items)
//     }).catch((err:any)=>{
//       toast.error("Get Evaluasi Teknis: "+err?.response?.data?.meta?.message)
//     });
//   }
//
//   useEffect(() => {
//     getItems();
//     getSelectedVendor();

//   }, []);
//
//   useEffect(() => {
//     if(isPaketSistemNilai() && isMountedSistemNilai){
//       debounce(()=>{
//         submitIfPaketSistemNilai()
//       }, 500)
//     }
//     setTimeout(()=>{
//       setIsMountedSistemNilai(true)
//     }, 500)
//   },[scorePaketSistemNilai]);
//
//   return (
//     <div className={"mb-10"}>
//       <h3>Proses Evaluasi Teknis</h3>
//       <div className={"table-responsive"}>
//         <table className={"table table-bordered table-striped table-header-gray"}>
//           <thead>
//           <tr>
//             <th
//               className={"text-center border px-2"}
//               style={{ verticalAlign: "middle", position: 'sticky', left: 0, zIndex: 1,  }}
//               rowSpan={2}
//             >
//               <strong>&nbsp;No</strong>
//             </th>
//             <th className={"border align-items-start"} style={{ verticalAlign: "middle", minWidth: '250px', position: 'sticky', left: 30, zIndex: 1,  }} rowSpan={2}>
//               <strong>Deskripsi</strong></th>
//             <th className={"border text-center"} colSpan={4}><strong>Permintaan Buyer</strong></th>
//             <th className={"border text-center"} colSpan={7}><strong>Penawaran Vendor</strong></th>
//             <th
//               className={"border text-center text-nowrap px-2"}
//               style={{ verticalAlign: "middle", position: 'sticky', right: 0, zIndex: 1,  }}
//               colSpan={(isPaketSistemGugur() || isItemizeSistemGugur())  ? 2 : 1}
//               rowSpan={isPaketSistemNilai() || isItemizeSistemNilai() ? 2 : 1}
//             >
//                 <strong>
//                   {proposalTender?.metode_evaluasi === "Sistem Nilai" ? "Nilai Evaluasi" : "Hasil Evaluasi"}
//                 </strong>
//             </th>
//           </tr>
//           <tr>
//             <th className={"border px-2"}>Spesifikasi</th>
//             <th className={"border text-end"}>QTY</th>
//             <th className={"border"}>UOM</th>
//             <th className={"border text-nowrap"}>Mata Uang</th>
//             <th className={"border text-nowrap"}>Comply/Deviate</th>
//             <th className={"border"} style={{
//               minWidth: '250px'
//             }}>Deskripsi</th>
//             <th className={"border"}>Spesifikasi</th>
//             <th className={"border"}>QTY</th>
//             <th className={"border"}>UOM</th>
//             <th className={"border text-nowrap"}>Mata Uang</th>
//             <th className={"border"}>Catatan</th>
//             {(isPaketSistemGugur() || isItemizeSistemGugur()) && (
//               <>
//                 <th
//                   className={"text-center text-nowrap border"}
//                   style={{ verticalAlign: "middle", position: 'sticky', right: 125, zIndex: 1 }}
//                 >
//                 </th>
//                 <th
//                   className={"text-center text-nowrap border"}
//                   style={{ verticalAlign: "middle", position: 'sticky', right: 0, zIndex: 1 }}
//                 >
//                 </th>
//               </>
//             )}
//
//           </tr>
//           </thead>
//           <tbody>
//           {dataItems.map((item: any, index: number) => {
//             return(
//               <tr key={"items-"+item?.uuid}>
//                 <td className={"text-center border"} style={{position: 'sticky', left: 0, zIndex: 1, backgroundColor: '#fff'}}>{index + 1}</td>
//                 <td className={"border"} style={{position: 'sticky', left: 30, zIndex: 1, backgroundColor: '#fff'}}>{item?.short_text}</td>
//                 <td className={"border"} style={{
//                   minWidth: '250px',
//                 }}>
//                   <div style={{
//                     maxHeight: '100px',
//                     overflow: 'auto',
//                   }}>
//                     {item?.specification}
//                   </div>
//                 </td>
//                 <td className={"border text-end"}>{parseNumberToFloat(item?.quantity)}</td>
//                 <td className={"border"}>{item?.uom?.uom}</td>
//                 <td className={"border text-center text-nowrap"}>{item?.currency?.code}</td>
//                 <td className={"border text-center text-nowrap"}>
//                   <strong>{item?.quote}</strong>
//                 </td>
//                 <td className={"border"}>{item?.short_text}</td>
//                 <td className={"border"}>{item?.spesification}</td>
//                 <td className={"border text-end"}>{parseNumberToFloat(item?.quantity)}</td>
//                 <td className={"border"}>{item?.uom?.uom}</td>
//                 <td className={"border text-nowrap"}>{item?.currency?.code}</td>
//                 <td className={"border text-nowrap text-end"}>{item?.note}</td>
//                 {(isPaketSistemGugur() && index === 0) && (
//                   <>
//                     <td
//                       className={"border text-center text-nowrap px-2"}
//                       rowSpan={dataItems.length}
//                       style={{ verticalAlign: "middle", position: 'sticky', right: 125, zIndex: 1, backgroundColor: '#f8fafb' }}
//                     >
//                       {isLoadingSave && (
//                         <i className={"fa fa-spinner fa-spin"}></i>
//                       )}
//                       <input type={"checkbox"} className={'form-check-input'} checked={statusEvaluationTechnical === "PASSED"} disabled={!isFormEnabled()} onChange={(e:any)=>{
//                         handleOnCheck(index, true)
//                       }} /> Diterima
//                     </td>
//                     <td
//                       className={"border text-center text-nowrap px-2"} rowSpan={dataItems.length}
//                       style={{ verticalAlign: "middle", position: 'sticky', right: 0, zIndex: 1, backgroundColor: '#f8fafb' }}
//                     >
//                       {isLoadingSave && (
//                         <i className={"fa fa-spinner fa-spin"}></i>
//                       )}
//                       <input type={"checkbox"} className={'form-check-input'} checked={statusEvaluationTechnical === "FAILED"} disabled={!isFormEnabled()} onChange={(e:any)=>{
//                         handleOnCheck(index, false)
//                       }} /> Tidak Diterima
//                     </td>
//                   </>
//                 )}
//                 {(isPaketSistemNilai() && index === 0) && (
//                   <td className={"border text-center align-start text-nowrap px-2"} rowSpan={dataItems.length} style={{ verticalAlign: "middle", position: 'sticky', right: 0, zIndex: 1,  }}>
//                     {isLoadingSave && (
//                       <i className={"fa fa-spinner fa-spin"}></i>
//                     )}
//
//                     <InputNumber
//                       defaultValue={dataItems[index]?.score_technical_evaluation}
//                       min={0} max={100}
//                       className={"w-100 text-end"}
//                       disabled={isLoadingSave}
//                       onChange={(value:any)=>{
//                         handleOnScoreChange(value)
//                       }}
//                     />
//                   </td>
//                 )}
//                 {isItemizeSistemGugur() && (
//                   <>
//                     <td
//                       className={"border text-center text-nowrap px-2"}
//                       style={{ verticalAlign: "middle", position: 'sticky', right: 125, zIndex: 1, backgroundColor: '#f8fafb' }}
//                     >
//                       {isLoadingSave && (
//                         <i className={"fa fa-spinner fa-spin"}></i>
//                       )}
//                       <input type={"checkbox"} className={'form-check-input'} checked={item?.technical_status === "PASSED"} disabled={!isFormEnabled()} onChange={(e:any)=>{
//                         handleOnCheck(index, true)
//                       }} /> Diterima
//                     </td>
//                     <td
//                       className={"border text-center text-nowrap px-2"}
//                       style={{ verticalAlign: "middle", position: 'sticky', right: 0, zIndex: 1, backgroundColor: '#f8fafb' }}
//                     >
//                       {isLoadingSave && (
//                         <i className={"fa fa-spinner fa-spin"}></i>
//                       )}
//                       <input type={"checkbox"} className={'form-check-input'} checked={item?.technical_status === "FAILED"} disabled={!isFormEnabled()} onChange={(e:any)=>{
//                         handleOnCheck(index, false)
//                       }} /> Tidak Diterima
//                     </td>
//                   </>
//                 )}
//                 {(isItemizeSistemNilai()) && (
//                   <td className={"border text-center align-start text-nowrap px-2"} style={{ verticalAlign: "middle", position: 'sticky', right: 0, zIndex: 1,  }}>
//                     {isLoadingSave && (
//                       <i className={"fa fa-spinner fa-spin"}></i>
//                     )}
//                     <InputNumber
//                       defaultValue={dataItems[index]?.score_technical_evaluation}
//                       min={0} max={100}
//                       className={"w-100 text-end"}
//                       disabled={isLoadingSave}
//                       onChange={(value:any)=>{
//                         debounce(()=>{
//                           handleOnChangeScoreItemizeSistemNilai(item, value)
//                         }, 500)
//                       }}
//                     />
//                   </td>
//                 )}
//               </tr>
//             )
//           })}
//           </tbody>
//         </table>
//       </div>
//       <NoteAttachmentEvaluation
//         proposalTender={proposalTender}
//         quotation={quotation}
//         onChange={onChangeEvaluationData}
//         fieldKey={"evaluation_technical"}
//       />
//     </div>
//   );
// };

const ProsesEvaluasiKomersil = ({ proposalTender, quotation, onChange }: any) => {
  const [syarats, setSyarats] = useState( []);
  const [dataItems, setDataItems] = useState( []);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isUncheckAll, setIsUncheckAll] = useState(false);
  const [renderKey, setRenderKey] = useState(0);

  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosGet(
    getEnv("tender") + `/evaluation/item/${proposalTender?.uuid}/${quotation?.vendor?.uuid}`
  )

  const  [{ doSubmit: doSubmitSave, isLoading: isLoadingSave }] = useAxiosPost(
    getEnv("tender") + `/evaluation/administration/syarat`
  )

  const getSyarats = () => {
    doSubmit({},
      getEnv('tender') +`/evaluation/commercial/${proposalTender?.uuid}/${quotation?.vendor?.uuid}`,
      undefined, () => {}
    ).then((res: any) => {
      setSyarats(res.data)
    }).catch((err:any)=>{
    });
  }


  const getItems = () => {
    doSubmit({}, undefined, undefined, () => {}).then((res: any) => {
      const _items = res.data;
      setDataItems(_items)
    }).catch((err:any)=>{
      toast.error("Get Evaluasi Teknis: "+err?.response?.data?.meta?.message)
    });
  }

  const updateSyarat = (index:number, state:string) => {
    const newSyarats = syarats.map((syarat:any, i:number)=>{
      if(i === index){
        syarat.status = state;
      }
      return syarat;
    })
    // @ts-ignore
    setSyarats(newSyarats)
  }

  const saveSyarat = () => {
    if(proposalTender?.order_placement === "Paket"){
      submitSyaratIfPaket()
    } else if(proposalTender?.order_placement === "Itemize"){
      submitSyaratIfItemize()
    }
  }

  const submitSyaratIfPaket = () => {
    // @ts-ignore
    const requirements = [];
    let isAllPassed = true;
    syarats.forEach((syarat:any)=>{
      requirements.push({
        id: syarat.uuid,
        evaluation: syarat.status === "PASSED",
        description: ""
      })
      if(syarat.status !== "PASSED") isAllPassed = false;
    })

    // @ts-ignore
    const payload = {
      proposal_tender_vendor_id: quotation?.vendor?.uuid,
      // @ts-ignore
      requirements: requirements
    }

    const isNeedEvaluateCommercial = proposalTender?.metode_evaluasi === "Sistem Gugur";

    doSubmitSave(payload, getEnv("tender") + `/evaluation/commercial/syarat/${proposalTender?.uuid}`, !isNeedEvaluateCommercial ? toast : undefined, () => {}).then((res: any) => {

      // submit commercial jika sistem gugur
      if(isNeedEvaluateCommercial){
        const payloadCommercialEvaluation = {
          proposal_tender_vendor_id: quotation?.vendor?.uuid,
          evaluation: isAllPassed
        }
        doSubmitSave(payloadCommercialEvaluation, getEnv("tender") + `/evaluation/commercial/item/${proposalTender?.uuid}`, toast, () => {
        }).then((res: any) => {
          onChange && onChange()
        });
      } else {
        onChange && onChange()
      }
    });

  }

  const submitSyaratIfItemize = () => {
    // @ts-ignore
    const requirements = [];
    syarats.forEach((syarat:any)=>{
      requirements.push({
        id: syarat.uuid,
        evaluation: syarat.status === "PASSED",
        description: ""
      })
    })

    // @ts-ignore
    const payload = {
      proposal_tender_vendor_id: quotation?.vendor?.uuid,
      // @ts-ignore
      requirements: requirements
    }

    doSubmitSave(payload, getEnv("tender") + `/evaluation/commercial/syarat/${proposalTender?.uuid}`, toast , () => {}).then((res: any) => {

    });
  }

  const submitEvaluationItemize = (item:any, state:boolean) => {
    const payload = {
      proposal_tender_vendor_id: quotation?.vendor?.uuid,
      quotation_item_id: item?.uuid,
      evaluation: state,
      note: "-"
    }
    doSubmitSave(payload, getEnv("tender") + `/evaluation/commercial/item/${proposalTender?.uuid}`, toast, () => {
      dataItems.forEach((item2:any)=>{
        if(item2?.uuid === item?.uuid){
          item.commercial_status = state ? "PASSED" : "FAILED";
        }
      })
    }).then((res: any) => {
      onChange && onChange()
    });
  }
  const isItemize = () => {
    return proposalTender?.order_placement === "Itemize"
  }
  const isItemizeSistemGugur = () => {
    return proposalTender?.order_placement === "Itemize" &&
      proposalTender?.metode_evaluasi === "Sistem Gugur";
  }

  const isFormEnabled = () => {
    const allowedStatus = [
      "Evaluasi Administrasi",
      "Evaluasi Teknikal",
    ]
    return allowedStatus.includes(proposalTender?.status) && !isLoadingSave
  }

  useEffect(() => {
    getItems();
    getSyarats();
  }, []);

  useEffect(() => {
    if(syarats?.length > 0 && renderKey > 0) saveSyarat()
    if(syarats?.length > 0) setRenderKey(renderKey + 1)
  }, [syarats]);


  return (
    <>
      <h3>Proses Evaluasi Komersil</h3>
      <div className={"table-responsive"}>
        <table className={"table table-bordered table-striped table-header-gray"}>
          <thead>
          <tr>
            <th className={"text-center border text-nowrap"} rowSpan={2} style={{ verticalAlign: "middle", minWidth: '100px', position: 'sticky', left: 0, zIndex: 1,  }}>
              <strong>Nomor PR</strong>
            </th>
            <th className={"text-center border text-nowrap"} rowSpan={2} style={{ verticalAlign: "middle", minWidth: '100px', position: 'sticky', left: 100, zIndex: 1,  }}>
              <strong>Item PR</strong>
            </th>
            <th className={"text-center border text-nowrap"} rowSpan={2} style={{ verticalAlign: "middle" }}>
              <strong>Material No</strong>
            </th>
            <th className={"text-center border"} rowSpan={2} style={{ verticalAlign: "middle", minWidth: '200px' }}>
              <strong>Deskripsi</strong>
            </th>
            <th className={"text-center border"} rowSpan={2} style={{ verticalAlign: "middle", minWidth: '200px' }}>
              <strong>Spesifikasi</strong>
            </th>
            <th className={"text-center border text-nowrap"} rowSpan={2} style={{ verticalAlign: "middle" }}>
              <strong>Mata Uang</strong>
            </th>
            <th className={"text-center border"} rowSpan={2} style={{ verticalAlign: "middle" }}>
              <strong>Satuan</strong>
            </th>
            <th className={"text-end border"} rowSpan={2} style={{ verticalAlign: "middle" }}>
              <strong>Per</strong>
            </th>
            <th className={"text-center border text-nowrap"} colSpan={2}>
              <strong>Permintaan Buyer</strong>
            </th>
            <th className={"text-center border text-nowrap"} colSpan={2}>
              <strong>Penawaran Vendor</strong>
            </th>
            <th className={"text-center border"} rowSpan={2} style={{ verticalAlign: "middle" }}>
              <strong>Quote</strong>
            </th>
            {isItemizeSistemGugur() && (
              <th className={"text-center border px-2"} colSpan={2} rowSpan={2}>
                <strong>Hasil Evaluasi</strong>
              </th>
            )}
          </tr>
          <tr>
            <th className={"text-end border"}>QTY</th>
            <th className={"text-end border text-nowrap"}>Harga Satuan</th>
            <th className={"text-end border"}>QTY</th>
            <th className={"text-end border text-nowrap"}>Harga Satuan</th>
          </tr>
          </thead>
          <tbody>
          {dataItems.map((item: any, index: number) => {
            return (
              <tr key={"item-evaluasi-teknis"+index}>
                <td className={"border px-2"}  style={{ verticalAlign: "middle", position: 'sticky', minWidth: '100px', left: 0, zIndex: 1, backgroundColor: '#fff' }}>{item?.noPr}</td>
                <td className={"border"}  style={{ verticalAlign: "middle", position: 'sticky', minWidth: '100px', left: 100, zIndex: 1, backgroundColor: '#fff' }}>{item?.itemPr}</td>
                <td className={"border"}>{item?.material_no}</td>
                <td className={"border"}>{item?.short_text}</td>
                <td className={"border"}>{item?.spesification}</td>
                <td className={"text-center border"}>{item?.currency?.code}</td>
                <td className={"text-center border"}>{item?.uom?.uom}</td>
                <td className={"text-end border"}>{parseNumberToFloat(item?.per)}</td>
                <td className={"text-end border"}>{parseNumberToFloat(item?.quantity)}</td>
                <td className={"text-end border"}>{parseNumberToCurrency(item?.buyer_valuation_price,item?.currency?.code)}</td>
                <td className={"text-end border"}>{parseNumberToFloat(item?.quantity)}</td>
                <td className={"text-end border"}>{parseNumberToCurrency(item?.valuation_price,item?.currency?.code)}</td>
                <td className={"text-center px-2"}>
                  {item?.quote}
                </td>
                {/* {isItemizeSistemGugur() && (
                  <>
                    <td className={"text-center border text-nowrap px-2"}>
                      <input type={"checkbox"} className={'form-check-input'} checked={item?.commercial_status === "PASSED"} disabled={!isFormEnabled()} onChange={(e:any)=>{
                        submitEvaluationItemize(item, true)
                      }} /> Diterima
                    </td>
                    <td className={"text-center border text-nowrap px-2"}>
                      <input type={"checkbox"} className={'form-check-input'} checked={item?.commercial_status === "FAILED"} disabled={!isFormEnabled()} onChange={(e:any)=>{
                        submitEvaluationItemize(item, false)
                      }} /> Tidak Diterima
                    </td>
                  </>
                )} */}
              </tr>
            )
          })}
          </tbody>
        </table>
      </div>

      <div className={"table-responsive mt-10"}>
        <table className={"table table-bordered table-header-gray"}>
          <thead>
          <tr>
            <th className={"text-center border"}><strong>No</strong></th>
            <th className={"text-center border"}><strong>Deskripsi</strong></th>
            <th className={"text-center border"}><strong>Mandatory</strong></th>
            <th className={"text-center border"}><strong>Dokumen</strong></th>
            <th className={"text-center border"}><strong>Hasil Evaluasi</strong></th>
          </tr>
          </thead>
          <tbody>
          {syarats.length === 0 && (
            <tr>
              <td className={"text-center border"} colSpan={5}>Tidak ada data.</td>
            </tr>
          )}
          {syarats.map((syarat: any, index: number) => {
            return (
              <tr key={'evaluasi-kommersial'+index}>
                <td className={"text-center border"}>{index + 1}</td>
                <td className={"text-left border"}>{syarat.description}</td>
                <td className={"text-center border"}>{syarat.required_attachment?'Ya':'Tidak'}</td>
                <td className={"border text-center"}>
                  <div className={"d-flex align-items-center justify-content-center gap-2"}>
                    {isLoadingSave && (
                      <i className={"fa fa-spinner fa-spin"}></i>
                    )}
                    <input type={"checkbox"} className={'form-check-input'} checked={syarat?.status === 'PASSED'} disabled={!isFormEnabled()} onChange={(e:any)=>{
                      updateSyarat(index, "PASSED")
                    }} /> Diterima
                  </div>
                </td>
                <td className={"border text-center"}>
                  <div className={"d-flex align-items-center justify-content-center gap-2"}>
                    {isLoadingSave && (
                      <i className={"fa fa-spinner fa-spin"}></i>
                    )}
                    <input type={"checkbox"} className={'form-check-input'} checked={syarat?.status === 'FAILED'} disabled={!isFormEnabled()} onChange={(e:any)=>{
                      updateSyarat(index, "FAILED")
                    }}  /> Tidak Diterima
                  </div>
                </td>
              </tr>
            );
          })}
          </tbody>
        </table>
      </div>
    </>
  );
};

const InformasiRekanan = ({ quotation, proposalTender }: any) => {
  const [expiredAt, setExpiredAt] = useState<any>("");
  const [bidBondAttachment,setBidBondAttachment] = useState(null)

  const getExpiredAt = () => {
    const date = new Date(quotation?.date);
    date.setDate(date.getDate() + quotation?.validity);
    const expiredAt = date.toISOString().slice(0,10);
    setExpiredAt(expiredAt)
  }

  useEffect(() => {
    getExpiredAt();
  }, [quotation]);

  return (
    <>
      <div className={"row"}>
        <div className={"col-sm-12 col-md-6"}>
          <div className={"form-group mb-3"}>
            <label htmlFor="">Nama Rekanan</label>
            <input type="text" className={"form-control form-control-sm"} value={quotation?.vendor?.vendor?.name} disabled={true} />
          </div>
          <div className={"form-group mb-3"}>
            <label htmlFor="">Nomor Penawaran</label>
            <input type="text" className={"form-control form-control-sm"} value={quotation?.quotation_number} disabled={true} />
          </div>
          <div className={"form-group mb-3"}>
            <label htmlFor="">Tanggal Penawaran</label>
            <input type="text" className={"form-control form-control-sm"} value={quotation?.date} disabled={true} />
          </div>
          <div className={"form-group mb-3"}>
            <label htmlFor="">Berlaku Hingga Tanggal</label>
            <input type="text" className={"form-control form-control-sm"} value={expiredAt} disabled={true} />
          </div>
          <div className={"form-group mb-3"}>
            <label htmlFor="">Masa Berlaku Penawaran</label>
            <div className={"input-group"}>
              <input type="text" className={"form-control form-control-sm"} value={quotation?.validity} disabled={true} />
              <div className="input-group-append">
                <span className="input-group-text">Hari</span>
              </div>
            </div>
          </div>
        </div>
        <div className={"col-sm-12 col-md-6"}>
          <div className={"row mb-3"}>
            <div className={"col-md-4"}>
              <div className={"form-group mb-3"}>
                <label htmlFor="">Incoterm</label>
                <input type="text" className={"form-control form-control-sm"} value={quotation?.incoterm?.code} disabled={true} />
              </div>
            </div>
            <div className={"col-md-8"}>
              <div className={"form-group mb-3"}>
                <label htmlFor="">Deskripsi Incoterm</label>
                <input type="text" className={"form-control form-control-sm"} value={quotation?.incoterm?.name} disabled={true} />
              </div>
            </div>
          </div>
          <div className={"form-group mb-3"}>
            <label htmlFor="">Nilai Jaminan Penawaran</label>
            {/*<input type="text" className={"form-control  form-control-sm"}*/}
            {/*       value={parseNumberToCurrency(quotation?.bid_bond_value)} disabled={true} />*/}
            <div className={"input-group"}>
              <input type="text" className={"form-control  form-control-sm"}
                     value={parseNumberToCurrency(quotation?.bid_bond_value)} disabled={true} />
              <div className="input-group-append">
                <button
                  className={"btn btn-primary btn-sm"}
                  type={"button"}
                  onClick={() => {
                    setBidBondAttachment(quotation?.bid_bond_media_url);
                  }}
                >
                  <i className={"fa fa-eye"}></i>
                </button>
              </div>
              <PreviewAttachment previewExt={bidBondAttachment} onHide={() => {
                setBidBondAttachment(null);
              }} />
            </div>
          </div>
          <div className={"form-group mb-3"}>
            <label htmlFor="">Jaminan Penawaran Berlaku Hingga</label>
            <input type="text" className={"form-control form-control-sm"} value={quotation?.bid_bond_masa_berlaku}
                   disabled={true} />
          </div>
          <div className={"form-group mb-3"}>
            <label htmlFor="">Additional Cost</label>
            <input type="text" className={"form-control form-control-sm"} value={""} disabled={true} />
          </div>
        </div>
      </div>
    </>
  );
};

const HasilAkhirEvaluasi = ({ formIndex, proposalTender, quotation }: any) => {
  const [dataItems, setDataItems] = useState([]);
  const [currentProposalTender, setCurrentProposalTender] = useState(null);
  const [selectedVendor, setSelectedVendor] = useState(proposalTender);
  const [statusEvaluation, setStatusEvaluation] = useState("NOT-EVALUATE");
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosGet(
    getEnv("tender") + `/evaluation/item/${proposalTender?.uuid}/${quotation?.vendor?.uuid}`
  )
  const [summary, setSummary] = useState( null );
  const [nilaiTeknis, setNilaiTeknis] = useState(0);
  const [nilaiKomersil, setNilaiKomersil] = useState(0);
  const [nilaiAmbangBatas, setNilaiAmbangBatas] = useState(0);
  const [nilaiAkhir, setNilaiAkhir] = useState(0);

  const getItems = () => {
    doSubmit({}, undefined, undefined, () => {}).then((res: any) => {
      const _items = res.data;
      setDataItems(_items)
    }).catch((err:any)=>{
      toast.error("Get Evaluasi Teknis: "+err?.response?.data?.meta?.message)
    });
  }

  const getSelectedVendor = () => {
    // @ts-ignore
    axios
      .get(getEnv("tender") + `/dur-vendor?filter[proposal_tender_id]=${proposalTender?.id}&perPage=1000`)
      .then((res) => {
        const _selectedVendor = res.data.data?.find((vendor:any)=>{
          return (vendor?.vendor_id).toString() === (quotation?.vendor_id).toString()
        })
        setStatusEvaluation(_selectedVendor?.status_evaluation_technical)
        setSelectedVendor(_selectedVendor)
        setNilaiAkhir(_selectedVendor?.score)
      })
  }

  const getNilaiTeknisKomersil = () => {
    // @ts-ignore
    const configuration = currentProposalTender?.syarats?.find((syarat:any)=> syarat?.type === "configuration")
    if(configuration) {
      setNilaiTeknis(configuration?.bobot_teknis)
      setNilaiKomersil(configuration?.bobot_komersial)
      setNilaiAmbangBatas(configuration?.ambang_batas)
    }
  }

  /**
   * paket, ambil dari proposalTender?.vendors?.evaluation_technical
   */
  const checkIsPaketSistemGugur = () => {
    return proposalTender?.order_placement === "Paket" &&
      proposalTender?.metode_evaluasi === "Sistem Gugur"
  }

  const checkIsPaketSistemNilai = () => {
    return proposalTender?.order_placement === "Paket" &&
      proposalTender?.metode_evaluasi === "Sistem Nilai"
  }

  const checkIsItemizeSistemGugur = () => {
    return proposalTender?.order_placement === "Itemize" &&
      proposalTender?.metode_evaluasi === "Sistem Gugur"
  }

  const checkIsItemizeSistemNilai = () => {
    return proposalTender?.order_placement === "Itemize" &&
      proposalTender?.metode_evaluasi === "Sistem Nilai"
  }

  const checkIsPaket = () => {
    return proposalTender?.order_placement === "Paket"
  }

  const checkIsItemize = () => {
    return proposalTender?.order_placement === "Itemize"
  }

  const getProposalTender = () => {
    const newUrl = getEnv("tender") + `/tender/${proposalTender?.uuid}`
    doSubmit({}, newUrl, undefined, () => {}).then((res: any) => {
      const _proposalTender = res.data;
      setCurrentProposalTender(_proposalTender)
    }).catch((err:any)=>{
      toast.error("Get Evaluasi Teknis: "+err?.response?.data?.meta?.message)
    });
  }

  useEffect(() => {
    if(formIndex > 0) getProposalTender();
  }, [formIndex]);

  useEffect(() => {
   if(currentProposalTender){
     getItems();
     getSelectedVendor();
     getNilaiTeknisKomersil()
   }
  }, [currentProposalTender]);

  useEffect(() => {
    setCurrentProposalTender(proposalTender)
  }, []);

  return (
    <>
      <h3>Hasil Akhir Evaluasi Teknis</h3>
      <div className={"table-responsive"}>
          <table className={"table table-bordered table-header-gray"}>
            <thead>
            <tr>
              <th className={"text-center border px-2"} style={{
                verticalAlign: "top", position: 'sticky', minWidth: '20px', left: 0, zIndex: 1,
              }}>No</th>
              <th className={"text-start border"} style={{
                verticalAlign: "top", position: 'sticky', minWidth: '200px', left: 30, zIndex: 1,
              }}>Nama Rekanan</th>
              <th className={"text-start border"} style={{
                minWidth: '300px'
              }}>Deskripsi</th>
              {(checkIsPaketSistemGugur() || checkIsItemizeSistemGugur()) && (
                <th className={"text-center border"} colSpan={2}>Kesimpulan</th>
              )}
              {checkIsPaketSistemNilai() && (
                <>
                  <th className={"text-center border text-nowrap"}>
                    Nilai Teknis, Proporsi Bobot
                  </th>
                  <th className={"text-center border text-nowrap"}>
                    Nilai Komersil, Proporsi Bobot
                  </th>
                  <th className={"text-center border text-nowrap"}>
                    Ambang Batas Teknis
                  </th>
                  <th className={"text-center border text-nowrap"}>
                    Nilai Total
                  </th>
                  <th className={"text-center border text-nowrap px-2"}>
                    Kesimpulan
                  </th>
                </>
              )}
              {checkIsItemizeSistemNilai() && (
                <>
                  <th className={"text-center border text-nowrap"}>
                    Nilai Teknis, Proporsi Bobot
                  </th>
                  <th className={"text-center border text-nowrap"}>
                    Nilai Komersil, Proporsi Bobot
                  </th>

                  <th className={"text-center border text-nowrap"}>
                    Nilai Total
                  </th>
                  <th className={"text-center border text-nowrap"}>
                    Kesimpulan
                  </th>
                </>
              )}
            </tr>
            </thead>
            <tbody>
            {dataItems.map((item: any, index: number) => {
              return (
                <tr key={"hasil-akhir-evaluasi"+index}>
                  {index === 0 && (
                    <td className={"border text-center px-2"} style={{ verticalAlign: "top", position: 'sticky', minWidth: '20px', left: 0, zIndex: 1, backgroundColor: '#fff' }} rowSpan={dataItems?.length}>
                      1
                    </td>
                  )}
                  {index === 0 && (
                    <td className={"border px-2"} style={{ verticalAlign: "top", position: 'sticky', minWidth: '200px', left: 30, zIndex: 1, backgroundColor: '#fff' }} rowSpan={dataItems?.length}>
                      {/* @ts-ignore */}
                      {selectedVendor?.vendor?.name}
                    </td>
                  )}
                  <td className={"border px-2"}>{item?.short_text}</td>
                  {(checkIsPaketSistemGugur() && index === 0) && (
                    <>
                      <td className={"border text-center"} rowSpan={dataItems?.length}>
                        <input type={"checkbox"} className={'form-check-input'} disabled={true} checked={statusEvaluation === "PASSED"} /> Lolos
                      </td>
                      <td className={"border text-center"} rowSpan={dataItems?.length}>
                        <input type={"checkbox"} className={'form-check-input'} disabled={true} checked={statusEvaluation === "FAILED"} /> Tidak Lolos
                      </td>
                    </>
                  )}

                  {(checkIsPaketSistemNilai() && index === 0) && (
                    <>
                      <td className={"border text-center px-2"} rowSpan={dataItems?.length}>
                        {nilaiTeknis}
                      </td>
                      <td className={"border text-center px-2"} rowSpan={dataItems?.length}>
                        {nilaiKomersil}
                      </td>
                      <td className={"border text-center px-2"} rowSpan={dataItems?.length}>
                        {nilaiAmbangBatas}
                      </td>
                      <td className={"border text-center px-2"} rowSpan={dataItems?.length}>
                        {nilaiAkhir}
                      </td>
                      <td className={"border text-center px-2 text-nowrap"} rowSpan={dataItems?.length}>
                        <StatusBadgePassedFailed status={statusEvaluation} />
                      </td>
                    </>
                  )}

                  {(checkIsItemizeSistemGugur()) && (
                    <>
                      <td className={"border"}>
                        <input type={"checkbox"} className={'form-check-input'} checked={item?.status === "PASSED"} /> Lolos
                      </td>
                      <td className={"border"}>
                        <input type={"checkbox"} className={'form-check-input'} checked={item?.status !== "PASSED"} /> Tidak Lolos
                      </td>
                    </>
                  )}

                  {(checkIsItemizeSistemNilai()) && (
                    <>
                      <td className={"border text-center"}>
                        {item?.score_technical}
                      </td>
                      <td className={"border text-center"}>
                        {item?.score_commercial}
                      </td>
                      <td className={"border text-center"}>
                        <strong>{item?.score_technical + item?.score_commercial}</strong>
                      </td>
                      <td className={"border text-center"}>
                        <StatusBadgePassedFailed status={item?.status} />
                      </td>
                    </>
                  )}

                </tr>
              )
            })}
            </tbody>
          </table>

      </div>
    </>
  )
}

const DetailPenawaran = ({ proposalTender, quotation, onBack }: any) => {
  const [formIndex, setFormIndex] = useState(0);
  const [{doSubmit: doSubmitPost, isLoading: isLoadingPost}] =  useAxiosPost("");
  const [evaluationData, setEvaluationData] = useState(null as any);
  const [isDisabled, setIsDisabled] = useState(false);
  const intl = useIntl();
  const handleOnChangeEvaluasiKomersil =  () => {
    setFormIndex(formIndex + 1);
  }

  const isProsesEvaluasiKomersilShow = () => {
    const allowedStatus = [
      "1 Sampul",
      "2 Sampul",
    ]
    return allowedStatus.includes(proposalTender?.metode_penyampaian)
  }

  const checkIsDisabled =  () => {
    const allowedStatus = ["Evaluasi Administrasi", "Evaluasi Teknikal", "Evaluasi Komersil"];
    setIsDisabled(!allowedStatus.includes(proposalTender?.status));
  };

  const onChangeEvaluationData = async (field: string, value: any) => {
    setEvaluationData({ ...evaluationData, [field]: value });
  };

  const handleOnSaveEvaluation = async () => {
    console.log(evaluationData);

    // region save evaluation administrasi
    if (evaluationData?.evaluation_administration) {
      // region note
      await doSubmitPost({
        note: evaluationData?.evaluation_administration?.note || "-",
        type: "Evaluasi Administrasi"
      }, getEnv("tender") + `/evaluation/notes/${quotation?.vendor?.uuid}`, undefined);
      // endregion

      //region attachment
      evaluationData?.evaluation_administration?.attachments?.forEach(async (attachment: any) => {
        if(attachment.current_id) return;
        const payload = {
          description: attachment?.media_type?.file_name,
          type: "Evaluasi Administrasi",
          media_type: attachment?.media_type,
          media_url: attachment?.media_url
        }
        await doSubmitPost(payload, getEnv("tender") + `/evaluation/attachment/${quotation?.vendor?.uuid}`, undefined);
      });
      //endregion
    }
    // endregion

    //region save evaluation teknis
    if (evaluationData?.evaluation_technical) {
      await doSubmitPost({
        note: evaluationData?.evaluation_technical?.note || "-",
        type: "Evaluasi Teknikal"
      }, getEnv("tender") + `/evaluation/notes/${quotation?.vendor?.uuid}`, undefined);
      //region attachment
      evaluationData?.evaluation_technical?.attachments?.forEach(async (attachment: any) => {
        if(attachment.current_id) return;
        const payload = {
          description: attachment?.media_type?.file_name,
          type: "Evaluasi Teknikal",
          media_type: attachment?.media_type,
          media_url: attachment?.media_url
        }
        await doSubmitPost(payload, getEnv("tender") + `/evaluation/attachment/${quotation?.vendor?.uuid}`, undefined);
      });
      //endregion
    }
    //endregion

    //region delete attachment
    if (evaluationData?.evaluation_administration_deleted) {
      evaluationData?.evaluation_administration_deleted?.forEach(async (attachment: any) => {
        await doSubmitPost({
          _method: "DELETE"
        }, getEnv("tender") + `/evaluation/attachment/${quotation?.vendor?.uuid}/${attachment}`, undefined);
      });
    }
    if (evaluationData?.evaluation_technical_deleted) {
      evaluationData?.evaluation_technical_deleted?.forEach(async (attachment: any) => {
        await doSubmitPost({
          _method: "DELETE"
        }, getEnv("tender") + `/evaluation/attachment/${quotation?.vendor?.uuid}/${attachment}`, undefined);
      });
    }
    //endregion

    toast.success(intl.formatMessage({ id: "Berhasil menyimpan data" }));
  };


  useEffect(() => {
    checkIsDisabled();
  }, [proposalTender]);

  return (
    <>
      <div className={"row"}>
        <div className={"col-md-6 mb-5"}>
          <button className={"btn btn-sm btn-danger"} type={"button"} onClick={() => onBack()}>
            <i className={"fa fa-arrow-left"}></i> Kembali
          </button>
        </div>
        <div className={"col-md-6"}>
        </div>
        <div className={"col-md-12"}>
          <InformasiRekanan proposalTender={proposalTender} quotation={quotation} />
          <hr />
          <EvaluasiAdministrasi
            proposalTender={proposalTender}
            quotation={quotation}
            onChangeEvaluationData={onChangeEvaluationData}
          />
          {/*<hr />*/}
          <EvaluasiTeknis
            proposalTender={proposalTender}
            quotation={quotation}
            onChange={() => handleOnChangeEvaluasiKomersil()}
            onChangeEvaluationData={onChangeEvaluationData}
          />

          {isProsesEvaluasiKomersilShow() && (
            <>
              <hr />
              <ProsesEvaluasiKomersil
                proposalTender={proposalTender}
                quotation={quotation}
                onChange={() => handleOnChangeEvaluasiKomersil()}
                onChangeEvaluationData={onChangeEvaluationData}
              />
            </>
          )}

          <hr />
          <HasilAkhirEvaluasi formIndex={formIndex} proposalTender={proposalTender} quotation={quotation} />
        </div>
      </div>
    </>
  );
};

const AdministrasiTeknisDuaTahapTab = ({ proposalTender, onQuotationChanged }: any) => {
  const urlIndex = getEnv("tender") + `/evaluation/quotation/${proposalTender.uuid}`;
  const [selectedRows, setSelectedRows] = useState(null);
  const tableColumns = [
    {
      Header: "#",
      accessor: "line_no",
      width: 100,
      className: "text-center",
      Cell: (props: any) => {
        return (
          <>
            <a href={"#"} title={"Lihat Penawaran"} onClick={(e: any) => {
              e.preventDefault();
              setSelectedRows(props.row.original);
            }} className={"btn btn-primary btn-sm"}>
              <i className={"fa fa-eye"}></i>
            </a>
          </>
        );
      }
    },
    { Header: "Vendor ID", accessor: "vendor_id", width: 100, className: "text-start", Cell: (props: any) => {
        return (
          <>
            {props.row.original.vendor_id}
          </>
        )
      }
    },
    { Header: "Nama Vendor", accessor: "vendor.vendor.name", width: 300, className: "text-start"},
    {
      Header: "Status",
      accessor: "vendor.vendor.status_evaluation_technical",
      width: 150,
      className: "text-center",
      Cell: (props: any) => {
        if (props.row.original.vendor.status_evaluation_technical === "FAILED" || props.row.original.vendor.status_evaluation_administration === "FAILED") {
          const status = 'FAILED';
          return (
            <>
              <StatusBadgePassedFailed statusValue={status} />
            </>
          );
        } else {
          const status = props.row.original.vendor.status_evaluation_technical;
          return (
            <>
              <StatusBadgePassedFailed statusValue={status} />
            </>
          );
        }
      }
    },
    { Header: "Quotation Terakhir", accessor: "updated_at", className: "text-center", width: 300 },

  ];

  useEffect(() => {
      onQuotationChanged(selectedRows)
  }, [selectedRows])
  return (
    <>
      {selectedRows && (
        <DetailPenawaran
          proposalTender={proposalTender}
          quotation={selectedRows}
          onBack={() => {
            setSelectedRows(null);
          }}
        />
      )}

      {!selectedRows && (
        <TableXWrapper
          readonly={true}
          title={"Proposal Tender"}
          urlIndex={urlIndex}
          columns={tableColumns}
          isForceCreateButton={true}
          disableUrlChanged={true}
        />
      )}
      {/*{JSON.stringify(proposalTender)}*/}
    </>
  );
};

export {
  AdministrasiTeknisDuaTahapTab,
};