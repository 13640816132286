import { useEffect, useState } from "react";


const PaktaIntegritasAuctionVendorTab = ({ auction }: any) => {
  useEffect(() => {
  }, []);
  return (
    <>
      <h3>Pakta Integritas</h3>
      <p className='py-3' dangerouslySetInnerHTML={{ __html: auction?.petunjuk }}></p>
    </>
  );
};

export { PaktaIntegritasAuctionVendorTab };
