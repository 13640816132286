import {KTCard} from '../../../../../../_metronic/helpers'
import TableTabs from '../../../../mr-sr/request/list/components/header/TableTabs'
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {ToolbarInterface, useProposalTender} from '../core/_proposalTenderProvider'

const ToolbarEvaluasi = () => {
  const intl = useIntl();
  const {proposalTender, toolbarEvaluasi, setToolbarEvaluasi} = useProposalTender()
  const [tabs, setTabs] = useState([
    {
      value: 0,
      text: intl?.formatMessage({id: 'Evaluasi Penawaran'}),
      total: 0,
    },
    {
      value: 1,
      text: intl?.formatMessage({id: 'Evaluasi User'}),
      total: 0,
    },
    {
      value: 2,
      text: intl?.formatMessage({id: 'Klarifikasi Vendor'}),
      total: 0,
    },
  ])

  const checkIsEvaluasiEnabled = () => {
    const allowedStatus = [
      'Evaluasi Administrasi',
      'Negotiation',
      'Laporan Pengadaan',
      'Penawaran Komersil',
      'Evaluasi Komersil',
      'Pengumuman Pemenang',
      'Penunjukan Pemenang',
      'Penawaran Teknikal',
      'Purchase Order'
    ]

    return allowedStatus.includes(proposalTender?.status)
  }

  useEffect(() => {
    setTabs((prevTabs) =>
      prevTabs.map((tab) =>
        tab.value !== 0 ? { ...tab, disabled: !checkIsEvaluasiEnabled() } : tab
      )
    );
  }, [proposalTender]);

  return (
    <KTCard className={'mt-0 mb-1'}>
      <TableTabs
        tabs={tabs}
        currentTab={toolbarEvaluasi?.currentTab}
        setCurrentTab={(tabNumber: any) => {
          // handleTabChange(tabNumber)
          setToolbarEvaluasi((prevValue:ToolbarInterface) => {
            return {
              ...prevValue,
              currentTab: tabNumber
            }
          })

        }}
        hideBadge={true}
      />
    </KTCard>
  )
}

const ToolbarRightLayout = () => {
  const {toolbarEvaluasi} = useProposalTender();
  const isShowEvaluasi = process.env.REACT_APP_IS_EVALUATION_USER === 'true';

  useEffect(() => {
    console.log('ToolbarEvaluasi:', toolbarEvaluasi);
  }, [toolbarEvaluasi])

  return (
    <>
      {(isShowEvaluasi && toolbarEvaluasi.show) && (
        <ToolbarEvaluasi />
      )}
    </>
  )
}

export default ToolbarRightLayout;