import React, {FC, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {useGetModuleUser} from '../../../partials/layout/header-menus/core/hooks'
import {Link, useLocation} from 'react-router-dom'
import ModalContact from '../ModalContact'
import { useAuth } from "../../../../app/modules/auth";
import { ThemeModeComponent } from "../../../assets/ts/layout";
import { darkModeClass } from "../../../../helpers/utils";
const HeaderSelectApps: FC = () => {
  const [isLoadingModule, responseDataModule, responseErrorModule, getDataModule] =
    useGetModuleUser()
  const [isModalAppOpen, setIsModalAppOpen] = useState(false)
  const location = useLocation()
  const [show, setShow] = useState(false)
  const { currentUser } = useAuth();
  // console.log('Location : ', location)

  const handleShortcut = (event:any) => {
    if (event.ctrlKey && event.key === 'm') {
      // Logika yang ingin Anda jalankan saat shortcut dipicu
      setIsModalAppOpen(!isModalAppOpen)
    }
  };

  React.useEffect(() => {
    document.addEventListener('keydown', handleShortcut);
    return () => {
      document.removeEventListener('keydown', handleShortcut);
    };
  }, []);
  return (
    <>
      {location.pathname !== '/dashboard' && (
        <>
          {!currentUser?.has_roles?.includes("vendor") && (
            <a
              href='/'
              className={`fs-6 fw-bold me-4 btn btn-sm btn-light-primary ${darkModeClass()}`}
              title={'Main Dashboard'}
            >
              <i className={`fas fa-fw fa-chevron-left ${darkModeClass()}`}></i>
              Home
            </a>
          )}
          
          <ModalContact show={show} handleClose={() => setShow(false)} disabled={false} />
          <button
            className={`fs-6 fw-bold me-4 btn btn-sm btn-light-primary ${darkModeClass()}`}
            onClick={() => setShow(true)}
          >
            <i className={`bi bi-headset ${darkModeClass()}`}></i>
            <span>Contact Us</span>
          </button>
        </>
      )}
      {!currentUser?.has_roles?.includes("vendor") && (
      <a
        href='#'
        className={`${darkModeClass()}`}
        title={'Shortcut: Ctrl + M'}
        onClick={(e) => {
          e.preventDefault()
          setIsModalAppOpen(true)
        }}
      >
        {/*<i className={`fa fa-app-store fa-brands fs-2 ${darkModeClass()}`}></i>*/}
        <img src={"/media/svg/misc/infography.svg"} className={"w-20px h-20px"} />
      </a>
        )}

      <Modal show={isModalAppOpen} onHide={() => setIsModalAppOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{useIntl().formatMessage({id: 'APP.APPLICATION_LIST'})}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={'row'}>
            {responseDataModule.data &&
              responseDataModule.data.map((v: any) => {
                return (
                  <Link
                    className={'col-12 text-center d-flex flex-row'}
                    key={'modal-module-' + v.id}
                    to={v.path}
                    onClick={() => {
                      setIsModalAppOpen(false)
                      let toggle: any = document.querySelectorAll('#kt_aside_toggle.active')[0]
                      toggle?.click()
                    }}
                  >
                    <KTSVG
                      path='/media/icons/duotune/abstract/abs027.svg'
                      className='svg-icon-4x svg-icon-success'
                    />
                    <h4 className={'mt-4 ms-4'}>{v.name}</h4>
                  </Link>
                )
              })}
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
export {HeaderSelectApps}
