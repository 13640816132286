import { formatNumberToDecimalAuction, getEnv, multiplyNumber, parseNumberToCurrency } from "../../../../../../helpers/procurex";
import { useEffect, useState } from "react";
import axios from "axios";
import {Placeholder} from "rsuite";


const BeritaAcaraAuctionTab = ({auction }: any) => {
  const [isLoadingBeritaAcara, setIsLoadingBeritaAcara] = useState(false);
  const [urlPdf, setUrlPdf] = useState("");

  const getBeritaAcara = () => {
    setIsLoadingBeritaAcara(true);
    const newUrl = getEnv("auction") + `/auction/generate-berita-acara/${auction?.id}`;
    axios.get(newUrl, {
      responseType: "blob"
    })
      .then((response) => {
        setIsLoadingBeritaAcara(false);
        const url = URL.createObjectURL(response.data);
        setUrlPdf(url);
      })
      .catch((error) => {
        setIsLoadingBeritaAcara(false);
      })

  };

  useEffect(() => {
    getBeritaAcara();
  }, []);

  return (
    <>
      <div className={'row'}>
        <div className={'col-md-12'}>
        {(isLoadingBeritaAcara)
          ? <Placeholder.Graph height={700} active={isLoadingBeritaAcara} />
          : urlPdf
            ? <>
              <object width="100%" style={{
                height: "700px"
              }} data={urlPdf} type="application/pdf">
                {" "}
              </object>
            </>
            : <div className={"bg-secondary w-100"} style={{ "height": 700, "opacity": "0.6" }}></div>
        }
        </div>
      </div>
    </>
  );
};

export { BeritaAcaraAuctionTab };
