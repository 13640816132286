import { Modal, Tab, Tabs } from "react-bootstrap";
import React from "react";
import { useCreateProposalTender, useSyncPR } from "../../hooks/proposalTenderHook";
import { toast } from "react-toastify";
import FormAttachmentPR from "./FormAttachmentPRItemTender";


const FormSinkronSAP = ({show,closeModal,selectedRows,onSuccessClose}:any) => {
  const [{ doSubmit, data, isLoading, isError, isSuccess }] = useSyncPR();
  const handleSubmit =async () => {
    // @ts-ignore
    let payloads = {
      start_date: "",
      end_date: "",
      pr_number: []
    }
    selectedRows?.map((row: any) => {
      // @ts-ignore
      payloads.pr_number.push(row?.original?.no)
    })
    doSubmit(payloads, toast).finally(() => {
      closeModal(false)
      onSuccessClose()
    });
  }


  return (
    <>
      <Modal show={show} size={"lg"}>
        <Modal.Header closeButton onHide={()=>closeModal(false)}>
          <Modal.Title>Sinkron SAP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={"table-responsive"} style={{
            maxHeight: "500px",
          }}>
            <table className="table table-bordered table-striped table-header-gray">
              <thead>
                <tr>
                  <th className={"text-center px-2"}>No</th>
                  <th className={"text-center px-2"}>Header PR SAP</th>
                  <th className={"text-center px-2"}>Material PO Text</th>
                  <th className={"text-center"}>Purch. Group</th>
                </tr>
              </thead>
              <tbody>
                {selectedRows?.map((row: any, index: number) => {
                  return (
                    <tr key={"pr-modal-" + row?.original?.id}>
                      {/* <td>{JSON.stringify(row?.original)}</td> */}
                      <td className={"text-center"}>{index+1}</td>
                      <td className={"text-left"}>
                        <div>
                          <b>Nomor PR: </b><i className="text-highlight">{row?.original?.no}</i>
                        </div>
                        <div
                        dangerouslySetInnerHTML={{
                          __html: row?.original?.purchaseRequisitionItem?.purchaseRequisition?.text?.replace(/\n/g, "<br />"),
                        }}></div>
                      </td>
                      <td className={"text-left"}>
                        <div>
                          <b>Item:</b> <i className="text-highlight">{row?.original?.line_no}</i>
                        </div>
                        <div>
                          <b>Short Text:</b> <i className="text-highlight">{row?.original?.short_text}</i>
                        </div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: row?.original?.purchaseRequisitionItem?.poTexts[0]?.text?.replace(/\n/g, "<br />"),
                          }}
                        ></div>
                      </td>                     
                      <td className={"text-center"}>{row?.original?.purchasing_group_id}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type='button'
            onClick={() => {
              closeModal(false)
            }}
            disabled={isLoading}
            className='btn btn-danger'>
            <i className="fa fa-times"></i>
            Cancel
          </button>
          <button
            type='button'
            onClick={() => {
              handleSubmit()
            }}
            disabled={isLoading}
            className='btn btn-primary'>
            {isLoading && <i className='fa fa-spinner fa-spin'></i>}
            {!isLoading && <i className="fa fa-save"></i>}
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

// @ts-ignore
export default FormSinkronSAP