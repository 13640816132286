import { Field, Form, Formik } from 'formik'
import React, { useState, useRef, useEffect } from 'react'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom'
import SelectX from '../../../../../components/shared/form/SelectX'
import { ApiUrl } from '../../../../../config/Api'
import { getEnv } from "../../../../../../helpers/procurex";
import { InputNumber } from "rsuite";
import axios from 'axios'
import { useIntl } from "react-intl";
import SwalX from '../../../../../components/shared/SwalX'
import Swal from "sweetalert2";
import DatepickerX2 from "../../../../../components/shared/form/DatepickerX2";
import { useUploadMedia } from '../../../../../../hooks/MediaUploadHook'
import { useAuth } from "../../../../auth";
import FormikUploadFile from '../../../../../components/shared/form/FormikUploadFile'

const CreateBuletinPrice = () => {
    const { currentUser } = useAuth();

    const initialValues = {
        judul: '',
        jenis_publikasi: '',
        tanggal_keluar_publikasi: '',
        file: '',
        date_created: '',
    }

    const intl = useIntl();
    const refFormik = React.useRef<any>();
    const [itemsMasterJenisPublikasi, setItemsMasterJenisPublikasi] = useState<any>([])
    const [itemLoading, setItemLoading] = useState(false)

    // const validationSchema = Yup.object({});
    const formSchemaValidation = Yup.object().shape({
        judul: Yup.string().required('Judul is required'),
        jenis_publikasi: Yup.string().required('Jenis Publikasi is required'),
        tanggal_keluar_publikasi: Yup.date().required('Tanggal Keluar Publikasi is required'),
        file: Yup.string().nullable().required('The field of file is required'),
    });

    const loadItem = () => {
        axios
            .get(getEnv("stockview") + `/jenis-publikasi`)
            .then((res) => {
                setItemsMasterJenisPublikasi(res.data.data)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setItemLoading(false)
            })
    }

    useEffect(() => {
        loadItem();
    }, [])

    const [formData, setFormData] = React.useState([]);

    // const [initialValues, setInitialvalues] = useState<any>(defaultValue)
    const [loading, setLoading] = useState(true)
    const [isLoading, setIsLoading] = useState(false);
    const [key, setKey] = useState(0)

    const TableRow = ({ no, judul, jenis_publikasi, tanggal_keluar_publikasi, file, onDelete, onPreview, date_created, ...props }: any) => {
        return (
            <tr>
                <td className={"border text-center"}>{no}</td>
                <td className={"border"}>{judul}</td>
                <td className={"border"}>{jenis_publikasi}</td>
                <td className={"border"}>{tanggal_keluar_publikasi}</td>
                <td className={"border"}>{file}</td>
                <td className={"border"}>{date_created}</td>
                <td className={"border text-center"}>
                    <button className={"btn btn-sm btn-secondary"} onClick={() => onDelete()}>
                        <i className={"fa fa-trash text-danger"}></i>
                    </button><br></br>
                    <button
                        className='btn btn-sm btn-primary'
                        onClick={() => onPreview()}
                    >
                        <i className='fa fa-eye' title='preview'></i>
                    </button>
                </td>
            </tr>
        );
    };

    let [isLoadingDelete, setIsLoadingDelete] = useState(false)
    let [isConfirmSubmitOpen, setIsConfirmSaveOpen] = useState(false)

    const [formindex, setFormIndex] = useState(0)
    const navigate = useNavigate();
    const { upload } = useUploadMedia()

    const handleBackClick = () => {
        if (formData.length !== 0) {
            handleBack();
        } else {
            navigate('/stockview/price-bulletin/table-price-bulletin');
        }
    };

    // const date = new Date().toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
    const date = new Date();
    const today = date.toISOString().split('T')[0];
    const handleAddRow = async (values: any) => {

        const newData = {
            judul: values.judul,
            jenis_publikasi: values.jenis_publikasi,
            tanggal_keluar_publikasi: values.tanggal_keluar_publikasi,
            file: values.file,
            date_created: today
        };

        setFormData([...formData, newData]);
        // refFormik.current?.resetForm();
        setFormIndex(formindex + 1);
        // @ts-ignore
    };

    const handleDeleteRow = async (index: number) => {
        const newData = formData.filter((item: any, i: number) => i !== index);
        // @ts-ignore
        setFormData(newData);
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            let updatedFormData = await Promise.all(formData.map(async (item: any) => {
                let data = { ...item };
                if (typeof data.file !== 'string') {
                    let responseFile = await upload('49', data.file);
                    data.file = responseFile.url;
                }
                return data;
            }));

            const response = await axios.post(getEnv("stockview") + `/buletin-price`, updatedFormData)
            if (response.status === 200 || response.status === 201) {
                toast.success(response?.status + ': ' + response?.data.meta.message, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                });
            }
        } catch (error) {
            console.error('Error processing records:', error);
            toast.error(error?.data?.meta?.message || 'Terjadi kesalahan', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        } finally {
            setLoading(false);
        }
    };

    const handleBack = async () => {
        Swal.fire({
            title: `Data Belum Tersimpan`,
            html: `Apakah Anda Yakin Ingin Keluar ?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: `Ya, Keluar!`,
            confirmButtonColor: "#999999",
            cancelButtonColor: "#258141",
            cancelButtonText: `Cancel`,
        }).then((result) => {
            if (result.isConfirmed) {
                navigate('/stockview/price-bulletin/table-price-bulletin')
            }
        })
    }

    const preview = (value: string) => {
        var file = new Blob([value], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
    }

    return (
        <>
            <Formik
                key={formindex}
                innerRef={refFormik}
                initialValues={initialValues}
                onSubmit={handleAddRow}
                validationSchema={formSchemaValidation}
                enableReinitialize
                validateOnChange={true}
            >
                {({ errors, touched, values, setFieldValue }: any) => (
                    <Form>
                        <div className='d-flex justify-content-between mb-6'>
                            <div>
                                <button className='btn btn-secondary btn-sm fw-bold' onClick={handleBackClick}>
                                    Back To list
                                </button>
                            </div>
                            <div className='d-flex gap-4'>
                                <button className={"btn btn-primary btn-sm"} disabled={isLoading}
                                    onClick={() => handleSubmit()}>
                                    {isLoading && <i className={"fa fa-spinner fa-spin"}></i>}
                                    {!isLoading && <i className={"fa fa-save"}></i>}
                                    {intl.formatMessage({ id: "Simpan" })}
                                </button>
                            </div>
                        </div>

                        <div className='card my-5'>
                            <div className='card-header d-flex align-items-center'>
                                <b>FORM ADD BULETIN PRICE</b>
                            </div>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label required'>
                                                Judul
                                            </label>
                                            <Field className='form-control' name="judul" />
                                            <small className="form-text">
                                                Contoh Penulisan: PUBLIKASI JUDUL - 2023/07/20
                                            </small>
                                            {(errors.judul && touched.judul) && (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">{errors.judul}</div>
                                                </div>
                                            )}
                                        </div>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label required'>
                                                Jenis Publikasi
                                            </label>
                                            <SelectX
                                                onChange={(e: any) => setFieldValue('jenis_publikasi', e)}
                                                options={itemsMasterJenisPublikasi.map((item: any) => ({
                                                    label: `${item.nama_jenis}`,
                                                    value: item.nama_jenis
                                                }))}
                                            />
                                            {(errors.jenis_publikasi && touched.jenis_publikasi) && (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">{errors.jenis_publikasi}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label required'>
                                                Tanggal Keluar Publikasi
                                            </label>
                                            <DatepickerX2
                                                onChange={(date: any) => setFieldValue("tanggal_keluar_publikasi", date)}
                                                size={"lg"}
                                                value={values.tanggal_keluar_publikasi}
                                                format={"dd-MM-yyyy"}
                                            />
                                            {(errors.tanggal_keluar_publikasi && touched.tanggal_keluar_publikasi) && (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">{errors.tanggal_keluar_publikasi}</div>
                                                </div>
                                            )}
                                        </div>
                                        <div className='mb-6'>
                                            <FormikUploadFile
                                                mediaTypeId='49'
                                                label='Lampiran Publikasi'
                                                name='file'
                                            />
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>File Type : pdf, xlsx</span>
                                                    <br />
                                                    <span role='alert'>
                                                        MAX FILE 10MB
                                                    </span>
                                                </div>
                                            </div>
                                            {(errors.file && touched.file) && (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">{errors.file}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-end gap-4'>
                                        <button type={"submit"} className={"btn btn-primary btn-sm fw-bold"}>
                                            {isLoading && <i className={"fa fa-spinner fa-spin"}></i>}
                                            {!isLoading && <i className={"fa fa-plus"}></i>}
                                            {intl.formatMessage({ id: "Tambah" })}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"col-md-12 mt-5"}>
                            <table className={"table table-bordered"}>
                                <thead>
                                    <tr>
                                        <th className={"border text-center"}><strong>No</strong></th>
                                        <th className={"border text-center"}><strong>Judul</strong>
                                        </th>
                                        <th className={"border text-center"}><strong>Jenis Publikasi</strong>
                                        </th>
                                        <th className={"border text-center"}><strong>Tanggal Keluar Publikasi</strong></th>
                                        <th className={"border text-center"}><strong>Lampiran</strong></th>
                                        <th className={"border text-center"}><strong>Tanggal Hari Ini</strong></th>
                                        <th className={"border text-center"}><strong>Aksi</strong></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {formData.length === 0 && (
                                        <tr>
                                            <td colSpan={7} className={"text-center"}>
                                                <i>Tidak ada data</i>
                                            </td>
                                        </tr>
                                    )}
                                    {formData.map((item: any, index: number) => {
                                        return (
                                            <TableRow
                                                key={"adm-" + index}
                                                no={index + 1}
                                                judul={item.judul}
                                                jenis_publikasi={item.jenis_publikasi}
                                                tanggal_keluar_publikasi={item.tanggal_keluar_publikasi}
                                                file={item.file?.name}
                                                date_created={item.date_created}
                                                onDelete={() => handleDeleteRow(index)}
                                                onPreview={() => preview(item.file)}
                                            />
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export { CreateBuletinPrice }
