import { useEffect, useState, useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import SwalX from "../../../../../components/shared/SwalX";
import { useIntl } from "react-intl";
import TableX from "../../../../../components/shared/TableX";
import { formatNumber } from "../../../../../../helpers/procurex";
import moment from "moment";
import { NumericFormat } from "react-number-format";
import { AuctionContext } from "../../../../../routing/AuctionRoutes";

const API_URL = process.env.REACT_APP_API_URL;
const ParamSettingAuction = () => {
  const intl = useIntl()
  const [key, setKey] = useState(0)
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false)
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)
  const params = useParams();
  const id = params?.['*'];
  const [detailData, setDetailData]: any = useState([{
    decrement: "",
    item_id: "",
    kuantitas: 0,
    nama: "",
    satuan: "",
    peserta: [],
    unit_price_terendah: "",
    unit_price_tertinggi: "",
  }]);
  const {itemPesertaState, setItemPesertaState} = useContext(AuctionContext);

  const [detailAuction, setDetailAuction] = useState({
    nama: '',
    status: '',
    nomor: '',
    type: '',
    perusahaan: '',
    batas_awal: '',
    batas_akhir: '',
    catatan: '',
    petunjuk: '',
    isReadyPublish: false
  })

  const [isLoadingFetch, setIsLoadingFetch] = useState(false)
  const [showModalDetailPeserta, setShowModalDetailPeserta] = useState(false);
  const [isLoadingSave, setIsLoadingSave] = useState(false)
  const tableColumns = [
    {
      Header: "No", width: 50, className: "text-center",
      Cell: (props: any) => {
        return (
          <span>
            {props?.row?.index + 1}
          </span>
        )
      }
    },
    {
      Header: "Rekanan", width: 400, accessor: "rekanan", className: "text-center",
    },
    { Header: "Qty", accessor: "qty", className: "text-center" },
    {
      Header: "Unit Price", className: "text-center",
      Cell: (props: any) => {
        return (
          <>
            <span>
              {detailAuction?.status != 'published' ? (
                <>
                  <NumericFormat
                    className='form-control'
                    value={props?.cell?.row?.original?.unit_price}
                    thousandSeparator={'.'}
                    type="text"
                    fixedDecimalScale={true}
                    decimalScale={2}
                    decimalSeparator=","
                    onKeyPress={(e: any) => {
                      if (e.key === 'Enter') {
                        let dataParam = {
                          item_auction_id: props?.cell?.row?.original?.item_id,
                          peserta_id: props?.cell?.row?.original?.id,
                        }
                        detailAuction?.status == 'draft'
                          ? onChangeUnitPriceEnter(dataParam, e)
                          : alert('cant edit published auction')
                      }
                    }
                    }
                  />
                  <label className="form-label  justify-content-start" style={{ fontSize: 10, fontWeight: '100', color: 'red' }}>*Press Enter after change</label>
                </>
              ) : (
                <label className="form-label">{formatNumber(props?.cell?.row?.original?.unit_price)}</label>
              )}
            </span>
          </>
        )
      }
    },
    { Header: "Currency", accessor: "currency", className: "text-center" },
    {
      Header: "Total Nilai", accessor: "total_price", className: "text-center",
      Cell: (props: any) => {
        return (
          <>
            <div className='w-200'>
              <span>
                <label className="form-label">{formatNumber(props?.cell?.row?.original?.total_price)+ ",00"}</label>
              </span>
            </div>
          </>
        )
      },
    },
  ];

  const onChangeUnitPriceEnter = async (paramData: any, val: any) => {
    let valTrim = val?.target?.value.toString().split('.').join("")
    let data = { ...paramData, unit_price: valTrim.split(',')[0] }
    try {
      const response: any = await axios.put(`${API_URL}/api/auction/param-setting/${id}/unit-price`, data)
      if (response?.status === 201 || response?.status === 200) {
        setKey(key + 1)
        toast.success(response?.status + ': ' + response?.data.meta.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
        setItemPesertaState(true)
        setShowModalDetailPeserta(false)
        return true
      }
    } catch (error) {
      setIsLoadingSave(false)
      console.error(error)
    } finally {
      setIsLoadingSave(false)
      fetchDetailData()
    }
  }

  const fetchDetailData = async () => {
    setIsLoadingFetch(true);
    try {
      const response: any = await axios.get(`${API_URL}/api/auction/param-setting/${id}`)
      setDetailData(response.data?.data?.item);

      setIsLoadingFetch(false);

    } catch (e) {
      setIsLoadingFetch(false);
      console.log(e)
    }
  }

  const deleteItem = async () => {
    setIsLoadingDelete(true)
    try {
      const response: any = await axios.delete(`${API_URL}/api/auction/item-auction/${id}`)

      if (response.status === 200) {
        setKey(key + 1)
        setIsConfirmDeleteOpen(false)
        toast.success(response?.data?.meta?.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      }
    } catch (error) {
    } finally {
      setIsLoadingDelete(false)
    }
  }

  const fetchDetailAuction = async () => {
    const response: any = await axios.get(`${API_URL}/api/auction/auction/${id}`)
    let details = {
      nama: response?.data?.data?.nama,
      status: response?.data?.data?.status,
      nomor: response?.data?.data?.no_auction,
      type: response?.data?.data?.tipe_auction?.value,
      perusahaan: response?.data?.data?.perusahaaan,
      batas_awal: response.data?.data?.batas_awal ? moment(response.data?.data?.batas_awal).format('DD MMMM yyyy mm:HH') : '',
      batas_akhir: response.data?.data?.batas_akhir ? moment(response.data?.data?.batas_akhir).format('DD MMMM yyyy mm:HH') : '',
      catatan: response.data?.data?.catatan,
      petunjuk: response.data?.data?.petunjuk,
      isReadyPublish: response.data?.data?.ready_publish ?? false
    }

    setDetailAuction(details);
  }

  useEffect(() => {
    fetchDetailData();
    fetchDetailAuction();
  }, [itemPesertaState])

  return (
    <>
      <SwalX
        title={intl.formatMessage({ id: 'DATATABLE.CONFIRM_DELETE' })}
        message={intl.formatMessage({ id: 'DATATABLE.MESSAGE_DELETE' })}
        show={isConfirmDeleteOpen}
        loading={isLoadingDelete}
        onHide={() => setIsConfirmDeleteOpen(false)}
        onSubmit={() => deleteItem()}
      />
      <div className={'mt-4'} >
        <>
          <Row>
            <Col>
              <div className='d-flex justify-content-start mt-3' style={{ marginLeft: 30 }} data-kt-materialrequest-table-toolbar='base'>
                <label className="form-label mb-3">ITEM PARAMETER</label>
              </div>
            </Col>
          </Row>
          <hr style={{ marginLeft: 30, marginRight: 30 }} />
          {detailData?.length > 0 ? (
            detailData?.map((item: any, index: any) => (
              <div key={index}>
                <Row>
                  <Col>
                    <div className='d-flex justify-content-start mt-3' style={{ marginLeft: 30 }} data-kt-materialrequest-table-toolbar='base'>
                      <label className="form-label mb-3">Item {index + 1} - {item?.nama}</label>
                    </div>
                  </Col>
                </Row>
                <hr style={{ marginLeft: 30, marginRight: 30 }} />
                <Row>
                  <Col>
                    <div className='d-flex justify-content-start mt-3' style={{ marginLeft: 30 }} data-kt-materialrequest-table-toolbar='base'>
                      <label className="form-label mb-3">Quantity : {item?.kuantitas} | Unit Price (Min): {formatNumber(item?.unit_price_terendah)} | Unit Price (Max): {formatNumber(item?.unit_price_tertinggi)} | Incremenet/Decrement: {item?.decrement}</label>
                    </div>
                  </Col>
                </Row>
                <hr style={{ marginLeft: 30, marginRight: 30 }} />
                <Row>
                  <Col>
                    <TableX
                      key={key}
                      data={item?.peserta ?? []}
                      meta={{
                        perPage: item?.meta?.per_page || item?.peserta?.length,
                        currentPage: item?.meta?.current_page,
                        total: item?.peserta?.length,
                      }}
                      columns={tableColumns}
                      isLoading={isLoadingFetch}
                    />
                  </Col>
                </Row>

                <hr style={{ marginLeft: 30, marginRight: 30 }} />
              </div>
            ))
          ) : (
            <></>
          )
          }

        </>
        <hr style={{ marginLeft: 30, marginRight: 30 }} />
      </div>
    </>
  );
};

export { ParamSettingAuction };
