import { Field, Form, Formik } from 'formik'
import React, { useState } from 'react'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { Link, useNavigate, useParams } from 'react-router-dom'
import SelectX from '../../../../../components/shared/form/SelectX'
import { ApiUrl } from '../../../../../config/Api'
import { getEnv } from "../../../../../../helpers/procurex";
import { InputNumber } from "rsuite";
import axios from 'axios'

const validationSchema = Yup.object({
    start_date: Yup.date().required('The field is required'),
    end_date: Yup.date()
        .required('The Field is required')
        .test('end_date', 'Tanggal harus lebih besar dari Berlaku Sejak Tanggal', function (value) {
            const start_date = this.parent?.start_date
            return !start_date || !value || value > start_date
        }),
    reason_note: Yup.string().required(),
})

const Create = () => {

    const defaultValue = {
        uuid: '',
        doc_header_text: '',
        reference: '',
        posting_date: '',
        document_date: '',
        material_number: '',
        qty: '',
        uom: '',
        plant: '',
        sloc: '',
        material_number_dest: '',
        plant_dest: '',
        sloc_dest: '',
        movement_type: '',
        order: '',
    }

    const [initialValues, setInitialvalues] = useState<any>(defaultValue)
    const [loading, setLoading] = useState(true)
    const [key, setKey] = useState(0)

    const onSubmit = async (values: any, { resetForm }: any) => {
        setLoading(true)
        try {
            if (values.id) {
                const data = { ...values }

                const response = await axios.put(getEnv("stockview") + `/api/transmovement-trans/${values.id}`, data)

                if (response.status === 200 || response.status === 201) {
                    toast.success(response?.status + ': ' + response?.data.meta.message, {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'light',
                    })
                }
            } else {
                const data = { ...values }

                const response = await axios.post(getEnv("stockview") + `/api/transmovement-trans/`, data)

                if (response.status === 200 || response.status === 201) {
                    toast.success(response?.status + ': ' + response?.data.meta.message, {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'light',
                    })
                }
            }
            setKey(key + 1)
            resetForm(initialValues)
        } catch (error: any) {
            console.error(error)
            toast.error(error?.data?.meta?.message || 'Terjadi kesalahan', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            })
        } finally {
            setLoading(false)
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize
        >
            <Form>
                <div className='d-flex justify-content-between mb-6'>
                    <div>
                        <Link to='/stockview/transaction/goods-movement' className='btn btn-secondary btn-sm fw-bold'>
                            Back To list
                        </Link>
                    </div>
                    <div className='d-flex gap-4'>
                        <button
                            // disabled={loading}
                            type='submit'
                            className='btn btn-primary btn-sm fw-bold'
                        >
                            Submit
                            {/* {loading && <span className='spinner-border spinner-border-sm me-1' />}
                                {detail?.status === 'submitted' ? 'Revisi' : 'Tambah'} */}
                        </button>
                    </div>
                </div>

                <div className='card my-5'>
                    <div className='card-header d-flex align-items-center'>
                        <b>FORM ADD MOVEMENT</b>
                    </div>
                    <div className='card-body'>
                        <div className='row'>
                            <div className='col-lg-4'>
                                <div className='mb-6'>
                                    <label htmlFor='' className='form-label'>
                                        Document Header Text
                                    </label>
                                    <input className='form-control' placeholder='Input Document Header' />
                                </div>
                                <div className='mb-6'>
                                    <label htmlFor='' className='form-label'>
                                        Movement Type *
                                    </label>
                                    {/* <input className='form-control' placeholder='Search For A Movement Type' /> */}
                                    <SelectX
                                        defaultValue={0}
                                        // onChange={(e: any) => setFieldValue('punishment_type', e)}
                                        options={[
                                            {
                                                label: 'Suspend',
                                                value: 'suspend',
                                            },
                                            {
                                                label: 'Blacklist',
                                                value: 'blacklist',
                                            },
                                        ]}
                                    />
                                </div>
                                <div className='row mb-6'>
                                    <div className='col-lg-9'>
                                        <label htmlFor='' className='form-label'>
                                            Material *
                                        </label>
                                        <SelectX
                                            defaultValue={0}
                                            // onChange={(e: any) => setFieldValue('punishment_type', e)}
                                            options={[
                                                {
                                                    label: 'Suspend',
                                                    value: 'suspend',
                                                },
                                                {
                                                    label: 'Blacklist',
                                                    value: 'blacklist',
                                                },
                                            ]}
                                        />
                                        {/* <MetronicErrorMessage name='sap_no' /> */}
                                    </div>
                                    <div className='col-lg-3'>
                                        <label htmlFor='' className='form-label'>
                                            Uom *
                                        </label>
                                        <input className='form-control' disabled />
                                        {/* <MetronicErrorMessage name='sap_no' /> */}
                                    </div>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col-lg-9'>
                                        <label htmlFor='' className='form-label'>
                                            Material Dest
                                        </label>
                                        <SelectX
                                            defaultValue={0}
                                            // onChange={(e: any) => setFieldValue('punishment_type', e)}
                                            options={[
                                                {
                                                    label: 'Suspend',
                                                    value: 'suspend',
                                                },
                                                {
                                                    label: 'Blacklist',
                                                    value: 'blacklist',
                                                },
                                            ]}
                                            isDisabled
                                        />
                                        {/* <MetronicErrorMessage name='npwp_no' /> */}
                                    </div>
                                    <div className='col-lg-3'>
                                        <label htmlFor='' className='form-label'>
                                            Uom
                                        </label>
                                        <input className='form-control' disabled />
                                        {/* <MetronicErrorMessage name='sap_no' /> */}
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className='mb-6'>
                                    <label htmlFor='' className='form-label'>
                                        Document Date *
                                    </label>
                                    <Field
                                        className='form-control'
                                        name='document_date'
                                        type='date'
                                    // disabled={
                                    //     !isPicOrSuperadmin || isRegisteredOrUpdated
                                    //         ? true
                                    //         : values.is_permanent_tdp
                                    // }
                                    />
                                </div>
                                <div className='mb-6'>
                                    <label htmlFor='' className='form-label'>
                                        Reference
                                    </label>
                                    <input className='form-control' placeholder='Input Reference' />
                                </div>
                                <div className='mb-6'>
                                    <label htmlFor='' className='form-label'>
                                        Plant *
                                    </label>
                                    <SelectX
                                        defaultValue={0}
                                        // onChange={(e: any) => setFieldValue('punishment_type', e)}
                                        options={[
                                            {
                                                label: 'Suspend',
                                                value: 'suspend',
                                            },
                                            {
                                                label: 'Blacklist',
                                                value: 'blacklist',
                                            },
                                        ]}
                                    />
                                </div>
                                <div className='mb-6'>
                                    <label htmlFor='' className='form-label'>
                                        Plant Dest
                                    </label>
                                    <SelectX
                                        defaultValue={0}
                                        // onChange={(e: any) => setFieldValue('punishment_type', e)}
                                        options={[
                                            {
                                                label: 'Suspend',
                                                value: 'suspend',
                                            },
                                            {
                                                label: 'Blacklist',
                                                value: 'blacklist',
                                            },
                                        ]}
                                        isDisabled
                                    />
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className='mb-6'>
                                    <label htmlFor='' className='form-label'>
                                        Posting Date *
                                    </label>
                                    <Field
                                        className='form-control'
                                        name='posting_date'
                                        type='date'
                                    // disabled={
                                    //     !isPicOrSuperadmin || isRegisteredOrUpdated
                                    //         ? true
                                    //         : values.is_permanent_tdp
                                    // }
                                    />
                                </div>
                                <div className='mb-6'>
                                    <label htmlFor='' className='form-label'>
                                        Order
                                    </label>
                                    <input className='form-control' placeholder='Input Order' />
                                </div>
                                <div className='mb-6'>
                                    <label htmlFor='' className='form-label'>
                                        Sloc *
                                    </label>
                                    <SelectX
                                        defaultValue={0}
                                        // onChange={(e: any) => setFieldValue('punishment_type', e)}
                                        options={[
                                            {
                                                label: 'Suspend',
                                                value: 'suspend',
                                            },
                                            {
                                                label: 'Blacklist',
                                                value: 'blacklist',
                                            },
                                        ]}
                                    />
                                </div>
                                <div className='mb-6'>
                                    <label htmlFor='' className='form-label'>
                                        Sloc Dest
                                    </label>
                                    <SelectX
                                        defaultValue={0}
                                        // onChange={(e: any) => setFieldValue('punishment_type', e)}
                                        options={[
                                            {
                                                label: 'Suspend',
                                                value: 'suspend',
                                            },
                                            {
                                                label: 'Blacklist',
                                                value: 'blacklist',
                                            },
                                        ]}
                                        isDisabled
                                    />
                                </div>
                            </div>
                        </div>
                        <div className=''>
                            <div className='mb-6'>
                                <label htmlFor='' className='form-label'>
                                    Quantity *
                                </label>
                                <InputNumber
                                    defaultValue={0}
                                    min={0} max={100}
                                    className={"w-100 text-end"}
                                // onChange={(value: any) => {
                                //     handleOnScoreChange(value)
                                // }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </Formik>
    )
}

export { Create }
