import { KTCard, KTCardBody } from "../../../../../../_metronic/helpers";
import React, { useCallback, useEffect, useState } from "react";
import TableXWrpper from "../../../../../components/shared/TableXWrapper";
import { getEnv } from "../../../../../../helpers/procurex";
import { useIntl } from "react-intl";
import { darkModeClass } from "../../../../../../helpers/utils";
import FormDisplayPRDetail from "../../purchasing-requisition/components/FormDisplayPRDetail";
import axios from "axios";
import { Cell } from "jspdf-autotable";
import { useNavigate } from "react-router-dom";

interface IPropsStatusBadge {
  status: string;
}
const StatusBadgeMonitoringTender = ({status}:IPropsStatusBadge) => {
  const [badge,setBadge] = useState("badge-primary");
  useEffect(() => {
    if (status === "Draft") setBadge("badge-info");
    if (status === "DUR") setBadge("badge-primary");
  }, [status]);
  return (
    <div className={"text-start"}>
      <span className={`badge ${badge}`}>{status}</span>
    </div>
  )
}

const MonitoringPRList = () => {
  const urlIndex = getEnv('tender')+"/purchase-requisition-item-tender/monitoring";
  const navigate = useNavigate();
  const intl = useIntl();
  const [showModal, setShowModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const BadgeUser = ({username}:any)=>{
    const [user,setUser] = useState({} as any)
    const getCauser = useCallback(() => {
      axios.get(getEnv('sso') + `/user?filter[username]=${username}`).then(function (response: any) {
        setUser(response?.data?.data)
      })
    },[username])

    useEffect(()=>{
      if(username) getCauser()
    },[getCauser])

    return (
      <>
        {user[0] && (
          <span className={"badge badge-primary"}>
            {user[0]?.name} - {user[0]?.username}
          </span>
        )}
      </>
    )
  }

  const tableColumns = [
    {
      Header: intl.formatMessage({id:"Aksi"}),
      accessor: "action",
      width: 50,
      className: "text-start fixed-column",
      Cell: (props: any) => {
        const row = props.row.original;
        return (
          <>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href={"javascript:;"} title={"Edit"} onClick={(e:any) => {
              e.preventDefault();
              setSelectedRows(row)
              setShowModal(true)
            }}
              className={`me-2 ${darkModeClass()}`}>
              <i className={`fa fs-3 fa-eye ${darkModeClass()}`}></i>
            </a>
          </>
        );
      }
    },
    {
      Header: intl.formatMessage({id:"No PR"}),
      accessor: "no_pr",
      className: "text-start text-nowrap fixed-column",
      width: 100,
    },
    {
      Header: intl.formatMessage({id:"Line No"}),
      accessor: "line_no",
      width: 80,
      disableCreate: true,
      disableEdit: true,
      className: "text-center"
    },
    {
      Header: intl.formatMessage({id:"Send to Procurement"}),
      accessor: "date_send_to_procurement",
      className: "text-center",
      width: 180
    },
    { Header: intl.formatMessage({id:"Company Code Requestor"}), accessor: "requester_company", width: 130, className: "text-center" },
    { Header: intl.formatMessage({id:"Jenis Permintaan"}), accessor: "account_assignment_used", width: 130, className: "text-center" },
    { Header: intl.formatMessage({id:"Prioritas"}), accessor: "priority_mr_sr", width: 130, className: "text-center" },
    { 
      Header: intl.formatMessage({id:"No Tender"}), 
      accessor: "no_tender", 
      width: 150, 
      className: "text-center text-nowrap",
      Cell: (props: any) => {
        return (
          <>
            <a href={"javascript:;"} 
              title={"View Proposal Tender"} 
              onClick={() => { 
                navigate("/tender/admin/proposal-tender/detail/" + props?.cell?.row?.original?.uuid_tender) 
                }
              } 
              className={`text-info`}>
              { props?.cell?.row?.original?.no_tender }
            </a>
          </>
        )
      } 
    },
    { Header: intl.formatMessage({id:"No Procurex"}), accessor: "no_2", width:   150, className: "text-center text-nowrap" },
    {
      Header: intl.formatMessage({id:"Status PR"}),
      accessor: "status_pr",
      width: 100,
      className: "text-center text-nowrap",
      Cell: (props: any) => {
        return (
          <span className={"badge badge-dark"}>
            {props?.cell?.row?.original?.status_pr}
          </span>
        )
      },
    },
    {
      Header: intl.formatMessage({id:"Buyer"}),
      accessor: "current_user",
      width: 250,
      className: "text-center text-nowrap",
      Cell: (props: any) => {
        return (
          <BadgeUser username={props?.cell?.row?.original?.current_user} />
        )
      },
    },
    {
      Header: intl.formatMessage({id:"Status Tender"}),
      accessor: "status_tender",
      width: 150,
      className: "text-center",
      Cell: (props: any) => {
        return (
          <span className={"badge badge-info"}>
            {props?.cell?.row?.original?.status_tender}
          </span>
        )
      },
    },
    {
      Header: intl.formatMessage({id:"Status Detail Tender"}),
      accessor: "status_tender_detail",
      width: 170,
      className: "text-center text-nowrap",
      Cell: (props: any) => {
        return (
          <span className={"badge badge-success"}>
            {props?.cell?.row?.original?.status_tender_detail}
          </span>
        )
      },
    },
    { Header: intl.formatMessage({id:"Short Text"}), accessor: "short_text", width: 150, className: "" },
    { Header: intl.formatMessage({id:"Quantity"}), accessor: "quantity", width: 100, className: "text-end" },
    { Header: intl.formatMessage({id:"Quantity Remaining"}), accessor: "quantity_remaining", width: 100, className: "text-end" },
    { Header: intl.formatMessage({id:"Quantity PO"}), accessor: "quantity_po", width: 100, className: "text-end" },
    { Header: intl.formatMessage({id:"UOM"}), accessor: "uom_id", width: 100, className: "text-center" },
    { Header: intl.formatMessage({id:"Valuation Price"}), accessor: "valuation_price", type:'number', width: 150, className: "text-end" },
    { Header: intl.formatMessage({id:"Per"}), accessor: "per", width: 150, className: "text-end", type:'number' },
    { Header: intl.formatMessage({id:"Total Value"}), accessor: "total_value", width: 150, type:'number', className: "text-end" },
    { Header: intl.formatMessage({id:"Purchasing Group"}), accessor: "purchasing_group_id", width: 150, className: "text-end" },
    { Header: intl.formatMessage({id:"Requisitioner"}), accessor: "requisitioner", width: 150, className: "text-end" },
    { Header: intl.formatMessage({id:"Dibuat Pada"}), accessor: "created_at", width: 200, className: "text-center" },
    { Header: intl.formatMessage({id:"Diupdate Pada"}), accessor: "updated_at", width: 200, className: "text-center" },
  ];

  return (
    <>
      <FormDisplayPRDetail
        show={showModal}
        closeModal={() => setShowModal(false)}
        selectedRows={selectedRows}
      />

      <KTCard>
        <KTCardBody className='py-4'>
          <TableXWrpper
              readonly={true}
              title={"Proposal Tender"}
              urlIndex={urlIndex}
              columns={tableColumns}
              isForceCreateButton={true}
          />
        </KTCardBody>
      </KTCard>
    </>
  )
}

export {MonitoringPRList, StatusBadgeMonitoringTender}
