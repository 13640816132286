/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import noUiSlider from 'nouislider'
import {useLayout} from '../../core'
import {KTSVG} from '../../../helpers'
import {DefaultTitle} from './page-title/DefaultTitle'
import {HeaderUserMenu, ThemeModeSwitcher} from '../../../partials'
import {useAuth} from '../../../../app/modules/auth'
import {HeaderSelectApps} from './HeaderSelectApps'
import {HeaderNotification} from './HeaderNotification'
import { Languages } from "../../../partials/layout/header-menus/Languages";
import HeaderImpersonate from "./HeaderImpersonate";
import HeaderImpersonateChooseUser from "./HeaderImpersonateChooseUser";
import {StatusOnline} from './StatusOnline'

const HeaderToolbar = () => {
  const {currentUser} = useAuth()
  const {classes} = useLayout()
  const [status, setStatus] = useState<string>('1')


  useEffect(() => {
    const rangeSlider = document.querySelector('#kt_toolbar_slider')
    const rangeSliderValueElement = document.querySelector('#kt_toolbar_slider_value')


    if (!rangeSlider || !rangeSliderValueElement) {
      return
    }

    // @ts-ignore
    noUiSlider.create(rangeSlider, {
      start: [5],
      connect: [true, false],
      step: 1,
      format: {
        to: function (value) {
          const val = +value
          return Math.round(val).toString()
        },
        from: function (value) {
          return value
        },
      },
      range: {
        min: [1],
        max: [10],
      },
    })

    // @ts-ignore
    rangeSlider.noUiSlider.on('update', function (values, handle) {
      rangeSliderValueElement.innerHTML = values[handle]
    })

    const handle = rangeSlider.querySelector('.noUi-handle')
    if (handle) {
      handle.setAttribute('tabindex', '0')
    }

    // @ts-ignore
    handle.addEventListener('click', function () {
      // @ts-ignore
      this.focus()
    })



    // @ts-ignore
    handle.addEventListener('keydown', function (event) {
      // @ts-ignore
      const value = Number(rangeSlider.noUiSlider.get())
      // @ts-ignore
      switch (event.which) {
        case 37:
          // @ts-ignore
          rangeSlider.noUiSlider.set(value - 1)
          break
        case 39:
          // @ts-ignore
          rangeSlider.noUiSlider.set(value + 1)
          break
      }
    })
    return () => {
      // @ts-ignore
      rangeSlider.noUiSlider.destroy()
    }
  }, [])

  const getFirstName = (name: string) => {
    return name.split(' ')[0]
  }

  const [environtment, setEnvirontment] = useState('')
  useEffect(() => {
    if(process.env.REACT_APP_API_URL === 'https://procurement-qa.pupuk-indonesia.com'){
      setEnvirontment('QA')
    } else if(process.env.REACT_APP_API_URL === 'https://procurement-dev.pupuk-indonesia.com'){
      setEnvirontment('DEV')
    }
  }, []);
  const userRef = useRef(null);


  return (
    <div className='toolbar d-flex align-items-stretch'>
      <div className={"d-flex flex-column align-items-stretch"} style={{
        width: "100%",
      }}>
        <HeaderImpersonate />
        <div className={"py-4"} style={{
          minHeight: "50px",
          backgroundColor: "#fff",
        }}>
          {environtment !== '' && (
            <a href={"#"}
               className={"ribbon ribbon-top-right " + (environtment === "QA" ? "ribbon-qa" : "ribbon-dev")}
               onClick={() => {
                 if (userRef && userRef.current) {
                   // @ts-ignore
                   userRef.current.click();
                 } else {
                 }
               }}
            >
              <span>{environtment}</span>
            </a>
          )}

          {/* begin::Toolbar container */}
          <div
            className={`${classes.headerContainer.join(
              ' '
            )} py-6 py-lg-0 d-flex flex-column flex-lg-row align-items-lg-stretch justify-content-lg-between`}
          >
            <DefaultTitle />
            <div className='d-flex align-items-stretch overflow-auto pt-3 pt-lg-0'>
              {/* begin::Action wrapper */}
              <div className='d-flex align-items-center'>
                {/* end::Label */}

                {/* begin::Actions */}
                <div className="d-flex gap-8">

                  <div className="d-flex align-items-center">
                    {/*begin::Action*/}
                    <HeaderSelectApps />
                    {/*end::Action*/}
                  </div>
                  <div className="d-flex align-items-center mt-2">
                    <StatusOnline />
                  </div>
                  {currentUser?.has_roles?.includes('superadmin') && (
                    <div className="d-flex align-items-center">
                      {/*begin::Action*/}
                      <HeaderImpersonateChooseUser />
                      {/*end::Action*/}
                    </div>
                  )}
                  {/* begin::Theme mode */}
                  <div className="d-flex align-items-center">
                    <Languages />
                  </div>
                  {/*<div className="d-flex align-items-center">*/}
                  {/*  <ThemeModeSwitcher />*/}
                  {/*</div>*/}
                  <div className="d-flex align-items-center">
                    <HeaderNotification />
                  </div>

                  {/* end::Theme mode */}

                  <div className="d-flex align-items-center">
                    {/*begin::Action*/}
                    <HeaderUserMenu />
                    {/*end::Action*/}
                  </div>


                </div>
                {/* end::Actions */}
              </div>
              {/* end::Action wrapper */}
            </div>
            {/* end::Toolbar container */}
          </div>
        </div>
      </div>

    </div>
  )
}

export { HeaderToolbar }
