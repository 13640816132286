import { Navigate, Route, Routes } from "react-router-dom";
import { FC, lazy, Suspense } from "react";
import { WithChildren } from "../../_metronic/helpers";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import TopBarProgress from "react-topbar-progress-indicator";
import PurchasingRequisitionPage from "../modules/tendering/admin/purchasing-requisition/PurchasingRequisitionPage";
import MonitoringTenderPage from "../modules/tendering/admin/monitoring/MonitoringTenderPage";
import AanwijzingPage from "../modules/tendering/admin/aanwijzing/AanwijzingPage";
import { useAuth } from "../modules/auth";
import PraQualificationVendorPage from "../modules/tendering/vendor/pra-qualification/PraQualificationVendorPage";
import ProposalTenderVendorPage from "../modules/tendering/vendor/proposal-tender/ProposalTenderVendorPage";
import MonitoringPRPage from "../modules/tendering/admin/monitoring/MonitoringPRPage";
import AanwijzingVendorPage from '../modules/tendering/vendor/aanwijzing/AanwijzingVendorPage'
import {EvaluationPage} from '../modules/tendering/admin/evaluation/EvaluationPage'
import MonitoringEvaluationPage from '../modules/tendering/admin/monitoring/MonitoringEvaluationPage'

const TenderingRoutes = () => {
  const DashboardPage = lazy(() => import("../modules/tendering/admin/dashboard/TenderingDashboard"));
  const ProposalTenderPage = lazy(() => import("../modules/tendering/admin/proposal-tender/ProposalTenderPage"));
  const { currentUser } = useAuth();
  return (
    <>
      {!currentUser?.has_roles?.includes("vendor") && (
        <Routes>
          <Route index element={<Navigate to="/tender/dashboard" />} />
          <Route path="dashboard" element={<SuspensedView><DashboardPage /></SuspensedView>} />
          <Route path="/admin/purchasing-requisition/*"
                 element={<SuspensedView><PurchasingRequisitionPage /></SuspensedView>} />
          <Route path="/admin/proposal-tender/*" element={<SuspensedView><ProposalTenderPage /></SuspensedView>} />
          <Route path="/admin/evaluation/*" element={<SuspensedView><EvaluationPage /></SuspensedView>} />
          <Route path="/admin/aanwijzing/*" element={<SuspensedView><AanwijzingPage /></SuspensedView>} />
          <Route path="/admin/monitoring/*" element={<SuspensedView><MonitoringTenderPage /></SuspensedView>} />
          <Route path="/admin/monitoring-pr/*" element={<SuspensedView><MonitoringPRPage /></SuspensedView>} />
          <Route path="/admin/monitoring-evaluasi/*" element={<SuspensedView><MonitoringEvaluationPage /></SuspensedView>} />
        </Routes>
      )}
      {currentUser?.has_roles?.includes("vendor") && (
        <Routes>
          <Route path="/vendor/pra-qualification/*" element={<SuspensedView><PraQualificationVendorPage /></SuspensedView>} />
          <Route path="/vendor/aanwijzing/*" element={<SuspensedView><AanwijzingVendorPage /></SuspensedView>} />
          <Route path="/vendor/tender/*" element={<SuspensedView><ProposalTenderVendorPage /></SuspensedView>} />
        </Routes>
      )}

    </>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor
    },
    barThickness: 1,
    shadowBlur: 5
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export default TenderingRoutes;
