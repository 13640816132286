import React, {useRef, useState} from 'react'
import {InvalidImportExcelModal} from './InvalidImportExcelModal'
import {IColumns as ITableXColumns} from '../TableX'
import {ImportExcelMainModal, ImportExcelMainModalProps, ImportExcelMainModalRef} from './ImportExcelMainModal'

interface ImportExcelModalProps extends ImportExcelMainModalProps {
  invalidTableXColumns?: Array<ITableXColumns>
  invalidTableXDataColumns?: Array<ITableXColumns>
}

export const ImportExcelModal = ({masterCode, show, onHide, invalidTableXColumns, invalidTableXDataColumns}: ImportExcelModalProps) => {
  const mainModalRef = useRef<ImportExcelMainModalRef>()
  const [importUuid, setImportUuid] = useState(null)
  const [isShowInvalidModal, setIsShowInvalidModal] = useState(false)

  return (
    <>
      <ImportExcelMainModal
        ref={mainModalRef}
        masterCode={masterCode}
        show={show}
        onHide={onHide}
        onInvalidImport={(data: any) => {
          setImportUuid(data.uuid)
          setIsShowInvalidModal(true)
        }}
      />

      <InvalidImportExcelModal
        importUuid={importUuid}
        show={isShowInvalidModal}
        onHide={() => {
          setIsShowInvalidModal(false)

          mainModalRef.current?.formikRef.current?.resetForm()
        }}
        tableXColumns={invalidTableXColumns}
        tableXDataColumns={invalidTableXDataColumns}
      />
    </>
  );
}