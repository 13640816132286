import React, { useEffect, useState, useContext, createContext } from "react";
import { KTCard, KTCardBody } from "../../../../../../_metronic/helpers";
import { Button, Col, Modal, Row, Tab, Tabs } from "react-bootstrap";
import { ParameterAuction } from "./ParameterAuction";
import { ItemAuction } from "./ItemAuction";
import { PesertaAuction } from "./PesertaAuction";
import { JadwalAuction } from "./JadwalAuction";
import { ParamSettingAuction } from "./ParamSettingAuction";
import { PetunjukPeraturanAuction } from "./PetunjukPeraturanAuction";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import SwalX from "../../../../../components/shared/SwalX";
import { toast } from "react-toastify";
import { TabulasiAuction } from "./TabulasiAuction";
import { RiwayatAuction } from "./RiwayatAuction";
import Timer from "../../../../../components/Timer";
import { AuctionContext } from "../../../../../routing/AuctionRoutes";

const AuctionDraft = () => {
  const BASE_URL = process.env.REACT_APP_API_URL;
  moment.locale('id');
  const params = useParams();
  const id = params?.['*'];
  const [isConfirmPublish, setIsConfirmPublish] = useState(false);
  const [isLoadingPublish, setIsLoadingPublish] = useState(false);
  const {
    itemPesertaState,
    setItemPesertaState,
    auctionDetail,
    setAuctionDetail
  } = useContext(AuctionContext)

  const navigate = useNavigate();
  // const [auctionDetail, setauctionDetail] = useState({
  //   nama: '',
  //   status: '',
  //   nomor: '',
  //   type: '',
  //   perusahaan: '',
  //   batas_awal: '',
  //   batas_akhir: '',
  //   catatan: '',
  //   petunjuk: '',
  //   isReadyPublish: false,
  //   startDate: moment(),
  //   endDate: moment(),
  // })

  const fetchauctionDetail = async () => {
    const response: any = await axios.get(`${BASE_URL}/api/auction/auction/${id}`)
    let endDates = moment(response.data?.data?.batas_akhir, 'YYYY-MM-DD hh:mm:ss')
    let startDates = moment(moment(response.data?.data?.batas_awal).toDate())
    let details = {
      nama: response?.data?.data?.nama,
      status: response?.data?.data?.status,
      nomor: response?.data?.data?.no_auction,
      type: response?.data?.data?.tipe_auction?.value,
      metode_evaluasi: response?.data?.data?.metode_evaluasi?.value,
      metode_peringkat: response?.data?.data?.metode_peringkat?.value,
      visibilitas_peringkat: response?.data?.data?.metode_peringkat?.value,
      visibilitas_harga: response?.data?.data?.visibilitas_harga?.value,
      jenis_penerimaan: response?.data?.data?.jenis_penerimaan?.value,
      opsi_peringkat: response?.data?.data?.opsi_peringkat?.value,
      perusahaan: response?.data?.data?.perusahaaan,
      batas_awal: response.data?.data?.batas_awal ? moment(response.data?.data?.batas_awal).format('DD MMMM yyyy HH:mm') : '',
      batas_akhir: response.data?.data?.batas_akhir ? moment(response.data?.data?.batas_akhir).format('DD MMMM yyyy HH:mm') : '',
      catatan: response.data?.data?.catatan,
      petunjuk: response.data?.data?.petunjuk,
      isReadyPublish: response.data?.data?.ready_publish ?? false,
      startDate: startDates,
      endDate: endDates,
    }
    // setauctionDetail(details);
    setAuctionDetail(details);
  }

  const onPublishAuction = async () => {
    setIsLoadingPublish(true)
    try {
      const response: any = await axios.put(`${BASE_URL}/api/auction/auction/publish/${id}/1`)

      if (response.status === 200) {
        setIsConfirmPublish(false)
        toast.success(response?.data?.meta?.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      }
    } catch (error) {
    } finally {
      setIsLoadingPublish(false)
      navigate(-1);
    }
  }

  useEffect(() => {
    fetchauctionDetail();
    setItemPesertaState(false)
  }, [itemPesertaState])

  return (
    <>
      <div className='mb-10'>
        <SwalX
          title={'Konirmasi Publish'}
          message={'Apakah kamu yakin?'}
          show={isConfirmPublish}
          btnConfirmLabel='Ya, Publish'
          btnConfirmVariant={'success'}
          btnConfirmIcon={'fa fa-paper-plane'}
          loading={isLoadingPublish}
          onHide={() => setIsConfirmPublish(false)}
          onSubmit={() => onPublishAuction()}
        />
        {auctionDetail?.status == 'published' && (
          <div
            className='mt-10'
            style={{
              position: 'fixed',
              left: '57%',
              top: '15%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <div
              className='d-flex justify-content-center'
              data-kt-materialrequest-table-toolbar='base'
            >
              <Timer
                label='Waiting Time Auction'
                endDate={auctionDetail?.startDate}
                isCountDown={true}
              ></Timer>
              {/* <input onChange={(e:any) => { console.log(moment('2023-11-08 14:39:00').locale('id')); console.log(auctionDetail?.endDate.diff(auctionDetail?.startDate)) }} /> */}
            </div>
            <div
              className='d-flex justify-content-center'
              data-kt-materialrequest-table-toolbar='base'
            >
              <Timer
                label='Waktu Sisa Auction'
                startDate={auctionDetail?.startDate}
                endDate={auctionDetail?.endDate}
                isCountDown={true}
                meta={'test'}
              ></Timer>
            </div>
          </div>
        )}
        <Row>
          <Col>
            <div
              className='d-flex justify-content-start mt-3'
              data-kt-materialrequest-table-toolbar='base'
            >
              <label className='fs-2 font-weight-bold' style={{fontWeight: '600'}}>
                [SIMULASI] {auctionDetail?.nama}
              </label>
            </div>
          </Col>
          <Col>
            <div
              className='d-flex justify-content-end mb-3'
              data-kt-materialrequest-table-toolbar='base'
            >
              <label className='fs-2 font-weight-bold' style={{fontWeight: '700'}}>
                {auctionDetail?.status}
              </label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div
              className='d-flex justify-content-start mt-3'
              data-kt-materialrequest-table-toolbar='base'
            >
              <label>No</label>
              <label className='form-label'>: {auctionDetail?.nomor}</label>
              <div style={{marginLeft: 10}} />
              <label>Type Auction</label>
              <label className='form-label'>: {auctionDetail?.type}</label>
            </div>
          </Col>
          <Col>
            <div
              className='d-flex justify-content-end'
              data-kt-materialrequest-table-toolbar='base'
            >
              <label className='font-weight-bold' style={{fontWeight: '700'}}>
                {auctionDetail?.batas_awal} - {auctionDetail?.batas_akhir}
              </label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div
              className='d-flex justify-content-start'
              data-kt-materialrequest-table-toolbar='base'
            >
              <label className='form-label'>{auctionDetail?.perusahaan}</label>
            </div>
          </Col>
          <Col>
            <div
              className='d-flex justify-content-end'
              data-kt-materialrequest-table-toolbar='base'
            >
              <label>{auctionDetail?.catatan}</label>
            </div>
          </Col>
        </Row>
      </div>
      <KTCard>
        <KTCardBody className='py-4'>
          {auctionDetail?.isReadyPublish && (
            <Button
              onClick={() => {
                setIsConfirmPublish(true)
              }}
              className='btn btn-success btn-sm fw-bold'
              style={{float: 'inline-end', right: 5, marginTop: 10}}
            >
              Publish
            </Button>
          )}
          <Tabs style={{marginTop: 50}}>
            {auctionDetail?.status === 'published' ? (
              <Tab
                eventKey='tabulasi'
                disabled={auctionDetail?.status == 'draft'}
                title={'Tabulasi'}
                key={'tabulasi'}
              >
                {auctionDetail?.status != 'draft' ? <TabulasiAuction /> : <></>}
              </Tab>
            ) : (
              <Tab key='parameter' eventKey='parameter' title={'Parameter Auction'}>
                <ParameterAuction />
              </Tab>
            )}
            <Tab key='item' eventKey='item' title={'Item Auction'}>
              <ItemAuction />
            </Tab>
            <Tab key='peserta' eventKey='peserta' title={'Peserta'}>
              <PesertaAuction />
            </Tab>
            <Tab key='param-setting' eventKey='param-setting' title={'Param Setting'}>
              <ParamSettingAuction />
            </Tab>
            <Tab key='jadwal' eventKey='jadwal' title={'Jadwal'}>
              <JadwalAuction />
            </Tab>
            <Tab
              key='petunjuk-peraturan'
              eventKey='petunjuk-peraturan'
              title={'Petunjuk Peraturan'}
            >
              <PetunjukPeraturanAuction />
            </Tab>
            {auctionDetail?.status === 'published' ? (
              <Tab key='parameter' eventKey='parameter' title={'Parameter Auction'}>
                <ParameterAuction />
              </Tab>
            ) : (
              <Tab
                eventKey='tabulasi'
                disabled={auctionDetail?.status == 'draft'}
                title={'Tabulasi'}
                key={'tabulasi'}
              >
                {auctionDetail?.status != 'draft' ? <TabulasiAuction /> : <></>}
              </Tab>
            )}
            <Tab
              key='riwayat'
              eventKey='riwayat'
              disabled={auctionDetail?.status == 'draft'}
              title={'Riwayat'}
            >
              {auctionDetail?.status != 'draft' ? <RiwayatAuction /> : <></>}
            </Tab>
            <Tab eventKey='pemegang-lelang' disabled color='' title={'Pemegang Lelang'}>
              Pemegang Lelang
            </Tab>
            <Tab eventKey='pendandatanganan' disabled title={'Pendandatanganan'}>
              Pendandatanganan
            </Tab>
          </Tabs>
        </KTCardBody>
      </KTCard>
    </>
  )
};

export { AuctionDraft };
