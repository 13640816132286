import React, { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import ReactQuill from "react-quill";
import { useAxiosPost } from "../../../../../../hooks/useAxios";
import { getEnv } from "../../../../../../helpers/procurex";
import { toast } from "react-toastify";

const PetunjukPeraturanAuctionTab = ({ auction, onSave }: any) => {
  const headerFormSchema = Yup.object().shape({});
  const [initialValues, setInitialValues]: any = useState({
    petunjuk: auction?.petunjuk,
  });
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosPost(
    getEnv('auction') + `/auction/petunjuk/${auction?.id}`
  );

  const isFormEnabled = () => {
    return auction?.status === 'draft';
  }
  const onSubmit = async (values: any) => {
    const payload = {
      _method: 'PUT',
      petunjuk: values?.petunjuk
    }
    doSubmit(payload, undefined, toast).then((res: any) => {
      onSave && onSave();
    });
  };
  return (
    <>
      <Formik
        enableReinitialize
        validationSchema={headerFormSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({ errors, touched, values, setFieldValue }: any) => (
          <Form>
            <div className={"row"}>
              <div className={"col-md-12"}>
                <div className="form-group mb-10">
                  <label className="form-label mb-3">Peraturan</label>
                  <ReactQuill
                    value={values?.petunjuk}
                    theme='snow'
                    onChange={(value) => {
                      setFieldValue('petunjuk', value)
                    }}
                    modules={{
                      toolbar: [
                        [{ header: [1, 2, false] }],
                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                        ['link', 'image'],
                        ['clean'],
                      ],
                    }}
                    style={{
                      height: '500px'
                    }}
                    readOnly={!isFormEnabled()}
                  />
                </div>
                <div className='form-group text-end pt-10'>
                  <button
                    type='submit'
                    className='btn btn-primary btn-sm'
                    disabled={isLoading || !isFormEnabled()}
                  >
                    {isLoading && (
                      <span className='spinner-border spinner-border-sm me-1'></span>
                    )}
                    {!isLoading && <i className='fa fa-save me-2'></i>}
                    Simpan
                  </button>
                </div>
              </div>
            </div>

          </Form>
        )
        }
      </Formik>
    </>
  );
};

export { PetunjukPeraturanAuctionTab };
