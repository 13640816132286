import { useEffect, useState } from "react";
import Swal from 'sweetalert2'
import {toast} from 'react-toastify'


const LatencyChecker = ({ isOnline }) => {
  const url = process.env.REACT_APP_WEBSOCKET_URL;
  const [fetchLatency, setFetchLatency] = useState(null);
  const [error, setError] = useState(null);
  const [statusOnlineBefore, setStatusOnlineBefore] = useState(isOnline);
  const [isRequestPending, setIsRequestPending] = useState(false);

  const measureFetchLatency = async () => {
    if (isRequestPending) return;
    setIsRequestPending(true);
    try {
      const start = performance.now();
      const response = await fetch(`${url}?time=${start}`, { mode: 'no-cors' });
      if (!response.ok && response.type !== 'opaque') {
        throw new Error('Network response was not ok');
      }
      const end = performance.now();
      setFetchLatency((end - start).toFixed(2));
      setError(null);
    } catch (error) {
      setFetchLatency(<i className={"fa infinite"} />);
      setError(error.message);
    } finally {
      setIsRequestPending(false);
    }
  };

  useEffect(() => {
    if (isOnline) {
      measureFetchLatency();
      const interval = setInterval(measureFetchLatency, 5000); // Increased interval to 5 seconds
      return () => clearInterval(interval);
    }
  }, [url, isOnline]);

  useEffect(() => {
    if(fetchLatency > 500) {
      toast.error("We detect your connection is not stable.",{
        toastId: 1,
        theme: 'dark',
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeButton: false,
        pauseOnHover: false,
        className: 'text-nowrap w-100'
      });
    }
  }, [fetchLatency]);

  useEffect(() => {
    if (!isOnline) {
      toast.error("You are offline");
      setStatusOnlineBefore(isOnline);
    } else if (statusOnlineBefore !== isOnline) {
      toast.success("Welcome back, you are online now");
      setStatusOnlineBefore(isOnline);
    }
  }, [isOnline]);

  if (!isOnline) {
    return (
      <div className='d-flex flex-row gap-2 align-items-center'>
        <div><i className='fa fa-plane-slash text-danger' /></div>
        <i className='text-danger' style={{ fontWeight: "bold" }}></i>
      </div>
    );
  }

  return (
    <div className='d-flex flex-column items-center align-middle justify-content-center text-center'>
      <div className='text-sm' style={{
        fontSize: '8pt',
        fontWeight: 'bold',
        color: fetchLatency < 100 ? '#50cd89' : (fetchLatency < 200 ? 'orange' : 'red'),
      }}>{fetchLatency} ms
      </div>
      <div className={(fetchLatency > 100 ? 'blink_me' : '')}>
        <span style={{
          color: fetchLatency < 100 ? '#50cd89' : (fetchLatency < 200 ? 'orange' : 'red'),
        }}>{fetchLatency > 100 ? '!' : ''}</span>
        <i className={'fa fa-wifi-3 '} style={{
        fontWeight: 'bold',
        color: fetchLatency < 100 ? '#50cd89' : (fetchLatency < 200 ? 'orange' : 'red'),
      }} /></div>
    </div>
  );
};

const StatusOnline = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine)
  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    // Listen to the online status
    window.addEventListener('online', handleStatusChange);

    // Listen to the offline status
    window.addEventListener('offline', handleStatusChange);

    if(!isOnline) {
      Swal.fire(
        'Whoopss, it seems you are offline, please check your connection',
        "",
        "error"
      );
    }

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
    };
  }, [isOnline]);
  return (
    <div className={"d-flex flex-col gap-2"}>

      <LatencyChecker isOnline={isOnline} />
    </div>
  );
}

export { StatusOnline };