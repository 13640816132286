import { ID, KTCard, KTCardBody } from '../../../../../../_metronic/helpers'
import React, { useState, useRef } from 'react'
import { Link } from 'react-router-dom';
import TableXWrpper from "../../../../../components/shared/TableXWrapper";
import { ApiUrl } from '../../../../../config/Api'
import { getEnv } from "../../../../../../helpers/procurex";
import { Field, Form, Formik } from 'formik'
import SelectX from '../../../../../components/shared/form/SelectX'
import FormikPublicSelect from '../../../../home/vendor-register/components/FormikPublicSelect'
import { toast } from 'react-toastify'
import axios from 'axios'
import { Button, ButtonGroup, Col, Modal, Row } from "react-bootstrap";
import { useAuth } from "../../../../../../app/modules/auth";

const MaterialMasterDataStockViewList = () => {
    const { currentUser } = useAuth();
    const tablecolumns = [
        {
            Header: "MATERIAL NUMBER",
            accessor: "material_number",
            width: 160,
        },
        {
            Header: "MATERIAL DESC",
            accessor: "material_desc",
            width: 250,
        },
        {
            Header: "PLANT",
            accessor: "kode_plant",
            width: 160,
        },
        {
            Header: "UOM",
            accessor: "uom",
            width: 150,
        },
        {
            Header: "COMPANY",
            accessor: "company",
            width: 150,
        },
        {
            Header: "STATUS",
            accessor: "active_app",
            width: 150,
            Cell: (props: any) => {
                const row = props.row.original;
                return (
                    <label className="form-check-label" htmlFor="flexSwitchDefault">
                        {row.active_app ? 'Active' : 'Inactive'}
                    </label>
                );
            },
        },
        {
            Header: "Action",
            accessor: "uuid",
            width: 180,
            className: "text-center fixed-column",
            Cell: (props: any) => {
                const row = props.row.original;
                return (
                    <ButtonGroup size='sm' aria-label='Action'>
                        {!excludedRoles.some(r => role.includes(r)) && (
                            <>
                                <button
                                    type='button'
                                    className='btn btn-sm btn-success'
                                    onClick={(e: any) => {
                                        e.preventDefault();
                                        setId(row.id);
                                        openModalWithItem(row); // Pass the row item to the function
                                    }}
                                >
                                    Edit
                                </button>
                            </>
                        )}
                    </ButtonGroup>
                );
            },
        },
    ];

    const [formIndex, setFormIndex] = useState(0);
    const [isShowModal, setIsShowModal] = useState(false)
    const [isShowDetail, setIsShowDetail] = useState(false)
    const [key, setKey] = useState(0)
    const [id, setId] = useState('')
    const [item, setItem] = useState<any>(null)
    const formRef = useRef({} as any)
    const [isConfirmDialog, setIsConfirmDialog] = useState(false)
    const excludedRoles = ['admin_anper'];

    const submit = async (values: any) => {
        try {
            console.log(values);

            const response = await axios.put(getEnv("stockview") + `/material-plant/${id}`, values)

            if (response.status === 200 || response.status === 201) {
                toast.success(response.data.meta.message)
                setId('')
                setIsShowModal(false)
                setKey(key + 1)
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.meta?.message || 'Terjadi kesalahan')
        } finally {
            setIsConfirmDialog(false)
            setIsShowDetail(false)
        }
    }

    const [initialValues, setInitialValues] = useState<any>({
        active_app: item?.active_app || '',
    })

    const openModalWithItem = (item: any) => {
        // Set the initial value of active_app based on the item
        setInitialValues({
            active_app: item?.active_app || ''
        });
        // Set the item and open the modal
        setItem(item);
        setIsShowModal(true);
    };

    let role = currentUser.has_roles;
    let company_code_user = currentUser.company_code[0];

    return (
        <>
            <Modal show={isShowModal} onHide={() => setIsShowModal(false)}>
                <Modal.Header>Edit Material Status</Modal.Header>
                <Modal.Body>
                    <Formik initialValues={initialValues} onSubmit={submit} enableReinitialize>
                        {({ values, setFieldValue }) => (
                            <Form>
                                <div className='mb-10'>
                                    <div className="form-check form-switch form-check-custom form-check-solid">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={values.active_app !== ''}
                                            onChange={(e) => setFieldValue('active_app', e.target.checked ? 'X' : '')}
                                            id="flexSwitchDefault"
                                        />
                                        <label className="form-check-label" htmlFor="flexSwitchDefault">
                                            {values.active_app !== '' ? 'Active' : 'Inactive'}
                                        </label>
                                    </div>
                                </div>

                                <div className='d-flex gap-4 mt-5'>
                                    <button type='submit' className='btn btn-primary'>
                                        Save
                                    </button>
                                    <button
                                        type='button'
                                        className='btn btn-secondary'
                                        onClick={() => {
                                            setId('')
                                            setIsShowModal(false)
                                        }}
                                    >
                                        Batal
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>

            <KTCard className='card border mt-5'>
                <KTCardBody >
                    <TableXWrpper
                        title={"Material Master Data"}
                        key={formIndex}
                        readonly
                        urlIndex={getEnv("stockview") + `/material-plant?company_code=${company_code_user}`}
                        columns={tablecolumns}
                    />
                </KTCardBody>
            </KTCard>
        </>
    )
}

export { MaterialMasterDataStockViewList }
