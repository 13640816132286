import SelectX from "../../../../../components/shared/form/SelectX"
import { useEffect } from "react"

interface Props {
    data: any
    key: any
    loadingDocument: boolean
    loadingSubmit: boolean
    setJenisDocumentGenerate: Function
}

const optionsTemplateDocument: { [key: string]: any } = {
    'A000': [
      { label: 'Purchase Order', value: 'Po' },
      { label: 'SSUK A000 JPP', value: 'Jpp SSUK' },
      { label: 'SSUK A000 Konstruksi', value: 'Konstruksi SSUK' },
      { label: 'SSUK A000 Konsultasi', value: 'Konsultasi SSUK' },
      { label: 'SSUK A000 Lumpsum', value: 'Lumpsum SSUK' },
      { label: 'SSUK A000 Sewa', value: 'Sewa SSUK' },
      { label: 'SSUK A000 Umum dan Bidang Lain', value: 'Umum dan Bidang Lain SSUK' },
      { label: 'SSKK A000 JPP', value: 'Jpp' },
      { label: 'SSKK A000 Konstruksi', value: 'Konstruksi' },
      { label: 'SSKK A000 Konsultasi', value: 'Konsultasi' },
      { label: 'SSKK A000 Sewa', value: 'Sewa' },
      { label: 'SSKK A000 Umum dan Bidang Lain', value: 'Umum dan Bidang Lain' },
    ],
    'B000': [
      { label: 'Purchase Order', value: 'Po' },
      { label: 'SSUK B000 JPP', value: 'Jpp SSUK' },
      { label: 'SSUK B000 Konstruksi', value: 'Konstruksi SSUK' },
      { label: 'SSUK B000 Konsultasi', value: 'Konsultasi SSUK' },
      { label: 'SSUK B000 Lumpsum', value: 'Lumpsum SSUK' },
      { label: 'SSUK B000 Sewa', value: 'Sewa SSUK' },
      { label: 'SSUK B000 Umum dan Bidang Lain', value: 'Umum dan Bidang Lain SSUK' },
      { label: 'SSKK B000 JPP', value: 'Jpp' },
      { label: 'SSKK B000 Konstruksi', value: 'Konstruksi' },
      { label: 'SSKK B000 Konsultasi', value: 'Konsultasi' },
      { label: 'SSKK B000 Sewa', value: 'Sewa' },
      { label: 'SSKK B000 Umum dan Bidang Lain', value: 'Umum dan Bidang Lain' },
    ],
    'C000': [
      { label: 'Purchase Order', value: 'Po' },
      { label: 'SSUK C000 JPP', value: 'Jpp SSUK' },
      { label: 'SSUK C000 Konstruksi', value: 'Konstruksi SSUK' },
      { label: 'SSUK C000 Konsultasi', value: 'Konsultasi SSUK' },
      { label: 'SSUK C000 Lumpsum', value: 'Lumpsum SSUK' },
      { label: 'SSUK C000 Sewa', value: 'Sewa SSUK' },
      { label: 'SSUK C000 Umum dan Bidang Lain', value: 'Umum dan Bidang Lain SSUK' },
      { label: 'SSKK C000 JPP', value: 'Jpp' },
      { label: 'SSKK C000 Konstruksi', value: 'Konstruksi' },
      { label: 'SSKK C000 Konsultasi', value: 'Konsultasi' },
      { label: 'SSKK C000 Sewa', value: 'Sewa' },
      { label: 'SSKK C000 Umum dan Bidang Lain', value: 'Umum dan Bidang Lain' },
    ],
    'D000': [
      { label: 'Purchase Order', value: 'Po' },
      { label: 'SSUK D000 JPP', value: 'Jpp SSUK' },
      { label: 'SSUK D000 Konstruksi', value: 'Konstruksi SSUK' },
      { label: 'SSUK D000 Konsultasi', value: 'Konsultasi SSUK' },
      { label: 'SSUK D000 Lumpsum', value: 'Lumpsum SSUK' },
      { label: 'SSUK D000 Sewa', value: 'Sewa SSUK' },
      { label: 'SSUK D000 Umum dan Bidang Lain', value: 'Umum dan Bidang Lain SSUK' },
      { label: 'SSKK D000 JPP', value: 'Jpp' },
      { label: 'SSKK D000 Konstruksi', value: 'Konstruksi' },
      { label: 'SSKK D000 Konsultasi', value: 'Konsultasi' },
      { label: 'SSKK D000 Sewa', value: 'Sewa' },
      { label: 'SSKK D000 Umum dan Bidang Lain', value: 'Umum dan Bidang Lain' },
    ],
    'E000': [
      { label: 'Purchase Order', value: 'Po' },
      { label: 'SSUK E000 JPP', value: 'Jpp SSUK' },
      { label: 'SSUK E000 Konstruksi', value: 'Konstruksi SSUK' },
      { label: 'SSUK E000 Konsultasi', value: 'Konsultasi SSUK' },
      { label: 'SSUK E000 Lumpsum', value: 'Lumpsum SSUK' },
      { label: 'SSUK E000 Sewa', value: 'Sewa SSUK' },
      { label: 'SSUK E000 Umum dan Bidang Lain', value: 'Umum dan Bidang Lain SSUK' },
      { label: 'SSKK E000 JPP', value: 'Jpp' },
      { label: 'SSKK E000 Konstruksi', value: 'Konstruksi' },
      { label: 'SSKK E000 Konsultasi', value: 'Konsultasi' },
      { label: 'SSKK E000 Sewa', value: 'Sewa' },
      { label: 'SSKK E000 Umum dan Bidang Lain', value: 'Umum dan Bidang Lain' },
    ],
    'F000': [
      { label: 'Purchase Order', value: 'Po' },
      { label: 'SSUK F000 JPP', value: 'Jpp SSUK' },
      { label: 'SSUK F000 Konstruksi', value: 'Konstruksi SSUK' },
      { label: 'SSUK F000 Konsultasi', value: 'Konsultasi SSUK' },
      { label: 'SSUK F000 Lumpsum', value: 'Lumpsum SSUK' },
      { label: 'SSUK F000 Sewa', value: 'Sewa SSUK' },
      { label: 'SSUK F000 Umum dan Bidang Lain', value: 'Umum dan Bidang Lain SSUK' },
      { label: 'SSKK F000 JPP', value: 'Jpp' },
      { label: 'SSKK F000 Konstruksi', value: 'Konstruksi' },
      { label: 'SSKK F000 Konsultasi', value: 'Konsultasi' },
      { label: 'SSKK F000 Sewa', value: 'Sewa' },
      { label: 'SSKK F000 Umum dan Bidang Lain', value: 'Umum dan Bidang Lain' },
    ],
    'G000': [
      { label: 'Purchase Order', value: 'Po' },
      { label: 'SSUK G000 JPP', value: 'Jpp SSUK' },
      { label: 'SSUK G000 Konstruksi', value: 'Konstruksi SSUK' },
      { label: 'SSUK G000 Konsultasi', value: 'Konsultasi SSUK' },
      { label: 'SSUK G000 Lumpsum', value: 'Lumpsum SSUK' },
      { label: 'SSUK G000 Sewa', value: 'Sewa SSUK' },
      { label: 'SSUK G000 Umum dan Bidang Lain', value: 'Umum dan Bidang Lain SSUK' },
      { label: 'SSKK G000 JPP', value: 'Jpp' },
      { label: 'SSKK G000 Konstruksi', value: 'Konstruksi' },
      { label: 'SSKK G000 Konsultasi', value: 'Konsultasi' },
      { label: 'SSKK G000 Sewa', value: 'Sewa' },
      { label: 'SSKK G000 Umum dan Bidang Lain', value: 'Umum dan Bidang Lain' },
    ],
    'H000': [
      { label: 'Purchase Order', value: 'Po' },
      { label: 'SSUK H000 JPP', value: 'Jpp SSUK' },
      { label: 'SSUK H000 Konstruksi', value: 'Konstruksi SSUK' },
      { label: 'SSUK H000 Konsultasi', value: 'Konsultasi SSUK' },
      { label: 'SSUK H000 Lumpsum', value: 'Lumpsum SSUK' },
      { label: 'SSUK H000 Sewa', value: 'Sewa SSUK' },
      { label: 'SSUK H000 Umum dan Bidang Lain', value: 'Umum dan Bidang Lain SSUK' },
      { label: 'SSKK H000 JPP', value: 'Jpp' },
      { label: 'SSKK H000 Konstruksi', value: 'Konstruksi' },
      { label: 'SSKK H000 Konsultasi', value: 'Konsultasi' },
      { label: 'SSKK H000 Sewa', value: 'Sewa' },
      { label: 'SSKK H000 Umum dan Bidang Lain', value: 'Umum dan Bidang Lain' },
    ],
    'I000': [
      { label: 'Purchase Order', value: 'Po' },
      { label: 'SSUK I000 JPP', value: 'Jpp SSUK' },
      { label: 'SSUK I000 Konstruksi', value: 'Konstruksi SSUK' },
      { label: 'SSUK I000 Konsultasi', value: 'Konsultasi SSUK' },
      { label: 'SSUK I000 Lumpsum', value: 'Lumpsum SSUK' },
      { label: 'SSUK I000 Sewa', value: 'Sewa SSUK' },
      { label: 'SSUK I000 Umum dan Bidang Lain', value: 'Umum dan Bidang Lain SSUK' },
      { label: 'SSKK I000 JPP', value: 'Jpp' },
      { label: 'SSKK I000 Konstruksi', value: 'Konstruksi' },
      { label: 'SSKK I000 Konsultasi', value: 'Konsultasi' },
      { label: 'SSKK I000 Sewa', value: 'Sewa' },
      { label: 'SSKK I000 Umum dan Bidang Lain', value: 'Umum dan Bidang Lain' },
    ],
    'J000': [
      { label: 'Purchase Order', value: 'Po' },
      { label: 'SSUK J000 JPP', value: 'Jpp SSUK' },
      { label: 'SSUK J000 Konstruksi', value: 'Konstruksi SSUK' },
      { label: 'SSUK J000 Konsultasi', value: 'Konsultasi SSUK' },
      { label: 'SSUK J000 Lumpsum', value: 'Lumpsum SSUK' },
      { label: 'SSUK J000 Sewa', value: 'Sewa SSUK' },
      { label: 'SSUK J000 Umum dan Bidang Lain', value: 'Umum dan Bidang Lain SSUK' },
      { label: 'SSKK J000 JPP', value: 'Jpp' },
      { label: 'SSKK J000 Konstruksi', value: 'Konstruksi' },
      { label: 'SSKK J000 Konsultasi', value: 'Konsultasi' },
      { label: 'SSKK J000 Sewa', value: 'Sewa' },
      { label: 'SSKK J000 Umum dan Bidang Lain', value: 'Umum dan Bidang Lain' },
    ],
}

const SelectXTemplateDocument = ({data, key, loadingDocument, loadingSubmit, setJenisDocumentGenerate}: Props) => {

    useEffect(() => {
        setJenisDocumentGenerate({})
    }, [loadingDocument || loadingSubmit])

    return (
        <>
            <label htmlFor='' className='required form-label'>
                Template Document
            </label>
            <SelectX
                key={key}
                options={optionsTemplateDocument[data?.company_code]}
                onChange={(e: any) => {
                    setJenisDocumentGenerate(e)
                }}
                onValue={true}
                isDisabled={loadingDocument || loadingSubmit}
            />
        </>
    )
}

export default SelectXTemplateDocument