import React, {useEffect, useState} from 'react'
import {useAxiosGet, useAxiosPost} from '../../../../../../../hooks/useAxios'
import {getEnv} from '../../../../../../../helpers/procurex'
import {Spinner} from 'react-bootstrap'
import BottomToolbarWrapper from '../../../../../../components/shared/BottomToolbarWrapper'
import {useNavigate} from 'react-router-dom'
import {ApprovalX} from '../../../../../../components/shared/ApprovalX'
import {toast} from 'react-toastify'
import {BeritaAcaraPenunjukkanPemenangTab} from './BeritaAcaraPenunjukkanPemenangTab'
import FlagRejected from '../FlagRejected'
import Swal from 'sweetalert2'
import useGet from '../../../../../../../hooks/useGet'


const PenunjukkanPemenangPage = ({ proposalTender, onRefresh }: any) => {
  const [{ doSubmit, isLoading }] = useAxiosPost(getEnv("tender") + "/tender/submit");
  const [{doGetDetail: doGetVendors, data: vendors, isLoading: isLoadingGetVendors}] = useGet();
  const [{ doSubmit: doSubmitGet, isLoading: isLoadingGet}] = useAxiosGet("")
  const navigate = useNavigate()
  const [isShowApprovalSchemaPengumumanPemenang, setIsShowApprovalSchemaPengumumanPemenang] = useState(false);
  const [approvalSchemaPengumumanPemenang, setApprovalSchemaPengumumanPemenang] = React.useState([]);
  const [baKey,setBaKey] = useState(0)
  const [vendorWinner, setVendorWinner] = useState<any>([])

  const showSidebar = () => {
    // hide sidebar
    const elementToClick = document.getElementById('kt_aside_toggle');

    if (elementToClick && elementToClick.classList.contains('active')) {
      // Memicu klik pada elemen
      elementToClick.click();
    }
  }

  const onGoBack = () => {
    showSidebar();
    const path = localStorage.getItem("backTo");
    // alert(path)
    navigate(path ? path : "/tender/admin/proposal-tender/draft");
    // navigate('/tender/admin/proposal-tender')
  };

  const onShowModalApproval = async() => {
    const newUrl = getEnv("tender") + `/awarding-designation/routing-approval-code/${proposalTender?.uuid}`;
    doSubmitGet({},newUrl, undefined).then((res: any) => {
      setApprovalSchemaPengumumanPemenang(res.data?.details);
      setIsShowApprovalSchemaPengumumanPemenang(true);
    }).finally(() => {
    });
  }

  const handleOnSubmit = async (values:any)=>  {
    const payload = {
      approvers: values.approval.map((item: any) => {
        return {
          step: item.step,
          jabatan: item.jabatan,
          type: "APPROVAL",
          user_id: item.extra_info.user.user_id,
          extra_info: item.extra_info
        };
      }),
    };
    // console.log(payload);

    const newUrl = getEnv("tender") + `/awarding-designation/submit/${proposalTender?.uuid}`;
    doSubmit(payload, newUrl, toast, () => {
    }).then((data: any) => {
      setIsShowApprovalSchemaPengumumanPemenang(false);
      // props.afterSaveDraft && props.afterSaveDraft();
      onRefresh && onRefresh();
    }).catch(() => {
    });
  }

  const [{ doSubmit: doSubmitRollback, isLoading: isLoadingRollback }] = useAxiosPost(getEnv("tender") + `/awarding-designation/rollback/${proposalTender?.uuid}`);

  const onSubmitRollback = () => {
    Swal.fire({
      title: "Konfirmasi",
      text: "Apakah anda yakin ingin kembali ke prosesollback?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, Lanjutkan ",
      cancelButtonText: "Tidak"
    }).then(async (result) => {
      if (result.isConfirmed) {
        doSubmitRollback({},undefined,toast).then((res:any)=> {
          window.location.reload();
        })
      }
    })
  };

  useEffect(() => {
    doGetVendors(getEnv("tender") + `/negotiation/${proposalTender?.negotiation?.uuid}`, toast)
  }, []);

  useEffect(() => {
    if (vendors) {
      setVendorWinner(vendors?.vendors?.filter((vendor: any) => vendor?.status_tender === "WINNER"))
    }
  }, [vendors]);

  return (
    <div>
      {proposalTender && (
        <FlagRejected proposalTender={proposalTender} type={"Penunjukan Pemenang"} />
      )}

      <div className={"d-flex justify-content-between"}>
        <div><h3>Penunjukkan Pemenang</h3></div>
      </div>

      <hr />

      <BeritaAcaraPenunjukkanPemenangTab
        key={baKey}
        proposalTender={proposalTender}
        vendorWinner={vendorWinner}
        onRefresh={()=>{
          onRefresh()
          setBaKey(baKey+1)
        }}
      />
      
      <BottomToolbarWrapper onGoBack={onGoBack} children={
        <>
          {(proposalTender?.status === "Penunjukan Pemenang" &&
            (proposalTender?.status_detail === "Draft" ||
            proposalTender?.status_detail === "Rejected")) &&
            <>
              <button
                type={'button'}
                disabled={isLoading}
                className={'btn btn-lg btn-danger me-3'}
                onClick={() => onSubmitRollback()}
              >
                {isLoading ? (
                  <i className="fa fa-spinner fa-spin"></i>
                ) : (
                  <i className={'fa fa-undo'}></i>
                )}
                {proposalTender?.required_announcement_winner ? (
                  "Kembali Ke Pengumuman Pemenang"
                ) : (
                  "Kembali Ke LUP"
                )}
              </button>
              <button
                className={"btn btn-primary"}
                type={"button"}
                onClick={onShowModalApproval}
              >
                {isLoading && <Spinner className={"spinner-grow-sm"} animation="border" size="sm" />}
                {!isLoading && <i className={"fa fa-paper-plane me-2"}></i>}
                Submit Penunjukkan Pemenang
              </button>
            </>
          }

        </>
      }
      />

      <ApprovalX
        show={isShowApprovalSchemaPengumumanPemenang}
        schema={approvalSchemaPengumumanPemenang}
        onHide={() => {
          setIsShowApprovalSchemaPengumumanPemenang(false);
        }}
        onSubmit={handleOnSubmit}
        isLoading={isLoading}
        source={"TENDER"}
        object_id={proposalTender?.id}
        company_id={proposalTender?.company?.id}
        code={proposalTender?.routing_approval_config_code}
        readOnly={isLoading}
        // readOnly={props.proposalTender?.is_on_approval}
      />

    </div>
  );
};

export default PenunjukkanPemenangPage;