import React from "react";
import { KTCard, KTCardBody } from "../../../../../_metronic/helpers";
import SideLeftLayout from "./components/SideLeftLayout";
import ParameterPengadaanPage from "./components/ParameterPengadaanPage";
import { useNavigate } from "react-router-dom";
import { Placeholder } from "rsuite";
import { useIntl } from "react-intl";

interface IProposalTenderForm {
  proposalTender: any,
  onRegisterPQ?: any,
  onReload?: any,
  source: any
  ButtonToolbar?: any
  fillRequirementURL?: string
}

const ProposalTenderForm = ({ proposalTender, onRegisterPQ, source, onReload, ButtonToolbar, fillRequirementURL }: IProposalTenderForm) => {
  const [selectedMenu, setSelectedMenu] = React.useState({
    index: 0,
    headerIndex: 1,
    component: null
  });
  const intl = useIntl();
  const [isHideSidebar,setIsHideSidebar] = React.useState(false);
  const [isButtonToolbarLoading, setIsButtonToolbarLoading] = React.useState(true);
  const refParameterPengadaan = React.useRef(null);
  const navigate = useNavigate();
  const showSidebar = () => {
    // hide sidebar
    const elementToClick = document.getElementById('kt_aside_toggle');

    if (elementToClick && elementToClick.classList.contains('active')) {
      // Memicu klik pada elemen
      elementToClick.click();
    }
  }
  const goBack = () => {
    showSidebar();
    navigate(-2);
  };

  // @ts-ignore
  return (
    <>
      {/*<PageAlertX*/}
      {/*  title={"Warning"}*/}
      {/*  variant={"warning"}*/}
      {/*  message={"Lorem Ipsum is simply dummy text of the printing and typesetting industry"}*/}
      {/*/>*/}
      <div className={"row mb-3"}>
        <div className={"col-md-3 col-sm-12 col-lg-3"}>
          <div className={"mb-5"}>
            <button className={"btn btn-sm btn-danger me-3"} onClick={goBack}>
              <i className={"fa fa-arrow-left"}></i>
              {intl.formatMessage({id:'Kembali ke list'})}
            </button>
          </div>
        </div>
        <div className={"col-md-9 col-sm-12 col-lg-9 text-end"}>
          <div className={"mb-5"}>
            { ButtonToolbar }
          </div>
        </div>
        <div className={isHideSidebar ? "col-md-1 col-sm-12 col-lg-1" : 'col-md-3 col-sm-12 col-lg-3'}>
          <div className={"d-flex align-items-end justify-content-start"}>

          </div>
          <div style={{ position: 'sticky', top: '80px' }}>
            <KTCard>
              <KTCardBody className="p-3 mb-3">
                {!proposalTender && (
                  <>
                    <Placeholder.Paragraph active style={{ marginTop: 30 }} />
                    <Placeholder.Paragraph active />
                    <Placeholder.Paragraph active />
                    <Placeholder.Paragraph active />
                    <Placeholder.Paragraph active />
                    <Placeholder.Paragraph active />
                    <Placeholder.Paragraph active />
                    <Placeholder.Paragraph active />
                    <Placeholder.Paragraph active />
                    <Placeholder.Paragraph active />
                    <Placeholder.Paragraph active />
                  </>
                )}
                {proposalTender && (
                  <SideLeftLayout
                    source={source}
                    proposalTender={proposalTender}
                    onIndexChange={(e: any) => {
                      setSelectedMenu(e);
                    }}
                    onRefresh={() => {
                      onReload()
                    }}
                    isHide={isHideSidebar}
                    onHideButtonClicked={(e:any)=>{
                      setIsHideSidebar(e)
                    }}
                  />
                )}

              </KTCardBody>
            </KTCard>
          </div>
        </div>
        <div className={isHideSidebar ? "col-md-11 col-sm-12 col-lg-11" : 'col-md-9 col-sm-12 col-lg-9'}>
          <div className={"d-flex align-items-end justify-content-end"}>

          </div>
          {/*
						Karena button parameter pengadaan form selalu diatas, maka kita bisa menggunakan ref untuk mengakses methodnya
					*/}
          {!proposalTender && (
            <div className={"card card-body"}>
              <Placeholder.Paragraph rows={30}  active />
            </div>
          )}
          {proposalTender && (
            <>
              <ParameterPengadaanPage
                show={
                  // @ts-ignore
                  selectedMenu.index === 0 && selectedMenu.headerIndex === 1
                }
                ref={refParameterPengadaan}
                proposalTender={proposalTender}
                afterSaveDraft={() => onReload()} parent={this}
              />
              <>
                {selectedMenu?.component && (
                  selectedMenu?.component
                )}
              </>
            </>

          )}
        </div>
      </div>
    </>
  );
};

export { ProposalTenderForm };
