import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";
import { useAuth } from "../../modules/auth";
import { useNavigate } from "react-router-dom";
import { useAxiosGet } from "../../../hooks/useAxios";
import { formatDateTimeToDBFormat, getEnv } from "../../../helpers/procurex";
import { Placeholder, Panel } from "rsuite";
import parse from "html-react-parser";


const RowVersion = ({ item }: any) => {
  return (
    <Panel header={item?.version_no + " - " + formatDateTimeToDBFormat(item?.created_at) + " WIB"} className={"mb-4"} collapsible bordered>
      {parse(item?.description)}
    </Panel>
  )
};

const VersioningPage = () => {
  const intl = useIntl();
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [versionList, setVersionList] = useState([] as any);

  const [{ doSubmit, isLoading }] = useAxiosGet(getEnv("masterdata") + "/changelog");
  const getVersionList = () => {
    doSubmit({}, getEnv("masterdata") + "/changelog?sort=-id&perPage=100").then((res: any) => {
      setVersionList(res.data);
    });
  };

  useEffect(() => {
    if (currentUser?.has_roles.includes("vendor")) {
      navigate("/vendor/dashboard");
    }
    getVersionList();
  }, []);

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: "Release Note" })}</PageTitle>
      <div className="row g-5 g-xl-8 mb-5">
        <div className="col-12 bg-white py-4">
          {isLoading && (
            <Placeholder.Paragraph rows={5} active />
          )}
          {versionList?.map((item: any, index: number) => {
            return <RowVersion item={item} key={"version-list" + index} />;
          })}
        </div>
      </div>
    </>
  );
};

export { VersioningPage };
