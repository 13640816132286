import { Outlet, Route, Routes, useLocation } from "react-router-dom";
import { PageLink, PageTitle } from "../../../../../_metronic/layout/core";
import React, { useState } from "react";
import { ProposalTenderList } from "./ProposalTenderList";
import ProposalTenderVendorDetail from "./ProposalTenderVendorDetail";
import {ProposalTenderVendorProvider} from './core/_proposalTenderVendorProvider'

const masterData = {
  title: 'Tendering',
  path: 'tender',
  isSeparator: false,
  isActive: false,
}
const separator = {
  title: '',
  path: '',
  isSeparator: true,
  isActive: false,
}
const breadcrumbs: Array<PageLink> = [masterData, separator]

const ProposalTenderVendorPage = () => {
  const location = useLocation();
  const [index,setIndex] = useState(0); // Default status, bisa disesuaikan dengan default Anda
  return (
    <Routes key={index}>
      <Route element={<Outlet />}>
        <Route index element={<><PageTitle breadcrumbs={breadcrumbs}>Proposal Tender</PageTitle><ProposalTenderList status={"Draft"} /></>}></Route>
      </Route>
      <Route element={<Outlet />}>
        <Route path={"/:uuid"} element={
          <>
            <PageTitle breadcrumbs={breadcrumbs}>Proposal Tender Create</PageTitle>
            <ProposalTenderVendorProvider>
              <ProposalTenderVendorDetail />
            </ProposalTenderVendorProvider>
          </>
        }></Route>
      </Route>

    </Routes>
  )
}

export default ProposalTenderVendorPage
