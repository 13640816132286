import axios from "axios";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as Yup from "yup";
import { useMutation, useQuery } from "react-query";
import { Field, Form, Formik } from "formik";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import {getEnv, parseDBDateToJSFormat} from '../../../../../../helpers/procurex'
import FormikSelect3 from '../../../../../components/shared/form/FormikSelect3'
import DateRangePickerX from '../../../../../components/shared/form/DateRangePickerX'
import BottomToolbarWrapper from '../../../../../components/shared/BottomToolbarWrapper'

const fetchStoreAanwijzing = (payload:any) => {
  return axios.put(`${getEnv('tender')}/aanwijzing/${payload.uuid}`,payload)
}

const fetchSubmitForApproval = (payload:any) => {
  return axios.post(`${getEnv('tender')}/aanwijzing/submit/${payload.uuid}`,payload)
}


const GambaranUmumTab = ({proposalTender,isLoading, aanwijzing, onSubmitForApproval, onClickSubmitForApproval}:any) => {
  const formik = useRef({} as any);
  const [formIndex, setFormIndex] = React.useState(0);

  // @ts-ignore
  const [defaultJadwal, setDefaultJadwal] = React.useState<[Date, Date]>([]);
  const [isFormEnabled, setIsFormEnabled] = useState(true)
  const [initialValues,setInitialValues] = useState({
    title: "",
    source: "",
    note: "",
    media: "",
    reference: "",
    metode_aanwijzing: "",
    jadwal: "",
  });
  const formSchemaValidation = Yup.object().shape({
    title: Yup.string().required('Title harus diisi'),
    metode_aanwijzing: Yup.string().required("Metode Aanwijzing harus diisi"),
    reference: Yup.string().nullable(),
    media: Yup.string().required("Media harus diisi"),
    note: Yup.string().nullable(),
    jadwal: Yup.string().required("Jadwal harus diisi")
  });

  // const handleSaveDraft = async (values: any, errors: any) => {
  //   const dateJadwal = JSON.parse(values.jadwal);
  //   const payload = {
  //     title: values.title,
  //     reference: values.reference,
  //     metode_aanwijzing: values.metode_aanwijzing,
  //     note: values.note,
  //     media: values.media,
  //     // @ts-ignore
  //     start_at: parseDateTimeToDBFormat(dateJadwal[0]),
  //     // @ts-ignore
  //     end_at: parseDateTimeToDBFormat(dateJadwal[1]),
  //   };
  //   // await doSubmit(payload, toast, () => {
  //   //   props.afterSaveDraft && props.afterSaveDraft();
  //   // });
  // };

  //region store data
  const onSuccessStore = (data:any) => {
    toast.success(data.data.meta.message)
    onSubmitForApproval && onSubmitForApproval()
  }
  const onErrorStore = (error:any) => {}
  const {mutate:mutateStore, isLoading:isLoadingStore} = useMutation("store-aanwijzing",fetchStoreAanwijzing,{
    onSuccess: onSuccessStore,
    onError: onErrorStore
  })

  const handleSaveDraft = async (values: any) => {
    const dateJadwal = JSON.parse(values.jadwal);
    const payload = {
      ...values,
      uuid: aanwijzing?.uuid,
      // @ts-ignore
      start_at: parseDateTimeToDBFormat(dateJadwal[0]),
      // @ts-ignore
      end_at: parseDateTimeToDBFormat(dateJadwal[1]),
    }
    // console.log(payload)
    mutateStore(payload)
  }

  //endregion

  //region submit for approval
  const onSuccessGetRoutingApprovalCode = (data:any)=>{
    console.log(data)
  }
  const onErrorGetRoutingApprovalCode = (error:any)=>{
    toast.error(error)
  }




  useEffect(() => {
    if(aanwijzing?.id){
      setInitialValues({
        ...initialValues,
        title: aanwijzing.title,
        reference: aanwijzing.reference,
        media: aanwijzing.media,
        note: aanwijzing.note,
        metode_aanwijzing: aanwijzing.metode_aanwijzing,
        source: aanwijzing.source,
      })
      if(aanwijzing.start_at && aanwijzing.end_at) {
        setDefaultJadwal([parseDBDateToJSFormat(aanwijzing.start_at), parseDBDateToJSFormat(aanwijzing.end_at)])
      }

      //region set form enabled
      const aanwijzingStatusAllowed = ["DRAFT","OPEN","REJECTED"];
      if(!aanwijzingStatusAllowed.includes(aanwijzing.status)){
        setIsFormEnabled(false)
      }

      //endregion
      setFormIndex(formIndex+1)
    } else {
      setIsFormEnabled(true)
    }
  },[aanwijzing])

  const isShowSubmitForApproval = () => {
    return aanwijzing?.status === "OPEN" ||
      aanwijzing?.status === "REJECTED" ||
      checkStatus()
  }

  const isOnApproval = () => {
    return aanwijzing?.status === "SUBMITTED"
  }

  const checkStatus = useCallback(() => {
    if(proposalTender){
      const allowedStatusTender = [
        'Pra Qualification',
        'DUR',
        'Published',
        'Aanwijzing',
        'Registrasi',
        'Penawaran',
        'Penawaran Teknikal',
        'Penawaran Komersil',
        'Bid Opening',
        'Bid Opening Teknis'
      ];
      return allowedStatusTender.includes(proposalTender.status)
    }
    return true;
  },[])

  // const isCanSaveDraft = useCallback(() => {
  //   if(aanwijzing?.status === "SUBMITTED") return false;
  //   return isLoadingStore
  //     || isLoadingGetApprovalCode
  //     || isLoading
  //     || isLoadingSubmitForApproval
  //     || aanwijzing?.status === "Draft  "
  //     || checkProposalTender()
  // },[])

  const handleOnSubmitForApproval = () => {
    onClickSubmitForApproval && onClickSubmitForApproval();
  }

  return (
    <>
      <div className={"d-flex justify-content-between"}>


      </div>
      <Formik
        innerRef={formik}
        initialValues={initialValues}
        onSubmit={handleSaveDraft}
        validationSchema={formSchemaValidation}
        enableReinitialize
        validateOnChange={true}
        key={formIndex}
      >
        {({ errors, touched, values, setFieldValue, resetForm, submitForm }: any) => (
          <Form id="form-hps">
            <div className={"card mb-4"}>
              <div className={"card-body"}>
                <div className={"row"}>
                  <div className={"col-md-6 col-sm-12 col-lg-6"}>
                    <div className={"form-group mb-3"}>
                      <label className="required form-label">
                        Title
                      </label>
                      <Field
                        name="title"
                        type="text"
                        className="form-control"
                        disabled={!isFormEnabled}
                      />
                      {(errors.title && touched.title) && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.title}</div>
                        </div>
                      )}
                    </div>

                    <div className={"form-group mb-3"}>
                      <FormikSelect3
                        label="Metode Aanwijzing"
                        name="metode_aanwijzing"
                        optionsUrl={`${getEnv('masterdata')}/enumeration?filter[type]=TENDER_METODE_AANWIJZING`}
                        getOptionValue={(opt: any) => opt.value}
                        getOptionLabel={(opt: any) =>
                          `${opt.value}`
                        }
                        isDisabled={!isFormEnabled}
                      />
                      {(errors.metode_aanwijzing && touched.metode_aanwijzing) && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.metode_aanwijzing}</div>
                        </div>
                      )}
                    </div>

                    <div className={"form-group mb-3"}>
                      <label className="form-label">
                        Media
                      </label>
                      <Field
                        name="media"
                        type="text"
                        className="form-control"
                        label={"media"}
                        disabled={!isFormEnabled}
                        placeholder={"Ex: Zoom Link: http://zoom.us"}
                      />
                      {
                        (errors.media && touched.media) && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{errors.media}</div>
                          </div>
                        )
                      }
                    </div>

                    <div className={"form-group mb-3"}>
                      <label className="form-label">
                        Jadwal
                      </label>
                      <DateRangePickerX defaultValue={defaultJadwal} onChange={(e:any)=>{
                        if(e && e.length === 2) {
                          setFieldValue("jadwal",JSON.stringify(e))
                        } else {
                          setFieldValue("jadwal", "")
                        }
                      }} disabled={!isFormEnabled} />
                      {(errors.jadwal && touched.jadwal) && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.jadwal}</div>
                        </div>
                      )}
                    </div>

                  </div>
                  <div className={"col-md-6 col-sm-12 col-lg-6"}>
                    <div className={"form-group mb-3"}>
                      <label className="required form-label">
                        Source
                      </label>
                      <Field
                        name="source"
                        type="text"
                        className="form-control"
                        disabled={true}
                      />
                      {(errors.source && touched.source) && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.source}</div>
                        </div>
                      )}
                    </div>
                    <div className={"form-group mb-3"}>
                      <label className="form-label">
                        Nomor Referensi
                      </label>
                      <Field
                        name="reference"
                        type="text"
                        className="form-control"
                        label={"Incoterm"}
                        disabled={!isFormEnabled}
                      />
                      {
                        (errors.reference && touched.reference) && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{errors.reference}</div>
                          </div>
                        )
                      }
                    </div>
                    <div className={"form-group mb-3"}>
                      <label className="form-label">
                        Notes
                      </label>
                      <Field
                        name="note"
                        type="text"
                        className="form-control"
                        label={"note"}
                        disabled={!isFormEnabled}
                        as={"textarea"}
                      />
                      {
                        (errors.note && touched.note) && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{errors.note}</div>
                          </div>
                        )
                      }
                    </div>




                  </div>
                  {/*  End Col-6 */}

                  <div className={"col-md-12 col-sm-12 col-lg-12"}>

                  </div>
                </div>

              </div>
            </div>

          </Form>
        )}
      </Formik>


    </>
  )
}

export default GambaranUmumTab;