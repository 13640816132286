import React, {FC} from 'react'
import {Button, Modal} from "react-bootstrap";
import {useIntl} from "react-intl";
import LoadingX from "./LoadingX";

type IProps = {
    title: string,
    message: string,
    show: boolean,
    loading: boolean,
    size?: number,
    onHide: any,
    onSubmit: any,
    btnConfirmLabel?: string,
    btnConfirmIcon?: string,
    btnConfirmVariant?: string,
}
const SwalX: FC<IProps> = (props) => {
    const { title, loading, size, onHide, onSubmit, message, btnConfirmLabel, btnConfirmIcon, btnConfirmVariant } = props;

    return (
        <>
            <Modal show={props.show} onHide={()=>onHide(false)} centered>
                {/*<Modal.Header closeButton>*/}
                {/*    <Modal.Title>*/}
                {/*        {title}*/}
                {/*    </Modal.Title>*/}
                {/*</Modal.Header>*/}
                <Modal.Body>
                    <div className={"row"}>
                        <div className={"col-sm-2 text-center"}>
                            <i className={"fa fa-exclamation-triangle fa-3x text-danger"}></i>
                        </div>
                        <div className={"col-sm-10"}>
                            <h3><strong>{title}</strong></h3>
                            {message}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size={"sm"} disabled={loading} onClick={()=>onHide(true)} className={"d-flex flex-row align-items-center justify-content-center"}>
                        <i className={"fa fa-times"}></i>
                        {useIntl().formatMessage({id: 'DATATABLE.CLOSE'})}
                    </Button>
                    <Button variant={btnConfirmVariant ? btnConfirmVariant : 'danger'} size={"sm"} disabled={loading} type={"button"} onClick={()=>onSubmit(true)} className={"d-flex flex-row align-items-center justify-content-center"}>
                        <LoadingX show={loading}>
                            {btnConfirmIcon && <i className={btnConfirmIcon}></i>}
                            {!btnConfirmIcon && <i className={"fa fa-trash"}></i>}
                        </LoadingX>
                        {!btnConfirmLabel ? "Ya, Hapus" : btnConfirmLabel}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default SwalX;