import React, {useEffect, useState} from 'react'
import {useGetDetailProposalTender, useGetJadwalTender, usePostPersyaratan} from '../../../hooks/proposalTenderHook'
import * as Yup from 'yup'
import {Field, Form, Formik} from 'formik'
import {toast} from 'react-toastify'
import {useAxiosGet, useAxiosPost} from '../../../../../../../hooks/useAxios'
import {getEnv, parseStringToBool} from '../../../../../../../helpers/procurex'
import {Nav} from 'rsuite'
import {useIntl} from 'react-intl'
import {Outlet, Route, Routes, useLocation, useNavigate, useParams} from 'react-router-dom'
import TableXWrapper from '../../../../../../components/shared/TableXWrapper'
import {ButtonGroup, Col, Row, Spinner} from 'react-bootstrap'
import {toastError, toastSuccess} from '../../../../../../../_metronic/helpers'
import axios from 'axios'
import SwalX from '../../../../../../components/shared/SwalX'
import BottomToolbarWrapper from '../../../../../../components/shared/BottomToolbarWrapper'
import Swal from 'sweetalert2'

const FormDetailPersyaratan = ({proposalTender, selectedData, onHide, ...props}: any) => {
  const [currentProposalTender, setCurrentProposalTender] = useState(proposalTender)
  const [{doSubmit, isLoading, isError, data, isSuccess}] = usePostPersyaratan()
  const [{doSubmit: doSubmitGet}] = useAxiosGet(
    getEnv('tender') + `/tender/${proposalTender?.uuid}`,
  )
  const refFormik = React.useRef<any>()
  const [isDisabledForm, setIsDisabledForm] = React.useState(true)
  const intl = useIntl()
  const [initialValues, setInitialValues] = useState({
    bobot_teknis: '',
    bobot_komersial: '',
    ambang_batas: '',
  })
  const formSchemaValidation = Yup.object().shape({
    bobot_teknis: Yup.string().required('Required'),
    bobot_komersial: Yup.string().required('Required'),
    ambang_batas: Yup.string().required('Required'),
  })

  const getProposalTender = () => {
    const newUrl = getEnv('tender') + `/tender/${proposalTender?.uuid}`
    doSubmitGet({}, newUrl, undefined, () => {
    }).then((res: any) => {
      const _proposalTender = res.data
      setCurrentProposalTender(_proposalTender)
    }).catch((err: any) => {
      toast.error('Get Evaluasi Teknis: ' + err?.response?.data?.meta?.message)
    })
  }

  const initializeForm = () => {
    const syarats = currentProposalTender?.syarats.filter((item: any) => item.type === 'configuration')
    if (syarats.length > 0) {
      const syarat = syarats[0]
      setInitialValues({
        bobot_teknis: syarat?.bobot_teknis,
        bobot_komersial: syarat?.bobot_komersial,
        ambang_batas: syarat?.ambang_batas,
      })
    }
  }

  useEffect(() => {
    getProposalTender()
  }, [])

  useEffect(() => {
    setIsDisabledForm(checkIsDisableForm())
    const syarats = currentProposalTender?.syarats.filter((item: any) => item.type === 'configuration')
    if (syarats.length > 0) {
      const syarat = syarats[0]
      setInitialValues({
        bobot_teknis: syarat?.bobot_teknis,
        bobot_komersial: syarat?.bobot_komersial,
        ambang_batas: syarat?.ambang_batas,
      })
    }
  }, [currentProposalTender])

  const checkIsDisableForm = () => {
    return (currentProposalTender?.status_detail !== 'Draft' && currentProposalTender?.status_detail !== 'Rejected') ||
      currentProposalTender?.metode_evaluasi !== 'Sistem Nilai'
  }
  const handleSubmit = async (values: any) => {
    const payload = {
      proposal_tender_id: proposalTender?.uuid,
      type: 'configuration',
      bobot_teknis: values.bobot_teknis,
      bobot_komersial: values.bobot_komersial,
      ambang_batas: values.ambang_batas,
      source: props.source,
    }
    await doSubmit(payload, toast, () => {
      getProposalTender()
    })
    props.onRefresh && props.onRefresh()
  }
  return (
    <>
      <Formik
        innerRef={refFormik}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={formSchemaValidation}
        enableReinitialize
        validateOnChange={true}
      >
        {({errors, touched, values, setFieldValue, resetForm, submitForm}: any) => (
          <Form id="form-hps">
            <div className={'row py-4'}>
              <div className={'col-md-12'}>
                {proposalTender.metode_evaluasi === 'Sistem Nilai' && (
                  <div className={'alert alert-warning text-dark'}>
                    <strong>INFORMATION</strong>
                    <br />
                    Jumlah Bobot Teknis & Komersial harus sama dengan 100%.
                  </div>
                )}
                {proposalTender.metode_evaluasi !== 'Sistem Nilai' && (
                  <div className={'alert alert-info text-dark'}>
                    <strong>INFORMATION</strong>
                    <br />
                    Penggunaan Bobot hanya dapat dilakukan untuk metode evaluasi <strong>Sistem Nilai</strong>.
                  </div>
                )}
              </div>
              <div className={'col-md-6'}>
                <div className={'form-group mb-4'}>
                  <label className={'required'}>{intl.formatMessage({id: 'Bobot Teknis'})}</label>
                  <Field
                    name="bobot_teknis"
                    type="text"
                    className="form-control"
                    disabled={isDisabledForm}
                  />
                  {(errors.bobot_teknis && touched.bobot_teknis) && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{errors.bobot_teknis}</div>
                    </div>
                  )}
                </div>
                <div className={'form-group mb-4'}>
                  <label className={'required'}>{intl.formatMessage({id: 'Ambang Batas'})}</label>
                  <Field
                    name="ambang_batas"
                    type="text"
                    className="form-control"
                    disabled={isDisabledForm}
                  />
                  {(errors.ambang_batas && touched.ambang_batas) && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{errors.ambang_batas}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className={'col-md-6'}>
                <div className={'form-group mb-4'}>
                  <label className={'required'}>{intl.formatMessage({id: 'Bobot Komersil'})}</label>
                  <Field
                    name="bobot_komersial"
                    type="text"
                    className="form-control"
                    disabled={isDisabledForm}
                  />
                  {(errors.bobot_komersial && touched.bobot_komersial) && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{errors.bobot_komersial}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className={'col-md-12 mt-5 d-flex flex-end gap-2 border-top pt-4'}>
                <button className={'btn btn-primary btn-sm'} disabled={isLoading || isDisabledForm}>
                  {isLoading && <i className={'fa fa-spinner fa-spin'}></i>}
                  {!isLoading && <i className={'fa fa-save'}></i>}
                  {intl.formatMessage({id: 'Simpan'})}
                </button>

              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

const FormPersyaratanAdmTeknis = ({proposalTender, selectedData, onHide, source, ...props}: any) => {
  const intl = useIntl()
  const urlIndex = getEnv('tender') + '/tender-syarat?perPage=15&page=1&filter[proposal_tender_id]=' + proposalTender?.id + '&filter[type]=administration&filter[source]=tender'
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false)
  const [itemId, setItemId] = useState(0)
  const [{doSubmit, isLoading, isError, data, isSuccess}] = usePostPersyaratan()
  const refFormik = React.useRef<any>()
  const [isDisabledForm, setIsDisabledForm] = React.useState(true)
  const [key, setKey] = React.useState(0)
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)

  const initialValues = {
    description: '',
    required_attachment: false,
  }

  const tableColumns = [
    {
      Header: intl.formatMessage({id: 'Aksi'}),
      accessor: 'urlss',
      className: 'text-center',
      width: 150,
      Cell: (props: any) => (
        <>
          <ButtonGroup size={'sm'} aria-label="Action">
            <button className={'btn btn-sm btn-secondary'}
                    onClick={() => {
                      setItemId(props.cell.row.original.uuid)
                      setIsConfirmDeleteOpen(true)
                    }}
            >
              <i className={'fa fa-trash text-danger'} />
            </button>
          </ButtonGroup>
        </>
      ),
    },
    {Header: intl.formatMessage({id: 'Deskripsi'}), accessor: 'description', width: 300, className: 'text-center'},
    {
      Header: intl.formatMessage({id: 'Wajib Lampiran'}),
      accessor: 'required_attachment',
      className: 'text-center',
      width: 225,
      Cell: (props: any) => {
        const original = props.cell.row.original
        let badgeColor
        let description

        if (original.required_attachment) {
          badgeColor = 'badge-success'
          description = 'Ya'
        } else {
          badgeColor = 'badge-warning'
          description = 'Tidak'
        }

        return <span className={`badge ${badgeColor}`}>{description}</span>
      },
    },
  ]

  const deleteItem = async () => {
    try {
      setIsLoadingDelete(true)
      const response: any = await axios.delete(getEnv('tender') + `/tender-syarat/${itemId}`)
      if (response.status === 200) {
        setKey(key + 1)
        setIsConfirmDeleteOpen(false)
        toastSuccess(response?.data?.meta?.message)
      }
    } catch (error: any) {
      toastError(error.response?.data?.meta?.message || 'Terjadi kesalahan')
    } finally {
      setIsLoadingDelete(false)
    }
  }

  const [formData, setFormData] = React.useState([])

  const formSchemaValidation = Yup.object().shape({
    description: Yup.string().required('Description harus diisi!'),
    required_attachment: Yup.boolean().required('Wajib Lampiran harus diisi!'),
  })

  useEffect(() => {
    if (proposalTender?.status_detail === 'Draft' || proposalTender?.status_detail === 'Rejected') {
      setIsDisabledForm(false)
    } else {
      setIsDisabledForm(true)
    }
  }, [proposalTender])

  const {uuid} = useParams()
  const [{doGetDetail, data: currentPropoposalTender}] = useGetDetailProposalTender()
  const initFormData = () => {
    doGetDetail(uuid, undefined).then((resProposalTender: any) => {

    })
  }

  useEffect(() => {
    initFormData()
  }, [])

  useEffect(() => {
    if (currentPropoposalTender) {
      const syarats = currentPropoposalTender?.syarats.filter((item: any) => item.type === 'administration' && item.source === 'proposal_tender')
      if (syarats.length > 0) {
        const syarat = syarats
        // @ts-ignore
        const newFormData = []
        syarats.map((item: any) => {
          newFormData.push({
            description: item.description,
            required_attachment: item.required_attachment,
          })
        })
        // @ts-ignore
        setFormData(newFormData)
      }
    }
  }, [currentPropoposalTender])

  const handleSubmit = async (values: any) => {
    const payload = {
      proposal_tender_id: proposalTender?.uuid,
      type: 'administration',
      syarat: values,
      source: source,
    }

    await doSubmit(payload, toast, () => {
      setKey(key + 1)
      props.onRefresh && props.onRefresh()
    })
  }

  const TableRow = ({no, description, disabled, required_attachment, onCheck, onDelete, ...props}: any) => {
    return (
      <tr>
        <td className={'border text-center'}>{no}</td>
        <td className={'border'}>{description}</td>
        <td className={'border text-center'}>
          <input type={'checkbox'} disabled={disabled} value={1} checked={required_attachment}
                 onChange={(e: any) => onCheck(e.target.checked)} />
        </td>
        <td className={'border text-center'}>
          <button className={'btn btn-sm btn-secondary'} disabled={disabled} onClick={() => onDelete()}>
            <i className={'fa fa-trash text-danger'}></i>
          </button>
        </td>
      </tr>
    )
  }

  const handleAddRow = async (values: any) => {
    if (values.description) {
      const newData = {
        description: values.description,
        required_attachment: false,
      }
      refFormik.current?.resetForm()
      // @ts-ignore
      setFormData([...formData, newData])
    }
  }

  const handleDeleteRow = async (index: number) => {
    const newData = formData.filter((item: any, i: number) => i !== index)
    // @ts-ignore
    setFormData(newData)
  }

  const handleCheckRow = async (index: number, checked: boolean) => {
    const newData = formData.map((item: any, i: number) => {
      if (i === index) {
        item.required_attachment = checked
      }
      return item
    })
    // @ts-ignore
    setFormData(newData)
  }

  return (
    <>
      <SwalX
        title={intl.formatMessage({id: 'DATATABLE.CONFIRM_DELETE'})}
        message={intl.formatMessage({id: 'DATATABLE.MESSAGE_DELETE'})}
        show={isConfirmDeleteOpen}
        loading={isLoadingDelete}
        onHide={() => setIsConfirmDeleteOpen(false)}
        onSubmit={() => deleteItem()}
      />

      <Formik
        key={key}
        innerRef={refFormik}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={formSchemaValidation}
        enableReinitialize
        validateOnChange={true}
      >
        {({errors, touched, values, setFieldValue, resetForm, submitForm}: any) => (
          <Form id="form-persyaratan-adm-teknis">
            <div className={'row py-4 mb-7'}>
              <div className={'col-md-6'}>
                <div className={'form-group mb-5'}>
                  <label className={'required'}>{intl.formatMessage({id: 'Deskripsi'})}</label>
                  <Field
                    name="description"
                    type="text"
                    className="form-control"
                    disabled={isDisabledForm}
                  />
                  {(errors.description && touched.description) && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{errors.description}</div>
                    </div>
                  )}
                </div>
                <div className={'form-group mb-5'}>
                  <label className={'required'}>
                    {intl.formatMessage({id: 'Wajib Lampiran'})}
                  </label>
                  <Row className={'mt-2'}>
                    <Col>
                      <label>
                        <Field
                          type="radio"
                          disabled={isDisabledForm}
                          name="required_attachment"
                          className={'me-2'}
                          value={true}
                          checked={values.required_attachment === null ? false : parseStringToBool(values.required_attachment)}
                          onChange={(e: any) => {
                            setFieldValue('required_attachment', parseStringToBool(e.target.value))
                          }}
                        />
                        {intl.formatMessage({id: 'Ya'})}
                      </label>
                    </Col>
                    <Col>
                      <label>
                        <Field type="radio" name="required_attachment" className={'me-2'} value={false}
                               disabled={isDisabledForm}
                               checked={values.required_attachment === null ? false : !parseStringToBool(values.required_attachment)}
                               onChange={(e: any) => {
                                 setFieldValue('required_attachment', parseStringToBool(e.target.value))
                               }}
                        />
                        {intl.formatMessage({id: 'Tidak'})}
                      </label>
                    </Col>
                  </Row>
                  {(errors.required_attachment && touched.required_attachment) && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{errors.required_attachment}</div>
                    </div>
                  )}
                </div>
                <div className={'col-md-12 d-flex flex-end gap-2'}>
                  <button type={'submit'} className={'btn btn-primary btn-sm'} disabled={isLoading || isDisabledForm}>
                    {isLoading && <i className={'fa fa-spinner fa-spin'}></i>}
                    {!isLoading && <i className={'fa fa-save'}></i>}
                    {intl.formatMessage({id: 'Simpan'})}
                  </button>
                </div>
                <div className={'col-md-12 text-end'}>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <div className="card border">
        <div className="card-body">
          <TableXWrapper
            key={key}
            readonly={true}
            title={'Proposal Tender Syarat'}
            urlIndex={urlIndex}
            columns={tableColumns}
            disableUrlChanged={true}
            disablePaddingCard={true}
          />
        </div>
      </div>
    </>
  )
}

const FormPersyaratanTeknis = ({proposalTender, selectedData, onHide, source, ...props}: any) => {
  const intl = useIntl()
  const urlIndex = getEnv('tender') + '/tender-syarat?perPage=15&page=1&filter[proposal_tender_id]=' + proposalTender?.id + '&filter[type]=technical&filter[source]=tender'
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false)
  const [itemId, setItemId] = useState(0)
  const [{doSubmit, isLoading, isError, data, isSuccess}] = usePostPersyaratan()
  const refFormik = React.useRef<any>()
  const [isDisabledForm, setIsDisabledForm] = React.useState(true)
  const [key, setKey] = React.useState(0)
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)

  const initialValues = {
    description: '',
    required_attachment: false,
  }

  const tableColumns = [
    {
      Header: intl.formatMessage({id: 'Aksi'}),
      accessor: 'urlss',
      className: 'text-center',
      width: 150,
      Cell: (props: any) => (
        <>
          <ButtonGroup size={'sm'} aria-label="Action">
            <button className={'btn btn-sm btn-secondary'}
                    onClick={() => {
                      setItemId(props.cell.row.original.uuid)
                      setIsConfirmDeleteOpen(true)
                    }}
            >
              <i className={'fa fa-trash text-danger'} />
            </button>
          </ButtonGroup>
        </>
      ),
    },
    {Header: intl.formatMessage({id: 'Deskripsi'}), accessor: 'description', width: 300, className: 'text-center'},
    {
      Header: intl.formatMessage({id: 'Wajib Lampiran'}),
      accessor: 'required_attachment',
      className: 'text-center',
      width: 225,
      Cell: (props: any) => {
        const original = props.cell.row.original
        let badgeColor
        let description

        if (original.required_attachment) {
          badgeColor = 'badge-success'
          description = 'Ya'
        } else {
          badgeColor = 'badge-warning'
          description = 'Tidak'
        }

        return <span className={`badge ${badgeColor}`}>{description}</span>
      },
    },
  ]

  const deleteItem = async () => {
    try {
      setIsLoadingDelete(true)
      const response: any = await axios.delete(getEnv('tender') + `/tender-syarat/${itemId}`)
      if (response.status === 200) {
        setKey(key + 1)
        setIsConfirmDeleteOpen(false)
        toastSuccess(response?.data?.meta?.message)
      }
    } catch (error: any) {
      toastError(error.response?.data?.meta?.message || 'Terjadi kesalahan')
    } finally {
      setIsLoadingDelete(false)
    }
  }

  const [formData, setFormData] = React.useState([])

  const formSchemaValidation = Yup.object().shape({
    description: Yup.string().required('Description harus diisi!'),
    required_attachment: Yup.boolean().required('Wajib Lampiran harus diisi!'),
  })

  useEffect(() => {
    if (proposalTender?.status_detail === 'Draft' || proposalTender?.status_detail === 'Rejected') {
      setIsDisabledForm(false)
    } else {
      setIsDisabledForm(true)
    }
  }, [proposalTender])

  const {uuid} = useParams()
  const [{doGetDetail, data: currentPropoposalTender}] = useGetDetailProposalTender()
  const initFormData = () => {
    doGetDetail(uuid, undefined).then((resProposalTender: any) => {

    })
  }

  useEffect(() => {
    initFormData()
  }, [])

  useEffect(() => {
    if (currentPropoposalTender) {
      const syarats = currentPropoposalTender?.syarats.filter((item: any) => item.type === 'technical' && item.source === 'proposal_tender')
      if (syarats.length > 0) {
        const syarat = syarats
        // @ts-ignore
        const newFormData = []
        syarats.map((item: any) => {
          newFormData.push({
            description: item.description,
            required_attachment: item.required_attachment,
          })
        })
        // @ts-ignore
        setFormData(newFormData)
      }
    }
  }, [currentPropoposalTender])

  const handleSubmit = async (values: any) => {
    const payload = {
      proposal_tender_id: proposalTender?.uuid,
      type: 'technical',
      syarat: values,
      source: source,
    }

    await doSubmit(payload, toast, () => {
      setKey(key + 1)
      props.onRefresh && props.onRefresh()
    })
  }

  const TableRow = ({no, description, disabled, required_attachment, onCheck, onDelete, ...props}: any) => {
    return (
      <tr>
        <td className={'border text-center'}>{no}</td>
        <td className={'border'}>{description}</td>
        <td className={'border text-center'}>
          <input type={'checkbox'} disabled={disabled} value={1} checked={required_attachment}
                 onChange={(e: any) => onCheck(e.target.checked)} />
        </td>
        <td className={'border text-center'}>
          <button className={'btn btn-sm btn-secondary'} disabled={disabled} onClick={() => onDelete()}>
            <i className={'fa fa-trash text-danger'}></i>
          </button>
        </td>
      </tr>
    )
  }

  const handleAddRow = async (values: any) => {
    if (values.description) {
      const newData = {
        description: values.description,
        required_attachment: false,
      }
      refFormik.current?.resetForm()
      // @ts-ignore
      setFormData([...formData, newData])
    }
  }

  const handleDeleteRow = async (index: number) => {
    const newData = formData.filter((item: any, i: number) => i !== index)
    // @ts-ignore
    setFormData(newData)
  }

  const handleCheckRow = async (index: number, checked: boolean) => {
    const newData = formData.map((item: any, i: number) => {
      if (i === index) {
        item.required_attachment = checked
      }
      return item
    })
    // @ts-ignore
    setFormData(newData)
  }

  return (
    <>
      <SwalX
        title={intl.formatMessage({id: 'DATATABLE.CONFIRM_DELETE'})}
        message={intl.formatMessage({id: 'DATATABLE.MESSAGE_DELETE'})}
        show={isConfirmDeleteOpen}
        loading={isLoadingDelete}
        onHide={() => setIsConfirmDeleteOpen(false)}
        onSubmit={() => deleteItem()}
      />

      <Formik
        key={key}
        innerRef={refFormik}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={formSchemaValidation}
        enableReinitialize
        validateOnChange={true}
      >
        {({errors, touched, values, setFieldValue, resetForm, submitForm}: any) => (
          <Form id="form-persyaratan-adm-teknis">
            <div className={'row py-4 mb-7'}>
              <div className={'col-md-6'}>
                <div className={'form-group mb-5'}>
                  <label className={'required'}>{intl.formatMessage({id: 'Deskripsi'})}</label>
                  <Field
                    name="description"
                    type="text"
                    className="form-control"
                    disabled={isDisabledForm}
                  />
                  {(errors.description && touched.description) && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{errors.description}</div>
                    </div>
                  )}
                </div>
                <div className={'form-group mb-5'}>
                  <label className={'required'}>
                    {intl.formatMessage({id: 'Wajib Lampiran'})}
                  </label>
                  <Row className={'mt-2'}>
                    <Col>
                      <label>
                        <Field
                          type="radio"
                          disabled={isDisabledForm}
                          name="required_attachment"
                          className={'me-2'}
                          defaultValue={true}
                          value={true}
                          checked={values.required_attachment === null ? false : parseStringToBool(values.required_attachment)}
                          onChange={(e: any) => {
                            setFieldValue('required_attachment', parseStringToBool(e.target.value))
                          }}
                        />
                        {intl.formatMessage({id: 'Ya'})}
                      </label>
                    </Col>
                    <Col>
                      <label>
                        <Field type="radio" name="required_attachment" className={'me-2'} value={false}
                               disabled={isDisabledForm}
                               checked={values.required_attachment === null ? false : !parseStringToBool(values.required_attachment)}
                               onChange={(e: any) => {
                                 setFieldValue('required_attachment', parseStringToBool(e.target.value))
                               }}
                        />
                        {intl.formatMessage({id: 'Tidak'})}
                      </label>
                    </Col>
                  </Row>
                  {(errors.required_attachment && touched.required_attachment) && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{errors.required_attachment}</div>
                    </div>
                  )}
                </div>
                <div className={'col-md-12 d-flex flex-end gap-2'}>
                  <button type={'submit'} className={'btn btn-primary btn-sm'} disabled={isLoading || isDisabledForm}>
                    {isLoading && <i className={'fa fa-spinner fa-spin'}></i>}
                    {!isLoading && <i className={'fa fa-save'}></i>}
                    {intl.formatMessage({id: 'Simpan'})}
                  </button>
                </div>
                <div className={'col-md-12 text-end'}>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <div className="card border">
        <div className="card-body">
          <TableXWrapper
            key={key}
            readonly={true}
            title={'Proposal Tender Syarat'}
            urlIndex={urlIndex}
            columns={tableColumns}
            disableUrlChanged={true}
            disablePaddingCard={true}
          />
        </div>
      </div>
    </>
  )
}


const FormPersyaratanKomersial = ({proposalTender, selectedData, onHide, source, ...props}: any) => {
  const [{doSubmit, isLoading, isError, data, isSuccess}] = usePostPersyaratan()
  const refFormik = React.useRef<any>()
  const [isDisabledForm, setIsDisabledForm] = React.useState(true)
  const intl = useIntl()
  const [formData, setFormData] = React.useState([])
  const [key, setKey] = React.useState(0)
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)
  const urlIndex = getEnv('tender') + '/tender-syarat?perPage=15&page=1&filter[proposal_tender_id]=' + proposalTender?.id + '&filter[type]=commercial&filter[source]=tender'
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false)
  const [itemId, setItemId] = useState(0)

  const initialValues = {
    description: '',
    required_attachment: false,
  }

  const tableColumns = [
    {
      Header: intl.formatMessage({id: 'Aksi'}),
      accessor: 'urlss',
      className: 'text-center',
      width: 150,
      Cell: (props: any) => (
        <>
          <ButtonGroup size={'sm'} aria-label="Action">
            <button className={'btn btn-sm btn-secondary'}
                    onClick={() => {
                      setItemId(props.cell.row.original.uuid)
                      setIsConfirmDeleteOpen(true)
                    }}
            >
              <i className={'fa fa-trash text-danger'} />
            </button>
          </ButtonGroup>
        </>
      ),
    },
    {Header: intl.formatMessage({id: 'Deskripsi'}), accessor: 'description', width: 300, className: 'text-center'},
    {
      Header: intl.formatMessage({id: 'Wajib Lampiran'}),
      accessor: 'required_attachment',
      className: 'text-center',
      width: 225,
      Cell: (props: any) => {
        const original = props.cell.row.original
        let badgeColor
        let description

        if (original.required_attachment) {
          badgeColor = 'badge-success'
          description = 'Ya'
        } else {
          badgeColor = 'badge-warning'
          description = 'Tidak'
        }

        return <span className={`badge ${badgeColor}`}>{description}</span>
      },
    },
  ]

  const deleteItem = async () => {
    try {
      setIsLoadingDelete(true)
      const response: any = await axios.delete(getEnv('tender') + `/tender-syarat/${itemId}`)
      if (response.status === 200) {
        setKey(key + 1)
        setIsConfirmDeleteOpen(false)
        toastSuccess(response?.data?.meta?.message)
      }
    } catch (error: any) {
      toastError(error.response?.data?.meta?.message || 'Terjadi kesalahan')
    } finally {
      setIsLoadingDelete(false)
    }
  }

  const formSchemaValidation = Yup.object().shape({
    description: Yup.string().required('Description harus diisi!'),
    required_attachment: Yup.boolean().required('Wajib Lampiran harus diisi!'),
  })

  useEffect(() => {
    setIsDisabledForm(!checkIsFormEnabled())
  }, [proposalTender])

  const {uuid} = useParams()
  const [{doGetDetail, data: currentPropoposalTender}] = useGetDetailProposalTender()
  const initFormData = () => {
    doGetDetail(uuid, undefined).then((resProposalTender: any) => {

    })
  }

  useEffect(() => {
    initFormData()
  }, [])

  useEffect(() => {
    if (currentPropoposalTender) {
      const syarats = currentPropoposalTender?.syarats.filter((item: any) => item.type === 'commercial' && item.source === 'proposal_tender')
      if (syarats.length > 0) {
        const syarat = syarats
        // @ts-ignore
        const newFormData = []
        syarats.map((item: any) => {
          newFormData.push({
            description: item.description,
            required_attachment: item.required_attachment,
          })
        })
        // @ts-ignore
        setFormData(newFormData)
      }
    }
  }, [currentPropoposalTender])

  const handleSubmit = async (values: any) => {
    const payload = {
      proposal_tender_id: proposalTender?.uuid,
      type: 'commercial',
      syarat: values,
      source: source,
    }

    await doSubmit(payload, toast, () => {
      setKey(key + 1)
      props.onRefresh && props.onRefresh()
    })
  }

  const checkIsFormEnabled = () => {
    return proposalTender?.status_detail === 'Draft' || proposalTender?.status_detail === 'Rejected'
  }

  const TableRow = ({no, description, disabled, required_attachment, onCheck, onDelete, ...props}: any) => {
    return (
      <tr>
        <td className={'border text-center'}>{no}</td>
        <td className={'border'}>{description}</td>
        <td className={'border text-center'}>
          <input type={'checkbox'} disabled={disabled} value={1} checked={required_attachment}
                 onChange={(e: any) => onCheck(e.target.checked)} />
        </td>
        <td className={'border text-center'}>
          <button className={'btn btn-sm btn-secondary'} disabled={disabled} onClick={() => onDelete()}>
            <i className={'fa fa-trash text-danger'}></i>
          </button>
        </td>
      </tr>
    )
  }

  const handleAddRow = async (values: any) => {
    const newData = {
      description: values.description,
      required_attachment: false,
    }
    refFormik.current?.resetForm()
    // @ts-ignore
    setFormData([...formData, newData])
  }

  const handleDeleteRow = async (index: number) => {
    const newData = formData.filter((item: any, i: number) => i !== index)
    // @ts-ignore
    setFormData(newData)
  }

  const handleCheckRow = async (index: number, checked: boolean) => {
    const newData = formData.map((item: any, i: number) => {
      if (i === index) {
        item.required_attachment = checked
      }
      return item
    })
    // @ts-ignore
    setFormData(newData)
  }

  return (
    <>
      <SwalX
        title={intl.formatMessage({id: 'DATATABLE.CONFIRM_DELETE'})}
        message={intl.formatMessage({id: 'DATATABLE.MESSAGE_DELETE'})}
        show={isConfirmDeleteOpen}
        loading={isLoadingDelete}
        onHide={() => setIsConfirmDeleteOpen(false)}
        onSubmit={() => deleteItem()}
      />

      <Formik
        key={key}
        innerRef={refFormik}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={formSchemaValidation}
        enableReinitialize
        validateOnChange={true}
      >
        {({errors, touched, values, setFieldValue, resetForm, submitForm}: any) => (
          <Form id="form-persyaratan-commer">
            <div className={'row py-4 mb-7'}>
              <div className={'col-md-6'}>
                <div className={'form-group mb-5'}>
                  <label className={'required'}>{intl.formatMessage({id: 'Deskripsi'})}</label>
                  <Field
                    name="description"
                    type="text"
                    className="form-control"
                    disabled={isDisabledForm}
                  />
                  {(errors.description && touched.description) && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{errors.description}</div>
                    </div>
                  )}
                </div>
                <div className={'form-group mb-5'}>
                  <label className={'required'}>
                    {intl.formatMessage({id: 'Wajib Lampiran'})}
                  </label>
                  <Row className={'mt-2'}>
                    <Col>
                      <label>
                        <Field
                          type="radio"
                          disabled={isDisabledForm}
                          name="required_attachment"
                          className={'me-2'}
                          defaultValue={true}
                          value={true}
                          checked={values.required_attachment === null ? false : parseStringToBool(values.required_attachment)}
                          onChange={(e: any) => {
                            setFieldValue('required_attachment', parseStringToBool(e.target.value))
                          }}
                        />
                        {intl.formatMessage({id: 'Ya'})}
                      </label>
                    </Col>
                    <Col>
                      <label>
                        <Field type="radio" name="required_attachment" className={'me-2'} value={false}
                               disabled={isDisabledForm}
                               checked={values.required_attachment === null ? false : !parseStringToBool(values.required_attachment)}
                               onChange={(e: any) => {
                                 setFieldValue('required_attachment', parseStringToBool(e.target.value))
                               }}
                        />
                        {intl.formatMessage({id: 'Tidak'})}
                      </label>
                    </Col>
                  </Row>
                  {(errors.required_attachment && touched.required_attachment) && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{errors.required_attachment}</div>
                    </div>
                  )}
                </div>
                <div className={'col-md-12 d-flex flex-end gap-2'}>
                  <button type={'submit'} className={'btn btn-primary btn-sm'} disabled={isLoading || isDisabledForm}>
                    {isLoading && <i className={'fa fa-spinner fa-spin'}></i>}
                    {!isLoading && <i className={'fa fa-save'}></i>}
                    {intl.formatMessage({id: 'Simpan'})}
                  </button>
                </div>
                <div className={'col-md-12 text-end'}>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <div className="card border">
        <div className="card-body">
          <TableXWrapper
            key={key}
            readonly={true}
            title={'Proposal Tender Syarat'}
            urlIndex={urlIndex}
            columns={tableColumns}
            disableUrlChanged={true}
            disablePaddingCard={true}
          />
        </div>
      </div>
    </>
  )
}

const TabFormPersyaratan = ({proposalTender, selectedData, onHide, ...props}: any) => {
  const currentSelectedTab = localStorage.getItem('currentSelectedTab')
  const [selectedTab, setSelectedTab] = React.useState(currentSelectedTab ?? 'detail_persyaratan')
  const intl = useIntl()
  const navigate = useNavigate()
  const location = useLocation()

  const getProposalTender = () => {
    localStorage.setItem('currentSelectedTab', selectedTab ?? 'detail_persyaratan')
    props?.onRefresh()
  }


  useEffect(() => {
    localStorage.removeItem('currentSelectedTab')
    localStorage.setItem('currentSelectedTab', selectedTab ?? 'detail_persyaratan')
  }, [selectedTab])

  {/*TODO-MENU: 3. update tabRoute*/}
  const tabRoutes = {
    'detail_persyaratan': `/tender/admin/proposal-tender/detail/${proposalTender?.uuid}/persyaratan`,
    'persyaratan_adm_teknis': `/tender/admin/proposal-tender/detail/${proposalTender?.uuid}/persyaratan/administrasi`,
    'persyaratan_teknis': `/tender/admin/proposal-tender/detail/${proposalTender?.uuid}/persyaratan/teknis`,
    'persyaratan_komersial': `/tender/admin/proposal-tender/detail/${proposalTender?.uuid}/persyaratan/komersial`,
  }

  {/*TODO-MENU: 4. update getActiveKey*/}
  const getActiveKey = () => {
    for (const [key, path] of Object.entries(tabRoutes)) {
      if (location.pathname === path) return key
    }
    return 'detail_persyaratan'
  }

  return (
    <>
      {/*TODO-MENU: 2. Sesuaikan bagian navigate dan getActiveKey*/}
      {(selectedTab !== null) && (
        <Nav appearance={'subtle'} justified={true} activeKey={getActiveKey()} onSelect={(eventKey: string) => {
          navigate(tabRoutes[eventKey])
        }} style={{marginBottom: 20}}>
          <Nav.Item eventKey={'detail_persyaratan'}>
            <div className={'text-center w-100'}>
              {intl.formatMessage({id: 'Detail Persyaratan'})}
            </div>
          </Nav.Item>
          <Nav.Item eventKey={'persyaratan_adm_teknis'}>
            <div className={'text-center w-100'}>
              {intl.formatMessage({id: 'Administrasi'})}
            </div>
          </Nav.Item>
          <Nav.Item eventKey={'persyaratan_teknis'}>
            <div className={'text-center w-100'}>
              {intl.formatMessage({id: 'Teknis'})}
            </div>
          </Nav.Item>
          <Nav.Item eventKey={'persyaratan_komersial'}>
            <div className={'text-center w-100'}>
              {intl.formatMessage({id: 'Komersial'})}
            </div>
          </Nav.Item>
        </Nav>
      )}
      <div>
        {/*{proposalTender && (*/}
        {/*  <>*/}
        {/*    {selectedTab === "detail_persyaratan" && (*/}
        {/*      <FormDetailPersyaratan*/}
        {/*        proposalTender={proposalTender}*/}
        {/*        selectedData={selectedData}*/}
        {/*        onHide={onHide}*/}
        {/*        onRefresh={() => getProposalTender()}*/}
        {/*        source={"proposal_tender"}*/}
        {/*      />*/}
        {/*    )}*/}
        {/*    {selectedTab === "persyaratan_adm_teknis" && (*/}
        {/*      <FormPersyaratanAdmTeknis*/}
        {/*        proposalTender={proposalTender}*/}
        {/*        selectedData={selectedData}*/}
        {/*        onHide={onHide}*/}
        {/*        onRefresh={() => getProposalTender()}*/}
        {/*        source={"proposal_tender"}*/}
        {/*      />*/}
        {/*    )}*/}
        {/*    {selectedTab === "persyaratan_teknis" && (*/}
        {/*      <FormPersyaratanTeknis*/}
        {/*        proposalTender={proposalTender}*/}
        {/*        selectedData={selectedData}*/}
        {/*        onHide={onHide}*/}
        {/*        onRefresh={() => getProposalTender()}*/}
        {/*        source={"proposal_tender"}*/}
        {/*      />*/}
        {/*    )}*/}
        {/*    {selectedTab === "persyaratan_komersial" && (*/}
        {/*      <FormPersyaratanKomersial*/}
        {/*        proposalTender={proposalTender}*/}
        {/*        selectedData={selectedData}*/}
        {/*        onHide={onHide}*/}
        {/*        source={"proposal_tender"}*/}
        {/*        onRefresh={() => getProposalTender()}*/}
        {/*      />*/}
        {/*    )}*/}
        {/*  </>*/}
        {/*)}*/}

        {/*TODO-MENU: 5. buat routes untuk setiap tab*/}
        <Routes>
          <Route element={<Outlet />}>
            <Route path={'/'} index element={
              <FormDetailPersyaratan
                proposalTender={proposalTender}
                selectedData={selectedData}
                onHide={onHide}
                onRefresh={() => getProposalTender()}
                source={'proposal_tender'}
              />
            } />
            <Route path={'/administrasi'} element={
              <FormPersyaratanAdmTeknis
                proposalTender={proposalTender}
                selectedData={selectedData}
                onHide={onHide}
                onRefresh={() => getProposalTender()}
                source={'proposal_tender'}
              />
            } />
            <Route path={'/teknis'} element={
              <FormPersyaratanTeknis
                proposalTender={proposalTender}
                selectedData={selectedData}
                onHide={onHide}
                onRefresh={() => getProposalTender()}
                source={'proposal_tender'}
              />
            } />
            <Route path={'/komersial'} element={
              <FormPersyaratanKomersial
                proposalTender={proposalTender}
                selectedData={selectedData}
                onHide={onHide}
                source={'proposal_tender'}
                onRefresh={() => getProposalTender()}
              />
            } />
          </Route>
        </Routes>
      </div>
    </>
  )
}


const FormPersyaratanPQ = ({proposalTender, selectedData, onHide, ...props}: any) => {
  const [{doSubmit, isLoading, isError, data, isSuccess}] = useAxiosPost(
    getEnv('tender') + '/pq-syarat',
  )
  const intl = useIntl()
  const refFormik = React.useRef<any>()
  const [isDisabledForm, setIsDisabledForm] = React.useState(true)
  const initialValues = {
    description: '',
  }
  const [formData, setFormData] = React.useState([])
  const [defaultPersyaratanPQ, setDefaultPersyaratanPQ] = React.useState([
    'Pengurus Perusahaan',
    'Akta Perusahaan',
    'NPWP',
    'SIUP/TDP/NIB',
    'SITU/SKDU/Izin Lokasi',
    'SPT Tahunan',
    'Rekening Bank',
  ])
  const formSchemaValidation = Yup.object().shape({
    description: Yup.string().required('Required'),
  })

  useEffect(() => {
    const syarats = proposalTender?.syarats.filter((item: any) => item.type === 'administration' && item.source === 'pra_qualification')
    if (syarats.length > 0) {
      const syarat = syarats
      // @ts-ignore
      const newFormData = []
      syarats.map((item: any) => {
        newFormData.push({
          description: item.description,
          required_attachment: item.required_attachment,
        })
      })
      // @ts-ignore
      setFormData(newFormData)
    } else {
      // @ts-ignore
      const newFormData = []
      defaultPersyaratanPQ.map((item: any) => {
        newFormData.push({
          description: item,
          required_attachment: false,
        })
      })
      // @ts-ignore
      setFormData(newFormData)
      // @ts-ignore
      // submitForm(newFormData, false);
    }
    setIsDisabledForm(!checkIsFormEnabled())
  }, [])

  const handleSubmit = async () => {
    submitForm(formData, true)
  }

  const submitForm = async (_data: any, withRefresh: boolean) => {
    const payload = {
      proposal_tender_id: proposalTender?.uuid,
      type: 'administration',
      syarat: _data,
      source: 'pra_qualification',
    }
    await doSubmit(payload, null, toast).then((res: any) => {
      if (withRefresh)
        props.onRefresh && props.onRefresh()
    })
  }

  const TableRow = ({no, description, disabled, required_attachment, onCheck, onDelete, ...props}: any) => {
    return (
      <tr>
        <td className={'border text-center'}>{no}</td>
        <td className={'border'}>{description}</td>
        <td className={'border text-center'}>
          <input type={'checkbox'} disabled={disabled} value={1} checked={required_attachment}
                 onChange={(e: any) => onCheck(e.target.checked)} />
        </td>
      </tr>
    )
  }

  const handleAddRow = async (values: any) => {
    const newData = {
      description: values.description,
      required_attachment: false,
    }
    refFormik.current?.resetForm()
    // @ts-ignore
    setFormData([...formData, newData])
  }

  const handleDeleteRow = async (index: number) => {
    const newData = formData.filter((item: any, i: number) => i !== index)
    // @ts-ignore
    setFormData(newData)
  }

  const handleCheckRow = async (index: number, checked: boolean) => {
    const newData = formData.map((item: any, i: number) => {
      if (i === index) {
        item.required_attachment = checked
      }
      return item
    })
    // @ts-ignore
    setFormData(newData)
  }

  const checkIsFormEnabled = () => {
    return proposalTender?.status === 'Pra Qualification' && proposalTender?.status_detail === 'Draft' || proposalTender?.status_detail === 'Rejected'
  }

  return (
    <>

      <Formik
        innerRef={refFormik}
        initialValues={initialValues}
        onSubmit={handleAddRow}
        validationSchema={formSchemaValidation}
        enableReinitialize
        validateOnChange={true}
      >
        {({errors, touched, values, setFieldValue, resetForm, submitForm}: any) => (
          <Form id="form-hps">
            <div className={'row py-4'}>
              <div className={'col-md-12 mt-5'}>
                <table className={'table table-bordered'}>
                  <thead>
                  <tr>
                    <th className={'border text-center'}><strong>{intl.formatMessage({id: 'No'})}</strong></th>
                    <th className={'border text-center'}><strong>{intl.formatMessage({id: 'Deskripsi'})}</strong></th>
                    <th className={'border text-center'}><strong>{intl.formatMessage({id: 'Mandatory'})}</strong></th>
                  </tr>
                  </thead>
                  <tbody>
                  {formData.length === 0 && (
                    <tr>
                      <td colSpan={4} className={'text-center'}>
                        <i>{intl.formatMessage({id: 'Tidak ada data'})}</i>
                      </td>
                    </tr>
                  )}
                  {formData.map((item: any, index: number) => {
                    return (
                      <TableRow
                        key={'adm-' + index}
                        no={index + 1}
                        description={item.description}
                        required_attachment={item.required_attachment}
                        onDelete={() => handleDeleteRow(index)}
                        onCheck={(checked: boolean) => {
                          handleCheckRow(index, checked)
                        }}
                        source={'proposal_tender'}
                        disabled={isDisabledForm}
                      />
                    )
                  })}
                  </tbody>
                </table>
              </div>

              <div className={'col-md-12 mt-5 d-flex flex-end gap-2 border-top pt-4'}>
                <button className={'btn btn-primary btn-sm'} disabled={isLoading || isDisabledForm}
                        onClick={() => handleSubmit()}>
                  {isLoading && <i className={'fa fa-spinner fa-spin'}></i>}
                  {!isLoading && <i className={'fa fa-save'}></i>}
                  {intl.formatMessage({id: 'Simpan'})}
                </button>

              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

const FormPersyaratanTambahanPQ = ({proposalTender, selectedData, onHide, ...props}: any) => {
  const [{doSubmit, isLoading, isError, data, isSuccess}] = usePostPersyaratan(true)
  const intl = useIntl()
  const refFormik = React.useRef<any>()
  const [isDisabledForm, setIsDisabledForm] = React.useState(true)
  const [key, setKey] = React.useState(0)
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)
  const urlIndex = getEnv('tender') + '/tender-syarat?perPage=15&page=1&filter[proposal_tender_id]=' + proposalTender?.id + '&filter[type]=additional&filter[source]=pra_qualification'
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false)
  const [itemId, setItemId] = useState(0)
  const [formData, setFormData] = React.useState([])

  const initialValues = {
    description: '',
    required_attachment: false,
  }

  const tableColumns = [
    {
      Header: intl.formatMessage({id: 'Aksi'}),
      accessor: 'urlss',
      className: 'text-center',
      width: 150,
      Cell: (props: any) => (
        <>
          <ButtonGroup size={'sm'} aria-label="Action">
            <button className={'btn btn-sm btn-secondary'}
                    onClick={() => {
                      setItemId(props.cell.row.original.uuid)
                      setIsConfirmDeleteOpen(true)
                    }}
            >
              <i className={'fa fa-trash text-danger'} />
            </button>
          </ButtonGroup>
        </>
      ),
    },
    {Header: intl.formatMessage({id: 'Deskripsi'}), accessor: 'description', width: 300, className: 'text-center'},
    {
      Header: intl.formatMessage({id: 'Wajib Lampiran'}),
      accessor: 'required_attachment',
      className: 'text-center',
      width: 225,
      Cell: (props: any) => {
        const original = props.cell.row.original
        let badgeColor
        let description

        if (original.required_attachment) {
          badgeColor = 'badge-success'
          description = 'Ya'
        } else {
          badgeColor = 'badge-warning'
          description = 'Tidak'
        }

        return <span className={`badge ${badgeColor}`}>{description}</span>
      },
    },
  ]

  const deleteItem = async () => {
    try {
      setIsLoadingDelete(true)
      const response: any = await axios.delete(getEnv('tender') + `/tender-syarat/${itemId}`)
      if (response.status === 200) {
        setKey(key + 1)
        setIsConfirmDeleteOpen(false)
        toastSuccess(response?.data?.meta?.message)
        props.onRefresh && props.onRefresh()
      }
    } catch (error: any) {
      toastError(error.response?.data?.meta?.message || 'Terjadi kesalahan')
    } finally {
      setIsLoadingDelete(false)
    }
  }

  const formSchemaValidation = Yup.object().shape({
    description: Yup.string().required('Description harus diisi!'),
    required_attachment: Yup.boolean().required('Wajib Lampiran harus diisi!'),
  })

  useEffect(() => {
    const syarats = proposalTender?.syarats.filter((item: any) => item.type === 'additional' && item.source === 'pra_qualification')
    if (syarats.length > 0) {
      const syarat = syarats
      // @ts-ignore
      const newFormData = []
      syarats.map((item: any) => {
        newFormData.push({
          description: item.description,
          required_attachment: item.required_attachment,
        })
      })
      // @ts-ignore
      setFormData(newFormData)
    }

    setIsDisabledForm(!checkIsFormEnabled())
  }, [])

  const handleSubmit = async (values: any) => {
    const payload = {
      proposal_tender_id: proposalTender?.uuid,
      type: 'additional',
      syarat: values,
      source: 'pra_qualification',
    }

    await doSubmit(payload, toast, () => {
      setKey(key + 1)
      props.onRefresh && props.onRefresh()
    })
  }

  const TableRow = ({no, description, disabled, required_attachment, onCheck, onDelete, ...props}: any) => {
    return (
      <tr>
        <td className={'border text-center'}>{no}</td>
        <td className={'border'}>{description}</td>
        <td className={'border text-center'}>
          <input type={'checkbox'} disabled={disabled} value={1} checked={required_attachment}
                 onChange={(e: any) => onCheck(e.target.checked)} />
        </td>
        <td className={'border text-center'}>
          <button className={'btn btn-sm btn-secondary'} disabled={disabled} onClick={() => onDelete()}>
            <i className={'fa fa-trash text-danger'}></i>
          </button>
        </td>
      </tr>
    )
  }

  const handleAddRow = async (values: any) => {
    if (values.description === '') return
    const newData = {
      description: values.description,
      required_attachment: false,
    }
    refFormik.current?.resetForm()
    // @ts-ignore
    setFormData([...formData, newData])
  }

  const handleDeleteRow = async (index: number) => {
    const newData = formData.filter((item: any, i: number) => i !== index)
    // @ts-ignore
    setFormData(newData)
  }

  const handleCheckRow = async (index: number, checked: boolean) => {
    const newData = formData.map((item: any, i: number) => {
      if (i === index) {
        item.required_attachment = checked
      }
      return item
    })
    // @ts-ignore
    setFormData(newData)
  }

  const checkIsFormEnabled = () => {
    return proposalTender.status === 'Pra Qualification' &&
      (proposalTender.status_detail === 'Draft' || proposalTender.status_detail === 'Rejected')
  }

  return (
    <>
      <SwalX
        title={intl.formatMessage({id: 'DATATABLE.CONFIRM_DELETE'})}
        message={intl.formatMessage({id: 'DATATABLE.MESSAGE_DELETE'})}
        show={isConfirmDeleteOpen}
        loading={isLoadingDelete}
        onHide={() => setIsConfirmDeleteOpen(false)}
        onSubmit={() => deleteItem()}
      />

      <Formik
        key={key}
        innerRef={refFormik}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={formSchemaValidation}
        enableReinitialize
        validateOnChange={true}
      >
        {({errors, touched, values, setFieldValue, resetForm, submitForm}: any) => (
          <Form id="form-persyaratan-add-on">
            <div className={'row py-4 mb-7'}>
              <div className={'col-md-6'}>
                <div className={'form-group mb-5'}>
                  <label className={'required'}>{intl.formatMessage({id: 'Deskripsi'})}</label>
                  <Field
                    name="description"
                    type="text"
                    className="form-control"
                    disabled={isDisabledForm}
                  />
                  {(errors.description && touched.description) && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{errors.description}</div>
                    </div>
                  )}
                </div>
                <div className={'form-group mb-5'}>
                  <label className={'required'}>
                    {intl.formatMessage({id: 'Wajib Lampiran'})}
                  </label>
                  <Row className={'mt-2'}>
                    <Col>
                      <label>
                        <Field
                          type="radio"
                          disabled={isDisabledForm}
                          name="required_attachment"
                          className={'me-2'}
                          value={true}
                          checked={values.required_attachment === null ? false : parseStringToBool(values.required_attachment)}
                          onChange={(e: any) => {
                            setFieldValue('required_attachment', parseStringToBool(e.target.value))
                          }}
                        />
                        {intl.formatMessage({id: 'Ya'})}
                      </label>
                    </Col>
                    <Col>
                      <label>
                        <Field type="radio" name="required_attachment" className={'me-2'} value={false}
                               disabled={isDisabledForm}
                               checked={values.required_attachment === null ? false : !parseStringToBool(values.required_attachment)}
                               onChange={(e: any) => {
                                 setFieldValue('required_attachment', parseStringToBool(e.target.value))
                               }}
                        />
                        {intl.formatMessage({id: 'Tidak'})}
                      </label>
                    </Col>
                  </Row>
                  {(errors.required_attachment && touched.required_attachment) && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{errors.required_attachment}</div>
                    </div>
                  )}
                </div>
                <div className={'col-md-12 d-flex flex-end gap-2'}>
                  <button type={'submit'} className={'btn btn-primary btn-sm'} disabled={isLoading || isDisabledForm}>
                    {isLoading && <i className={'fa fa-spinner fa-spin'}></i>}
                    {!isLoading && <i className={'fa fa-save'}></i>}
                    {intl.formatMessage({id: 'Simpan'})}
                  </button>
                </div>
                <div className={'col-md-12 text-end'}>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <div className="card border">
        <div className="card-body">
          <TableXWrapper
            key={key}
            readonly={true}
            title={'Proposal Tender Syarat'}
            urlIndex={urlIndex}
            columns={tableColumns}
            disableUrlChanged={true}
            disablePaddingCard={true}
          />
        </div>
      </div>
    </>
  )
}


const TabPersyaratanPQ = ({proposalTender, selectedData, onHide, ...props}: any) => {
  const intl = useIntl();
  const [selectedTab, setSelectedTab] = React.useState('detail_persyaratan')
  const [isCanSubmitForApproval,setIsCanSubmitForApproval] = useState({
    state: false,
    message: ''
  })
  const [{ doSubmit: doSubmitGet, isLoading: isLoadingGet}] = useAxiosGet("")
  const [{ doSubmit: doSubmitPQ, isLoading: isLoadingPQ }] = useAxiosPost(getEnv("tender") + "/pq/submit");

  const isShowSubmitPQ = () => {
    return proposalTender?.status === "Pra Qualification" &&
      proposalTender?.status_detail === "Draft" &&
      proposalTender?.praqualification;
  }

  const checkIsCanPublishPq =  async() => {
    const shouldCheckOnStatus = [
      "Pra Qualification",
    ];
    const shouldCheckOnStatusDetail = [
      "Draft",
    ];

    if(shouldCheckOnStatus.includes(proposalTender?.status) && shouldCheckOnStatusDetail.includes(proposalTender?.status_detail)){
      const newUrl = getEnv('tender') + `/pq/check-publish-status/${proposalTender?.uuid}`
      doSubmitGet({},newUrl,undefined).then((res:any)=>{
        setIsCanSubmitForApproval({
          state:true,
          message: ''
        })
      }).catch((err:any)=>{
        setIsCanSubmitForApproval({
          state:false,
          message: err?.response?.data?.meta?.message
        })
      })
    }
  }

  const handleOnCancelPQ = async() => {
    Swal.fire({
      title: "Apakah Anda yakin?",
      text: "Anda akan membatalkan Pra Qualification",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Ya, lanjutkan",
      cancelButtonText: "Tidak"
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        doSubmitPQ(
          {},
          getEnv("tender") + `/pq/cancel/${proposalTender.uuid}`,
          toast
        ).then((res: any) => {
          props.onRefresh && props.onRefresh();
        }).finally(() => {
          props.onUpdateLoading && props.onUpdateLoading(false);
        });
      } else {
        props.onUpdateLoading && props.onUpdateLoading(false);
      }
    });
  }

  const handleOnPublishPQ = async() => {
    Swal.fire({
      title: intl.formatMessage({id:"Apakah Anda yakin"})+"?",
      text: intl.formatMessage({id:"Anda akan mempublish PQ ini"}),
      icon: "question",
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({id:"Ya, Submit"}),
      cancelButtonText: intl.formatMessage({id:"Tidak"})
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        doSubmitPQ(
          {},
          getEnv("tender") + `/pq/submit/${proposalTender.uuid}`,
          toast
        ).then((res: any) => {
          props.onRefresh && props.onRefresh();
        });
      }
    });
  }

  useEffect (() => {
    checkIsCanPublishPq()
  },[proposalTender])

  return (
    <>
      <Nav appearance={'subtle'} justified={true} activeKey={selectedTab} onSelect={(e: any) => {
        setSelectedTab(e)
      }} style={{marginBottom: 20}}>
        <Nav.Item eventKey={'detail_persyaratan'}>
          Detail Persyaratan
        </Nav.Item>
        <Nav.Item eventKey={'persyaratan_adm_teknis'}>
          Persyaratan Administrasi
        </Nav.Item>
        {/* <Nav.Item eventKey={"persyaratan_teknis"}>
          Persyaratan Teknis
        </Nav.Item> */}
      </Nav>
      {selectedTab === 'detail_persyaratan' && (
        <FormPersyaratanPQ proposalTender={proposalTender} selectedData={selectedData} onHide={onHide}
                           onRefresh={props?.onRefresh} />
      )}
      {selectedTab === 'persyaratan_adm_teknis' && (
        <FormPersyaratanTambahanPQ proposalTender={proposalTender} selectedData={selectedData} onHide={onHide}
                                   onRefresh={props?.onRefresh} />
      )}
      {selectedTab === 'persyaratan_teknis' && (
        <FormPersyaratanTeknis
          proposalTender={proposalTender}
          selectedData={selectedData}
          onHide={onHide}
          onRefresh={props?.onRefresh}
          source={'proposal_tender'}
        />
      )}

      <BottomToolbarWrapper onGoBack={props?.onGoBack} children={
        <>
          {isShowSubmitPQ() && (
            <>
              <button
                className={"btn btn-danger me-3"}
                type={"button"}
                onClick={handleOnCancelPQ}
                disabled={isLoadingPQ}
              >
                {isLoadingPQ && <Spinner className={"spinner-grow-sm me-2"} animation="border" size="sm" />}
                {!isLoadingPQ && <i className={"fa fa-undo me-2"}></i> }
                {intl.formatMessage({id:'Batalkan PQ'})}
              </button>
              <button
                className={"btn btn-primary"}
                type={"button"}
                onClick={handleOnPublishPQ}
                disabled={isLoadingPQ || !isCanSubmitForApproval?.state}
              >
                {isLoadingPQ && <Spinner className={"spinner-grow-sm me-2"} animation="border" size="sm" />}
                {!isLoadingPQ && <i className={"fa fa-globe me-2"}></i> }
                {intl.formatMessage({id:'Publish PQ'})}
              </button>
            </>
          )}
        </>
      } />
    </>
  )
}

interface IProps {
  proposalTender?: any;
  onRefresh?: any;
  onUpdateLoading?: any;
}

const PerysaratanTenderPageV2 = ({proposalTender, onRefresh, onUpdateLoading}: IProps) => {
  const isShowJadwalPQ = () => {
    return proposalTender?.praqualification && proposalTender?.status === 'Pra Qualification'
  }
  const intl = useIntl()
  useEffect(() => {
    if (proposalTender?.id) {
    }
  }, [proposalTender])

  return (
    <>
      <div className={''}>
        <div className={'d-flex justify-content-between'}>
          <div>
            <h3>{intl.formatMessage({id: 'Persyaratan'})} {isShowJadwalPQ() ? 'PQ' : 'Tender'}</h3>
          </div>
        </div>
        <hr />
        <div className={'row'}>
          <div className={'col-12'}>
            {!isShowJadwalPQ() && (
              <>
                <TabFormPersyaratan
                  proposalTender={proposalTender}
                  onRefresh={onRefresh}
                  selectedTab={'detail_persyaratan'}
                />
              </>
            )}
            {isShowJadwalPQ() && (
              <TabPersyaratanPQ
                proposalTender={proposalTender}
                onRefresh={onRefresh}
                onUpdateLoading={onUpdateLoading}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default PerysaratanTenderPageV2