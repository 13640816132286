import {useState} from "react";
import {Form, Formik} from "formik";
import {Modal, Spinner} from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getEnv } from "../../../../../../helpers/procurex";
import { useAxiosPost } from "../../../../../../hooks/useAxios";
import * as Yup from "yup";
import FormikSelect2 from "../../../../../components/shared/form/FormikSelect2";

type IProps = {
  proposalTender: any
  show: boolean
  onHide: Function
}

export const AlihkanModal = ({ proposalTender, show, onHide }: IProps) => {
  const navigate = useNavigate()
  const [{doSubmit, isLoading}] = useAxiosPost("");

  const initialValues = {user: ''}

  const validationSchema = Yup.object({user: Yup.object().required('The field is required')})

  const onSubmit = (values:any) => {
    const payload = values
    const newUrl = getEnv('tender')+`/tender/alihkan/${proposalTender?.uuid}`
    doSubmit(payload,newUrl,toast).then((res:any)=>{
      onHide()
      navigate('/contract-management/po')
    })
  }

  return (
    <>
      <Modal show={show}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {() => (
            <Form>
              <>
                <Modal.Header onHide={() => onHide(false)}>
                  <h4>Alihkan Buyer Tender</h4>
                </Modal.Header>

                <Modal.Body>
                  <div>
                    <label className='form-label mb-3' htmlFor='destination'>
                      Tujuan Buyer
                    </label>
                    <FormikSelect2
                      name={`user`}
                      optionsUrl={getEnv('sso') + `/user`}
                      getOptionLabel={(opt: any) =>
                        `${opt.username} - ${opt.name} ${
                          opt.has_roles[0] ? '(' + opt.roles[0].name + ')' : ''
                        }`
                      }
                      getOptionValue={(opt: any) => opt}
                      unique_property='emp_no'
                      search_property={['name', 'username']}
                    />
                  </div>
                </Modal.Body>

                <Modal.Footer>
                  <button className={"btn btn-sm btn-danger"} type={"button"} onClick={() => onHide()}>
                    <i className={"fa fa-times"}></i>
                    Batal
                  </button>
                  <button className={"btn btn-sm btn-primary"} type={"submit"} disabled={isLoading}>
                    {isLoading ? <Spinner animation={'border'} size={"sm"} style={{color: 'white'}} /> : <i className={"fa fa-save"}/>}
                    Submit
                  </button>
                </Modal.Footer>
              </>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};