import { Modal } from 'react-bootstrap'
import React, { useState } from 'react'
import { getEnv } from '../../../../helpers/procurex'
import TableXWrapper from '../TableXWrapper'
import moment from 'moment'
import { IColumns as ITableXColumns } from '../TableX'

interface InvalidImportModalProps {
  importUuid: string;
  show: boolean;
  onHide: () => void;
  tableXColumns?: Array<ITableXColumns>
  tableXDataColumns?: Array<ITableXColumns>
}

interface IPropsStatusBadge {
  status: string;
}

const StatusBadgeInvalidImportModal = ({ status }: IPropsStatusBadge) => {
  const badge = {
    valid: "badge-success",
    invalid: "badge-danger",
  }[status] || "badge-primary";

  return (
    <span className={`badge ${badge} text-start`}>{status.toUpperCase()}</span>
  )
}

const InvalidReasonList = ({ errors }: any) => {
  if (errors) {
    return (
      <ul className="mb-0">
        {Object.keys(errors).map((key: string) =>
          errors[key].map((error: any, index: number) => {
            return <li key={key + '.' + index}>{error}</li>
          })
        )}
      </ul>
    )
  }

  return <></>;
}

const statusFilters = [
  { value: '', label: 'All' },
  { value: 'valid', label: <span className={`badge badge-success text-start`}>VALID</span> },
  { value: 'invalid', label: <span className={`badge badge-danger text-start`}>INVALID</span> },
]


export const InvalidImportExcelModal = ({ importUuid, show, onHide, tableXColumns, tableXDataColumns }: InvalidImportModalProps) => {
  const [statusFilter, setStatusFilter] = useState('invalid')
  const [tableKey, setTableKey] = useState(0);

  const handleStatusFilterChange = (status: string) => {
    setStatusFilter(status)
    setTableKey(value => value + 1)
  }


  return (
    <Modal show={show} onHide={onHide} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>
          Invalid Import Excel
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TableXWrapper
          title={'Invalid Import Excel'}
          key={tableKey}
          readonly
          urlIndex={getEnv("excel_importer") + `/import-detail`}
          defaultFilter={`filter[import_uuid]=${importUuid}&filter[status]=${statusFilter}&sort=row_number`}
          hideSelection={true}
          headerButtons={(
            <div className={'input-group mb-4'}>
              <span className="input-group-text" id="inputGroup-sizing-default">Status</span>
              <div className="input-group-text bg-white">
                {statusFilters.map((filter) => (
                  <div className="form-check form-check-inline mb-0 me-6">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="status-filter"
                      id={`status-filter-${filter.value}`}
                      value={filter.value}
                      checked={statusFilter === filter.value}
                      onChange={(event) => handleStatusFilterChange(event.target.value)}
                    />
                    <label className="form-check-label" htmlFor={`status-filter-${filter.value}`}>
                      {filter.label}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          )}
          columns={tableXColumns ?? [
            {
              Header: "Row",
              accessor: "row_number",
              width: 50,
            },
            {
              Header: "Status",
              accessor: "status",
              width: 100,
              searchable: false,
              className: 'text-center',
              Cell: ({ value }) => <StatusBadgeInvalidImportModal status={value} />,
            },

            ...(tableXDataColumns),

            {
              Header: "Invalid Reasons",
              accessor: "errors",
              width: 250,
              Cell: ({ value }) => <InvalidReasonList errors={value} />,
            },
          ]}
        />
      </Modal.Body>
    </Modal>
  )
}