import { useEffect, useState, useContext, createContext } from "react";
import TableXWrpper from "../../../../../components/shared/TableXWrapper";
import { KTCard, KTCardBody } from "../../../../../../_metronic/helpers";
import { Modal, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import SwalX from "../../../../../components/shared/SwalX";
import { useIntl } from "react-intl";
import ListToolbar from "../headers/ListToolbar";
import FormikSelect from "../../../../../components/shared/form/FormikSelect";
import FormikSelect2 from "../../../../../components/shared/form/FormikSelect2";
import { formatNumber } from "../../../../../../helpers/procurex";
import CurrencyInput from 'react-currency-input-field';
import moment from "moment";
import { AuctionContext } from "../../../../../routing/AuctionRoutes";


const ItemAuction = () => {
  const {itemPesertaState, setItemPesertaState} = useContext(AuctionContext);
  const API_URL = process.env.REACT_APP_API_URL;
  const validationSchema = Yup.object({});
  const intl = useIntl()
  const params = useParams();
  const [key, setKey] = useState(0)
  const idParam = params['*'];
  const [isShowModalCreate, setIsShowModalCreate] = useState(false)
  const [isLoadingSave, setIsLoadingSave] = useState(false)
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false)
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)
  const [id, setId] = useState('')
  const [detailData, setDetailData] = useState({})
 
  const [detailAuction, setDetailAuction]: any = useState({});
  const [modalTitle, setModalTitle] = useState("Buat Item Baru");
  const [isEdit, setEditMode] = useState(false);

  const fetchDetailAuction = async () => {
    const response: any = await axios.get(`${API_URL}/api/auction/auction/${id}`)
    let details = {
      batas_awal: response.data?.data?.batas_awal ? Date.parse(moment(response.data?.data?.batas_awal).format('yyyy-MM-DD HH:mm:ss')) : null,
      batas_akhir: response.data?.data?.batas_akhir ? Date.parse(moment(response?.data?.data?.batas_akhir).format('yyyy-MM-DD HH:mm:ss')) : null,
      catatan: response.data?.data?.catatan,
      status: response.data?.data?.status
    }
    setDetailAuction(details);
  }

  const tableColumns = [
    {
      Header: 'Action',
      width: 200,
      Cell: (props: any) => (
        <>
          {detailAuction?.status != 'published' && (
            <div className='d-flex w-100 justify-content-center'>
              <span onClick={() => {
                fetchDetailData(props?.cell?.row?.original?.id);
              }} className='p-3'>
                <i className={'fa fa-eye'} />
              </span>
              <span
                onClick={() => {
                  setId(props?.cell?.row?.original?.id)
                  setIsConfirmDeleteOpen(true)
                }}
                className='p-3 cursor-pointer'
              >
                <i className={'fa fa-trash'} />
              </span>
            </div>
          )}
        </>
      ),
    },
    { Header: "Deskripsi", accessor: "nama", width: 100, className: "text-center" },
    { Header: "Note", accessor: "note", width: 200, className: "text-center" },
    { Header: "Currency", accessor: "mata_uang.code", width: 150, className: "text-center" },
    {
      Header: "Kuantitas", accessor: "kuantitas", width: 500, className: "text-center",
      Cell: (props: any) => {
        return (
          <>
            <div className='w-200'>
              <span>
                <label className="form-label">{formatNumber(props?.cell?.row?.original?.kuantitas)}</label>
              </span>
            </div>
          </>
        )
      },
    },
    {
      Header: "Unit Price Tertinggi", accessor: "unit_price_tertinggi", width: 250, className: "text-center",
      Cell: (props: any) => {
        return (
          <>
            <div className='w-200'>
              <span>
                <label className="form-label">{formatNumber(props?.cell?.row?.original?.unit_price_tertinggi) + ",00"}</label>
              </span>
            </div>
          </>
        )
      },
    },
    {
      Header: "Unit Price Terendah", accessor: "unit_price_terendah", width: 250, className: "text-center",
      Cell: (props: any) => {
        return (
          <>
            <div className='w-200'>
              <span>
                <label className="form-label">{formatNumber(props?.cell?.row?.original?.unit_price_terendah) + ",00"}</label>
              </span>
            </div>
          </>
        )
      },
    },
    {
      Header: "Unit Price", accessor: "unit_price", width: 250, className: "text-center",
      Cell: (props: any) => {
        return (
          <>
            <div className='w-200'>
              <span>
                <label className="form-label">{formatNumber(props?.cell?.row?.original?.unit_price) + ",00"}</label>
              </span>
            </div>
          </>
        )
      },
    },
    {
      Header: 'Subtotal', accessor: 'subtotal', width: 250, className: "text-center",
      Cell: (props: any) => {
        return (
          <>
            <div className='w-200'>
              <span>
                <label className="form-label">{formatNumber(props?.cell?.row?.original?.subtotal) + ",00"}</label>
              </span>
            </div>
          </>
        )
      },
    },
  ];

  const fetchDetailData = async (idDetail: any) => {
    setDetailData({})
    setModalTitle("Edit Item");
    setEditMode(true);
    setId(idDetail);
    let details: any = {}
    try {
      const response: any = await axios.get(`${API_URL}/api/auction/item-auction/${idDetail}`)
      details = { ...response.data?.data };
    } catch (error) {
      console.error(error)
    } finally {
      setDetailData(details);
      if (details) {
        setIsShowModalCreate(true)
      }
    }
  }

  const deleteItem = async () => {
    setIsLoadingDelete(true)
    try {
      const response: any = await axios.delete(`${API_URL}/api/auction/item-auction/${id}`)

      if (response.status === 200) {
        setKey(key + 1)
        setIsConfirmDeleteOpen(false)
        toast.success(response?.data?.meta?.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
        setItemPesertaState(true);
      }
    } catch (error) {
    } finally {
      setIsLoadingDelete(false)
    }
  }

  const onSubmit = async (values: any) => {
    setIsLoadingSave(true)
    try {
      let data = { auction_id: idParam, ...values }
      const response:any = (isEdit) ? await axios.put(`${API_URL}/api/auction/item-auction/${id}`, data) : await axios.post(`${API_URL}/api/auction/item-auction`, data);
      if (response?.status === 201 || response?.status === 200) {
        toast.success(response?.status + ': ' + response?.data.meta.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        setEditMode(false);
        setIsShowModalCreate(false);
        setItemPesertaState(true);
        return true;
      }
    } catch (error) {
      setIsLoadingSave(false)
      console.error(error)
    } finally {
      setKey(key + 1)

      setIsLoadingSave(false)

      const responseDetailAuction: any = await axios.get(`${API_URL}/api/auction/auction/${idParam}`)
      //console.log(responseDetailAuction?.data?.data?.is_param_setting);
      
      if (responseDetailAuction?.data?.data?.is_param_setting) {
        //window.location.reload();
      }
    }
  }
  useEffect(() => {
    //fetchDetailAuction();
    // console.log(itemPesertaState)
    setItemPesertaState(false)
  }, [itemPesertaState])

  // const [kuantitasState, setKuantitas] = useState(0);
  // const [unitPriceState, setUnitPrice] = useState(0);
  // const [subTotalState, setSubTotal] = useState(0);
  let _kuantitas = 0;
  let _unitPrice = 0;
  let _subTotal = 0;
  const subTotal = (val:any) => {
    if(val) {
      _subTotal = 0;
    } else {
      _subTotal = _kuantitas * _unitPrice;
    }
    return _subTotal;
  };
  
  let kuantitas = (val:any) => {
    if(val) _kuantitas = val;
    return _kuantitas;
  };

  let unitPrice = (val:any) => {
    if(val) _unitPrice = val;
    return _unitPrice;
  };

  const reset = () => {
    kuantitas(0);
    unitPrice(0);
    subTotal(0);
  }

  return (
    <>
      <SwalX
        title={intl.formatMessage({ id: 'DATATABLE.CONFIRM_DELETE' })}
        message={intl.formatMessage({ id: 'DATATABLE.MESSAGE_DELETE' })}
        show={isConfirmDeleteOpen}
        loading={isLoadingDelete}
        onHide={() => setIsConfirmDeleteOpen(false)}
        onSubmit={() => deleteItem()}
      />
      {detailAuction?.status != 'published' && (
        <div className={'mt-4'} style={{ marginRight: 55 }}>
          <ListToolbar buttonText={'Buat Item Baru'} setShowModalCreate={setIsShowModalCreate} setDefaultData={setDetailData} callBack={setModalTitle} />
        </div>
      )}
      <KTCard>
        <KTCardBody className="py-4">
          <TableXWrpper
            key={key}
            title={"Item Auction"}
            urlIndex={API_URL + "/api/auction/item-auction?auction_id=" + idParam}
            columns={tableColumns}
            readonly
          />
        </KTCardBody>
      </KTCard>
      <Modal size={"lg"} show={isShowModalCreate} onHide={() => { reset(); setIsShowModalCreate(false); }}>
        <Formik
          enableReinitialize
          initialValues={detailData}
          validationSchema={validationSchema}
          onSubmit={onSubmit}>
          {({ errors, touched, values, setFieldValue, handleChange }: any) => (
            <>
              <Form>
                <Modal.Header closeButton>
                  <Modal.Title>
                    {modalTitle}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className={"form-group mb-4"}>
                    <label className="required form-label mb-3">Nama</label>
                    <Field
                      type={'text'}
                      className="form-control"
                      name="nama"
                      required
                    />
                  </div>
                  <div className={"form-group mb-4"}>
                    {!detailData ?
                      (
                        <FormikSelect
                          name="mata_uang"
                          label="Mata Uang"
                          optionsUrl={`${API_URL}/api/masterdata/currency`}
                          unique_property={"id"}
                          getOptionValue={(opt: any) => opt}
                          getOptionLabel={(opt: any) => opt.code + " - " + opt.long_text}
                          search_property={"code"}
                          required
                        />
                      ) : (
                        <FormikSelect2
                          name="mata_uang"
                          label="Mata Uang"
                          optionsUrl={`${API_URL}/api/masterdata/currency`}
                          unique_property={"id"}
                          getOptionValue={(opt: any) => opt}
                          getOptionLabel={(opt: any) => opt.code + " - " + opt.long_text}
                          search_property={"code"}
                          required
                        />
                      )}
                  </div>
                  <div className={"row mb-4"}>
                    <div className={"col-md-4"}>
                      <label className="required form-label mb-3">Kuantitas</label>
                      <Field>
                      {({}) => (
                        <CurrencyInput
                          name="kuantitas"
                          defaultValue={(values.kuantitas) ? kuantitas(values.kuantitas) : kuantitas(0)}
                          allowDecimals={false}
                          allowNegativeValue={false}
                          intlConfig={{ locale: 'id-ID' }}
                          decimalScale={0}
                          disableGroupSeparators={false}
                          decimalSeparator=","
                          groupSeparator="."
                          className="form-control"
                          onChange={(e:any) => {
                            const val = Number(String(e.target.value).split('.').join(''));
                            setFieldValue('kuantitas', kuantitas(val));
                            setFieldValue('subtotal', subTotal(null));
                          }}
                          required
                        />)}
                      </Field>
                    </div>
                    <div className={"col-md-8"}>
                      {!detailData ?
                        (
                          <FormikSelect
                            name="satuan"
                            label="Satuan"
                            optionsUrl={`${API_URL}/api/masterdata/satuan`}
                            unique_property={"id"}
                            getOptionValue={(opt: any) => opt}
                            getOptionLabel={(opt: any) => opt.uom_uom_name}
                            search_property={"uom_name"}
                            required
                          />
                        ) : (
                          <FormikSelect2
                            name="satuan"
                            label="Satuan"
                            optionsUrl={`${API_URL}/api/masterdata/satuan`}
                            unique_property={"id"}
                            getOptionValue={(opt: any) => opt}
                            getOptionLabel={(opt: any) => opt.uom_uom_name}
                            search_property={"uom_name"}
                            required
                          />
                        )}
                    </div>
                  </div>
                  <div className={"form-group mb-4"}>
                    <label className="required form-label mb-3">Unit Price</label>
                    <Field>
                      {({}) => (
                      <CurrencyInput
                        name="unit_price"
                        defaultValue={(values.unit_price) ? unitPrice(values.unit_price) : unitPrice(0)}
                        allowDecimals={false}
                        allowNegativeValue={false}
                        intlConfig={{ locale: 'id-ID' }}
                        decimalScale={2}
                        disableGroupSeparators={false}
                        decimalSeparator=","
                        groupSeparator="."
                        className="form-control"
                        onChange={(e:any) => { 
                          const val = Number(String(e.target.value).split('.').join('')); 
                          setFieldValue('unit_price', unitPrice(val));
                          setFieldValue('subtotal', subTotal(null));
                        }}
                        required
                      />
                      )}
                      </Field>
                  </div>
                  <div className={"form-group mb-4"}>
                    <label className="required form-label mb-3">Sub Total (Estimasi)</label>
                    <Field>
                      {({}) => (
                        <CurrencyInput
                          name="subtotal"
                          disabled={true}
                          defaultValue={0}
                          allowDecimals={false}
                          allowNegativeValue={false}
                          intlConfig={{ locale: 'id-ID' }}
                          decimalScale={2}
                          disableGroupSeparators={false}
                          decimalSeparator=","
                          groupSeparator="."
                          className="form-control"
                          value={subTotal(null)}
                          required
                        />
                      )}
                    </Field>
                  </div>
                  <div className={"row mb-4"}>
                    <div className={"col-md-6"}>
                      <label className="required form-label mb-3">Unit Price Terendah</label>
                        <Field>
                        {({}) => (
                        <CurrencyInput
                          name="unit_price_terendah"
                          defaultValue={(values.unit_price_terendah) ? values.unit_price_terendah : 0}
                          allowDecimals={false}
                          allowNegativeValue={false}
                          intlConfig={{ locale: 'id-ID' }}
                          decimalScale={2}
                          disableGroupSeparators={false}
                          decimalSeparator=","
                          groupSeparator="."
                          className="form-control"
                          onChange={(e:any) => {
                            const val = Number(String(e.target.value).split('.').join(''));
                            setFieldValue('unit_price_terendah', val);
                          }}
                          required
                        />)}
                      </Field>
                    </div>
                    <div className={"col-md-6"}>
                      <label className="required form-label mb-3">Unit Price Tertinggi</label>
                      <Field>
                        {({}) => (
                        <CurrencyInput
                          name="unit_price_tertinggi"
                          defaultValue={(values.unit_price_tertinggi) ? values.unit_price_tertinggi : 0}
                          allowDecimals={false}
                          allowNegativeValue={false}
                          intlConfig={{ locale: 'id-ID' }}
                          decimalScale={2}
                          disableGroupSeparators={false}
                          step={100000}
                          decimalSeparator=","
                          groupSeparator="."
                          className="form-control"
                          onChange={(e:any) => {
                            const val = Number(String(e.target.value).split('.').join(''));
                            setFieldValue('unit_price_tertinggi', val);
                          }}
                          required
                        />)}
                      </Field>
                    </div>
                  </div>
                  <div className={"form-group mb-4"}>
                    <label className="required form-label mb-3">Note</label>
                    <Field
                      as='textarea'
                      rows={5}
                      className="form-control"
                      name="note"
                      required
                    />
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <div>
                    <button className={"btn btn-danger me-3 btn-sm"} onClick={() => {
                      reset()
                      setIsShowModalCreate(false)
                    }}>
                      <i className={"fa fa-times"}></i> Batal
                    </button>
                    <button type='submit' className='btn btn-primary me-3 btn-sm' disabled={isLoadingSave}>
                      {isLoadingSave && <Spinner animation={"border"} size={"sm"} className={"me-2"} />}
                      <i className={"fa fa-save"}></i> Simpan Draft
                    </button>
                  </div>
                </Modal.Footer>
              </Form>
            </>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export { ItemAuction };
