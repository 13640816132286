import {Link, useLocation, useNavigate, useParams} from 'react-router-dom'
import {Badge, Placeholder, Toggle} from 'rsuite'
import {IntlShape, useIntl} from 'react-intl'
import BottomToolbarWrapper from '../../../../../components/shared/BottomToolbarWrapper'
import React, {useCallback, useState} from 'react'
import {
  ClarificationAttachmentType,
  ClarificationEvaluatorResultModel,
  ClarificationEvaluatorResultNoteModel,
  ClarificationEvaluatorResultParamModel,
  ClarificationTenderItemAttachment,
  ClarificationTenderItemModel,
  ClarificationTenderItemStatus,
  ClarificationTenderItemVendorModel,
} from '../../../core/_models'
import {FetchResponse, MediaModel, Response} from '../../../../../../_models'
import {UseMutateAsyncFunction, useMutation, useQuery, useQueryClient} from 'react-query'
import axios, {AxiosError, AxiosResponse} from 'axios'
import {formatDateTimeToDBFormat, getEnv} from '../../../../../../helpers/procurex'
import {UseMutationOptions, UseQueryOptions} from 'react-query/types/react/types'
import {Field, Form, Formik, FormikHelpers} from 'formik'
import * as Yup from 'yup'
import {
  KTCard,
  MetronicErrorMessage,
  toastError,
  toastSuccess,
} from '../../../../../../_metronic/helpers'
import {useAuth, UserModel} from '../../../../auth'
import SelectX, {getSelectStylesControl} from '../../../../../components/shared/form/SelectX'
import moment from 'moment'
import {ApprovalX, ButtonProsesApproval} from '../../../../../components/shared/ApprovalX'
import BeritaAcaraTabContent from './BeritaAcaraTabContent'
import {EvaluationSendToEndUserModal} from './EvaluationSendToEndUserModal'
import TableXWrpper from '../../../../../components/shared/TableXWrapper'
import TableXWrapper from '../../../../../components/shared/TableXWrapper'
import {IColumns} from '../../../../../components/shared/TableX'
import {Column} from 'react-table'
import FormikUploadFile from '../../../../../components/shared/form/FormikUploadFile'
import Swal from 'sweetalert2'
import {useUploadMedia} from '../../../../../../hooks/MediaUploadHook'
import EvaluationSyncPrModalButton from './EvaluationSyncPrModal'
import PreviewAttachment from '../../../../../components/PreviewAttachment'
import TableTabs from '../../../../mr-sr/request/list/components/header/TableTabs'
import {useHashNumberValueByPrefix} from '../../../../../../_metronic/layout/components/core/hooks'
import Select from 'react-select'
import {
  useClarificationTenderItemsQuery,
  useGetRoutingApprovalConfigForSubmitReturnQuery,
  useSubmitReturnMutation,
} from '../core/_queries'
import {EvaluationAlihkanModal} from './EvaluationAlihkanModal'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

const TENDER_API_URL = getEnv('tender')

interface QueryOptions<T> extends Omit<UseQueryOptions<T>, 'queryKey' | 'queryFn'> {}

const allowedEvaluatorStatuses: ClarificationTenderItemStatus[] = [
  'On Progress',
  'Reject Approval Return',
  'Reject Receive',
  'Reject Approval Evaluator',
  'Reject Approval Alihkan',
  'Cancel End User',
]
const allowedEndUserStatuses: ClarificationTenderItemStatus[] = [
  'On Progress End User',
  'Reject Approval Return End User',
]

//region RowHasilEvaluasi
interface RowHasilEvaluasiProps {
  index: number
  evaluatorResult: ClarificationEvaluatorResultModel
  isReadOnly: boolean
  evaluatorResultValue: any
  setFieldValue: FormikHelpers<any>['setFieldValue']
}

const RowHasilEvaluasi = ({
  index,
  evaluatorResult,
  isReadOnly,
  evaluatorResultValue,
  setFieldValue,
}: RowHasilEvaluasiProps) => {
  const isAllParamsFilled =
    evaluatorResultValue?.params
      ?.filter((param: any) => !param.exclude)
      ?.every((param: any) => param.is_comply !== '' && param.comment !== '' && param.is_locked) ||
    false
  const hasTooltip = !isReadOnly && !isAllParamsFilled

  isReadOnly = isReadOnly || !isAllParamsFilled

  // noinspection RequiredAttributes
  return (
    <div className={'text-start p-2'}>
      <OverlayTrigger
        overlay={hasTooltip
          ? <Tooltip id={`tooltip-result-${evaluatorResult.id}`}>
            Lengkapi hasil, komentar, kuncil hasil seluruh parameter
          </Tooltip>
          : <></>
        }
      >
        <div className={'border-bottom mb-2'}>
          <label className='fw-bold'>
            Hasil Evaluasi Final{' '}
            {hasTooltip && <i className='bi bi-patch-question-fill ms-1'></i>}
          </label>
          <div>
            <SelectX
              defaultValue={evaluatorResult?.status}
              isDisabled={isReadOnly}
              onChange={(newValue: string) => setFieldValue(`results.${index}.status`, newValue)}
              options={['Diterima', 'Diterima dengan catatan', 'Ditolak'].map((value) => ({
                label: value,
                value,
              }))}
            />
          </div>
        </div>
      </OverlayTrigger>

      {['Diterima dengan catatan', 'Ditolak'].includes(evaluatorResultValue?.status) && (
        <div>
          <strong>Catatan Hasil Evaluasi</strong>
          <div>
            <Field
              as='textarea'
              row='2'
              name={`results.${index}.note`}
              className={'form-control'}
              disabled={isReadOnly}
            />
          </div>
        </div>
      )}
      <div className={'border-bottom'}>
        <strong>Dilakukan Oleh</strong>
        <div>
          {evaluatorResult?.updated_by
            ? `${evaluatorResult.updated_by.name} (${evaluatorResult.updated_by.username})`
            : '-'}
        </div>
      </div>
      <div>
        <strong>Pada</strong>
        <div>
          {evaluatorResult?.updated_by
            ? moment(evaluatorResult.updated_at).format('DD-MM-YYYY HH:mm:ss')
            : '-'}
        </div>
      </div>
    </div>
  )
}
//endregion

//region RowDokumen
const RowDokumen = ({
  description,
  badgeContent,
  mediaUrl,
}: {
  description: string
  badgeContent: string
  mediaUrl: string
}) => {
  const [preview, setPreview] = useState(null)

  return (
    <div className={'border-bottom mb-3'}>
      <div className={'text-start d-flex flex-column'}>
        <div className={'d-flex justify-content-between'}>
          <div>{description}</div>
          <div>
            <Badge content={badgeContent} color={'green'} />
          </div>
        </div>
        <div>
          {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
          <a href={'javascript:;'} onClick={() => setPreview(mediaUrl)}>
            <i className={'fa fa-chevron-right me-2'}></i> Lihat File
          </a>
          <PreviewAttachment previewExt={preview} onHide={() => setPreview(null)} />
        </div>
      </div>
    </div>
  )
}
//endregion

//region RowAksi
interface RowAksiProps {
  index: number
  vendor: ClarificationTenderItemVendorModel
  evaluatorResult: ClarificationEvaluatorResultModel
  isReadOnly: boolean
  evaluatorResultValue: any
  setFieldValue: FormikHelpers<any>['setFieldValue']
}

const RowAksi = ({
  index,
  vendor,
  evaluatorResult,
  isReadOnly,
  evaluatorResultValue,
  setFieldValue,
}: RowAksiProps) => {
  const intl = useIntl()
  const syaratTexts = {
    administration: intl.formatMessage({id: 'Admin'}),
    technical: intl.formatMessage({id: 'Teknis'}),
    commercial: intl.formatMessage({id: 'Komersial'}),
  }

  return (
    <div>
      <div className={'text-start'}>
        <strong>Dokumen</strong>
        <div
          className={'border p-2 custom-scrollbar '}
          style={{
            maxHeight: '250px',
            overflow: 'auto',
          }}
        >
          {vendor.syarat_evaluations.map((syaratEvaluasi, syaratEvaluasiIndex) => (
            <RowDokumen
              key={syaratEvaluasiIndex}
              description={syaratEvaluasi?.description}
              badgeContent={syaratTexts[syaratEvaluasi?.type] || 'Item'}
              mediaUrl={syaratEvaluasi?.media_url}
            />
          ))}
          {vendor.clarification_attachments.map((attachment, attachmentIndex) => (
            <RowDokumen
              key={attachmentIndex}
              description={`Klarifikasi Vendor Sesi ${attachment?.session}`}
              badgeContent={syaratTexts[attachment?.syarat_evaluation_type] || 'Item'}
              mediaUrl={attachment?.media_url}
            />
          ))}
        </div>
      </div>

      <div className={'border'}>
        <RowHasilEvaluasi
          index={index}
          evaluatorResult={evaluatorResult}
          isReadOnly={isReadOnly}
          setFieldValue={setFieldValue}
          evaluatorResultValue={evaluatorResultValue}
        />
      </div>
    </div>
  )
}
//endregion

//region RowVendor
const canEditLatestNote = (
  clarificationTenderItem: ClarificationTenderItemModel,
  evaluatorResult: ClarificationEvaluatorResultModel,
  currentUser: UserModel
) => {
  if (evaluatorResult?.clarification_tender_item_id !== clarificationTenderItem.id) {
    return false
  }

  const latestNote = evaluatorResult?.notes.at(-1)

  return (
    clarificationTenderItem.current_user === currentUser.username &&
    (latestNote?.created_by === null || latestNote?.created_by?.username === currentUser.username)
  )
}

interface RowVendorProps {
  index: number
  tenderItem: ClarificationTenderItemModel
  vendor: ClarificationTenderItemVendorModel
  evaluatorResult: ClarificationEvaluatorResultModel
  isReadOnly: boolean
  evaluatorResultValue: any
  setFieldValue: FormikHelpers<any>['setFieldValue']
}

const RowVendor = ({
  index,
  tenderItem,
  vendor,
  evaluatorResult,
  isReadOnly,
  evaluatorResultValue,
  setFieldValue,
}: RowVendorProps) => {
  const {currentUser} = useAuth()
  const params =
    evaluatorResult !== undefined
      ? evaluatorResult.params
      : tenderItem?.parameters.map((param) => ({
          name: param,
          is_comply: null,
        }))
  const isCanEditLatestNote = canEditLatestNote(tenderItem, evaluatorResult, currentUser)
  const shownNotes = evaluatorResult
    ? evaluatorResult.notes
        .slice(0, isCanEditLatestNote ? -1 : undefined)
        .filter((note) => note.note)
    : []

  return (
    <div className={'border p-2'}>
      <section title='Info Vendor' className={'d-flex flex-column align-items-start'}>
        <div>
          <strong>
            {vendor.vendor.name} ({vendor.vendor_id})
          </strong>
        </div>
      </section>

      <section title='Tabel Parameter Evaluasi'>
        <table className={'table mt-4'}>
          <thead>
            <tr>
              <th className={'border px-2 text-center'} style={{width: 10}}>
                <strong>No</strong>
              </th>
              <th className={'border px-2'} style={{width: 100}}>
                <strong>Parameter Evaluasi</strong>
              </th>
              <th className={'border px-2'} style={{width: 120}}>
                <strong>Hasil</strong>
              </th>
              <th className={'border px-2'} style={{width: 200}}>
                <strong>Komentar</strong>
              </th>
              <th className={'border px-2'} style={{width: 50}}>
                <strong>Kunci Hasil</strong>
              </th>
            </tr>
          </thead>
          <tbody className='text-start'>
            {params.map((param: ClarificationEvaluatorResultParamModel, paramIndex: number) => {
              const paramValue = evaluatorResultValue.params?.[paramIndex]
              const isCurrentlyLocked = paramValue?.is_locked ?? false
              const is_comply = paramValue?.is_comply || null
              const comment = paramValue?.comment || ''
              const disableLock =
                isReadOnly ||
                tenderItem.step !== 'evaluator' ||
                paramValue?.exclude ||
                [null, '', undefined].includes(is_comply) ||
                [null, '', undefined].includes(
                  typeof comment === 'string' ? comment.trim() : comment
                )

              return (
                <tr key={paramIndex}>
                  <td className={'px-2 border align-middle text-center'}>{paramIndex + 1}</td>
                  <td className={'px-2 border align-middle text-start'}>{param.name}</td>
                  <td className={'px-2 border'}>
                    <SelectX
                      placeholder={isReadOnly ? '' : undefined}
                      defaultValue={param.is_comply === null ? '' : param.is_comply ? '1' : '0'}
                      isDisabled={
                        isReadOnly || isCurrentlyLocked || tenderItem.step !== 'evaluator'
                      }
                      onChange={(newValue: string) =>
                        setFieldValue(`results.${index}.params.${paramIndex}.is_comply`, newValue)
                      }
                      options={[
                        {
                          value: '1',
                          label: 'Sesuai',
                        },
                        {
                          value: '0',
                          label: 'Tidak Sesuai',
                        },
                      ]}
                    />
                    <MetronicErrorMessage
                      name={`results.${index}.params.${paramIndex}.is_comply`}
                    />
                  </td>
                  <td className={'px-2 border'}>
                    {paramValue?.exclude ? (
                      <input value={comment} disabled className='form-control' />
                    ) : (
                      <Field
                        name={`results.${index}.params.${paramIndex}.comment`}
                        disabled={
                          isReadOnly || isCurrentlyLocked || tenderItem.step !== 'evaluator'
                        }
                        className='form-control'
                      />
                    )}

                    <MetronicErrorMessage name={`results.${index}.params.${paramIndex}.comment`} />
                  </td>
                  <td className={'px-2 border align-middle text-center'}>
                    <Toggle
                      defaultChecked={param.locked_by?.username !== undefined}
                      onChange={(checked) =>
                        setFieldValue(`results.${index}.params.${paramIndex}.is_locked`, checked)
                      }
                      disabled={isReadOnly || disableLock}
                    />
                    <span className={'text-nowrap ms-2'}>
                      {param.locked_by
                        ? `${param.locked_by.username} - ${param.locked_by.name}`
                        : ''}
                    </span>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </section>

      {shownNotes.length > 0 && (
        <section title='Tabel Catatan Evaluasi Terkait Vendor'>
          <table className={'table mt-4 mb-0'}>
            <thead>
              <tr>
                <th className={'border px-2 text-center'} style={{width: 10}}>
                  <strong>No</strong>
                </th>
                <th className={'border px-2'}>
                  <strong>Catatan Evaluasi Terkait Vendor</strong>
                </th>
              </tr>
            </thead>
            <tbody className='text-start'>
              {shownNotes.map((note: ClarificationEvaluatorResultNoteModel, noteIndex: number) => (
                <tr key={noteIndex}>
                  <td className={'px-2 border text-center'}>{noteIndex + 1}</td>
                  <td className={'px-2 border text-start'}>
                    <div className='d-flex align-items-center'>
                      <div className='flex-grow-1'>
                        <span className='text-gray-800 fw-bold'>{`${note.created_by?.name} (${note.created_by?.username})`}</span>

                        <span className='badge badge-light'>
                          {note.type === 'evaluator' ? 'Evaluator' : 'End User'}
                        </span>

                        <span className='text-gray-500 fw-semibold d-block'>
                          {note?.updated_at
                            ? moment(note.updated_at).format('DD-MM-YYYY HH:mm:ss')
                            : '-'}
                        </span>
                      </div>
                    </div>

                    <div className='pt-3 fw-normal text-gray-700' style={{whiteSpace: 'pre-wrap'}}>
                      {note.note}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
      )}

      {isCanEditLatestNote && (
        <section title='Input Catatan Evaluasi Vendor' className={'text-start mt-3'}>
          <label htmlFor={`results.${index}.quotation_item_note`}>
            Catatan Evaluasi Terkait Vendor{' '}
            <strong>
              {vendor.vendor.name} ({vendor.vendor_id})
            </strong>
            :
          </label>
          <Field
            as='textarea'
            name={`results.${index}.quotation_item_note`}
            className={'form-control'}
            rows={5}
            disabled={isReadOnly}
          />

          <MetronicErrorMessage name={`results.${index}.quotation_item_note`} />
        </section>
      )}
    </div>
  )
}
//endregion

//region RowAttachment
interface AddClarificationTenderItemAttachmentPayload {
  type: string
  media_url: string
  description?: string | null
}

const useAddClarificationTenderItemAttachmentMutation = (tenderItemUuid: string) =>
  useMutation((data: AddClarificationTenderItemAttachmentPayload) =>
    axios.post(`${TENDER_API_URL}/clarification/tender-item/${tenderItemUuid}/attachment`, data)
  )

const useDeleteClarificationTenderItemAttachmentMutation = (
  tenderItemUuid: string,
  options?: Omit<UseMutationOptions<Response<any>, AxiosError, string>, 'mutationFn'>
) =>
  useMutation(
    (uuid: string): FetchResponse<any> =>
      axios.delete(
        `${TENDER_API_URL}/clarification/tender-item/${tenderItemUuid}/attachment/${uuid}`
      ),
    options
  )

const getTableColumns = (
  intl: IntlShape,
  currentUser: UserModel,
  isReadOnly: boolean,
  onDelete: UseMutateAsyncFunction<Response<any>, unknown, string>
): IColumns[] | Column<ClarificationTenderItemAttachment>[] => {
  const columns = []

  columns.push({
    Header: intl.formatMessage({id: 'Aksi'}),
    accessor: 'uuid',
    searchable: false,
    orderable: false,
    disableSortBy: true,
    width: 100,
    Cell: ({value: uuid, row}) => {
      const [preview, setPreview] = useState(null as string)
      return (
        <div className={'text-center'}>
          {!isReadOnly && (
            <>
              {row.original.created_by?.username === currentUser.username && (
                <button
                  className='btn btn-link btn-xs me-2'
                  onClick={() =>
                    Swal.fire({
                      title: intl.formatMessage({id: 'Apakah Anda yakin'}) + '?',
                      text: intl.formatMessage({id: 'Anda akan menghapus attachment ini'}),
                      icon: 'question',
                      showCancelButton: true,
                      confirmButtonText: 'Ya, hapus',
                      cancelButtonText: 'Tidak',
                    }).then(async (result) => {
                      /* Read more about isConfirmed, isDenied below */
                      if (result.isConfirmed) {
                        await onDelete(uuid, {
                          onSuccess: (response) => {
                            toastSuccess(response.data.meta.message)
                          },
                        })
                      }
                    })
                  }
                >
                  <i className={'fa fa-trash fs-3'} />
                </button>
              )}
            </>
          )}

          <button
            className='btn btn-link btn-xs'
            onClick={() => {
              setPreview(row?.original?.media_url)
            }}
          >
            <i className={'fa fa-eye fs-3'} />
          </button>
          <PreviewAttachment previewExt={preview} onHide={() => setPreview(null)} />
        </div>
      )
    },
  })

  return [
    ...columns,
    {
      Header: intl.formatMessage({id: 'Jenis Lampiran'}),
      accessor: 'type',
      width: 200,
    },
    {
      Header: intl.formatMessage({id: 'Deskripsi Lampiran'}),
      accessor: 'description',
      width: 325,
    },
    {
      Header: intl.formatMessage({id: 'Upload By'}),
      accessor: 'created_by',
      width: 225,
      Cell: ({value}) =>
        typeof value !== 'string' && value?.username ? `${value.username} - ${value.name}` : value,
    },
    {
      Header: intl.formatMessage({id: 'Upload Date'}),
      accessor: 'created_at',
      width: 250,
      Cell: ({value}) => (value ? moment(value).format('DD-MM-YYYY HH:mm:ss') : ''),
    },
  ]
}

interface RowAttachmentProps {
  clarificationTenderItem: ClarificationTenderItemModel
  isReadOnly: boolean
}

const RowAttachment = ({clarificationTenderItem, isReadOnly}: RowAttachmentProps) => {
  const {currentUser} = useAuth()
  const {upload} = useUploadMedia()
  const intl = useIntl()
  const [tableKey, setTableKey] = useState(1)
  const [inputFileKey, setInputFileKey] = useState(1)
  const attachmentType: ClarificationAttachmentType | null =
    clarificationTenderItem !== undefined
      ? clarificationTenderItem.step === 'evaluator'
        ? 'Lampiran Evaluasi (Evaluator)'
        : 'Lampiran Evaluasi (End User)'
      : null
  const {mutateAsync: addAttachment, isLoading: isAddLoading} =
    useAddClarificationTenderItemAttachmentMutation(clarificationTenderItem?.uuid)

  const {mutateAsync: deleteAttachment} = useDeleteClarificationTenderItemAttachmentMutation(
    clarificationTenderItem?.uuid,
    {
      onSuccess: () => setTableKey((current) => current + 1),
    }
  )

  const tableColumns = getTableColumns(intl, currentUser, isReadOnly, deleteAttachment)

  return (
    <div>
      <h5 className='mb-5'>Lampiran Evaluasi</h5>

      {!isReadOnly && (
        <Formik
          initialValues={{
            type: attachmentType ?? '',
            attachment: '',
            description: '',
          }}
          validationSchema={Yup.object().shape({
            attachment: Yup.mixed().required().label('File'),
            description: Yup.string().label('Deskripsi'),
          })}
          onSubmit={async (values: any, {resetForm}) => {
            const {attachment, ...others} = values
            const PR_TENDER_OTHERS_MEDIA_TYPE_ID = '25'
            const media: MediaModel | null =
              attachment !== '' ? await upload(PR_TENDER_OTHERS_MEDIA_TYPE_ID, attachment) : null

            if (media === null) {
              toastError('Upload media gagal')
              return
            }

            await addAttachment(
              {
                media_url: media?.url,
                ...others,
              },
              {
                onSuccess: (response) => {
                  resetForm()
                  setInputFileKey((current) => current + 1)
                  setTableKey((current) => current + 1)
                  toastSuccess(response.data.meta.message)
                },
              }
            )
          }}
        >
          <Form>
            <div className='d-md-flex mb-8 gap-4'>
              <div className='flex-grow-1'>
                <div className='row'>
                  <div className='col-md-4 mb-3'>
                    <label htmlFor='type' className='form-label'>
                      Jenis Lampiran
                    </label>

                    <input type='text' className='form-control' disabled value={attachmentType} />

                    <MetronicErrorMessage name='type' />
                  </div>

                  <div className='col-md-4 mb-3'>
                    <FormikUploadFile key={inputFileKey} label='File' name='attachment' />
                  </div>

                  <div className='col-md-4 mb-3'>
                    <label htmlFor='description' className='form-label'>
                      Deskripsi
                    </label>

                    <Field name='description' className={'form-control'} disabled={isAddLoading} />

                    <MetronicErrorMessage name='description' />
                  </div>
                </div>
              </div>

              <div>
                <label className='form-label d-none d-md-block'>&nbsp;</label>
                <button disabled={isAddLoading} className={'btn btn-info me-4'}>
                  <i className={'fa fa-upload me-2'}></i>
                  Upload
                </button>
              </div>
            </div>
          </Form>
        </Formik>
      )}

      {clarificationTenderItem && (
        <div className='px-3 border'>
          <TableXWrpper
            key={tableKey}
            readonly={true}
            urlIndex={`${TENDER_API_URL}/clarification/tender-item/${clarificationTenderItem.uuid}/attachment`}
            columns={tableColumns as IColumns[]}
            disablePaddingCard={true}
            hideSelection={true}
            disableSearchInputAutoFocus={true}
          />
        </div>
      )}
    </div>
  )
}
//endregion

//region EvaluationSubmitModal
type EvaluationSubmitModalProps = {
  show: boolean
  onHide: () => void
  clarificationTenderItem: ClarificationTenderItemModel
  routingApprovalConfig: any
}

const EvaluationSubmitModal = ({
  show,
  onHide,
  clarificationTenderItem,
  routingApprovalConfig,
}: EvaluationSubmitModalProps) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const {mutate: submit, isLoading: isLoadingSubmitForApproval} = useSubmitReturnMutation({
    onSuccess: (response) => {
      toastSuccess(response.data.meta.message)
      onHide()
    },
    onError: (error: AxiosError) => {
      toastError(
        error.response.data?.meta?.message || error.response.data?.message || 'Terjadi kesalahan'
      )
      onHide()
    },
  })

  return (
    <ApprovalX
      show={show && routingApprovalConfig !== undefined}
      schema={routingApprovalConfig?.details}
      onHide={onHide}
      onSubmit={(values: {approval: any[]}) => {
        const data = {
          clarification_tender_item_ids: [clarificationTenderItem.uuid],
          approvers: values.approval.map((item: any) => ({
            step: item.step,
            jabatan: item.jabatan,
            type: 'APPROVAL',
            user_id: item.extra_info.user.user_id,
            extra_info: item.extra_info,
          })),
        }

        // noinspection JSIgnoredPromiseFromCall
        submit(data, {
          onSuccess: () => {
            onHide()
            // noinspection JSIgnoredPromiseFromCall
            queryClient.invalidateQueries([
              'clarification-tender-items',
              clarificationTenderItem.uuid,
              'results',
            ])
            navigate('/mr-sr/evaluasi-penawaran/incoming')
          },
        })
      }}
      isLoading={isLoadingSubmitForApproval}
      source={'TENDER'}
      object_id={clarificationTenderItem.id}
      company_id={clarificationTenderItem.company_id}
      code={clarificationTenderItem.routing_approval_config_code}
      readOnly={false}
    />
  )
}
//endregion

//region EvaluationIncomingForm
//region Fetch & Query
interface FetchParams {
  include?: string[]
}

const fetchClarificationTenderItem = (
  uuid: string,
  params?: FetchParams
): FetchResponse<ClarificationTenderItemModel> =>
  axios
    .get(`${TENDER_API_URL}/clarification/tender-item/${uuid}`, {
      params: {
        include: params?.include?.join(','),
      },
    })
    .then((response) => response.data)

const useClarificationTenderItemQuery = (
  uuid: string,
  fetchParams?: FetchParams,
  options?: QueryOptions<ClarificationTenderItemModel>
) =>
  useQuery<ClarificationTenderItemModel>(
    ['clarification-tender-items', uuid, 'results'],
    () => fetchClarificationTenderItem(uuid, fetchParams).then((response) => response.data),
    options
  )

const useClarificationTenderItemUpdateResultsMutation = (uuid: string, options: any) =>
  useMutation(
    ['clarification-tender-items', uuid, 'results'],
    (data: any) => axios.put(`${TENDER_API_URL}/clarification/tender-item/${uuid}/results`, data),
    options
  )

//endregion

const EvaluationIncomingFormPlaceholder = () => {
  return (
    <div className={'px-3'}>
      <div className={'row py-3 mb-4 bg-white'}>
        <div className={'col-sm-12 border'}>
          <div className='bg-white'>
            <h5>Spesifikasi</h5>

            <Placeholder.Graph height={125} />
          </div>
        </div>
      </div>

      <div className={'row bg-white'}>
        <div className={'col-md-9 col-sm-12 text-center border py-2'}>
          <h5>Vendor</h5>
        </div>
        <div className={'col-md-3 col-sm-12 text-center border py-2'}>
          <h5>Aksi</h5>
        </div>
      </div>

      <div className={'row bg-white'}>
        <div className='col-12'>
          {[1, 2].map((index) => (
            <div key={index} className='row'>
              <div className={'col-md-9 bg-white col-sm-12 text-center border py-2'}>
                <div className={'border p-2'}>
                  <Placeholder.Paragraph rows={1} />

                  <Placeholder.Grid rows={2} columns={5} className='mt-4' />

                  <Placeholder.Grid rows={2} columns={2} className='mt-4 mb-0' />

                  <Placeholder.Graph height={125} />
                </div>
              </div>
              <div className={'col-md-3 bg-white col-sm-12 text-center border p-2 mb-4'}>
                <div className={'text-start'}>
                  <Placeholder.Paragraph rows={1} />

                  <Placeholder.Grid rows={2} columns={2} />
                </div>

                <div className={'border text-start p-2'}>
                  <div className={'border-bottom mb-2'}>
                    <Placeholder.Paragraph rows={1} />

                    <Placeholder.Graph height={40} />
                  </div>
                  <div>
                    <Placeholder.Paragraph rows={1} />

                    <Placeholder.Graph height={60} />
                  </div>
                  <div className={'border-bottom'}>
                    <Placeholder.Paragraph rows={1} />

                    <Placeholder.Paragraph rows={1} />
                  </div>
                  <div>
                    <Placeholder.Paragraph rows={1} />

                    <Placeholder.Paragraph rows={1} />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className='col-12'>
          <hr />

          <Placeholder.Grid columns={6} rows={3} />
        </div>
      </div>
    </div>
  )
}

interface EvaluationIncomingFormProps {
  clarificationTenderItem: ClarificationTenderItemModel
  isReadOnly?: boolean
  hideBottomToolbar?: boolean
  isLoading?: boolean
}

interface EvaluationFormValues {
  evaluator_note: string
  results: Array<{
    id: string
    status: string
    note: string
    quotation_item_note: string
    params: Array<{
      id: string
      is_comply: string
      comment: string
      is_locked: boolean
      exclude: boolean
    }>
  }>
}

const getInitialValues = (
  clarificationTenderItem: ClarificationTenderItemModel
): EvaluationFormValues => {
  return {
    evaluator_note: clarificationTenderItem.evaluator_note || '',
    results: clarificationTenderItem.vendors.map((vendor) => {
      const evaluatorResult = clarificationTenderItem.evaluator_results?.find((evaluatorResult) =>
        vendor.quotation_item_ids.includes(evaluatorResult.quotation_item_id)
      )

      return {
        id: evaluatorResult?.uuid,
        status: evaluatorResult?.status || '',
        note: evaluatorResult?.note || '',
        quotation_item_note: evaluatorResult?.notes.at(-1)?.note,
        params: evaluatorResult?.params.map((param) => ({
          id: param.uuid,
          is_comply: param.is_comply === null ? '' : param.is_comply ? '1' : '0',
          comment: param.comment || '',
          is_locked: param.locked_by !== null,
          exclude:
            (param.locked_by &&
              param.locked_by?.username !== clarificationTenderItem.current_user) ||
            false,
        })),
      }
    }),
  }
}

export const EvaluationIncomingForm = ({
  clarificationTenderItem,
  isReadOnly = false,
  hideBottomToolbar = false,
  isLoading = false,
}: EvaluationIncomingFormProps) => {
  const {currentUser} = useAuth()
  const {state} = useLocation()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const [isShowSubmitModal, setIsShowSubmitModal] = useState(false)
  const [isShowSendToEndUserModal, setIsShowSendToEndUserModal] = useState(false)
  const [isShowAlihkanModal, setIsShowAlihkanModal] = useState(false)
  const {mutateAsync: updateResults, isLoading: isUpdateResultsLoading} =
    useClarificationTenderItemUpdateResultsMutation(clarificationTenderItem?.uuid, {
      onSuccess: (response: AxiosResponse<Response<any>>) => {
        const uuid = clarificationTenderItem.uuid

        // noinspection JSIgnoredPromiseFromCall
        queryClient.invalidateQueries(['clarification-tender-items', uuid, 'results'])
        // noinspection JSIgnoredPromiseFromCall
        queryClient.invalidateQueries([
          'clarification-tender-items',
          uuid,
          'routing-approval-config',
        ])

        toastSuccess(response.data.meta.message)
      },
      onError: (error: AxiosError) => {
        toastError(
          error.response.data?.meta?.message || error.response.data?.message || 'Terjadi kesalahan'
        )
      },
    })

  const canSubmit =
    allowedEvaluatorStatuses.includes(clarificationTenderItem?.status) ||
    allowedEndUserStatuses.includes(clarificationTenderItem?.status)

  const {data: routingApprovalConfig, isFetching: isGetRoutingApprovalConfigFetching} =
    useGetRoutingApprovalConfigForSubmitReturnQuery([clarificationTenderItem?.uuid], {
      enabled: clarificationTenderItem !== undefined && canSubmit && isShowSubmitModal,
      retry: false,
      onError: (error: AxiosError) => {
        setIsShowSubmitModal(false)
        toastError(
          error.response.data?.meta?.message || error.response.data?.message || 'Terjadi kesalahan'
        )
      },
    })

  const submitHandler = useCallback(
    async (values: EvaluationFormValues, onSuccess?: Function) => {
      values.results.forEach((result) => {
        result.params = result.params.filter((param) => !param.exclude)
      })

      await updateResults(values, {
        onSuccess: () => onSuccess && onSuccess(),
      })
    },
    [updateResults]
  )

  if (isLoading) {
    return <EvaluationIncomingFormPlaceholder />
  }

  const type = clarificationTenderItem?.step

  isReadOnly =
    isReadOnly ||
    isUpdateResultsLoading ||
    isGetRoutingApprovalConfigFetching ||
    clarificationTenderItem?.current_user !== currentUser.username ||
    !(
      allowedEvaluatorStatuses.includes(clarificationTenderItem?.status) ||
      allowedEndUserStatuses.includes(clarificationTenderItem?.status)
    )

  const canSubmitAlihkan =
    !isReadOnly &&
    clarificationTenderItem?.step === 'evaluator' &&
    [
      'On Progress',
      'Reject Approval Evaluator',
      'Reject Approval Alihkan',
      'Reject Approval Return',
      'Reject Receive',
      'Cancel End User',
    ].includes(clarificationTenderItem?.status)

  // noinspection PointlessBooleanExpressionJS
  return (
    <div className={'px-3'}>
      <div className={'row py-3 mb-4 bg-white'}>
        <div className={'col-sm-12 border'}>
          <div className='bg-white'>
            <h5>Spesifikasi</h5>
            <textarea
              className='form-control'
              rows={5}
              value={clarificationTenderItem?.po_text?.text}
              disabled
            >
              {clarificationTenderItem?.po_text?.text}
            </textarea>
          </div>
        </div>
      </div>

      <div className={'row bg-white'}>
        <div className={'col-md-9 col-sm-12 text-center border py-2'}>
          <h5>Vendor</h5>
        </div>
        <div className={'col-md-3 col-sm-12 text-center border py-2'}>
          <h5>Aksi</h5>
        </div>
      </div>

      <div className='row bg-white'>
        {clarificationTenderItem !== undefined && (
          <Formik
            enableReinitialize
            onSubmit={(values) => submitHandler(values)}
            initialValues={getInitialValues(clarificationTenderItem)}
            validationSchema={Yup.object()}
          >
            {({setFieldValue, values}) => (
              <>
                <div className='col-12'>
                  <Form>
                    {clarificationTenderItem?.vendors?.map((vendor, index) => {
                      const evaluatorResult = clarificationTenderItem.evaluator_results.find(
                        (evaluatorResult) =>
                          vendor.quotation_item_ids.includes(evaluatorResult.quotation_item_id)
                      )

                      return (
                        <div key={index} className='row'>
                          <div className={'col-md-9 bg-white col-sm-12 text-center border py-2'}>
                            <RowVendor
                              index={index}
                              tenderItem={clarificationTenderItem}
                              vendor={vendor}
                              evaluatorResult={evaluatorResult}
                              isReadOnly={isReadOnly}
                              evaluatorResultValue={values.results[index]}
                              setFieldValue={setFieldValue}
                            />
                          </div>
                          <div
                            className={'col-md-3 bg-white col-sm-12 text-center p-2 border pb-4'}
                          >
                            <RowAksi
                              index={index}
                              vendor={vendor}
                              evaluatorResult={evaluatorResult}
                              isReadOnly={isReadOnly || type !== 'evaluator'}
                              evaluatorResultValue={values.results[index]}
                              setFieldValue={setFieldValue}
                            />
                          </div>
                        </div>
                      )
                    })}

                    {!hideBottomToolbar && (
                      <BottomToolbarWrapper>
                        {clarificationTenderItem?.step === 'evaluator' && (
                          <button
                            type={'button'}
                            disabled={isReadOnly}
                            className={'btn btn-sm btn-warning me-4'}
                            onClick={async () => {
                              setIsShowSendToEndUserModal(true)
                            }}
                          >
                            <i className={'fa fa-user me-2'}></i>
                            Kirim ke Enduser
                          </button>
                        )}

                        {canSubmitAlihkan && (
                          <button
                            type={'button'}
                            disabled={isReadOnly}
                            className={'btn btn-sm btn-secondary me-4'}
                            onClick={async () => {
                              setIsShowAlihkanModal(true)
                            }}
                          >
                            <i className={'fa fa-arrow-right me-2'}></i>
                            Alihkan
                          </button>
                        )}

                        <button
                          type='submit'
                          disabled={isReadOnly}
                          className={'btn btn-sm btn-info me-4'}
                        >
                          <i className={'fa fa-save me-2'}></i>
                          Simpan
                        </button>
                        <button
                          type={'button'}
                          disabled={isReadOnly}
                          className={'btn btn-sm btn-primary'}
                          onClick={async () =>
                            await submitHandler(values, () => setIsShowSubmitModal(true))
                          }
                        >
                          <i className={'fa fa-paper-plane me-2'}></i>
                          Submit
                        </button>
                      </BottomToolbarWrapper>
                    )}
                  </Form>
                </div>

                {clarificationTenderItem?.step === 'evaluator' && (
                  <div className='col-12 order-last'>
                    <hr />

                    <div className='col mb-3'>
                      <label htmlFor='evaluator_note' className='h5'>
                        Catatan dari Evaluator
                      </label>

                      <Field
                        as={'textarea'}
                        name={`evaluator_note`}
                        className={'form-control'}
                        rows={5}
                        disabled={isReadOnly}
                      />

                      <MetronicErrorMessage name='evaluator_note' />
                    </div>
                  </div>
                )}
              </>
            )}
          </Formik>
        )}

        <div className='col-12'>
          <hr />

          <RowAttachment
            clarificationTenderItem={clarificationTenderItem}
            isReadOnly={isReadOnly}
          />
        </div>
      </div>

      <div className='row bg-white'>
        {clarificationTenderItem !== undefined && (
          <EvaluationSendToEndUserModal
            clarificationTenderItems={[clarificationTenderItem]}
            show={isShowSendToEndUserModal}
            onHide={() => setIsShowSendToEndUserModal(false)}
            onSuccess={() => navigate(state?.previous || '/mr-sr/evaluasi-penawaran/incoming')}
          />
        )}

        {clarificationTenderItem !== undefined && (
          <EvaluationSubmitModal
            show={
              !isUpdateResultsLoading && !isGetRoutingApprovalConfigFetching && isShowSubmitModal
            }
            onHide={() => setIsShowSubmitModal(false)}
            clarificationTenderItem={clarificationTenderItem}
            routingApprovalConfig={routingApprovalConfig}
          />
        )}

        {clarificationTenderItem !== undefined && canSubmitAlihkan && (
          <EvaluationAlihkanModal
            clarificationTenderItems={[clarificationTenderItem]}
            show={isShowAlihkanModal}
            onHide={() => setIsShowAlihkanModal(false)}
            onSuccess={() => navigate(state?.previous || '/tender/admin/evaluation/incoming')}
          />
        )}
      </div>
    </div>
  )
}
//endregion

const EvaluasiUserDetailHistory = ({clarificationTenderItem}) => {
  const intl = useIntl()
  const urlIndex = `${getEnv('tender')}/clarification/tender-item/logs`
  const tableColumns: IColumns[] | Column<any>[] = [
    {
      Header: intl.formatMessage({id: 'Pengirim'}),
      accessor: 'causer',
      searchable: false,
      orderable: false,
      disableSortBy: true,
      width: 260,
      Cell: ({value}) => (value ? `${value.name} (${value.username})` : ''),
    },
    {
      Header: intl.formatMessage({id: 'Kegiatan'}),
      accessor: 'description',
      orderable: false,
      disableSortBy: true,
      width: 650,
    },
    {
      Header: intl.formatMessage({id: 'Komentar'}),
      accessor: 'properties.comment',
      orderable: false,
      disableSortBy: true,
      width: 200,
      Cell: ({value}) => (Array.isArray(value) ? value.join(', ') : value),
    },
    {
      Header: intl.formatMessage({id: 'Tanggal Aksi'}),
      accessor: 'created_at',
      width: 150,
      className: 'text-center',
      Cell: ({value}) => (value ? formatDateTimeToDBFormat(value) : ''),
    },
  ]

  return (
    <div className={'card'}>
      <div className={'card-body'}>
        {clarificationTenderItem?.proposal_tender_item_id && (
          <TableXWrapper
            urlIndex={urlIndex}
            defaultFilter={`sort=-created_at&include=causer&filter[proposal_tender_item_id]=${clarificationTenderItem.proposal_tender_item_id}`}
            columns={tableColumns}
            disableUrlChanged={true}
            hideSearch={false}
            hideExportAs={false}
            disablePaddingCard={true}
            readonly={true}
          />
        )}
      </div>
    </div>
  )
}

const EvaluationDetailSelect = ({
  clarificationTenderItem,
  onChange,
  isLoading,
}: {
  clarificationTenderItem: ClarificationTenderItemModel
  onChange: (value: ClarificationTenderItemModel) => void
  isLoading?: boolean
}) => {
  const {currentUser} = useAuth()
  const {data: relatedItems, isLoading: isRelatedItemsLoading} = useClarificationTenderItemsQuery(
    {
      enabled: clarificationTenderItem?.proposal_tender_id !== undefined,
      retry: false,
      select: (data: ClarificationTenderItemModel[]) =>
        data.map((item) => ({
          ...item,
          value: item.id,
          label: `PR ${item.no_pr} Item ${item.line_no} (${item.short_text})`,
        })),
    },
    {
      filters: {
        latest_item_of_proposal_tender_id: clarificationTenderItem?.proposal_tender_id,
        current_user: currentUser?.username,
      },
    }
  )

  isLoading = (isLoading ?? false) || isRelatedItemsLoading

  return (
    <div className='bg-white' style={{minWidth: '350px'}}>
      <div className='input-group w-100' style={{minHeight: '42.29px'}}>
        <span className='input-group-text'>
          <i className='fa fa-list'></i>
        </span>
        <div className='input-group-prepend flex-grow-1'>
          {isLoading ? (
            <Placeholder.Graph className='h-100' />
          ) : (
            <Select
              defaultValue={{
                ...clarificationTenderItem,
                value: clarificationTenderItem?.id,
                label: clarificationTenderItem
                  ? `PR ${clarificationTenderItem.no_pr} Item ${clarificationTenderItem.line_no} (${clarificationTenderItem.short_text})`
                  : '',
              }}
              options={(relatedItems ?? []) as any}
              onChange={onChange}
              menuPlacement='auto'
              menuPosition='fixed'
              styles={{
                control: (baseStyles, props) => getSelectStylesControl(baseStyles, props),
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export const EvaluationIncomingDetail = () => {
  const intl = useIntl()
  const {state} = useLocation()
  const {uuid} = useParams()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const initialSelectedTab = useHashNumberValueByPrefix('tab', 1, [1, 2])
  const [formKey, setFormKey] = useState(1)
  const [selectedTab, setSelectedTab] = useState(initialSelectedTab)
  const [isShowApprovalSchema, setIsShowApprovalSchema] = useState(false)
  const {data: clarificationTenderItem, isLoading} = useClarificationTenderItemQuery(
    uuid,
    {
      include: [
        'poText',
        'vendors.clarification_attachments',
        'evaluator_results.notes',
        'evaluator_results.params',
      ],
    },
    {staleTime: Infinity}
  )

  const allowedStatuses: ClarificationTenderItemStatus[] = [
    'Submit Approval Return',
    'Submit Approval Evaluator',
    'Submit Approval Return End User',
    'Submit Approval Alihkan',
  ]
  const onApproval = allowedStatuses.includes(clarificationTenderItem?.status)

  return (
    <>
      <div className='mb-5 d-flex'>
        <div>
          <h1>
            {clarificationTenderItem !== undefined && !isLoading ? (
              `Evaluasi PR ${clarificationTenderItem.no_pr} Item ${clarificationTenderItem.line_no} (${clarificationTenderItem.short_text})`
            ) : (
              <Placeholder.Graph height={20} width={500} />
            )}
          </h1>
        </div>

        <div className='ms-auto'>
          <EvaluationDetailSelect
            clarificationTenderItem={clarificationTenderItem}
            onChange={async (newValue) => {
              navigate(`/mr-sr/evaluasi-penawaran/${newValue.uuid}`)

              await queryClient.invalidateQueries([
                'clarification-tender-items',
                newValue.uuid,
                'results',
              ])

              setFormKey((current) => current + 1)
            }}
            isLoading={isLoading}
          />
        </div>
      </div>

      <div className={'mb-4 d-flex'}>
        <div>
          <Link
            to={state?.previous || '/mr-sr/evaluasi-penawaran/incoming'}
            className={'btn btn-danger btn-sm'}
          >
            <i className={'fa fa-arrow-left'}></i>
            {intl.formatMessage({id: 'Kembali ke list'})}
          </Link>
        </div>

        <div className='ms-auto align-top'>
          <EvaluationSyncPrModalButton clarificationTenderItem={clarificationTenderItem} />

          {clarificationTenderItem && onApproval && (
            <div>
              <ButtonProsesApproval
                isLoading={isLoading}
                onShowDetailApproval={() => {
                  setIsShowApprovalSchema(true)
                }}
                source={'TENDER'}
                object_id={clarificationTenderItem.id}
                company_id={clarificationTenderItem.company_id}
                code={clarificationTenderItem.routing_approval_config_code}
                readOnly={true}
                onSubmit={async () => {
                  navigate('/mr-sr/evaluasi-penawaran/incoming')

                  await queryClient.invalidateQueries([
                    'clarification-tender-items',
                    uuid,
                    'results',
                  ])
                }}
              />

              <ApprovalX
                show={isShowApprovalSchema}
                onHide={() => setIsShowApprovalSchema(false)}
                source={'TENDER'}
                object_id={clarificationTenderItem.id}
                company_id={clarificationTenderItem.company_id}
                code={clarificationTenderItem.routing_approval_config_code}
                readOnly={true}
              />
            </div>
          )}
        </div>
      </div>

      <KTCard className={'mt-0 mb-1'}>
        <TableTabs
          tabs={[
            {
              value: 1,
              text: intl?.formatMessage({id: 'Proses Evaluasi'}),
              total: 0,
              href: `/mr-sr/evaluasi-penawaran/${uuid}#tab=1`,
              preventDefault: false,
            },
            {
              value: 2,
              text: intl?.formatMessage({id: 'Riwayat Evaluasi'}),
              total: 0,
              href: `/mr-sr/evaluasi-penawaran/${uuid}#tab=2`,
              preventDefault: false,
            },
            {
              value: 3,
              text: intl?.formatMessage({id: 'Berita Acara'}),
              total: 0,
              href: `/mr-sr/evaluasi-penawaran/${uuid}#tab=3`,
              preventDefault: false,
            },
          ]}
          currentTab={selectedTab}
          setCurrentTab={setSelectedTab}
          hideBadge={true}
        />
      </KTCard>

      {selectedTab === 1 && (
        <>
          {clarificationTenderItem?.step === 'end_user' && clarificationTenderItem?.evaluator_note && (
            <div className='col-md-12 mt-4'>
              <div className='alert alert-info text-dark'>
                <strong>{intl.formatMessage({id: 'Note dari Evaluator'})}</strong>
                <br />
                {clarificationTenderItem?.evaluator_note}
              </div>
            </div>
          )}
          <EvaluationIncomingForm
            key={formKey}
            clarificationTenderItem={clarificationTenderItem}
            isLoading={isLoading}
          />
        </>
      )}

      {selectedTab === 2 && (
        <EvaluasiUserDetailHistory clarificationTenderItem={clarificationTenderItem} />
      )}

      {selectedTab === 3 && (
        <div className='card'>
          <div className="card-body">
            <BeritaAcaraTabContent proposalTenderUuid={clarificationTenderItem?.proposal_tender_uuid} />
          </div>
        </div>
      )}
    </>
  )
}
