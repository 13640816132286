import React, { FC, useState } from 'react';
import { Alert } from 'react-bootstrap';

type Props = {
  title?: any;
  variant?: string;
  message?: string;
  dismissible?: boolean;
  html?: any;
};

const PageAlertX: FC<Props> = (props) => {
  const [show, setShow] = useState(true);

  const messageLines = props?.message?.split('\n') || [];

  const lineStyle = {
    margin: '5px 0', // You can adjust this value to change the spacing between lines
    lineHeight: '1.3', // Or you can adjust line-height for spacing
  };

  return (
    <Alert show={show} variant={props?.variant || 'info'} onClose={() => setShow(false)} dismissible={props.dismissible} className={"mb-5"}>
      {props?.title && (<Alert.Heading>{props?.title}</Alert.Heading>)}
      {messageLines.map((line, index) => (
        <p key={index} className={"text-dark"} style={lineStyle}>
          {line}
        </p>
      ))}
      {props?.html}
    </Alert>
  );
};

export { PageAlertX };