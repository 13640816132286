interface Props {
  tabs: Array<{
    value: number
    text: string
    disabled: boolean
  }>
  currentTab: number
  setCurrentTab: React.Dispatch<React.SetStateAction<number>>
}

const Tabs = ({tabs, currentTab, setCurrentTab}: Props) => {
  return (
    <>
      <div className='card my-3' style={{boxShadow: '0px 0px 30px rgba(56, 71, 109, 0.22)'}}>
        <div className='card-body p-0'>
          <div className='d-flex justify-content-between w-100 overflow-auto align-items-center rounded-lg py-3'>
            {tabs.map((tab: any) => (
              <div
                key={tab.value}
                className={`p-3 text-center d-flex align-items-center flex-column gap-2 flex-fill ${
                  tab.value === currentTab ? 'fw-bold text-info' : ''
                } ${!tab.disabled ? 'hover-cursor-pointer' : 'bg-secondary'}`}
                onClick={() => {
                  if (!tab.disabled) {
                    setCurrentTab(tab.value)
                  }
                }}
              >
                <i
                  className={`fs-4 ${tab.icon} ${tab.value === currentTab ? 'text-info' : ''}`}
                ></i>
                <span className='text-sm'>{tab.text}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default Tabs
