import { getEnv, parseNumberToCurrency, parseNumberToFloat } from "../../../../../helpers/procurex";
import { useEffect, useState } from "react";
import { useAxiosGet } from "../../../../../hooks/useAxios";
import { Spinner } from "react-bootstrap";


const RowRiwayat = ({ item, index }: any) => {
  return (
    <>
      <thead>
        <tr>
          <th className={"border text-center px-2"} style={{ width: "50px" }}>No</th>
          <th className={"border text-start px-2"}>Item Name</th>
          <th className={"border text-start px-2"}>Currency</th>
          <th className={"border text-start px-2"}>Quantity</th>
          <th className={"border text-start px-2"}>Unit Price</th>
          <th className={"border text-start px-2"}>Total Price</th>
          <th className={"border text-start px-2"}>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr key={"row-riwayat" + index}>
          <td className={"border text-center px-2"}>{index + 1}</td>
          <td className={"border text-start px-2"}>{item.nama}</td>
          <td className={"border text-start px-2"}>{item.currency}</td>
          <td className={"border text-end px-2"}>{item.quantity}</td>
          <td className={"border text-end px-2"}>-</td>
          <td className={"border text-end px-2"}>-</td>
          <td className={"border text-end px-2"}></td>
        </tr>
      </tbody>
      <thead>
        <tr>
          <th className={"border text-start px-2"} colSpan={2}>Date</th>
          <th className={"border text-start px-2"}>Vendor</th>
          <th className={"border text-start px-2"}>Quantity</th>
          <th className={"border text-start px-2"}>Unit Price</th>
          <th className={"border text-start px-2"}>Total Price</th>
          <th className={"border text-start px-2"}></th>
        </tr>
      </thead>
      <tbody>
        {item?.details?.map((history: any, index2: number) => {
          return (
            <tr key={"tfoot-riwayat-" + index + '-' + index2}>
              <td className={"border text-center px-2"} colSpan={2}>{history.created_at}</td>
              <td className={"border text-start px-2"}>{history.peserta.vendor.name}</td>
              <td className={"border text-end px-2"}>{item.quantity}</td>
              <td className={"border text-end px-2"}>{parseNumberToCurrency(history.unit_price, item.currency)}</td>
              <td className={"border text-end px-2"}>{parseNumberToCurrency(history.total_price, item.currency)}</td>
              <td className={"border text-start px-2"}>{history.status}</td>
            </tr>
          );
        })}
      </tbody>
    </>
  );
};

const RowRiwayatMVB = ({ item, index }: any) => {
  return (
    <>
      <thead>
        <tr>
          <th className={"border text-center px-2"} style={{ width: "50px" }}>No</th>
          <th className={"border text-start px-2"}>Item Name</th>
          <th className={"border text-start px-2"}>Variable Name</th>
          <th className={"border text-start px-2"}>Currency</th>
          <th className={"border text-start px-2"}>Value</th>
          <th className={"border text-start px-2"}>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr key={"row-riwayat" + index}>
          <td className={"border text-center px-2"}>{index + 1}</td>
          <td className={"border text-start px-2"}>{item.nama}</td>
          <td className={"border text-start px-2"}>{item.param_setting_mvb_variable?.detail_template?.variable_name}</td>
          <td className={"border text-start px-2"}>{item.currency}</td>
          <td className={"border text-end px-2"}>-</td>
        </tr>
      </tbody>
      <thead>
        <tr>
          <th className={"border text-start px-2"} colSpan={3}>Date</th>
          <th className={"border text-start px-2"}>Vendor</th>
          <th className={"border text-start px-2"}>Value</th>
          <th className={"border text-start px-2"}></th>
        </tr>
      </thead>
      <tbody>
        {item?.details?.map((history: any, index2: number) => {
          return (
            <tr key={"tfoot-riwayat-" + index + '-' + index2}>
              <td className={"border text-center px-2"} colSpan={3}>{history.created_at}</td>
              <td className={"border text-start px-2"}>{history.peserta.vendor.name}</td>
              <td className={"border text-end px-2"}>{parseNumberToFloat(history.unit_price,2)}</td>
              <td className={"border text-start px-2"}>{history.status}</td>
            </tr>
          );
        })}
      </tbody>
    </>
  );
};

const RiwayatAuctionVendorTab = ({ title, tabulasi }: any) => {
  const [histories, setHistories] = useState<any>([]);

  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosGet(
    getEnv("auction") + "/as-vendor/history-bid/" + tabulasi?.auction_id
  );
  
  const getHistory = async () => {
    let url = getEnv("auction") + "/as-vendor/history-bid/" + tabulasi?.auction_id;
    doSubmit(undefined,url,undefined).then((res: any) => {
      setHistories(res.data);
    });
  };
  useEffect(() => {
    getHistory();
  }, []);

  return (
    <>
      <h3>{title}</h3>
      <button
        type={"button"}
        onClick={() => {
          getHistory();
        }}
        className='btn mb-10 btn-sm btn-light-info mx-1 fw-bold'>
        {isLoading && <Spinner animation={"border"} size={"sm"} className={"me-2"}></Spinner>}
        {!isLoading && <i className={"fa fa-refresh"}></i>}
        Refresh</button>
      <div className={"table-responsive"}>
        <table className={"table table-header-gray"}>
          {!tabulasi?.is_mvb && histories.map((item: any, index: number) => {
            return (
              <RowRiwayat key={'row-riwayat-' + index} item={item} index={index} />
            );
          })}
          {tabulasi?.is_mvb && histories.map((item: any, index: number) => {
            return (
              <RowRiwayatMVB key={'row-riwayat-' + index} item={item} index={index} />
            );
          })}
        </table>
      </div>
    </>
  );
};

export { RiwayatAuctionVendorTab };
