import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { setAuthImpersonate, useAuth } from "../../../../app/modules/auth";
import { useAxiosPost } from "../../../../hooks/useAxios";
import { getEnv } from "../../../../helpers/procurex";
import { toast } from "react-toastify";
import TableXWrpper from "../../../../app/components/shared/TableXWrapper";

const HeaderImpersonateChooseUser = () => {
  const [isShow, setIsShow] = React.useState(false);
  const [urlIndex,setUrlIndex] = useState(process.env.REACT_APP_API_URL+"/api/auth/user");
  const {currentUser} = useAuth()
  const [targetImpersonate,setTargetImpersonate] = useState({} as any)
  const [showImpersonate,setShowImpersonate] = useState(false)
  const  [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosPost(
    getEnv('sso')+'/login'
  );
  const handleImpersonate = async() => {
    // setTargetImpersonate(user)
    doSubmit({
      grant_type:'password',
      client_id:process.env.REACT_APP_CLIENT_ID,
      client_secret:process.env.REACT_APP_CLIENT_SECRET,
      username:targetImpersonate.username,
      password:'password',
      scope:'',
      impersonate: true
    },undefined,undefined).then((res:any)=>{
      toast.success('Impersonate Success, Please wait...')
      setAuthImpersonate(res.data)
      setShowImpersonate(false)
      setTimeout(()=>{
        window.location.reload()
      },1000)
    })
  }

  const tableColumns = [
    { Header: "ID", accessor: "id", width: 50, disableCreate: true, disableEdit: true},
    {Header: 'Username', accessor: 'username', width: 150},
    {Header: 'Name', accessor: 'name', width: 300},
    {Header: 'Email', accessor: 'email', width: 300},
    {Header: 'Password', accessor: 'password', width: 300, disableIndex: true},
    {Header: 'Roles', accessor: 'roles', width: 150,className:'text-center',type:'select2_multiple',
      options: {
        url: process.env.REACT_APP_API_URL+"/api/auth/role",
        key: 'id',
        label: 'name',
        return_only_id: true
      }
    },
    {Header: 'Company Code', accessor: 'company_code', width: 150,className:'text-center',type:'select2',
      options: {
        url: process.env.REACT_APP_API_URL+"/api/masterdata/companies",
        key: 'comp_code',
        label: 'name',
        return_only_id: true
      }
    },
    {Header: 'Consumer ID', accessor: 'consumer_id', width: 300, className:'text-center text-nowrap',disableCreate:true,disableEdit:true},
    {Header: 'Status', accessor: 'status', type: 'status', width: 100},
  ]
  return (
    <>
      <a href={"javascript:;"} onClick={(e:any)=>{
        e.preventDefault()
        setIsShow(!isShow)
      }}>
        <i className={"fa fa-user-astronaut fs-2 text-primary"}></i>
      </a>
      <Modal size={"xl"} show={isShow} onHide={() => setIsShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Impersonate User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TableXWrpper
            title={"User Management"}
            urlIndex={urlIndex}
            columns={tableColumns}
            disableUrlChanged={true}
            addRowButton={[
              {
                label: 'Impersonate',
                icon: 'fa fa-user-astronaut fs-2 text-success',
                onClick: (row:any)=>{
                  // console.log(row)
                  setIsShow(false)
                  setTargetImpersonate(row)
                  setShowImpersonate(true)
                }
              }
            ]}
          />
        </Modal.Body>
      </Modal>
      <Modal show={showImpersonate} onHide={()=>setShowImpersonate(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Impersonate</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className={"table table-bordered"}>
            <tbody>
            <tr>
              <td><strong>Username</strong></td>
              <td className={"text-end"}>{targetImpersonate?.username}</td>
            </tr>
            <tr>
              <td><strong>Name</strong></td>
              <td className={"text-end"}>{targetImpersonate?.name}</td>
            </tr>
            <tr>
              <td><strong>Company Code</strong></td>
              <td className={"text-end"}>{targetImpersonate?.company_code}</td>
            </tr>
            <tr>
              <td><strong>Role</strong></td>
              <td className={"text-end"}>
                {targetImpersonate?.roles?.map((role:any)=>role.name).join(', ')}
              </td>
            </tr>
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-danger btn-elevate" onClick={()=>setShowImpersonate(false)}>
            <i className={"fa fa-times"}></i>
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary btn-elevate"
            onClick={handleImpersonate}
            disabled={isLoading || targetImpersonate?.username === currentUser?.username}
          >
            {isLoading && <span className="spinner-border spinner-border-sm align-middle ms-2"></span>}{" "}
            {!isLoading && <i className="fa fa-user-astronaut text-white"></i>}{" "}
            Impersonate
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default HeaderImpersonateChooseUser;
