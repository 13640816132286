import axios from "axios";
import React, {useEffect, useRef, useState} from 'react'
import * as Yup from "yup";
import { useMutation, useQuery } from "react-query";
import { Form, Formik } from "formik";
import { Col, Row, Spinner } from "react-bootstrap";
import ReactQuill from "react-quill";
import { toast } from "react-toastify";
import {getEnv} from '../../../../../../helpers/procurex'
import {useIntl} from 'react-intl'
import {Radio} from 'rsuite'
import Swal from 'sweetalert2'
import { isAlreadyGiveFeedback } from "../AanwijzingVendorDetail";
import Ckeditor from '../../../../../components/shared/form/Ckeditor'

const fetchSummaryAanwijzing = (aanwijzingId: any) => {
  return axios.get(`${getEnv('tender')}/aanwijzing-summary/${aanwijzingId}`)
}

const fetchCreateSummaryAanwijzing = (payloadData) => {
  return axios.post(`${getEnv('tender')}/aanwijzing-summary`,payloadData)
}

const FormFeedback = ({aanwijzing,onRefresh}: any) => {
  const intl = useIntl()
  const [stateAggreement, setStateAggreement] = useState(null)
  const [isFirstRender, setIsFirstRender] = useState(false);
  const [currentAanwijzing,setCurrentAanwijzing] = useState(aanwijzing);

  //region give feedback
  const {mutate:mutateStoreFeedback} = useMutation({
    mutationKey: ["aanwijzing","summary-feedback"],
    mutationFn: ({uuid,payload}: any) => axios.post(getEnv("tender")+`/vendor/aanwijzing/approve-ba/${uuid}`,payload),
    onSuccess: (res:any) => {
      Swal.fire(
        intl.formatMessage({id:"Terima kasih telah memberikan feedback"}),
        "",
        "success"
      );
      onRefresh && onRefresh()
      setCurrentAanwijzing({
        ...currentAanwijzing,
        status_vendor_approve_ba: stateAggreement === 1 ? "APPROVE" : "REJECT"
      })
    },
    onError: (err:any) => {
      toast.error(err)
    }
  })

  const handleOnSubmit = () => {
    const payload = {
      uuid: aanwijzing?.uuid,
      payload: {
        response: stateAggreement === 1
      }
    }
    mutateStoreFeedback(payload)
  }
  //endregion

  useEffect(() => {
    setCurrentAanwijzing(aanwijzing)
  }, [aanwijzing])

  useEffect(() => {
    if(!currentAanwijzing) return ;
    if(currentAanwijzing?.status_vendor_approve_ba === "APPROVE"){
      setStateAggreement(1)
    } else if(currentAanwijzing?.status_vendor_approve_ba === "REJECT"){
      setStateAggreement(0)
    }

    if(!isAlreadyGiveFeedback(currentAanwijzing)) setIsFirstRender(true)

  }, [currentAanwijzing])

  useEffect(() => {
    // if(aanwijzing && !isAlreadyGiveFeedback(aanwijzing)) {
      if (isFirstRender) {
        const timer = setTimeout(() => {
          setIsFirstRender(false);
        }, 2000); // Adjust the duration as needed
        return () => clearTimeout(timer);
      }
    // }
  }, [isFirstRender]);

  return (
    <>
      <div className={`${!isAlreadyGiveFeedback(currentAanwijzing) ? 'border border-danger p-2 border-3 rounded ' : ''} ${isFirstRender ? 'blink_me' : ''}`}>
        <h5>
          {intl.formatMessage({id: 'Feedback Summary'})}
          {!isAlreadyGiveFeedback(currentAanwijzing) && (
            <i className={'fa fa-exclamation-circle text-danger ms-2 blink_me'}></i>
          )}

        </h5>
        <div className={'form-group mb-3'}>
        <label>{intl.formatMessage({id: 'Apakah Anda setuju dengan summary Aanwijzing ini?'})}</label>
          <div className={'d-flex flex-row gap-3'}>
            <Radio
              color="red"
              checked={stateAggreement === 0}
              onClick={() => {
                if(isAlreadyGiveFeedback(currentAanwijzing)) return ;
                setStateAggreement(0)
              }}
              disabled={isAlreadyGiveFeedback(currentAanwijzing)}
            >
              {intl.formatMessage({id: 'Tidak Setuju'})}
            </Radio>

            <Radio
              color="blue"
              checked={stateAggreement === 1}
              onClick={() => {
                if(isAlreadyGiveFeedback(currentAanwijzing)) return ;
                setStateAggreement(1)
              }}
              disabled={isAlreadyGiveFeedback(currentAanwijzing)}
            >
              {intl.formatMessage({id: 'Setuju'})}
            </Radio>
          </div>
        </div>
        <div className={'form-group'}>
          <button
            className={'btn btn-sm btn-primary'}
            type={'button'}
            disabled={stateAggreement === null || isAlreadyGiveFeedback(currentAanwijzing)}
            onClick={handleOnSubmit}
          >
            <i className={'fa fa-save me-2'}></i>
            {intl.formatMessage({id: 'Submit Feedback'})}
          </button>
        </div>
      </div>
    </>
  )
}

const SummaryTab = ({aanwijzing, onRefresh}: any) => {
  const intl = useIntl()

  const [initialValues, setInitialValues] = useState({
    summary: '',
  } as any)
  const validationSchema = Yup.object().shape({
    summary: Yup.string().required('Summary harus diisi'),
  })
  const formik = useRef({} as any)



  //region create summary
  const onSuccessCreateSummary = (data: any) => {
    toast.success('Successfull save summary')
  }
  const onErrorCreateSummary = (error: any) => {
  }
  const {
    mutate: mutateCreateSummary,
    isLoading: isLoadingCreateSummary,
  } = useMutation('create-summary-aanwijzing', (payloadData) => fetchCreateSummaryAanwijzing(payloadData), {
    onSuccess: onSuccessCreateSummary,
    onError: onErrorCreateSummary,
  })
  //endregion


  //region get summary
  const onSuccessGetSummary = (data: any) => {
    formik.current.setFieldValue('summary', data.data.data.summary)
  }
  const onErrorGetSummary = (error: any) => {
  }
  const {data, isLoading, refetch} = useQuery("get-summary-aanwijzing",
    ()=>fetchSummaryAanwijzing(aanwijzing?.summary?.uuid),{
      // enabled: aanwijzing?.summary?.uuid !== undefined,
      onSuccess: onSuccessGetSummary,
      onError: onErrorGetSummary
    }
  )
  //endregion

  const handleOnSubmit = async (values:any) => {
    // @ts-ignore
    mutateCreateSummary({
      aanwijzing_id: aanwijzing.uuid,
      summary: values.summary
    })
  }

  const isShowFeedback = () => {
    const allowedStatus = [
      'CLOSED',
      'DONE'
    ]
    return allowedStatus.includes(aanwijzing?.status)
  }



  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleOnSubmit}
        validationSchema={validationSchema}
        innerRef={formik}
        enableReinitialize
      >
        {({
            errors,
            touched,
            values,
            setFieldValue,
            isValid,
            setTouched,
            isSubmitting,
            dirty,
          }) => (
          <Form>
            <Row className={"mb-4"}>
              <Col>
                <div className='mb-10'>
                  <label
                    htmlFor='exampleFormControlInput1'
                    className='required form-label'
                  >
                    <span>Summary</span>
                  </label>
                  <Ckeditor
                    defaultValue={values?.summary}
                    value={values?.summary}
                    onChange={()=>{}}
                    disabled={true}
                  />
                </div>
              </Col>
            </Row>

          </Form>
        )}
      </Formik>

      {/*{isShowFeedback() && (*/}
      {/*  <>*/}
      {/*    <hr />*/}
      {/*    <FormFeedback*/}
      {/*      aanwijzing={aanwijzing}*/}
      {/*      onRefresh={onRefresh}*/}
      {/*    />*/}
      {/*  </>*/}
      {/*)}*/}
    </>
  )
}


export default SummaryTab;