import { useIntl } from "react-intl";
import { Button, Spinner } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useUploadMedia } from "../../../../../../hooks/MediaUploadHook";
import { getEnv, getSizeInBytes } from "../../../../../../helpers/procurex";
import PreviewAttachment from "../../../../../components/PreviewAttachment";
import { useAxiosGet } from "../../../../../../hooks/useAxios";
import { PageAlertX } from "../../../../../components/shared/PageAlertX";


const NoteAttachmentEvaluation = ({ proposalTender, quotation, onChange, fieldKey, onDeleted, disabled }: any) => {
  const intl = useIntl();
  const [note, setNote] = useState("");
  const [attachment, setAttachment] = useState([] as any);
  const [isLoading, setIsLoading] = useState(false);
  const refFile = useRef(null as any);
  const [previewFile, setPreviewFile] = useState(null as any);
  const [deletedAttachment, setDeletedAttachment] = useState([] as any);
  const { upload } = useUploadMedia();
  const [{ doSubmit, isLoading: isLoadingGet }] = useAxiosGet("");
  const [isDisabled, setIsDisabled] = useState(disabled);



  const handleChange = () => {
    const data = {
      note: note,
      attachments: attachment
    };
    onChange && onChange(fieldKey, data);
  };

  const fileOnChange = async (e: any) => {
    let file = e.target.files[0];
    if (file) {
      try {
        setIsLoading(true);
        let responseFile = await upload("1", file);
        console.log(responseFile);
        refFile.current.value = null;
        setAttachment([
          ...attachment,
          {
            media_type: {
              ...responseFile?.type,
              size: responseFile?.size_in_bytes,
              file_name: file?.name
            },
            media_url: responseFile?.url
          }
        ]);
        //     setDefaultPreviewUrl(responseFile?.url)
        //     responseFile.description = "-"
        //     afterSubmit && afterSubmit(responseFile)
        //
      } catch (error: any) {
        console.error(error);
        //     window.scrollTo(0, 0)
        toast.error(error.response?.data?.message || "Terjadi kesalahan", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light"
        });
        return false;
      } finally {
        setIsLoading(false);
      }
    }

  };

  const getDefaultNote = () => {
    doSubmit({}, getEnv("tender") + `/evaluation/notes/${quotation?.vendor?.uuid}`).then((res: any) => {
      if (res?.data?.length > 0) {
        if (fieldKey === "evaluation_administration") {
          const _note = res?.data?.find((item: any) => item?.type === "Evaluasi Administrasi");
          setNote(_note?.note);
        } else if (fieldKey === "evaluation_technical") {
          const _note = res?.data?.find((item: any) => item?.type === "Evaluasi Teknikal");
          setNote(_note?.note);
        } else if (fieldKey === "evaluation_commercial") {
          const _note = res?.data?.find((item: any) => item?.type === "Evaluasi Komersil");
          setNote(_note?.note);
        }
      }
    });
  };

  const getDefaultAttachment = () => {
    doSubmit({}, getEnv("tender") + `/evaluation/attachment/${quotation?.vendor?.uuid}`).then((res: any) => {
      if (res?.data?.length > 0) {
        let tmpAttachment: any = [];
        let _attachment: any = [];
        if (fieldKey === "evaluation_administration") {
          _attachment = res?.data?.filter((item: any) => item?.type === "Evaluasi Administrasi");
        } else if (fieldKey === "evaluation_technical") {
          _attachment = res?.data?.filter((item: any) => item?.type === "Evaluasi Teknikal");
        } else if (fieldKey === "evaluation_commercial") {
          _attachment = res?.data?.filter((item: any) => item?.type === "Evaluasi Komersil");
        }
        _attachment?.map((item: any) => {
          tmpAttachment.push({
            current_id: item?.uuid,
            media_type: item?.media_type,
            media_url: item?.media_url
          });
        });
        setAttachment(tmpAttachment);
      }
    });
  };

  const handleDeleteAttachment = (index: number) => {
    let _attachment = attachment;
    if(_attachment[index]?.current_id) {
      setDeletedAttachment([...deletedAttachment, _attachment[index]?.current_id]);
    }
    _attachment.splice(index, 1);
    setAttachment([..._attachment]);
  }


  useEffect(() => {
    setIsDisabled(disabled)
  }, [disabled]);

  useEffect(() => {
    getDefaultNote();
    getDefaultAttachment();
  }, []);

  useEffect(() => {
    handleChange();
  }, [note, attachment]);

  useEffect(() => {
    if(deletedAttachment.length > 0) {
      onChange && onChange(fieldKey+"_deleted", deletedAttachment);
      onDeleted && onDeleted(deletedAttachment);
    }
  }, [deletedAttachment]);

  return (
    <>
      <div className="row">
        <div className={"col-md-12"}>
        <div style={{ margin: "20px" }}></div>
          <div className="form-group">
            <label htmlFor="note">{intl.formatMessage({ id: "Catatan" })}</label>
            <textarea disabled={isDisabled || isLoading} className={"form-control"} value={note} onChange={(e: any) => setNote(e.target.value)}></textarea>
          </div>
          <div className={"form-group mt-3"}>
            <Button
              variant={"outline-primary"}
              size={"sm"}
              className={"border border-primary py-1 px-5"}
              onClick={() => {
                refFile?.current?.click();
              }}
              disabled={isLoading || isDisabled}
            >
              {isLoading && (
                <Spinner animation="border" role="status" size={"sm"} className={"me-2"} />
              )}
              {!isLoading && (
                <i className={"fa fa-paperclip"}></i>
              )}

              {intl.formatMessage({ id: "Pilih File" })}
            </Button>
            <input
              type="file"
              className={"d-none"}
              ref={refFile}
              onChange={fileOnChange}
            />

            {attachment.map((item: any, index: number) => {
              return (
                <div key={index}
                     className={"mt-2 mb-1 alert alert-info bg-light-info px-2 py-2 w-100 d-flex align-items-center justify-content-between"}
                     style={{
                       borderRadius: 0,
                       border: "none"
                     }}>
                  <div>
                    <a href={"#"} className={""} onClick={(e: any) => {
                      e.preventDefault();
                      setPreviewFile(item?.media_url);
                    }}>
                      <strong className={"text-info"}>{item?.media_type?.file_name}&nbsp;</strong>
                      <strong>({getSizeInBytes(item?.media_type?.size)})</strong>
                    </a>
                  </div>
                  <div>
                    <a href={"#"} className={(isLoading || isDisabled) ? 'text-gray' : "text-danger"} onClick={(e: any) => {
                      e.preventDefault();
                      if(!isLoading && !isDisabled) handleDeleteAttachment(index);
                    }}>
                      <i className={"fa fa-times me-2 text-danger"}></i>
                    </a>
                  </div>
                </div>
              );
            })}
            <PreviewAttachment previewExt={previewFile} onHide={() => setPreviewFile(null)} />
          </div>
        </div>
      </div>
    </>
  );
};
export default NoteAttachmentEvaluation;