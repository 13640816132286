import { Spinner } from 'react-bootstrap'
import {
  formatNumberToDecimalAuction,
  getEnv,
  multiplyNumber,
  parseNumberToCurrency,
  parseNumberToCurrencyAuction,
} from '../../../../../../helpers/procurex'
import { useEffect, useState } from 'react'
import { useAxiosGet, useAxiosPost } from '../../../../../../hooks/useAxios'
import { toast } from 'react-toastify'
import CurrencyInput from 'react-currency-input-field'
import { useParams } from 'react-router'

const RowServiceLineAuction = ({
  item,
  index,
  tabulasi,
  itemGroup,
  indexGroup,
  indexDetail,
  itemDetail,
  updateUnitPrice
}: any) => {
  const [unitPrice, setUnitPrice] = useState(item?.unit_price || 0)

  const subTotalPrice = () => {
    return multiplyNumber(item?.kuantitas, formatNumberToDecimalAuction(unitPrice, 3));
  }

  const totalPrice = () => {
    return itemGroup?.items
    .filter((e: any) => e.unit_price && e.kuantitas)
    .map((e: any) => multiplyNumber(e.kuantitas, formatNumberToDecimalAuction(e.unit_price, 3))) // Kalikan sub_total_change dengan quantity
    .reduce((a: any, b: any) => a + b, 0);
  }

  useEffect(() => {
    updateUnitPrice(item.id, unitPrice);
  }, [unitPrice])
  return (
    <>
      <tr className='border border-gray-600' key={'row-riwayat-auction' + indexDetail}>
        {indexGroup == 0 && index == 0 && (
          <>
            <td
              className={'border border-gray-600 text-center text-nowrap align-middle px-2'}
              rowSpan={itemDetail.total_item}
            >
              {indexDetail + 1}
            </td>
            <td
              className={'border border-gray-600 text-center align-middle px-2'}
              rowSpan={itemDetail.total_item}
            >
              {itemDetail.nama_vendor}
            </td>
          </>
        )}
        {index == 0 && (
          <>
            <td
              className={'border border-gray-600 text-center align-middle px-2'}
              rowSpan={itemGroup.items.length}
            >
              {itemGroup.nama_item}
            </td>
            <td
              className={'border border-gray-600 text-center text-nowrap align-middle px-2'}
              rowSpan={itemGroup.items.length}
            >
              {parseNumberToCurrencyAuction(itemGroup.unit_price, itemGroup.mata_uang)}
            </td>
            <td
              className={'border border-gray-600 text-center text-nowrap align-middle px-2'}
              rowSpan={itemGroup.items.length}
            >
              {itemGroup.mata_uang}
            </td>
          </>
        )}
        <td className={'border border-gray-600 text-start px-2'}>{item.satuan}</td>
        <td className={'border border-gray-600 text-end px-2'}>{item.kuantitas}</td>
        <td className={'border border-gray-600 text-start px-2'}>{item.description}</td>
        <td className={'border border-gray-600 text-start px-2'}>
          <CurrencyInput
            className={'form-control form-control-sm text-end '}
            groupSeparator='.'
            decimalSeparator=','
            decimalsLimit={3}
            value={unitPrice}
            disabled={tabulasi?.status != 'done'}
            onValueChange={(value: any) => {
              setUnitPrice(value)
            }}
            style={{}}
          />
        </td>
        <td className={'border border-gray-600 text-end px-2'}>
          {parseNumberToCurrencyAuction(subTotalPrice(), itemGroup.mata_uang)}
        </td>
        {index == 0 &&
          <td
            className={'border border-gray-600 text-center align-middle px-2'}
            rowSpan={itemGroup.items.length}
          >
            {parseNumberToCurrencyAuction(totalPrice(), itemGroup.mata_uang)}
          </td>
        }
      </tr>
    </>
  )
}

const FinalisasiAuctionTab = ({ tabulasi }: any) => {
  const { uuid } = useParams()
  const [serviceLines, setServiceLines] = useState<any>([])

  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosPost(
    getEnv('auction') + `/service-line/${uuid}`
  )

  const [{ doSubmit: getData }] = useAxiosGet(getEnv('auction') + `/service-line/${uuid}`)

  const getServiceLines = async () => {
    const newUrl = getEnv('auction') + `/service-line/${uuid}`
    getData(undefined, newUrl, undefined).then((res: any) => {
      setServiceLines(res.data)
    })
  }

  const updateUnitPrice = (id, unitPrice) => {
    const updatedData = serviceLines.map((vendor) => {
      return {
        ...vendor,
        details: vendor.details.map((detail) => {
          return {
            ...detail,
            items: detail.items.map((item) => {
              if (item.id === id) {
                return {
                  ...item,
                  unit_price: unitPrice,
                };
              }
              return item;
            }),
          };
        }),
      };
    });
    setServiceLines(updatedData);
  }

  const handleOnSaveFinalisasi = async () => {
    const items: any[] = []
    serviceLines.map((groupDetail: any) => {
      groupDetail.details.map((groupItem: any) => {
        groupItem.items.map((item: any) => {
          items.push({
            id: item.id,
            unit_price: formatNumberToDecimalAuction(item.unit_price, 3),
          })
        })
      })
    })
    const payload = {
      items: items,
    }
    doSubmit(payload, undefined).then((res: any) => {
      toast.success('Success Save Finalisasi')
    })
  }

  useEffect(() => {
    getServiceLines()
  }, [tabulasi])

  return (
    <>
      <div className={'row'}>
        <div className={'col-md-12'}>
          <div className='table-responsive'>
            <table className={'table'}>
              <thead className='bg-gray-200 fw-bold'>
                <tr>
                  <th className={'border border-gray-600 text-center align-middle px-2'} style={{ width: '50px' }}>
                    No
                  </th>
                  <th className={'border border-gray-600 text-center align-middle px-2'} style={{ minWidth: "100px" }}>Vendor</th>
                  <th className={'border border-gray-600 text-center align-middle px-2'} style={{ minWidth: "200px" }}>Item</th>
                  <th className={'border border-gray-600 text-center align-middle px-2'}>Harga Unit Price</th>
                  <th className={'border border-gray-600 text-center align-middle px-2'} style={{ minWidth: "100px" }}>Mata Uang</th>
                  <th className={'border border-gray-600 text-center align-middle px-2'} style={{ minWidth: "100px" }}>Satuan</th>
                  <th className={'border border-gray-600 text-center align-middle px-2'} style={{ minWidth: "100px" }}>Qty</th>
                  <th className={'border border-gray-600 text-center align-middle px-2'} style={{ minWidth: "100px" }}>Deskripsi</th>
                  <th className={'border border-gray-600 text-center align-middle px-2'} style={{ minWidth: "200px" }}>Harga</th>
                  <th className={'border border-gray-600 text-center align-middle px-2'} style={{ minWidth: "200px" }}>Sub Total</th>
                  <th className={'border border-gray-600 text-center align-middle px-2'} style={{ minWidth: "200px" }}>Total</th>
                </tr>
              </thead>
              <tbody>
                {serviceLines?.map((itemDetail: any, indexDetail: number) => {
                  return itemDetail?.details.map((itemGroup: any, indexGroup: number) => {
                    return itemGroup?.items.map((item: any, index: number) => {
                      return (
                        <RowServiceLineAuction
                          key={'row-service-line-auction-' + index}
                          indexDetail={indexDetail}
                          itemDetail={itemDetail}
                          indexGroup={indexGroup}
                          itemGroup={itemGroup}
                          item={item}
                          index={index}
                          tabulasi={tabulasi}
                          updateUnitPrice={updateUnitPrice}
                        />
                      )
                    })
                  })
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className={'row'}>
        <div className={'col-md-12 text-end'}>
          <button
            type='button'
            onClick={handleOnSaveFinalisasi}
            className={'btn btn-primary btn-sm'}
            disabled={isLoading || tabulasi?.status != 'done'}
          >
            {isLoading && <Spinner animation={'border'} size={'sm'} className={'me-2'} />}
            {!isLoading && <i className={'fa fa-save me-2'}></i>}
            Simpan
          </button>
        </div>
      </div>
    </>
  )
}

export { FinalisasiAuctionTab }
