import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {NavLink} from 'react-router-dom'
import {BadgeUserContractManagementList} from '../po/list/List'
import {ApiUrl} from '../../../config/Api'
import TableXWrapper from '../../../components/shared/TableXWrapper'

const MonitoringPOPage = () => {
  const tableColumns = [
    {
      Header: 'Aksi ',
      accessor: 'created_by.id',
      width: 150,
      Cell: (props: any) =>
        <div className='d-flex w-100 justify-content-center'>
          <OverlayTrigger
            placement='right'
            overlay={<Tooltip id='button-tooltip-2'>Detail PO</Tooltip>}
          >
            <NavLink to={'/contract-management/po/' + props?.cell?.row?.original?.uuid+"?back="+window.location.pathname} className='p-3'>
              <i className={'fa fs-3 fa-pencil text-dark'} />
            </NavLink>
          </OverlayTrigger>
        </div>
    },
    {
      Header: 'Status PO',
      accessor: 'status',
      width: 200,
      Cell: (props: any) => {
        const original = props?.cell?.row?.original
        const badgeColor = 'badge badge-primary fw-bolder'

        return (
          <>
            <div className='d-flex gap-2'>
              <span className={`${badgeColor}`}>{original.status}</span>
            </div>
          </>
        )
      },
    },
    {Header: 'No PO ProcureX', accessor: 'no', width: 170},
    {Header: 'No PO SAP', accessor: 'sap_no', width: 150},
    {Header: 'No tender', accessor: 'proposal_tender_no', width: 180},
    {Header: 'Judul Tender', accessor: 'title', width: 150},
    {
      Header: 'Vendor',
      accessor: 'vendor_no_name',
      width: 200
    },
    {
      Header: 'Purch. Org',
      accessor: 'purchasing_organization',
      width: 200,
      Cell: (props: any) => (
        ''
        // props?.cell?.row?.original?.purchasing_organization?.comp_code + ' - ' + props?.cell?.row?.original?.purchasing_organization?.name
      )
    },
    {
      Header: 'Purch. Grp',
      accessor: 'purchasing_group_id_name',
      width: 200
    },
    {
      Header: 'Tanggal PO',
      accessor: 'po_date_format',
      width: 200
    },
    {
      Header: 'Tanggal Release PO',
      accessor: 'release_date_format',
      width: 180
    },
    {
      Header: 'PR Number',
      accessor: 'pr_no',
      className: "text-center",
      width: 150
    },
    {
      Header: 'Buyer',
      accessor: 'created_by_username_name',
      width: 300
    },
    {Header: 'Current User', accessor: 'current_user', width: 300, className: 'text-center', Cell: (props: any) => {
        return <BadgeUserContractManagementList data={props.cell.row.original} />
      }},

  ]
  return (
    <div>
      <div className={"card"}>
        <div className={"card-body px-0"}>
          <TableXWrapper
            readonly
            urlIndex={`${ApiUrl}/api/tender/po?source=monitoring`}
            columns={tableColumns}
          />
        </div>
      </div>
    </div>
  )
}
export default MonitoringPOPage;