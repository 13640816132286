import { useIntl } from "react-intl";
import { useAxiosPost } from "../../../../../../hooks/useAxios";
import { getEnv } from "../../../../../../helpers/procurex";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from 'react-toastify'

interface Props {
    key: any
    setKey: Function
    detailPo: any
    showModal: boolean
    onHide: Function
}

const ModalNotes = ( {key, setKey, detailPo, showModal, onHide}: Props ) => {
    const intl = useIntl()
    const  [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosPost(
      getEnv("tender") + "/po/notes"
    )
    const [notes, setNotes] = useState(detailPo?.notes)
  
    const handleSave = async() => {
      const payload = {
        _method: "PUT",
        notes: notes
      }
      doSubmit(payload, getEnv("tender") + `/po/notes/${detailPo.uuid}`, toast)
        .then((res: any) => {
            // onHide()
            // setKey(key + 1)
            window.location.reload()
        })
    }
  
    return (
      <>
        <Modal
            key={key}
            show={showModal}
            onHide={() => onHide(false)}
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                {intl.formatMessage({id:"Catatan"})}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group">
                    <table className="table table-borderless">
                    <tr className={"mb-3"}>
                        <th><strong>No</strong></th>
                        <td className={"text-end"}>
                        {detailPo?.no}
                        </td>
                    </tr>
                    <tr className={"pt-3"}>
                        <th><strong>No PR</strong></th>
                        <td className={"text-end"}>
                        {detailPo?.pr_no}
                        </td>
                    </tr>
                    <tr className={"pt-3"}>
                        <th><strong>Judul</strong></th>
                        <td className={"text-end"}>
                        {detailPo?.title}
                        </td>
                    </tr>
                    <tr className={"pt-3"}>
                        <th><strong>Vendor</strong></th>
                        <td className={"text-end"}>
                        {detailPo?.vendor_no_name}
                        </td>
                    </tr>
                    </table>
                </div>
                <hr />
                <div className="form-group">
                    <label className="form-label">{intl.formatMessage({ id: "Catatan" })}</label>
                    <textarea
                    className="form-control"
                    rows={3}
                    onChange={(e: any) => {
                        setNotes(e.target.value);
                    }}
                    >{detailPo?.notes}</textarea>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-light btn-sm" onClick={() => onHide(false)} disabled={isLoading}>
                    <i className="fa fa-times"></i>
                    {intl.formatMessage({ id: "Tutup" })}
                </button>
                <button type="button" className="btn btn-sm btn-primary" onClick={handleSave} disabled={isLoading}>
                    {isLoading && <i className="fa fa-spinner fa-spin me-2"></i>}
                    {!isLoading && <i className="fa fa-save me-2"></i>}
                    {intl.formatMessage({ id: "Simpan" })}
                </button>
            </Modal.Footer>
        </Modal>
      </>
    )
}

export default ModalNotes