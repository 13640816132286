import React, { useEffect, useState } from "react";
import { Nav, Placeholder } from "rsuite";
import { useAxiosGet, useAxiosPost } from "../../../../../../../hooks/useAxios";
import { getEnv } from "../../../../../../../helpers/procurex";
import { Spinner } from "react-bootstrap";
import BottomToolbarWrapper from "../../../../../../components/shared/BottomToolbarWrapper";
import { useNavigate } from "react-router-dom";
import { ApprovalX } from "../../../../../../components/shared/ApprovalX";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import axios from "axios";
import { BeritaAcaraPengumumanPemenangTab } from "./BeritaAcaraPengumumanPemenangTab";
import FlagRejected from "../FlagRejected";


const PengumumanPemenangPage = ({ proposalTender, onRefresh }: any) => {
  const [currentProposalTender,setCurrentProposalTender] = useState(proposalTender);
  const [{ doSubmit, isLoading }] = useAxiosPost(getEnv("tender") + "/tender/submit");
  const [{ doSubmit: doSubmitGet, isLoading: isLoadingGet}] = useAxiosGet("")
  const navigate = useNavigate()
  const [isShowApprovalSchemaPengumumanPemenang, setIsShowApprovalSchemaPengumumanPemenang] = useState(false);
  const [approvalSchemaPengumumanPemenang, setApprovalSchemaPengumumanPemenang] = React.useState([]);

  useEffect(() => {
    if (proposalTender) {
      setCurrentProposalTender(proposalTender)
    }
  }, [proposalTender]);

  const showSidebar = () => {
    // hide sidebar
    const elementToClick = document.getElementById('kt_aside_toggle');

    if (elementToClick && elementToClick.classList.contains('active')) {
      // Memicu klik pada elemen
      elementToClick.click();
    }
  }
  const onGoBack = () => {
    showSidebar();
    const path = localStorage.getItem("backTo");
    // alert(path)
    navigate(path ? path : "/tender/admin/proposal-tender/draft");
    // navigate('/tender/admin/proposal-tender')
  };

  const onShowModalApproval = async() => {
    const newUrl = getEnv("tender") + `/awarding-announcement/routing-approval-code/${proposalTender?.uuid}`;
    doSubmitGet({},newUrl, undefined).then((res: any) => {
      setApprovalSchemaPengumumanPemenang(res.data?.details);
      setIsShowApprovalSchemaPengumumanPemenang(true);
    }).finally(() => {
    });
  }

  const getProposalTender = () => {
    const newUrl = getEnv("tender") + `/tender/${proposalTender?.uuid}`
    doSubmitGet({}, newUrl, undefined, () => {}).then((res: any) => {
      const _proposalTender = res.data;
      setCurrentProposalTender(_proposalTender)
    })
  }

  const handleOnSubmitApprovalPengumumanPemenang = async (values:any)=>  {
    const payload = {
      approvers: values.approval.map((item: any) => {
        return {
          step: item.step,
          jabatan: item.jabatan,
          type: "APPROVAL",
          user_id: item.extra_info.user.user_id,
          extra_info: item.extra_info
        };
      }),
      masa_sanggah: values.masa_sanggah,
    };

    const newUrl = getEnv("tender") + `/awarding-announcement/submit/${proposalTender?.uuid}`;
    doSubmit(payload, newUrl, toast, () => {
    }).then((data: any) => {
      setIsShowApprovalSchemaPengumumanPemenang(false);
    }).catch(() => {
    }).finally(() => {
      getProposalTender()
      onRefresh && onRefresh();
    })
  }

  const handleOnSubmitMasaSanggah = async () => {
    Swal.fire({
      title: "Apakah anda yakin?",
      html: `Anda akan menutup waktu Masa Sanggah`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Ya, tutup`,
      cancelButtonText: "Tidak",
      confirmButtonColor: "#3085d6"
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(getEnv("tender") + `/awarding-announcement/submit/${proposalTender?.uuid}`)
          .then((res) => {
            toast.success(res.data.meta.message);
            onRefresh && onRefresh();
          }).catch((err) => {
          Swal.fire(
            err.response.data.message || err.response.data.meta.message,
            "",
            "error"
          );
        }).finally(() => {
        });
      }
    });
  }

  const isReLUPEnabled = () => {
    const allowedStatus = [
      'Draft',
      'Rejected',
    ]
    return allowedStatus.includes(currentProposalTender?.status_detail) && currentProposalTender?.status === "Pengumuman Pemenang";
  }

  const [{ doSubmit: doSubmitReLUP, isLoading: isLoadingReLUP }] = useAxiosPost(getEnv("tender") + `/awarding-announcement/rollback/${proposalTender?.uuid}`);

  const onSubmitReLUP = () => {
    Swal.fire({
      title: "Konfirmasi",
      text: "Apakah anda yakin ingin kembali ke proses LUP?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, Lanjutkan ",
      cancelButtonText: "Tidak"
    }).then(async (result) => {
      if (result.isConfirmed) {
        doSubmitReLUP({},undefined,toast).then((res:any)=> {
          window.location.reload();
        })
      }
    })
  };

  return (
    <>
    {isShowApprovalSchemaPengumumanPemenang && (
      <ApprovalX
        title={"Pengumuman Pemenang"}
        show={isShowApprovalSchemaPengumumanPemenang}
        schema={approvalSchemaPengumumanPemenang}
        onHide={() => {
          setIsShowApprovalSchemaPengumumanPemenang(false);
        }}
        onSubmit={(data: any) => {
          handleOnSubmitApprovalPengumumanPemenang(data);
        }}
        isLoading={isLoading}
        source={"TENDER"}
        object_id={proposalTender?.id}
        company_id={proposalTender?.company?.id}
        code={proposalTender?.routing_approval_config_code}
        AdditionalForm={(arg:any) => {
          return (
            <>
              <div className={"form-group mb-3"}>
                <label className="form-label">
                  Masa Sanggah (hari)
                </label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e:any)=>arg.onChange(e.target.value,"masa_sanggah")}
                />
              </div>
              <hr />
            </>
          )
        }
        }
        readOnly={proposalTender?.is_on_approval}
      />
    )}

    <div>
      <div className={"d-flex justify-content-between"}>
        <div><h3>Awarding</h3></div>
      </div>
      <hr />
      {proposalTender && (
        <FlagRejected proposalTender={proposalTender} type={"Pengumuman Pemenang"} />
      )}
      <BeritaAcaraPengumumanPemenangTab
        proposalTender={proposalTender}
        onRefresh={onRefresh}
      />
      <BottomToolbarWrapper onGoBack={onGoBack} children={
        <>
          {isReLUPEnabled() &&
            <button
              type={'button'}
              disabled={isLoading}
              className={'btn btn-lg btn-danger me-3'}
              onClick={() => onSubmitReLUP()}
            >
              {isLoading ? (
                <i className="fa fa-spinner fa-spin"></i>
              ) : (
                <i className={'fa fa-undo'}></i>
              )}
              Kembali Ke LUP
            </button>
          }
          {(currentProposalTender?.status === "Pengumuman Pemenang"
          && (currentProposalTender?.status_detail === "Draft" || currentProposalTender?.status_detail === "Rejected")) &&
            <button
              className={"btn btn-primary"}
              type={"button"}
              onClick={onShowModalApproval}
            >
              {isLoading && <Spinner className={"spinner-grow-sm"} animation="border" size="sm" />}
              {!isLoading && <i className={"fa fa-paper-plane me-2"}></i>}
              Submit Pengumuman Pemenang
            </button>
          }
          {(currentProposalTender?.status === "Pengumuman Pemenang" && currentProposalTender?.status_detail === "Masa Sanggah") &&
            <button
              className={"btn btn-primary"}
              type={"button"}
              onClick={handleOnSubmitMasaSanggah}
            >
              {isLoading && <Spinner className={"spinner-grow-sm"} animation="border" size="sm" />}
              {!isLoading && <i className={"fa fa-paper-plane me-2"}></i>}
              Tutup Masa Sanggah
            </button>
          }
        </>
      }
      />
      {/* Pengumuman Pemenang*/}
      {/*<ApprovalX*/}
      {/*  show={isShowApprovalSchemaPengumumanPemenang}*/}
      {/*  schema={approvalSchemaPengumumanPemenang}*/}
      {/*  onHide={() => {*/}
      {/*    setIsShowApprovalSchemaPengumumanPemenang(false);*/}
      {/*  }}*/}
      {/*  onSubmit={handleOnSubmitApprovalPengumumanPemenang}*/}
      {/*  isLoading={isLoading}*/}
      {/*  source={"TENDER"}*/}
      {/*  object_id={proposalTender?.id}*/}
      {/*  company_id={proposalTender?.company?.id}*/}
      {/*  code={proposalTender?.routing_approval_config_code}*/}
      {/*  readOnly={isLoading}*/}
      {/*  AdditionalForm={(arg:any) => {*/}
      {/*    return (*/}
      {/*      <>*/}
      {/*        <div className={"form-group mb-3"}>*/}
      {/*          <label className="form-label">*/}
      {/*            Masa Sanggah (hari)*/}
      {/*          </label>*/}
      {/*          <input*/}
      {/*            type="text"*/}
      {/*            className="form-control"*/}
      {/*            onChange={(e:any)=>arg.onChange(e.target.value,"masa_sanggah")}*/}
      {/*            defaultValue={arg?.values?.masa_sanggah || 2}*/}
      {/*          />*/}
      {/*        </div>*/}
      {/*        <hr />*/}
      {/*      </>*/}
      {/*    )*/}
      {/*  }*/}
      {/*  }*/}
      {/*  // readOnly={props.proposalTender?.is_on_approval}*/}
      {/*/>*/}
    </div>
    </>
  );
};

export default PengumumanPemenangPage;