import { Navigate, Route, Routes } from "react-router-dom";
import { createContext, FC, lazy, Suspense, useState } from "react";
import { WithChildren } from "../../_metronic/helpers";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import TopBarProgress from "react-topbar-progress-indicator";
import AuctionDetailForm from "../modules/auction-v2/admin/auction/AuctionDetailForm";
import { useAuth } from "../modules/auth";
import AuctionVendorPage from "../modules/auction-v2/vendor/AuctionVendorPage";
import moment from "moment";
import AuctionVendorDetailForm from "../modules/auction-v2/vendor/AuctionVendorDetailForm";

interface AuctionContextNode {
  itemPesertaState: boolean;
  setItemPesertaState: any;
  auctionDetail: any;
  setAuctionDetail: any;
}

export const AuctionContext = createContext({} as AuctionContextNode)

const AuctionProvider: FC<any> = ({children}) => {
  const [itemPesertaState, setItemPesertaState] = useState<boolean>(false);
  const [auctionDetail, setAuctionDetail]:any = useState({
    nama: '',
    status: '',
    nomor: '',
    type: '',
    metode_evaluasi: '',
    metode_peringkat: '',
    visibilitas_peringkat: '',
    visibilitas_harga: '',
    jenis_penerimaan: '',
    opsi_peringkat: '',
    perusahaan: '',
    batas_awal: '',
    batas_akhir: '',
    catatan: '',
    petunjuk: '',
    isReadyPublish: false,
    startDate: moment(),
    endDate: moment(),
  });
  return (
    <AuctionContext.Provider value={{
      itemPesertaState, setItemPesertaState, auctionDetail, setAuctionDetail
    }}>
      {children}
    </AuctionContext.Provider>
  )
}

const AuctionRoutes = () => {
  const AuctionPage = lazy(() => import('../modules/auction-v2/admin/auction/AuctionPage'))
  const MVBTemplatePage = lazy(() => import('../modules/auction-v2/admin/mvb-template/MVBTemplatePage'))
  const { currentUser, logout } = useAuth()
  const isVendor = () => {
    return currentUser?.vendor;
  }
  return (
    <AuctionProvider>
        <Routes>
          <Route index element={<Navigate to='/auction-v2/auction' />} />
          {/* Menu Buyer */}
          {!isVendor() && (
            <>
              <Route path='auction/*' element={<SuspensedView><AuctionPage /></SuspensedView>} />
              <Route path='mvb-template/*' element={<SuspensedView><MVBTemplatePage /></SuspensedView>} />
              <Route path='auction/:uuid' element={<SuspensedView><AuctionDetailForm /></SuspensedView>} />
            </>
          )}
          {/* Menu Vendor */}
          {isVendor() && (
            <>
              <Route path='auction/*' element={<SuspensedView><AuctionVendorPage /></SuspensedView>} />
              <Route path='auction/:uuid' element={<SuspensedView><AuctionVendorDetailForm /></SuspensedView>} />
            </>
          )}
        </Routes>
    </AuctionProvider>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export default AuctionRoutes
