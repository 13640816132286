/**
 * Wrapper untuk TableX, file ini digunakan untuk request data api, create, edit via modal
 */
import React, {FC, useEffect, useState} from 'react'
import TableX, {IColumns} from './TableX'
import SwalX from './SwalX'
import {useCreate, useDelete, useGetTableList, useUpdate} from './core/hook'
import {Button, Modal} from 'react-bootstrap'
import FormX from './form/FormX'
import {useIntl} from 'react-intl'
import LoadingX from './LoadingX'
import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {formatCurrency} from '../../../_metronic/helpers'

type IProps = {
  data?: any
  title?: string
  urlIndex: string
  urlCreate?: string
  urlUpdate?: string
  columns: Array<IColumns>
  readonly?: boolean
  defaultFilter?: string
  onSelectedRows?: any
  isForceCreateButton?: boolean //force create button, jika true maka tombol create button akan ditampilkan apapun permissionnya
  urlPageCreate?: string //force URL page create, jika diisi, maka akan redirect kehalaman create baru
  onCreateClick?: Function
  onAfterLoadData?: Function
  disableSearchInputAutoFocus?: boolean
  autoRefreshInSeconds?: number
  defaultPerPage?: number
  disableUrlChanged?: boolean
  hideSearch?: boolean
  hideShowPagination?: boolean
  hideExportAs?: boolean
  onRowDoubleClick?: Function
  addRowButton?: any
  tableButton?: any
  headerButtons?: any
  subHeaderButtons?: any
  showMigrationVendor?: boolean
  disablePaddingCard?: boolean
  hideSelection?: boolean
  errorMessageConditionDisabled?: string
  maxHeight?: string,
  hasAnySearch?: boolean

  //checkbox sidebar condition
  checkboxConditionDisabled?: Function
}

const TableXWrapper: FC<IProps> = (props) => {
  const {
    urlIndex,
    columns,
    title,
    readonly,
    defaultFilter,
    onSelectedRows,
    isForceCreateButton,
    urlPageCreate,
    addRowButton,
    data,
    onAfterLoadData,
    maxHeight
  } = props
  const disableSearchInputAutoFocus = props.disableSearchInputAutoFocus ?? false
  const intl = useIntl()
  const [isLoading, responseData, responseError, getData] = useGetTableList(
    props.urlIndex,
    defaultFilter
  )
  const [isLoadingCreate, responseDataCreate, responseErrorCreate, submitCreate] = useCreate(
    props.urlCreate ?? props.urlIndex
  )
  const [isLoadingUpdate, responseDataUpdate, responseErrorUpdate, submitUpdate] = useUpdate(
    props.urlUpdate ?? props.urlIndex
  )
  const [isLoadingDelete, responseDataDelete, responseErrorDelete, submitDelete] = useDelete(
    props.urlIndex
  )
  const [showCreateButton, setShowCreateButton] = useState(!readonly)
  const [isModalCreateOpen, setIsModalCreateOpen] = useState(false)
  const [isModalEditOpen, setIsModalEditOpen] = useState(false)
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false)
  const [formCreateFields, setFormCreateFields] = useState([])
  const [formEditFields, setFormEditFields] = useState([])
  const [tableColumns, setTableColumns] = useState([])
  const [selectedRow, setSelectedRow] = useState({})
  const [formKey, setFormKey] = useState(1)
  const [columnSort, setColumnSort] = useState('')
  const [conlumnSortDesc, setColumnSortDesc] = useState(false)

  useEffect(() => {}, [])

  useEffect(() => {
    generateFormCreate()
    generateTableColumns()
  }, [columns])

  useEffect(() => {
    setFormKey(formKey + 1)
  }, [isModalCreateOpen, isModalEditOpen])

  useEffect(() => {
    onAfterLoadData && onAfterLoadData(responseData)
  },[responseData])

  useEffect(() => {
    if (responseDataCreate && responseDataCreate.meta) {
      toast.success(responseDataCreate?.meta?.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
      getData()
      setIsModalCreateOpen(false)
      generateFormCreate()
    }
  }, [responseDataCreate])

  useEffect(() => {
    try {
      if (responseErrorCreate && responseErrorCreate.message && responseErrorCreate.response) {
        if (responseErrorCreate.response.status === 422) {
          toast.error(intl.formatMessage({id: 'FORM.VALIDATION_ERROR'}), {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          })
        } else {
          toast.error(responseErrorCreate.response.status + ': ' + responseErrorCreate.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          })
        }
      } else {
        toast.error(responseErrorCreate, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      }
    } catch (e) {
      toast.error('Unknown Error: ' + e, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
    }
  }, [responseErrorCreate])

  useEffect(() => {
    if (responseDataUpdate && responseDataUpdate.meta) {
      toast.success(responseDataUpdate?.meta?.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
      getData()
      setIsModalEditOpen(false)
      setSelectedRow({})
    }
  }, [responseDataUpdate])

  useEffect(() => {
    if (responseDataDelete && responseDataDelete.meta) {
      toast.success(responseDataDelete?.meta?.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
      getData()
      setIsConfirmDeleteOpen(false)
      setSelectedRow({})
    }
  }, [responseDataDelete])

  //auto refresh in seconds from props
  useEffect(() => {
    if (props.autoRefreshInSeconds) {
      getData(props.defaultPerPage)
      const interval = setInterval(() => {
        getData(props.defaultPerPage)
      }, props.autoRefreshInSeconds * 1000)
      return () => clearInterval(interval)
    } else {
      getData(props.defaultPerPage)
    }
  }, [])

  const generateFormCreate = () => {
    // @ts-ignore
    let tmp = []
    columns.map((v: IColumns, index: number) => {
      if (v.disableCreate === false || v.disableCreate === undefined) {
        tmp.push({
          focus: index === 1,
          // @ts-ignore
          Header: v.Header,
          // @ts-ignore
          accessor: v.accessor_form || v.accessor,
          type: v.type,
          options: v.options,
        })
      }
    })
    // @ts-ignore
    setFormCreateFields(tmp)
  }

  const generateFormEdit = (row: any) => {
    setSelectedRow(row)
    // @ts-ignore
    let tmp = []
    columns.map((v: IColumns, index: number) => {
      if (v.disableEdit === false || v.disableEdit === undefined) {
        tmp.push({
          focus: index === 1,
          // @ts-ignore
          Header: v.Header,
          // @ts-ignore
          accessor: v.accessor_form || v.accessor,
          // @ts-ignore
          value: row[v.accessor_form || v.accessor],
          // value: row.routes,
          type: v.type,
          options: v.options,
          row: row,
        })
      }
    })
    // @ts-ignore
    // console.log(tmp)
    // @ts-ignore
    setFormEditFields(tmp)
    setFormKey(formKey + 1)
  }

  /**
   * Conditional column sesuai dengan typenya sebelum di generate
   */
  const generateTableColumns = () => {
    let tmp = columns.filter((v: any) => v.disableIndex !== true)
    if (!readonly) {
      tmp.unshift({
        Header: 'Action',
        fixed: 'left',
        accessor: 'action',
        width: 150,
        disableEdit: true,
        disableCreate: true,
        disableSortBy: true,
        searchable: false,
        Cell: (props: any) => {
          const action = props?.cell?.row?.original?.action || props?.cell?.row?.original?.actions
          return (
            <div className='w-100 text-center'>
              {props.cell.row.values && action && action?.edit && (
                <span
                  className='p-3 cursor-pointer'
                  onClick={() => handleEditButton(props.cell.row.original)}
                >
                  <i className={'fa fs-3 fa-pencil'} />
                </span>
              )}
              {props.cell.row.values && action && action?.delete && (
                <span
                  className='cursor-pointer p-3'
                  onClick={() => handleDeleteButton(props.cell.row.original)}
                >
                  <i className={'fa fs-3 fa-trash'} />
                </span>
              )}
              {addRowButton && addRowButton.length > 0 && (
                <>
                  {addRowButton.map((v: any, index: number) => {
                    return (
                      <span
                        className='cursor-pointer p-3'
                        onClick={() => v?.onClick(props.row.original)}
                        key={'add-row-button-' + index}
                      >
                        <i
                          className={
                            v.icon +
                            (v?.spinner ? (v.spinner(props.cell.row.values) ? ' fa-spin' : '') : '')
                          }
                        />
                      </span>
                    )
                  })}
                </>
              )}
            </div>
          )
        },

      })
    }

    tmp.map((v) => {
      if (v.type === 'boolean' || v.type === 'status') {
        v.Cell = (props: any) => {
          const value = props.cell.row
          return (
            <>
              <div className={'text-center'}>
                {(!props.cell.row.values || !props?.cell?.row?.values?.is_svp) ? (
                  <i className={'fa fa-times-circle fa-2x text-danger'}></i>
                ) : (props?.cell?.row?.values
                  || props?.cell?.row?.values === 'ACTIVE'
                  || props?.cell?.row?.values?.is_svp) && (
                    <i className={'fa fa-check-circle fa-2x text-success'}></i>
                )}
              </div>
            </>
          )
        }
      } else if (v.type === 'number') {
        v.Cell = (props: any) => {
          const value = props.cell.row
          return (
            <div className={'text-end'}>{formatCurrency(props.cell.row.values[v.accessor])}</div>
          )
        }
      } else if (v.type === 'select2_multiple') {
        v.Cell = (props: any) => {
          const value = props.cell.row
          return (
            <div className={'text-center'}>
              {props.cell.row.values[v.accessor].length > 0 &&
                props.cell.row.values[v.accessor].map((x: any, y: any) => {
                  return (
                    <div className={'badge bg-primary'} key={'row-multiple-' + v.accessor + y}>
                      {v?.options?.label && x[v?.options?.label]}
                    </div>
                  )
                })}
            </div>
          )
        }
      } else if (v.type === 'file') {
        v.Cell = (props: any) => {
          return (
            <div className={'text-center'}>
              <a href={'javascript:;'} className='badge badge-primary'>
                <i className={'fa fa-download me-2'}></i> Download
              </a>
            </div>
          )
        }
      }

      if (v.accessor === 'id') {
        v.Cell = (props: any) => {
          const value = props.cell.row
          return <div className={'text-center'}>{props.cell.row.values[v.accessor]}</div>
        }
      }
    })

    // @ts-ignore
    setTableColumns(tmp)
  }

  const onChangeFormCreate = (value: any) => {
    setFormCreateFields(value)
  }

  const onChangeFormUpdate = (value: any) => {
    setFormEditFields(value)
  }
  const handleCreateButton = () => {
    if (urlPageCreate) {
      window.location.href = urlPageCreate
    } else {
      if (props.onCreateClick) {
        props.onCreateClick()
      } else {
        setIsModalCreateOpen(true)
      }
    }
  }
  const handleSubmitCreate = () => {
    submitCreate(generateFormDataBeforeSubmit(formCreateFields))
  }

  const handleSubmitUpdate = () => {
    // @ts-ignore
    submitUpdate(selectedRow?.uuid || selectedRow?.id, generateFormDataBeforeSubmit(formEditFields))
  }

  const generateFormDataBeforeSubmit = (schema: any) => {
    let formData = {}
    schema.map((v: any) => {
      if (v.type === 'status') {
        // @ts-ignore
        formData[v.accessor] = v.value ? 'ACTIVE' : 'INACTIVE'
      } else {
        // @ts-ignore
        formData[v.accessor] = v.value
      }
    })
    return formData
  }

  const handleEditButton = (row: any) => {
    generateFormEdit(row)
    setIsModalEditOpen(true)
  }

  const handleDeleteButton = (row: any) => {
    setSelectedRow(row)
    setIsConfirmDeleteOpen(true)
  }

  const handleSubmitDelete = () => {
    // @ts-ignore
    submitDelete(selectedRow?.id)
  }

  return (
    <>
      {/*{JSON.stringify(responseData.data.meta.per_page)}*/}
      <TableX
        data={responseData.data ? responseData.data.data ?? responseData.data : []}
        meta={{
          perPage: responseData?.meta?.per_page || 25,
          currentPage: responseData?.meta?.current_page,
          total: responseData?.meta?.total,
        }}
        columns={tableColumns}
        isLoading={isLoading}
        onRefresh={(state: any, perPage: number, currentPage: number, serialize: string) => {
          getData(props?.defaultPerPage ?? perPage, currentPage, serialize, columnSort, conlumnSortDesc)
        }}
        onSort={(tate: any, perPage: number, currentPage: number, serialize: string, columnSort:string,isDesc:boolean) => {
          // getData(props?.defaultPerPage ?? perPage, currentPage, serialize, columnSort, isDesc)
          // alert(columnSort+" "+isDesc)
          setColumnSort(columnSort)
          setColumnSortDesc(isDesc)
          getData(props?.defaultPerPage ?? perPage, currentPage, serialize, columnSort, isDesc)
        }}
        showCreateButton={
          readonly ? false : !isForceCreateButton ? responseData?.meta?.create || false : true
        }
        maxHeight={maxHeight ?? maxHeight}
        onCreate={() => handleCreateButton()}
        onSelectedRows={(e: any) => (onSelectedRows ? onSelectedRows(e) : null)}
        disableSearchInputAutoFocus={disableSearchInputAutoFocus}
        defaultPerPage={props.defaultPerPage}
        disableUrlChanged={props.disableUrlChanged}
        hideSearch={props.hideSearch}
        hideShowPagination={props.hideShowPagination}
        hideExportAs={props.hideExportAs}
        onRowDoubleClick={props?.onRowDoubleClick || null}
        tableButton={props?.tableButton}
        headerButtons={props?.headerButtons}
        subHeaderButtons={props?.subHeaderButtons}
        disablePaddingCard={props?.disablePaddingCard}
        hideSelection={props?.hideSelection}
        checkboxConditionDisabled={props?.checkboxConditionDisabled}
        columnSort={columnSort}
        columnSortIsDesc={conlumnSortDesc}
        errorMessageConditionDisabled={props?.errorMessageConditionDisabled}
        hasAnySearch={props?.hasAnySearch}
      />
      <Modal size={'lg'} show={isModalCreateOpen} onHide={() => setIsModalCreateOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {useIntl().formatMessage({id: 'DATATABLE.FORM_CREATE'})} {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body key={formKey}>
          <FormX
            title={'Form'}
            fields={formCreateFields}
            formError={responseErrorCreate}
            onChange={onChangeFormCreate}
            colMd={12}
            colXl={12}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            disabled={isLoadingCreate}
            onClick={() => setIsModalCreateOpen(false)}
          >
            <i className={'fa fa-times'}></i>
            {useIntl().formatMessage({id: 'DATATABLE.CLOSE'})}
          </Button>
          <Button
            variant='primary'
            disabled={isLoadingCreate}
            type={'button'}
            onClick={() => handleSubmitCreate()}
            className={'d-flex flex-row align-items-center justify-content-center'}
          >
            <LoadingX show={isLoadingCreate}>
              <i className={'fa fa-save'}></i>
            </LoadingX>
            {useIntl().formatMessage({id: 'DATATABLE.SUBMIT'})}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal size={'lg'} show={isModalEditOpen} onHide={() => setIsModalEditOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {useIntl().formatMessage({id: 'DATATABLE.FORM_EDIT'})} {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body key={formKey}>
          {/*{JSON.stringify(formEditFields)}*/}
          <FormX
            title={'Form'}
            fields={formEditFields}
            formError={responseErrorUpdate}
            onChange={onChangeFormUpdate}
            colMd={12}
            colXl={12}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            disabled={isLoadingUpdate}
            onClick={() => setIsModalEditOpen(false)}
          >
            <i className={'fa fa-times'}></i>
            {useIntl().formatMessage({id: 'DATATABLE.CLOSE'})}
          </Button>
          <Button
            variant='primary'
            disabled={isLoadingUpdate}
            type={'button'}
            onClick={() => handleSubmitUpdate()}
            className={'d-flex justify-content-center align-items-center flex-row'}
          >
            <LoadingX show={isLoadingUpdate}>
              <i className={'fa fa-save'}></i>
            </LoadingX>
            {useIntl().formatMessage({id: 'DATATABLE.UPDATE'})}
          </Button>
        </Modal.Footer>
      </Modal>
      <SwalX
        title={intl.formatMessage({id: 'DATATABLE.CONFIRM_DELETE'})}
        message={intl.formatMessage({id: 'DATATABLE.MESSAGE_DELETE'})}
        show={isConfirmDeleteOpen}
        loading={isLoadingDelete}
        onHide={() => setIsConfirmDeleteOpen(false)}
        onSubmit={() => handleSubmitDelete()}
      />
    </>
  )
}

export default TableXWrapper
