import React, {FC, useEffect, useRef, useState} from 'react'
import Select from 'react-select'

import {useGetSelectList} from "../core/hook";
type IOptions = {
	url: string,
	key: string,
	label: string,
	return_only_id?: boolean //jika true, maka return data hanya berupa id saja, jika tidak, maka object return
}
type Props = {
	name?: string,
	model: string,
	onChange?: any,
	value?: any,
	focus?: boolean,
	defaultValue?: any,
	options: IOptions
}
const Select2FormX: FC<Props> = (props) => {
	const {model,onChange,value,focus, name, options, defaultValue} = props;
	const [_value,_setValue] = useState(value)
	const refKey = useRef();
	const [selectKey,setSelectKey] = useState(1)
	const [isMounted,setIsMounted] = useState(false)
	const [currentValue,setCurrentValue] = useState([])
	const [page,setPage] = useState(1);
	const [ isLoading, responseData, responseError, getData] = useGetSelectList();
	const [optionsData,setOptionsData] = useState([])
	const [search,setSearch] = useState("")
	useEffect(()=>{
		if(!options) return ;
		loadData()
	},[options])

	useEffect(()=>{
		if(!responseData) return ;
		if(!responseData.data) return ;
		if(responseData.data.length === 0) return ;
		// @ts-ignore
		let tmp = [];
		responseData.data.map((v:any)=>{
			//@ts-ignore
			tmp.push({
				value: v[options.key],
				label: v[options.label],
				data: v
			})
		})
		//@ts-ignore
		setOptionsData(tmp)
	},[responseData])

	useEffect(()=>{
		if(!isMounted && defaultValue){
			// @ts-ignore
			let tmp = [];
			// @ts-ignore
			let selected_id = [];
			defaultValue.map((v:any)=>{
				tmp.push({
					value: v[options.key],
					label: v[options.label],
					data: v
				})
				selected_id.push(
					v[options.key]
				)
			})
			// @ts-ignore
			onChange(selected_id)
			// @ts-ignore
			setCurrentValue(tmp)
			setIsMounted(true)
			setSelectKey(selectKey+1)
		}
	},[defaultValue])

	useEffect(()=>{
		if(search) {
			setOptionsData([])
			const getDataSearch = setTimeout(() => {
				loadData(1)
			}, 500)
			return () => clearTimeout(getDataSearch)
		} else {
			loadData(1)
		}
	},[search])

	const handleSearch = (e:any) => {
		setSearch(e)
	}
	const handleChangePage = async () => {
		if(responseData?.meta?.current_page < responseData?.meta?.last_page){
			loadData(responseData?.meta?.current_page+1)
		}
	}
	const loadData = async(page=1)=>{
		let url = options.url;
		getData(
			url,
			page,
			search?`${options.label}`:'',
			search
		);
	}

	const handleOnChange = (e:any)=>{
		let selected_id = e.map((v:any)=>{
			if(props.options.return_only_id === true) return v.value;
			return v;
		})
		onChange(selected_id)
	}

	// @ts-ignore
	return (
		<div className={"w-100"}>
			<Select
				key={selectKey}
				onChange={(e:any)=>handleOnChange(e)}
				isLoading={isLoading}
				isClearable
				isMulti={true}
				options={optionsData}
				// value={currentValue}
				defaultValue={currentValue}
				onInputChange={(e)=>handleSearch(e)}
				onMenuScrollToBottom={handleChangePage}
			/>
		</div>
	)
}

export default Select2FormX;