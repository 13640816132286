/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from "react-intl";
import { AsideMenuItemWithSub } from "./AsideMenuItemWithSub";
import { AsideMenuItem } from "./AsideMenuItem";
import { useGetSideMenu } from "../core/hooks";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Spinner } from "react-bootstrap";
import { Search } from "../../../partials";
import { useAuth } from "../../../../app/modules/auth";
import { Modal } from "rsuite";
import ModalDocument from "./ModalDocument";
// daftar uri yang akan di ignore untuk mengambil main menu
const ShouldLoadMainMenu = ["account", "dashboard"];

export function AsideMenuMain() {
  const intl = useIntl();
  const { pathname } = useLocation();
  const { currentUser, menuKey } = useAuth();
  const [currentUri, setCurrentUri] = useState("");
  const [isLoadingSideMenu, responseDataSideMenu, responseErrorSideMenu, getDataSideMenu] =
    useGetSideMenu();
  const [searchMenu, setSearchMenu] = useState("");
  useEffect(() => {
    const uriSlide = pathname.substring(1).split("/");
    if (currentUri !== uriSlide[0]) {
      let uri = ShouldLoadMainMenu.includes(uriSlide[0]) ? "dashboard" : uriSlide[0];
      setCurrentUri(uri);
    }
  }, [pathname]);

  useEffect(() => {
    if (!currentUri) return;
    getDataSideMenu(currentUri);
  }, [currentUri]);

  return (
    <>
      {isLoadingSideMenu && (
        <div className={"text-center"}>
          <Spinner animation={"border"} style={{ color: "white" }} />
        </div>
      )}
      <div className="aside-search p-1 pb-3">
        {/*<Search />*/}
        <div className="form-group has-search">
          <span className="fa fa-search form-control-feedback"></span>
          <input
            type="text"
            className="form-control form-control-sm"
            placeholder={intl.formatMessage({ id: "Cari Menu" })}
            onChange={(e: any) => setSearchMenu(e.target.value)}
          />
        </div>
      </div>

      <div key={menuKey}>
        {!isLoadingSideMenu &&
          responseDataSideMenu?.data &&
          responseDataSideMenu?.data?.length > 0 &&
          responseDataSideMenu?.data[0]?.menus
            .filter((v: any) => {
              return JSON.stringify(v).toLowerCase().includes(searchMenu.toLowerCase());
            })
            .map((v: any) => {
              return (
                <div key={"sm-" + v.id}>
                  {v.submenus && v.submenus.length > 0 && (
                    <AsideMenuItemWithSub
                      key={"menu" + v.id}
                      to={v.path}
                      // title={intl.formatMessage({id: v.name})}
                      title={v?.name}
                      icon="/media/icons/duotune/general/gen025.svg"
                    >
                      {v.submenus.map((x: any) => {
                        // Sub menu "MR/SR Sentralisasi Masuk"
                        if (x.id === 90 && currentUser?.has_roles?.includes('staff_perencana_anper')) {
                          return <></>
                        }

                        return (
                          <AsideMenuItem
                            key={"submenu" + v.id + "x" + x.id}
                            to={x.path}
                            icon={x.icon}
                            title={x.name}
                            countingUrl={x.badge_count_url}
                          />
                        );
                      })}
                    </AsideMenuItemWithSub>
                  )}
                  {(!v.submenus || v.submenus.length === 0) && (
                    <AsideMenuItem
                      key={"menu" + v.id}
                      to={v.path}
                      icon={v.icon}
                      title={v.name}
                      countingUrl={v.badge_count_url}
                    />
                  )}
                </div>
              );
            })}
      </div>

      <ModalDocument 
        documentType='internal'
      />

      {/*<AsideMenuItem to='/builder' icon='/media/icons/duotune/general/gen019.svg' title='Berita' />*/}

      {/*<AsideMenuItemWithSub*/}
      {/*  to='/master-data'*/}
      {/*  title={intl.formatMessage({id: 'MENU.MASTER_DATA'})}*/}
      {/*  icon='/media/icons/duotune/general/gen025.svg'*/}
      {/*>*/}
      {/*  <AsideMenuItem to='/master-data/announcement-categories' title='Announcement Category' />*/}
      {/*</AsideMenuItemWithSub>*/}
      {/*  <AsideMenuItemWithSub*/}
      {/*      to='/procure-x'*/}
      {/*      title={intl.formatMessage({id: 'MENU.ADMINAREA'})}*/}
      {/*      icon='/media/icons/duotune/general/gen025.svg'*/}
      {/*  >*/}
      {/*      <AsideMenuItem to='/procure-x/module-management' title='Module' />*/}
      {/*      <AsideMenuItem to='/procure-x/menu-management' title='Menu' />*/}
      {/*  </AsideMenuItemWithSub>*/}
    </>
  );
}
