import React, { FC, useEffect, useState } from "react";
import DataTableBase from "../../../components/shared/DataTableBase";
import { taskColumns } from "../_columns";
import TableXWrpper from "../../../components/shared/TableXWrapper";
import { toast } from "react-toastify";

const TaskTable: FC = () => {
  const [urlIndex, setUrlIndex] = useState(process.env.REACT_APP_API_URL + "/api/task/task-ticket/me");
  const tableColumns = [
    { Header: "Action", accessor: "url", width: 50, tdClass: "text-center", className: "text-center", Cell: (props:any) =>{
      return (
        <>
          {props.cell.row.values.url && (
            <button
              type='button'
              className='btn btn-xs btn-secondary'
              onClick={() => {
                window.location.href = props.cell.row.values.url;
              }}
            >
              <i className={'fa fa-eye'} />
            </button>
          )}
        </>
      )
    }},
    {
      Header: "Type",
      accessor: "source",
      width: 200,
      disableCreate: true,
      disableEdit: false,
      className: "text-center"
    },
    {
      Header: "Description", accessor: "title", width: 500, tdClass: "text-center"
    },
    { Header: "Status", accessor: "status", width: 100, tdClass: "text-center", className: "text-center" },
    { Header: "Time", accessor: "time", width: 160, tdClass: "text-center", className: "text-center" },

  ];
  return (
    <>
      <TableXWrpper
        title={"Task"}
        urlIndex={urlIndex}
        columns={tableColumns}
        readonly={true}
        autoRefreshInSeconds={30}
      />
    </>
  );
};

export default TaskTable;
