import React, { useContext, useEffect, useState } from "react";
import { KTCard, KTCardBody } from "../../../../../../_metronic/helpers";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import FormikSelect2 from "../../../../../components/shared/form/FormikSelect2";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";

const ParameterAuction = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const headerFormSchema = Yup.object().shape({});
  const params = useParams();
  const id = params?.['*'];
  const [detailData, setDetailData]: any = useState([]);
  const [isLoadingSave, setIsLoadingSave] = useState(false)

  const fetchDetailData = async () => {
    const response: any = await axios.get(`${API_URL}/api/auction/auction/${id}`)
    setDetailData(response.data?.data);
  }

  const onSubmit = async (values: any) => {
    setIsLoadingSave(true)
    try {
      let data = { ...values }
      const response: any = await axios.put(`${API_URL}/api/auction/auction/` + id, data)
      if (response?.status === 201 || response?.status === 200) {
        toast.success(response?.status + ': ' + response?.data.meta.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
        return true
      }
    } catch (error) {
      setIsLoadingSave(false)
      console.error(error)
    } finally {
      setIsLoadingSave(false)
    }
  }

  useEffect(() => {
    fetchDetailData();
  }, [])


  return (
    <>
      <div className='d-flex gap-4 mb-5'></div>
      <KTCard>
        <KTCardBody className='py-4'>
          <Formik
            enableReinitialize
            validationSchema={headerFormSchema}
            initialValues={detailData}
            onSubmit={onSubmit}
          >
            {({errors, touched, values, setFieldValue}: any) => (
              <Form>
                <div className={'row'}>
                  <div className={'col-md-6'}>
                    <div className='mb-10'>
                      <label className='required form-label mb-3'>No</label>
                      <Field className='form-control' disabled={true} required name='no_auction' />
                    </div>
                    <div className='mb-10'>
                      <label className='required form-label mb-3'>Nama</label>
                      <Field
                        className='form-control'
                        disabled={detailData?.status == 'published'}
                        name='nama'
                        required
                      />
                    </div>
                    <div className='mb-10'>
                      <FormikSelect2
                        name='purchasing_org'
                        label='Purchasing Org'
                        disabled={detailData?.status == 'published'}
                        required
                        optionsUrl={`${API_URL}/api/masterdata/companies`}
                        unique_property={'id'}
                        defaultValue={values?.purchasing_org}
                        getOptionValue={(opt: any) => opt}
                        getOptionLabel={(opt: any) => opt.comp_code_name}
                        onChangeValue={(opt: any) => {
                          setFieldValue('purchasingGroup', 0)
                        }}
                      />
                    </div>
                    <div className='mb-10'>
                      <label className='required form-label mb-3'>Buyer</label>
                      <Field
                        className='form-control'
                        disabled={true}
                        name='created_by.company_name'
                        required
                      />
                    </div>
                  </div>
                  <div className={'col-md-6'}>
                    <div className='mb-10'>
                      <label className='required form-label mb-3'>Status</label>
                      <Field className='form-control' name='status' disabled={true} required />
                    </div>
                    <div className='mb-10'>
                      <FormikSelect2
                        name='tipe_auction'
                        label='Tipe Auction'
                        disabled={true}
                        defaultValue={values?.tipe_auction}
                        optionsUrl={
                          API_URL + '/api/masterdata/enumeration?filter[type]=AUCTION_TYPE'
                        }
                        unique_property={'id'}
                        getOptionValue={(opt) => opt}
                        getOptionLabel={(opt) => opt.value}
                        search_property={'value'}
                        required
                      />
                    </div>
                    <div className='mb-10'>
                      <FormikSelect2
                        label='Purchasing Group'
                        name='purchasing_group'
                        disabled={detailData?.status == 'published'}
                        defaultValue={values?.purchasing_group}
                        optionsUrl={
                          API_URL +
                          '/api/masterdata/purchasing-group?filter[comp_code]=' +
                          values.purchasing_org?.comp_code
                        }
                        getOptionValue={(opt: any) => opt}
                        getOptionLabel={(opt: any) =>
                          `${opt.purch_group_id} - ${opt.purch_group_name}`
                        }
                        unique_property={'id'}
                        required
                      />
                    </div>
                    <div className='mb-10'>
                      <label className='required form-label mb-3'>Freeze Time</label>
                      <Field
                        className='form-control'
                        disabled={detailData?.status == 'published'}
                        name='freeze_time'
                        required
                      />
                    </div>
                  </div>
                  <div className={'col-md-12'}>
                    <hr />
                  </div>
                  <div className={'col-md-6'}>
                    <div className='mb-10'>
                      <FormikSelect2
                        name='metode_evaluasi'
                        label='Metode Evaluasi Pemenang'
                        disabled={true}
                        defaultValue={values?.metode_evaluasi}
                        optionsUrl={
                          API_URL +
                          '/api/masterdata/enumeration?filter[type]=AUCTION_EVALUATION_METHOD'
                        }
                        unique_property={'id'}
                        getOptionValue={(opt) => opt}
                        getOptionLabel={(opt) => opt.value}
                        search_property={'value'}
                        required
                      />
                    </div>
                    <div className='mb-10'>
                      <FormikSelect2
                        name='opsi_peringkat'
                        defaultValue={values?.opsi_peringkat}
                        disabled={detailData?.status == 'published'}
                        label='Opsi Penampilan Peringkat'
                        optionsUrl={
                          API_URL +
                          '/api/masterdata/enumeration?filter[type]=AUCTION_RATING_APPEARANCE'
                        }
                        unique_property={'id'}
                        getOptionValue={(opt) => opt}
                        getOptionLabel={(opt) => opt.value}
                        search_property={'value'}
                        required
                      />
                    </div>
                    <div className='mb-10'>
                      <FormikSelect2
                        name='jenis_penerimaan'
                        defaultValue={values?.jenis_penerimaan}
                        disabled={detailData?.status == 'published'}
                        label='Penerimaan Jumlah Diluar Rentang'
                        optionsUrl={
                          API_URL + '/api/masterdata/enumeration?filter[type]=AUCTION_ACCEPT'
                        }
                        unique_property={'id'}
                        getOptionValue={(opt) => opt}
                        getOptionLabel={(opt) => opt.value}
                        search_property={'value'}
                        required
                      />
                    </div>
                    <div className='mb-10'>
                      <label className='required form-label mb-3'>Increment / Decrement</label>
                      <Field
                        name={`increment`}
                        id='increment'
                        disabled={detailData?.status == 'published'}
                        type='number'
                        className={'form-control'}
                      />
                    </div>
                  </div>
                  <div className={'col-md-6'}>
                    <div className={'row'}>
                      <div className={'col-md-4'}>
                        <div className='mb-10'>
                          <FormikSelect2
                            name='metode_peringkat'
                            defaultValue={values?.metode_peringkat}
                            disabled={true}
                            label='Metode Peringkat'
                            optionsUrl={
                              API_URL +
                              '/api/masterdata/enumeration?filter[type]=AUCTION_RATING_METHOD'
                            }
                            unique_property={'id'}
                            getOptionValue={(opt) => opt}
                            getOptionLabel={(opt) => opt.value}
                            search_property={'value'}
                            required
                          />
                        </div>
                      </div>
                      <div className={'col-md-8'}>
                        <div className='mb-10'>
                          <label className='required form-label mb-3'>Template MVB</label>
                          <Field
                            disabled={true}
                            className='form-control'
                            name='template_vb'
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className='mb-10'>
                      <FormikSelect2
                        name='visibilitas_peringkat'
                        defaultValue={values?.visibilitas_peringkat}
                        label='Visibilitas Peringkat Terbaik'
                        disabled={detailData?.status == 'published'}
                        optionsUrl={
                          API_URL + '/api/masterdata/enumeration?filter[type]=AUCTION_VISIBLE'
                        }
                        unique_property={'id'}
                        getOptionValue={(opt) => opt}
                        getOptionLabel={(opt) => opt.value}
                        search_property={'value'}
                        required
                      />
                    </div>
                    <div className='mb-10'>
                      <FormikSelect2
                        name='visibilitas_harga'
                        defaultValue={values?.visibilitas_harga}
                        disabled={detailData?.status == 'published'}
                        label='Visibilitas Harga Terbaik'
                        optionsUrl={
                          API_URL + '/api/masterdata/enumeration?filter[type]=AUCTION_VISIBLE'
                        }
                        unique_property={'id'}
                        getOptionValue={(opt) => opt}
                        getOptionLabel={(opt) => opt.value}
                        search_property={'value'}
                        required
                      />
                    </div>
                    <div className='mb-10'>
                      <FormikSelect2
                        name='dominasi'
                        disabled={detailData?.status == 'published'}
                        defaultValue={values?.dominasi}
                        label='Denominasi'
                        optionsUrl={
                          API_URL + '/api/masterdata/enumeration?filter[type]=AUCTION_DOMINATION'
                        }
                        unique_property={'id'}
                        getOptionValue={(opt) => opt}
                        getOptionLabel={(opt) => opt.value}
                        search_property={'value'}
                        required
                      />
                    </div>
                  </div>
                </div>
                {detailData?.status == 'draft' && (
                  <div className={'row'}>
                    <div className={'col-md-12 text-end'}>
                      <button className={'btn btn-primary'} disabled={isLoadingSave}>
                        {isLoadingSave && (
                          <Spinner animation={'border'} size={'sm'} className={'me-2'} />
                        )}
                        Simpan
                      </button>
                    </div>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </KTCardBody>
      </KTCard>
    </>
  )
};

export { ParameterAuction };
