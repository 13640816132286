import { Drawer, Placeholder, Timeline } from "rsuite";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { formatDateTimeToDBFormat, getEnv } from "../../../../../helpers/procurex";
import useGet from "../../../../../hooks/useGet";
import { Spinner } from "react-bootstrap";

const ActivityRow = ({item}:any) => {
  const [{ doGetDetail, isLoading, isError, data, isSuccess }] = useGet();
  const [causer,setCauser] = useState<any>(null);
  const getCauser = () => {
    if(!item?.causer_id) return;
    const url = getEnv('sso') + `/user/${item?.causer_id}`;
    doGetDetail(url,undefined).then((res:any)=>{
      setCauser(res?.data)
    })
  }

  useEffect(() => {
    getCauser()
  }, []);
  return (
    <Timeline.Item key={'log-drawer-'+item?.id}>

      <div className="d-flex justify-content-between align-content-between">
        <div>
          <strong>{formatDateTimeToDBFormat(item?.created_at,false)}</strong>
        </div>
        <div>
          {isLoading && (
            <Spinner animation="border" variant="primary" size="sm" />
          )}
          {!isLoading && causer?.username && (
            <strong>{causer?.username} - {causer?.name}</strong>
          )}
          {!isLoading && !causer?.username && (
            <strong><i>System</i></strong>
          )}

        </div>
      </div>
      {item?.log_name}: {item?.description}
      <div>
        {item?.properties?.comment && (
          <i>{item?.properties?.comment}</i>
        )}
      </div>
    </Timeline.Item>
  )
}

const ActivityLogDrawer = ({subject_id, subject_type, onHide}:any) => {
  const [isShowLog, setIsShowLog] = React.useState(false);
  const intl = useIntl();
  const [urlIndex,setUrlIndex] = useState("");
  const [{ doGetDetail, isLoading, isError, data, isSuccess }] = useGet();
  const [timelineData, setTimelineData] = useState<any>([]);
  const getTimeline = () => {
    setIsShowLog(true)
    doGetDetail(urlIndex,undefined).then((res: any) => {
      setTimelineData(res?.data)
    })
  }

  const handleOnHide = () => {
    setIsShowLog(false)
    onHide && onHide()
  }

  useEffect(() => {
    setUrlIndex(getEnv("tender") + `/log?filter[subject_id]=${subject_id}&filter[subject_type]=${subject_type}&perPage=1000`)
  }, [subject_id]);

  useEffect(() => {
    if(!urlIndex) return;
    getTimeline()
  }, [urlIndex]);

  return (
    <>

      <Drawer open={isShowLog} onClose={() => handleOnHide()}>
        <Drawer.Header>
          <Drawer.Title>
            {intl.formatMessage({ id: "Log Aktivitas" })}
          </Drawer.Title>
          <Drawer.Actions>
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body>
          {isLoading && (
            <>
              <Placeholder.Paragraph rows={3} active={isLoading} />
              <Placeholder.Paragraph rows={3} active={isLoading} />
            </>
          )}
          {!isLoading && (
            <>
              <Timeline isItemActive={Timeline.ACTIVE_FIRST}>
                {timelineData?.length === 0 && (
                  <div className="text-center">
                    <img src={"/empty.png"} style={{ width: "100px" }} alt={"empty"} />
                    <h5 className="mt-3">
                      {intl.formatMessage({ id: "Belum ada log tersedia" })}
                    </h5>
                  </div>
                )}
                {timelineData?.reverse()?.map((item: any,index:number) => {
                  return (
                    <ActivityRow item={item} key={"history-log-"+index+'-'+item?.id} />
                  )
                })}
              </Timeline>
              <hr/>
              <div className="text-muted text-start mt-4">
                <i>*{intl.formatMessage({ id: "Waktu Server dalam WIB" })} / (GMT+7)</i>
              </div>
            </>
          )}
        </Drawer.Body>
      </Drawer>
    </>
  );
};

export default ActivityLogDrawer;
