import React, { useCallback, useEffect, useState } from "react";
import { useAxiosGet, useAxiosPost } from "../../../../../../../../hooks/useAxios";
import { getEnv, parseNumberToCurrency, formatNumber } from "../../../../../../../../helpers/procurex";
import { Placeholder } from "rsuite";
import { toast } from "react-toastify";
import { Modal, Spinner } from "react-bootstrap";
import BottomToolbarWrapper from "../../../../../../../components/shared/BottomToolbarWrapper";
import Swal from "sweetalert2";
import { useIntl } from "react-intl";
import {formatCurrency} from '../../../../../../../../_metronic/helpers'
import {PageAlertX} from '../../../../../../../components/shared/PageAlertX'

const StatusBadge = ({ status }: any) => {
  const [badge,setBadge] = useState("badge-primary")
  useEffect(() => {
    if(status === "APPROVE") setBadge("badge-success")
    else if(status === "REJECT") setBadge("badge-danger")
    else setBadge("badge-primary")
  }, [status]);
  return (
    <div className={"badge "+badge}>
      {status}
    </div>
  )
}

const TRItemDetail = ({  loading, proposalTender, item, negotiation, itemLength, index, selectedApprovePaket, setSelectedApprovePaket, onSubmit }: any) => {
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosPost(
    getEnv("tender") + `/negotiation/${proposalTender?.negotiation?.uuid}`
  );
  const [selectedApproveItemize, setSelectedApproveItemize] = useState(null as any);
  const [isShowConfirmation,setIsShowConfirmation] = useState(false)
  const [isShowConfirmationReject,setIsShowConfirmationReject] = useState(false)
  const [rejectionNote,setRejectionNote] = useState("")
  const [currentItem,setCurrentItem] = useState(item)
  const [showPreview, setShowPreview] = useState(false)
  const handleApproveItemize = (state: boolean) => {
    if(!state) {
      if(rejectionNote.length === 0) {
        toast.error("Rejection Note harap diisi.")
        return ;
      }
    }
    const payload = {
      negotiation_item_id: item?.uuid,
      evaluate: state,
      rejection_note: rejectionNote
    };
    doSubmit(payload, undefined, toast).then((res: any) => {
      setIsShowConfirmationReject(false)
      setIsShowConfirmation(false)
      setCurrentItem({
        ...currentItem,
        status_current_negotiation: state ? "APPROVE" : "REJECT"
      })
    });
  };

  const isPaket = () => {
    return proposalTender?.order_placement === "Paket";
  };

  const checkIsApproveDisabled = () => {
    if(isPaket()) {
      let isNegotiatiate = item?.is_submitted;
      // negotiation?.items.forEach((item: any) => {
      //   if(item?.total_negotiation !== item?.total_current_negotiation) isNegotiatiate = true;
      // });
      return item.status_current_negotiation === "APPROVE" || !isNegotiatiate;
    } else {
      return item.status_current_negotiation === "APPROVE";
    }
  }

  const checkIsShowApproveReject = () => {
    return item?.total_negotiation !== item?.total_current_negotiation;
  }

  const handleReject = () => {
    setIsShowConfirmationReject(true)
  }

  return (
    <tr>
      <td className={'border px-2 text-center text-nowrap'}>{item.material}</td>
      <td className={'border px-2 text-center text-nowrap'}>{item.short_text}</td>
      <td className={'border px-2 text-end text-nowrap'}>{parseNumberToCurrency(item?.hps)}</td>
      <td className={'border px-2 text-end text-nowrap'}>{formatNumber(item?.quantity)}</td>
      <td className={'border px-2 text-end text-nowrap'}>{parseNumberToCurrency(item?.total_offering)}</td>
      <td className={'border px-2 text-end text-nowrap'}>
        <strong><span>{parseNumberToCurrency(item?.total_negotiation)}</span></strong>
      </td>
      <td className={'border px-2 text-end text-nowrap'}>
        <strong><span>{parseNumberToCurrency(item?.total_current_negotiation)}</span></strong>
      </td>
      <td className={'border px-2 text-end text-nowrap'}>{parseNumberToCurrency(item?.total)}</td>
      <td className={'border px-2 text-center text-nowrap'}>
        {/*  Vendor Quote*/}
        {item?.quotation_quote}
      </td>
      <td className={'border px-2 text-center text-nowrap'}>{item.delivery_time || 0}</td>
      {/*<td className={"border px-2 text-center text-nowrap"}>{item.material_number}</td>*/}
      <td className={'border px-2 text-center text-nowrap'}>
        {parseNumberToCurrency(item?.hps - item?.total_current_negotiation)}
      </td>
      <td className={'border px-2 text-center text-nowrap'}
      >
        <textarea className={'form-control form-control-sm'} disabled={true}>{currentItem?.rejection_note || '-'}</textarea>
        <a href={'javascript:;'} className={'mt-2 text-primary'} onClick={() => setShowPreview(true)}>
          <i className={'fa fa-eye'}></i> Preview
        </a>
      </td>
      <td
        className={'border px-2 text-center text-nowrap'}
        style={{verticalAlign: 'middle', position: 'sticky', right: 210, zIndex: 1, backgroundColor: '#fff'}}
      >
        <StatusBadge status={currentItem.status_current_negotiation} />
      </td>

      {!isPaket() && (
        <>
          <td className={'border px-2 text-center text-nowrap bg-light-info'}
              style={{verticalAlign: 'middle', position: 'sticky', right: 80, zIndex: 1}}
          >
            <div className={'d-flex align-items-center justify-content-center gap-2'}>
              {isLoading && (
                <Spinner animation="border" variant="primary" size={'sm'} />
              )}
              {/*<input*/}
              {/*  type={"checkbox"}*/}
              {/*  className={"form-check-input"}*/}
              {/*  checked={selectedApproveItemize === true || item.status_current_negotiation === "APPROVE"}*/}
              {/*  onChange={(e: any) => {*/}
              {/*    setSelectedApproveItemize(true);*/}
              {/*  }}*/}
              {/*  disabled={isLoading || item.status_current_negotiation === "APPROVE"}*/}
              {/*/> Approve*/}
              <button
                className={'btn btn-primary btn-sm'}
                type={'button'}
                onClick={() => setIsShowConfirmation(true)}
                disabled={currentItem.status_current_negotiation !== 'OPEN' || !checkIsShowApproveReject()}
              >
                <i className={'fa fa-check'}></i>
                Approve
              </button>

            </div>
          </td>
          <td className={'border px-2 text-center text-nowrap bg-light-info'}
              style={{verticalAlign: 'middle', position: 'sticky', right: 0, zIndex: 1}}>
            <div className={'d-flex align-items-center justify-content-center gap-2'}>
              {isLoading && (
                <Spinner animation="border" variant="primary" size={'sm'} />
              )}
              {/*<input*/}
              {/*  type={"checkbox"}*/}
              {/*  className={"form-check-input"}*/}
              {/*  checked={selectedApproveItemize === false || item.status_current_negotiation === "REJECT"}*/}
              {/*  onChange={(e: any) => {*/}
              {/*    setSelectedApproveItemize(false);*/}
              {/*  }}*/}
              {/*  disabled={isLoading || item.status_current_negotiation === "APPROVE"}*/}
              {/*/> Reject*/}
              <button
                className={'btn btn-danger btn-sm'}
                type={'button'}
                onClick={() => {
                  setIsShowConfirmationReject(true)
                }}
                disabled={currentItem.status_current_negotiation !== 'OPEN' || !checkIsShowApproveReject()}
              >
                <i className={'fa fa-times'}></i>
                Reject
              </button>
            </div>
          </td>
        </>
      )}
      {(isPaket() && index === 0) && (
        <>
          <td className={'border px-2 text-center text-nowrap bg-light-info'}
              style={{verticalAlign: 'middle', width: 150, position: 'sticky', right: 100, zIndex: 1}}
              rowSpan={index === 0 ? itemLength : 1}
          >
            <div className={'d-flex align-items-center justify-content-center gap-2'}>
              {isLoading && (
                <Spinner animation="border" variant="primary" size={'sm'} />
              )}
              {/*<input*/}
              {/*  type={"checkbox"}*/}
              {/*  className={"form-check-input"}*/}
              {/*  checked={selectedApprovePaket === true}*/}
              {/*  onChange={(e: any) => {*/}
              {/*    setSelectedApprovePaket(true);*/}
              {/*  }}*/}
              {/*  disabled={isLoading}*/}
              {/*/> Approve*/}
              <button
                className={'btn btn-primary btn-sm'}
                type={'button'}
                onClick={() => setIsShowConfirmation(true)}
                disabled={checkIsApproveDisabled()}
              >
                {isLoading && (
                  <Spinner animation="border" variant="primary" size={'sm'} />
                )}
                {!isLoading && (
                  <i className={'fa fa-check'}></i>
                )}
                Approve
              </button>

            </div>
          </td>
          <td className={'border px-2 text-center text-nowrap bg-light-info'}
              style={{verticalAlign: 'middle', position: 'sticky', right: 0, zIndex: 1}}
              rowSpan={index === 0 ? itemLength : 1}
          >
            <div className={'d-flex align-items-center justify-content-center gap-2'}>
              {isLoading && (
                <Spinner animation="border" variant="primary" size={'sm'} />
              )}
              {/*<input*/}
              {/*  type={"checkbox"}*/}
              {/*  className={"form-check-input"}*/}
              {/*  checked={selectedApprovePaket === false}*/}
              {/*  onChange={(e: any) => {*/}
              {/*    setSelectedApprovePaket(false);*/}
              {/*  }}*/}
              {/*  disabled={isLoading}*/}
              {/*/> Reject*/}
              <button
                className={'btn btn-danger btn-sm'}
                type={'button'}
                onClick={() => {
                  // setSelectedApprovePaket(false)
                  handleReject()
                }}
                disabled={checkIsApproveDisabled()}
              >
                <i className={'fa fa-times'}></i>
                Reject
              </button>
            </div>
          </td>
        </>
      )}

      <Modal show={isShowConfirmation}>
        <Modal.Header>
          <h4>Konfirmasi Approve</h4>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className={'table-responsive'}>
              <table className={'table table-striped table-bordered'}>
                <tbody>
                <tr>
                  <td style={{width: 200}}><strong>Short Text</strong></td>
                  <td className={'text-end'}>{item?.short_text}</td>
                </tr>
                <tr>
                  <td><strong>Currency</strong></td>
                  <td className={'text-end'}>{item?.currency_id}</td>
                </tr>
                <tr>
                  <td><strong>HPS</strong></td>
                  <td className={'text-end'}>{formatCurrency(item?.hps)}</td>
                </tr>
                <tr>
                  <td><strong>Total Penawaran</strong></td>
                  <td className={'text-end'}>{formatCurrency(item?.total_offering)}</td>
                </tr>
                <tr>
                  <td><strong>Total Sebelum Sesi Nego</strong></td>
                  <td className={'text-end'}>{formatCurrency(item?.total_negotiation)}</td>
                </tr>
                <tr>
                  <td><strong>Total Setelah Sesi Nego</strong></td>
                  <td className={'text-end'}>
                    <strong>{formatCurrency(item?.total_current_negotiation)}</strong>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            className={'btn btn-danger'}
            type={'button'}
            onClick={() => setIsShowConfirmation(false)}
            disabled={loading}
          >
            {!loading && (
              <i className={'fa fa-times'}></i>
            )}
            {loading && (
              <Spinner animation="border" role="status" size={'sm'} className={'me-2'} />
            )}
            Batal
          </button>
          <button
            className={'btn btn-primary'}
            type={'button'}
            disabled={loading}
            onClick={() => {
              if (isPaket()) onSubmit(true)
              else handleApproveItemize(true)
            }}
          >
            {!loading && (
              <i className={'fa fa-check'}></i>
            )}
            {loading && (
              <Spinner animation="border" role="status" size={'sm'} className={'me-2'} />
            )}
            Approve
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={isShowConfirmationReject} size={'lg'}>
        <Modal.Header>
          <h4>Konfirmasi Reject</h4>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className={'table-responsive'}>
              <table className={'table table-striped table-bordered'}>
                <tbody>
                <tr>
                  <td style={{width: 200}}><strong>Short Text</strong></td>
                  <td style={{width: '5px'}}>:</td>
                  <td className={'text-end'}>{item?.short_text}</td>
                </tr>
                <tr>
                  <td><strong>HPS</strong></td>
                  <td>:</td>
                  <td className={'text-end'}>{parseNumberToCurrency(item?.hps)}</td>
                </tr>
                <tr>
                  <td><strong>Total Penawaran</strong></td>
                  <td>:</td>
                  <td className={'text-end'}>{parseNumberToCurrency(item?.total_offering)}</td>
                </tr>
                <tr>
                  <td><strong>Total Sebelum Sesi Nego</strong></td>
                  <td>:</td>
                  <td className={'text-end'}>{parseNumberToCurrency(item?.total_negotiation)}</td>
                </tr>
                <tr>
                  <td><strong>Total Nego</strong></td>
                  <td>:</td>
                  <td className={'text-end'}>
                    <strong>{parseNumberToCurrency(item?.total_current_negotiation)}</strong>
                  </td>
                </tr>
                <tr>
                  <td><strong>Rejection Note</strong></td>
                  <td>:</td>
                  <td className={'text-end'}>
                    <textarea className={'form-control'} onChange={(e) => setRejectionNote(e.target.value)}></textarea>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            className={'btn btn-warning'}
            type={'button'}
            onClick={() => setIsShowConfirmationReject(false)}
            disabled={loading}
          >
            {!loading && (
              <i className={'fa fa-times'}></i>
            )}
            {loading && (
              <Spinner animation="border" role="status" size={'sm'} className={'me-2'} />
            )}
            Batal
          </button>
          <button
            className={'btn btn-danger'}
            type={'button'}
            disabled={loading}
            onClick={() => {
              if(isPaket()) onSubmit(false, rejectionNote)
              else handleApproveItemize(false)
            }}
          >
            {!loading && (
              <i className={'fa fa-times'}></i>
            )}
            {loading && (
              <Spinner animation="border" role="status" size={'sm'} className={'me-2'} />
            )}
            Reject
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={showPreview}>
        <Modal.Header>
          <h4>Rejection Note</h4>
        </Modal.Header>
        <Modal.Body>
          <>
            <p>
              {item?.rejection_note}
            </p>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            className={'btn btn-primary'}
            type={'button'}
            onClick={() => {
              setShowPreview(false)
            }}
          >
            <i className={'fa fa-thumbs-up'}></i>
            Ok
          </button>
        </Modal.Footer>
      </Modal>
    </tr>
  );
};

const TRItem = ({loading, proposalTender, negotiation, index, onAfterApprove}: any) => {
  const [{doSubmit, isLoading, isError, data, isSuccess}] = useAxiosPost(
    getEnv('tender') + `/negotiation/${proposalTender?.negotiation?.uuid}`
  );
  const [selectedApprovePaket, setSelectedApprovePaket] = useState(null as any);
  const isPaket = () => {
    return proposalTender?.order_placement === "Paket";
  };

  const handleApprovePaket = (state: boolean,rejectionNote="") => {
    const payload = {
      proposal_tender_vendor_id: negotiation?.uuid,
      evaluate: state,
      rejection_note: rejectionNote
    };
    doSubmit(payload, undefined, toast).then((res: any) => {
      onAfterApprove && onAfterApprove()
    });
  };

  return (
    <>
      <tr>
        {/*  {isPaket() && (*/}
        {/*    <>*/}
        {/*      <td className={"border px-2 text-center"} rowSpan={isPaket() ? negotiation?.items.length + 1 : 1}>*/}
        {/*        <div className={"d-flex align-items-center justify-content-center gap-2"}>*/}
        {/*          {isLoading && (*/}
        {/*            <Spinner animation="border" variant="primary" size={"sm"} />*/}
        {/*          )}*/}
        {/*          <input*/}
        {/*            type={"checkbox"}*/}
        {/*            className={"form-check-input"}*/}
        {/*            checked={selectedApprovePaket === true}*/}
        {/*            onChange={(e: any) => {*/}
        {/*              setSelectedApprovePaket(true);*/}
        {/*            }}*/}
        {/*            disabled={isLoading}*/}
        {/*          /> Approve*/}

        {/*        </div>*/}
        {/*      </td>*/}
        {/*      <td className={"border px-2 text-text-nowrap"} rowSpan={isPaket() ? negotiation?.items.length + 1 : 1}>*/}
        {/*        <div className={"d-flex align-items-center justify-content-center gap-2"}>*/}
        {/*          {isLoading && (*/}
        {/*            <Spinner animation="border" variant="primary" size={"sm"} />*/}
        {/*          )}*/}
        {/*          <input*/}
        {/*            type={"checkbox"}*/}
        {/*            checked={selectedApprovePaket === false}*/}
        {/*            className={"form-check-input"}*/}
        {/*            onChange={(e: any) => {*/}
        {/*              setSelectedApprovePaket(false);*/}
        {/*            }}*/}
        {/*            disabled={isLoading}*/}
        {/*          /> Reject*/}
        {/*        </div>*/}
        {/*      </td>*/}
        {/*    </>*/}
        {/*  )}*/}
        <td className={"border px-2 text-start text-nowrap"} rowSpan={negotiation?.items.length + 1}>
          <div><strong><small>{negotiation.vendor_id}</small></strong></div>
          <div>{negotiation.vendor?.name}</div>
        </td>

      </tr>
      {negotiation?.items.map((item: any, index: number) => {
        return (
          <TRItemDetail
            key={"proses-nego-list-item-" + index}
            index={index}
            proposalTender={proposalTender}
            item={item}
            negotiation={negotiation}
            itemLength={negotiation?.items.length}
            selectedApprovePaket={selectedApprovePaket}
            setSelectedApprovePaket={setSelectedApprovePaket}
            loading={isLoading}
            onSubmit={(state:boolean,rejectionNote:any)=>handleApprovePaket(state,rejectionNote)}
          />
        );
      })}
    </>
  );
};


const ProsesNegosiasiTab = ({ proposalTender, onChange }: any) => {
  const intl = useIntl();
  const [negotiations, setNegotiations] = useState([] as any);
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosGet(
    getEnv("tender") + `/negotiation/${proposalTender?.negotiation?.uuid}?only_process=true`
  );
  const [{ doSubmit: doSubmitClose, isLoading: isLoadingClose }] = useAxiosPost(
    getEnv("tender") + `/negotiation/${proposalTender?.negotiation?.uuid}`
  );
  const [renderKey,setRenderKey] = useState(0)

  const getItems = () => {
    doSubmit(null, undefined, undefined).then((res: any) => {
      const vendors = res?.data?.vendors || [];
      const selectedVendors = [] as any;
      vendors.forEach((vendor: any) => {
        // const selectedVendors = [] as any;
        const hasItemProcess = vendor?.items?.filter((i: any) => i.process === true).length > 0;
        if(hasItemProcess) {
          selectedVendors.push(vendor)
        }
      });
      // console.log(selectedVendors)
      setNegotiations(selectedVendors)
      setRenderKey(renderKey+1)
      // setNegotiations(tmp)
      // setNegotiations(res?.data?.vendors || []);
    });
  };

  const isPaket = () => {
    return proposalTender?.order_placement === "Paket";
  };

  const isShowCloseNegotiation = () => {
    const allowedStatus = [
      "Negotiation"
    ];
    const allowedStatusDetail = [
      "Proses Negosiasi"
    ];
    return allowedStatus.includes(proposalTender?.status) &&
      allowedStatusDetail.includes(proposalTender?.status_detail) &&
      proposalTender?.negotiation?.type === "SATUAN";
  };

  const onCloseNegosiasi = async() => {
    Swal.fire({
      title: intl.formatMessage({id:'Apakah Anda yakin?'}),
      text: intl.formatMessage({id:'Anda akan menutup sesi negosiasi ini'}),
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Ya, Tutup",
      cancelButtonText: "Batal"
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        // Submit evaluasi vendor
        doSubmitClose({},
          getEnv("tender") + `/negotiation/close/${proposalTender?.negotiation?.uuid}`,
          toast
        ).then((res: any) => {
          // submit evaluasi administrasi
        }).finally(() => {
          onChange && onChange();
        });
      } else {
      }
    });
  }


  useEffect(() => {
    getItems();
  }, []);

  return (
    <>
      <div>
        <PageAlertX title={""} variant={"info"} html={
          <>
            Jenis Negosiasi <strong>{proposalTender?.negotiation?.type}</strong>
          </>
        } /> <br />
      </div>
      <div className={'d-flex justify-content-between mb-3'}>
        <h3>Item Negosiasi</h3>
        <div>
          <button className={"btn btn-sm btn-primary"} type={"button"} onClick={()=>getItems()} disabled={isLoading}>
            <i className={"fa fa-refresh"}></i> {intl.formatMessage({id:"Refresh"})}
          </button>
        </div>
      </div>
      <div className={'table-responsive'}>
        {isLoading && (
          <Placeholder.Grid rows={10} columns={13} active />
        )}
        {!isLoading && (
          <table className={"table table-header-gray"} key={renderKey}>
            <thead>
            <tr>
              <th className={'border px-2 text-center text-nowrap'}>Rekanan</th>
              <th className={'border px-2 text-center text-nowrap'}>Material Number</th>
              <th className={'border px-2 text-start text-nowrap'}>Description</th>
              <th className={'border px-2 text-center text-nowrap'}>HPS</th>
              <th className={'border px-2 text-center text-nowrap'}>Qty</th>
              <th className={'border px-2 text-center text-nowrap'}>Harga Satuan Penawaran Awal</th>
              <th className={'border px-2 text-center text-nowrap'}>Harga Satuan Sebelum Sesi Nego</th>
              <th className={'border px-2 text-center text-nowrap'}>Harga Satuan Pengajuan Nego</th>
              <th className={'border px-2 text-center text-nowrap'}>Harga Total Pengajuan Nego</th>
              <th className={'border px-2 text-center text-nowrap'}>Vendor Quote</th>
              {/*<th className={"border px-2 text-center text-nowrap"}>Status</th>*/}
              <th className={'border px-2 text-center text-nowrap'}>Delivery Time(Hari)</th>
              <th className={'border px-2 text-center text-nowrap'}>Selisih OE</th>
              <th className={'border px-2 text-center'} colSpan={1}>
                Note
              </th>
              <th
                className={'border px-2 text-center text-nowrap'}
                style={{verticalAlign: 'middle', position: 'sticky', right: 210, zIndex: 1}}
              >
                Status
              </th>

              <th className={'border px-2 text-center'} colSpan={2}
                  style={{verticalAlign: 'middle', position: 'sticky', right: 0, zIndex: 1}}>Hasil Negosiasi
              </th>
            </tr>
            </thead>
            <tbody>
            {negotiations.length === 0 && (
              <tr>
                <td colSpan={13} className={'text-center'}>
                  <i>Tidak ada data</i>
                </td>
              </tr>
            )}

            {negotiations && negotiations.map((item: any, index: number) => {
              return (
                <TRItem
                  key={"proses-nego-list-" + index}
                  proposalTender={proposalTender}
                  negotiation={item}
                  index={index}
                  onAfterApprove={()=>getItems()}
                  loading={isLoading}
                />
              );
            })}


            </tbody>
          </table>
        )}

      </div>
      {isShowCloseNegotiation() && (

      <BottomToolbarWrapper
        children={
          <>

              <>
                <button
                  type={"button"}
                  disabled={isLoading}
                  className={"btn btn-danger ms-2"}
                  onClick={onCloseNegosiasi}
                >
                  {isLoadingClose ? (
                    <i className="fa fa-spinner fa-spin"></i>
                  ) : (
                    <i className={"fa fa-close"}></i>
                  )}
                  Tutup Negosiasi
                </button>
              </>

          </>
        } />

        )}
    </>
  );

};

export default ProsesNegosiasiTab;